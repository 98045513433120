import {
  createI18n,
  // @ts-ignore
} from 'vue-i18n';
import {
  watch,
} from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import en from '@/constants/locales/en';
import de from '@/constants/locales/de';
import {
  TSupportedLocale,
} from '@/types/i18n.ts';

// Type-define "en" as the master schema for the resource
// "master" schema means it will be used for keys resolving
type MessageSchema = typeof en;

const messages = {
  en,
  de,
};

export const i18n = createI18n<[MessageSchema], TSupportedLocale>({
  locale: 'en',
  fallbackLocale: 'en',
  // later to explicitly define not using it as `ctx.$i18n`
  // legacy: false,
  globalInjection: true,
  allowComposition: true,
  messages,
});

watch(
  () => i18n.global.locale,
  (locale) => {
    dayjs.locale(locale);
  },
);
