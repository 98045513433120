import {
  keyBy,
} from 'lodash';
import {
  BlobStoreService,
  NAMESPACE_MAINTENANCE_CALENDAR,
} from '@/services/BlobStoreService';
import {
  EquipmentService,
} from '@/services/EquipmentService';
import {
  MaintenanceCalendarService,
} from '@/services/MaintenanceCalendar';

export default {
  namespaced: true,
  state: {
    seriesDetails: [],
    isLoadingSeriesDetails: false,
    jobDetails: [],
    isLoadingJobDetails: false,
    isLoadingReportsList: false,
    equipmentType: null,
    isLoadingEquipmentType: false,
    occurrenceResults: [],
    isLoadingOccurrenceResults: false,
    reportFile: [],
    isLoadingReportFile: false,
    docsFiles: [],
    isLoadingDocsFiles: false,
  },
  mutations: {
    setSeriesDetailsLoading(state, isLoading) {
      state.isLoadingSeriesDetails = isLoading;
    },
    setSeriesDetails(state, seriesDetails) {
      state.seriesDetails = seriesDetails;
    },
    setJobDetailsLoading(state, isLoading) {
      state.isLoadingJobDetails = isLoading;
    },
    setJobDetails(state, jobDetails) {
      state.jobDetails = jobDetails;
    },
    setEquipmentTypeLoading(state, isLoading) {
      state.isLoadingEquipmentType = isLoading;
    },
    setEquipmentType(state, equipmentType) {
      state.equipmentType = equipmentType;
    },
    setOccurrenceResultsLoading(state, isLoading) {
      state.isLoadingOccurrenceResults = isLoading;
    },
    setOccurrenceResults(state, occurrenceResults) {
      state.occurrenceResults = occurrenceResults;
    },
    updateOccurrenceResults(state, updatedResults) {
      const byId = keyBy(state.occurrenceResults, 'id');
      updatedResults.forEach((result) => {
        byId[result.id] = result;
      });
      state.occurrenceResults = Object.values(byId);
    },
    setReportFileLoading(state, isLoading) {
      state.isLoadingReportFile = isLoading;
    },
    setReportFile(state, reportFile) {
      state.reportFile = reportFile;
    },
    setDocsFilesLoading(state, isLoading) {
      state.isLoadingDocsFiles = isLoading;
    },
    setDocsFiles(state, docsFiles) {
      state.docsFiles = docsFiles;
    },
  },
  actions: {
    async LOAD_SERIES_DETAILS(context, seriesId) {
      try {
        context.commit('setSeriesDetailsLoading', true);
        const seriesDetails = await MaintenanceCalendarService.getSeriesDetails(
          seriesId,
        );
        context.commit('setSeriesDetails', seriesDetails);
      } catch (e) {
        context.commit('setSeriesDetails', []);
      } finally {
        context.commit('setSeriesDetailsLoading', false);
      }
    },
    async LOAD_JOB_DETAILS(context, maintenanceJobId) {
      try {
        context.commit('setJobDetailsLoading', true);
        const jobDetails = await MaintenanceCalendarService.getMaintenanceJob(
          maintenanceJobId,
        );
        context.commit('setJobDetails', jobDetails);
      } catch (e) {
        context.commit('setJobDetails', []);
      } finally {
        context.commit('setJobDetailsLoading', false);
      }
    },
    async LOAD_EQUIPMENT_TYPE(context, assetId) {
      try {
        context.commit('setEquipmentTypeLoading', true);
        const {
          modelName,
        } = await EquipmentService.getEquipmentById(assetId);
        context.commit('setEquipmentType', modelName);
      } catch (e) {
        context.commit('setEquipmentType', null);
      } finally {
        context.commit('setEquipmentTypeLoading', false);
      }
    },
    async LOAD_OCCURRENCE_RESULTS(context, occurrenceId) {
      try {
        context.commit('setOccurrenceResultsLoading', true);
        const occurrenceResults = await MaintenanceCalendarService.getOccurrenceResults(occurrenceId);
        context.commit('setOccurrenceResults', occurrenceResults);
      } catch (e) {
        context.commit('setOccurrenceResults', []);
      } finally {
        context.commit('setOccurrenceResultsLoading', false);
      }
    },
    async UPDATE_OCCURRENCE_RESULTS(context, {
      occurrenceId, results,
    }) {
      try {
        context.commit('setOccurrenceResultsLoading', true);
        const updated = await MaintenanceCalendarService.updateOccurrenceResults({
          occurrenceId,
          results,
        });
        context.commit('updateOccurrenceResults', updated);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setOccurrenceResultsLoading', false);
      }
    },
    async LOAD_REPORT_FILE(context, {
      key,
    }) {
      try {
        context.commit('setReportFileLoading', true);
        const reportFile = await BlobStoreService.getFileList(
          NAMESPACE_MAINTENANCE_CALENDAR,
          key,
        );
        context.commit('setReportFile', reportFile);
      } catch (e) {
        context.commit('setReportFile', []);
      } finally {
        context.commit('setReportFileLoading', false);
      }
    },
    async DELETE_REPORT_FILE(context) {
      try {
        context.commit('setReportFileLoading', true);
        await Promise.all(
          context.state.reportFile.map((file) => BlobStoreService.delete(
            NAMESPACE_MAINTENANCE_CALENDAR,
            file.key,
            file.name,
          )),
        );
        context.commit('setReportFile', []);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setReportFileLoading', false);
      }
    },
    async LOAD_DOCS_FILES(context, {
      key,
    }) {
      try {
        context.commit('setDocsFilesLoading', true);
        const docsFiles = await BlobStoreService.getFileList(
          NAMESPACE_MAINTENANCE_CALENDAR,
          key,
        );
        context.commit('setDocsFiles', docsFiles);
      } catch (e) {
        context.commit('setDocsFiles', []);
      } finally {
        context.commit('setDocsFilesLoading', false);
      }
    },
  },
  getters: {
    getSeriesDetails(state) {
      return state.seriesDetails;
    },
    getIsLoadingSeriesDetails(state) {
      return state.isLoadingSeriesDetails;
    },
    getJobDetails(state) {
      return state.jobDetails;
    },
    getIsLoadingJobDetails(state) {
      return state.isLoadingJobDetails;
    },
    getIsLoadingReportsList(state) {
      return state.isLoadingReportsList;
    },
    getEquipmentType(state) {
      return state.equipmentType;
    },
    getIsLoadingEquipmentType(state) {
      return state.isLoadingEquipmentType;
    },
    getOccurrenceResults(state) {
      return state.occurrenceResults;
    },
    getIsLoadingOccurrenceResults(state) {
      return state.isLoadingOccurrenceResults;
    },
    getReportFile(state) {
      return state.reportFile;
    },
    getIsLoadingReportFile(state) {
      return state.isLoadingReportFile;
    },
    getDocsFiles(state) {
      return state.docsFiles;
    },
    getIsLoadingDocsFiles(state) {
      return state.isLoadingDocsFiles;
    },
  },
};
