import {
  ATOMIC_PERCENTAGE,
  GROUPS_PATH,
  MATERIALS_PATH,
  SIMULATOR_MATERIAL_CHART_PATH,
  SIMULATOR_MATERIAL_PATH,
  SYSTEMS_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const MaterialsService = {
  getMaterialsList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MATERIALS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getMaterial(materialId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MATERIALS_PATH}/${materialId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addNewMaterial(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${MATERIALS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getMaterialsGroups() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MATERIALS_PATH}/${GROUPS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getMaterialsSystems() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MATERIALS_PATH}/${SYSTEMS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  calculateMaterialComposition(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${MATERIALS_PATH}/${ATOMIC_PERCENTAGE}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  calculateMaterialProperties(payload, {
    calculationModel,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${SIMULATOR_MATERIAL_PATH}`, payload, {
          params: {
            calculation_model: calculationModel,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getMaterialCharts(payload, {
    chartType, calculationModel,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${SIMULATOR_MATERIAL_CHART_PATH}`, payload, {
          params: {
            chart_type: chartType,
            calculation_model: calculationModel,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
