import {
  AxiosResponse,
} from 'axios';
import {
  EOpenapiNpmPaths,
  computedUrl,
  // TComponentNpmValue,
} from '@/types/openapi-type';
import {
  paths as npmPaths,
} from '@/types/openapi_npm';
import {
  axiosClientV3Npm,
} from './client';
import {
  TApiPayload,
} from './type/export';

export type TCreateOrUpdateRecipePathPayload = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['put']['parameters']['path'];
export type TCreateOrUpdateRecipeBodyParamsPayload = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['put']['requestBody']['content']['application/json'];
export type TCreateOrUpdateRecipePayload = TApiPayload<TCreateOrUpdateRecipePathPayload, undefined, TCreateOrUpdateRecipeBodyParamsPayload>;
export type TCreateOrUpdateRecipeResponse = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['put']['responses']['200']['content']['application/json'];

export async function createOrUpdateRecipe(payload: TCreateOrUpdateRecipePayload): Promise<AxiosResponse<TCreateOrUpdateRecipeResponse>> {
  return axiosClientV3Npm
    .put(computedUrl[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE](payload.path), payload.bodyParams);
}

export type TFurnaceLoadRecipePath = npmPaths[EOpenapiNpmPaths.FURNACE_LOAD_JOB]['post']['parameters']['path'];
export type TFurnaceLoadRecipeBodyParams = npmPaths[EOpenapiNpmPaths.FURNACE_LOAD_JOB]['post']['requestBody']['content']['application/json'];
export type TFurnaceLoadRecipePayload = TApiPayload<TFurnaceLoadRecipePath, undefined, TFurnaceLoadRecipeBodyParams>;
export type TFurnaceLoadRecipeResponse = npmPaths[EOpenapiNpmPaths.FURNACE_LOAD_JOB]['post']['responses']['200']['content']['application/json'];

export async function furnaceLoadRecipe(payload: TFurnaceLoadRecipePayload): Promise<AxiosResponse<TFurnaceLoadRecipeResponse>> {
  return axiosClientV3Npm
    .post(computedUrl[EOpenapiNpmPaths.FURNACE_LOAD_JOB](payload.path), payload.bodyParams);
}

export type TFurnaceSetStatePath = npmPaths[EOpenapiNpmPaths.FURNACE_STATE_POST]['post']['parameters']['path'];
export type TFurnaceSetStateQuery = npmPaths[EOpenapiNpmPaths.FURNACE_STATE_POST]['post']['parameters']['query'];
// TODO wait for BE to add it to TS
// commands are: "start" | "resume" | "abort" | "end"
export type TFurnaceSetStateBodyParams = npmPaths[EOpenapiNpmPaths.FURNACE_STATE_POST]['post']['requestBody']['content']['application/json'];
export type TFurnaceSetStatePayload = TApiPayload<TFurnaceSetStatePath, TFurnaceSetStateQuery, TFurnaceSetStateBodyParams>;
export type TFurnaceSetStateResponse = npmPaths[EOpenapiNpmPaths.FURNACE_STATE_POST]['post']['responses']['200']['content']['application/json'];

export async function furnaceSetState(payload: TFurnaceSetStatePayload): Promise<AxiosResponse<TFurnaceSetStateResponse>> {
  return axiosClientV3Npm
    .post(computedUrl[EOpenapiNpmPaths.FURNACE_STATE_POST](payload.path, payload.query), payload.bodyParams);
}

export type TGetRecipePathPayload = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['get']['parameters']['path'];
export type TGetRecipePayload = TApiPayload<TGetRecipePathPayload>;
export type TGetRecipeResponse = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['get']['responses']['200']['content']['application/json'];

export async function getRecipe(payload: TGetRecipePayload): Promise<AxiosResponse<TGetRecipeResponse>> {
  return axiosClientV3Npm
    .get(computedUrl[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE](payload.path), payload.bodyParams);
}

export type TGetRecipeTemplatePathPayload = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_TEMPLATE_GET]['get']['parameters']['path'];
export type TGetRecipeTemplatePayload = TApiPayload<TGetRecipeTemplatePathPayload>;
export type TGetRecipeTemplateResponse = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_TEMPLATE_GET]['get']['responses']['200']['content']['application/json'];

export async function getRecipeTemplate(payload: TGetRecipeTemplatePayload): Promise<AxiosResponse<TGetRecipeTemplateResponse>> {
  return axiosClientV3Npm
    .get(computedUrl[EOpenapiNpmPaths.FURNACE_RECIPE_TEMPLATE_GET](payload.path), payload.bodyParams);
}

export type TGetRecipeListPathPayload = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_LIST]['get']['parameters']['path'];
export type TGetRecipeListPayload = TApiPayload<TGetRecipeListPathPayload>;
export type TGetRecipeListResponse = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_LIST]['get']['responses']['200']['content']['application/json'];

export async function getRecipesList(payload: TGetRecipeListPayload): Promise<AxiosResponse<TGetRecipeListResponse>> {
  return axiosClientV3Npm
    .get(computedUrl[EOpenapiNpmPaths.FURNACE_RECIPE_LIST](payload.path), payload.bodyParams);
}

export type TDeleteRecipePathPayload = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['delete']['parameters']['path']
export type TDeleteRecipePayload = TApiPayload<TDeleteRecipePathPayload>;
export type TDeleteRecipeResponse = npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['delete']['responses']['200']['content'];

export async function deleteRecipe(payload: TDeleteRecipePayload): Promise<AxiosResponse<TDeleteRecipeResponse>> {
  return axiosClientV3Npm
    .delete(computedUrl[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE](payload.path), payload.bodyParams);
}
