export default {
  chart: {
    title: 'Chart',
    signals: {
      title: 'Signals',
    },
    meanings: {
      title: 'Meanings',
    },
    functions: {
      title: 'Functions',
    },
  },
  expression: {
    title: 'Expression',
  },
};
