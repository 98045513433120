import {
  NpmControlService,
} from '@/services/NpmControlService';

export default {
  namespaced: true,
  state: {
    isLoadingRecipeList: false,
    recipeList: [],
    isLoadingAlarmsList: false,
    alarmsList: [],
    isLoadingLoadJobConfig: false,
    loadJobConfig: null,
    isLoadingLoadJobInfo: false,
    loadJobInfo: null,
    isLoadingSystemStatus: false,
    systemStatus: [],
    isUserLoggedIn: false,
    buttonInfo: [],
    isLoadingButtonInfo: false,
  },
  mutations: {
    setIsLoadingAlarmsList(state, isLoading) {
      state.isLoadingAlarmsList = isLoading;
    },
    setAlarmsList(state, alarmsList) {
      state.alarmsList = alarmsList;
    },
    setIsLoadingLoadJobConfig(state, isLoading) {
      state.isLoadingLoadJobConfig = isLoading;
    },
    setLoadJobConfig(state, loadJobConfig) {
      state.loadJobConfig = loadJobConfig;
    },
    setIsLoadingLoadJobInfo(state, isLoading) {
      state.isLoadingLoadJobInfo = isLoading;
    },
    setLoadJobInfo(state, loadJobInfo) {
      state.loadJobInfo = loadJobInfo;
    },
    setIsLoadingRecipeList(state, isLoading) {
      state.isLoadingRecipeList = isLoading;
    },
    setRecipeList(state, recipeList) {
      state.recipeList = recipeList;
    },
    setIsLoadingSystemStatus(state, isLoading) {
      state.isLoadingSystemStatus = isLoading;
    },
    setSystemStatus(state, systemStatus) {
      state.systemStatus = systemStatus;
    },
    setIsUserLoggedIn(state, isLoggedIn) {
      state.isUserLoggedIn = isLoggedIn;
    },
    setButtonInfo(state, buttonInfo) {
      state.buttonInfo = buttonInfo;
    },
    setIsLoadingButtonInfo(state, isLoading) {
      state.isLoadingButtonInfo = isLoading;
    },
  },
  actions: {
    async GET_NPM_RECIPE_LIST(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('setIsLoadingRecipeList', true);
        context.commit('setRecipeList', []);
        const recipeList = await NpmControlService.getRecipeList({
          customerCode,
          location,
          systemId,
        });
        context.commit('setRecipeList', recipeList);
      } catch (e) {
        context.commit('setRecipeList', []);
      } finally {
        context.commit('setIsLoadingRecipeList', false);
      }
    },
    async GET_LOAD_JOB_CONFIG(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('setIsLoadingLoadJobConfig', true);
        const loadJobConfig = await NpmControlService.getLoadJobConfig({
          customerCode,
          location,
          systemId,
        });
        context.commit('setLoadJobConfig', loadJobConfig);
      } catch (e) {
        context.commit('setLoadJobConfig', []);
      } finally {
        context.commit('setIsLoadingLoadJobConfig', false);
      }
    },
    async GET_LOAD_JOB_INFO(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('setIsLoadingLoadJobInfo', true);
        const loadJobInfo = await NpmControlService.getLoadJobInfo({
          customerCode,
          location,
          systemId,
        });
        context.commit('setLoadJobInfo', loadJobInfo);
      } catch (e) {
        context.commit('setLoadJobInfo', null);
      } finally {
        context.commit('setIsLoadingLoadJobInfo', false);
      }
    },
    async GET_NPM_SYSTEM_STATUS(context, {
      isLoadingIndicator, subSystemId, customerCode, location, systemId,
    }) {
      try {
        if (isLoadingIndicator) {
          context.commit('setIsLoadingAlarmsList', true);
          context.commit('setAlarmsList', []);
        }
        context.commit('setIsLoadingSystemStatus', true);
        const systemStatus = await NpmControlService.getSystemStatus({
          customerCode,
          location,
          systemId,
        });
        if (subSystemId > 0) {
          const alarmsList = systemStatus.find((system) => system.sub_system_id === subSystemId).alarms;
          context.commit('setAlarmsList', alarmsList);
        } else {
          context.commit('setAlarmsList', systemStatus[0].alarms);
        }
        context.commit('setSystemStatus', systemStatus);
      } catch (e) {
        context.commit('setAlarmsList', []);
        context.commit('setSystemStatus', []);
      } finally {
        if (isLoadingIndicator) {
          context.commit('setIsLoadingAlarmsList', false);
        }
        context.commit('setIsLoadingSystemStatus', false);
      }
    },
    SET_USER_IS_LOGIN(context, isLoggedIn) {
      context.commit('setIsUserLoggedIn', isLoggedIn);
    },
    RESET_SYSTEM_STATUS(context) {
      context.commit('setSystemStatus', []);
    },
    RESET_ALARMS(context) {
      context.commit('setAlarmsList', []);
    },
    async GET_BUTTON_INFO(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('setIsLoadingButtonInfo', true);
        const buttonInfo = await NpmControlService.getButtonInfo({
          customerCode,
          location,
          systemId,
        });
        context.commit('setButtonInfo', buttonInfo);
      } catch (e) {
        context.commit('setButtonInfo', []);
      } finally {
        context.commit('setIsLoadingButtonInfo', false);
      }
    },
  },
  getters: {
    getNpmRecipeList(state) {
      return state.recipeList;
    },
    getIsLoadingRecipeList(state) {
      return state.isLoadingRecipeList;
    },
    getNpmAlarmsList(state) {
      return state.alarmsList;
    },
    getIsLoadingNpmAlarmsList(state) {
      return state.isLoadingAlarmsList;
    },
    getLoadJobConfig(state) {
      return state.loadJobConfig;
    },
    getIsLoadingLoadJobConfig(state) {
      return state.isLoadingLoadJobConfig;
    },
    getLoadJobInfo(state) {
      return state.loadJobInfo;
    },
    getIsLoadingLoadJobInfo(state) {
      return state.isLoadingLoadJobInfo;
    },
    geSystemStatus(state) {
      return state.systemStatus;
    },
    getIsLoadingSystemStatus(state) {
      return state.isLoadingSystemStatus;
    },
    getIsUserLoggedIn(state) {
      return state.isUserLoggedIn;
    },
    getButtonInfo(state) {
      return state.buttonInfo;
    },
    getIsLoadingButtonInfo(state) {
      return state.isLoadingButtonInfo;
    },
  },
};
