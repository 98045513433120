import {
  GraphQLTypes,
  InputType,
  ValueTypes,
} from '@/pt9800/generated/zeus';
import {
  client,
} from '@/pt9800/api/api-client.ts';
import {
  userQuery,
} from '@/pt9800/zeus/user.ts';
import {
  COMMON_MUTATION_RESULT_FIELDS,
} from '@/pt9800/zeus/util.ts';

export interface IUserRouteItem {
  id: string | number
  displayName: string
  routeName: string
  children?: IUserRouteItem[]
}

const {
  thunder,
} = client;

export type TGetUserRoutesResult = Record<string, IUserRouteItem>

export type TUser = InputType<GraphQLTypes['SHARED_User'], typeof userQuery.userList>
export type TCreateUserInput = ValueTypes['createUserInput'];
export type TUpdateUserInput = ValueTypes['updateUserInput'] & { id: number };
export type TDeleteUserInput = { id: number };

const getUserList = async (location: string) => {
  const result = await thunder('query')({
    userList: userQuery.userList,
  }, {
    variables: {
      location,
    },
  });
  return result.userList!;
};

const createUser = async (location: string, input: TCreateUserInput) => {
  const result = await thunder('mutation')({
    createUser: [
      {
        data: input,
      },
      userQuery.userList,
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.createUser!;
};

const updateUser = async (location: string, input: TUpdateUserInput) => {
  const {
    id, ...data
  } = input;
  const result = await thunder('mutation')({
    updateUser: [
      {
        id,
        data,
      },
      userQuery.userList,
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.updateUser!;
};

const deleteUser = async (location: string, input: TDeleteUserInput) => {
  const result = await thunder('mutation')({
    deleteUser: [
      {
        id: input.id,
      },
      true,
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.deleteUser!;
};

const getLanguageList = async (location: string) => {
  const result = await thunder('query')({
    languageList: {
      languageId: true,
      description: true,
    },
  }, {
    variables: {
      location,
    },
  });
  return result.languageList!;
};

export type TLanguage = Awaited<ReturnType<typeof getLanguageList>>[number]

const getUserGroupList = async (location: string) => {
  const result = await thunder('query')({
    userGroup: userQuery.userGroup,
  }, {
    variables: {
      location,
    },
  });
  return result.userGroup!;
};

export type TUserGroup = Awaited<ReturnType<typeof getUserGroupList>>[number];
export type TCreateUserGroupInput = ValueTypes['createUserGroupInput'];
export type TUpdateUserGroupInput = ValueTypes['updateUserGroupInput'] & { id: number };
export type TDeleteUserGroupInput = { id: number }

const createUserGroup = async (location: string, data: TCreateUserGroupInput) => {
  const result = await thunder('mutation')({
    createUserGroup: [
      {
        data,
      },
      userQuery.userGroup,
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.createUserGroup;
};

const updateUserGroup = async (location: string, input: TUpdateUserGroupInput) => {
  const {
    id, ...data
  } = input;
  const result = await thunder('mutation')({
    updateUserGroup: [
      {
        id,
        data,
      },
      userQuery.userGroup,
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.updateUserGroup!;
};

const deleteUserGroup = async (location: string, data: TDeleteUserGroupInput) => {
  const result = await thunder('mutation')({
    deleteUserGroup: [
      {
        id: data.id,
      },
      true,
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.deleteUserGroup!;
};

export const create = (apiClient: any) => {
  /* eslint-disable arrow-body-style */
  const getPermittedRoutes = async (location: string): Promise<TGetUserRoutesResult> => {
    const config = {
      headers: {
        'X-PT-Location': location,
      },
    };
    return (await apiClient.axios.get('/me', config)).data;
  };

  return {
    getPermittedRoutes,
  };
};

const getApplicationGroupList = async (location: string) => {
  const result = await thunder('query')({
    applicationGroup: userQuery.applicationGroup,
  }, {
    variables: {
      location,
    },
  });
  return result.applicationGroup!;
};

export type TApplicationGroup = Awaited<ReturnType<typeof getApplicationGroupList>>[number];
export type TCreateAppGroupInput = ValueTypes['CreateAppGroupInput'];
export type TEditAppGroupInput = ValueTypes['EditAppGroupInput'];
export type TDeleteAppGroupInput = ValueTypes['DeleteAppGroupInput'];

const createApplicationGroup = async (location: string, data: TCreateAppGroupInput) => {
  const result = await thunder('mutation')({
    createApplicationGroup: [
      {
        data,
      },
      {
        ...COMMON_MUTATION_RESULT_FIELDS,
        applicationGroup: userQuery.applicationGroup,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.createApplicationGroup!;
};

const editApplicationGroup = async (location: string, data: TEditAppGroupInput) => {
  const result = await thunder('mutation')({
    editApplicationGroup: [
      {
        data,
      },
      {
        ...COMMON_MUTATION_RESULT_FIELDS,
        applicationGroup: userQuery.applicationGroup,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.editApplicationGroup!;
};

const deleteApplicationGroup = async (location: string, data: TDeleteAppGroupInput) => {
  const result = await thunder('mutation')({
    deleteApplicationGroup: [
      {
        data,
      },
      {
        ...COMMON_MUTATION_RESULT_FIELDS,
        applicationGroup: userQuery.applicationGroup,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.deleteApplicationGroup!;
};

const getApplicationMetadata = async (location: string) => {
  const result = await thunder('query')({
    applicationMetadata: userQuery.applicationMetadata,
  }, {
    variables: {
      location,
    },
  });
  return result.applicationMetadata!;
};

export type TApplicationMetadata = Awaited<ReturnType<typeof getApplicationMetadata>>;

const getApplicationList = async (location: string) => {
  const result = await thunder('query')({
    applicationList: userQuery.applicationList,
  }, {
    variables: {
      location,
    },
  });
  return result.applicationList!;
};

export type TApplication = Awaited<ReturnType<typeof getApplicationList>>[number];
export type TCreateApplicationInput = ValueTypes['CreateApplicationInput'];
export type TEditApplicationInput = ValueTypes['EditApplicationInput'];
export type TDeleteApplicationInput = ValueTypes['DeleteApplicationInput'];

const createApplication = async (location: string, data: TCreateApplicationInput) => {
  const result = await thunder('mutation')({
    createApplication: [
      {
        data,
      },
      {
        ...COMMON_MUTATION_RESULT_FIELDS,
        application: userQuery.applicationList,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.createApplication!;
};

const editApplication = async (location: string, data: TEditApplicationInput) => {
  const result = await thunder('mutation')({
    editApplication: [
      {
        data,
      },
      {
        ...COMMON_MUTATION_RESULT_FIELDS,
        application: userQuery.applicationList,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.editApplication!;
};

const deleteApplication = async (location: string, data: TDeleteApplicationInput) => {
  const result = await thunder('mutation')({
    deleteApplication: [
      {
        data,
      },
      {
        ...COMMON_MUTATION_RESULT_FIELDS,
        application: userQuery.applicationList,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.deleteApplication!;
};

const getUserAccessList = async (location: string) => {
  const result = await thunder('query')({
    userAccessList: userQuery.userAccessList,
  }, {
    variables: {
      location,
    },
  });
  return result.userAccessList!;
};

export type TUserAccessListItem = Awaited<ReturnType<typeof getUserAccessList>>[number];
export type TUpdateUserAccessInput = ValueTypes['updateUserAccessInput'];

const updateUserAccess = async (location: string, data: TUpdateUserAccessInput) => {
  const result = await thunder('mutation')({
    updateUserAccess: [
      {
        data,
      },
      {
        ...userQuery.userAccessList,
      },
    ],
  }, {
    variables: {
      location,
    },
  });
  return result.updateUserAccess!;
};

export {
  getApplicationGroupList,
  createApplicationGroup,
  editApplicationGroup,
  deleteApplicationGroup,

  getApplicationMetadata,
  getApplicationList,
  createApplication,
  editApplication,
  deleteApplication,

  getLanguageList,

  getUserList,
  createUser,
  updateUser,
  deleteUser,

  getUserGroupList,
  createUserGroup,
  updateUserGroup,
  deleteUserGroup,

  getUserAccessList,
  updateUserAccess,
};
