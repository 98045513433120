import cloneDeep from 'lodash/cloneDeep';
import {
  PartService,
} from '@/services/simulator/PartService';

const emptyChart = {
  data: [],
};
const transformToChartDataFormat = (response) => {
  const data = [];
  response.x.values.map((x, i) => {
    data.push({
      x,
      y: response.y.values[i],
    });
  });
  return data;
};
const emptyPartDetails = {
  name: '',
  shape: null,
  description: '',
  size: 0,
  setPoints: {
    surfaceHardness: 0,
    effectiveCaseDepth: 0,
    effectiveCaseHardness: 0,
  },
  grainSize: 0,
  quenchingIntensity: 0,
  endTemperature: 0,
};

export default {
  state: {
    partList: [],
    isLoadingPartList: false,
    isLoadingPartDetails: false,
    selectedPart: null,
    clonedPartDetails: emptyPartDetails,
    partDetails: emptyPartDetails,
    isPartEdited: false,
    cctPartsChartData: [
      emptyChart,
    ],
    isLoadingCctPartsChartData: false,
  },

  getters: {
    getPartList(state) {
      return state.partList;
    },
    getClonedPartDetails(state) {
      return state.clonedPartDetails;
    },
    getSelectedPart(state) {
      return state.selectedPart;
    },
    getPartDetails(state) {
      return state.partDetails;
    },
    getIsPartEdited(state) {
      return state.isPartEdited;
    },
    getIsLoadingPartList(state) {
      return state.isLoadingPartList;
    },
    getCctPartsChartData(state) {
      return state.cctPartsChartData;
    },
    isLoadingCctPartsChartData(state) {
      return state.isLoadingCctPartsChartData;
    },
  },

  mutations: {
    setIsLoadingPartList(state, isLoading) {
      state.isLoadingPartList = isLoading;
    },
    setIsLoadingPartDetails(state, isLoading) {
      state.isLoadingPartDetails = isLoading;
    },
    setPartList(state, data) {
      state.partList = data;
    },
    setSelectedPart(state, part) {
      state.selectedPart = part;
    },
    setClonePartDetails(state, part) {
      state.clonedPartDetails = cloneDeep(part);
    },
    setPartDetails(state, part) {
      state.partDetails = part;
    },
    resetPartChanges(state) {
      state.partDetails = cloneDeep(state.clonedPartDetails);
    },
    clearPartChanges(state) {
      state.partDetails = cloneDeep(emptyPartDetails);
      state.clonedPartDetails = cloneDeep(emptyPartDetails);
    },
    setIsPartEdited(state, isEdited) {
      state.isPartEdited = isEdited;
    },
    setCctPartsChartData(state, chartData) {
      state.cctPartsChartData = chartData;
    },
    setIsLoadingCctPartsChartData(state, loading) {
      state.isLoadingCctPartsChartData = loading;
    },
  },

  actions: {
    RESET_PART_CHANGES(context) {
      context.commit('resetPartChanges');
    },
    CLEAR_PART_CHANGES(context) {
      context.commit('clearPartChanges');
      context.commit('setCctPartsChartData', [
        emptyChart,
      ]);
    },
    async SET_SIMULATORS_PART_LIST(context) {
      context.commit('setIsLoadingPartList', true);
      try {
        context.commit('setPartList', await PartService.getPartList());
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingPartList', false);
      }
    },
    async PART_EDITED(context, isEdited) {
      context.commit('setIsPartEdited', isEdited);
    },
    async SET_SELECTED_PART(context, part) {
      context.commit('setSelectedPart', part);
    },
    async SET_PART_DETAILS(context, partId) {
      context.commit('setIsLoadingPartDetails', true);
      try {
        const response = await PartService.getSinglePart(partId);
        context.commit('setClonePartDetails', response);
        context.commit('setPartDetails', response);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingPartDetails', false);
      }
    },
    async SET_PARTS_CCT_CHART(
      context,
      {
        materialComposition, calculationModel, part,
      },
    ) {
      context.commit('setIsLoadingCctPartsChartData', true);
      try {
        const response = await PartService.getPartCharts(
          {
            materialComposition,
            calculationModel,
            part,
          },
          {
            calculationModel,
          },
        );
        const datasets = [];
        for (let i = 0; i < response[0].plots.length; i++) {
          const data = transformToChartDataFormat(response[0].plots[i]);
          datasets.push({
            showLine: true,
            borderColor: 'rgb(117,117,117)',
            name: response[0].plots[i].id,
            data,
          });
        }
        context.commit('setCctPartsChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingCctPartsChartData', false);
      }
    },
  },
};
