export const create = (apiClient) => {
  const {
    fetchGraphJSON,
  } = apiClient;

  function getImages(loadName, location) {
    const query = `
          query {
            getImages(loadName: "${loadName}") {
              no
              dataBase64
            }
          }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function saveImage({
    loadName, dataBase64,
  }, location) {
    return new Promise((resolve, reject) => {
      apiClient.axios
        .post(`/productionLogs/saveImage/${loadName}`, dataBase64, {
          headers: {
            'X-PT-Location': location,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  }

  function deleteImage({
    loadName, no,
  }, location) {
    const mutation = `
          mutation {
             deleteImage(input: {loadName: "${loadName}", no: ${no}})
          }`;
    return fetchGraphJSON(mutation, {
      location,
    });
  }

  return {
    getImages,
    saveImage,
    deleteImage,
  };
};
