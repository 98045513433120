<template>
  <div class="app">
    <app-header
      v-if="isProtectedRoute"
      class="app__header flex flex-row align-center gap-3 p-2 pr-4"
      style="border-bottom: 1px solid var(--color-103)"
    />
    <navbar
      v-if="isProtectedRoute"
      class="app__navbar"
      :show-sidebar="showSidebar"
    />
    <main class="app__main">
      <router-view />
    </main>
  </div>
  <progress-bar />
  <prm-toast />
  <prm-confirm-dialog />
</template>

<script setup lang="ts">
import PrmToast from 'primevue/toast';
import PrmConfirmDialog from 'primevue/confirmdialog';
import {
  AxiosError,
} from 'axios';
import {
  useToast,
} from 'primevue/usetoast';
import {
  i18n,
} from '@i18n';
import {
  onBeforeUnmount,
  onMounted,
  shallowRef,
  computed,
  watch,
  WatchStopHandle,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import {
  setupQueryClient,
} from './query/client';
import ProgressBar from '@/components/progress-bar/progress-bar.vue';
import Navbar from '@/components/navbar/navbar.vue';
import AppHeader from '@/components/navbar/header.vue';
import {
  ANONYMOUS_ROUTES, AUTH_ROUTES,
} from './router/routes';
import {
  ERouteName,
} from './router/route-name';
import {
  getAppTitle,
} from '@/utils/string-utils.ts';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage.ts';
import {
  DEFAULT_FONT_SIZE,
} from './types/font-size.ts';
import {
  changeFontSizeForEntirePage,
} from '@/utils/font-size.ts';

setupQueryClient();

let watchStopHandle: WatchStopHandle;

const toast = useToast();
const route = useRoute();
const router = useRouter();
document.title = getAppTitle();
const customUserStorage = useCustomUserStorageStore();

const showSidebar = shallowRef(false);

// TODO: do layouts and use them based on that
const isProtectedRoute = computed(() => route.name !== undefined
  && !AUTH_ROUTES.includes(route.name as ERouteName)
  && !ANONYMOUS_ROUTES.includes(route.name as ERouteName));

function globalErrorHandler(event: PromiseRejectionEvent) {
  const reason = event.reason;

  if (
    // graphql zeus error
    !reason.errors
    && !(reason instanceof AxiosError)
  ) {
    throw reason;
  }

  // graphql zeus error
  if (reason.errors) {
    toast.add({
      severity: 'error',
      summary: i18n.global.t('common.error'),
      detail: reason.errors.map((item: any) => item.message).join('; '),
    });
    return;
  }

  toast.add({
    severity: 'error',
    summary: i18n.global.t('common.error'),
    detail:
      // REST qmulus
      reason.response?.data?.message
      || reason.response?.data?.details
      || reason.response?.data?.detail
      // npm
      || reason.response?.data?.detail?.message
      // graphql
      || reason.response?.data?.errors?.[0]?.message
      // fallback
      || i18n.global.t('common.errorMessage.default'),
  });

  if (reason.response?.status === 401) {
    router.replace({
      name: ERouteName.LOGIN,
      query: {
        backUrl: router.currentRoute.value.fullPath,
      },
    });
  }
}

onMounted(() => {
  window.addEventListener('unhandledrejection', globalErrorHandler);
  watchStopHandle = watch(() => customUserStorage.customUserSettings?.fontSize, (newFontSize) => {
    changeFontSizeForEntirePage(newFontSize || DEFAULT_FONT_SIZE);
  }, {
    immediate: true,
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('unhandledrejection', globalErrorHandler);
  watchStopHandle();
});

</script>

<style lang="scss">
@use '@/styles/primevue.scss';
@use '@/styles/util/color-var.scss';
@use '@/styles/util/gap-var.scss';
@use '@/styles/util/css-var.scss';
@use '@/styles/component/export.scss' as component;
@use '@/styles/util/typography-utility.scss';
@use '@/styles/font.scss';
</style>

<style lang="scss" scoped>
@use '@/styles/util/media.scss';

.app {
  width: 100dvw;
  min-height: 100dvh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header"
    "navbar main";

  &__navbar {
    grid-area: navbar;
    max-height: calc(100dvh - var(--navbar-header-height, 0));
    position: sticky;
    top: var(--navbar-header-height, 0);

    @include media.media(mobile) {
      display: none;
      width: 0;
      overflow: none;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    grid-area: header;

    padding: calc(.5rem + env(safe-area-inset-top)) calc(.5rem + env(safe-area-inset-right)) .5rem calc(.5rem + env(safe-area-inset-left));
  }

  &__main {
    position: relative;
    grid-area: main;
    overflow: auto;
  }

  // this breaks layout on all pages with sidebar + apps page
  // &__main > div {
  //   padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  // }
}
</style>
