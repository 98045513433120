export default {
  generalInformation: {
    title: 'Allgemeine Informationen',
    company: 'Unternehmen',
    street: 'Street',
    postalCode: 'Postleitzahl',
    city: 'Stadt',
    state: 'Bundesland',
    country: 'Land',
    email: 'E-Mail',
    phone: 'Telefon',
    fax: 'Fax',
    cellPhone: 'Mobiltelefon',
  },
  contact: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    jobTitle: 'Stellenbezeichnung',
    phone: 'Telefon 1',
    extensionPhone: 'Durchwahl',
    secondPhone: 'Telefon 2',
    cellPhone: 'Mobiltelefon',
    privateMail: 'E-Mail',
    addContact: 'Kontakt hinzufügen',
  },
};
