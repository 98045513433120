import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import {
  AnomalyDetectionService,
} from '@/services/AnomalyDetection';

export default {
  state: {
    jobFeatureDescriptionId: null,
    possibleRootCausesList: [],
    jobFeatureDescriptionsList: [],
    jobFeatureObservationsTypesList: [],
    observationsTypesList: [],
    jobFeatureRootCausesList: [],
    observationsList: [],
    observationTypeRootCause: [],
    observationsWithCauses: [],
    rootCausesConnections: [],
    actionList: [],
    isLoadingPossibleRootCausesList: false,
    isLoadingJobFeatureDescriptions: false,
    isLoadingObservationsTypesList: false,
    isLoadingJobFeatureObservationsTypesList: false,
    isLoadingJobFeatureRootCausesList: false,
    isLoadingObservationsList: false,
    isLoadingObservationTypeRootCause: false,
    isLoadingObservationsWithCauses: false,
    isLoadingActionList: false,
    isLoadingRootCausesConnections: false,
  },

  getters: {
    observationTypesByValue(state) {
      return keyBy(state.observationsTypesList, 'value');
    },
    getJobFeatureDescriptionId(state) {
      return state.jobFeatureDescriptionId;
    },
    getCausesList(state) {
      return state.possibleRootCausesList;
    },
    getCausesListIsLoading(state) {
      return state.isLoadingPossibleRootCausesList;
    },
    getObservationsTypesList(state) {
      return state.observationsTypesList;
    },
    getObservationsTypesListIsLoading(state) {
      return state.isLoadingObservationsTypesList;
    },
    getJobFeatureDescriptionsList(state) {
      return state.jobFeatureDescriptionsList;
    },
    getJobFeatureDescriptionsListIsLoading(state) {
      return state.isLoadingJobFeatureDescriptions;
    },
    getJobFeatureRootCausesList(state) {
      return state.jobFeatureRootCausesList;
    },
    getJobFeatureRootCausesListIsLoading(state) {
      return state.isLoadingJobFeatureRootCausesList;
    },
    getJobFeatureObservationsTypesList(state) {
      return state.jobFeatureObservationsTypesList;
    },
    getJobFeatureObservationsTypesListIsLoading(state) {
      return state.isLoadingJobFeatureObservationsTypesList;
    },
    getObservationsList(state) {
      return state.observationsList;
    },
    getIsLoadingObservationsList(state) {
      return state.isLoadingObservationsList;
    },
    getObservationTypeRootCause(state) {
      return state.observationTypeRootCause;
    },
    getIsLoadingObservationTypeRootCause(state) {
      return state.isLoadingObservationTypeRootCause;
    },
    getObservationsWithCauses(state) {
      return state.observationsWithCauses;
    },
    getIsLoadingObservationsWithCauses(state) {
      return state.isLoadingObservationsWithCauses;
    },
    getConnectionsList(state) {
      return state.rootCausesConnections;
    },
    getConnectionsListIsLoading(state) {
      return state.isLoadingRootCausesConnections;
    },
    getActionList(state) {
      return state.actionList;
    },
    getIsLoadingActionList(state) {
      return state.isLoadingActionList;
    },
  },

  mutations: {
    setJobFeatureDescriptionId(state, id) {
      state.jobFeatureDescriptionId = id;
    },
    setPossibleRootCausesLoading(state, isLoading) {
      state.isLoadingPossibleRootCausesList = isLoading;
    },
    setJobFeatureDescriptionsLoading(state, isLoading) {
      state.isLoadingJobFeatureDescriptions = isLoading;
    },
    setJobFeatureObservationsTypesListLoading(state, isLoading) {
      state.isLoadingJobFeatureObservationsTypesList = isLoading;
    },
    setJobFeatureObservationsTypesList(state, jobFeatureObservationsTypesList) {
      state.jobFeatureObservationsTypesList = jobFeatureObservationsTypesList;
    },
    setObservationsTypesListLoading(state, isLoading) {
      state.isLoadingObservationsTypesList = isLoading;
    },
    setJobFeatureRootCausesLoading(state, isLoading) {
      state.isLoadingJobFeatureRootCausesList = isLoading;
    },
    setObservationsLoading(state, isLoading) {
      state.isLoadingObservationsList = isLoading;
    },
    setObservationTypeRootCauseLoading(state, isLoading) {
      state.isLoadingObservationTypeRootCause = isLoading;
    },
    setObservationsWithCausesLoading(state, isLoading) {
      state.isLoadingObservationsWithCauses = isLoading;
    },
    setRootCausesConnectionsLoading(state, isLoading) {
      state.isLoadingRootCausesConnections = isLoading;
    },
    setActionListLoading(state, isLoading) {
      state.isLoadingActionList = isLoading;
    },
    setPossibleRootCausesList(state, possibleRootCausesList) {
      state.possibleRootCausesList = possibleRootCausesList;
    },
    setJobFeatureDescriptionsList(state, jobFeatureDescriptionsList) {
      state.jobFeatureDescriptionsList = jobFeatureDescriptionsList;
    },
    setObservationsTypesList(state, observationsTypesList) {
      state.observationsTypesList = observationsTypesList;
    },
    setJobFeatureRootCausesList(state, jobFeatureRootCausesList) {
      state.jobFeatureRootCausesList = jobFeatureRootCausesList;
    },
    setObservationsList(state, observationsList) {
      state.observationsList = observationsList;
    },
    setObservationTypeRootCause(state, observationsList) {
      state.observationTypeRootCause = observationsList;
    },
    setObservationsWithCauses(state, observationsWithCauses) {
      state.observationsWithCauses = observationsWithCauses;
    },
    setRootCausesConnections(state, rootCausesConnections) {
      state.rootCausesConnections = rootCausesConnections;
    },
    setActionList(state, actionList) {
      state.actionList = actionList;
    },
  },

  actions: {
    async SET_OBSERVATIONS_WITH_CAUSES(context, {
      jobFeatureDescriptionId, groupedObservations,
    }) {
      const observationsWithCauses = [];
      try {
        context.commit('setObservationsWithCausesLoading', true);
        const observations = cloneDeep(groupedObservations);
        for (const key in observations) {
          if (key) {
            // eslint-disable-next-line no-await-in-loop
            const rootCauses = await AnomalyDetectionService.getRootCauses({
              jobFeatureDescriptionId,
              observationType: key,
            });
            for (const item of observations[key]) {
              item.rootCauses = cloneDeep(rootCauses);
              observationsWithCauses.push(item);
            }
          }
        }
      } finally {
        context.commit('setObservationsWithCauses', observationsWithCauses);
        context.commit('setObservationsWithCausesLoading', false);
      }
    },
    SAVE_JOB_FEATURE_DESCRIPTION_ID(context, jobFeatureDescriptionId) {
      context.commit('setJobFeatureDescriptionId', jobFeatureDescriptionId);
    },
    async SET_ROOT_CAUSES_LIST(context) {
      let possibleRootCausesList = [];
      try {
        context.commit('setPossibleRootCausesLoading', true);
        possibleRootCausesList = await AnomalyDetectionService.getPossibleRootCauses();
      } finally {
        context.commit('setPossibleRootCausesList', possibleRootCausesList);
        context.commit('setPossibleRootCausesLoading', false);
      }
    },
    async SET_ROOT_CAUSES_CONNECTIONS(context, payload) {
      let rootCausesConnections = [];
      try {
        context.commit('setRootCausesConnectionsLoading', true);
        rootCausesConnections = await AnomalyDetectionService.getRootCauseConnections({
          rootCauseId: payload.rootCauseId,
          equipmentId: payload.equipmentId,
        });
      } finally {
        context.commit('setRootCausesConnections', rootCausesConnections);
        context.commit('setRootCausesConnectionsLoading', false);
      }
    },
    async SET_JOB_FEATURE_DESCRIPTIONS(context, equipmentId) {
      let jobFeatureDescriptionsList = [];
      try {
        context.commit('setJobFeatureDescriptionsLoading', true);
        jobFeatureDescriptionsList = await AnomalyDetectionService.getJobFeatureDescriptions(equipmentId);
      } finally {
        context.commit(
          'setJobFeatureDescriptionsList',
          jobFeatureDescriptionsList,
        );
        context.commit('setJobFeatureDescriptionsLoading', false);
      }
    },
    async SET_JOB_FEATURE_OBSERVATIONS_TYPES_LIST(
      context,
      {
        jobFeatureDescriptionId,
      },
    ) {
      let observationsTypesList = [];
      try {
        if (context.state.observationsTypesList.length === 0) {
          await context.dispatch('SET_OBSERVATIONS_TYPES_LIST');
        }
        context.commit('setJobFeatureObservationsTypesListLoading', true);
        observationsTypesList = cloneDeep(context.state.observationsTypesList);
        const items = [];
        if (jobFeatureDescriptionId) {
          for (const item of observationsTypesList) {
            const root = AnomalyDetectionService.getRootCauses({
              jobFeatureDescriptionId,
              observationType: item.value,
            }).then((connectedRootCauses) => {
              item.numberRootCauses = connectedRootCauses.length;
            });
            items.push(root);
          }
        }
        await Promise.all(items);
      } finally {
        context.commit(
          'setJobFeatureObservationsTypesList',
          observationsTypesList,
        );
        context.commit('setJobFeatureObservationsTypesListLoading', false);
      }
    },
    async SET_OBSERVATIONS_TYPES_LIST(context) {
      let observationsTypesList = [];
      try {
        context.commit('setObservationsTypesListLoading', true);
        observationsTypesList = await AnomalyDetectionService.getObservationsTypes();
      } finally {
        context.commit('setObservationsTypesList', observationsTypesList);
        context.commit('setObservationsTypesListLoading', false);
      }
    },
    async SET_OBSERVATIONS_LIST(
      context,
      {
        equipmentId, rootCauseId, from, to,
      },
    ) {
      let observationsList = [];
      try {
        if (context.state.observationsTypesList.length === 0) {
          await context.dispatch('SET_OBSERVATIONS_TYPES_LIST');
        }
        context.commit('setObservationsLoading', true);
        observationsList = await AnomalyDetectionService.getObservations({
          equipmentId,
          rootCauseId,
          from,
          to,
        });
      } finally {
        context.commit('setObservationsList', observationsList);
        context.commit('setObservationsLoading', false);
      }
    },
    async SET_JOB_FEATURE_ROOT_CAUSES(
      context,
      {
        jobFeatureDescriptionId, observationType,
      },
    ) {
      let jobFeatureRootCauses = [];
      try {
        context.commit('setJobFeatureRootCausesLoading', true);
        jobFeatureRootCauses = await AnomalyDetectionService.getRootCauses({
          jobFeatureDescriptionId,
          observationType,
        });
      } finally {
        context.commit('setJobFeatureRootCausesList', jobFeatureRootCauses);
        context.commit('setJobFeatureRootCausesLoading', false);
      }
    },
    async SET_OBSERVATION_TYPE_ROOT_CAUSE(
      context,
      {
        equipmentId, rootCauseId,
      },
    ) {
      let observationTypeRootCause = [];
      try {
        context.commit('setObservationTypeRootCauseLoading', true);
        observationTypeRootCause = await AnomalyDetectionService.getObservationTypeRootCause({
          equipmentId,
          rootCauseId,
        });
      } finally {
        context.commit('setObservationTypeRootCause', observationTypeRootCause);
        context.commit('setObservationTypeRootCauseLoading', false);
      }
    },
    async GET_ACTION_LIST(
      context,
    ) {
      let actionList = [];
      try {
        context.commit('setActionListLoading', true);
        actionList = await AnomalyDetectionService.getActionList();
      } finally {
        context.commit('setActionList', actionList);
        context.commit('setActionListLoading', false);
      }
    },
  },
};
