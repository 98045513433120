export default {
  resetZoom: 'Reset zoom',
  chartSettings: 'Chart settings',
  exitFullscreen: 'Exit fullscreen',
  fullscreen: 'Fullscreen',
  showPoints: 'Show points',
  showNotes: 'Show notes',
  leaveNote: 'Leave a note (Alt + Click)',
  zoomMode: 'Zoom mode',
  overFlowMenu: 'Additional functions',
};
