import {
  EquipmentService,
} from '../../services/EquipmentService.js';
import EventBus from '@/utils/event-bus';

export default {
  state: {
    certificates: {
      pyrometry: {
        furnaceClass: null,
        instrumentationType: null,
      },
      heatTreatment: [],
      others: [],
    },
    capabilities: {
      gasses: [],
      other: [],
      maxTemp: null,
      minTemp: null,
      dimensionsOfLoad: 'Rectangular',
      diameter: null,
      width: null,
      depth: null,
      height: null,
      maxLoad: null,
    },
    isLoadingCertificates: false,
    isLoadingCapabilities: false,
  },

  getters: {
    overviewCertificates(state) {
      return state.certificates;
    },
    overviewCapabilities(state) {
      return state.capabilities;
    },
    isLoadingOverviewCertificates(state) {
      return state.isLoadingCertificates;
    },
    isLoadingOverviewCapabilities(state) {
      return state.isLoadingCapabilities;
    },
  },

  mutations: {
    setCertificates(state, certificates) {
      if (certificates && !certificates.pyrometry) {
        certificates.pyrometry = {
          furnaceClass: null,
          instrumentationType: null,
        };
      }
      state.certificates = certificates;
    },

    setCapabilities(state, capabilities) {
      state.capabilities = capabilities;
    },

    setIsLoadingCertificates(state, isLoading) {
      state.isLoadingCertificates = isLoading;
    },

    setIsLoadingCapabilities(state, isLoading) {
      state.isLoadingCapabilities = isLoading;
    },

    clearCertificates(state) {
      state.certificates = {
        pyrometry: {
          furnaceClass: null,
          instrumentationType: null,
        },
        heatTreatment: [],
        others: [],
      };
    },

    clearCapabilities(state) {
      state.capabilities = {
        gasses: [],
        other: [],
        maxTemp: null,
        minTemp: null,
        dimensionsOfLoad: 'Rectangular',
        diameter: null,
        width: null,
        depth: null,
        height: null,
        maxLoad: null,
      };
    },
  },

  actions: {
    async UPDATE_ASSET_OVERVIEW(context, payload) {
      const equipment_id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setIsLoadingCertificates', true);
      context.commit('setIsLoadingCapabilities', true);
      await EquipmentService.updateAssetOverview({
        payload,
        equipment_id,
      })
        .then((response) => {
          context.rootState.equipmentStore.selectedEquipment.department = payload.department;
          context.rootState.equipmentStore.selectedEquipment.description = payload.description;
          context.commit('setCapabilities', response.capabilities);
          context.commit('setCertificates', response.certificates);
        })
        .catch((e) => {
          EventBus.$emit('api_error', e);
        })
        .finally(() => {
          context.commit('setIsLoadingCertificates', false);
          context.commit('setIsLoadingCapabilities', false);
        });
    },

    SET_CAPABILITIES_LIST(context) {
      const id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setIsLoadingCapabilities', true);
      context.commit('clearCapabilities');
      EquipmentService.getAssetOverviewCapabilites(id)
        .then((response) => {
          context.commit('setCapabilities', response);
        })
        .catch((e) => {
          EventBus.$emit('api_error', e);
        })
        .finally(() => {
          context.commit('setIsLoadingCapabilities', false);
        });
    },

    SET_CERTIFICATES_LIST(context) {
      const id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setIsLoadingCertificates', true);
      context.commit('clearCertificates');
      EquipmentService.getAssetOverviewCertificates(id)
        .then((response) => {
          context.commit('setCertificates', response);
        })
        .catch((e) => {
          EventBus.$emit('api_error', e);
        })
        .finally(() => {
          context.commit('setIsLoadingCertificates', false);
        });
    },
  },
};
