export const ROUTE_NAME = {
  VISUALIZATION: 'visualization',
  FURNACE_VIEW: 'furnace-view',
  FURNACE_VIEWS: 'FurnaceViews',
  FURNACE: 'furnace-book',
  ALARM_STATISTICS: 'alarm-statistics',
  RECIPE_ON_DEMAND: 'recipe-on-demand',
  RECIPES: 'recipes',
  USER: 'user',
  USER_GROUP: 'user-group',
  USER_ACCESS: 'user-access',
  APPLICATION: 'application',
  APPLICATION_GROUP: 'application-group',
  SMS: 'sms',
  OUTAGE: 'utilization',
  BOX_ADMINISTRATION: 'box',
  COLOR_ADMINISTRATION: 'color',
  FIXTURE_ADMINISTRATION: 'system-data-fixture-administration', // ?
  FIXTURE_TYPES: 'system-data-fixture-types', // ?
  ALARMS: 'alarms',
  TASKS: 'tasks',
  MANAGE_USAGE: 'consumption',
  CUSTOMER_SPECIFIC: 'internal-customer-specific',
  ENGINEERING_UNIT: 'eng-unit',
  UNITS_PER_USER: 'units-per-user',
  WORKPLACE: 'workplace-data',
  WORKPLACE_TYPE: 'workplace-type',
  PE_PROCESS_TYPE: 'pe-process-type',
  PE_ATTRIBUTE_TYPE: 'pe-attribute-type',
  PHYSICAL_UNIT: 'physical-unit',
  TERMINAL_TYPE: 'terminal-type',
  TERMINAL: 'terminal',
  CLIENT_ACCESS: 'client-access',
  BACKGROUND_PROCESS: 'background-process',
  OPERATION: 'operation',
  PROCESS_FLOW: 'process-flow',
  MATERIAL: 'material',
  MATERIAL_GROUPS: 'material-group',
  MATERIAL_NORMS: 'material-norms', // ?
  PART_MANAGEMENT: 'part',
};
