export default {
  title: {
    create: 'New virtual signal',
    edit: 'Signal Settings',
  },
  tabs: {
    signal: 'Virtual signal settings',
    preset: 'Preset settings',
    metadata: 'Signal settings',
  },
};
