import {
  portalV2Api,
} from '@/services/AxiosService';

// TODO: Add docstrings to this once we decide on a docstring format.. Since this is not a typescript repo
export const equipmentGroups = {
  get: async (userId) => {
    const response = await portalV2Api.get(
      `users/${userId}/settings/equipment-groups`,
    );
    return response.data;
  },
  update: async (userId, equipmentGroups) => {
    const response = await portalV2Api.put(
      `users/${userId}/settings/equipment-groups`,
      equipmentGroups,
    );
    return response.data;
  },
};
