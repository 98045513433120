import {
  NITREX_CONNECT_CONNECTIONS_PATH,
} from '../constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const NitrexConnectService = {
  getPlantList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${NITREX_CONNECT_CONNECTIONS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addPlant(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${NITREX_CONNECT_CONNECTIONS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updatePlant(plantId, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${NITREX_CONNECT_CONNECTIONS_PATH}/${plantId}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deletePlant(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${NITREX_CONNECT_CONNECTIONS_PATH}/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
