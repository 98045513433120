import upperFirst from 'lodash/upperFirst';
import config from '@/config/config';

export function setFirstCapitalLetter(word: string, separator: string = '_'): string {
  if (word.length) {
    const lowerCaseWord = word.toLowerCase().split(separator).join(' ');
    return upperFirst(lowerCaseWord);
  }
  return word;
}

export function getAppTitle(): string {
  // Todo: Replace TENANT_NAME with TENANT_ID and TENANT_DISPLAY_NAME
  return `Qmulus (${config.VITE_APP_TENANT_NAME})`;
}
