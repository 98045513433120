// https://stackoverflow.com/a/37796055/8842015
function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

export const getHexColor = (number) => `#${`000000${(number >>> 0).toString(16)}`.slice(-6)}`;
export const getHexFromRGB = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

export const backgroundColors = [
  '#F44336',
  '#FF4081',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];
