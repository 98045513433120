import {
  JobService,
} from '@/services/JobService';

const revalidateTimeRanges = (band, chart) => {
  if (band.timeRanges) {
    const datasets = chart.datasets.filter((ds) => band.sensors?.includes(ds.id));

    band.timeRanges.forEach((range) => {
      const startIndex = chart.labels.findIndex(
        (timestamp) => timestamp >= range.startTime,
      );
      const endIndex = chart.labels.findIndex(
        (timestamp) => timestamp > range.endTime,
      );
      const validDatasets = [];
      datasets.forEach((ds) => {
        if (
          !ds.data
            .slice(startIndex, endIndex)
            .some((value) => value < band.bandMinus || value > band.bandPlus)
        ) {
          validDatasets.push(ds);
        }
      });

      range.sensorsValues = range.sensorsValues.filter((sensor) => band.sensors?.includes(sensor.id));
      range.inBand = datasets.length > 0 && validDatasets.length === datasets.length;
    });
  }
};

export default {
  state: {
    audits: [],
    isLoadingAudits: false,
    activeBandIndex: -1,
    audit: {
      production_logs_audit_id: null,
      user_name: null,
      create_date: null,
      job_id: null,
      location: null,
      comment: '',
      vote: null,
      sensors: [],
      bands: [
        // {
        //   yAxisID: String,
        //   bandMinus: Number,
        //   bandPlus: Number,
        //   sensors: [],
        //   timeRanges: [[Number, Number]],
        // }
      ],
    },
  },

  getters: {
    activeAuditBand(state) {
      if (state.activeBandIndex < 0) {
        return [];
      }
      return state.audit.bands[state.activeBandIndex];
    },
  },

  mutations: {
    addAudit(state, audit) {
      state.audits.push(audit);
    },
    setAudits(state, audits) {
      state.audits = audits;
    },
    setAudit(state, audit) {
      state.audit = audit;
    },
    setAuditComment(state, comment) {
      state.audit.comment = comment;
    },
    setAuditRating(state, rating) {
      state.audit.state = rating;
    },
    setAuditActiveBandIndex(state, bandIndex) {
      state.activeBandIndex = bandIndex;
    },
    addAuditBand(state, payload) {
      state.audit.bands = [
        ...state.audit.bands,
        payload,
      ];
    },
    removeAuditBand(state, {
      index,
    }) {
      state.audit.bands = state.audit.bands.filter((band, i) => index !== i);
    },
    setAuditSensors(state, sensors) {
      state.audit.sensors = sensors;
    },
    removeAuditSensor(state, sensorId) {
      state.audit.sensors = state.audit.sensors.filter((id) => id !== sensorId);
    },
    resetAuditBands(state, payload) {
      state.audit.bands = payload;
    },
    updateAuditBands(state, payload) {
      const {
        index, band,
      } = payload;
      if (index !== -1) {
        const updated = {
          ...state.audit.bands[index],
          ...band,
        };
        const bands = [
          ...state.audit.bands,
        ];
        bands[index] = updated;
        state.audit.bands = bands;
      }
    },
    setIsLoadingAudits(state, payload) {
      state.isLoadingAudits = payload;
    },
  },

  actions: {
    async LOAD_JOB_AUDITS(context, payload) {
      let audits = [];
      try {
        context.commit('setIsLoadingAudits', true);
        audits = await JobService.getAudits(payload);
      } finally {
        context.commit('setAudits', audits);
        context.commit('setIsLoadingAudits', false);
      }
    },
    async SAVE_JOB_AUDIT({
      commit,
    }, payload) {
      try {
        commit('setIsLoadingAudits', true);
        const audit = await JobService.createAudit(payload);
        commit('addAudit', audit);
        commit('setAudit', audit);
      } finally {
        commit('setIsLoadingAudits', false);
      }
    },
    ADD_AUDIT_BAND_SENSOR({
      state, commit,
    }, sensor) {
      if (state.activeBandIndex < 0) {
        return;
      }
      const index = state.activeBandIndex;
      const band = {
        ...state.audit.bands[index],
      };

      band.sensors.push(sensor);

      commit('updateAuditBands', {
        index,
        band,
      });
    },
    REMOVE_AUDIT_BAND_SENSOR({
      state, commit,
    }, sensor) {
      if (state.activeBandIndex < 0) {
        return;
      }
      const index = state.activeBandIndex;
      const band = {
        ...state.audit.bands[index],
      };

      band.sensors = band.sensors.filter((id) => id !== sensor);

      commit('updateAuditBands', {
        index,
        band,
      });
    },
    ADD_AUDIT_TIME_RANGE({
      state, commit,
    }, {
      timeRange,
    }) {
      if (state.activeBandIndex < 0) {
        return;
      }
      const index = state.activeBandIndex;
      const band = {
        ...state.audit.bands[index],
      };

      if (!Array.isArray(band.timeRanges)) {
        band.timeRanges = [];
      }
      const isInvalid = band.timeRanges.reduce((acc, aTimeRange) => {
        if (
          (timeRange.startTime > aTimeRange.startTime
            && timeRange.startTime < aTimeRange.endTime)
          || (timeRange.endTime > aTimeRange.endTime
            && timeRange.startTime < aTimeRange.endTime)
        ) {
          acc = true;
        }
        return acc;
      }, false);

      if (!isInvalid) {
        band.timeRanges.push(timeRange);
        band.timeRanges.sort((a, b) => a.startTime - b.startTime);
      }

      commit('updateAuditBands', {
        index,
        band,
      });
    },
    REMOVE_AUDIT_TIME_RANGE({
      state, commit,
    }, {
      rangeIndex,
    }) {
      if (state.activeBandIndex < 0) {
        return;
      }
      const index = state.activeBandIndex;
      const band = {
        ...state.audit.bands[index],
      };

      band.timeRanges.splice(rangeIndex, 1);

      commit('updateAuditBands', {
        index,
        band,
      });
    },
    SET_AUDIT(context, audit) {
      context.commit('setAudit', audit);
    },
    RESET_AUDIT_BANDS(context, payload = []) {
      if (context.state.audit) {
        context.commit('resetAuditBands', payload);
      }
    },
    UPDATE_AUDIT_BANDS(context, payload) {
      const {
        band, chartData, checkTimeRanges,
      } = payload;

      if (checkTimeRanges) {
        revalidateTimeRanges(band, chartData);
      }

      context.commit('updateAuditBands', payload);
    },
  },
};
