import {
  AxiosError,
  AxiosResponse,
  CanceledError,
} from 'axios';
import {
  ref, shallowRef,
} from 'vue';

type TCallback<RESPONSE_DATA = any, CALLBACK_ARGS = any> = (abortController: AbortController, data?: CALLBACK_ARGS) => Promise<AxiosResponse<RESPONSE_DATA>>;
type TData<RESPONSE_DATA> = Awaited<ReturnType<TCallback<RESPONSE_DATA>>>;

interface IOptions<RESPONSE_DATA = any> {
  /*
   * True: It will refetch if execute() is called. It aborts ongoing requests
   * False: Caches data does not refetch
   */
  shouldBeAborted?: boolean,
  /*
   * If shouldBeAborted === true and throwCancelError === true then a CancelError
   * is thrown in case the request is aborted.
   */
  throwCancelError?: boolean,
  onSuccess?: (data: AxiosResponse<RESPONSE_DATA>) => void,
  onError?: (data: AxiosError<RESPONSE_DATA> | Error) => void,
  /*
   * Works as vue-watcher. If set to true, the data is fetched immediately. Otherwise,
   * data needs to be fetched by calling execute().
   */
  immediate?: boolean,
  /*
   * If value should be reset while waiting for new value
   */
  resetOnLoad?: boolean,
}

export function useAxiosRequest<RESPONSE_DATA = any, CALLBACK_ARGS = any>(callback: TCallback<RESPONSE_DATA, CALLBACK_ARGS>, options: IOptions<RESPONSE_DATA> = {}) {
  const isLoading = ref(false);
  const abortController = shallowRef(new AbortController());
  const data = shallowRef<TData<RESPONSE_DATA> | null>(null);
  const error = shallowRef<AxiosError<RESPONSE_DATA> | Error | null>(null);
  const promise = shallowRef<Promise<AxiosResponse<RESPONSE_DATA>> | null>(null);

  async function execute(args?: CALLBACK_ARGS) {
    if (promise.value && !options.shouldBeAborted) {
      return promise.value;
    }
    isLoading.value = true;
    if (options.shouldBeAborted) {
      abortController.value.abort();
      abortController.value = new AbortController();
      error.value = null;
    }
    if (options.resetOnLoad) {
      data.value = null;
    }

    promise.value = callback(abortController.value, args);
    try {
      data.value = await promise.value!;
      isLoading.value = false;
    } catch (_error) {
      if (!(_error instanceof CanceledError)
        || options.throwCancelError) {
        isLoading.value = false;
        error.value = _error as AxiosError<RESPONSE_DATA> | Error;

        if (options.onError) {
          options.onError(_error as AxiosError<RESPONSE_DATA> | Error);
        }

        throw _error;
      }
    }
    if (options.onSuccess) {
      options.onSuccess(data.value!);
    }
    return data.value!;
  }

  if (options.immediate) {
    execute();
  }

  return {
    isLoading,
    abortController,
    execute,
    data,
    promise,
    error,
  };
}
