import {
  inject,
} from 'vue';
import {
  createAPIClient,
} from '../pt9800/api/api-client';
import * as api from '../pt9800/api/api';
import * as furnaceModule from '../pt9800/store/modules/furnaces.js';
import * as shopLayoutModule from '../pt9800/store/modules/shopLayouts.js';
import * as recipesModule from '../pt9800/store/modules/recipes.js';
import * as userModule from '../pt9800/store/modules/user.js';

const createPT9800 = (options) => {
  const {
    serverURL, store, storeKey,
  } = options;
  // Validation
  if (!serverURL && !options.apiClient) {
    throw new Error('Provide option {serverURL} or {apiClient}');
  }
  if (!store) {
    throw new Error('Provide option {store}');
  }
  if (!storeKey) {
    throw new Error('Provide option {storeKey}');
  }

  return {
    install: (app) => {
      const apiClient = options.apiClient || createAPIClient({
        serverURL,
      });

      const provide = {
        storeKey,
        apiClient,
        services: api.factory(apiClient),
      };

      app.provide('pt9800', provide);
      // eslint-disable-next-line no-param-reassign
      app.config.globalProperties.$pt9800 = provide;

      store.registerModule(storeKey, {
        namespaced: true,
        modules: {
          recipes: recipesModule.createModule(provide.services),
          user: userModule.createModule(provide.services),
        },
      });

      provide.getUserRoutes = async (location) => {
        await store.dispatch(`${storeKey}/user/init`, location);
        return store.getters[`${storeKey}/user/getRoutes`](location);
      };
      provide.getUserPermissions = (location, application) => store.getters[`${storeKey}/user/getPermissions`](location, application);

      provide.unregisterLocation = (locationKey) => {
        const path = [
          storeKey,
          locationKey.toLowerCase(),
        ].join(':');
        if (store.hasModule(path)) {
          store.unregisterModule(path);
        }
      };

      provide.registerLocation = (locationKey, location) => {
        const path = [
          storeKey,
          locationKey.toLowerCase(),
        ].join(':');
        if (!store.hasModule(path)) {
          store.registerModule(path, {
            namespaced: true,
            modules: {
              furnaces: furnaceModule.createModule(provide.services, location),
              shopLayouts: shopLayoutModule.createModule(
                provide.services,
                location,
              ),
            },
          });
        }
        return path;
      };

      provide.getStoreKey = (location = null) => {
        if (typeof location !== 'string') {
          throw new Error('Cannot getStoreKey: Missing location param');
        }
        return provide.registerLocation(location || 'default', location);
      };
    },
  };
};

const usePT9800 = () => inject('pt9800');

const appContextMixin = {
  inject: {
    appContext: {
      from: 'pt9800.context',
    },
  },
  created() {
    if (!this.appContext) {
      throw new Error('Error: pt9800.context not provided');
    }
  },
};

const useAppContext = () => {
  const appContext = inject('pt9800.context');

  if (!appContext) {
    throw new Error('Error: pt9800.context not provided');
  }

  return appContext;
};

export {
  appContextMixin, createPT9800, useAppContext, usePT9800,
};
