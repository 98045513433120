export default {
  headline: 'Edit user',
  permissions: 'Permissions',
  controllerUserMapping: 'Controller user mapping',
  role: 'Roles',
  customPermission: 'Custom Permissions',
  appPermission: 'App Permissions',
  assetPermission: 'Asset Permissions',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  warningHeadline: 'You are loosing access to User Management',
  warningText: 'You are about to lock your self out of the User Management. You may only gain access again, if another Admin grants you access again.',
};
