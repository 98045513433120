import {
  EquipmentService,
} from '@/services/EquipmentService.js';
import EventBus from '@/utils/event-bus';

export default {
  state: {
    components: [],
    isLoadingComponents: false,
    spareParts: [],
    isLoadingSpareParts: false,
    sparePartsList: [],
  },

  mutations: {
    setComponents(state, components) {
      state.components = components;
    },

    setIsLoadingComponents(state, isLoading) {
      state.isLoadingComponents = isLoading;
    },

    setSpareParts(state, spareParts) {
      state.spareParts = spareParts;
    },

    setIsLoadingSpareParts(state, isLoading) {
      state.isLoadingSpareParts = isLoading;
    },

    clearEquipmentMain(state) {
      state.components = [];
      state.spareParts = [];
      state.sparePartsList = [];
    },
    setSparePartsList(state, spareList) {
      state.sparePartsList = [];
      state.sparePartsList = spareList;
    },
  },

  actions: {
    SET_COMPONENTS(context) {
      const id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setIsLoadingComponents', true);
      context.commit('setComponents', []);
      EquipmentService.getEquipmentComponents(id)
        .then((response) => {
          context.commit('setComponents', response);
        })
        .catch(() => {
          EventBus.$emit('show_error');
        })
        .finally(() => {
          context.commit('setIsLoadingComponents', false);
        });
    },

    SET_SPARE_PARTS(context) {
      const id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setIsLoadingSpareParts', true);
      context.commit('setSpareParts', []);
      EquipmentService.getEquipmentSpareParts(id)
        .then((response) => {
          const mappedSpareParts = response.map((sparePart) => {
            return {
              ...sparePart,
              stockLevel: Math.floor(Math.random() * 5),
            };
          });
          context.commit('setSpareParts', mappedSpareParts);
        })
        .catch((e) => {
          EventBus.$emit('api_error', e);
        })
        .finally(() => context.commit('setIsLoadingSpareParts', false));
    },

    SET_SPARE_PARTS_LIST(context) {
      EquipmentService.getSparePartsList()
        .then((response) => {
          context.commit('setSparePartsList', response);
        })
        .catch((e) => {
          EventBus.$emit('api_error', e);
        });
    },
  },
};
