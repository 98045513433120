import {
  components,
} from '@/types/openapi.ts';

export const appTileColorMap: Record<components['schemas']['AppGroupSemantic'], string> = {
  Operation: 'var(--color-120)',
  Data: 'var(--color-218)',
  Maintenance: 'var(--color-189)',
  Process: 'var(--color-130)',
  Configuration: 'var(--color-122)',
};
