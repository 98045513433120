import {
  NPM_CREDENTIALS_PATH,
  NPM_FURNACE_PATH,
} from '@/constants/apiPaths';
import {
  configureRequestConfigForSuppressError,
  portalApiClient,
} from '@/services/AxiosService';

export const NpmService = {
  getCredentials() {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_CREDENTIALS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });
  },
  saveAssetCredentials(systemIdentifier, payload) {
    const {
      customerCode, location, systemId,
    } = systemIdentifier;
    return new Promise((resolve, reject) => {
      portalApiClient
        .put(
          `${NPM_CREDENTIALS_PATH}/${customerCode}/${location}/${systemId}`,
          {
            systemIdentifier: {
              customerCode,
              location,
              systemId,
            },
            ...payload,
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });
  },
  saveCredentials(payload) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .put(`${NPM_CREDENTIALS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });
  },
  validateAssetCredentials(systemIdentifier, {
    userName, password,
  }) {
    const {
      customerCode, location, systemId,
    } = systemIdentifier;
    return new Promise((resolve, reject) => {
      portalApiClient
        .put(`${NPM_CREDENTIALS_PATH}/${customerCode}/${location}/${systemId}/validity`, {
          systemIdentifier: {
            customerCode,
            location,
            systemId,
          },
          userName,
          password,
        }, configureRequestConfigForSuppressError(portalApiClient, 403))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const {
            response,
          } = error;
          const transformedError = response.data?.detail ? new Error(response.data?.detail) : error;
          reject(transformedError);
        });
    });
  },
  furnaceLogin(systemIdentifier, credentials = {}) {
    const {
      customerCode, location, systemId,
    } = systemIdentifier;
    return new Promise((resolve, reject) => {
      const config = configureRequestConfigForSuppressError(portalApiClient, 403);
      if (credentials.userName) {
        config.headers = {};
        config.headers['X-NPM-Authentication'] = btoa([
          credentials.userName,
          credentials.password,
        ].join(':'));
      }
      portalApiClient
        .get(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/login`, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const {
            response,
          } = error;
          const transformedError = response.data?.detail ? new Error(response.data?.detail) : error;
          reject(transformedError);
        });
    });
  },
};
