import {
  acceptHMRUpdate,
  defineStore,
} from 'pinia';
import {
  AxiosResponse,
} from 'axios';
import {
  asyncRequestActionList,
} from './util/request.ts';
import {
  getUnseenCount,
  TGetUnseenCountResponse,
} from '@/generated/axios/openapi/core.ts';

export const useNotificationStore = defineStore('notificationStorage', {
  state: () => ({
    notificationUnreadCount: undefined as TGetUnseenCountResponse | undefined,
    notificationUnreadCountPromise: undefined as Promise<AxiosResponse<TGetUnseenCountResponse>> | undefined,
  }),
  getters: {
    notificationUnreadCountIsLoading: (state) => state.notificationUnreadCountPromise !== undefined,
  },
  actions: {
    async loadNotificationUnreadCount(force?: boolean) {
      return asyncRequestActionList({
        callback: () => getUnseenCount(),
        list: this.notificationUnreadCount,
        promise: this.notificationUnreadCountPromise,
        setList: (data) => {
          this.notificationUnreadCount = data;
        },
        setPromise: (promise) => {
          this.notificationUnreadCountPromise = promise;
        },
        force,
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
