import {
  EquipmentService,
} from '../../services/EquipmentService.js';
import {
  ResponseUtils,
} from '@/utils/ResponseUtils';
import {
  qmulusSdk,
} from '@/services/qmulusService';
import {
  TokenService,
} from '@/services/TokenService';

export const emptyEquipment = {
  id: '',
  equipmentId: '',
  type: '',
  status: '',
  notifications: 0,
  location: '',
  description: '',
  department: '',
  imageURL: '',
};

export default {
  state: {
    equipments: [],
    isLoadingEquipments: false,
    hasEquipments: false,
    selectedEquipment: emptyEquipment,
    authorizedEquipment: false,
    isLoadingAuth: false,
    locationGroup: [],
    isUpdatingGroups: false,
  },

  mutations: {
    /**
     * Set the equipments array
     * @param {Object} state state of the store
     * @param {Array} equipments
     */
    setEquipments(state, equipments) {
      state.hasEquipments = true;
      state.equipments = equipments;
    },

    addEquipment(state, equipment) {
      state.equipments.push(equipment);
    },

    setSelectedEquipment(state, equipment) {
      state.selectedEquipment = equipment;
    },

    setIsLoadingEquipments(state, isLoading) {
      state.isLoadingEquipments = isLoading;
    },

    clearSelectedEquipment(state) {
      state.selectedEquipment = emptyEquipment;
    },

    /**
     * Sets teh equipments state to empty array
     * @param {Object} state
     */
    clearEquipments(state) {
      state.hasEquipments = false;
      state.equipments = [];
    },

    setAuthorizedEquipment(state, isAuthorized) {
      state.authorizedEquipment = isAuthorized;
    },

    setIsLoadingAuth(state, isLoadingAuth) {
      state.isLoadingAuth = isLoadingAuth;
    },

    clearAuthorization(state) {
      state.authorizedEquipment = false;
    },
    setLocationGroups(state, locationGroup) {
      state.locationGroup = locationGroup;
    },
    setIsUpdatingGroups(state, isUpdatingGroups) {
      state.isUpdatingGroups = isUpdatingGroups;
    },
  },

  getters: {
    /**
     * Returns selectedEquipment if param and selectedEquipment have same value, null otherwise
     * @param {String} id
     * @returns {Object | undefined}
     */
    getEquipmentById: (state) => (id) => {
      if (
        state.selectedEquipment !== emptyEquipment
        && id === state.selectedEquipment.id
      ) {
        return state.selectedEquipment;
      }
      return null;
    },

    /**
     * Returns object from equipments with id from params if exists, undefined otherwise
     * @param {String} id
     * @returns {Object |undefined}
     */
    searchEquipmentList: (state) => (id) => {
      return state.equipments.find((equipment) => equipment.id == id);
    },

    /**
     * Checks if equipment array has elements in it
     * @returns {Boolean} true if it has equipments, false otherwise
     */
    hasEquipments: (state) => {
      return state.hasEquipments;
    },
  },

  actions: {
    /**
     * Gets equipments data for table by calling api
     * Sets equipments property
     * @param {String} location specifies location
     */
    SET_EQUIPMENTS(context, location = null) {
      context.commit('clearEquipments');
      return new Promise((resolve, reject) => {
        context.commit('setIsLoadingEquipments', true);
        EquipmentService.getEquipments(location)
          .then((response) => {
            const equipments = response.map((equipment) => {
              return ResponseUtils.mapEquipment(equipment);
            });
            context.commit('setEquipments', equipments);
            resolve();
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => context.commit('setIsLoadingEquipments', false));
      });
    },

    SET_LOCATION_GROUPS(context, location = null) {
      return new Promise(async (resolve, reject) => {
        context.commit('setIsLoadingEquipments', true);
        const {
          id: userId,
        } = await TokenService.decodedIdToken(); // TODO: Figure out a more efficient way of doing this
        await qmulusSdk.users.settings.equipmentGroups
          .get(userId)
          .then((response) => {
            const locationGroup = response.filter(
              ({
                locationId,
              }) => locationId === location,
            );
            context.commit('setLocationGroups', locationGroup);
            resolve();
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => context.commit('setIsLoadingEquipments', false));
      });
    },

    async UPDATE_GROUPS(context, payload) {
      context.commit('setIsUpdatingGroups', true);
      try {
        const {
          id: userId,
        } = await TokenService.decodedIdToken(); // TODO: Figure out a more efficient way of doing this
        await qmulusSdk.users.settings.equipmentGroups.update(userId, payload);
      } catch (e) {
        console.log(e);
        return e.response.status;
      } finally {
        context.commit('setIsUpdatingGroups', false);
      }
    },

    CLEAR_EQUIPMENTS(context) {
      context.commit('clearEquipments');
    },

    GET_EQUIPMENT_AUTHORIZATION(context, equipmentId = null) {
      return new Promise((resolve, reject) => {
        EquipmentService.getEquipmentAuthorization(equipmentId)
          .then((response) => {
            context.commit('setAuthorizedEquipment', response);
            resolve();
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => context.commit('setIsLoadingAuth', false));
      });
    },

    /**
     * Sets selectedEquipment with response from API
     * @param {Object} context
     * @param {String} id
     * @returns {Promise<>}
     */
    SET_SELECTED_EQUIPMENT_REQUEST(context, {
      id, resetCache = false,
    }) {
      context.commit('clearSelectedEquipment');
      return new Promise((resolve, reject) => {
        EquipmentService.getEquipmentById(id, resetCache)
          .then((response) => {
            const mappedEquipment = ResponseUtils.mapEquipment(response);
            context.commit('setSelectedEquipment', mappedEquipment);
            if (!context.state.hasEquipments) {
              context.commit('addEquipment', mappedEquipment);
            }
            resolve();
          })
          .catch((e) => reject(e));
      });
    },

    /**
     *Sets selectedEquipment with value from params
     * @param {*} context
     * @param {Object} equipment
     */
    SET_SELECTED_EQUIPMENT(context, equipment) {
      context.commit('setSelectedEquipment', equipment);
    },
  },
};
