export const productivityChartColors = {
  lost_productive_time: 'red',
  not_realized_by_shift_model: 'grey',
  planned_outage: 'gold',
  productive_time: 'green',
};

export const pieChartColors = {
  available_days_after_planned_outage: 'rgb(42,170,111)',
  availability_in_days: 'rgb(197,42,47)',
  overall_jobs_downtime_days: 'rgb(47,136,225)',
  actual_usage_in_days: 'rgb(204,218,2)',
};

export const historyAndTrendColors = {
  '820e12a2-c70a-11ec-bcc7-021755dd3e8f': 'rgb(10,58,250)',
  '8210486a-c70a-11ec-bcc8-021755dd3e8f': 'rgb(236,17,17)',
  'ac2259d6-c70a-11ec-b3ef-021755dd3e8f': 'rgb(47,208,170)',
  'ac244552-c70a-11ec-b3f0-021755dd3e8f': 'rgb(155,39,39)',
  'ac26329a-c70a-11ec-b3f1-021755dd3e8f': 'rgb(218,252,158)',
  'ac282d70-c70a-11ec-b3f2-021755dd3e8f': 'rgb(210,89,98)',
  'acc2baa2-c70a-11ec-b3f8-021755dd3e8f': 'rgb(76,176,48)',
  'ae246ed6-c70a-11ec-b403-021755dd3e8f': 'rgb(228,88,32)',
  'aee20c2a-c70a-11ec-b40f-021755dd3e8f': 'rgb(112,38,199)',
  'e9550844-c70a-11ec-8b18-021755dd3e8f': 'rgb(27,131,168)',
  'ffb74674-c70a-11ec-9ee8-021755dd3e8f': 'rgb(29,182,93)',
  '7a503c78-c734-11ec-afae-021755dd3e8f': 'rgb(150,51,230)',
  '7a6175ce-c734-11ec-afaf-021755dd3e8f': 'rgb(164,123,200)',
  '7a78f406-c734-11ec-afb0-021755dd3e8f': 'rgb(116,3,77)',
  '7a9ce26c-c734-11ec-afb1-021755dd3e8f': 'rgb(223,243,88)',
  '7aadaf5c-c734-11ec-afb2-021755dd3e8f': 'rgb(46,146,162)',
  '7ad0bc18-c734-11ec-afb3-021755dd3e8f': 'rgb(11,125,218)',
  'edc73806-c7d3-11ec-8a1f-021755dd3e8f': 'rgb(101,237,115)',
  'edd03190-c7d3-11ec-93cf-021755dd3e8f': 'rgb(142,242,23)',
  'eddd4b0a-c7d3-11ec-8a20-021755dd3e8f': 'rgb(114,15,149)',
  'ede65ca4-c7d3-11ec-93d0-021755dd3e8f': 'rgb(155,119,49)',
  '0831fc7e-c894-11ec-98fd-021755dd3e8f': 'rgb(120,253,234)',
  '08340f32-c894-11ec-98fe-021755dd3e8f': 'rgb(250,15,167)',
  '0dd982b4-c894-11ec-a0c7-021755dd3e8f': 'rgb(42,178,69)',
  'ac1ed586-c70a-11ec-b3ee-021755dd3e8f': 'rgb(239,149,22)',
  '0dd72078-c894-11ec-9923-021755dd3e8f': 'rgb(171,225,126)',
  '02be181c-93fc-11ec-a5f6-027b917c2912': 'rgb(182,202,207)',
  '1427e1cc-93fd-11ec-b4bb-027b917c2912': 'rgb(39,234,85)',
  '1428f080-93fd-11ec-b4bc-027b917c2912': 'rgb(99,169,121)',
  'c4a4df9a-940d-11ec-adc5-027b917c2912': 'rgb(117,45,221)',
  'c4a5f592-940d-11ec-adc6-027b917c2912': 'rgb(168,218,233)',
  'e6b85c72-94b9-11ec-bc53-027b917c2912': 'rgb(134,253,148)',
  'e6b94d4e-94b9-11ec-bc54-027b917c2912': 'rgb(42,106,122)',
  '5747065a-94ba-11ec-a316-027b917c2912': 'rgb(8,155,96)',
  'db366c98-94bb-11ec-b338-027b917c2912': 'rgb(74,131,88)',
  '11cfa2e8-94c0-11ec-bc4b-027b917c2912': 'rgb(137,40,66)',
  '53333d90-94d3-11ec-8520-027b917c2912': 'rgb(40,90,9)',
};

export const chartColors = {
  '': 'rgb(25,13,125)',
  '∑': 'rgb(24,223,53)',
  '2354 Not found': 'rgb(37,47,225)',
  '43 ResNTC(Ohm)(JSZ COVER)': 'rgb(3,226,37)',
  '9016 Not found': 'rgb(218,252,158)',
  '9018 Not found': 'rgb(210,89,98)',
  '9022 Not found': 'rgb(76,176,48)',
  '9024 Not found': 'rgb(228,88,32)',
  '9026 Not found': 'rgb(112,38,199)',
  A: 'rgb(27,131,168)',
  'Abs. Press': 'rgb(29,182,93)',
  'ACC Cooling': 'rgb(150,51,230)',
  'ACCUFLO Core Temperature': 'rgb(164,123,200)',
  'ACCUFLO Core Voltage': 'rgb(116,3,77)',
  'ACCUFLO Diff Press': 'rgb(223,243,88)',
  'ACCUFLO Diff pressure': 'rgb(46,146,162)',
  'ACCUFLO FLOW': 'rgb(11,125,218)',
  'ACCUFLO Gage Press': 'rgb(101,237,115)',
  'ACCUFLO Gauge pressure': 'rgb(142,242,23)',
  'ACCUFLO Temperature 1': 'rgb(114,15,149)',
  'ACCUFLO Temperature 2': 'rgb(155,119,49)',
  'ACCUFLO w RAW Diff Press': 'rgb(120,253,234)',
  'ACCUFLO  w RAW Gage Press': 'rgb(250,15,167)',
  'A/C Status': 'rgb(42,178,69)',
  'Activator Pump': 'rgb(182,202,207)',
  'Activator Pump (ml)': 'rgb(39,234,85)',
  'Activator Pump Press': 'rgb(99,169,121)',
  'Activator Pump Press.': 'rgb(117,45,221)',
  'Activator Pump SP': 'rgb(168,218,233)',
  'AFLO FLOW': 'rgb(239,149,22)',
  'AFLO FLOW cnts': 'rgb(134,253,148)',
  'Air Condition': 'rgb(42,106,122)',
  'Air IN': 'rgb(171,225,126)',
  'Air Out': 'rgb(8,155,96)',
  'Alarm Light': 'rgb(74,131,88)',
  'Atm. Air': 'rgb(137,40,66)',
  'Atm. CO': 'rgb(40,90,9)',
  'Atm. dNH3': 'rgb(79,190,196)',
  'Atm. ENDO': 'rgb(50,126,172)',
  'ATM ENG': 'rgb(71,93,178)',
  'Atm. KnCtrl': 'rgb(39,39,95)',
  'Atm. N2O': 'rgb(140,192,78)',
  'Atm.NH3': 'rgb(30,189,135)',
  'AUX Exhaust': 'rgb(76,169,250)',
  'Aux TC 1': 'rgb(96,59,36)',
  'Aux TC 1 ambient': 'rgb(33,243,128)',
  'Aux TC 1 lop': 'rgb(163,210,197)',
  'Aux TC 2': 'rgb(18,135,87)',
  'Backup Filter Closed': 'rgb(206,74,85)',
  'Block Temp': 'rgb(56,238,251)',
  'Blower Ramp': 'rgb(236,243,27)',
  Buzzer: 'rgb(30,235,90)',
  'Bypass ON': 'rgb(2,246,184)',
  'Bypass OPEN': 'rgb(105,125,229)',
  C: 'rgb(27,19,180)',
  '∑ C3H8 Flow': 'rgb(68,23,159)',
  'C3H8 Flow': 'rgb(61,29,224)',
  'C3H8 Flow Out': 'rgb(137,95,174)',
  CComp: 'rgb(190,38,49)',
  'Circ. Fan': 'rgb(134,176,165)',
  CO: 'rgb(137,83,38)',
  CO2: 'rgb(94,123,177)',
  'CO2 Analyzer': 'rgb(209,216,165)',
  '∑ CO2 Flow': 'rgb(196,199,248)',
  'CO2 Flow': 'rgb(250,240,183)',
  'CO2 SP': 'rgb(149,28,23)',
  'CO Analyzer': 'rgb(182,89,128)',
  '∑ CO Flow': 'rgb(149,193,46)',
  'CO Flow': 'rgb(3,128,72)',
  'Control Voltage': 'rgb(243,85,164)',
  'Conv Fan': 'rgb(169,54,160)',
  'Conv.Fan': 'rgb(26,69,113)',
  'Cooling Blower': 'rgb(237,177,67)',
  'Cooling Blower 1': 'rgb(107,74,119)',
  'Cooling Blower 2': 'rgb(86,152,135)',
  'Cooling Gate': 'rgb(220,231,48)',
  'Cooling Ramp': 'rgb(105,167,200)',
  'Cooling Water': 'rgb(32,27,105)',
  'CO SP': 'rgb(25,249,21)',
  Cover: 'rgb(121,237,112)',
  'Cover ACK': 'rgb(14,229,107)',
  'Cover Ack SW': 'rgb(201,60,225)',
  'Cover Closed': 'rgb(153,247,82)',
  'Cover Enable': 'rgb(94,181,156)',
  'CPU temp': 'rgb(87,135,25)',
  D: 'rgb(49,184,140)',
  'D-Agent Pump': 'rgb(247,14,125)',
  'D-C3H8 Flow': 'rgb(175,46,168)',
  'D-CO': 'rgb(223,165,23)',
  'D-CO2': 'rgb(206,245,0)',
  'D-CO2 Flow': 'rgb(56,93,156)',
  'D-CO2  Flow': 'rgb(228,201,92)',
  'D-CO Flow': 'rgb(134,180,149)',
  'D-Diss': 'rgb(143,164,173)',
  'D-Diss 2': 'rgb(163,122,122)',
  'D-Diss A': 'rgb(73,245,169)',
  'D-Diss B': 'rgb(0,180,29)',
  'D-dNH3 A': 'rgb(50,175,204)',
  'D-dNH3 B': 'rgb(197,73,13)',
  'D-dNH3 Flow': 'rgb(90,247,209)',
  'D-ENDO Flow': 'rgb(250,131,137)',
  'D-Flow': 'rgb(143,228,44)',
  'D-INJ': 'rgb(11,42,252)',
  Dissipation: 'rgb(179,30,187)',
  'Dissipation 43(JSZ COVER)': 'rgb(146,20,208)',
  'Dissipation (JSZ COVER)': 'rgb(142,187,30)',
  'Dissipation (JSZ EXH.)': 'rgb(96,211,217)',
  Dissociation: 'rgb(207,157,136)',
  'Dissociation 1': 'rgb(98,196,187)',
  'Dissociation 2': 'rgb(142,45,204)',
  'Dissociation 2 H2Smart': 'rgb(127,230,56)',
  'Dissociation 2nd': 'rgb(94,86,237)',
  'Dissociation 43(JSZ EXH.2)': 'rgb(51,169,18)',
  'Dissociation A': 'rgb(211,15,29)',
  'Dissociation B': 'rgb(127,20,84)',
  'Dissociation DP80': 'rgb(63,149,250)',
  'Dissociation H2Smart': 'rgb(145,37,7)',
  'Dissociation H2 Smart': 'rgb(144,213,50)',
  'Dissociation (JSZ COVER)': 'rgb(165,66,70)',
  'Dissociation (JSZ EXH.)': 'rgb(107,212,20)',
  Dissociator: 'rgb(127,224,180)',
  'Dissociator Temp': 'rgb(139,138,131)',
  'Diss. Status': 'rgb(238,210,43)',
  'Diss Temp': 'rgb(128,114,12)',
  'D-Kc': 'rgb(161,64,170)',
  'D-Kn': 'rgb(247,63,79)',
  'D-Kn2': 'rgb(173,55,144)',
  'D-N2 A': 'rgb(251,89,156)',
  'D-N2A Flow': 'rgb(254,178,51)',
  'D-N2B Flow': 'rgb(233,202,188)',
  'D-N2 Flow': 'rgb(43,63,92)',
  'D-N2O Flow': 'rgb(59,98,149)',
  'D-N2O  Flow': 'rgb(127,109,94)',
  dNH3: 'rgb(107,72,166)',
  'D-NH3 A': 'rgb(229,62,10)',
  'D-NH3A Flow': 'rgb(212,23,92)',
  'D-NH3 B': 'rgb(153,5,11)',
  'D-NH3 B Flow': 'rgb(234,29,8)',
  'D-NH3B Flow': 'rgb(97,149,34)',
  'dNH3-B SP': 'rgb(103,182,193)',
  'D-NH3C Flow': 'rgb(57,153,225)',
  '∑ dNH3 Flow': 'rgb(58,14,160)',
  'dNH3 Flow': 'rgb(220,77,121)',
  'D-NH3 Flow': 'rgb(218,221,131)',
  'dNH3 Flow -A': 'rgb(130,233,166)',
  'dNH3 Flow -B': 'rgb(180,239,58)',
  'dNH3 Flow-B DP40': 'rgb(227,38,36)',
  'dNH3 Flow PLANT': 'rgb(107,46,111)',
  'dNH3 SP': 'rgb(173,196,111)',
  'Drain Pump': 'rgb(174,105,7)',
  'Dryer Satus': 'rgb(204,218,2)',
  'D-T': 'rgb(173,77,205)',
  'D-Temp': 'rgb(181,9,143)',
  'D-Total Flow': 'rgb(114,173,217)',
  Emergency: 'rgb(94,61,144)',
  '∑ EM N2': 'rgb(195,52,204)',
  'EM N2': 'rgb(158,43,166)',
  Endo: 'rgb(149,231,119)',
  '∑ Endo Flow': 'rgb(134,109,30)',
  'Endo Flow': 'rgb(157,34,147)',
  'Endo SP': 'rgb(225,88,165)',
  'E-STOP': 'rgb(114,174,0)',
  Exhaust: 'rgb(14,128,158)',
  'Exhaust small': 'rgb(235,205,149)',
  'Exh.Bypass': 'rgb(105,139,166)',
  'Fce N2O': 'rgb(64,63,137)',
  'Filt. Blocked': 'rgb(243,154,63)',
  'Filt.Bypassed': 'rgb(74,118,71)',
  'Filter Bypass': 'rgb(190,37,85)',
  'Filter Installed': 'rgb(32,134,210)',
  'Filter Press. 1': 'rgb(61,0,80)',
  'Filter Purged': 'rgb(64,135,15)',
  'Filters Ready': 'rgb(11,175,163)',
  'Flap Contrl': 'rgb(145,149,75)',
  'FLTR EXH.': 'rgb(100,94,35)',
  'Furnace Air': 'rgb(71,136,217)',
  'Furnace C3H8': 'rgb(111,119,65)',
  'Furnace CO': 'rgb(154,133,36)',
  'Furnace CO2': 'rgb(163,61,21)',
  'Furnace dNH3': 'rgb(136,145,175)',
  'Furnace Endo': 'rgb(42,228,212)',
  'Furnace N2': 'rgb(129,235,232)',
  'Furnace N2O': 'rgb(1,219,239)',
  'Furnace NH3': 'rgb(77,245,61)',
  'Furnace Removed': 'rgb(190,239,120)',
  'Furnace VAC': 'rgb(43,219,112)',
  'Furn. O/H': 'rgb(162,135,43)',
  'Furn.Press': 'rgb(19,88,222)',
  'Furn. Press': 'rgb(74,19,176)',
  'Furn.Press Aux': 'rgb(128,111,94)',
  'Furn.Press Turbo': 'rgb(91,61,233)',
  'Furn. PS': 'rgb(6,84,245)',
  'Gas Ballast': 'rgb(114,127,56)',
  'Gate Closed': 'rgb(203,71,110)',
  'Gate Open': 'rgb(76,213,100)',
  'H2 Analyzer': 'rgb(103,36,146)',
  'H2 Analyzer Flow': 'rgb(76,166,71)',
  'H2 Analyzer Flow 2': 'rgb(122,88,101)',
  'H2 Analyzer Flow DP80': 'rgb(172,60,133)',
  'H2 Analyzer Flow (H2S)': 'rgb(145,237,210)',
  'H2 Analyzer Smart': 'rgb(39,140,103)',
  'H2O Level Min': 'rgb(254,202,27)',
  'H2S Ambient Temp.': 'rgb(209,46,30)',
  'H2S Ambient Temp. 2': 'rgb(13,229,242)',
  'H2S Ambient Temp. 2 (H2 Smart)': 'rgb(176,222,66)',
  'H2S Ambient Temp. 43(JSZ COVER)': 'rgb(42,68,49)',
  'H2S Ambient Temp. DP80': 'rgb(92,74,96)',
  'H2S Ambient Temp. (H2S)': 'rgb(180,226,138)',
  'H2S Ambient Temp. (H2S 2)': 'rgb(139,144,39)',
  'H2S Ambient Temp. (H2 Smart)': 'rgb(1,77,110)',
  'H2S Ambient Temp. (JSZ COVER)': 'rgb(95,238,230)',
  'H2S Ambient Temp. (JSZ EXH.)': 'rgb(36,249,8)',
  'H2S Block Control': 'rgb(4,195,61)',
  'H2S Block Temp.': 'rgb(73,239,227)',
  'H2S Block Temp. 2': 'rgb(106,173,95)',
  'H2S Block Temp. 2 (H2 Smart)': 'rgb(250,44,193)',
  'H2S Block Temp. 43(JSZ COVER)': 'rgb(216,170,155)',
  'H2S Block Temp. DP80': 'rgb(233,120,205)',
  'H2S Block Temp. (H2S)': 'rgb(143,18,116)',
  'H2S Block Temp. (H2S 2)': 'rgb(57,186,156)',
  'H2S Block Temp. (H2 Smart)': 'rgb(40,117,71)',
  'H2S Block Temp. (JSZ COVER)': 'rgb(217,99,88)',
  'H2S Block Temp. (JSZ EXH.)': 'rgb(227,45,31)',
  'H2S Flow Alarm': 'rgb(227,226,67)',
  'H2S Pump Alarm': 'rgb(221,46,151)',
  'H2S Pump Control': 'rgb(253,153,183)',
  'H2S Pump Control 43 Block (JSZ EXH.2)': 'rgb(112,102,36)',
  'H2S Pump Control Block': 'rgb(54,204,19)',
  'H2S Pump Control Block 2': 'rgb(6,143,37)',
  'H2S Pump Control Block 2 (H2 Smart)': 'rgb(70,104,85)',
  'H2S Pump Control Block DP80': 'rgb(243,216,94)',
  'H2S Pump Control Block (H2S)': 'rgb(241,234,198)',
  'H2S Pump Control Block (H2S 2)': 'rgb(209,239,0)',
  'H2S Pump Control Block (H2 Smart)': 'rgb(138,160,68)',
  'H2S Pump Control Block (JSZ COVER)': 'rgb(189,41,92)',
  'H2S Pump Control Block (JSZ EXH.)': 'rgb(16,145,175)',
  'H2S Pump Control Pump': 'rgb(111,212,90)',
  'H2S Pump Control Pump 2': 'rgb(130,114,23)',
  'H2S Pump Control Pump 2 (H2 Smart)': 'rgb(1,116,229)',
  'H2S Pump Control Pump DP80': 'rgb(81,116,120)',
  'H2S Pump Control Pump (H2 Smart)': 'rgb(137,195,215)',
  'Heater 1': 'rgb(12,178,166)',
  'Heater 1 TC': 'rgb(17,14,153)',
  'Heater 2 TC': 'rgb(173,184,44)',
  'Heater 3 TC': 'rgb(192,174,39)',
  'Heater 4 TC': 'rgb(140,196,222)',
  'Heating Enable': 'rgb(90,188,19)',
  'Heating Ramp': 'rgb(174,220,199)',
  'Heat Status': 'rgb(152,133,104)',
  HT1: 'rgb(55,17,56)',
  'HT1 (Exhaust)': 'rgb(7,204,14)',
  'HT1 EXT.': 'rgb(69,183,202)',
  'HT1 INT.': 'rgb(4,170,174)',
  'HT1 (Sampling)': 'rgb(172,196,49)',
  HT2: 'rgb(25,85,172)',
  'HT2 EXT.': 'rgb(27,87,155)',
  'HT2 INT.': 'rgb(22,252,131)',
  'HT2 (Sampling)': 'rgb(112,108,67)',
  HT3: 'rgb(203,180,55)',
  'HT3 Cover': 'rgb(128,14,67)',
  'HT3 Cover EXT.': 'rgb(22,153,251)',
  'HT3 Cover INT.': 'rgb(223,31,83)',
  'HT Enable': 'rgb(97,158,99)',
  'HT Zone 1': 'rgb(171,74,225)',
  'HT Zone 1 Exhaust': 'rgb(174,215,212)',
  'HT Zone 1 (Exhaust)': 'rgb(235,220,98)',
  'HT Zone 1 (Sampling)': 'rgb(56,115,151)',
  'HT Zone 2': 'rgb(193,131,206)',
  'HT Zone 2 (Exhaust)': 'rgb(74,219,14)',
  'HT Zone 2 (Sampling)': 'rgb(95,116,211)',
  'HT Zone 3': 'rgb(222,234,227)',
  'HT Zone 4': 'rgb(32,121,221)',
  'Injection Agent Low': 'rgb(215,69,55)',
  'Injection Alarm': 'rgb(60,18,32)',
  'Injection Alarm SP': 'rgb(150,79,108)',
  'Injection Low': 'rgb(11,227,129)',
  'Injection Pressure': 'rgb(242,252,17)',
  'Injection Volume': 'rgb(31,215,3)',
  'Inlet Air': 'rgb(47,185,207)',
  'Inlet CO2': 'rgb(222,62,19)',
  'Inlet dNH3': 'rgb(179,218,103)',
  'Inlet Endo': 'rgb(118,93,63)',
  'Inlet N2': 'rgb(47,136,225)',
  'Inlet N2O': 'rgb(87,174,186)',
  'Inlet NH3': 'rgb(234,219,194)',
  'In Load TC': 'rgb(54,114,147)',
  'In Standby': 'rgb(23,124,160)',
  'IntTemp C N2 Waukee': 'rgb(69,136,199)',
  'IntTemp C NH3 Waukee': 'rgb(128,182,215)',
  'IntTemp F N2 Waukee': 'rgb(88,127,77)',
  'IntTemp F NH3 Waukee': 'rgb(67,71,201)',
  'IO Bus': 'rgb(121,235,14)',
  'IO Panel': 'rgb(57,108,116)',
  'IO Panel 10': 'rgb(180,47,2)',
  'IO Panel 3': 'rgb(173,76,192)',
  'Job Time': 'rgb(147,250,64)',
  Kc: 'rgb(250,93,61)',
  'Kc 2': 'rgb(232,36,200)',
  'Kc PE': 'rgb(117,146,147)',
  Kn: 'rgb(39,146,67)',
  Kn2: 'rgb(128,102,99)',
  'Kn 2': 'rgb(90,51,208)',
  'Kn2 VLP': 'rgb(51,139,181)',
  'Kn A': 'rgb(162,113,176)',
  'Kn B': 'rgb(75,53,86)',
  'Kn EFF': 'rgb(245,32,193)',
  'Kn-EFF': 'rgb(159,87,163)',
  'Kn PEG': 'rgb(53,65,100)',
  'Kn-PEG': 'rgb(206,33,11)',
  Ko: 'rgb(187,125,72)',
  'LogO2(VLP1)': 'rgb(31,232,138)',
  'Main Exh': 'rgb(244,62,170)',
  'MAIN EXH.': 'rgb(191,40,155)',
  'Main TC': 'rgb(201,234,252)',
  'Main TC MOXA': 'rgb(232,32,211)',
  'Main TC panel': 'rgb(227,176,201)',
  'Main TC SP': 'rgb(6,128,183)',
  'MGS Block Pressure': 'rgb(18,208,6)',
  'MGS Block Temperature': 'rgb(211,171,19)',
  'MGS H2': 'rgb(209,214,80)',
  'MGS NH3': 'rgb(132,164,244)',
  'MGS Power 1.5V': 'rgb(189,251,201)',
  'MGS Power 1.8V': 'rgb(76,118,65)',
  N2: 'rgb(150,253,236)',
  N20: 'rgb(207,191,14)',
  'N2-B': 'rgb(200,132,106)',
  '∑ N2 Backfill': 'rgb(183,37,253)',
  'N2 Backfill': 'rgb(187,15,51)',
  'N2-B SP': 'rgb(66,8,30)',
  'N2-B SP Waukee': 'rgb(73,169,177)',
  '∑ N2 Flow': 'rgb(20,42,26)',
  'N2 Flow': 'rgb(89,249,163)',
  'N2 Flow-B': 'rgb(166,215,73)',
  'N2 Flow-B Waukee': 'rgb(19,178,220)',
  'N2 Flow WAUKEE FTP': 'rgb(195,106,220)',
  'N2O Flow': 'rgb(210,243,205)',
  'N2O SP': 'rgb(98,81,87)',
  'N2 Purge Valve': 'rgb(105,47,93)',
  'N2 SP': 'rgb(142,175,111)',
  'N2 SP Retransmitted': 'rgb(105,240,231)',
  Neutralizer: 'rgb(56,99,211)',
  'Neutraliz.TC': 'rgb(147,130,188)',
  Nf: 'rgb(89,23,191)',
  NH3: 'rgb(127,22,239)',
  'NH3-B': 'rgb(99,254,214)',
  '∑ NH3 Backfill': 'rgb(191,98,75)',
  'NH3 Backfill': 'rgb(18,225,140)',
  'NH3-B SP': 'rgb(227,190,184)',
  'NH3-B SP Waukee': 'rgb(218,215,72)',
  'NH3-C': 'rgb(213,26,244)',
  'NH3-C sp': 'rgb(251,196,17)',
  '∑ NH3 Flow': 'rgb(73,125,26)',
  'NH3 Flow': 'rgb(98,14,254)',
  'NH3 Flow -A': 'rgb(161,126,129)',
  'NH3 Flow-B': 'rgb(140,238,148)',
  'NH3 Flow-B DP80': 'rgb(21,197,239)',
  'NH3 Flow-B W': 'rgb(125,107,86)',
  'NH3 Flow-B Waukee': 'rgb(225,24,39)',
  '∑ NH3 Flow-C': 'rgb(164,57,187)',
  'NH3 Flow-C': 'rgb(155,187,246)',
  'NH3 Flow w': 'rgb(162,47,141)',
  'NH3 Flow Wauke': 'rgb(188,114,143)',
  'NH3 SP': 'rgb(27,27,155)',
  'NH3 SP w': 'rgb(195,59,1)',
  'No. Stages': 'rgb(239,20,209)',
  '% O2': 'rgb(143,28,209)',
  'O2 Monitor': 'rgb(179,240,188)',
  'O2 mV': 'rgb(10,90,102)',
  'O2 Probe mV': 'rgb(158,158,235)',
  'O2 Probe mV (Lambda)': 'rgb(78,226,247)',
  'O2 probe Temp.': 'rgb(197,39,147)',
  'O2 Probe Temp': 'rgb(104,82,135)',
  'O2 T': 'rgb(226,183,10)',
  'O2 Temp': 'rgb(122,25,247)',
  ONC: 'rgb(12,155,110)',
  'ONC waste': 'rgb(176,144,95)',
  'ONC Water Inj': 'rgb(130,69,142)',
  'On Load TC': 'rgb(155,104,23)',
  Open: 'rgb(32,197,214)',
  'Overheat Temp': 'rgb(118,44,92)',
  'Panel Temp': 'rgb(115,229,211)',
  'Phase Status': 'rgb(146,240,79)',
  'P/O No': 'rgb(158,132,8)',
  Power: 'rgb(49,109,12)',
  '∑ Power 1': 'rgb(229,102,130)',
  'Power 1': 'rgb(178,70,246)',
  'Power 1 PT400': 'rgb(178,77,31)',
  '∑ Power 2': 'rgb(91,8,132)',
  'Power 2': 'rgb(153,102,234)',
  '∑ Power 3': 'rgb(5,214,126)',
  'Power 3': 'rgb(160,136,209)',
  '∑ Power 4': 'rgb(51,190,220)',
  'Power 4': 'rgb(190,116,182)',
  '∑ Power 5': 'rgb(183,157,118)',
  'Power 5': 'rgb(8,44,46)',
  '∑ Power 6': 'rgb(134,222,207)',
  'Power 6': 'rgb(19,73,13)',
  'pp CH4': 'rgb(63,171,138)',
  'pp CO': 'rgb(212,46,158)',
  'pp CO2': 'rgb(67,170,60)',
  'pp H2': 'rgb(109,129,250)',
  'pp H2O': 'rgb(251,192,162)',
  'pp N2': 'rgb(224,22,71)',
  'pp NH3': 'rgb(80,164,87)',
  'pp O2': 'rgb(231,55,222)',
  'Press Ramp': 'rgb(17,149,63)',
  'Pulse Time Fl.Ref': 'rgb(186,248,54)',
  'Pulse Time Fl.Sensor': 'rgb(140,121,123)',
  'Pulse Time Resistor': 'rgb(216,69,173)',
  'Pump  Thresh.': 'rgb(35,45,76)',
  'Purge Complete': 'rgb(125,232,23)',
  'Purged Volume': 'rgb(114,158,38)',
  'Purge Flow': 'rgb(232,112,226)',
  'Raw Flow': 'rgb(189,158,54)',
  'Recirc Fan 1-RPM': 'rgb(9,140,64)',
  'Recirc Fan 2-RPM': 'rgb(13,54,64)',
  'Recirc Fan 3-RPM': 'rgb(113,176,238)',
  Reset: 'rgb(37,17,135)',
  'ResNTC(Ohm)(JSZ COVER)': 'rgb(242,109,101)',
  'ResNTC(Ohm)(JSZ EXH.)': 'rgb(158,153,108)',
  Running: 'rgb(222,31,198)',
  Sampling: 'rgb(180,24,246)',
  'Sampling Alarm': 'rgb(187,129,77)',
  'Sampling Enable': 'rgb(182,185,87)',
  'Sampling Enable A': 'rgb(226,204,123)',
  'Sampling Enable B': 'rgb(197,42,47)',
  'Sampling Fl': 'rgb(95,191,84)',
  'Sampling Fl 1': 'rgb(155,139,114)',
  'Sampling Fl 2': 'rgb(88,127,27)',
  'Sampling Fl 2nd': 'rgb(45,36,168)',
  'Sampling Fl 43(JSZ COVER)': 'rgb(251,233,121)',
  'Sampling Fl (H2 Smart)': 'rgb(64,89,41)',
  'Sampling Fl (H2 Smart2)': 'rgb(42,199,211)',
  'Sampling Fl (JSZ COVER)': 'rgb(87,45,76)',
  'Sampling ON': 'rgb(89,20,185)',
  'Sampling Warning': 'rgb(119,228,48)',
  'Sampl. Temp': 'rgb(111,188,226)',
  'SCR O/H': 'rgb(119,136,222)',
  'SGS O2mV': 'rgb(129,150,32)',
  'SGS O2Tc': 'rgb(106,186,93)',
  'Shared N2&NH3': 'rgb(142,13,229)',
  'Stage Time': 'rgb(14,144,215)',
  Status: 'rgb(54,217,76)',
  'Status PLC': 'rgb(109,33,8)',
  'Status PURGE': 'rgb(212,126,160)',
  'Steam 1': 'rgb(7,134,57)',
  'Steam 2': 'rgb(105,214,211)',
  'Steam Enable': 'rgb(7,120,59)',
  'Steam On': 'rgb(211,140,110)',
  'Steam Valve': 'rgb(27,18,82)',
  'Temp OH': 'rgb(179,224,73)',
  'Total Flow': 'rgb(96,45,118)',
  Totalizer: 'rgb(221,179,100)',
  Turbine: 'rgb(122,46,205)',
  'Turbine SP': 'rgb(98,82,217)',
  'Turbo Gas IN': 'rgb(55,246,110)',
  'Turbo Gas OUT': 'rgb(229,150,80)',
  'Turbo Motor': 'rgb(217,207,103)',
  'Turbo Pressure Kn PEG': 'rgb(85,13,39)',
  'Turbo RPM': 'rgb(27,5,211)',
  'Turbo Water IN': 'rgb(79,102,235)',
  'Turbo Water OUT': 'rgb(215,42,168)',
  'VAC-Medium': 'rgb(201,234,252)',
  'VAC. Pump': 'rgb(14,88,104)',
  'Vac.Ramp': 'rgb(184,32,140)',
  'Vac. Valve': 'rgb(26,68,20)',
  'Vac.Valve In': 'rgb(29,141,222)',
  'Vac.Valve Out': 'rgb(230,112,76)',
  'Water Valve': 'rgb(214,28,83)',
  'WCS_ACC Status': 'rgb(17,163,47)',
  'Zone1 TC': 'rgb(167,19,129)',
  'Zone1 TC MOXA': 'rgb(42,170,111)',
  'Zone1 TC PT400-sp': 'rgb(61,162,248)',
  'Zone1 TC Spare': 'rgb(55,229,164)',
  'Zone1 TC T1': 'rgb(116,36,53)',
  'Zone1 TC T2': 'rgb(172,228,48)',
  'Zone1 TC T3': 'rgb(35,213,186)',
  'Zone2 TC': 'rgb(209,239,0)',
  'Zone2 TC MOXA': 'rgb(168,48,174)',
  'Zone3 TC': 'rgb(27,5,211)',
  'Zone3 TC MOXA': 'rgb(128,123,160)',
  'Zone4 TC': 'rgb(43,148,87)',
  'Zone5 TC': 'rgb(233,11,63)',
  'Zone6 TC': 'rgb(61,157,106)',
  // below are labels and colors from pt9800 (b_pen_definition table)
  SCR3: 'rgb(0,0,0)',
  SCR2: 'rgb(0,0,0)',
  SCR1: 'rgb(0,0,0)',
  Flow: 'rgb(0,32,3)',
  DNH3Flow: 'rgb(191,64,200)',
  NH3Flow: 'rgb(0,0,0)',
  N2Flow: 'rgb(1,172,4)',
  Press: 'rgb(124,135,151)',
  IntTemp: 'rgb(0,0,4)',
  Zone5T: 'rgb(0,0,0)',
  Zone4T: 'rgb(0,0,0)',
  Zone3T: 'rgb(0,0,0)',
  Zone2T: 'rgb(0,4,31)',
  Zone1Temp: 'rgb(0,0,0)',
  HCLeak: 'rgb(0,136,4)',
  CCLeak: 'rgb(0,4,255)',
  SetPoint: 'rgb(0,112,255)',
  '%YOut': 'rgb(216,191,151)',
  'Oil Pump': 'rgb(4,255,4)',
  'S Oil': 'rgb(0,0,112)',
  'N Oil': 'rgb(112,255,255)',
  'FCE Fan': 'rgb(0,112,255)',
  OUT_INT_CJ_Temp: 'rgb(160,191,127)',
  'H2O Pres': 'rgb(0,4,255)',
  'Cp Y%': 'rgb(0,0,0)',
  'Oil Y%': 'rgb(112,255,255)',
  'Fce Y%': 'rgb(4,255,255)',
  TC3: 'rgb(4,255,4)',
  'Cond.': 'rgb(4,255,255)',
  TTQ: 'rgb(4,255,4)',
  TC1: 'rgb(0,0,112)',
  'Oil Temp': 'rgb(0,0,112)',
  pH: 'rgb(4,255,4)',
  'Cp Temp': 'rgb(216,191,151)',
  'Oil SP': 'rgb(0,0,4)',
  'Fce SP': 'rgb(0,0,112)',
  'AI 4': 'rgb(0,4,255)',
  'AI 3': 'rgb(0,112,255)',
  'AI 2': 'rgb(4,255,255)',
  'CC PI227': 'rgb(0,4,255)',
  'CC PI170': 'rgb(84,71,87)',
  'HC PI221': 'rgb(0,4,255)',
  'HC PI160': 'rgb(84,71,87)',
  'TC 14': 'rgb(84,71,87)',
  'TC 13': 'rgb(0,0,0)',
  'TC 11': 'rgb(0,0,112)',
  'O2 Probe': 'rgb(136,135,255)',
  'TC 9': 'rgb(112,255,255)',
  'TC 7': 'rgb(0,112,255)',
  'Cp SP': 'rgb(0,0,112)',
  'Cp PV': 'rgb(112,255,255)',
  'TC 5': 'rgb(0,0,0)',
  'TC 3': 'rgb(0,0,112)',
  'TC 4': 'rgb(0,0,4)',
  Oil_SP: 'rgb(0,0,112)',
  Temp_SP: 'rgb(0,4,255)',
  'Fce Temp': 'rgb(0,0,4)',
  'CC Time': 'rgb(0,0,4)',
  CCLeakSP: 'rgb(84,71,87)',
  'HC Time': 'rgb(32,31,31)',
  HCLeakSP: 'rgb(4,255,255)',
  IntTempS: 'rgb(0,0,112)',
  'Kn-SP': 'rgb(112,255,255)',
  'HJP 100': 'rgb(255, 20, 147)',
  'Diffusion Depth': 'rgb(48,165,255)',
  FU_3_Oel: 'rgb(109,146,225)',
  Oel_Bad: 'rgb(137,176,190)',
  A1_Innen: 'rgb(247,167,181)',
  Zone2: 'rgb(165,145,39)',
  FU_2_Oel: 'rgb(87,246,23)',
  ChargeSW: 'rgb(157,36,194)',
  A2_Zone1: 'rgb(136,103,220)',
  CP: 'rgb(249,247,5)',
  FU_4_Oel: 'rgb(98,236,179)',
  'mV-Sonde': 'rgb(97,35,239)',
  'mV CS87': 'rgb(180,197,154)',
  'Meth-Ch': 'rgb(37,68,235)',
  'Luft-Ch': 'rgb(221,196,139)',
  'CP SW': 'rgb(28,145,181)',
  Charge: 'rgb(114,41,99)',
  FU_1_Oel: 'rgb(242,138,134)',
  'kW-Ch': 'rgb(146,190,83)',
  A1_Zone1: 'rgb(185,125,217)',
  FU_1_4_S: 'rgb(104,194,44)',
  Sonde: 'rgb(235,172,168)',
  A2_Zone2: 'rgb(166,146,230)',
  'Prop-Ch': 'rgb(147,241,120)',
  Heizung: 'rgb(52,118,95)',
  TempCS87: 'rgb(26,93,176)',
  Zone1: 'rgb(7,97,202)',
  Ölbad: 'rgb(67,208,112)',
  'mV CS88': 'rgb(77,152,94)',
  Luft: 'rgb(193,171,253)',
  Propan: 'rgb(146,98,104)',
  TempCS88: 'rgb(20,254,196)',
  FU_2_3_S: 'rgb(236,147,105)',
  Methanol: 'rgb(163,62,113)',
  Zone3: 'rgb(126,251,148)',
  KaskSW: 'rgb(215,70,66)',
  A2_Innen: 'rgb(66,62,130)',
  'CP O2': 'rgb(118,144,79)',
  Oel_B_Re: 'rgb(253,33,152)',
  'N2-Ch': 'rgb(49,22,160)',
  A1_Zone2: 'rgb(92,131,63)',
};
