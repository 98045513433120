import {
  AxiosResponse,
} from 'axios';
import {
  EOpenapiPaths,
  computedUrl,
} from '@/types/openapi-type';
import {
  TApiPayload,
} from '@/api/type/export';
import axiosClientV3 from './client';
import {
  ICustomStorageItem,
} from '@/utils/custom-storage/export.ts';

export type TCustomStoragePath<T extends keyof ICustomStorageItem> = {
  storageKey: T,
};
export type TGetCustomStoragePayload<T extends keyof ICustomStorageItem> = TApiPayload<TCustomStoragePath<T>>;

export async function getCustomStorage<T extends keyof ICustomStorageItem>(payload: TGetCustomStoragePayload<T>, abortSignal?: AbortSignal): Promise<AxiosResponse<ICustomStorageItem[T]>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.CUSTOM_USER_STORAGE_GET_PUT](payload.path), {
    signal: abortSignal,
  });
}

export type TPutCustomStorageParams<T extends keyof ICustomStorageItem> = ICustomStorageItem[T];
export type TPutCustomStoragePayload<T extends keyof ICustomStorageItem> = TApiPayload<TCustomStoragePath<T>, undefined, TPutCustomStorageParams<T>>;

export async function createOrUpdateCustomStorage<T extends keyof ICustomStorageItem>(payload: TPutCustomStoragePayload<T>): Promise<AxiosResponse<ICustomStorageItem[T]>> {
  return axiosClientV3.put(computedUrl[EOpenapiPaths.CUSTOM_USER_STORAGE_GET_PUT](payload.path), JSON.stringify(payload.bodyParams), {
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((response) => ({
      ...response,
      data: payload.bodyParams as ICustomStorageItem[T],
    }));
}
