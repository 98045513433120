import {
  AxiosResponse,
} from 'axios';
import {
  EOpenapiPaths,
} from '@/types/openapi-type';
import {
  paths,
} from '@/types/openapi';
import axiosClientV3 from './client';

export type TGetAppListResponse = paths[EOpenapiPaths.APP_LIST_GET]['get']['responses']['200']['content']['application/json'];
export async function getAppList(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAppListResponse>> {
  return axiosClientV3.get(EOpenapiPaths.APP_LIST_GET, {
    signal: abortSignal,
  });
}
