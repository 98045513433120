import {
  useQueryClient,
} from '@tanstack/vue-query';

// can be called only from within setup context
export function setupQueryClient() {
  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      // 30 min in millis
      staleTime: 1000 * 60 * 30,
      // 30 min in millis
      gcTime: 1000 * 60 * 30,
    },
  });
}
