import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/styles';
import {
  aliases, fa,
} from 'vuetify/lib/iconsets/fa';
import {
  mdi,
} from 'vuetify/lib/iconsets/mdi';
import {
  createVuetify,
} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import de from 'vuetify/lib/locale/de.mjs';
import {
  h,
} from 'vue';
import {
  customAliases,
} from '@/components/icons/vuetifyAliases';

export default createVuetify({
  icons: {
    aliases: {
      ...aliases,
      ...customAliases,
    },
    defaultSet: 'fa',
    sets: {
      mdi,
      fa,
      custom: {
        component: (props) => h(props.tag, {
          class: [
            'custom',
            props.icon,
          ],
        }),
      },
    },
  },
  defaults: {
    VBtn: {
      elevation: 0,
    },
    VTextField: {
      density: 'default',
      variant: 'filled',
      hideDetails: 'auto',
    },
    VTextarea: {
      density: 'default',
      variant: 'filled',
      hideDetails: 'auto',
    },
    VSelect: {
      density: 'default',
      variant: 'filled',
      hideDetails: 'auto',
      noDataText: 'No data...',
    },
    VFileInput: {
      density: 'default',
      variant: 'filled',
      hideDetails: 'auto',
    },
    VAutocomplete: {
      density: 'default',
      variant: 'filled',
      hideDetails: 'auto',
      menuProps: {
        maxHeight: '250px',
        width: '250px',
      },
      noDataText: 'No data...',
    },
    VRadioGroup: {
      hideDetails: 'true',
      density: 'default',
    },
    VCheckbox: {
      density: 'comfortable',
      hideDetails: 'auto',
    },
    VExpansionPanels: {
      multiple: true,
      variant: 'accordion',
    },
    VExpansionPanelTitle: {
      color: '#e9e9e9',
      expandIcon: 'fas fa-chevron-up',
      collapseIcon: 'fas fa-chevron-down',
    },
    VWindowItem: {
      transition: false,
      reverseTransition: false,
    },
    VList: {
      density: 'compact',
    },
  },
  components,
  directives,
  locale: {
    messages: {
      de,
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        colors: {
          primary: '#000000',
          secondary: '#424242',
          accent: '#E5673E',
          alternativeaccent: '#E5673E',
          background: '#e3e3e2',
          disabledButton: '#F2B29D',
          primaryButton: '#E5673E',
          secondaryButton: '#ffffff',
          tertiaryButton: '#c1c1c1',
          errorButton: '#F44336',
          successButton: '#4CAF50',
          warningButton: '#FB8C00',
          infoButton: '#2196F3',
        },
      },
      light: {
        colors: {
          primary: '#000000',
          secondary: '#424242',
          accent: '#E5673E',
          alternativeaccent: '#E5673E',
          background: '#e3e3e2',
          disabledButton: '#F2B29D',
          primaryButton: '#E5673E',
          secondaryButton: '#ffffff',
          tertiaryButton: '#c9c9c9',
          errorButton: '#F44336',
          successButton: '#4CAF50',
          warningButton: '#FB8C00',
          infoButton: '#2196F3',
          calendarBorder: '#cccccc',
          calendarColor: '#666666',
          calendarBackground: '#e2e2e2',
        },
      },
    },
  },
});
