import {
  portalApi,
} from '@/services/AxiosService';
import {
  EQUIPMENTS_PATH,
  SENSORS_PATH,
  COMMON_SENSORS,
} from '@/constants/apiPaths';

export const SensorMappingService = {
  getAllCommonSensors() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENTS_PATH}/${SENSORS_PATH}/${COMMON_SENSORS}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getCommonSensors(equipment_id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENTS_PATH}/${SENSORS_PATH}/${COMMON_SENSORS}/${equipment_id}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getAssetSensors(equipment_id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENTS_PATH}/${SENSORS_PATH}/${equipment_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateSensorDataMapping({
    sensor_data_mapping_id,
    common_sensor_data_id,
    furnace_sensor_name,
    furnace_sensor_id,
    set_furnace_sensor_id,
    location_id,
    location_name,
    equipment_id,
    equipment_name,
    furnace_type,
    value_precision,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}/${SENSORS_PATH}/${equipment_id}`, {
          sensor_data_mapping_id,
          common_sensor_data_id,
          furnace_sensor_name,
          furnace_sensor_id,
          set_furnace_sensor_id,
          location_id,
          location_name,
          equipment_id,
          equipment_name,
          furnace_type,
          value_precision,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteSensorDataMapping(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${EQUIPMENTS_PATH}/${SENSORS_PATH}/${COMMON_SENSORS}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
