import {
  ALARMS_LIST,
  ALARM_FREQ_PARETO_GRAPH_PATH,
  DASHBOARD_CONSUMABLES_PATH,
  DASHBOARD_OEE_PATH,
  DASHBOARD_OHI_PATH,
  DASHBOARD_OVERVIEW_GRAPH_PATH,
  DASHBOARD_PENDING_EMERGENCY_PATH,
  FURNACES_PATH,
  MAINTENANCE_DASHBOARDS_PATH,
  MONITORING_DASHBOARD_PATH,
  OPERATION_DASHBOARDS_PATH,
} from '@/constants/apiPaths';
import {
  portalApi, portalV2Api,
} from '@/services/AxiosService';
import {
  qmulusSdk,
} from '@/services/qmulusService';
import {
  ResponseUtils,
} from '@/utils/ResponseUtils';

export const GraphsService = {
  getDashboardList: async (assetId, is_eq) => {
    // TODO: If !is_eq, it could mean that assetId is one of below 3, we need to fix this as this causes bad practices in the BE
    const query = {
      'equipment-id': is_eq ? assetId : null,
      locationId: is_eq ? null : assetId,
      'customer-id': is_eq ? null : assetId,
      'group-id': is_eq ? null : assetId,
    };
    return await qmulusSdk.furnace.get(query);
  },
  getSankeyChart: async (equipmentId) => {
    return await qmulusSdk.furnace.productivity.get(equipmentId);
  },
  getAlarmFreqPareto(equipment_id, start_time, end_time) {
    ResponseUtils.validateId(equipment_id, 'equipment_id');
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${MAINTENANCE_DASHBOARDS_PATH}/${ALARM_FREQ_PARETO_GRAPH_PATH}/${equipment_id}`,
          {
            params: {
              endTime: end_time,
              startTime: start_time,
            },
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getAlarmsList(equipment_id, start_time, end_time) {
    ResponseUtils.validateId(equipment_id, 'equipment_id');
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_DASHBOARDS_PATH}/${ALARMS_LIST}/${equipment_id}`, {
          params: {
            endTime: end_time,
            startTime: start_time,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOverviewGraph(equipment_id, is_main) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${OPERATION_DASHBOARDS_PATH}/${equipment_id}/${DASHBOARD_OVERVIEW_GRAPH_PATH}`,
          {
            params: {
              is_main,
            },
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getPendingEmergency(furnaceId) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(
          `${MONITORING_DASHBOARD_PATH}/${DASHBOARD_PENDING_EMERGENCY_PATH}`,
          {
            params: {
              furnace_id: furnaceId,
            },
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getConsumables(equipmentId) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`/${FURNACES_PATH}/${equipmentId}/${DASHBOARD_CONSUMABLES_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOhi(furnaceId) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${MONITORING_DASHBOARD_PATH}/${DASHBOARD_OHI_PATH}`, {
          params: {
            furnace_id: furnaceId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOee(furnaceId) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${MONITORING_DASHBOARD_PATH}/${DASHBOARD_OEE_PATH}`, {
          params: {
            furnace_id: furnaceId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getHeatLossChart(start_time, end_time, equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_DASHBOARDS_PATH}/job-features-data`, {
          params: {
            start_time,
            end_time,
            equipmentId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
