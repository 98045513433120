import equipmentAuditStore from './equipmentAudit';
import equipmentMainStore from './equipmentMainStore';
import equipmentCommunicationStore from './equipmentCommunicationStore';
import equipmentSensorMapping from './equipmentSensorMapping';
import equipmentPlannedUsageStore from './equipmentPlannedUsageStore';
import equipmentOverviewStore from './equipmentOverviewStore';
import equipmentNpmControllerView from './equipmentNpmControllerView';

export default {
  modules: {
    equipmentAuditStore,
    equipmentMainStore,
    equipmentCommunicationStore,
    equipmentSensorMapping,
    equipmentPlannedUsageStore,
    equipmentOverviewStore,
    equipmentNpmControllerView,
  },

  state: {
    equipmentId: '',
  },

  mutations: {
    setEquipmentId(state, id) {
      state.equipmentId = id;
    },
  },
  actions: {
    SET_EQUIPMENT_ID_FOR_DETAILS(context, id) {
      context.commit('setEquipmentId', id);
    },

    REQUEST_EQUIPMENT_DETAILS(context, {
      id, status, documentImageWidth,
    }) {
      context.dispatch('REQUEST_EQUIPMENT_DETAILS_CORE', {
        id,
        status,
        documentImageWidth,
      });
    },

    async REQUEST_EQUIPMENT_DETAILS_CORE(
      context,
      // { id, status, documentImageWidth }
      {
        id,
      },
    ) {
      context.commit('setEquipmentId', id);
      context.dispatch('SET_CAPABILITIES_LIST', id);
      context.dispatch('SET_CERTIFICATES_LIST', id);
      context.dispatch('SET_SPARE_PARTS_LIST');
      context.dispatch('SET_SPARE_PARTS');
      context.dispatch('SET_CUSTOMER_REQUESTS', {
        id,
        type: 'equipment',
      });
      // if (status) {
      //   context.dispatch("SET_ANNOUNCEMENTS", status);
      // } else {
      //   context.dispatch("CLEAR_ANNOUNCEMENTS");
      // }
      // context.dispatch("SET_CUSTOMER_REQUESTS", false);
      // context.dispatch('SET_DOCUMENTS', id);

      // context.dispatch("SET_DRAWINGS", documentImageWidth);
      context.dispatch('SET_COMPONENTS');
      context.dispatch('SET_EQ_PLANNED_USAGE');
    },

    CLEAR_EQUIPMENT_DETAILS(context) {
      context.dispatch('CLEAR_EQUIPMENT_DETAILS_CORE');
    },

    CLEAR_EQUIPMENT_ID(context) {
      context.commit('setEquipmentId', '');
    },

    CLEAR_EQUIPMENT_DETAILS_CORE(context) {
      context.commit('setEquipmentId', '');
      context.commit('clearEquipmentMain');
      context.commit('clearEquipmentCommunication');
      context.commit('clearEquipmentDocuments');
    },
  },
};
