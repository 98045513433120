import {
  MaterialsService,
} from '@/services/Materials';

const emptyChart = {
  data: [],
};
const transformToChartDataFormat = (response) => {
  const data = [];
  response.x.values.map((x, i) => {
    data.push({
      x,
      y: response.y.values[i],
    });
  });
  return data;
};
export default {
  state: {
    materialsList: [],
    isLoadingMaterialsList: false,
    validationCompositionTable: true,
    material: null,
    isLoadingMaterial: false,
    materialsGroups: [],
    isLoadingMaterialsGroups: false,
    materialsSystems: [],
    isLoadingMaterialsSystems: false,
    selectedMaterial: null,
    materialCompositionEditedData: [],
    isCompositionTableEdited: false,
    calculatedMaterialComposition: [],
    isLoadingCalculateMaterialComposition: false,
    calculatedMaterialProperties: [],
    isLoadingCalculateMaterialProperties: false,
    jominyChartData: [
      emptyChart,
    ],
    isLoadingJominyChartData: false,
    temperingChartData: [
      emptyChart,
    ],
    isLoadingTemperingChartData: false,
    schaefflerChartData: [
      emptyChart,
    ],
    schaefflerAnnotations: {},
    isLoadingSchaefflerChartData: false,
    cctChartData: [
      emptyChart,
    ],
    isLoadingCctChartData: false,
    selectedMaterialModel: 'GUNNARSON',
  },

  getters: {
    getSelectedMaterialModel(state) {
      return state.selectedMaterialModel;
    },
    getIsCompositionTableEdited(state) {
      return state.isCompositionTableEdited;
    },
    getMaterialCompositionTableValidation(state) {
      return state.validationCompositionTable;
    },
    getMaterialCompositionEditedData(state) {
      return state.materialCompositionEditedData;
    },
    getSelectedMaterial(state) {
      return state.selectedMaterial;
    },
    getMaterialsTableList(state) {
      return state.materialsList;
    },
    getMaterialsList(state) {
      const materialSystems = state.materialsSystems.map(({
        name,
      }) => name);
      return state.materialsList.map(({
        id, materialNames,
      }) => {
        let name = '';
        for (const system of materialSystems) {
          for (const material of materialNames) {
            if (material.namingSystem === system) {
              name = name.length ? `${name} - ${material.name}` : material.name;
            }
          }
        }
        return {
          id,
          name,
        };
      });
    },
    getMaterial(state) {
      return state.material;
    },
    getMaterialsGroups(state) {
      return state.materialsGroups;
    },
    getMaterialsSystems(state) {
      return state.materialsSystems.map(({
        name,
      }) => name);
    },
    getCalculatedMaterialComposition(state) {
      return state.calculatedMaterialComposition;
    },
    getCalculatedMaterialProperties(state) {
      return state.calculatedMaterialProperties;
    },
    getJominyChartData(state) {
      return state.jominyChartData;
    },
    getTemperingChartData(state) {
      return state.temperingChartData;
    },
    getSchaefflerChartData(state) {
      return state.schaefflerChartData;
    },
    getSchaefflerAnnotations(state) {
      return state.schaefflerAnnotations;
    },
    getCctChartData(state) {
      return state.cctChartData;
    },
    isLoadingMaterialsTableList(state) {
      return state.isLoadingMaterialsList;
    },
    isLoadingMaterial(state) {
      return state.isLoadingMaterial;
    },
    isLoadingMaterialsGroups(state) {
      return state.isLoadingMaterialsGroups;
    },
    isLoadingMaterialsSystems(state) {
      return state.isLoadingMaterialsSystems;
    },
    isLoadingMaterialCompositionTable(state) {
      return state.isLoadingMaterialCompositionTable;
    },
    isLoadingCalculateMaterialComposition(state) {
      return state.isLoadingCalculateMaterialComposition;
    },
    isLoadingCalculateMaterialProperties(state) {
      return state.isLoadingCalculateMaterialProperties;
    },
    isLoadingJominyChartData(state) {
      return state.isLoadingJominyChartData;
    },
    isLoadingTemperingChartData(state) {
      return state.isLoadingTemperingChartData;
    },
    isLoadingSchaefflerChartData(state) {
      return state.isLoadingSchaefflerChartData;
    },
    isLoadingCctChartData(state) {
      return state.isLoadingCctChartData;
    },
  },

  mutations: {
    setSelectedMaterialModel(state, materialModel) {
      state.selectedMaterialModel = materialModel;
    },
    setIsCompositionTableEdited(state, isEdited) {
      state.isCompositionTableEdited = isEdited;
    },
    setMaterialCompositionEditedData(state, data) {
      state.materialCompositionEditedData = data;
    },
    setSelectedMaterial(state, material) {
      state.selectedMaterial = material;
    },
    setValidationCompositionTable(state, isValid) {
      state.validationCompositionTable = isValid;
    },
    setMaterialsList(state, materials) {
      state.materialsList = materials;
    },
    setMaterial(state, data) {
      state.material = data;
    },
    setMaterialsGroups(state, data) {
      state.materialsGroups = data;
    },
    setMaterialsSystems(state, data) {
      state.materialsSystems = data;
    },
    setCalculatedMaterialComposition(state, data) {
      state.calculatedMaterialComposition = data;
    },
    setCalculatedMaterialProperties(state, data) {
      state.calculatedMaterialProperties = data;
    },
    setJominyChartData(state, chartData) {
      state.jominyChartData = chartData;
    },
    setTemperingChartData(state, chartData) {
      state.temperingChartData = chartData;
    },
    setSchaefflerChartData(state, chartData) {
      state.schaefflerChartData = chartData;
    },
    setSchaefflerAnnotations(state, annotations) {
      state.schaefflerAnnotations = annotations;
    },
    setCctChartData(state, chartData) {
      state.cctChartData = chartData;
    },
    setIsLoadingMaterialsTableList(state, loading) {
      state.isLoadingMaterialsList = loading;
    },
    setIsLoadingMaterial(state, loading) {
      state.isLoadingMaterial = loading;
    },
    setIsLoadingMaterialsGroups(state, loading) {
      state.isLoadingMaterialsGroups = loading;
    },
    setIsLoadingMaterialsSystems(state, loading) {
      state.isLoadingMaterialsSystems = loading;
    },
    setIsLoadingCalculateMaterialComposition(state, loading) {
      state.isLoadingCalculateMaterialComposition = loading;
    },
    setIsLoadingCalculateMaterialProperties(state, loading) {
      state.isLoadingCalculateMaterialProperties = loading;
    },
    setIsLoadingCctChartData(state, loading) {
      state.isLoadingCctChartData = loading;
    },
    setIsLoadingTemperingChartData(state, loading) {
      state.isLoadingTemperingChartData = loading;
    },
    setIsLoadingJominyChartData(state, loading) {
      state.isLoadingJominyChartData = loading;
    },
    setIsLoadingSchaefflerChartData(state, loading) {
      state.isLoadingSchaefflerChartData = loading;
    },
  },

  actions: {
    async SET_SELECTED_MATERIAL_MODEL(context, materialModel) {
      context.commit('setSelectedMaterialModel', materialModel);
    },
    async COMPOSITION_TABLE_EDITED(context, isEdited) {
      context.commit('setIsCompositionTableEdited', isEdited);
    },
    async SET_SELECTED_MATERIAL(context, {
      material,
    }) {
      context.commit('setSelectedMaterial', material);
    },
    async SET_VALIDATION_COMPOSITION_TABLE(context, isValid) {
      context.commit('setValidationCompositionTable', isValid);
    },
    async SET_EDITED_MATERIAL_COMPOSITION(context, data) {
      context.commit('setMaterialCompositionEditedData', data);
    },
    CLEAR_MATERIALS_DETAILS(context) {
      context.commit('setMaterial', null);
      context.commit('setMaterialCompositionEditedData', []);
      context.commit('setCalculatedMaterialComposition', []);
      context.commit('setCalculatedMaterialProperties', []);
      context.commit('setJominyChartData', [
        emptyChart,
      ]);
      context.commit('setTemperingChartData', [
        emptyChart,
      ]);
      context.commit('setSchaefflerChartData', [
        emptyChart,
      ]);
      context.commit('setSchaefflerAnnotations', {});
      context.commit('setCctChartData', [
        emptyChart,
      ]);
    },
    CLEAR_MATERIALS_LIST(context) {
      context.commit('setMaterialsList', []);
      context.commit('setMaterialsGroups', []);
    },
    async SET_MATERIALS_TABLE_LIST(context) {
      context.commit('setIsLoadingMaterialsTableList', true);
      try {
        context.commit(
          'setMaterialsList',
          await MaterialsService.getMaterialsList(),
        );
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingMaterialsTableList', false);
      }
    },
    async SET_MATERIAL_DETAILS(context, {
      material_id,
    }) {
      context.commit('setIsLoadingMaterial', true);
      try {
        context.commit(
          'setMaterial',
          await MaterialsService.getMaterial(material_id),
        );
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingMaterial', false);
      }
    },
    async SET_MATERIALS_GROUPS(context) {
      context.commit('setIsLoadingMaterialsGroups', true);
      try {
        context.commit(
          'setMaterialsGroups',
          await MaterialsService.getMaterialsGroups(),
        );
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingMaterialsGroups', false);
      }
    },
    async SET_MATERIALS_SYSTEMS(context) {
      context.commit('setIsLoadingMaterialsSystems', true);
      try {
        context.commit(
          'setMaterialsSystems',
          await MaterialsService.getMaterialsSystems(),
        );
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingMaterialsSystems', false);
      }
    },
    async CALCULATE_MATERIAL_COMPOSITION(context, payload) {
      context.commit('setIsLoadingCalculateMaterialComposition', true);
      try {
        context.commit(
          'setCalculatedMaterialComposition',
          await MaterialsService.calculateMaterialComposition(payload),
        );
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingCalculateMaterialComposition', false);
      }
    },
    async CALCULATE_MATERIAL_PROPERTIES(
      context,
      {
        materialCompositions, calculationModel,
      },
    ) {
      context.commit('setIsLoadingCalculateMaterialProperties', true);
      try {
        const response = await MaterialsService.calculateMaterialProperties(
          materialCompositions,
          {
            calculationModel,
          },
        );
        const data = [];
        for (const key in response) {
          data.push({
            austenite: key,
            min: response[key].min.toFixed(2),
            max: response[key].max.toFixed(2),
            avg: response[key].avg.toFixed(2),
            comp: response[key].comp.toFixed(2),
          });
        }
        context.commit('setCalculatedMaterialProperties', data);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingCalculateMaterialProperties', false);
      }
    },
    async SET_MATERIALS_TEMPERING_CHART(
      context,
      {
        materialCompositions, calculationModel,
      },
    ) {
      context.commit('setIsLoadingTemperingChartData', true);
      try {
        const response = await MaterialsService.getMaterialCharts(
          materialCompositions,
          {
            chartType: 'tempering',
            calculationModel,
          },
        );
        // TODO: color matching on the chart (get from BE or FE - will be discussed)
        const datasets = [];
        const data = transformToChartDataFormat(response[0].plots[0]);
        datasets.push({
          borderColor: 'rgb(147,165,255)',
          pointRadius: 0,
          showLine: true,
          xAxisUnit: response[0].xAxisLabel,
          yAxisUnit: response[0].yAxisLabel,
          data,
        });
        context.commit('setTemperingChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingTemperingChartData', false);
      }
    },
    async SET_MATERIALS_SCHAEFFLER_CHART(
      context,
      {
        materialCompositions, calculationModel,
      },
    ) {
      context.commit('setIsLoadingSchaefflerChartData', true);
      try {
        const response = await MaterialsService.getMaterialCharts(
          materialCompositions,
          {
            chartType: 'schaeffler',
            calculationModel,
          },
        );

        const chartAnnotations = response[0].plots.filter(
          (plot) => plot.type === 'text',
        );
        const annotations = {};
        for (const annotation of chartAnnotations) {
          annotations[annotation.id] = {
            type: 'label',
            xValue: annotation.x.value,
            yValue: annotation.y.value,
            content: annotation.text,
            font: {
              weight: 'bold',
            },
          };
        }
        context.commit('setSchaefflerAnnotations', annotations);

        const datasets = [];
        const filteredData = response[0].plots.filter(
          (plot) => plot.type !== 'text',
        );
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].type === 'point') {
            datasets.push({
              // is techdebt, used to filter point data among lines data
              // do not change, will be removed alltogether with legacy code
              filterValue: 'static-string',
              type: 'bubble',
              backgroundColor: 'rgb(252,0,0)',
              name: filteredData[i].id,
              data: [
                [
                  filteredData[i].x.value,
                  filteredData[i].y.value,
                  5,
                ],
              ],
            });
          } else {
            const data = transformToChartDataFormat(filteredData[i]);
            datasets.push({
              pointRadius: 0,
              showLine: true,
              borderColor: 'rgb(117,117,117)',
              name: filteredData[i].id,
              data,
            });
          }
        }
        context.commit('setSchaefflerChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingSchaefflerChartData', false);
      }
    },
    async SET_MATERIALS_JOMINY_CHART(
      context,
      {
        materialCompositions, calculationModel,
      },
    ) {
      context.commit('setIsLoadingJominyChartData', true);
      try {
        const response = await MaterialsService.getMaterialCharts(
          materialCompositions,
          {
            chartType: 'jominy',
            calculationModel,
          },
        );
        const datasets = [];
        const data = transformToChartDataFormat(response[0].plots[0]);
        datasets.push({
          borderColor: 'rgb(147,165,255)',
          pointRadius: 0,
          showLine: true,
          xAxisUnit: response[0].xAxisLabel,
          yAxisUnit: response[0].yAxisLabel,
          data,
        });
        context.commit('setJominyChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingJominyChartData', false);
      }
    },
    async SET_MATERIALS_CCT_CHART(
      context,
      {
        materialCompositions, calculationModel,
      },
    ) {
      context.commit('setIsLoadingCctChartData', true);
      try {
        const response = await MaterialsService.getMaterialCharts(
          materialCompositions,
          {
            chartType: 'CCT',
            calculationModel,
          },
        );
        const datasets = [];
        for (let i = 0; i < response[0].plots.length; i++) {
          const data = transformToChartDataFormat(response[0].plots[i]);
          datasets.push({
            showLine: true,
            borderColor: 'rgb(117,117,117)',
            name: response[0].plots[i].id,
            data,
          });
        }
        context.commit('setCctChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingCctChartData', false);
      }
    },
  },
};
