<template>
  <div
    v-show="appStore.isNavigating"
    class="progress-bar"
    :style="{
      'width': `${width}%`
    }"
  />
</template>

<script setup lang="ts">
import {
  ref,
  watch,
} from 'vue';
import {
  useAppStore,
} from '@/store/pinia/app';

const appStore = useAppStore();

const progress = ref(0);
const width = ref(0);
const intervalId = ref<any>(undefined);

function easeOutCirc(x: number): number {
  return Math.sqrt(1 - (x - 1) ** 2);
}

watch(() => appStore.isNavigating, (newIsNavigating) => {
  if (newIsNavigating) {
    intervalId.value = setInterval(() => {
      progress.value += 0.005;
      width.value = easeOutCirc(progress.value) * 100;
    }, 100);
  } else {
    width.value = 0;
    progress.value = 0;
    clearInterval(intervalId.value);
  }
});
</script>

<style lang="scss" scoped>
@use '@/styles/util/z-index.scss';

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 2px;
  width: 100dvh;

  z-index: z-index.$progress-bar;
  background-color: var(--primary-color);
}
</style>
