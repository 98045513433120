import {
  LOCALIZATION_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const LocalizationService = {
  getSettings() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${LOCALIZATION_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  setLanguage(language) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${LOCALIZATION_PATH}/language`, language)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  setNumberFormat(numberFormat) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${LOCALIZATION_PATH}/number-format`, numberFormat)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  setDateFormat(dateFormat) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${LOCALIZATION_PATH}/date-format`, dateFormat)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
