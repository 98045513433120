import {
  TGetInAppNotificationsResponse,
} from '@/generated/axios/openapi/core.ts';

export type TEventType = Partial<TGetInAppNotificationsResponse['items'][number]['eventType']>;
export enum EEventType {
  ALARM_SEVERITY_ALARM_OCCURRED = 'alarm_severity_alarm_occurred',
  ALARM_SEVERITY_EMERGENCY_OCCURRED = 'alarm_severity_emergency_occurred',
  ALARM_SEVERITY_WARNING_OCCURRED = 'alarm_severity_warning_occurred',
  JOB_FINISHED = 'job_finished',
  JOB_STARTED = 'job_started',
  TIMESERIES_LAG_DETECTED = 'timeseries_lag_detected',
  NEW_QMULUS_RELEASE = 'new_qmulus_release',
}

export type TTranslationEventItem = {
  label: string,
  description: string
}

export type TTranslationEvent = Record<TEventType, TTranslationEventItem>;
