import {
  components,
} from '@/types/openapi';

export enum EAttributeLabel {
  WEIGHT = 'WEIGHT',
  LENGTH = 'LENGTH',
  DIAMETER = 'DIAMETER',
  SURFACE_HARDNESS = 'SURFACE_HARDNESS',
  SURFACE_HARDNESS_MIN = 'SURFACE_HARDNESS_MIN',
  SURFACE_HARDNESS_MAX = 'SURFACE_HARDNESS_MAX',
  CORE_HARDNESS = 'CORE_HARDNESS',
  CORE_HARDNESS_MIN = 'CORE_HARDNESS_MIN',
  CORE_HARDNESS_MAX = 'CORE_HARDNESS_MAX',
  EFFECTIVE_CASE_DEPTH_MIN = 'EFFECTIVE_CASE_DEPTH_MIN',
  EFFECTIVE_CASE_DEPTH_MAX = 'EFFECTIVE_CASE_DEPTH_MAX',
  EFFECTIVE_CASE_DEPTH_HARDNESS = 'EFFECTIVE_CASE_DEPTH_HARDNESS',
}

export const attributeUnitMap: Record<components['schemas']['BuiltInPartAttributeId'], keyof typeof EAttributeLabel> = {
  '-1': 'WEIGHT',
  '-2': 'LENGTH',
  '-3': 'DIAMETER',
  '-4': 'SURFACE_HARDNESS',
  '-5': 'SURFACE_HARDNESS_MIN',
  '-6': 'SURFACE_HARDNESS_MAX',
  '-7': 'CORE_HARDNESS',
  '-8': 'CORE_HARDNESS_MIN',
  '-9': 'CORE_HARDNESS_MAX',
  '-10': 'EFFECTIVE_CASE_DEPTH_MIN',
  '-11': 'EFFECTIVE_CASE_DEPTH_MAX',
  '-12': 'EFFECTIVE_CASE_DEPTH_HARDNESS',
};
