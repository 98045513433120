import {
  TTranslationAppearanceForm,
} from '@/page/general-settings/appearance/appearance.ts';

const appearanceForm: TTranslationAppearanceForm = {
  language: {
    label: 'Language',
    tooltip: 'Allows you to select the desired language for the user interface.',
  },
  timeFormat: {
    label: 'Time format',
    tooltip: 'Defines how dates and timestamps are displayed.',
  },
  fontSize: {
    label: 'Page scaling',
    tooltip: 'Allows you to adjust the size of the font and ui element.',
  },
};

export default {
  subline: 'Change the way Qmulus looks and feels',
  appearanceForm,
};
