import {
  EAppIdMap,
} from '@/types/app-type.ts';
import {
  ESettingsSectionType,
} from '@/types/settings.ts';
import {
  TTranslation,
} from './generic-settings-item';

const translation: TTranslation = {
  [EAppIdMap.REMOTE_CONTROL]: {
    [ESettingsSectionType.REMOTE_CONTROL_PT9800]: {
      title: 'PT9800 Controller',
      subtitle: 'The following settings define how the PT9800 controller behaves for every user.',
      settingsMap: {
        enforceBarcodeScanner: {
          label: 'Forcing barcode scanners.',
          tooltip: 'If selected, the confirmation of parts via barcode scanner is forced in the dialog for starting jobs.',
        },
      },
    },
  },
};

export default translation;
