export default {
  addSample: 'Add sample',
  createOneTimePart: 'Create one-time part',
  sample: {
    defaultDisplayName: 'Sample',
  },
  tableTest: {
    defaultDisplayName: 'Table',
  },
  chartTest: {
    defaultDisplayName: 'Chart',
  },
  imageTest: {
    defaultDisplayName: 'Image',
  },
  fileTest: {
    defaultDisplayName: 'File',
  },
};
