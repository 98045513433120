import {
  locLevels, ainObjectType,
} from '@/constants/enumerations';

export const emptyNode = {
  id: null,
  name: null,
  level: null,
};
export default {
  state: {
    locations: [],
    isLoadingLocations: false,
    selectedLocationParent: emptyNode,
    selectedLocation: emptyNode,
    selectedEquipmentNode: emptyNode,
    locationLevel: '',
  },
  mutations: {
    /**
     * Sets locations to empty array and selectedLocation, selectedLocationParent id and name to null
     */
    clearLocations(state) {
      state.locations = [];
      state.selectedLocation = emptyNode;
      state.selectedLocationParent = emptyNode;
      state.selectedEquipmentNode = emptyNode;
    },

    setSelectedLocation(state, node) {
      state.selectedLocation = {
        id: node.id,
        name: node.name,
        level: node.level,
      };
    },

    setParentLocation(state, node) {
      state.selectedLocationParent = {
        id: node.id,
        name: node.name,
        level: node.level,
      };
    },

    setSelectedEquipmentNode(state, equipmentNode) {
      state.selectedEquipmentNode = equipmentNode;
    },

    setLocations(state, locations) {
      state.locations = locations;
    },
    setLocationLevel(state, level) {
      state.locationLevel = level;
    },
  },

  getters: {
    getSelectedLocation(state) {
      return state.selectedLocation;
    },
    getSelectedLocationParent(state) {
      return state.selectedLocationParent;
    },
    getLocationLevel(state) {
      return state.locationLevel;
    },
    getSelectedEquipmentNode(state) {
      return state.selectedEquipmentNode;
    },
    getTreeviewNodes(state) {
      return state.locations;
    },
  },

  actions: {
    SET_SELECTED_LOCATION(context, node) {
      context.commit('setSelectedLocation', node);
      context.commit('setLocationLevel', node.level);
    },

    SET_PARENT_LOCATION(context, node) {
      context.commit('setParentLocation', node);
    },

    SET_LOCATIONS(context, locations) {
      function setLevel(location, index) {
        if (location.children && location.children.length > 0) {
          for (const child of location.children) {
            setLevel(child, index + 1);
          }
        }
        const locKeys = Object.keys(locLevels);
        if (index === 2) {
          location.level = location.objectType === ainObjectType.EQU
            ? locLevels.equipment
            : locLevels.group;
        } else {
          location.level = locKeys[index];
        }
      }
      for (const location of locations) {
        setLevel(location, 0);
      }
      context.commit('setLocations', locations);
    },
    SET_LOCATION_LEVEL(context, level) {
      context.commit('setLocationLevel', level);
    },
    SET_SELECTED_EQUIPMENT_NODE(context, node) {
      context.commit('setSelectedEquipmentNode', node);
      if (node !== emptyNode) {
        context.commit('setLocationLevel', node.level);
      }
    },
  },
};
