import {
  portalApi,
} from '@/services/AxiosService';
import {
  PRODUCTION_LOGS_PATH,
  STATISTICS_PATH,
  PRODUCTION_LOGS_LIST,
  CHART_CONFIG_PATH,
  KPIS_PATH,
} from '@/constants/apiPaths';

export const ProductionLogsService = {
  getKPIs() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CHART_CONFIG_PATH}/${KPIS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getProductionLogs(payload, abortController = null) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${PRODUCTION_LOGS_PATH}/${PRODUCTION_LOGS_LIST}`, payload, {
          signal: abortController?.signal,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getStatisticsList(customerId, plantId, systemId, processName, from, to) {
    const params = {};
    if (customerId) {
      params.customerId = customerId;
    }
    if (plantId) {
      params.plantId = plantId;
    }
    if (systemId) {
      params.systemId = systemId;
    }
    if (processName) {
      params.processName = processName;
    }
    if (from && to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);

      // toISOString() always datetime format, so we need to substring to get only date
      // YYYY-MM-DDTHH:mm:ss.sssZ -> YYYY-MM-DD
      params.dateFrom = fromDate.toISOString().substring(0, 10);
      params.dateTo = toDate.toISOString().substring(0, 10);
    }
    return new Promise((resolve, reject) => {
      portalApi
        .get(STATISTICS_PATH, {
          params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getStatisticsParent(customerId, plantId, systemId, processName, from, to) {
    const params = {};
    if (customerId) {
      params.customerId = customerId;
    }
    if (plantId) {
      params.plantId = plantId;
    }
    if (systemId) {
      params.systemId = systemId;
    }
    if (processName) {
      params.processName = processName;
    }
    if (from && to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);

      // toISOString() always datetime format, so we need to substring to get only date
      // YYYY-MM-DDTHH:mm:ss.sssZ -> YYYY-MM-DD
      params.dateFrom = fromDate.toISOString().substring(0, 10);
      params.dateTo = toDate.toISOString().substring(0, 10);
    }
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${STATISTICS_PATH}/parent`, {
          params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFilteredLocations(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${PRODUCTION_LOGS_PATH}/filters-data/locations`, {
          params: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFilteredTypes(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${PRODUCTION_LOGS_PATH}/filters-data/furnace-types`, {
          params: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFilteredNames(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${PRODUCTION_LOGS_PATH}/filters-data/furnace-names`, {
          params: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  saveReport(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${PRODUCTION_LOGS_PATH}/report`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getReport(auditId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${PRODUCTION_LOGS_PATH}/report/${auditId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
