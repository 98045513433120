export const tokenizedContainsFilterFunction = (
  haystack: string[] | string,
  needle: string,
): boolean => {
  const whatSearchList = needle.split(' ').map(
    (_word) => _word.toLowerCase(),
  );

  if (Array.isArray(haystack)) {
    // If value is an array, check if each word in filter is present in any of the array elements
    return whatSearchList.every(
      (_word) => haystack.some(
        (_element) => _element.toLowerCase().includes(_word),
      ),
    );
  }

  // If value is a string, check if each word in filter is present in the string
  return whatSearchList.every(
    (_word) => haystack.toLowerCase().includes(_word),
  );
};
