export default {
  description: 'Create and manage your preset',
  presetLabel: 'Selected preset',
  nameLabel: 'Preset name',
  saveAsDefault: 'Save preset as default',
  publishGlobaly: 'Publish for all users',
  publishGlobalyTooltip: 'To change this setting, copy or create a new preset',
  defaultName: 'New preset',
  selectPreset: 'Select a preset',
  reset: 'Reset',
};
