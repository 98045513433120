export default {
  setpoint: 'Setpoint in hhh:mm:ss',
  stabilizationPeriodSeconds: 'Stabilisation period in hhh:mm:ss',
  timerSensors: 'Timer sensors',
  overruleResult: 'Overrule result',
  overruleReason: 'Overrule reason',
  overruleResultLabel: 'Overrule result',
  mainSensor: {
    placeholder: 'Select main signal',
  },
  durationSensor: {
    placeholder: 'Select duration signal',
  },
};
