import {
  format,
} from 'date-fns';

export const formatDate = (date) => {
  const formatObj = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(date).toLocaleString('en-GB', formatObj);
};

export const dateFromUnixTimestamp = (timestamp) => (timestamp ? formatDate(timestamp * 1000) : '');

export const formatUnix = (unixTimeStamp) => (unixTimeStamp ? new Date(unixTimeStamp * 1000) : null);

export const dateStringToSeconds = (dateString) => (new Date(dateString).getTime() / 1000).toFixed(0);

export const secondsToFormattedString = (seconds) => {
  const hValue = Math.trunc(seconds / 3600);
  const minValue = Math.trunc((seconds % 3600) / 60);
  const secValue = Math.trunc(seconds % 60);
  const h = hValue <= 9 ? `0${hValue}` : hValue;
  const min = minValue <= 9 ? `0${minValue}` : minValue;
  const sec = secValue <= 9 ? `0${secValue}` : secValue;

  return `${h}:${min}:${sec}`;
};

export const minutesToFormattedString = (minutes) => {
  return secondsToFormattedString(minutes * 60);
};

export const secondsToFormattedHoursMin = (seconds) => {
  const hValue = Math.trunc(seconds / 3600);
  const minValue = Math.trunc((seconds % 3600) / 60);
  const h = hValue <= 9 ? `0${hValue}` : hValue;
  const min = minValue <= 9 ? `0${minValue}` : minValue;

  return `${h}h ${min}min`;
};

/// standard time frame for ProductionLogs

const oneWeekInSeconds = 604800;
const oneWeekAgoTimeStamp = (Date.now() / 1000 - oneWeekInSeconds).toFixed(0);
const todayTimeStamp = (Date.now() / 1000).toFixed(0);

export const standartStart = oneWeekAgoTimeStamp;
export const standartEnd = todayTimeStamp;

export const formatedStandartStart = format(
  new Date(standartStart * 1000),
  'yyyy-MM-dd',
);
export const formatedStandartEnd = format(
  new Date(standartEnd * 1000),
  'yyyy-MM-dd',
);
