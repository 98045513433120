export default {
  general: {
    header: 'Allgemeine Eigenschaften',
    heatTreatmentTypeLabel: 'Art der Wärmebehandlung',
    coLabel: 'CO',
    h2Label: 'H2',
    balanceN2Label: 'Bilanz N2',
    fixedRadio: 'Fest',
    variableRadio: 'Variabel',
    h2Balance: 'H2-Bilanz',
  },
  simulationValue: {
    header: 'Daten zur Teilesimulation',
  },
  recipeStage: {
    header: 'Rezeptstufe',
    totalDurationLabel: 'Gesamtdauer:',
  },
  savePartAs: {
    header: 'Part speichern unter',
  },
};
