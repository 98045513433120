import {
  TTranslationEvent,
} from '@/components/item-notification/item-notification.ts';

const event: TTranslationEvent = {
  job_finished: {
    label: '{assetDisplayName}: Job {displayName} is finished',
    description: 'Job {displayName} with recipe: {recipeDisplayName} is finished at {finishedAtUtc} on {assetDisplayName} in {locationDisplayName}.\nJob description: {description}',
  },
  job_started: {
    label: '{assetDisplayName}: New job {displayName} has been started',
    description: 'Job {displayName} with recipe: {startedAtUtc} is finished at {finishedAtUtc} on {assetDisplayName} in {locationDisplayName}.\nJob description: {description}',
  },
  timeseries_lag_detected: {
    label: '{assetDisplayName}: Timeseries lag detected',
    description: 'Timeseries lag detected on {assetDisplayName} in {locationDisplayName}',
  },
  alarm_severity_alarm_occurred: {
    label: '{assetDisplayName}: Alarm {alarmDisplayName}',
    description: 'Alarm {alarmDisplayName} occurred on {assetDisplayName} in {locationDisplayName}',
  },
  alarm_severity_emergency_occurred: {
    label: '{assetDisplayName}: Emergency Alarm {alarmDisplayName}',
    description: 'Alarm {alarmDisplayName} occurred on {assetDisplayName} in {locationDisplayName}',
  },
  alarm_severity_warning_occurred: {
    label: '{assetDisplayName}: Warning {alarmDisplayName}',
    description: 'Alarm {alarmDisplayName} occurred on {assetDisplayName} in {locationDisplayName}',
  },
  new_qmulus_release: {
    label: 'New Qmulus version',
    description: 'Qmulus v{version} has just been released. See the release notes to learn more',
  },
};

export default {
  markAsRead: 'Mark as read',
  goToEvent: 'Go to Event',
  event,
};
