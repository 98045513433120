import {
  RecipeBuilderService,
} from '@/services/RecipeBuilderService';

export default {
  namespaced: true,
  state: {
    recipesList: [],
    isLoadingRecipesList: false,
    recipeDetails: {},
    isLoadingRecipeDetails: false,
    recipeTemplate: null,
    isLoadingRecipeTemplate: false,
    recipeConfig: null,
    isLoadingRecipeConfig: false,
    selectedEquipment: {
      id: null,
      name: null,
      locationName: null,
    },
  },
  mutations: {
    setSelectedEquipment(state, node) {
      if (node?.path) {
        const path = node.path.split('/');
        state.selectedEquipment = {
          id: node.id,
          name: node.name,
          locationName: path[1],
        };
      }
    },
    setRecipesList(state, recipesBuilder) {
      state.recipesList = recipesBuilder;
    },
    loadingRecipesList(state, loading) {
      state.isLoadingRecipesList = loading;
    },
    setRecipeDetails(state, recipeDetails) {
      state.recipeDetails = recipeDetails;
    },
    loadingRecipeDetails(state, loading) {
      state.isLoadingRecipeDetails = loading;
    },
    setRecipeTemplate(state, recipeTemplate) {
      state.recipeTemplate = recipeTemplate;
    },
    loadingRecipeTemplate(state, loading) {
      state.isLoadingRecipeTemplate = loading;
    },
    setRecipeConfig(state, recipeConfig) {
      state.recipeConfig = recipeConfig;
    },
    loadingRecipeConfig(state, loading) {
      state.isLoadingRecipeConfig = loading;
    },
  },
  actions: {
    async SET_RECIPES_BUILDER_SELECTED_EQUIPMENT(context, node) {
      context.commit('setSelectedEquipment', node);
    },
    async GET_RECIPES_LIST(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('loadingRecipesList', true);
        context.commit('setRecipesList', []);
        const response = await RecipeBuilderService.getRecipesList({
          customerCode,
          location,
          systemId,
        });
        context.commit('setRecipesList', response.recipe_list);
      } catch (e) {
        context.commit('setRecipesList', []);
        return e;
      } finally {
        context.commit('loadingRecipesList', false);
      }
    },
    async GET_RECIPE_DETAILS(context, {
      customerCode, location, systemId, recipeCode,
    }) {
      try {
        context.commit('loadingRecipeDetails', true);
        const response = await RecipeBuilderService.getRecipeDetails({
          customerCode,
          location,
          systemId,
          recipeCode,
        });
        context.commit('setRecipeDetails', response);
      } catch (e) {
        return e;
      } finally {
        context.commit('loadingRecipeDetails', false);
      }
    },
    async GET_RECIPE_TEMPLATE(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('loadingRecipeTemplate', true);
        const response = await RecipeBuilderService.getRecipeTemplate({
          customerCode,
          location,
          systemId,
        });
        context.commit('setRecipeTemplate', response);
      } catch (e) {
        return e;
      } finally {
        context.commit('loadingRecipeTemplate', false);
      }
    },
    async GET_RECIPE_CONFIG(context, {
      customerCode, location, systemId,
    }) {
      try {
        context.commit('loadingRecipeConfig', true);
        const response = await RecipeBuilderService.getRecipeConfig({
          customerCode,
          location,
          systemId,
        });
        context.commit('setRecipeConfig', response);
      } catch (e) {
        return e;
      } finally {
        context.commit('loadingRecipeConfig', false);
      }
    },
  },
  getters: {
    recipeBuilderSelectedEquipment(state) {
      return state.selectedEquipment;
    },
    getRecipesList(state) {
      return state.recipesList;
    },
    getIsLoadingRecipesList(state) {
      return state.isLoadingRecipesList;
    },
    getRecipeDetails(state) {
      return state.recipeDetails;
    },
    getIsLoadingRecipeDetails(state) {
      return state.isLoadingRecipeDetails;
    },
    getRecipeTemplate(state) {
      return state.recipeTemplate;
    },
    getIsLoadingRecipeTemplate(state) {
      return state.isLoadingRecipeTemplate;
    },
    getRecipeConfig(state) {
      return state.recipeConfig;
    },
    getIsLoadingRecipeConfig(state) {
      return state.isLoadingRecipeConfig;
    },
  },
};
