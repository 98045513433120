import {
  Chart,
} from 'chart.js';
import {
  LocalizationService,
} from '@/services/LocalizationService';

export default {
  state: {
    // @deprecated. Use pinia store instead to read current locale
    language: '',
    numberFormat: '',
    dateFormat: '',
    isLocalizationLoading: false,
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    setNumberFormat(state, numberFormat) {
      state.numberFormat = numberFormat;
      Chart.defaults.locale = numberFormat === 'en' ? 'en-CA' : 'de-DE';
    },
    setDateFormat(state, dateFormat) {
      state.dateFormat = dateFormat;
    },
    setIsLocalizationLoading(state, loading) {
      state.isLocalizationLoading = loading;
    },
  },
  actions: {
    async GET_LOCALIZATION(context) {
      context.commit('setIsLocalizationLoading', true);
      try {
        const localization = await LocalizationService.getSettings();
        context.commit('setLanguage', localization.language);
        context.commit('setNumberFormat', localization.number_format);
        context.commit('setDateFormat', localization.date_format);
      } finally {
        context.commit('setIsLocalizationLoading', false);
      }
    },
    async SET_LANGUAGE(context, language) {
      context.commit('setIsLocalizationLoading', true);
      try {
        const result = await LocalizationService.setLanguage(language);
        context.commit('setLanguage', result.language);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsLocalizationLoading', false);
      }
    },
    async SET_NUMBER_FORMAT(context, numberFormat) {
      context.commit('setIsLocalizationLoading', true);
      try {
        const result = await LocalizationService.setNumberFormat(numberFormat);
        context.commit('setNumberFormat', result.number_format);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsLocalizationLoading', false);
      }
    },
    async SET_DATE_FORMAT(context, dateFormat) {
      context.commit('setIsLocalizationLoading', true);
      try {
        const result = await LocalizationService.setDateFormat(dateFormat);
        context.commit('setDateFormat', result.date_format);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsLocalizationLoading', false);
      }
    },
  },
  getters: {
    // @deprecated. Use pinia store instead to read current locale
    getLocalizationSettings: (state) => {
      return state;
    },
  },
};
