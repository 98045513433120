import axios, {
  Axios,
} from 'axios';
import {
  Thunder,
} from '@/pt9800/generated/zeus';

export interface APIClientOptions {
    serverURL: string,
}

export interface APIClient {
  axios: Axios,
  thunder: ReturnType<typeof Thunder>,
  options: APIClientOptions,
  fetchGraph: Function,
  fetchGraphJSON: Function,
  setLocation: Function,
}

export interface FetchGraphOptions {
  location: string
}

const createAPIClientImpl = (options: APIClientOptions): APIClient => {
  const {
    serverURL,
  } = options;

  const defaults = {
    location: '',
  };

  const axiosClient = axios.create({
    baseURL: serverURL,
  });

  const setLocation = (location: string) => {
    defaults.location = location;
  };

  const fetchGraph = async (query: string, context: FetchGraphOptions) => {
    const config = {
      params: {
        ptDbId: context.location,
      },
    };

    return axiosClient
      .post('graphql', {
        query,
      }, config)
      .then((result) => ({
        json: () => result.data,
      }));
  };

  const fetchGraphJSON = (query: string, context: FetchGraphOptions) => fetchGraph(query, context).then((response: any) => response.json());

  const thunder = Thunder(async (query, variables) => {
    const location = variables?.location as string;
    if (!location) {
      throw new Error('Variable {location} is missing');
    }
    return (await fetchGraphJSON(
      query,
      {
        location,
      },
    )).data;
  });

  return {
    axios: axiosClient,
    thunder,
    options,
    fetchGraph,
    fetchGraphJSON,
    setLocation,
  };
};

type TClientType = ReturnType<typeof createAPIClientImpl>

// eslint-disable-next-line import/no-mutable-exports
let client: TClientType;

const createAPIClient = (options: APIClientOptions) => {
  if (client) {
    throw new Error('Cannot create APIClient because it was already created.');
  }
  client = createAPIClientImpl(options);
  return client;
};

export {
  client,
  createAPIClient,
};
