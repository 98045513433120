import {
  dateStringToSeconds,
} from '../utils/date.js';
import {
  scaleChartRecorder,
} from './inputCleaning.js';

export const create = (apiClient) => {
  const {
    fetchGraphJSON,
  } = apiClient;

  const TYPE_OPTION_ITEM = `{
    id
    value
  }`;
  const TYPE_ANALYSIS = `{
    c
    si
    mn
    cr
    ni
    v
    al
    cu
    p
    mo
    s
    b
    ti
  }`;
  const TYPE_MATERIAL = `{
    carbonContent,
    alloyFactor,
    legFactorN,
    carbideLimit,
    temperature,
    specialNitrides,
    analysis ${TYPE_ANALYSIS},
    co,
    h2,
    quenchingIntensity,
    requiredHardness,
    representativeDiameter,
    grainSize,
    endTemperature,
    targetSurfC,
    customerMaterialId,
  }`;
  const TYPE_ACT_SET_POINT = `{
    userComponentId
    valueType
    meaning
    name
    control ${TYPE_OPTION_ITEM}
    actualValue
    value
    unit
    toleranceMax
    toleranceMin
    alarmStartTimeInMin
    alarmToleranceTimeInMin
    endControl ${TYPE_OPTION_ITEM}
    endValue
  }`;
  const TYPE_SP_ENTRIES = `{
    furnaceSwitchEntry,
    recipeDescription2,
    recipeDescription3,
    recipeDescription4,
    gasEntryValues,
  }`;
  const TYPE_DIGITAL_OUTPUT = `{
    name
    value
  }`;
  const TYPE_ACT_RECIPE_POSITION_HEADER = `{
    positionNo
    chamberOption ${TYPE_OPTION_ITEM}
    chamberOperationOption ${TYPE_OPTION_ITEM}
    description
    estimatedDurationInMin
    afterOption ${TYPE_OPTION_ITEM}
    afterTimeInMin
    actualDurationInMin
  }`;
  const TYPE_ACT_RECIPE_POSITION = `{
    header ${TYPE_ACT_RECIPE_POSITION_HEADER}
    setpoints ${TYPE_ACT_SET_POINT}
    digitalOutputList ${TYPE_DIGITAL_OUTPUT}
    digitalOutputs
    endAfter
  }`;
  const furnaceViewsUpdatableFields = `
  value {
    id
    type
    value
    status
    digitsBeforeComma
    digitsAfterComma
    textBefore
    textAfter
    xPos
    yPos
    colorIndex,
    colorBG
    numberFormat
    fontSize
  }
  hourInfo {
    id
    xPos
    yPos
    text
    fontSize
    hourType
    zoneId
    time
    colorIndex
    colorBG
  }
  loadInfo {
    id
    xPos
    yPos
    text
    fontSize
    infoType
    colorIndex
    colorBG
    time
  }
  place {
    id
    xPos
    yPos
    xSize
    ySize
    colorBG
    systemColorBG
    name
  }
  loadButton {
    id
    type
    xPos
    yPos
    colorIndex
    xSize
    ySize
    chamberId
  }
  bitmap {
    id
    type
    filePath
    xPos
    yPos
    colorIndex
    colorBG
  }
  button {
    id
    type
    xPos
    yPos
    userInputId
    name
    mode
    userLevel
    btnText0
    btnText1
    helpText0
    helpText1
    value
  }
  label {
    id
    type
    text
    xPos
    yPos
    colorIndex
    colorBG
    fontSize
  }
  line {
    id
    type
    xPos
    yPos
    colorIndex
    width
    height
  }
  labelPlus {
    id
    type
    xPos
    yPos
    colorIndex
    colorBG
    text
    fontSize
  }
  rectangle {
    id
    type
    xPos
    yPos
    colorIndex
    colorBG
    xSize
    ySize
  }
  gif {
    id
    type
    xPos
    yPos
    filePath
  }
  progressBar {
    id
    type
    xPos
    yPos
    xSize
    ySize
    fullColor
    emptyColor
    colorBG
    minValue
    maxValue
    value
  }
  editBox {
    id
    type
    xPos
    yPos
    colorIndex
    colorBG
    xSize
    ySize
    focusOrderNumber
    variableType
    isEditable
    editValue
    digitsBeforeComma
    digitsAfterComma
    minValue
    maxValue
    numberFormat
    fontSize
    componentTypeId
    userInputId
    outputId
  }
`;
  const getFurnaceView = async (location, workplaceId) => {
    const query = `
        query {
          furnaceView(workplaceId: ${workplaceId}) {
            workplaceId
            state
            hold
            remote
            connected
            updateTime
            recipeHeader {
              actualStep
              loadIdentifier
              loadName,
              recipeName,
              versions {
                versionText
                startingTime
              }
            }
            colors {
              id
              r
              g
              b
            }
            furnaceViews {
              id
              name
              windowSizeId
              background {
                id
                type
                filePath
                xPos
                yPos
              }
              button {
                id
                type
                xPos
                yPos
                userInputId
                name
                mode
                userLevel
                btnText0
                btnText1
                helpText0
                helpText1
                value
              }
              text {
                id
                type
                value
                xPos
                yPos
                color
                colorBG
                fontSize
              }
              ${furnaceViewsUpdatableFields}
            }
          }
        }
      `;

    let res = await fetchGraphJSON(query, {
      location,
    });
    res = res.data.furnaceView;
    return res;
  };

  const updateFurnaceViewValues = async (location, workplaceId, viewId) => {
    const query = `
          query {
            updateFurnaceViews(workplaceId: ${workplaceId}, viewId: ${viewId}) {
              workplaceId
              state
              hold
              remote
              connected
              updateTime
              recipeHeader {
                actualStep
                loadIdentifier
                loadName,
                recipeName,
                versions {
                  versionText
                  startingTime
                }
              }
              furnaceViews {
                id
                name
                ${furnaceViewsUpdatableFields}
              }
            }
          }`;

    let res = await fetchGraphJSON(query, {
      location,
    });
    res = res.data.updateFurnaceViews;
    return res;
  };

  const getChartRecorderData = async (
    location,
    {
      workplaceId, startTime, endTime,
    },
  ) => {
    const startTimeSeconds = dateStringToSeconds(startTime);
    const endTimeSeconds = dateStringToSeconds(endTime);

    const query = `
query {
  chartRecorder(workplaceId: ${workplaceId}, startTime: ${startTimeSeconds}, endTime: ${endTimeSeconds}) {
    chartName
    chartColor
    chartUnit
    data {
      timestamp
      value
    }
  }
}`;

    let res = await fetchGraphJSON(query, {
      location,
    });
    res = res?.data?.chartRecorder;
    res = scaleChartRecorder(res);
    return res;
  };

  const getPartWorkplaceRecipeList = (location, workplaceId) => {
    const query = `
  query{
    partWorkplaceRecipeList(workplaceId: ${workplaceId}) {
      id
      name
      description
    }
  }`;

    return fetchGraphJSON(query, {
      location,
    });
  };

  const getPartList = (location) => {
    const query = `
  query{
    partList {
      id
      name
      description1
      description2
    }
  }`;

    return fetchGraphJSON(query, {
      location,
    });
  };

  const getPartsOfLoad = async (location, loadName) => {
    const query = `
  query 
{
  partInfo(loadName: "${loadName}") {
    header {
      contentName
      weightInKg
      amount
      createdAt
      modifiedAt
    }
    parts {
      pos
      partName
      partDescription
      amount
      weightInKg
      totalWeightInKg
      orderName
      orderRemark
      type
      intern
    }
  }
}`;
    return fetchGraphJSON(query, {
      location,
    });
  };

  const getProfileData = async (location, loadName) => {
    const query = `
  query 
  {
    profileChart(loadName: "${loadName}") {
      restaust
      surfC
      xUnit
      charts {
        yUnit
        data
      }
    }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  };

  function getFurnaceViewAlarms(location, workplaceId) {
    const query = `
  query{
    furnaceViewAlarms(workplaceId: ${workplaceId}) {
      alarmId
      workplaceName
      startTime
      acknowledgeTime
      endTime
      priority
      alarmMessage
      gate
      alarmNum
      byte
      bit
    }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function getFurnaceViewAlarmsSearch(
    location,
    {
      workplaceId, startTime, endTime,
    },
  ) {
    const query = `
  query{
    furnaceViewAlarmsSearch(workplaceId: ${workplaceId}, startTime: ${dateStringToSeconds(
  startTime,
)}, endTime: ${dateStringToSeconds(endTime)}) {
      workplaceName
      time
      event
      alarmText
      alarmNum
    }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function getFurnaceViewLoadList(
    location,
    {
      workplaceList, startTime, endTime,
    },
  ) {
    const query = `
  query{
  loadList(workplaceIds: ${JSON.stringify(
    workplaceList,
  )}, startTime: ${dateStringToSeconds(
  startTime,
)}, endTime: ${dateStringToSeconds(endTime)}){
        id
        identifier
        name
        workplaceId
        start
        end
        earliestTimeStart
        packedTime
        packedUser
        startedUser
        status
        parts{
            pos
            partName
            partDescription
            amount
            weightInKg
            totalWeightInKg
            orderName
            orderRemark
            customer
            intern
        }
        recipe{
            name
            description
        }
    }
  }
  `;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function getLoadListColumnNames(location) {
    const query = `
  query{
    loadColumnList{
        name
        value
    }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function getWorkplacesGeneralList(location) {
    const query = `
  query{
    workplaceGeneralList {
          id
          name
          description
          inventoryNumber
          type {
              number
              description
              prio
              modifiedOn
              modifiedBy
          }
          active {
              string
              int
          }
          useMode {
              string
              int
          }
          logicType
          recipePrefix
          chamberId
          sharesController 
          belongsTo
          ip
          mGuardIp
          device
          telegramSplit
          deactivateOperations
          aliveBitErrorNum 
          comment
          modifiedOn
          modifiedBy
      }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  const getActualRecipeOfWorkplace = async (location, workplaceId) => {
    const query = `
  query {
  actualRecipeOfWorkplace(workplaceId: ${workplaceId}) {
    header {
      id
      name
      recipePrefix
      description
      comment
      version
      processOption ${TYPE_OPTION_ITEM}
      processType
      processDepth
      processDepthAt
      correction
      diff
      loadName
      loadIdentifier
      actualStep
      startingTime
      user ${TYPE_OPTION_ITEM}
    }
    positions ${TYPE_ACT_RECIPE_POSITION}
    spEntries ${TYPE_SP_ENTRIES}
    material ${TYPE_MATERIAL}
    correctionProfile 
  }
}`;

    try {
      let res = await fetchGraphJSON(query, {
        location,
      });
      res = res?.data?.actualRecipeOfWorkplace;
      return res;
    } catch (err) {
      return err;
    }
  };

  const INPUT_SET_FURNACE_VIEW_BUTTON_VALUE = (
    workplaceId,
    userInputId,
    value,
  ) => `
    workplaceId: ${workplaceId}
    data: {
      userInputId: ${userInputId}
      value: ${value}
    }
  `;

  const TYPE_SET_FURNACE_VIEW_BUTTON_VALUE_RESULT = `{
    value
    isSuccess
    error {
      code
      message
      detail
    }
  }`;

  const setFurnaceViewButtonValue = async (
    location,
    workplaceId,
    userInputId,
    value,
  ) => {
    const mutation = `
    mutation {
      setFurnaceButtonValue( 
        ${INPUT_SET_FURNACE_VIEW_BUTTON_VALUE(workplaceId, userInputId, value)}
      ) ${TYPE_SET_FURNACE_VIEW_BUTTON_VALUE_RESULT}
    }`;
    const response = await fetchGraphJSON(mutation, {
      location,
    });

    return response.data.setFurnaceButtonValue;
  };

  const setFurnaceEditBoxValues = async (
    location,
    workplaceId,
    editBoxEntries,
  ) => {
    const INPUT_SET_FURNACE_VIEW_EDIT_BOX_VALUE = (editBox) => `{
      componentTypeId: ${editBox.componentTypeId},
      userInputId: ${editBox.userInputId},
      outputId: ${editBox.outputId},
      value: ${editBox.value}
    }`;
    const mutation = `
  mutation {
    setFurnaceEditBoxValues(
      workplaceId: ${workplaceId},
      data: [${editBoxEntries
    .map(INPUT_SET_FURNACE_VIEW_EDIT_BOX_VALUE)
    .join(', ')}] ) { 
        isSuccess
        error {
          code
          message
          detail
        }
    }
  }`;

    const response = await fetchGraphJSON(mutation, {
      location,
    });
    return response.data.setFurnaceEditBoxValues;
  };

  const furnaceAcknowledgeAlarms = async (
    location,
    {
      workplaceId, alarmIds,
    },
  ) => {
    const mutation = `
    mutation {
      furnaceAcknowledgeAlarms(workplaceId: ${workplaceId}, alarmIds: ${alarmIds}) {
      alarmId
      workplaceName
      startTime
      acknowledgeTime
      endTime
      priority
      alarmMessage
      gate
      alarmNum
      byte
      bit
      }
    }`;
    return fetchGraphJSON(mutation, {
      location,
    });
  };

  return {
    getActualRecipeOfWorkplace,
    getChartRecorderData,
    getFurnaceView,
    getFurnaceViewAlarms,
    getFurnaceViewAlarmsSearch,
    getFurnaceViewLoadList,
    getLoadListColumnNames,
    getWorkplacesGeneralList,
    setFurnaceViewButtonValue,
    setFurnaceEditBoxValues,
    updateFurnaceViewValues,
    getPartWorkplaceRecipeList,
    getPartList,
    getPartsOfLoad,
    getProfileData,
    furnaceAcknowledgeAlarms,
  };
};
