export default {
  resetZoom: 'Zoom zurücksetzen',
  chartSettings: 'Diagrammeinstellungen',
  exitFullscreen: 'Vollbildmodus beenden',
  fullscreen: 'Vollbild',
  showPoints: 'Punkte anzeigen',
  showNotes: 'Notizen anzeigen',
  leaveNote: 'Eine Notiz hinterlassen (Alt + Klick)',
  zoomMode: 'Zoommodus',
  overFlowMenu: 'Weitere Funktionen',
};
