import * as furnaceService from './furnace.js';
import * as imagesService from './image.js';
import * as materialService from './material.js';
import * as materialGroupService from './materialGroup.js';
import * as shopLayoutService from './shopLayout.js';
import * as recipesService from './recipe.js';
import * as userService from './user';

export const factory = (apiClient: any) => ({
  furnace: furnaceService.create(apiClient),
  images: imagesService.create(apiClient),
  material: materialService.create(apiClient),
  materialGroup: materialGroupService.create(apiClient),
  shopLayout: shopLayoutService.create(apiClient),
  recipes: recipesService.create(apiClient),
  user: userService.create(apiClient),
});
