export const createModule = (services, location) => {
  const {
    getAllShoplayoutsItems,
    getShopLayout,
    updateShopLayoutValues,
  } = services.shopLayout;

  return {
    // initial state
    state: {
      layouts: {},
      layout: [],
      isLoading: false,
    },
    namespaced: true,

    getters: {
      getLayouts(state) {
        return state.layouts;
      },
      getFurnace: (state) => (workplaceId) => {
        const layouts = Object.values(state.layouts);
        for (const layout of layouts) {
          const result = layout.data.find(
            (obj) => obj.workplaceId === Number(workplaceId),
          );
          if (result) {
            return result;
          }
        }
        return null;
      },
    },

    actions: {
      async getAllShopLayouts({
        commit,
      }) {
        return getAllShoplayoutsItems(location, (layouts) => {
          commit('setLayouts', layouts);
          return layouts;
        });
      },

      async getLayout({
        commit,
      }, shopLayoutId) {
        commit('setIsLoading', true);
        try {
          const response = await getShopLayout(location, shopLayoutId);
          commit('setLayout', response);
        } finally {
          commit('setIsLoading', false);
        }
      },

      async updateLayout({
        commit,
      }, shopLayoutId) {
        const response = await updateShopLayoutValues(location, shopLayoutId);
        commit('updateLayout', response);
      },

      async clearLayout({
        commit,
      }) {
        commit('clearLayout');
      },
    },

    // mutations
    mutations: {
      setLayouts(state, layouts) {
        for (const layout of layouts) {
          state.layouts[layout.id] = layout;
        }
      },
      setLayout(state, items) {
        state.layout = items;
      },
      updateLayout(state, items) {
        items.forEach((item) => {
          const index = state.layout.findIndex(
            (obj) => (obj.type === 'ShopLayout' && item.type === 'ShopLayout') || (obj.type === 'Furnace' && obj.workplaceId === item.workplaceId),
          );
          if (index !== -1) {
            state.layout[index] = {
              ...state.layout[index],
              ...item,
            };
          }
        });
      },
      setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
      },
      clearLayout(state) {
        state.layout = [];
      },
    },
  };
};
