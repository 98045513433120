export default {
  name: 'Display name',
  description: 'Description',
  color: 'Color',
  unit: 'Unit',
  meaning: 'Meaning',
  meaningNumber: 'Meaning Instance number',
  numberOfSignificantDigits: 'Significant digits',
  hidden: 'hidden',
  visible: 'visible',
};
