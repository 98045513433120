import DateRangePicker from '../src/component/date-range-picker/date-range-picker.vue';
import DynamicTable from '../src/component/dynamic-table/dynamic-table.vue';
import Icon from '../src/component/icon/icon.vue';
import SearchableDropdown from '../src/component/searchable-dropdown/searchable-dropdown.vue';

export default {
  install: (app : any) => {
    app.component('DateRangePicker', DateRangePicker);
    app.component('DynamicTable', DynamicTable);
    app.component('EIcon', Icon);
    app.component('Icon', Icon);
    app.component('SearchableDropdown', SearchableDropdown);
  },
};
