export default {
  header: {
    create: 'Create API Key',
    update: 'Update API Key',
  },
  warning: {
    title: 'API key is only visible once',
    subtitle: 'You are not able to retrieve the API key again. Please copy and save it in a secure place.',
  },
};
