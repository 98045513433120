import cloneDeep from 'lodash/cloneDeep';
import {
  RecipeService,
} from '@/services/simulator/RecipeService';

export const emptyRecipeDetails = {
  globalLevels: {},
};

export default {
  state: {
    recipes: [],
    loadingRecipes: false,
    selectedRecipe: null,
    recipeDetails: emptyRecipeDetails,
    loadingRecipeDetails: false,
    isEdited: false,
    detailsCloneData: emptyRecipeDetails,
  },

  mutations: {
    setSimulatorRecipes(state, list) {
      state.recipes = list;
    },
    clearSimulatorRecipes(state) {
      state.recipes = [];
    },
    setIsLoadingSimulatorRecipes(state, loading) {
      state.loadingRecipes = loading;
    },
    setSelectedRecipe(state, id) {
      state.selectedRecipe = id;
    },
    setSimulatorRecipeDetails(state, details) {
      state.recipeDetails = details;
    },
    clearSimulatorRecipeDetails(state) {
      state.recipeDetails = emptyRecipeDetails;
    },
    setIsLoadingSimulatorRecipeDetails(state, loading) {
      state.loadingRecipeDetails = loading;
    },
    setIsRecipeEdited(state, isEdited) {
      state.isEdited = isEdited;
    },
    resetChanges(state) {
      state.recipeDetails = cloneDeep(state.detailsCloneData);
    },
    setClonedData(state, details) {
      state.detailsCloneData = cloneDeep(details);
      for (const [
        index,
        stage,
      ] of state.detailsCloneData.stages.entries()) {
        stage.id = index;
      }
    },
  },

  actions: {
    async SET_SIMULATOR_RECIPES(context) {
      context.commit('clearSimulatorRecipes');
      context.commit('setIsLoadingSimulatorRecipes', true);
      try {
        const response = await RecipeService.getRecipesList();
        context.commit('setSimulatorRecipes', response);
      } catch (e) {
        context.commit('clearSimulatorRecipes');
      } finally {
        context.commit('setIsLoadingSimulatorRecipes', false);
      }
    },
    async RECIPE_EDITED(context, isEdited) {
      context.commit('setIsRecipeEdited', isEdited);
    },
    async RESET_RECIPE_CHANGES(context) {
      context.commit('resetChanges');
    },
    async SET_SIMULATOR_RECIPE(context, id) {
      context.commit('clearSimulatorRecipeDetails');
      context.commit('setIsLoadingSimulatorRecipeDetails', true);
      try {
        const response = await RecipeService.getRecipeDetails(id);
        context.commit('setSelectedRecipe', id);
        context.commit('setIsRecipeEdited', false);
        context.commit('setClonedData', response);
        context.commit('setSimulatorRecipeDetails', response);
      } catch (e) {
        context.commit('clearSimulatorRecipeDetails');
      } finally {
        context.commit('setIsLoadingSimulatorRecipeDetails', false);
      }
    },
  },
  getters: {
    getSimulatorRecipesList(state) {
      return state.recipes;
    },
    getSelectedSimulatorRecipe(state) {
      return state.selectedRecipe;
    },
    isSimulatorRecipesListLoading(state) {
      return state.loadingRecipes;
    },
    getSelectedSimulatorRecipeDetails(state) {
      return state.recipeDetails;
    },
    isSimulatorRecipesDetailsLoading(state) {
      return state.loadingRecipeDetails;
    },
    isRecipeEdited(state) {
      return state.isEdited;
    },
    recipeDetailsClonedData(state) {
      return state.detailsCloneData;
    },
  },
};
