import sortBy from 'lodash/sortBy';
import {
  JobService,
} from '@/services/JobService';
import {
  chartColors,
} from '@/config/colorsMap';

export default {
  state: {
    alarms: [],
    isLoadingAlarms: true,
    stageChange: [],
    isLoadingStageChange: true,
    stageSetup: [],
    isLoadingStageSetup: true,
    jobUsage: [],
    isLoadingJobUsage: true,
    jobInfo: {},
    isLoadingJobInfo: true,
    chartData: {},
    isLoadingChart: false,
    location: {},
    isLoadingBatchRating: false,
    batchRating: {},
    chartNotes: [],
    isLoadingNotes: false,
    fetchError: '',
    isLoadingChartSignals: false,
    chartSignals: [],
    odChartSignals: {
      main: [],
      secondary: [],
    },
    isLoadingOdChartSignals: false,
    isUpdatingOdChartSignals: false,
    isSearchingEquipment: false,
    equipmentId: '',
    isUpdatingKPIs: false,
  },

  getters: {
    foundEquipmentId(state) {
      return state.equipmentId;
    },
    isUpdatingKPIs: (state) => {
      return state.isUpdatingKPIs;
    },
  },

  mutations: {
    setIsSearchingEquipment(state, isSearchingEquipment) {
      state.isSearchingEquipment = isSearchingEquipment;
    },
    setEquipmentId(state, equipmentId) {
      state.equipmentId = equipmentId;
    },
    updateChartSignals(state, updatedSignal) {
      const index = state.chartSignals.findIndex(
        (signal) => signal.common_sensor_data_id === updatedSignal.common_sensor_data_id,
      );
      state.chartSignals[index].color = updatedSignal.color;
      state.chartSignals[index].custom_name = updatedSignal.custom_name;
    },
    setIsLoadingChartSignals(state, isLoading) {
      state.isLoadingChartSignals = isLoading;
    },
    setIsLoadingOdChartSignals(state, isLoading) {
      state.isLoadingOdChartSignals = isLoading;
    },
    setIsUpdatingOdChartSignals(state, isLoading) {
      state.isUpdatingOdChartSignals = isLoading;
    },
    setOdChartSignals(state, chartSignals) {
      const main = [];
      const secondary = [];
      chartSignals.forEach((sensor) => {
        if (sensor.is_main) {
          main.push(sensor.common_sensor_data_id);
        } else {
          secondary.push(sensor.common_sensor_data_id);
        }
      });
      state.odChartSignals = {
        main,
        secondary,
      };
    },
    setChartSignals(state, chartSignals) {
      state.chartSignals = chartSignals;
    },
    setAlarms(state, alarms) {
      state.alarms = alarms;
    },
    setStageChange(state, stageChange) {
      state.stageChange = stageChange;
    },
    setStageSetup(state, stageSetup) {
      state.stageSetup = stageSetup;
    },
    setJobUsage(state, jobUsage) {
      state.jobUsage = jobUsage;
    },
    setBatchRating(state, batchRating) {
      state.batchRating = batchRating;
    },
    setIsLoadingBatchRating(state, isLoading) {
      state.isLoadingBatchRating = isLoading;
    },
    setIsLoadingAlarms(state, isLoading) {
      state.isLoadingAlarms = isLoading;
    },
    setIsLoadingStageChange(state, isLoading) {
      state.isLoadingStageChange = isLoading;
    },
    setIsLoadingStageSetup(state, isLoading) {
      state.isLoadingStageSetup = isLoading;
    },
    setIsLoadingJobUsage(state, isLoading) {
      state.isLoadingJobUsage = isLoading;
    },
    setJobInfo(state, jobInfo) {
      state.jobInfo = jobInfo;
    },
    setLocationInfo(state, locationInfo) {
      state.location = locationInfo;
    },
    setIsLoadingJobInfo(state, isLoading) {
      state.isLoadingJobInfo = isLoading;
    },
    setChartData(state, chartData) {
      state.chartData = chartData;
    },
    setIsLoadingChart(state, isLoading) {
      state.isLoadingChart = isLoading;
    },
    setChartNotes(state, notes) {
      state.chartNotes = notes;
    },
    setIsUpdatingKPIs(state, isLoading) {
      state.isUpdatingKPIs = isLoading;
    },
    updateChartNotes(state, newNote) {
      const indexOfNote = state.chartNotes.findIndex(
        (note) => note.chart_note_id === newNote.chart_note_id,
      );
      if (indexOfNote >= 0) {
        state.chartNotes[indexOfNote].note = newNote.note;
      } else {
        state.chartNotes.push(newNote);
      }
    },
    deleteChartNote(state, removedNoteId) {
      const indexOfNote = state.chartNotes.findIndex(
        (note) => note.chart_note_id === removedNoteId.chart_note_id,
      );
      if (indexOfNote >= 0) {
        state.chartNotes.splice(indexOfNote, 1);
      }
    },
    setIsLoadingChartNotes(state, isLoading) {
      state.isLoadingNotes = isLoading;
    },
    clearEquipmentJob(state) {
      state.alarms = [];
      state.stageChange = [];
      state.jobUsage = [];
      state.jobInfo = {};
      state.chartData = {};
      state.notes = [];
    },
    setFetchError(state, error) {
      state.fetchError = error;
    },
  },

  actions: {
    CLEAR_EQUIPMENT_JOB(context) {
      context.commit('clearEquipmentJob');
    },
    async SET_CHART_NOTES(context, payload) {
      context.commit('setIsLoadingChartNotes', true);
      context.commit('setChartNotes', []);
      try {
        context.commit('setChartNotes', await JobService.getNotes(payload));
      } finally {
        context.commit('setIsLoadingChartNotes', false);
      }
    },
    async DELETE_CHART_NOTE(context, payload) {
      context.commit('setIsLoadingChartNotes', true);
      try {
        context.commit('deleteChartNote', await JobService.deleteNote(payload));
      } finally {
        context.commit('setIsLoadingChartNotes', false);
      }
    },
    async UPDATE_CHART_NOTES(context, payload) {
      context.commit('setIsLoadingChartNotes', true);
      try {
        context.commit(
          'updateChartNotes',
          await JobService.updateNotes(payload),
        );
      } finally {
        context.commit('setIsLoadingChartNotes', false);
      }
    },
    async SET_ALARMS(context, payload) {
      context.commit('setIsLoadingAlarms', true);
      context.commit('setAlarms', []);
      try {
        context.commit('setAlarms', await JobService.getJobAlarms(payload));
      } finally {
        context.commit('setIsLoadingAlarms', false);
      }
    },
    async SET_STAGE_CHANGE(context, payload) {
      context.commit('setIsLoadingStageChange', true);
      context.commit('setStageChange', []);
      try {
        context.commit(
          'setStageChange',
          await JobService.getJobStageChange(payload),
        );
      } finally {
        context.commit('setIsLoadingStageChange', false);
      }
    },
    async SET_JOB_USAGE(context, payload) {
      context.commit('setIsLoadingJobUsage', true);
      context.commit('setJobUsage', []);
      try {
        context.commit('setJobUsage', await JobService.getJobUsage(payload));
      } finally {
        context.commit('setIsLoadingJobUsage', false);
      }
    },
    async SET_JOB_INFO(context, payload) {
      context.commit('setIsLoadingJobInfo', true);
      context.commit('setJobInfo', {});
      try {
        context.commit('setJobInfo', await JobService.getJobInfo(payload));
      } catch (e) {
        context.commit('setFetchError', e.response ? e.response.data : e);
      } finally {
        context.commit('setIsLoadingJobInfo', false);
      }
    },
    async SET_BATCH_RATING(context, payload) {
      context.commit('setIsLoadingBatchRating', true);
      context.commit('setBatchRating', {});
      try {
        context.commit(
          'setBatchRating',
          await JobService.getBatchRating(payload),
        );
      } finally {
        context.commit('setIsLoadingBatchRating', false);
      }
    },
    async UPDATE_BATCH_RATING(context, batchRun) {
      context.commit('setIsLoadingBatchRating', true);
      try {
        context.commit(
          'setBatchRating',
          await JobService.updateBatchRating(batchRun),
        );
      } finally {
        context.commit('setIsLoadingBatchRating', false);
      }
    },
    async SET_CHART_SIGNALS(context) {
      context.commit('setIsLoadingChartSignals', true);
      context.commit('setChartSignals', []);
      try {
        const chartSignals = await JobService.getChartSignals();
        for (const signal of chartSignals) {
          if (signal.color === null) {
            signal.color = signal.default_color;
          }
        }
        context.commit('setChartSignals', chartSignals);
      } finally {
        context.commit('setIsLoadingChartSignals', false);
      }
    },
    async UPDATE_SIGNAL_CONFIG(context, payload) {
      try {
        context.commit(
          'updateChartSignals',
          await JobService.updateChartSignal(payload),
        );
      } catch (e) {
        return e.response.status;
      }
    },
    async SET_OD_CHART_SIGNALS(context) {
      context.commit('setIsLoadingOdChartSignals', true);
      context.commit('setOdChartSignals', []);
      try {
        const chartSignals = await JobService.getOdChartSignals();
        context.commit('setOdChartSignals', chartSignals);
      } finally {
        context.commit('setIsLoadingOdChartSignals', false);
      }
    },
    async ADD_OD_CHART_SIGNAL(context, payload) {
      context.commit('setIsUpdatingOdChartSignals', true);
      try {
        await JobService.addODChartSignal(payload);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsUpdatingOdChartSignals', false);
      }
    },
    async REMOVE_OD_CHART_SIGNAL(context, payload) {
      context.commit('setIsUpdatingOdChartSignals', true);
      try {
        await JobService.deleteODChartSignal(payload);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsUpdatingOdChartSignals', false);
      }
    },
    async UPDATE_KPIS(context, payload) {
      context.commit('setIsUpdatingKPIs', true);
      try {
        await JobService.updateKPIs(payload);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsUpdatingKPIs', false);
      }
    },
    async SET_CHART_DATA(context, payload) {
      context.commit('setIsLoadingChart', true);
      context.commit('setChartData', {});
      try {
        const jobChart = await JobService.getJobChart(payload);
        for (const job of jobChart.chart.datasets) {
          job.job_id = payload.jobId;
          if (job.is_data_mapped === false) {
            job.borderColor = chartColors[job.descMod];
            job.backgroundColor = chartColors[job.descMod];
          } else {
            job.backgroundColor = job.borderColor;
          }
        }
        for (const legend of jobChart.legend) {
          if (legend.is_data_mapped === false) {
            legend.color = chartColors[legend.descMod];
          } else {
            const dataset = jobChart.chart.datasets.find(
              (job) => job.id === legend.id,
            );
            legend.color = dataset.borderColor;
          }
        }
        jobChart.legend = sortBy(jobChart.legend, 'name');
        context.commit('setChartData', jobChart);
      } catch (e) {
        context.commit('setFetchError', e.response ? e.response.data : e);
      } finally {
        context.commit('setIsLoadingChart', false);
      }
    },
    async SET_EQUIPMENT_ID(
      context,
      {
        location_name: locationName, system_id: systemId,
      },
    ) {
      context.commit('setIsSearchingEquipment', true);
      context.commit('setEquipmentId', '');
      try {
        context.commit(
          'setEquipmentId',
          await JobService.searchForEquipment({
            locationName,
            systemId,
          }),
        );
      } finally {
        context.commit('setIsSearchingEquipment', false);
      }
    },
  },
};
