import {
  components,
} from './openapi';

export enum EHierarchy {
  ROOT = -10,
  COMPANY = 0,
  LOCATION = 10,
  EQUIPMENT_GROUP = 15,
  EQUIPMENT = 20,
  CHAMBER = 30,
  COMPONENT = 40,
}

export const HierarchyMap: Record<components['schemas']['AssetHierarchyLevel'], keyof typeof EHierarchy> = {
  '-10': 'ROOT',
  0: 'COMPANY',
  10: 'LOCATION',
  15: 'EQUIPMENT_GROUP',
  20: 'EQUIPMENT',
  30: 'CHAMBER',
  40: 'COMPONENT',
};
