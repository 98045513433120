import {
  TCsvQuoteStyleTranslations,
} from './select-csv-quote-style.ts';

const translations: TCsvQuoteStyleTranslations = {
  necessary: {
    title: 'Necessary',
    description: 'This puts quotes around fields only when necessary. They are necessary when fields contain a quote, separator or record terminator. Quotes are also necessary when writing an empty record (which is indistinguishable from a record with one empty field). This is the default.',
  },
  always: {
    title: 'Always',
    description: 'This puts quotes around every field. Always.',
  },
  non_numeric: {
    title: 'Non-numeric',
    description: 'This puts quotes around all fields that are non-numeric. Namely, when writing a field that does not parse as a valid float or integer, then quotes will be used even if they aren\'t strictly necessary.',
  },
  never: {
    title: 'Never',
    description: 'This never puts quotes around fields, even if that results in invalid CSV data (e.g.: by not quoting strings containing the separator).',
  },
};

export default {
  placeholder: '',
  label: 'Quote style',
  options: translations,
};
