import {
  MAINTENANCE_DASHBOARDS_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const MaintenanceDashboardService = {
  getFurnaces({
    customerId, locationId, groupId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_DASHBOARDS_PATH}`, {
          params: {
            customerId,
            locationId,
            groupId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFurnaceDetails(equipment_id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_DASHBOARDS_PATH}/details/${equipment_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
