export default {
  simulation: {
    header: 'Simulation',
    secondsPerCalculationStepLabel: 'Seconds per calculation step',
    secondsPerCalculationStepPlaceholder: 'Enter seconds per calculation step',
    secondsPerCalculationStepDescription: 'Number of seconds calculated per simulation step',
    factorOnCBetaLabel: 'Factor on C-Beta',
    factorOnCBetaDescription: 'Adjust carbon transfer coefficient',
    factorOnCDiffCoeffLabel: 'Factor on C-Diff-Coeff',
    factorOnCDiffCoeffDescription: 'Adjust carbon diffusion coefficient',
  },
  lowPressureCarburizingOnly: {
    header: 'Low-Pressure Carburizing only',
    maxCInCementiteLabel: 'Max C in Cementite',
    maxCInCementiteDescription: 'Upper limit for auto boost hysteresis',
    cBelowCarbidelimitLabel: 'C Below Carbidelimit',
    cBelowCarbidelimitDescription: 'Lower limit for auto boost hysteresis',
  },
  showComponentSelectionWhenLoadingCheckbox: 'Show component selection when loading',
};
