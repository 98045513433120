import {
  ROUTE_NAME,
} from '../../utils/routeBuilder/constant.js';

export const createModule = (services) => {
  const {
    getPermittedRoutes,
  } = services.user;

  class ApplicationAccess {
    constructor(name, arg, accessRights, accessLevel) {
      this.name = name;
      this.arg = arg;
      this.accessRights = accessRights;
      this.accessLevel = accessLevel;
    }

    isApplicationEqual(app) {
      return this.name === app.name && this.arg === app.arg;
    }
  }

  return {
    namespaced: true,
    state: {
      initializing: false,
      appsAccessByLocation: {},
      routesByLocation: {},
    },

    actions: {
      deinit({
        commit,
      }) {
        commit('reset');
      },
      async init({
        commit,
      }, location) {
        try {
          commit('setInitializing', true);

          const routes = await getPermittedRoutes(location);

          const appsAccess = [];
          const groups = Object.keys(routes);

          const createAppAccess = ({
            routeName,
            id,
            accessRights,
            accessLevel,
          }) => new ApplicationAccess(routeName, id, accessRights, accessLevel);

          groups.forEach((group) => {
            const route = routes[group];

            if (group === 'Visualization') {
              Object.keys(route).forEach((childGroup) => {
                const childRoute = route[childGroup];

                appsAccess.push(
                  createAppAccess({
                    ...childRoute,
                    routeName: ROUTE_NAME.VISUALIZATION,
                    id: childRoute.id,
                  }),
                );
                appsAccess.push(
                  ...childRoute.children.map((child) => createAppAccess({
                    ...child,
                    id: child.id,
                    routeName: ROUTE_NAME.FURNACE_VIEW,
                  })),
                );
              });
            } else if (Array.isArray(route.children)) {
              if (group === 'Recipes') {
                appsAccess.push(
                  ...route.children.map((child) => createAppAccess({
                    ...child,
                    id: child.workplaceId,
                    routeName: ROUTE_NAME.RECIPES,
                  })),
                );
              } else {
                route.children.forEach((child) => {
                  if (child.routeName) {
                    appsAccess.push(createAppAccess(child));
                  }
                });
              }
            }
          });

          commit('setRoutes', {
            location,
            routes,
          });
          commit('setAppsAccess', {
            location,
            appsAccess,
          });
        } catch (e) {
          commit('setRoutes', {
            location,
            routes: [],
          });
          commit('setAppsAccess', {
            location,
            appsAccess: [],
          });
          throw e;
        } finally {
          commit('setInitializing', false);
        }
      },
    },

    mutations: {
      reset(state) {
        state.accessListByLocation = {};
        state.routesByLocation = {};
      },
      setAppsAccess(state, {
        location, appsAccess,
      }) {
        state.appsAccessByLocation = {
          ...state.appsAccessByLocation,
          [location]: appsAccess,
        };
      },
      setInitializing(state, flag) {
        state.initializing = flag;
      },
      setRoutes(state, {
        location, routes,
      }) {
        state.routesByLocation = {
          ...state.routesByLocation,
          [location]: routes,
        };
      },
    },

    getters: {
      getRoutes: (state) => (location) => state.routesByLocation[location],
      getPermissions: (state) => (location, application) => {
        const permissions = state.appsAccessByLocation[location]?.find((item) => item.isApplicationEqual(application));
        return (
          permissions
          || new ApplicationAccess(application.name, application.arg, {})
        );
      },
    },
  };
};
