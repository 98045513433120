import {
  i18n,
} from '@i18n';
import {
  consumptionMeaningMap,
  jobFeatureMeaningMap,
} from './meaning-type.ts';

export function getConsumptionMeaningListWithTranslations(): {
  id: keyof typeof consumptionMeaningMap,
  displayName: string,
}[] {
  return Object
    .keys(consumptionMeaningMap)
    .map((key) => ({
      id: parseInt(key, 10) as keyof typeof consumptionMeaningMap,
      displayName: i18n.global.t(`api.meaning.${key}`),
    }));
}

export function getConsumptionMeaningKeyList(): (keyof typeof consumptionMeaningMap)[] {
  return Object
    .keys(consumptionMeaningMap)
    .map((key) => parseInt(key, 10)) as (keyof typeof consumptionMeaningMap)[];
}

export function getJobFeatureMeaningListWithTranslations(): {
  id: keyof typeof jobFeatureMeaningMap,
  displayName: string,
}[] {
  return Object
    .keys(jobFeatureMeaningMap)
    .map((key) => ({
      id: parseInt(key, 10) as keyof typeof jobFeatureMeaningMap,
      displayName: i18n.global.t(`api.meaning.${key}`),
    }));
}

export function getJobFeatureMeaningKeyList(): (keyof typeof jobFeatureMeaningMap)[] {
  return Object
    .keys(jobFeatureMeaningMap)
    .map((key) => parseInt(key, 10)) as (keyof typeof jobFeatureMeaningMap)[];
}
