<template>
  <v-container
    fluid
    class="pb-0"
  >
    <v-btn-toggle
      v-model="selectedRelativePeriodBtn"
      :divided="false"
      class="btn-toggle-group"
    >
      <div
        v-for="(item, idx) in relativePeriodsOptions"
        :key="`period-${idx}`"
        class="mb-3"
      >
        <p
          class="mb-1"
          style="font-weight: bold"
        >
          {{ $t(`dateRangePicker.${item.period}`) }}
        </p>
        <div class="d-flex flex-wrap">
          <v-btn
            v-for="(itemValue, index) in item.values"
            :key="`value-${index}-${idx}`"
            :elevation="0"
            :value="`${itemValue}${item.periodIndex}`"
            class="relative-select-buttons mr-1"
            @click="setDate(item.periodIndex, itemValue)"
          >
            {{ itemValue }}
          </v-btn>
        </div>
      </div>
    </v-btn-toggle>
    <v-row>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model.number="currentPeriodValue"
          type="number"
          :min="0"
          max-length="2"
          @change="setDate(currentPeriodIndex, currentPeriodValue)"
        />
      </v-col>

      <v-col
        cols="6"
      >
        <v-select
          v-model="currentPeriodIndex"
          :items="periodList"
          :item-title="item => getPeriodsOptionsText(item)"
          item-value="index"
          @blur="setDate(currentPeriodIndex, currentPeriodValue)"
        >
          <template #selection>
            {{
              getSelectedPeriodText(currentPeriodIndex)
            }}
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  trim,
} from 'lodash';
import moment from 'moment';

export const PERIODS = Object.freeze({
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
});

export const relativePeriodsOptions = [
  {
    period: PERIODS.DAYS,
    values: [
      1,
      2,
      3,
      4,
      5,
      6,
    ],
    periodIndex: 'd',
  },
  {
    period: PERIODS.WEEKS,
    values: [
      1,
      2,
      4,
      6,
    ],
    periodIndex: 'w',
  },
  {
    period: PERIODS.MONTHS,
    values: [
      3,
      6,
      12,
      15,
    ],
    periodIndex: 'm',
  },
  {
    period: PERIODS.YEARS,
    values: [
      1,
      2,
      3,
      4,
    ],
    periodIndex: 'y',
  },
];

export const periodList = [
  {
    period: PERIODS.DAYS,
    index: 'd',
  },
  {
    period: PERIODS.WEEKS,
    index: 'w',
  },
  {
    period: PERIODS.MONTHS,
    index: 'm',
  },
  {
    period: PERIODS.YEARS,
    index: 'y',
  },
];

export default {
  name: 'DateRangePickerRelative',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    selectedPeriod: {
      type: String,
      default: null,
    },
  },
  emits: [
    'selectedRelativePeriodText',
    'selectBtnFromMainPanel',
    'update:modelValue',
  ],
  data() {
    return {
      selectedRelativePeriodBtn: null,
      currentPeriodIndex: null,
      currentPeriodValue: null,
      periodList,
      relativePeriodsOptions,
    };
  },
  mounted() {
    if (this.selectedPeriod && this.selectedPeriod !== 'currentWeek') {
      const period = trim(this.selectedPeriod);
      if (period.length === 2) {
        this.selectedRelativePeriodBtn = this.selectedPeriod;
        [
          this.currentPeriodValue,
          this.currentPeriodIndex,
        ] = this.selectedPeriod;
      } else {
        this.selectedRelativePeriodBtn = period[1] + period[2];
        [
          , this.currentPeriodValue,
          this.currentPeriodIndex,
        ] = period;
      }
    }
  },
  methods: {
    getPeriodsOptionsText(item) {
      if (item?.period) {
        return this.$t(`dateRangePicker.${item.period}`);
      } return '';
    },
    getSelectedPeriodText(item) {
      if (item) {
        const {
          period,
        } = periodList.find(({
          index,
        }) => index === item);
        return this.$t(`dateRangePicker.${period}`);
      } return '';
    },
    setDate(periodIndex, value) {
      this.currentPeriodIndex = periodIndex;
      this.currentPeriodValue = value;

      this.selectBtnFromMainPanel();
      const {
        period,
      } = this.relativePeriodsOptions.find((item) => item.periodIndex === periodIndex);
      this.selectedRelativePeriodBtn = `${value}${periodIndex}`;

      const startDate = moment().subtract(value, period);

      this.$emit('update:modelValue', {
        from: startDate,
        to: moment(),
      });
    },
    selectBtnFromMainPanel() {
      if (this.currentPeriodIndex === 'w' && this.currentPeriodValue === 4) {
        this.$emit('selectBtnFromMainPanel', '1m');
      } else if ((this.currentPeriodIndex === 'd' && this.currentPeriodValue === 7) || (this.currentPeriodIndex === 'w' && this.currentPeriodValue === 1)) {
        this.$emit('selectBtnFromMainPanel', '7d');
      } else if (this.currentPeriodIndex === 'm' && this.currentPeriodValue === 3) {
        this.$emit('selectBtnFromMainPanel', '3m');
      } else if ((this.currentPeriodIndex === 'y' && this.currentPeriodValue === 1) || (this.currentPeriodIndex === 'm' && this.currentPeriodValue === 12)) {
        this.$emit('selectBtnFromMainPanel', '1y');
      } else {
        this.$emit('selectBtnFromMainPanel', null);
      }
      const text = `${this.currentPeriodValue}${this.currentPeriodIndex[0].toLowerCase()}`;
      this.$emit('selectedRelativePeriodText', text);
    },
  },
};
</script>

<style scoped lang="scss">
@use './date-range-picker.scss';
</style>
