import {
  components,
} from './openapi';

export enum EAssetProfileCombinator {
  ANY_OF = 'anyOf',
  ALL_OF = 'allOf',
  ONE_OF = 'oneOf',
}

export const AssetProfileCombinatorMap: Record<components['schemas']['CombinationTypes'], keyof typeof EAssetProfileCombinator> = {
  anyOf: 'ANY_OF',
  allOf: 'ALL_OF',
  oneOf: 'ONE_OF',
};
