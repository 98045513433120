import {
  components,
} from '@/types/openapi.ts';

export enum EAssetProfileStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  INVALID = 'invalid',
}

export const AssetProfileStatusMap: Record<components['schemas']['MappingState'], keyof typeof EAssetProfileStatus> = {
  complete: 'COMPLETE',
  incomplete: 'INCOMPLETE',
  invalid: 'INVALID',
};
