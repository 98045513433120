export default {
  general: {
    header: 'General properties',
    heatTreatmentTypeLabel: 'Heat treatment type',
    coLabel: 'CO',
    h2Label: 'H2',
    balanceN2Label: 'Balance N2',
    fixedRadio: 'Fixed',
    variableRadio: 'Variable',
    h2Balance: 'Balance H2',
  },
  simulationValue: {
    header: 'Simulation values',
  },
  recipeStage: {
    header: 'Recipe stage',
    totalDurationLabel: 'Total duration:',
  },
  savePartAs: {
    header: 'Save part as',
  },
};
