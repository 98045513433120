export default {
  header: 'Bearbeiten',
  general: {
    tabLabel: 'Allgemein',
    furnaceNameLabel: 'Ofenname',
    furnaceTypeLabel: 'Ofentyp',
    departmentLabel: 'Abteilung',
  },
  certificate: {
    tabLabel: 'Zertifikate',
    ams: {
      amsGroupLabel: 'AMS',
      pyrometry: {
        pyrometryCheckbox: 'AMS2750: Pyrometrie',
        furnaceClass: 'Ofenklasse',
        instrumentationType: 'Instrumentierungstyp',
      },
      heatTreatmentOfSteelParts: 'AMS2759: Wärmebehandlung von Stahlteilen',
    },
    others: {
      objectthersGroupLabel: 'Andere',
      newCertificatesPlaceholder: 'neue sonstige Zertifikate',
      instrumentationTypePlaceholder: 'Instrumentierungstyp',
    },
  },
  capability: {
    tabLabel: 'Fähigkeiten',
    otherCapabilitiesPlaceholder: 'Andere Fähigkeiten',
    temperature: {
      temperatureGroupLabel: 'Temperatur',
      maxTempLabel: 'Maximaltemperatur',
      minTempLabel: 'Minimaltemperatur',
    },
    dimensions: {
      dimensionsGroupLabel: 'Abmessungen',
      diameterLabel: 'Durchmesser',
      widthLabel: 'Breite',
      lengthLabel: 'Länge',
      heightLabel: 'Höhe',
      maxLoadLabel: 'Maximale Belastung',
    },
  },
  statistics: {
    targetProductivityLabel: 'Produktivitätsziel',
  },
};
