import {
  SimulatorService,
} from '@/services/SimulatorService';
import {
  COLOR,
} from '@/constants/chartColors';

export const POINT_STYLE_BY_PLOT_ID = Object.freeze({
  'Required carbon at carb. depth': 'cross',
  'Required hardness at case depth': 'rectRot',
});

export const Y_AXIS_TYPES = Object.freeze({
  C_N_POTENTIAL_WT_PCT: 'C / N Potential [wt%]',
  C_N_CONTENT_WT_PCT: 'C / N Content [wt%]',
  C_N_CONTENT_AND_POTENTIAL_WT_PCT: 'C / N Content and Potential [wt%]',
  HARDNESS_HV: 'Hardness [HV]',
  TEMPERATURE_C: 'Temperature [°C]',
});

// y-axis max used to align C/N scales of charts: profile, profile overview and process values
export const Y_AXIS_MAX_VALUES = Object.freeze({
  [Y_AXIS_TYPES.TEMPERATURE_C]: 1000,
  [Y_AXIS_TYPES.C_N_CONTENT_AND_POTENTIAL_WT_PCT]: 1.3,
});

const emptyChart = {
  label: '',
  data: [],
};

export const transformToChartDataFormat = (response) => {
  const data = [];
  response.x.values.forEach((x, i) => {
    data.push({
      x,
      y: response.y.values[i],
    });
  });
  return data;
};

const lineStyleForPlot = (id) => {
  const borderDash = [];

  switch (true) {
    case id.endsWith('min'): {
      borderDash.push(6, 2);
      break;
    }
    case id.endsWith('max'): {
      borderDash.push(2, 2);
      break;
    }
    default:
      break;
  }
  return {
    borderDash,
  };
};

export const transformChartProfile = (profile) => {
  const datasets = [];
  for (const dataset of profile) {
    const yAxisID = `${dataset.yAxisLabel}`;
    for (const plot of dataset.plots) {
      if (plot.type === 'point') {
        datasets.push({
          name: plot.id,
          label: plot.id,
          type: 'bubble',
          order: 1,
          borderColor: 'rgb(0,0,0)',
          backgroundColor: 'rgb(0,0,0)',
          legendType: 'circle',
          pointStyle: POINT_STYLE_BY_PLOT_ID[plot.id] || 'circle',
          data: [
            [
              plot.x.value,
              plot.y.value,
              6,
            ],
          ],
          yAxisID,
        });
      } else if (
        plot.id === 'Carbon profile'
        || plot.id === 'Carbide profile'
        || plot.id === 'Nitrogen profile'
        || plot.id === 'Nitride profile'
      ) {
        const data = transformToChartDataFormat(plot);
        datasets.push({
          type: 'bar',
          order: 2,
          name: plot.id,
          label: plot.id,
          xAxisUnit: dataset.xAxisLabel,
          yAxisUnit: dataset.yAxisLabel,
          pointRadius: 0,
          showLine: true,
          fill: true,
          legendType: 'rect',
          ...lineStyleForPlot(plot.id),
          borderColor: COLOR[plot.id],
          backgroundColor: COLOR[plot.id],
          data,
          yAxisID,
        });
      } else {
        const data = transformToChartDataFormat(plot);
        datasets.push({
          name: plot.id,
          label: plot.id,
          order: 3,
          xAxisUnit: dataset.xAxisLabel,
          yAxisUnit: dataset.yAxisLabel,
          pointRadius: 0,
          showLine: true,
          legendType: 'rect',
          ...lineStyleForPlot(plot.id),
          borderColor: COLOR[plot.id],
          backgroundColor: COLOR[plot.id],
          data,
          yAxisID,
        });
      }
    }
  }
  return datasets;
};

export default {
  state: {
    isLoadingProfileChartData: false,
    profileChartData: [
      emptyChart,
    ],
    isLoadingProfileOverviewChartData: false,
    profileOverviewChartData: [
      emptyChart,
    ],
    isLoadingProcessValuesChartData: false,
    processValuesChartData: [
      emptyChart,
    ],
    isLoadingChartStream: false,
    chartStreamTimeFrames: {},
    chartStreamLastStage: null,
    chartStreamLastUsedParams: {},
    chartStreamCurrentTime: null,
    chartStreamFixedEndTime: null,
  },

  getters: {
    getProfileChartData(state) {
      return state.profileChartData;
    },
    getProfileOverviewChartData(state) {
      return state.profileOverviewChartData;
    },
    getProcessValuesChartData(state) {
      return state.processValuesChartData;
    },
    isLoadingProfileChartData(state) {
      return state.isLoadingProfileChartData;
    },
    isLoadingProcessValuesChartData(state) {
      return state.isLoadingProcessValuesChartData;
    },
    isLoadingProfileOverviewChartData(state) {
      return state.isLoadingProfileOverviewChartData;
    },
  },

  mutations: {
    setChartStreamLastUsedParams(state, params) {
      state.chartStreamLastUsedParams = params;
    },
    setChartStreamCurrentTime(state, time) {
      state.chartStreamCurrentTime = time;
    },
    setChartStreamFixedEndTime(state, time) {
      state.chartStreamFixedEndTime = time;
    },
    clearChartStreamResults(state) {
      state.chartStreamLastStage = null;
      state.chartStreamTimeFrames = {};
    },
    appendChartStreamResults(state, results) {
      let lastStage = state.chartStreamLastStage;

      state.chartStreamTimeFrames = results.reduce(
        (acc, result, resultIndex) => {
          result.profile.forEach((profile) => {
            acc[profile.elapsedSeconds] = {
              stage: profile.stage,
              profile: profile.profile,
              profileOverview: profile.profileOverview,
              resultIndex,
            };
            if (lastStage === null || profile.stage !== lastStage) {
              acc[profile.elapsedSeconds].stageChanged = true;
              acc[profile.elapsedSeconds].elapsedSeconds = profile.elapsedSeconds;
              lastStage = profile.stage;
            }
          });
          return acc;
        },
        {
          ...state.chartStreamTimeFrames,
        },
      );

      state.chartStreamLastStage = lastStage;
    },
    setIsLoadingChartStream(state, loading) {
      state.isLoadingChartStream = loading;
    },
    setProfileChartData(state, chartData) {
      state.profileChartData = chartData;
    },
    appendProcessValuesChartData(state, arrayOfProcessValues) {
      const datasets = state.processValuesChartData;

      // use Map improve accessing datasets in loop
      const memo = datasets.reduce((acc, ds) => {
        acc.set(ds.name, ds);
        return acc;
      }, new Map());

      arrayOfProcessValues.forEach((response) => {
        for (const dataset of response) {
          const yAxisID = `${dataset.yAxisLabel}`;
          for (const plot of dataset.plots) {
            const data = transformToChartDataFormat(plot);
            let ds = memo.get(plot.id);
            if (!ds) {
              // console.log("add process values ds", plot.id);
              ds = {
                name: plot.id,
                label: plot.id,
                pointRadius: 0,
                showLine: true,
                ...lineStyleForPlot(plot.id),
                borderColor: COLOR[plot.id],
                backgroundColor: COLOR[plot.id],
                data: [],
                yAxisID: `${yAxisID}`,
              };
              memo.set(plot.id, ds);
            }
            ds.data = ds.data.concat(data);
          }
        }
      });

      state.processValuesChartData = Array.from(memo.values());
    },
    updateProfileChartData(state, chartData) {
      chartData.forEach((newDataset) => {
        const dataset = state.profileChartData.find(
          (ds) => ds.name === newDataset.name,
        );
        if (dataset) {
          dataset.data = newDataset.data;
        } else {
          state.profileChartData.push(newDataset);
        }
      });
    },
    setProfileOverviewChartData(state, chartData) {
      state.profileOverviewChartData = chartData;
    },
    setProcessValuesChartData(state, chartData) {
      state.processValuesChartData = chartData;
    },
    setIsLoadingProfileChartData(state, loading) {
      state.isLoadingProfileChartData = loading;
    },
    setIsLoadingProfileOverviewChartData(state, loading) {
      state.isLoadingProfileOverviewChartData = loading;
    },
    setIsLoadingProcessValuesChartData(state, loading) {
      state.isLoadingProcessValuesChartData = loading;
    },
  },

  actions: {
    async CLEAR_SIMULATOR_CHARTS_DATA(context) {
      await SimulatorService.cancelSimulatorChartDataStream();

      context.commit('setProfileChartData', [
        emptyChart,
      ]);
      context.commit('setProfileOverviewChartData', [
        emptyChart,
      ]);
      context.commit('setChartStreamCurrentTime', null);
      context.commit('clearChartStreamResults');
    },
    async CLEAR_SIMULATOR_PROCESS_CHARTS_DATA(context) {
      context.commit('setProcessValuesChartData', [
        emptyChart,
      ]);
    },
    async APPEND_CHART_STREAM_RESULTS(context, results) {
      const overviewPlots = [
        'carbon potential',
        'nitrogen potential',
        'soot limit',
        'temperature',
      ];
      results.forEach((result) => {
        // prepare profileOverview chart data for every profile
        const profileIndexSeconds = {};
        result.profile.forEach((profile, index) => {
          profile.profileOverview = [];
          profileIndexSeconds[profile.elapsedSeconds] = index;
        });
        // get profile overview plots by matching x values from processValues plots with profile elapsedSeconds
        result.processValues.forEach((dataset) => {
          const yAxisID = `${dataset.yAxisLabel}`;
          for (const plot of dataset.plots) {
            if (overviewPlots.includes(plot.id.toLowerCase())) {
              plot.x.values.forEach((x, i) => {
                if (Object.prototype.hasOwnProperty.call(profileIndexSeconds, x)) {
                  const profileIndex = profileIndexSeconds[x];
                  const datasets = result.profile[profileIndex].profileOverview;

                  const resolvedYAxisID = [
                    // mapping from process values y-axis to profile overview y-axis
                    Y_AXIS_TYPES.C_N_CONTENT_AND_POTENTIAL_WT_PCT,
                    Y_AXIS_TYPES.C_N_CONTENT_WT_PCT, // TODO: temporary fix BE data - remove when BE response is fixed
                  ].includes(yAxisID)
                    ? Y_AXIS_TYPES.C_N_POTENTIAL_WT_PCT
                    : yAxisID;

                  datasets.push({
                    id: plot.id,
                    name: plot.id,
                    label: '',
                    data: [
                      plot.y.values[i],
                    ],
                    yAxisID: resolvedYAxisID,
                    backgroundColor: COLOR[plot.id],
                  });

                  datasets.sort((a, b) => {
                    return a.name.length - b.name.length;
                  });
                }
              });
            }
          }
        });
      });

      context.commit('appendChartStreamResults', results);
      context.commit(
        'appendProcessValuesChartData',
        results.reduce((acc, result) => {
          acc.push(result.processValues);
          return acc;
        }, []),
      );
    },
    async SET_PROCESS_VALUE_CHART(context, {
      payload, calculationModel,
    }) {
      context.commit('setIsLoadingProcessValuesChartData', true);
      try {
        const response = await SimulatorService.getSimulatorChartData(payload, {
          chartType: 'process_values',
          calculationModel,
        });
        const datasets = [];
        for (const dataset of response) {
          const yAxisID = `${dataset.yAxisLabel}`;
          for (const plot of dataset.plots) {
            const data = transformToChartDataFormat(plot);
            datasets.push({
              name: plot.id,
              label: plot.id,
              pointRadius: 0,
              showLine: true,
              borderColor: COLOR[plot.id],
              backgroundColor: COLOR[plot.id],
              data,
              yAxisID: `${yAxisID}`,
            });
          }
        }
        context.commit('setProcessValuesChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingProcessValuesChartData', false);
      }
    },
    async SET_PROFILE_CHART(context, {
      payload, calculationModel,
    }) {
      context.commit('setIsLoadingProfileChartData', true);
      try {
        const response = await SimulatorService.getSimulatorChartData(payload, {
          chartType: 'profile',
          calculationModel,
        });
        const datasets = transformChartProfile(response);

        context.commit('setProfileChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingProfileChartData', false);
      }
    },
    async SET_PROFILE_OVERVIEW_CHART(context, {
      payload, calculationModel,
    }) {
      context.commit('setIsLoadingProfileOverviewChartData', true);
      try {
        const response = await SimulatorService.getSimulatorChartData(payload, {
          chartType: 'profile_overview',
          calculationModel,
        });
        const datasets = [];
        for (const dataset of response) {
          const yAxisID = `${dataset.yAxisLabel}`;
          for (const plot of dataset.plots) {
            datasets.push({
              id: plot.id,
              name: plot.x.value,
              label: '',
              data: [
                plot.y.value,
              ],
              yAxisID,
              backgroundColor: COLOR[plot.id],
            });
          }
        }
        context.commit('setProfileOverviewChartData', datasets);
      } catch (e) {
        return e.response;
      } finally {
        context.commit('setIsLoadingProfileOverviewChartData', false);
      }
    },
  },
};
