export const createModule = (services, location) => {
  const {
    getFurnaceView,
    setFurnaceViewButtonValue,
    updateFurnaceViewValues,
  } = services.furnace;

  return {
    // initial state
    state: {
      activeUpdateButtonRequests: [],
      items: [],
      isLoading: false,
    },
    namespaced: true,

    // actions
    actions: {
      async getFurnace({
        commit,
      }, workplaceId) {
        commit('setIsLoading', true);
        try {
          const response = await getFurnaceView(location, workplaceId);
          commit('setFurnace', response);
        } finally {
          commit('setIsLoading', false);
        }
      },

      async toggleButtonValue(
        {
          dispatch, commit,
        },
        {
          workplaceId, viewId, button,
        },
      ) {
        const result = {
          ok: false,
          error: null,
        };
        try {
          commit('updateFurnaceButtonRequestAdd', {
            workplaceId,
            button,
          });

          const {
            userInputId, value: actualValue,
          } = button;
          const {
            isSuccess,
            error,
            value: newValue,
          } = await setFurnaceViewButtonValue(
            location,
            workplaceId,
            userInputId,
            actualValue,
          );

          if (isSuccess) {
            commit('updateFurnaceButton', {
              workplaceId,
              viewId,
              button: {
                userInputId,
                value: newValue,
              },
            });
            result.ok = true;
          } else {
            const messages = {
              NOT_ACTUAL_VALUE: 'error.furnace.buttonNotActualValue',
            };
            result.error = messages[error.code] || error.code;

            if (error.code === 'NOT_ACTUAL_VALUE') {
              await dispatch('updateFurnace', {
                workplaceId,
                viewId,
              });
            }
          }
        } catch (e) {
          result.error = e.message;
        } finally {
          commit('updateFurnaceButtonRequestRemove', {
            workplaceId,
            button,
          });
        }
        return result;
      },
      async updateFurnace({
        commit,
      }, {
        workplaceId, viewId = null,
      }) {
        const response = await updateFurnaceViewValues(
          location,
          workplaceId,
          viewId,
        );
        commit('updateFurnace', response[0]);
      },
      async clearFurnace({
        commit,
      }, workplaceId) {
        commit('clearFurnace', workplaceId);
      },
    },

    // mutations
    mutations: {
      setFurnace(state, item) {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === item.workplaceId,
        );
        if (index !== -1) {
          state.items[index] = item;
        } else {
          state.items.push(item);
        }
      },
      updateFurnace(state, item) {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === item.workplaceId,
        );
        if (index !== -1) {
          const {
            furnaceViews,
          } = state.items[index];
          if (furnaceViews) {
            state.items[index].connected = item.connected;
            state.items[index].updateTime = item.updateTime;
            state.items[index].hold = item.hold;
            state.items[index].state = item.state;
            state.items[index].recipeHeader = item.recipeHeader;
            const viewsById = item.furnaceViews.reduce((acc, obj) => {
              acc[obj.id] = obj;
              return acc;
            }, {});

            state.items[index].furnaceViews = furnaceViews.map((obj) => (viewsById[obj.id] ? {
              ...obj,
              ...viewsById[obj.id],
            } : obj));
          }
        }
      },
      updateFurnaceButton(
        state,
        {
          workplaceId, viewId, button: {
            userInputId, value,
          },
        },
      ) {
        const button = state.items
          .find((obj) => obj.workplaceId === Number(workplaceId))
          ?.furnaceViews?.find((view) => view.id === viewId)
          ?.button?.find((b) => b.userInputId === userInputId);

        if (button) {
          button.value = value;
        }
      },
      updateFurnaceButtonRequestAdd(
        state,
        {
          workplaceId, button: {
            userInputId,
          },
        },
      ) {
        state.activeUpdateButtonRequests.push({
          workplaceId,
          userInputId,
        });
      },
      updateFurnaceButtonRequestRemove(
        state,
        {
          workplaceId, button: {
            userInputId,
          },
        },
      ) {
        state.activeUpdateButtonRequests = state.activeUpdateButtonRequests.filter(
          (o) => !(o.workplaceId === workplaceId && o.userInputId === userInputId),
        );
      },
      setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
      },
      clearFurnace(state, workplaceId) {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === workplaceId,
        );
        if (index !== -1) {
          state.items.splice(index, 1);
        }
      },
      clearFurnaces(state) {
        state.items = [];
      },
    },

    // getters
    getters: {
      getColors: (state) => (workplaceId) => {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === Number(workplaceId),
        );
        return index !== -1 ? state.items[index].colors || [] : [];
      },
      getFurnaceComponents: (state) => (workplaceId) => {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === Number(workplaceId),
        );
        return index !== -1 ? state.items[index].furnaceViews || [] : [];
      },
      getFurnaceRecipe: (state) => (workplaceId) => {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === Number(workplaceId),
        );
        return index !== -1 && state.items[index].recipeHeader;
      },
      getFurnaceData: (state) => (workplaceId) => {
        const index = state.items.findIndex(
          (obj) => obj.workplaceId === parseInt(workplaceId, 10),
        );
        return index !== -1 && state.items[index];
      },
    },
  };
};
