import {
  TCsvQuoteCharacterTranslations,
} from './select-csv-quote-char.ts';

const translations: TCsvQuoteCharacterTranslations = {
  single_quote: 'Einfache Anführungszeichen',
  double_quote: 'Doppelte Anführungszeichen',
};

export default {
  placeholder: '',
  exampleText: 'Mein Text',
  label: 'Anführungszeichen',
  options: translations,
};
