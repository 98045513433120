import {
  AxiosResponse,
} from 'axios';

export async function asyncRequestBatchAction<T, K extends string | number | symbol>({
  idList,
  callback,
  promiseMap,
  list,
}: {
  idList: K[],
  callback: (idList: K[]) => Promise<AxiosResponse<T>>,
  promiseMap: Record<K, Promise<AxiosResponse<T>> | undefined>,
  list: Record<K, T | undefined>,
}) {
  const promiseList = idList.map((_id) => promiseMap[_id]).filter((_item) => _item !== undefined);
  const dataList = idList.map((_id) => list[_id]).filter((_item) => _item !== undefined);

  const idListToFetch = idList.filter((_id) => !promiseMap[_id] && !list[_id]);

  if (promiseList.length === 0 && idListToFetch.length === 0) {
    return dataList;
  }

  if (idListToFetch.length !== 0) {
    const promise = callback(idListToFetch);
    idListToFetch.forEach((_id) => {
      // eslint-disable-next-line no-param-reassign
      promiseMap[_id] = promiseMap[_id] || promise;
    });
    promiseList.push(promise);
  }

  const responseData = await Promise.all(promiseList);

  idListToFetch.forEach((_id) => {
    // eslint-disable-next-line no-param-reassign
    delete promiseMap[_id];
  });

  responseData.forEach((_data, index) => {
    // eslint-disable-next-line no-param-reassign
    list[idListToFetch[index]] = _data!.data;
  });

  const resultData = [
    ...dataList,
    ...responseData.map((_data) => _data!.data),
  ];

  return resultData;
}

export async function asyncRequestAction<T, K extends string | number | symbol>({
  id,
  callback,
  promiseMap,
  list,
  force,
}: {
  id: K,
  callback: () => Promise<AxiosResponse<T>>,
  promiseMap: Record<K, Promise<AxiosResponse<T>> | undefined>,
  list: Record<K, T | undefined>,
  force?: boolean,
}) {
  if (promiseMap[id]) {
    return (await promiseMap[id]!).data;
  }

  if (list[id] && !force) {
    return list[id]!;
  }

  // eslint-disable-next-line no-param-reassign
  promiseMap[id] = callback();

  try {
    // eslint-disable-next-line no-param-reassign
    list[id] = (await promiseMap[id]!).data;
  } finally {
    // eslint-disable-next-line no-param-reassign
    delete promiseMap[id];
  }

  return list[id]!;
}

export async function asyncRequestActionList<T>({
  callback,
  setPromise,
  setList,
  list,
  promise,
  force,
}: {
  callback: () => Promise<AxiosResponse<T>>,
  setPromise: (promise: Promise<AxiosResponse<T>> | undefined) => void,
  setList: (list: T) => void,
  list: T | undefined,
  promise: Promise<AxiosResponse<T>> | undefined,
  force?: boolean,
}) {
  if (promise) {
    return (await promise).data;
  }

  if (list && !force) {
    return list;
  }

  const localPromise = callback();
  setPromise(localPromise);

  let data: T;
  try {
    data = (await localPromise).data;
    setList(data);
  } finally {
    setPromise(undefined);
  }

  return data;
}

export async function asyncRequestWithDataUpdate<RequestDataType, ResultDataType=RequestDataType>({
  callback,
  setPromise,
  setList,
  list,
  promise,
  force,
}: {
  callback: () => Promise<AxiosResponse<RequestDataType>>,
  setPromise: (promise: Promise<AxiosResponse<RequestDataType>> | undefined) => void,
  setList: (list: RequestDataType) => void,
  list: ResultDataType | undefined,
  promise: Promise<AxiosResponse<RequestDataType>> | undefined,
  force?: boolean,
}): Promise<ResultDataType|undefined> {
  if (promise && !force) {
    setList((await promise).data);
    return list;
  }

  if (list && !force) {
    return list;
  }

  const localPromise = callback();
  setPromise(localPromise);

  try {
    setList((await localPromise).data);
  } finally {
    setPromise(undefined);
  }

  return list;
}
