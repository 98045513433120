import {
  ModelProcessService,
} from '../../services/ModelProcessService';

export default {
  state: {
    tunnelChartData: {},
    loadingTunnelChartData: true,
    modelProcessList: [],
    newModelId: '',
    loadingNewModelId: false,
    removedModel: '',
    waitingForRemoval: false,
  },

  mutations: {
    setTunnelChartData(state, datasets) {
      state.tunnelChartData = {
        datasets,
      };
    },
    clearTunnelChartData(state) {
      state.tunnelChartData = {};
    },
    isLoadingTunnelChartData(state, loading) {
      state.loadingMaterials = loading;
    },
    setModelProcessList(state, processList) {
      state.modelProcessList = processList;
    },
    setIsLoadingModelProcessList(state, loading) {
      state.loadingModelProcessList = loading;
    },
    clearModelProcessList(state) {
      state.modelProcessList = [];
    },
    setNewModelId(state, id) {
      state.newModelId = id;
    },
    clearNewModelId(state) {
      state.newModelId = '';
    },
    isLoadingNewModelId(state, loading) {
      state.loadingNewModelId = loading;
    },
    setRemovedModel(state, id) {
      state.removedModel = id;
      state.modelProcessList = state.modelProcessList.filter(
        (model) => model.model_process_id !== id,
      );
    },
    clearRemovedModel(state) {
      state.removedModel = '';
    },
    isLoadingRemovedModel(state, loading) {
      state.waitingForRemoval = loading;
    },
  },

  actions: {
    async SET_TUNNEL_CHART(context, id = '') {
      context.commit('clearTunnelChartData');
      context.commit('isLoadingTunnelChartData', true);
      try {
        const response = await ModelProcessService.getTunnelChart(id);
        context.commit('setTunnelChartData', response);
      } catch (e) {
        context.commit('clearTunnelChartData');
        return e.response;
      } finally {
        context.commit('isLoadingTunnelChartData', false);
      }
    },
    async SET_MODEL_PROCESS_LIST(context, payload = {}) {
      context.commit('clearModelProcessList');
      context.commit('setIsLoadingModelProcessList', true);
      try {
        const response = await ModelProcessService.getModelProcessList(
          payload.location,
          payload.furnace,
          payload.recipe,
        );
        context.commit('setModelProcessList', response);
      } catch (e) {
        context.commit('clearModelProcessList');
        return e.response;
      } finally {
        context.commit('setIsLoadingModelProcessList', false);
      }
    },
    async CREATE_MODEL_PROCESS(context, payload) {
      context.commit('clearNewModelId');
      context.commit('isLoadingNewModelId', true);
      try {
        const response = await ModelProcessService.createNewModelProcess(
          payload,
        );
        context.commit('setNewModelId', response);
      } catch (e) {
        context.commit('clearNewModelId');
        return e.response;
      } finally {
        context.commit('isLoadingNewModelId', false);
      }
    },
    async REMOVE_MODEL_PROCESS(context, id = '') {
      context.commit('clearRemovedModel');
      context.commit('isLoadingRemovedModel', true);
      try {
        const response = await ModelProcessService.removeModelProcess(id);
        context.commit('setRemovedModel', response);
      } catch (e) {
        context.commit('clearRemovedModel');
        return e.response;
      } finally {
        context.commit('isLoadingRemovedModel', false);
      }
    },
  },
};
