import {
  EquipmentService,
} from '../../services/EquipmentService.js';

export default {
  state: {
    eqPlannedUsage: {},
    eqLocationPlannedUsage: [],
    isLoadingEqPlannedUsage: false,
  },
  getters: {
    getEqPlannedUsage: (state) => {
      return state.eqPlannedUsage;
    },
  },
  mutations: {
    setEqPlannedUsage(state, usage) {
      if (state.eqLocationPlannedUsage.length) {
        const rowIndex = state.eqLocationPlannedUsage.findIndex(
          (row) => row.equipment_planned_usage_id === usage.equipment_planned_usage_id,
        );
        if (rowIndex >= 0) {
          // Replace object in array by deleting and adding it again
          // Vue 2 cannot detect updates of a single index in an array
          state.eqLocationPlannedUsage.splice(rowIndex, 1, {
            ...usage,
          });
        }
      }
      state.eqPlannedUsage = usage;
    },
    setEqLocationPlannedUsage(state, usage) {
      state.eqLocationPlannedUsage = usage;
    },
    setIsLoadingEqPlannedUsage(state, isLoading) {
      state.isLoadingEqPlannedUsage = isLoading;
    },
  },

  actions: {
    async SET_EQ_PLANNED_USAGE(context) {
      const id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setIsLoadingEqPlannedUsage', true);
      context.commit('setEqPlannedUsage', {});
      try {
        const eqUsage = await EquipmentService.getEquipmentPlannedUsage(id);
        context.commit('setEqPlannedUsage', eqUsage);
      } finally {
        context.commit('setIsLoadingEqPlannedUsage', false);
      }
    },
    async UPDATE_EQ_PLANNED_USAGE(context, payload) {
      context.commit('setIsLoadingEqPlannedUsage', true);
      try {
        await EquipmentService.updateEquipmentPlannedUsage(payload);
        context.commit('setEqPlannedUsage', payload);
      } catch (e) {
        return e.response.status;
      } finally {
        context.commit('setIsLoadingEqPlannedUsage', false);
      }
    },
    async SET_LOCATION_PLANNED_USAGE(context, locationId) {
      context.commit('setIsLoadingEqPlannedUsage', true);
      context.commit('setEqLocationPlannedUsage', []);
      try {
        const eqUsage = await EquipmentService.getLocationPlannedUsage(locationId);
        context.commit('setEqLocationPlannedUsage', eqUsage);
      } finally {
        context.commit('setIsLoadingEqPlannedUsage', false);
      }
    },
  },
};
