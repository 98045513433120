class JSONStreamReaderController {
  constructor(rs, onData, onComplete) {
    this.onData = onData;
    this.reader = rs.getReader();
    this.start()
      .then(() => onComplete && onComplete())
      .catch((error) => onComplete && onComplete(error));
  }

  cancel() {
    return this.reader.cancel();
  }

  async start() {
    const reader = this.reader;

    while (true) {
      const {
        done, value,
        // eslint-disable-next-line no-await-in-loop
      } = await reader.read();

      if (done) {
        break;
      }

      if (value?.length > 0 && this.onData) {
        this.onData(value.map(JSON.parse));
      }
    }
  }
}

export {
  JSONStreamReaderController,
};
