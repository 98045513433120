import equipmentStore from './modules/equipmentStore';
import equipmentDetailsStore from './modules/equipmentDetailsStore';
import locationStore from './modules/locationStore';
import npmControlDetails from './modules/npmControlDetails';
import npmCredentials from './modules/npmCredentials';
import maintenanceCalendar from './modules/maintenanceCalendar';
import logger from './modules/loggerStore';
import recipeStore from './modules/recipeStore';
import modelProcessStore from './modules/modelProcessStore';
import recipeBuilderStore from './modules/recipeBuilderStore';
import localizationStore from '@/store/modules/localizationStore';
import nitrexConnectStore from '@/store/modules/nitrexConnectStore';
import materialsStore from '@/store/modules/materialsStore';
import simulatorStore from '@/store/modules/simulatorStore';

export default {
  modules: {
    equipmentStore,
    equipmentDetailsStore,
    locationStore,
    maintenanceCalendar,
    npmControlDetails,
    npmCredentials,
    recipeStore,
    modelProcessStore,
    recipeBuilderStore,
    localizationStore,
    logger,
    nitrexConnectStore,
    materialsStore,
    simulatorStore,
  },
};
