import {
  createWebHistory,
  createRouter,
} from 'vue-router';
import {
  authMiddleware,
} from '@/utils/axios/auth-middleware';
import {
  i18n,
} from '@/plugins/i18n';
import {
  useAppStore,
} from '@/store/pinia/app';
import {
  getAppTitle,
} from '@/utils/string-utils.ts';
import {
  routes,
} from './routes';
import {
  ERouteName,
} from './route-name';

if (import.meta.env.DEV) {
  routes.push({
    name: ERouteName.PLAYGROUND,
    path: '/playground',
    component: () => import('@/playground/playground.vue'),
  });
}

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach(() => {
  const appStore = useAppStore();
  appStore.isNavigating = true;
});

router.afterEach(() => {
  const appStore = useAppStore();
  appStore.isNavigating = false;
});

router.beforeEach(authMiddleware);

router.beforeEach((to) => {
  const routeTitleI18nKey = to.matched.at(-1)!.meta.titleI18nKey;
  window.document.title = routeTitleI18nKey
    ? `${getAppTitle()} | ${i18n.global.t(routeTitleI18nKey)}`
    : getAppTitle();
});

export default router;
