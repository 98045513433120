import {
  NitrexConnectService,
} from '../../services/NitrexConnectService';

export default {
  state: {
    furnace: {},
    plants: [],
    plant: {},
    loadingPlants: false,
  },

  getters: {
    getFurnace(state) {
      return state.furnace;
    },
    getPlants(state) {
      return state.plants;
    },
    getPlant(state) {
      return state.plant;
    },
    isLoadingPlants(state) {
      return state.loadingPlants;
    },
  },

  mutations: {
    SET_FURNACE(state, furnace) {
      state.furnace = furnace;
    },
    SET_PLANTS(state, plants) {
      state.plants = plants;
    },
    SET_PLANT(state, plant) {
      state.plant = plant;
    },
    ADD_PLANT(state, plant) {
      state.plants = [
        ...state.plants,
        plant,
      ];
    },
    SET_LOADING_PLANTS(state, loading) {
      state.loadingPlants = loading;
    },
  },

  actions: {
    async fetchPlants(context) {
      context.commit('SET_LOADING_PLANTS', true);
      try {
        context.commit('SET_PLANTS', await NitrexConnectService.getPlantList());
      } finally {
        context.commit('SET_LOADING_PLANTS', false);
      }
    },
    async addPlant(context, payload) {
      try {
        await NitrexConnectService.addPlant({
          name: payload.name,
          host: payload.host,
          port: payload.port,
          userName: payload.userName,
          password: payload.password,
        });

        context.dispatch('fetchPlants');
      } catch (e) {
        return e.response;
      } finally {
      }
    },
    async updatePlant(context, payload) {
      try {
        const toUpdate = {
          id: payload.id,
          name: payload.name,
          host: payload.host,
          port: payload.port,
          userName: payload.userName,
          password: payload.password,
        };

        await NitrexConnectService.updatePlant(payload.id, toUpdate);

        context.dispatch('fetchPlants');
      } catch (e) {
        return e.response;
      } finally {
      }
    },
    async deletePlant(context, id) {
      try {
        await NitrexConnectService.deletePlant(id);

        context.dispatch('fetchPlants');
      } catch (e) {
        return e.response;
      } finally {
      }
    },
    selectPlant(context, payload) {
      context.commit('SET_PLANT', payload);
    },
    selectFurnace(context, payload) {
      context.commit('SET_FURNACE', payload);
    },
  },
};
