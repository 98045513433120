export enum EAuditResultType {
  FAILED = -1,
  INCONCLUSIVE = 0,
  PASSED = 1
}

export const auditResultTypeMap: Record<EAuditResultType, string> = {
  [EAuditResultType.FAILED]: 'FAILED',
  [EAuditResultType.INCONCLUSIVE]: 'INCONCLUSIVE',
  [EAuditResultType.PASSED]: 'PASSED',
};
