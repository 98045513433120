// THis file is meant to contain the sdk required to interface with qmulus api-v2
// It handles a similar task to AxiosService, but it just makes it such that the user won't need to worry about axios commands
// But instead can just use a sdk lib.

// Scope of this is meant only for new qmulus api v2 endpoints

// TODO: refactor this so we DRY the "return response.data"
// TODO: Move this into a "api" directory so we can convert it into a fully fledged sdk.

import {
  furnace,
} from '@/services/qmulusService/furnace';
import {
  users,
} from '@/services/qmulusService/users';

export const qmulusSdk = {
  furnace,
  users,
};
