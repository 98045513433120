// @ts-nocheck
/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
}
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Error"]: AliasType<{
	code?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	detail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChartPoint"]: AliasType<{
	timestamp?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Chart"]: AliasType<{
	chartName?:boolean | `@${string}`,
	varId?:boolean | `@${string}`,
	chartColor?:boolean | `@${string}`,
	chartUnit?:boolean | `@${string}`,
	data?:ValueTypes["SHARED_ChartPoint"],
		__typename?: boolean | `@${string}`
}>;
	["UDM_ChartData"]: AliasType<{
	varId?:boolean | `@${string}`,
	data?:ValueTypes["SHARED_ChartPoint"],
		__typename?: boolean | `@${string}`
}>;
	["ChartMetaData"]: AliasType<{
	varId?:boolean | `@${string}`,
	chartName?:boolean | `@${string}`,
	chartColor?:boolean | `@${string}`,
	chartUnit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LoadRecipeHeader"]: AliasType<{
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_PartData"]: AliasType<{
	pos?:boolean | `@${string}`,
	partName?:boolean | `@${string}`,
	partDescription?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	weightInKg?:boolean | `@${string}`,
	totalWeightInKg?:boolean | `@${string}`,
	orderName?:boolean | `@${string}`,
	orderRemark?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	customer?:boolean | `@${string}`,
	intern?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_FurnaceSystemStatus"]: AliasType<{
	entranceReady?:boolean | `@${string}`,
	exitReady?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Load"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
	earliestTimeStart?:boolean | `@${string}`,
	packedTime?:boolean | `@${string}`,
	packedUser?:boolean | `@${string}`,
	startedUser?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	recipe?:ValueTypes["SHARED_LoadRecipeHeader"],
	parts?:ValueTypes["SHARED_PartData"],
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Workplace"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	inventoryNumber?:boolean | `@${string}`,
	type?:ValueTypes["SHARED_WorkplaceType"],
	active?:ValueTypes["SHARED_ActiveState"],
	useMode?:ValueTypes["SHARED_UseMode"],
	logicType?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
	chamberId?:boolean | `@${string}`,
	sharesController?:boolean | `@${string}`,
	belongsTo?:boolean | `@${string}`,
	ip?:boolean | `@${string}`,
	mGuardIp?:boolean | `@${string}`,
	device?:boolean | `@${string}`,
	telegramSplit?:boolean | `@${string}`,
	deactivateOperations?:boolean | `@${string}`,
	aliveBitErrorNum?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_WorkplaceType"]: AliasType<{
	number?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	prio?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ActiveState"]: AliasType<{
	string?:boolean | `@${string}`,
	int?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_UseMode"]: AliasType<{
	string?:boolean | `@${string}`,
	int?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_OptionItem"]: AliasType<{
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_SpEntries"]: AliasType<{
	furnaceSwitchEntry?:boolean | `@${string}`,
	recipeDescription2?:boolean | `@${string}`,
	recipeDescription3?:boolean | `@${string}`,
	recipeDescription4?:boolean | `@${string}`,
	gasEntryValues?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_DigitalOutput"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Material"]: AliasType<{
	carbonContent?:boolean | `@${string}`,
	alloyFactor?:boolean | `@${string}`,
	legFactorN?:boolean | `@${string}`,
	carbideLimit?:boolean | `@${string}`,
	temperature?:boolean | `@${string}`,
	specialNitrides?:boolean | `@${string}`,
	analysis?:ValueTypes["SHARED_ElementValueList"],
	co?:boolean | `@${string}`,
	h2?:boolean | `@${string}`,
	quenchingIntensity?:boolean | `@${string}`,
	representativeDiameter?:boolean | `@${string}`,
	requiredHardness?:boolean | `@${string}`,
	grainSize?:boolean | `@${string}`,
	endTemperature?:boolean | `@${string}`,
	targetSurfC?:boolean | `@${string}`,
	customerMaterialId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_PropertyInfo"]: AliasType<{
	property?:boolean | `@${string}`,
	target?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_PartInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	materialId?:boolean | `@${string}`,
	materialName?:boolean | `@${string}`,
	description1?:boolean | `@${string}`,
	description2?:boolean | `@${string}`,
	drawingNumber?:boolean | `@${string}`,
	revisionNumber?:boolean | `@${string}`,
	testPlanId?:boolean | `@${string}`,
	testPlanDescription?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	properties?:ValueTypes["SHARED_PropertyInfo"],
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_WorkplaceRecipe"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	version_no?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ElementValueList"]: AliasType<{
	c?:boolean | `@${string}`,
	si?:boolean | `@${string}`,
	mn?:boolean | `@${string}`,
	cr?:boolean | `@${string}`,
	ni?:boolean | `@${string}`,
	v?:boolean | `@${string}`,
	al?:boolean | `@${string}`,
	cu?:boolean | `@${string}`,
	p?:boolean | `@${string}`,
	s?:boolean | `@${string}`,
	mo?:boolean | `@${string}`,
	b?:boolean | `@${string}`,
	ti?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_OLD_CustomerMaterial"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	normId?:boolean | `@${string}`,
	normName?:boolean | `@${string}`,
	normDescription?:boolean | `@${string}`,
	groupId?:boolean | `@${string}`,
	groupName?:boolean | `@${string}`,
	elementValues?:ValueTypes["SHARED_ElementValueList"],
	averageAlloyFactor?:boolean | `@${string}`,
	averageAlloyFactorN?:boolean | `@${string}`,
	carbideLimit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_DropDownListItem"]: AliasType<{
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	isActive?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_User"]: AliasType<{
	id?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	shortName?:boolean | `@${string}`,
	function?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	employeeNumber?:boolean | `@${string}`,
	plant?:boolean | `@${string}`,
	costCenter?:boolean | `@${string}`,
	maintenancePopups?:boolean | `@${string}`,
	playAlarmSound?:boolean | `@${string}`,
	loginTimeInMin?:boolean | `@${string}`,
	accessLevel?:boolean | `@${string}`,
	languageId?:boolean | `@${string}`,
	language?:ValueTypes["SHARED_DropDownListItem"],
	userGroupId?:boolean | `@${string}`,
	userGroup?:ValueTypes["SHARED_DropDownListItem"],
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
	picture?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Client"]: AliasType<{
	id?:boolean | `@${string}`,
	clientType?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adress?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	resolution?:boolean | `@${string}`,
	valueLong1?:boolean | `@${string}`,
	valueLong2?:boolean | `@${string}`,
	valueLong3?:boolean | `@${string}`,
	valueLong4?:boolean | `@${string}`,
	valueLong5?:boolean | `@${string}`,
	valueFloat1?:boolean | `@${string}`,
	valueFloat2?:boolean | `@${string}`,
	valueFloat3?:boolean | `@${string}`,
	valueChar1?:boolean | `@${string}`,
	valueChar2?:boolean | `@${string}`,
	actUserName?:boolean | `@${string}`,
	actLoginTime?:boolean | `@${string}`,
	lastUserName?:boolean | `@${string}`,
	lastLoginTime?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
	isActive?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Operation"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	operationType?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	relevantForStat?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ValueViewComponent"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	digitsBeforeComma?:boolean | `@${string}`,
	digitsAfterComma?:boolean | `@${string}`,
	textBefore?:boolean | `@${string}`,
	textAfter?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	numberFormat?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_BackgroundView"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_TextView"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_RecipeVersion"]: AliasType<{
	id?:boolean | `@${string}`,
	startingTime?:boolean | `@${string}`,
	versionText?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_RecipeShortHeader"]: AliasType<{
	recipeName?:boolean | `@${string}`,
	actualStep?:boolean | `@${string}`,
	loadIdentifier?:boolean | `@${string}`,
	loadName?:boolean | `@${string}`,
	versions?:ValueTypes["SHARED_RecipeVersion"],
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LoadColumn"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LoadButton"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	chamberId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Bitmap"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Gif"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_MovingPic"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
	startX?:boolean | `@${string}`,
	startY?:boolean | `@${string}`,
	endX?:boolean | `@${string}`,
	endY?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	componentZero?:boolean | `@${string}`,
	componentSpan?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Label"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LabelPlus"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	text0?:boolean | `@${string}`,
	text1?:boolean | `@${string}`,
	text2?:boolean | `@${string}`,
	text3?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Line"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_EditBox"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	focusOrderNumber?:boolean | `@${string}`,
	variableType?:boolean | `@${string}`,
	isEditable?:boolean | `@${string}`,
	digitsBeforeComma?:boolean | `@${string}`,
	digitsAfterComma?:boolean | `@${string}`,
	minValue?:boolean | `@${string}`,
	maxValue?:boolean | `@${string}`,
	numberFormat?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
	editValue?:boolean | `@${string}`,
	componentTypeId?:boolean | `@${string}`,
	userInputId?:boolean | `@${string}`,
	outputId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ProgressBar"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	fullColor?:boolean | `@${string}`,
	emptyColor?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	minValue?:boolean | `@${string}`,
	maxValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Place"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	layoutOrder?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	colorPalette?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	systemColorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Rectangle"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChargeCarItem"]: AliasType<{
	wpId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	infoText?:boolean | `@${string}`,
	placeId?:boolean | `@${string}`,
	placeName?:boolean | `@${string}`,
	placeColor?:boolean | `@${string}`,
	placeColorBG?:boolean | `@${string}`,
	posX?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChargeCarAutFurnaceDriving"]: AliasType<{
	driverWpId?:boolean | `@${string}`,
	overWpId?:boolean | `@${string}`,
	furnaceWidth?:boolean | `@${string}`,
	furnaceHeight?:boolean | `@${string}`,
	furnaceOverviewNo?:boolean | `@${string}`,
	furnacePlace?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChargeCar"]: AliasType<{
	id?:boolean | `@${string}`,
	carType?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	layoutOrder?:boolean | `@${string}`,
	imageName?:boolean | `@${string}`,
	car?:ValueTypes["SHARED_ChargeCarItem"],
	carList?:ValueTypes["SHARED_ChargeCarItem"],
	railMaxPosPixels?:boolean | `@${string}`,
	railMaxPosMm?:boolean | `@${string}`,
	autFurnaceDriving?:ValueTypes["SHARED_ChargeCarAutFurnaceDriving"],
	mode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutChargeStatus"]: AliasType<{
	id?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	colorBorder?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutView"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	systemStatus?:ValueTypes["SHARED_FurnaceSystemStatus"],
	chargeStatusList?:ValueTypes["ShopLayoutChargeStatus"],
	recipeHeader?:ValueTypes["SHARED_RecipeShortHeader"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	text?:ValueTypes["SHARED_TextView"],
	value?:ValueTypes["SHARED_ValueViewComponent"],
	background?:ValueTypes["SHARED_BackgroundView"],
	loadButton?:ValueTypes["SHARED_LoadButton"],
	place?:ValueTypes["SHARED_Place"],
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	bitmap?:ValueTypes["SHARED_Bitmap"],
	gif?:ValueTypes["SHARED_Gif"],
	label?:ValueTypes["SHARED_Label"],
	labelPlus?:ValueTypes["SHARED_LabelPlus"],
	line?:ValueTypes["SHARED_Line"],
	editBox?:ValueTypes["SHARED_EditBox"],
	progressBar?:ValueTypes["SHARED_ProgressBar"],
	rectangle?:ValueTypes["SHARED_Rectangle"],
	chargeCar?:ValueTypes["SHARED_ChargeCar"],
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutViewData"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	systemStatus?:ValueTypes["SHARED_FurnaceSystemStatus"],
	recipeHeader?:ValueTypes["SHARED_RecipeShortHeader"],
	loadButton?:ValueTypes["SHARED_LoadButton"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:ValueTypes["SHARED_ValueViewComponent"],
	place?:ValueTypes["SHARED_Place"],
	bitmap?:ValueTypes["SHARED_Bitmap"],
	gif?:ValueTypes["SHARED_Gif"],
	label?:ValueTypes["SHARED_Label"],
	labelPlus?:ValueTypes["SHARED_LabelPlus"],
	line?:ValueTypes["SHARED_Line"],
	editBox?:ValueTypes["SHARED_EditBox"],
	progressBar?:ValueTypes["SHARED_ProgressBar"],
	rectangle?:ValueTypes["SHARED_Rectangle"],
	chargeCar?:ValueTypes["SHARED_ChargeCar"],
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutAlarm"]: AliasType<{
	alarmId?:boolean | `@${string}`,
	workplaceName?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	acknowledgeTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
	priority?:boolean | `@${string}`,
	alarmMessage?:boolean | `@${string}`,
	gate?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
	byte?:boolean | `@${string}`,
	bit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutAlarmSearch"]: AliasType<{
	workplaceName?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	event?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutLoadListGeneral"]: AliasType<{
	columnNames?:ValueTypes["SHARED_LoadColumn"],
	workplaceGeneral?:ValueTypes["SHARED_Workplace"],
	parts?:ValueTypes["SHARED_PartInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutRecipeList"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
shopLayoutView?: [{	shopLayoutId: number | Variable<any, string>},ValueTypes["ShopLayoutView"]],
updateShopLayoutValues?: [{	shopLayoutId: number | Variable<any, string>},ValueTypes["ShopLayoutViewData"]],
shopLayoutAlarms?: [{	shopLayoutId: number | Variable<any, string>},ValueTypes["ShopLayoutAlarm"]],
shopLayoutAlarmsSearch?: [{	shopLayoutId: number | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["ShopLayoutAlarmSearch"]],
shopLayoutLoadList?: [{	shopLayoutId: number | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["SHARED_Load"]],
shopLayoutLoadListGeneral?: [{	shopLayoutId: number | Variable<any, string>},ValueTypes["ShopLayoutLoadListGeneral"]],
shopLayoutRecipeList?: [{	shopLayoutId: number | Variable<any, string>},ValueTypes["ShopLayoutRecipeList"]],
furnaceView?: [{	workplaceId: number | Variable<any, string>},ValueTypes["FurnaceView"]],
furnaceViewAlarms?: [{	workplaceId: number | Variable<any, string>},ValueTypes["FurnaceViewAlarm"]],
furnaceViewAlarmsSearch?: [{	workplaceId: number | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["FurnaceViewAlarmSearch"]],
updateFurnaceViews?: [{	workplaceId: number | Variable<any, string>,	viewId?: number | undefined | null | Variable<any, string>},ValueTypes["updateFurnaceViewsResult"]],
furnaceChartUdmData?: [{	workplaceId: number | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>,	varId?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["UDM_ChartData"]],
furnaceChartMetaData?: [{	workplaceId: number | Variable<any, string>},ValueTypes["ChartMetaData"]],
furnaceChartRecorder?: [{	workplaceId: number | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["SHARED_Chart"]],
furnaceLoadList?: [{	workplaceId: number | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["SHARED_Load"]],
actualRecipeOfWorkplace?: [{	workplaceId: number | Variable<any, string>},ValueTypes["ActRecipeVersion"]],
furnaceLoadListGeneral?: [{	workplaceId: number | Variable<any, string>},ValueTypes["FurnaceLoadListGeneral"]],
furnaceRecipeList?: [{	workplaceId: number | Variable<any, string>},ValueTypes["SHARED_WorkplaceRecipe"]],
	listOfAvailableFurnaces?:boolean | `@${string}`,
furnaceChartDataFirstTimestamp?: [{	workplaceId: number | Variable<any, string>,	yearFrom?: number | undefined | null | Variable<any, string>},boolean | `@${string}`],
alarmStatistics?: [{	workplaceId: number | Variable<any, string>,	alarmNoList: Array<number | undefined | null> | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["AlarmStatisticInfo"]],
alarmList?: [{	workplaceId: number | Variable<any, string>,	importantErrorsOnly?: boolean | undefined | null | Variable<any, string>},ValueTypes["AlarmInfo"]],
	alarmWorkplaces?:ValueTypes["WorkplaceInfo"],
	manageUsage?:ValueTypes["Usage"],
furnaceBookAlarms?: [{	workplaceId: string | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["FurnaceBookAlarm"]],
	furnaceBookFilters?:ValueTypes["FurnaceBookFilters"],
getImages?: [{	loadName?: string | undefined | null | Variable<any, string>},ValueTypes["Image"]],
profileChart?: [{	loadName: string | Variable<any, string>},ValueTypes["ProfileChart"]],
furnaceBook?: [{	workplaceIds: Array<string | undefined | null> | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>,	filter: ValueTypes["Filter"] | Variable<any, string>},ValueTypes["ProductionLog"]],
productionLogsDummyCharts?: [{	numOfCharts: number | Variable<any, string>,	numOfPoints: number | Variable<any, string>,	maxValue: number | Variable<any, string>},ValueTypes["SHARED_Chart"]],
productionLogsChartRecorder?: [{	workplaceId: string | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["SHARED_Chart"]],
chargeConsumption?: [{	loadName?: string | undefined | null | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["ChargeConsumption"]],
usage?: [{	workplaceIds: Array<string | undefined | null> | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>,	standBy: boolean | Variable<any, string>},ValueTypes["UsageEntry"]],
utilization?: [{	mode: ValueTypes["UtilizationMode"] | Variable<any, string>,	workplaceIds: Array<string | undefined | null> | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>,	operationId?: number | undefined | null | Variable<any, string>,	minLoadRuntime?: number | undefined | null | Variable<any, string>,	loadChangeover?: number | undefined | null | Variable<any, string>,	isChangeCharge?: boolean | undefined | null | Variable<any, string>,	calculationMode?: number | undefined | null | Variable<any, string>},ValueTypes["Utilization"]],
	cptEntries?:ValueTypes["CptEntry"],
recipeOfLoad?: [{	loadName?: string | undefined | null | Variable<any, string>,	workplaceId: string | Variable<any, string>},ValueTypes["RecipeROL"]],
partInfo?: [{	loadName?: string | undefined | null | Variable<any, string>},ValueTypes["Part"]],
partsStatistics?: [{	workplaceIds: Array<string | undefined | null> | Variable<any, string>,	order: boolean | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>,	filter: ValueTypes["Filter"] | Variable<any, string>},ValueTypes["PartsStatistics"]],
	productionLogsOperationList?:ValueTypes["SHARED_Operation"],
	failureReasonAdministration?:ValueTypes["FailureReason"],
	fixtureAdministration?:ValueTypes["Fixture"],
	fixtureTypes?:ValueTypes["FixtureType"],
	customerSpecificData?:ValueTypes["CustomerSpecificData"],
	engineeringUnitList?:ValueTypes["EngineeringUnit"],
	userUnitsList?:ValueTypes["UserUnits"],
	customerMaterialList?:ValueTypes["SHARED_OLD_CustomerMaterial"],
	materialGroup?:ValueTypes["OLD_MaterialGroup"],
	normMaterialList?:ValueTypes["OLD_NormMaterial"],
	parts?:ValueTypes["SHARED_PartInfo"],
partManagement?: [{	partId: string | Variable<any, string>},ValueTypes["PartManagementInfo"]],
partsWorkplaceRecipeList?: [{	workplaceId: string | Variable<any, string>},ValueTypes["SHARED_WorkplaceRecipe"]],
	testPlanList?:ValueTypes["TestPlan"],
	toolingList?:ValueTypes["Tooling"],
	partsCustomerMaterialList?:ValueTypes["SHARED_OLD_CustomerMaterial"],
recipeList?: [{	recipePrefix: string | Variable<any, string>},ValueTypes["RecipeListItem"]],
recipeItem?: [{	recipePrefix: string | Variable<any, string>,	recipe: string | Variable<any, string>,	workplaceId: number | Variable<any, string>},ValueTypes["RecipeItemVersion"]],
getRecipeMetadata?: [{	recipePrefix: string | Variable<any, string>,	workplaceId: number | Variable<any, string>},ValueTypes["RecipeMetadata"]],
	peAttributeType?:ValueTypes["PeAttributeType"],
	peAttributeTypePhenNoList?:ValueTypes["SHARED_OptionItem"],
	peProcessType?:ValueTypes["PeProcessType"],
	peProcessTypePhenNoList?:ValueTypes["SHARED_OptionItem"],
	physicalUnit?:ValueTypes["PhysicalUnit"],
	physicalUnitMetadata?:ValueTypes["SHARED_OptionItem"],
	workplaceTypeList?:ValueTypes["SHARED_WorkplaceType"],
	backgroundProcessList?:ValueTypes["BackgroundProcess"],
	getBackgroundProcessStateList?:boolean | `@${string}`,
	workplaceList?:ValueTypes["SHARED_Workplace"],
	workplaceDataGeneralList?:ValueTypes["SHARED_Workplace"],
	workplaceUseModeList?:ValueTypes["SHARED_UseMode"],
	workplaceDataWpTypeList?:ValueTypes["SHARED_WorkplaceType"],
	operationAdministrationOperationList?:ValueTypes["Operation"],
	operationTypeList?:ValueTypes["OperationType"],
operationPartList?: [{	operationId?: string | undefined | null | Variable<any, string>},ValueTypes["OperationPart"]],
operationProcessList?: [{	operationId?: number | undefined | null | Variable<any, string>},ValueTypes["OperationProcess"]],
	operationMetadata?:ValueTypes["SHARED_OptionItem"],
	alarmAdministration?:ValueTypes["Alarm"],
	alarmAdministrationMetadata?:ValueTypes["AlarmAdministrationMetadata"],
	boxAdministration?:ValueTypes["Box"],
	boxMetadata?:ValueTypes["ColorBox"],
	colorAdministration?:ValueTypes["Color"],
	languageList?:ValueTypes["LanguageInfo"],
	externalizeTranslation?:ValueTypes["ExternalizeTranslationConfigValue"],
	dictionaryAdministration?:ValueTypes["DictionaryItem"],
	smsAdministration?:ValueTypes["SmsSettings"],
	systemTaskAdministration?:ValueTypes["Task"],
	clientAccessUserList?:ValueTypes["SHARED_User"],
clientAccessClientList?: [{	userId?: string | undefined | null | Variable<any, string>},ValueTypes["SHARED_Client"]],
	terminalTypeList?:ValueTypes["TerminalType"],
	terminalTypeMetadata?:ValueTypes["SHARED_OptionItem"],
	terminalClientList?:ValueTypes["TerminalClient"],
	terminalClientMetadata?:ValueTypes["TerminalClientMetadata"],
	applicationGroup?:ValueTypes["ApplicationGroup"],
	applicationList?:ValueTypes["Application"],
	applicationMetadata?:ValueTypes["ApplicationMetadata"],
	processFlowList?:ValueTypes["ProcessFlow"],
processFlowStepList?: [{	processFlowId: string | Variable<any, string>},ValueTypes["ProcessFlowStep"]],
operationWorkplaceRecipeList?: [{	operationId: string | Variable<any, string>,	workplaceId: string | Variable<any, string>},ValueTypes["RecipeMenuItem"]],
processFlowPartComparison?: [{	processFlowId: string | Variable<any, string>},ValueTypes["ProcessFlowPartComparisonItem"]],
	processFlowOperationList?:ValueTypes["SHARED_Operation"],
operationWorkplaceList?: [{	operationId: string | Variable<any, string>},ValueTypes["SHARED_Workplace"]],
	userAccessList?:ValueTypes["UserAccess"],
	userGroup?:ValueTypes["UserGroup"],
	userList?:ValueTypes["SHARED_User"],
	menuRecipes?:ValueTypes["TO_REMOVE_RecipeMenuItem"],
	shopLayoutMenuItems?:ValueTypes["TO_REMOVE_ShopLayoutMenuItem"],
	workplaceGeneralList?:ValueTypes["SHARED_Workplace"],
	loadColumnList?:ValueTypes["SHARED_LoadColumn"],
loadList?: [{	workplaceIds?: Array<number | undefined | null> | undefined | null | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["SHARED_Load"]],
chartRecorder?: [{	workplaceId: string | Variable<any, string>,	startTime: number | Variable<any, string>,	endTime: number | Variable<any, string>},ValueTypes["SHARED_Chart"]],
	partList?:ValueTypes["SHARED_PartInfo"],
partWorkplaceRecipeList?: [{	workplaceId: string | Variable<any, string>},ValueTypes["SHARED_WorkplaceRecipe"]],
clientList?: [{	userId?: string | undefined | null | Variable<any, string>},ValueTypes["SHARED_Client"]],
	operationList?:ValueTypes["SHARED_Operation"],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
shopLayoutAcknowledgeAlarms?: [{	shopLayoutId: number | Variable<any, string>,	alarmIds?: Array<number | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["ShopLayoutAlarm"]],
furnaceAcknowledgeAlarms?: [{	workplaceId: number | Variable<any, string>,	alarmIds?: Array<number | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["FurnaceViewAlarm"]],
setFurnaceEditBoxValues?: [{	workplaceId: number | Variable<any, string>,	data: Array<ValueTypes["EditBoxValueInput"] | undefined | null> | Variable<any, string>},ValueTypes["SetFurnaceEditBoxValuesResult"]],
setFurnaceButtonValue?: [{	workplaceId: number | Variable<any, string>,	data: ValueTypes["ButtonInput"] | Variable<any, string>},ValueTypes["ButtonValueResult"]],
createUsage?: [{	data: ValueTypes["createUsageInput"] | Variable<any, string>},ValueTypes["UsageResult"]],
updateUsage?: [{	id: number | Variable<any, string>,	data: ValueTypes["updateUsageInput"] | Variable<any, string>},ValueTypes["UsageResult"]],
deleteUsage?: [{	id: number | Variable<any, string>,	modifiedOn: number | Variable<any, string>},ValueTypes["UsageResult"]],
cloneUsage?: [{	id: number | Variable<any, string>,	data: ValueTypes["cloneUsageInput"] | Variable<any, string>},ValueTypes["UsageResult"]],
deleteImage?: [{	input: ValueTypes["DeleteImageInput"] | Variable<any, string>},boolean | `@${string}`],
createCustomerSpecificData?: [{	data: ValueTypes["CreateCustomerSpecificDataInput"] | Variable<any, string>},ValueTypes["CustomerSpecificDataResult"]],
editCustomerSpecificData?: [{	data: ValueTypes["EditCustomerSpecificDataInput"] | Variable<any, string>},ValueTypes["CustomerSpecificDataResult"]],
deleteCustomerSpecificData?: [{	data: ValueTypes["DeleteCustomerSpecificDataInput"] | Variable<any, string>},ValueTypes["CustomerSpecificDataResult"]],
deleteRecipeVersions?: [{	data: ValueTypes["RecipeVersionsHeaderInput"] | Variable<any, string>},boolean | `@${string}`],
cloneRecipeAndSaveAs?: [{	data: ValueTypes["CloneRecipeAndSaveAsInput"] | Variable<any, string>,	workplaceId: number | Variable<any, string>},ValueTypes["CloneRecipeAndSaveAsResult"]],
saveNewRecipe?: [{	data: ValueTypes["SaveNewRecipeInput"] | Variable<any, string>,	workplaceId: number | Variable<any, string>},ValueTypes["SaveRecipeResult"]],
saveEditedRecipeVersion?: [{	data: ValueTypes["saveEditedRecipeVersionInput"] | Variable<any, string>,	workplaceId: number | Variable<any, string>},ValueTypes["SaveRecipeResult"]],
createPeAttributeType?: [{	data: ValueTypes["CreatePeAttributeTypeInput"] | Variable<any, string>},ValueTypes["PeAttributeTypeResult"]],
editPeAttributeType?: [{	data: ValueTypes["EditPeAttributeTypeInput"] | Variable<any, string>},ValueTypes["PeAttributeTypeResult"]],
deletePeAttributeType?: [{	data: ValueTypes["DeletePeAttributeTypeInput"] | Variable<any, string>},ValueTypes["PeAttributeTypeResult"]],
createPeProcessType?: [{	data: ValueTypes["CreatePeProcessTypeInput"] | Variable<any, string>},ValueTypes["PeProcessTypeResult"]],
editPeProcessType?: [{	data: ValueTypes["EditPeProcessTypeInput"] | Variable<any, string>},ValueTypes["PeProcessTypeResult"]],
deletePeProcessType?: [{	data: ValueTypes["DeletePeProcessTypeInput"] | Variable<any, string>},ValueTypes["PeProcessTypeResult"]],
createPhysicalUnit?: [{	data: ValueTypes["CreatePhysicalUnitInput"] | Variable<any, string>},ValueTypes["PhysicalUnitResult"]],
editPhysicalUnit?: [{	data: ValueTypes["EditPhysicalUnitInput"] | Variable<any, string>},ValueTypes["PhysicalUnitResult"]],
deletePhysicalUnit?: [{	data: ValueTypes["DeletePhysicalUnitInput"] | Variable<any, string>},ValueTypes["PhysicalUnitResult"]],
createWorkplaceType?: [{	data: ValueTypes["createWorkplaceTypeInput"] | Variable<any, string>},ValueTypes["WorkplaceTypeResult"]],
updateWorkplaceType?: [{	number: number | Variable<any, string>,	data: ValueTypes["updateWorkplaceTypeInput"] | Variable<any, string>},ValueTypes["WorkplaceTypeResult"]],
deleteWorkplaceType?: [{	number: number | Variable<any, string>,	modifiedOn: number | Variable<any, string>},ValueTypes["WorkplaceTypeResult"]],
cloneWorkplaceType?: [{	number: number | Variable<any, string>,	data: ValueTypes["cloneWorkplaceTypeInput"] | Variable<any, string>},ValueTypes["WorkplaceTypeResult"]],
deleteBackgroundProcess?: [{	data: ValueTypes["DeleteProcessInput"] | Variable<any, string>},ValueTypes["ProcessResult"]],
cloneAndSaveAsBackgroundProcess?: [{	data: ValueTypes["CloneProcessInput"] | Variable<any, string>},ValueTypes["ProcessResult"]],
editBackgroundProcess?: [{	data: ValueTypes["EditProcessInput"] | Variable<any, string>},ValueTypes["ProcessResult"]],
createNewBackgroundProcess?: [{	data: ValueTypes["CreateNewProcessInput"] | Variable<any, string>},ValueTypes["ProcessResult"]],
createWorkplace?: [{	data: ValueTypes["createWorkplaceInput"] | Variable<any, string>},ValueTypes["WorkplaceResult"]],
updateWorkplace?: [{	id: number | Variable<any, string>,	data: ValueTypes["updateWorkplaceInput"] | Variable<any, string>},ValueTypes["WorkplaceResult"]],
deleteWorkplace?: [{	id: number | Variable<any, string>,	modifiedOn: number | Variable<any, string>},ValueTypes["WorkplaceResult"]],
cloneWorkplace?: [{	id: number | Variable<any, string>,	data: ValueTypes["cloneWorkplaceInput"] | Variable<any, string>},ValueTypes["WorkplaceResult"]],
createOperation?: [{	data: ValueTypes["CreateOperation"] | Variable<any, string>},ValueTypes["OperationResult"]],
editOperation?: [{	data: ValueTypes["EditOperation"] | Variable<any, string>},ValueTypes["OperationResult"]],
deleteOperation?: [{	data: ValueTypes["DeleteOperation"] | Variable<any, string>},ValueTypes["OperationResult"]],
cloneAndSaveAsAlarm?: [{	data: ValueTypes["CloneAndSaveAsInput"] | Variable<any, string>},ValueTypes["AlarmResult"]],
createNewAlarm?: [{	data: ValueTypes["CreateNewAlarmInput"] | Variable<any, string>},ValueTypes["AlarmResult"]],
editAlarm?: [{	data: ValueTypes["EditAlarmInput"] | Variable<any, string>},ValueTypes["AlarmResult"]],
deleteAlarm?: [{	data: ValueTypes["DeleteAlarmInput"] | Variable<any, string>},ValueTypes["AlarmResult"]],
deleteSelectedAlarmsForFurnace?: [{	data: ValueTypes["DeleteSelectedAlarmsForFurnaceInput"] | Variable<any, string>},ValueTypes["Alarm"]],
createBox?: [{	data: ValueTypes["CreateBoxInput"] | Variable<any, string>},ValueTypes["BoxResult"]],
editBox?: [{	data: ValueTypes["EditBoxInput"] | Variable<any, string>},ValueTypes["BoxResult"]],
deleteBox?: [{	data: ValueTypes["DeleteBoxInput"] | Variable<any, string>},ValueTypes["BoxResult"]],
createColor?: [{	data: ValueTypes["CreateColorInput"] | Variable<any, string>},ValueTypes["ColorResult"]],
editColor?: [{	data: ValueTypes["EditColorInput"] | Variable<any, string>},ValueTypes["ColorResult"]],
deleteColor?: [{	data: ValueTypes["deleteColorInput"] | Variable<any, string>},ValueTypes["ColorResult"]],
createTask?: [{	data: ValueTypes["createTaskInput"] | Variable<any, string>},ValueTypes["TaskResult"]],
updateTask?: [{	id: number | Variable<any, string>,	data: ValueTypes["updateTaskInput"] | Variable<any, string>},ValueTypes["TaskResult"]],
deleteTask?: [{	id: number | Variable<any, string>,	modifiedOn: number | Variable<any, string>},ValueTypes["TaskResult"]],
cloneTask?: [{	id: number | Variable<any, string>,	data: ValueTypes["cloneTaskInput"] | Variable<any, string>},ValueTypes["TaskResult"]],
updateClientAccessClientList?: [{	userId: number | Variable<any, string>,	data: Array<ValueTypes["updateClientAccessClientListInput"] | undefined | null> | Variable<any, string>},ValueTypes["updateClientAccessResult"]],
createTerminalType?: [{	data: ValueTypes["CreateTerminalTypeInput"] | Variable<any, string>},ValueTypes["TerminalTypeResult"]],
editTerminalType?: [{	data: ValueTypes["EditTerminalTypeInput"] | Variable<any, string>},ValueTypes["TerminalTypeResult"]],
deleteTerminalType?: [{	data: ValueTypes["DeleteTerminalTypeInput"] | Variable<any, string>},ValueTypes["TerminalTypeResult"]],
createTerminalClient?: [{	data: ValueTypes["CreateTerminalClientInput"] | Variable<any, string>},ValueTypes["TerminalClientResult"]],
editTerminalClient?: [{	data: ValueTypes["EditTerminalClientInput"] | Variable<any, string>},ValueTypes["TerminalClientResult"]],
deleteTerminalClient?: [{	data: ValueTypes["DeleteTerminalClientInput"] | Variable<any, string>},ValueTypes["TerminalClientResult"]],
createApplicationGroup?: [{	data: ValueTypes["CreateAppGroupInput"] | Variable<any, string>},ValueTypes["ApplicationGroupResult"]],
editApplicationGroup?: [{	data: ValueTypes["EditAppGroupInput"] | Variable<any, string>},ValueTypes["ApplicationGroupResult"]],
deleteApplicationGroup?: [{	data: ValueTypes["DeleteAppGroupInput"] | Variable<any, string>},ValueTypes["ApplicationGroupResult"]],
createApplication?: [{	data: ValueTypes["CreateApplicationInput"] | Variable<any, string>},ValueTypes["ApplicationResult"]],
editApplication?: [{	data: ValueTypes["EditApplicationInput"] | Variable<any, string>},ValueTypes["ApplicationResult"]],
deleteApplication?: [{	data: ValueTypes["DeleteApplicationInput"] | Variable<any, string>},ValueTypes["ApplicationResult"]],
updateUserAccess?: [{	data: ValueTypes["updateUserAccessInput"] | Variable<any, string>},ValueTypes["UserAccess"]],
createUserGroup?: [{	data: ValueTypes["createUserGroupInput"] | Variable<any, string>},ValueTypes["UserGroup"]],
updateUserGroup?: [{	id: number | Variable<any, string>,	data: ValueTypes["updateUserGroupInput"] | Variable<any, string>},ValueTypes["UserGroup"]],
deleteUserGroup?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
createUser?: [{	data: ValueTypes["createUserInput"] | Variable<any, string>},ValueTypes["SHARED_User"]],
updateUser?: [{	id: number | Variable<any, string>,	data: ValueTypes["updateUserInput"] | Variable<any, string>},ValueTypes["SHARED_User"]],
deleteUser?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["HourInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	hourType?:boolean | `@${string}`,
	zoneId?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoadInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	infoType?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Button"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	userInputId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	userLevel?:boolean | `@${string}`,
	btnText0?:boolean | `@${string}`,
	btnText1?:boolean | `@${string}`,
	helpText0?:boolean | `@${string}`,
	helpText1?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["furnaceViewButton"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	userLevel?:boolean | `@${string}`,
	btnText0?:boolean | `@${string}`,
	btnText1?:boolean | `@${string}`,
	helpText0?:boolean | `@${string}`,
	helpText1?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewComponents"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	background?:ValueTypes["SHARED_BackgroundView"],
	text?:ValueTypes["SHARED_TextView"],
	value?:ValueTypes["SHARED_ValueViewComponent"],
	hourInfo?:ValueTypes["HourInfo"],
	loadInfo?:ValueTypes["LoadInfo"],
	loadButton?:ValueTypes["SHARED_LoadButton"],
	bitmap?:ValueTypes["SHARED_Bitmap"],
	label?:ValueTypes["SHARED_Label"],
	labelPlus?:ValueTypes["SHARED_LabelPlus"],
	rectangle?:ValueTypes["SHARED_Rectangle"],
	button?:ValueTypes["Button"],
	gif?:ValueTypes["SHARED_Gif"],
	movingPic?:ValueTypes["SHARED_MovingPic"],
	place?:ValueTypes["SHARED_Place"],
	progressBar?:ValueTypes["SHARED_ProgressBar"],
	editBox?:ValueTypes["SHARED_EditBox"],
	line?:ValueTypes["SHARED_Line"],
	windowSizeId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceValueViewComponent"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	value?:ValueTypes["SHARED_ValueViewComponent"],
	hourInfo?:ValueTypes["HourInfo"],
	loadInfo?:ValueTypes["LoadInfo"],
	place?:ValueTypes["SHARED_Place"],
	bitmap?:ValueTypes["SHARED_Bitmap"],
	gif?:ValueTypes["SHARED_Gif"],
	movingPic?:ValueTypes["SHARED_MovingPic"],
	editBox?:ValueTypes["SHARED_EditBox"],
	labelPlus?:ValueTypes["SHARED_LabelPlus"],
	label?:ValueTypes["SHARED_Label"],
	progressBar?:ValueTypes["SHARED_ProgressBar"],
	loadButton?:ValueTypes["SHARED_LoadButton"],
	rectangle?:ValueTypes["SHARED_Rectangle"],
	button?:ValueTypes["Button"],
	line?:ValueTypes["SHARED_Line"],
		__typename?: boolean | `@${string}`
}>;
	["updateFurnaceViewsResult"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	hold?:boolean | `@${string}`,
	remote?:boolean | `@${string}`,
	connected?:boolean | `@${string}`,
	updateTime?:boolean | `@${string}`,
	furnaceViews?:ValueTypes["FurnaceValueViewComponent"],
	recipeHeader?:ValueTypes["SHARED_RecipeShortHeader"],
		__typename?: boolean | `@${string}`
}>;
	["SystemColor"]: AliasType<{
	id?:boolean | `@${string}`,
	r?:boolean | `@${string}`,
	g?:boolean | `@${string}`,
	b?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceView"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	hold?:boolean | `@${string}`,
	remote?:boolean | `@${string}`,
	connected?:boolean | `@${string}`,
	updateTime?:boolean | `@${string}`,
	colors?:ValueTypes["SystemColor"],
	furnaceViews?:ValueTypes["FurnaceViewComponents"],
	recipeHeader?:ValueTypes["SHARED_RecipeShortHeader"],
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewAlarm"]: AliasType<{
	alarmId?:boolean | `@${string}`,
	workplaceName?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	acknowledgeTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
	priority?:boolean | `@${string}`,
	alarmMessage?:boolean | `@${string}`,
	gate?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
	byte?:boolean | `@${string}`,
	bit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewAlarmSearch"]: AliasType<{
	workplaceName?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	event?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActRecipeHeader"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	version?:boolean | `@${string}`,
	processOption?:ValueTypes["SHARED_OptionItem"],
	processType?:boolean | `@${string}`,
	processDepth?:boolean | `@${string}`,
	processDepthAt?:boolean | `@${string}`,
	correction?:boolean | `@${string}`,
	diff?:boolean | `@${string}`,
	loadIdentifier?:boolean | `@${string}`,
	loadName?:boolean | `@${string}`,
	actualStep?:boolean | `@${string}`,
	startingTime?:boolean | `@${string}`,
	user?:ValueTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ActRecipePositionHeader"]: AliasType<{
	positionNo?:boolean | `@${string}`,
	chamberOption?:ValueTypes["SHARED_OptionItem"],
	chamberOperationOption?:ValueTypes["SHARED_OptionItem"],
	description?:boolean | `@${string}`,
	afterTimeInMin?:boolean | `@${string}`,
	estimatedDurationInMin?:boolean | `@${string}`,
	actualDurationInMin?:boolean | `@${string}`,
	afterOption?:ValueTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ActSetPoint"]: AliasType<{
	userComponentId?:boolean | `@${string}`,
	valueType?:boolean | `@${string}`,
	meaning?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	control?:ValueTypes["SHARED_OptionItem"],
	value?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	toleranceMax?:boolean | `@${string}`,
	toleranceMin?:boolean | `@${string}`,
	alarmStartTimeInMin?:boolean | `@${string}`,
	alarmToleranceTimeInMin?:boolean | `@${string}`,
	actualValue?:boolean | `@${string}`,
	endControl?:ValueTypes["SHARED_OptionItem"],
	endValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActRecipePosition"]: AliasType<{
	header?:ValueTypes["ActRecipePositionHeader"],
	setpoints?:ValueTypes["ActSetPoint"],
	digitalOutputList?:ValueTypes["SHARED_DigitalOutput"],
	digitalOutputs?:boolean | `@${string}`,
	endAfter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActRecipeVersion"]: AliasType<{
	header?:ValueTypes["ActRecipeHeader"],
	positions?:ValueTypes["ActRecipePosition"],
	spEntries?:ValueTypes["SHARED_SpEntries"],
	material?:ValueTypes["SHARED_Material"],
	correctionProfile?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewError"]: AliasType<{
	code?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	detail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetFurnaceEditBoxValuesResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["FurnaceViewError"],
		__typename?: boolean | `@${string}`
}>;
	["EditBoxValueInput"]: {
	componentTypeId: number | Variable<any, string>,
	userInputId: number | Variable<any, string>,
	outputId: number | Variable<any, string>,
	value: number | Variable<any, string>
};
	["FurnaceLoadListGeneral"]: AliasType<{
	columnNames?:ValueTypes["SHARED_LoadColumn"],
	parts?:ValueTypes["SHARED_PartInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonValueResult"]: AliasType<{
	value?:boolean | `@${string}`,
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["FurnaceViewError"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonInput"]: {
	userInputId: number | Variable<any, string>,
	value: number | Variable<any, string>
};
	["AlarmEvent"]: AliasType<{
	event?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AlarmStatisticInfo"]: AliasType<{
	alarmNo?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	durationInSec?:boolean | `@${string}`,
	quantityPercentage?:boolean | `@${string}`,
	durationPercentage?:boolean | `@${string}`,
	events?:ValueTypes["AlarmEvent"],
		__typename?: boolean | `@${string}`
}>;
	["AlarmInfo"]: AliasType<{
	alarmNo?:boolean | `@${string}`,
	alarmSeverity?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkplaceInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Usage"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	divisor?:boolean | `@${string}`,
	summaryCounter?:boolean | `@${string}`,
	overflow?:boolean | `@${string}`,
	validSince?:boolean | `@${string}`,
	pricePerUnit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsageResult"]: AliasType<{
	usage?:ValueTypes["Usage"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["createUsageInput"]: {
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	unit?: string | undefined | null | Variable<any, string>,
	divisor?: number | undefined | null | Variable<any, string>,
	summaryCounter?: boolean | undefined | null | Variable<any, string>,
	overflow?: number | undefined | null | Variable<any, string>,
	validSince?: number | undefined | null | Variable<any, string>,
	pricePerUnit?: number | undefined | null | Variable<any, string>
};
	["updateUsageInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	unit?: string | undefined | null | Variable<any, string>,
	divisor?: number | undefined | null | Variable<any, string>,
	summaryCounter?: boolean | undefined | null | Variable<any, string>,
	overflow?: number | undefined | null | Variable<any, string>,
	validSince?: number | undefined | null | Variable<any, string>,
	pricePerUnit?: number | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["cloneUsageInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["SearchComponentType"]:SearchComponentType;
	["SearchFilter"]: AliasType<{
	filterName?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	isChecked?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceBookFilters"]: AliasType<{
	workplaces?:ValueTypes["SHARED_Workplace"],
	filters?:ValueTypes["SearchFilter"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLoadTreatment"]: AliasType<{
	recipeName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	loadIdentifier?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContainerPosition"]: AliasType<{
	partName?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	totalWeightInKg?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductionLog"]: AliasType<{
	workplace?:ValueTypes["SHARED_Workplace"],
	startingTime?:boolean | `@${string}`,
	endingTime?:boolean | `@${string}`,
	durationInSec?:boolean | `@${string}`,
	loadId?:boolean | `@${string}`,
	loadName?:boolean | `@${string}`,
	alarms?:boolean | `@${string}`,
	header?:ValueTypes["HeaderLoadTreatment"],
	positions?:ValueTypes["ContainerPosition"],
	box?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceBookAlarm"]: AliasType<{
	messageNo?:boolean | `@${string}`,
	event?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProfileChartData"]: AliasType<{
	yUnit?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProfileChart"]: AliasType<{
	restaust?:boolean | `@${string}`,
	surfC?:boolean | `@${string}`,
	xUnit?:boolean | `@${string}`,
	charts?:ValueTypes["ProfileChartData"],
		__typename?: boolean | `@${string}`
}>;
	["Consumption"]: AliasType<{
	media?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	costs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChargeConsumption"]: AliasType<{
	consumption?:ValueTypes["Consumption"],
	totalCosts?:boolean | `@${string}`,
	currency?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsageEntry"]: AliasType<{
	workplace?:boolean | `@${string}`,
	chargesCounter?:boolean | `@${string}`,
	production?:ValueTypes["ChargeConsumption"],
	averagePerCharge?:ValueTypes["ChargeConsumption"],
	standBy?:ValueTypes["ChargeConsumption"],
	total?:ValueTypes["ChargeConsumption"],
		__typename?: boolean | `@${string}`
}>;
	["UtilizationMode"]: {
	type?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>
};
	["UsageValue"]: AliasType<{
	unit?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UtilizationTotal"]: AliasType<{
	workplace?:boolean | `@${string}`,
	numberOfLoads?:boolean | `@${string}`,
	numberOfLoadsEvaluated?:boolean | `@${string}`,
	loadsRatio?:ValueTypes["UsageValue"],
	totalTime?:ValueTypes["UsageValue"],
	productionTime?:ValueTypes["UsageValue"],
	timeRatio?:ValueTypes["UsageValue"],
	averageLoadingTime?:ValueTypes["UsageValue"],
	availability?:ValueTypes["UsageValue"],
	downtime?:ValueTypes["UsageValue"],
	downtimeReason?:ValueTypes["UsageValue"],
	downtimeNoReason?:ValueTypes["UsageValue"],
	utilization?:ValueTypes["UsageValue"],
	capacityLoss?:ValueTypes["UsageValue"],
	kpi?:boolean | `@${string}`,
	targetWeight?:ValueTypes["UsageValue"],
	produced?:ValueTypes["UsageValue"],
	weightRatio?:ValueTypes["UsageValue"],
	flowRate?:ValueTypes["UsageValue"],
		__typename?: boolean | `@${string}`
}>;
	["UtilizationPeriod"]: AliasType<{
	period?:boolean | `@${string}`,
	target?:ValueTypes["UsageValue"],
	actual?:ValueTypes["UsageValue"],
	utilizationRate?:ValueTypes["UsageValue"],
		__typename?: boolean | `@${string}`
}>;
	["UtilizationUsage"]: AliasType<{
	workplace?:boolean | `@${string}`,
	loadsEvaluated?:boolean | `@${string}`,
	runtime?:ValueTypes["UsageValue"],
	weight?:ValueTypes["UsageValue"],
	usage?:ValueTypes["UsageValue"],
	usageLoad?:ValueTypes["UsageValue"],
	usageRuntime?:ValueTypes["UsageValue"],
		__typename?: boolean | `@${string}`
}>;
	["Utilization"]: AliasType<{
	totalInfo?:ValueTypes["UtilizationTotal"],
	periodInfo?:ValueTypes["UtilizationPeriod"],
	usageInfo?:ValueTypes["UtilizationUsage"],
		__typename?: boolean | `@${string}`
}>;
	["CptEntry"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeHeaderROL"]: AliasType<{
	chargeIdentifier?:boolean | `@${string}`,
	recipeName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeVersionROL"]: AliasType<{
	id?:boolean | `@${string}`,
	startingTime?:boolean | `@${string}`,
	versionText?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetpointROL"]: AliasType<{
	name?:boolean | `@${string}`,
	control?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	toleranceMax?:boolean | `@${string}`,
	toleranceMin?:boolean | `@${string}`,
	alarmStartTimeInMin?:boolean | `@${string}`,
	alarmToleranceTimeInMin?:boolean | `@${string}`,
	actualValue?:boolean | `@${string}`,
	endControl?:boolean | `@${string}`,
	endValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DigitalOutputROL"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AfterTimeROL"]: AliasType<{
	activeOption?:boolean | `@${string}`,
	options?:ValueTypes["SHARED_OptionItem"],
	durationInSec?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StepHeaderROL"]: AliasType<{
	chamberOptions?:ValueTypes["SHARED_OptionItem"],
	operationOptions?:ValueTypes["SHARED_OptionItem"],
	description?:boolean | `@${string}`,
	estimatedDurationInSec?:boolean | `@${string}`,
	afterOptions?:ValueTypes["AfterTimeROL"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeStepROL"]: AliasType<{
	id?:boolean | `@${string}`,
	header?:ValueTypes["StepHeaderROL"],
	chamber?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	operation?:boolean | `@${string}`,
	setpoints?:ValueTypes["SetpointROL"],
	digitalOutputs?:boolean | `@${string}`,
	digitalOutputList?:ValueTypes["DigitalOutputROL"],
	endAfter?:boolean | `@${string}`,
	actualDurationInMin?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeROL"]: AliasType<{
	header?:ValueTypes["RecipeHeaderROL"],
	versions?:ValueTypes["RecipeVersionROL"],
	steps?:ValueTypes["RecipeStepROL"],
		__typename?: boolean | `@${string}`
}>;
	["PartHeader"]: AliasType<{
	contentName?:boolean | `@${string}`,
	weightInKg?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	modifiedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PartData"]: AliasType<{
	pos?:boolean | `@${string}`,
	partName?:boolean | `@${string}`,
	partDescription?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	weightInKg?:boolean | `@${string}`,
	totalWeightInKg?:boolean | `@${string}`,
	orderName?:boolean | `@${string}`,
	orderRemark?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	customer?:boolean | `@${string}`,
	intern?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Part"]: AliasType<{
	header?:ValueTypes["PartHeader"],
	parts?:ValueTypes["PartData"],
		__typename?: boolean | `@${string}`
}>;
	["PartsStatistics"]: AliasType<{
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	weight?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	no?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	dataBase64?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Filter"]: {
	orderName?: string | undefined | null | Variable<any, string>,
	partDescription?: string | undefined | null | Variable<any, string>,
	recipeName?: string | undefined | null | Variable<any, string>,
	seriesNumber?: string | undefined | null | Variable<any, string>,
	material?: string | undefined | null | Variable<any, string>,
	loadIdentifier?: string | undefined | null | Variable<any, string>,
	sample?: string | undefined | null | Variable<any, string>,
	chdFrom?: number | undefined | null | Variable<any, string>,
	chdTo?: number | undefined | null | Variable<any, string>,
	trayName?: string | undefined | null | Variable<any, string>,
	partName?: string | undefined | null | Variable<any, string>,
	loadName?: string | undefined | null | Variable<any, string>,
	suchfeld?: string | undefined | null | Variable<any, string>,
	aufgepackt?: string | undefined | null | Variable<any, string>,
	abgepackt?: string | undefined | null | Variable<any, string>,
	cellLoadName?: string | undefined | null | Variable<any, string>,
	processFlowName?: string | undefined | null | Variable<any, string>,
	customer?: string | undefined | null | Variable<any, string>,
	sampleCheckbox?: boolean | undefined | null | Variable<any, string>,
	series?: boolean | undefined | null | Variable<any, string>,
	addWork?: boolean | undefined | null | Variable<any, string>,
	own?: boolean | undefined | null | Variable<any, string>,
	foreign?: boolean | undefined | null | Variable<any, string>,
	earnings?: boolean | undefined | null | Variable<any, string>,
	withAlarms?: boolean | undefined | null | Variable<any, string>,
	withOrderPartInfo?: boolean | undefined | null | Variable<any, string>
};
	["DeleteImageInput"]: {
	loadName: string | Variable<any, string>,
	no: number | Variable<any, string>
};
	["FailureReason"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Fixture"]: AliasType<{
	id?:boolean | `@${string}`,
	no?:boolean | `@${string}`,
	fixture?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FixtureType"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	typeOfWorkplace?:boolean | `@${string}`,
	workplace?:boolean | `@${string}`,
	maximumHeight?:boolean | `@${string}`,
	maximumWidth?:boolean | `@${string}`,
	maximumDepth?:boolean | `@${string}`,
	maximumWeight?:boolean | `@${string}`,
	minimumWeight?:boolean | `@${string}`,
	loadWeight?:boolean | `@${string}`,
	ownWeight?:boolean | `@${string}`,
	maxNumOfLoads?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerSpecificData"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	floatValue?:boolean | `@${string}`,
	longValue?:boolean | `@${string}`,
	characterValue?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerSpecificDataResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
	customerSpecificData?:ValueTypes["CustomerSpecificData"],
		__typename?: boolean | `@${string}`
}>;
	["CreateCustomerSpecificDataInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	floatValue?: number | undefined | null | Variable<any, string>,
	longValue?: number | undefined | null | Variable<any, string>,
	characterValue?: string | undefined | null | Variable<any, string>
};
	["EditCustomerSpecificDataInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	floatValue?: number | undefined | null | Variable<any, string>,
	longValue?: number | undefined | null | Variable<any, string>,
	characterValue?: string | undefined | null | Variable<any, string>
};
	["DeleteCustomerSpecificDataInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["EngineeringUnit"]: AliasType<{
	id?:boolean | `@${string}`,
	shortDescription?:boolean | `@${string}`,
	descripton?:boolean | `@${string}`,
	physicalUnit?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	peToEuType?:boolean | `@${string}`,
	peToEu?:boolean | `@${string}`,
	euToPeType?:boolean | `@${string}`,
	euToPeString?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitValue"]: AliasType<{
	unitValue?:boolean | `@${string}`,
	default?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Unit"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:ValueTypes["UnitValue"],
		__typename?: boolean | `@${string}`
}>;
	["UserUnits"]: AliasType<{
	user?:boolean | `@${string}`,
	units?:ValueTypes["Unit"],
		__typename?: boolean | `@${string}`
}>;
	["OLD_MaterialGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OLD_NormMaterial"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	groupId?:boolean | `@${string}`,
	groupName?:boolean | `@${string}`,
	elementValuesFrom?:ValueTypes["SHARED_ElementValueList"],
	elementValuesTo?:ValueTypes["SHARED_ElementValueList"],
	averageAlloyFactor?:boolean | `@${string}`,
	carbideLimit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPlan"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Setpoints"]: AliasType<{
	recipeId?:boolean | `@${string}`,
	recipeName?:boolean | `@${string}`,
	numberOfPartsPerTray?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ToolingInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tooling"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoadInformation"]: AliasType<{
	packInformation?:boolean | `@${string}`,
	image1Base64?:boolean | `@${string}`,
	image2Base64?:boolean | `@${string}`,
	image3Base64?:boolean | `@${string}`,
	toolings?:ValueTypes["ToolingInfo"],
		__typename?: boolean | `@${string}`
}>;
	["PartWorkplaceInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	setpoints?:ValueTypes["Setpoints"],
	loadInformation?:ValueTypes["LoadInformation"],
		__typename?: boolean | `@${string}`
}>;
	["PartManagementInfo"]: AliasType<{
	partInfo?:ValueTypes["SHARED_PartInfo"],
	partWorkplaceList?:ValueTypes["PartWorkplaceInfo"],
		__typename?: boolean | `@${string}`
}>;
	["SetPointItem"]: AliasType<{
	userComponentId?:boolean | `@${string}`,
	relevant?:boolean | `@${string}`,
	valueType?:boolean | `@${string}`,
	meaning?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	control?:ValueTypes["SHARED_OptionItem"],
	value?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	toleranceMax?:boolean | `@${string}`,
	toleranceMin?:boolean | `@${string}`,
	alarmStartTimeInMin?:boolean | `@${string}`,
	alarmToleranceTimeInMin?:boolean | `@${string}`,
	endControl?:ValueTypes["SHARED_OptionItem"],
	endValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemPositionHeader"]: AliasType<{
	positionNo?:boolean | `@${string}`,
	chamberOption?:ValueTypes["SHARED_OptionItem"],
	chamberOperationOption?:ValueTypes["SHARED_OptionItem"],
	description?:boolean | `@${string}`,
	afterTimeInMin?:boolean | `@${string}`,
	estimatedDurationInMin?:boolean | `@${string}`,
	afterOption?:ValueTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemPosition"]: AliasType<{
	header?:ValueTypes["RecipeItemPositionHeader"],
	setpoints?:ValueTypes["SetPointItem"],
	digitalOutputList?:ValueTypes["SHARED_DigitalOutput"],
	digitalOutputs?:boolean | `@${string}`,
	endAfter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemHeader"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	version?:boolean | `@${string}`,
	processOption?:ValueTypes["SHARED_OptionItem"],
	processType?:boolean | `@${string}`,
	processDepth?:boolean | `@${string}`,
	processDepthAt?:boolean | `@${string}`,
	correction?:boolean | `@${string}`,
	diff?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemVersion"]: AliasType<{
	header?:ValueTypes["RecipeItemHeader"],
	positions?:ValueTypes["RecipeItemPosition"],
	spEntries?:ValueTypes["SHARED_SpEntries"],
	material?:ValueTypes["SHARED_Material"],
	correctionProfile?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeListItem"]: AliasType<{
	id?:boolean | `@${string}`,
	recipe?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CloneRecipeAndSaveAsResult"]: AliasType<{
	recipeVersions?:ValueTypes["RecipeItemVersion"],
	isSaved?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SaveRecipeResult"]: AliasType<{
	recipeVersion?:ValueTypes["RecipeItemVersion"],
	isSaved?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MetaDataSetPoint"]: AliasType<{
	name?:boolean | `@${string}`,
	userComponentId?:boolean | `@${string}`,
	meaning?:boolean | `@${string}`,
	relevant?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	valueType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MetaDataMethods"]: AliasType<{
	setPoints?:ValueTypes["MetaDataSetPoint"],
	digitalInputs?:ValueTypes["MetaDataSetPoint"],
	digitalOutputs?:ValueTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["OperationOptions"]: AliasType<{
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	validVarIds?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ControlMetadata"]: AliasType<{
	setPoints?:ValueTypes["MetaDataSetPoint"],
	digitalInputs?:ValueTypes["MetaDataSetPoint"],
	digitalOutputs?:ValueTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["OperationMetadata"]: AliasType<{
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	setPoints?:ValueTypes["MetaDataSetPoint"],
	digitalInputs?:ValueTypes["MetaDataSetPoint"],
	digitalOutputs?:ValueTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["MetaDataChamberOption"]: AliasType<{
	chamberId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	recipeControl?:ValueTypes["ControlMetadata"],
	operationControl?:ValueTypes["OperationMetadata"],
	operationOptions?:ValueTypes["SHARED_OptionItem"],
	setPoints?:ValueTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeMetadata"]: AliasType<{
	afterOptions?:ValueTypes["SHARED_OptionItem"],
	controlOptions?:ValueTypes["SHARED_OptionItem"],
	endControlOptions?:ValueTypes["SHARED_OptionItem"],
	chamberOptions?:ValueTypes["MetaDataChamberOption"],
	operationProcessList?:ValueTypes["SHARED_Operation"],
	materialList?:ValueTypes["SHARED_OLD_CustomerMaterial"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeVersionsHeaderInput"]: {
	name: string | Variable<any, string>,
	pgmPrefix: string | Variable<any, string>,
	versionIds: Array<string | undefined | null> | Variable<any, string>
};
	["CloneRecipeAndSaveAsInput"]: {
	oldRecipeName: string | Variable<any, string>,
	newRecipeName: string | Variable<any, string>,
	description: string | Variable<any, string>,
	recipePrefix: string | Variable<any, string>
};
	["SetPointInput"]: {
	meaning: number | Variable<any, string>,
	userComponentId: number | Variable<any, string>,
	relevant: number | Variable<any, string>,
	value?: number | undefined | null | Variable<any, string>,
	valueType?: number | undefined | null | Variable<any, string>,
	toleranceMax?: number | undefined | null | Variable<any, string>,
	toleranceMin?: number | undefined | null | Variable<any, string>,
	alarmStartTimeInMin?: number | undefined | null | Variable<any, string>,
	alarmToleranceTimeInMin?: number | undefined | null | Variable<any, string>,
	controlOptionNo?: number | undefined | null | Variable<any, string>,
	endControlOptionNo?: number | undefined | null | Variable<any, string>,
	endValue?: number | undefined | null | Variable<any, string>
};
	["PositionHeaderInput"]: {
	positionNo: number | Variable<any, string>,
	afterTimeOptionNo: number | Variable<any, string>,
	afterTimeInMin?: number | undefined | null | Variable<any, string>,
	estimatedTimeInMin?: number | undefined | null | Variable<any, string>,
	chamberId: number | Variable<any, string>,
	description: string | Variable<any, string>,
	operationId: number | Variable<any, string>,
	endOr?: number | undefined | null | Variable<any, string>,
	valueDepth?: number | undefined | null | Variable<any, string>
};
	["RecipePositionInput"]: {
	header: ValueTypes["PositionHeaderInput"] | Variable<any, string>,
	setPoints: Array<ValueTypes["SetPointInput"] | undefined | null> | Variable<any, string>
};
	["SpEntriesInput"]: {
	furnaceSwitchEntry?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	recipeDescription2?: string | undefined | null | Variable<any, string>,
	recipeDescription3?: string | undefined | null | Variable<any, string>,
	recipeDescription4?: string | undefined | null | Variable<any, string>,
	gasEntryValues?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["AnalysisInput"]: {
	c?: number | undefined | null | Variable<any, string>,
	si?: number | undefined | null | Variable<any, string>,
	mn?: number | undefined | null | Variable<any, string>,
	cr?: number | undefined | null | Variable<any, string>,
	ni?: number | undefined | null | Variable<any, string>,
	v?: number | undefined | null | Variable<any, string>,
	al?: number | undefined | null | Variable<any, string>,
	cu?: number | undefined | null | Variable<any, string>,
	p?: number | undefined | null | Variable<any, string>,
	mo?: number | undefined | null | Variable<any, string>,
	s?: number | undefined | null | Variable<any, string>,
	b?: number | undefined | null | Variable<any, string>,
	ti?: number | undefined | null | Variable<any, string>
};
	["MaterialInput"]: {
	carbonContent?: number | undefined | null | Variable<any, string>,
	alloyFactor?: number | undefined | null | Variable<any, string>,
	legFactorN?: number | undefined | null | Variable<any, string>,
	carbideLimit?: number | undefined | null | Variable<any, string>,
	temperature?: number | undefined | null | Variable<any, string>,
	specialNitrides?: number | undefined | null | Variable<any, string>,
	analysis?: ValueTypes["AnalysisInput"] | undefined | null | Variable<any, string>,
	co?: number | undefined | null | Variable<any, string>,
	h2?: number | undefined | null | Variable<any, string>,
	quenchingIntensity?: number | undefined | null | Variable<any, string>,
	representativeDiameter?: number | undefined | null | Variable<any, string>,
	requiredHardness?: number | undefined | null | Variable<any, string>,
	grainSize?: number | undefined | null | Variable<any, string>,
	endTemperature?: number | undefined | null | Variable<any, string>,
	targetSurfC?: number | undefined | null | Variable<any, string>,
	customerMaterialId?: number | undefined | null | Variable<any, string>,
	partId?: number | undefined | null | Variable<any, string>
};
	["RecipeHeaderInput"]: {
	recipeName: string | Variable<any, string>,
	description: string | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	processId: number | Variable<any, string>,
	processType: number | Variable<any, string>,
	processDepth?: number | undefined | null | Variable<any, string>,
	processDepthAt?: number | undefined | null | Variable<any, string>,
	diff: boolean | Variable<any, string>,
	correction: boolean | Variable<any, string>
};
	["SaveNewRecipeInput"]: {
	recipePrefix: string | Variable<any, string>,
	header: ValueTypes["RecipeHeaderInput"] | Variable<any, string>,
	positions: Array<ValueTypes["RecipePositionInput"] | undefined | null> | Variable<any, string>,
	spEntries?: ValueTypes["SpEntriesInput"] | undefined | null | Variable<any, string>,
	material: ValueTypes["MaterialInput"] | Variable<any, string>,
	correctionProfile?: Array<number | undefined | null> | undefined | null | Variable<any, string>
};
	["saveEditedRecipeVersionInput"]: {
	recipePrefix: string | Variable<any, string>,
	headId: number | Variable<any, string>,
	editedVersionNo: number | Variable<any, string>,
	editedRecipeName: string | Variable<any, string>,
	createNewVersion: boolean | Variable<any, string>,
	header: ValueTypes["RecipeHeaderInput"] | Variable<any, string>,
	positions: Array<ValueTypes["RecipePositionInput"] | undefined | null> | Variable<any, string>,
	spEntries?: ValueTypes["SpEntriesInput"] | undefined | null | Variable<any, string>,
	material: ValueTypes["MaterialInput"] | Variable<any, string>,
	correctionProfile?: Array<number | undefined | null> | undefined | null | Variable<any, string>
};
	["PeAttributeType"]: AliasType<{
	id?:boolean | `@${string}`,
	peAttributeTypeId?:boolean | `@${string}`,
	forDiffCalculation?:boolean | `@${string}`,
	forNitriding?:boolean | `@${string}`,
	hasMinimumLimit?:boolean | `@${string}`,
	hasMaximumLimit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PeAttributeTypeResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	peAttributeType?:ValueTypes["PeAttributeType"],
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePeAttributeTypeInput"]: {
	peAttributeTypeId: number | Variable<any, string>,
	forDiffCalculation: boolean | Variable<any, string>,
	forNitriding: boolean | Variable<any, string>,
	hasMinimumLimit: boolean | Variable<any, string>,
	hasMaximumLimit: boolean | Variable<any, string>
};
	["EditPeAttributeTypeInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	peAttributeTypeId: number | Variable<any, string>,
	forDiffCalculation: boolean | Variable<any, string>,
	forNitriding: boolean | Variable<any, string>,
	hasMinimumLimit: boolean | Variable<any, string>,
	hasMaximumLimit: boolean | Variable<any, string>
};
	["DeletePeAttributeTypeInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["PeProcessType"]: AliasType<{
	id?:boolean | `@${string}`,
	phenNoId?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PeProcessTypeResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	peProcessType?:ValueTypes["PeProcessType"],
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePeProcessTypeInput"]: {
	phenNoId: number | Variable<any, string>
};
	["EditPeProcessTypeInput"]: {
	id: number | Variable<any, string>,
	phenNoId: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["DeletePeProcessTypeInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["PhysicalUnit"]: AliasType<{
	id?:boolean | `@${string}`,
	shortDescription?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	typeId?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PhysicalUnitResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	physicalUnit?:ValueTypes["PhysicalUnit"],
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePhysicalUnitInput"]: {
	shortDescription?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	typeId?: number | undefined | null | Variable<any, string>,
	unit?: string | undefined | null | Variable<any, string>
};
	["EditPhysicalUnitInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	shortDescription?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	typeId?: number | undefined | null | Variable<any, string>,
	unit?: string | undefined | null | Variable<any, string>
};
	["DeletePhysicalUnitInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["updateWorkplaceTypeInput"]: {
	number: number | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	prio?: number | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["createWorkplaceTypeInput"]: {
	number: number | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	prio?: number | undefined | null | Variable<any, string>
};
	["cloneWorkplaceTypeInput"]: {
	number: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["WorkplaceTypeResult"]: AliasType<{
	workplaceType?:ValueTypes["SHARED_WorkplaceType"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["BackgroundProcess"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	argument?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	pid?:boolean | `@${string}`,
	activeTime?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProcessResult"]: AliasType<{
	process?:ValueTypes["BackgroundProcess"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteProcessInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["CloneProcessInput"]: {
	id: number | Variable<any, string>,
	name: string | Variable<any, string>,
	description: string | Variable<any, string>
};
	["EditProcessInput"]: {
	id: number | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	argument?: string | undefined | null | Variable<any, string>,
	active?: string | undefined | null | Variable<any, string>,
	pid?: number | undefined | null | Variable<any, string>,
	activeTime?: number | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["CreateNewProcessInput"]: {
	name: string | Variable<any, string>,
	description: string | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	argument?: string | undefined | null | Variable<any, string>,
	active?: string | undefined | null | Variable<any, string>,
	pid?: number | undefined | null | Variable<any, string>,
	activeTime?: number | undefined | null | Variable<any, string>
};
	["createWorkplaceInput"]: {
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	inventoryNumber?: string | undefined | null | Variable<any, string>,
	type?: number | undefined | null | Variable<any, string>,
	active?: number | undefined | null | Variable<any, string>,
	useMode?: number | undefined | null | Variable<any, string>,
	logicType?: string | undefined | null | Variable<any, string>,
	recipePrefix?: string | undefined | null | Variable<any, string>,
	chamberId?: number | undefined | null | Variable<any, string>,
	sharesController?: number | undefined | null | Variable<any, string>,
	belongsTo?: string | undefined | null | Variable<any, string>,
	ip?: string | undefined | null | Variable<any, string>,
	mGuardIp?: string | undefined | null | Variable<any, string>,
	device?: string | undefined | null | Variable<any, string>,
	telegramSplit?: boolean | undefined | null | Variable<any, string>,
	deactivateOperations?: boolean | undefined | null | Variable<any, string>,
	aliveBitErrorNum?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["updateWorkplaceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	inventoryNumber?: string | undefined | null | Variable<any, string>,
	type?: number | undefined | null | Variable<any, string>,
	active?: number | undefined | null | Variable<any, string>,
	useMode?: number | undefined | null | Variable<any, string>,
	logicType?: string | undefined | null | Variable<any, string>,
	recipePrefix?: string | undefined | null | Variable<any, string>,
	chamberId?: number | undefined | null | Variable<any, string>,
	sharesController?: number | undefined | null | Variable<any, string>,
	belongsTo?: string | undefined | null | Variable<any, string>,
	ip?: string | undefined | null | Variable<any, string>,
	mGuardIp?: string | undefined | null | Variable<any, string>,
	device?: string | undefined | null | Variable<any, string>,
	telegramSplit?: boolean | undefined | null | Variable<any, string>,
	deactivateOperations?: boolean | undefined | null | Variable<any, string>,
	aliveBitErrorNum?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["cloneWorkplaceInput"]: {
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["WorkplaceResult"]: AliasType<{
	workplace?:ValueTypes["SHARED_Workplace"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["OperationType"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationPart"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationProcess"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	sap?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateOperationProcess"]: {
	order?: number | undefined | null | Variable<any, string>,
	sap?: string | undefined | null | Variable<any, string>,
	workplaceId: number | Variable<any, string>
};
	["Operation"]: AliasType<{
	id?:boolean | `@${string}`,
	processTypeId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	operationType?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	relevantForStat?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	typeId?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
	operationProcess?:ValueTypes["OperationProcess"],
	operationParts?:ValueTypes["OperationPart"],
		__typename?: boolean | `@${string}`
}>;
	["CreateOperation"]: {
	processTypeId?: number | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	comment: string | Variable<any, string>,
	order: number | Variable<any, string>,
	relevantForStat: boolean | Variable<any, string>,
	mode: number | Variable<any, string>,
	typeId: number | Variable<any, string>,
	operationProcess?: Array<ValueTypes["CreateOperationProcess"] | undefined | null> | undefined | null | Variable<any, string>,
	operationParts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
};
	["EditOperation"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	processTypeId?: number | undefined | null | Variable<any, string>,
	comment: string | Variable<any, string>,
	order: number | Variable<any, string>,
	relevantForStat: boolean | Variable<any, string>,
	mode: number | Variable<any, string>,
	typeId: number | Variable<any, string>,
	operationProcess?: Array<ValueTypes["CreateOperationProcess"] | undefined | null> | undefined | null | Variable<any, string>,
	operationParts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
};
	["DeleteOperation"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["OperationResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	operation?:ValueTypes["Operation"],
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["Alarm"]: AliasType<{
	id?:boolean | `@${string}`,
	workplace?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	alarm?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
	errorType?:boolean | `@${string}`,
	outputType?:boolean | `@${string}`,
	security?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	onlyForChamber?:boolean | `@${string}`,
	causesATestingLoad?:boolean | `@${string}`,
	alarmGroup?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChambersPerWorkplace"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	chamberList?:ValueTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["AlarmAdministrationMetadata"]: AliasType<{
	workplaceList?:ValueTypes["SHARED_OptionItem"],
	errorTypeList?:ValueTypes["SHARED_OptionItem"],
	outputTypeList?:ValueTypes["SHARED_OptionItem"],
	alarmGroupList?:ValueTypes["SHARED_OptionItem"],
	importanceList?:ValueTypes["SHARED_OptionItem"],
	colorList?:ValueTypes["SHARED_OptionItem"],
	chambersList?:ValueTypes["ChambersPerWorkplace"],
		__typename?: boolean | `@${string}`
}>;
	["AlarmResult"]: AliasType<{
	alarm?:ValueTypes["Alarm"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreateNewAlarmInput"]: {
	workplaceId: number | Variable<any, string>,
	alarmNo: number | Variable<any, string>,
	alarmText: string | Variable<any, string>,
	errorTypeId: number | Variable<any, string>,
	outputTypeId: number | Variable<any, string>,
	importanceId?: number | undefined | null | Variable<any, string>,
	security: number | Variable<any, string>,
	colorId: number | Variable<any, string>,
	alarmGroupId: number | Variable<any, string>,
	causeTestingLoad: boolean | Variable<any, string>,
	onlyForChamber: number | Variable<any, string>
};
	["CloneAndSaveAsInput"]: {
	id: number | Variable<any, string>,
	alarmNo: number | Variable<any, string>
};
	["EditAlarmInput"]: {
	id: number | Variable<any, string>,
	alarmNo?: number | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	workplaceId?: number | undefined | null | Variable<any, string>,
	alarmText?: string | undefined | null | Variable<any, string>,
	errorTypeId?: number | undefined | null | Variable<any, string>,
	outputTypeId?: number | undefined | null | Variable<any, string>,
	importanceId?: number | undefined | null | Variable<any, string>,
	security?: number | undefined | null | Variable<any, string>,
	colorId?: number | undefined | null | Variable<any, string>,
	onlyForChamber?: number | undefined | null | Variable<any, string>,
	alarmGroupId?: number | undefined | null | Variable<any, string>,
	causeTestingLoad?: boolean | undefined | null | Variable<any, string>
};
	["DeleteAlarmInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["DeleteSelectedAlarmsForFurnaceInput"]: {
	workplaceId: number | Variable<any, string>,
	selectedAlarms: Array<ValueTypes["DeleteAlarmInput"] | undefined | null> | Variable<any, string>
};
	["Box"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	colorId?:boolean | `@${string}`,
	used?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorBox"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	redValue?:boolean | `@${string}`,
	greenValue?:boolean | `@${string}`,
	blueValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateBoxInput"]: {
	name: string | Variable<any, string>,
	colorId: number | Variable<any, string>,
	used: boolean | Variable<any, string>
};
	["EditBoxInput"]: {
	id: number | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	colorId?: number | undefined | null | Variable<any, string>,
	used?: boolean | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["DeleteBoxInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["BoxResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	box?:ValueTypes["Box"],
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["Color"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	redValue?:boolean | `@${string}`,
	greenValue?:boolean | `@${string}`,
	blueValue?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	color?:ValueTypes["Color"],
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreateColorInput"]: {
	name: string | Variable<any, string>,
	redValue: number | Variable<any, string>,
	greenValue: number | Variable<any, string>,
	blueValue: number | Variable<any, string>
};
	["EditColorInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	redValue?: number | undefined | null | Variable<any, string>,
	greenValue?: number | undefined | null | Variable<any, string>,
	blueValue?: number | undefined | null | Variable<any, string>
};
	["deleteColorInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["LanguageInfo"]: AliasType<{
	languageId?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TranslationInfo"]: AliasType<{
	languageId?:boolean | `@${string}`,
	translation?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DictionaryItem"]: AliasType<{
	textId?:boolean | `@${string}`,
	defaultText?:boolean | `@${string}`,
	peProperty?:boolean | `@${string}`,
	used?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	translations?:ValueTypes["TranslationInfo"],
	customerTranslations?:ValueTypes["TranslationInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ExternalizeTranslationConfigValue"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SmsSettings"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	addresseeMobilePhoneNo?:boolean | `@${string}`,
	pin?:boolean | `@${string}`,
	serialInterface?:boolean | `@${string}`,
	testMessage?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Task"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	command1?:boolean | `@${string}`,
	command2?:boolean | `@${string}`,
	command3?:boolean | `@${string}`,
	command4?:boolean | `@${string}`,
	command5?:boolean | `@${string}`,
	command6?:boolean | `@${string}`,
	command7?:boolean | `@${string}`,
	command8?:boolean | `@${string}`,
	command9?:boolean | `@${string}`,
	command10?:boolean | `@${string}`,
	startHour?:boolean | `@${string}`,
	intervalInMin?:boolean | `@${string}`,
	dateLastActive?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskResult"]: AliasType<{
	task?:ValueTypes["Task"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["createTaskInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	active?: boolean | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	command1?: string | undefined | null | Variable<any, string>,
	command2?: string | undefined | null | Variable<any, string>,
	command3?: string | undefined | null | Variable<any, string>,
	command4?: string | undefined | null | Variable<any, string>,
	command5?: string | undefined | null | Variable<any, string>,
	command6?: string | undefined | null | Variable<any, string>,
	command7?: string | undefined | null | Variable<any, string>,
	command8?: string | undefined | null | Variable<any, string>,
	command9?: string | undefined | null | Variable<any, string>,
	command10?: string | undefined | null | Variable<any, string>,
	startHour?: number | undefined | null | Variable<any, string>,
	intervalInMin?: number | undefined | null | Variable<any, string>,
	dateLastActive?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["updateTaskInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	active?: boolean | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	command1?: string | undefined | null | Variable<any, string>,
	command2?: string | undefined | null | Variable<any, string>,
	command3?: string | undefined | null | Variable<any, string>,
	command4?: string | undefined | null | Variable<any, string>,
	command5?: string | undefined | null | Variable<any, string>,
	command6?: string | undefined | null | Variable<any, string>,
	command7?: string | undefined | null | Variable<any, string>,
	command8?: string | undefined | null | Variable<any, string>,
	command9?: string | undefined | null | Variable<any, string>,
	command10?: string | undefined | null | Variable<any, string>,
	startHour?: number | undefined | null | Variable<any, string>,
	intervalInMin?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["cloneTaskInput"]: {
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["updateClientAccessClientListInput"]: {
	clientId: number | Variable<any, string>,
	isActive: boolean | Variable<any, string>
};
	["updateClientAccessResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["TerminalType"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	osTypeId?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TerminalTypeResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
	terminalType?:ValueTypes["TerminalType"],
		__typename?: boolean | `@${string}`
}>;
	["CreateTerminalTypeInput"]: {
	description?: string | undefined | null | Variable<any, string>,
	osTypeId: number | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>
};
	["EditTerminalTypeInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	osTypeId?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>
};
	["DeleteTerminalTypeInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["TerminalClient"]: AliasType<{
	id?:boolean | `@${string}`,
	clientTypeId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adress?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	resolution?:boolean | `@${string}`,
	valueLong1?:boolean | `@${string}`,
	valueLong2?:boolean | `@${string}`,
	valueLong3?:boolean | `@${string}`,
	valueLong4?:boolean | `@${string}`,
	valueLong5?:boolean | `@${string}`,
	valueFloat1?:boolean | `@${string}`,
	valueFloat2?:boolean | `@${string}`,
	valueFloat3?:boolean | `@${string}`,
	valueChar1?:boolean | `@${string}`,
	valueChar2?:boolean | `@${string}`,
	actUserId?:boolean | `@${string}`,
	actLoginTime?:boolean | `@${string}`,
	lastUserId?:boolean | `@${string}`,
	lastLoginTime?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TerminalClientResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
	terminalClient?:ValueTypes["TerminalClient"],
		__typename?: boolean | `@${string}`
}>;
	["CreateTerminalClientInput"]: {
	clientTypeId?: number | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adress?: string | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	resolution?: number | undefined | null | Variable<any, string>,
	valueLong1?: number | undefined | null | Variable<any, string>,
	valueLong2?: number | undefined | null | Variable<any, string>,
	valueLong3?: number | undefined | null | Variable<any, string>,
	valueLong4?: number | undefined | null | Variable<any, string>,
	valueLong5?: number | undefined | null | Variable<any, string>,
	valueFloat1?: number | undefined | null | Variable<any, string>,
	valueFloat2?: number | undefined | null | Variable<any, string>,
	valueFloat3?: number | undefined | null | Variable<any, string>,
	valueChar1?: string | undefined | null | Variable<any, string>,
	valueChar2?: string | undefined | null | Variable<any, string>,
	actUserId?: number | undefined | null | Variable<any, string>,
	actLoginTime?: number | undefined | null | Variable<any, string>,
	lastUserId?: number | undefined | null | Variable<any, string>,
	lastLoginTime?: number | undefined | null | Variable<any, string>
};
	["EditTerminalClientInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>,
	clientTypeId?: number | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adress?: string | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	resolution?: number | undefined | null | Variable<any, string>,
	valueLong1?: number | undefined | null | Variable<any, string>,
	valueLong2?: number | undefined | null | Variable<any, string>,
	valueLong3?: number | undefined | null | Variable<any, string>,
	valueLong4?: number | undefined | null | Variable<any, string>,
	valueLong5?: number | undefined | null | Variable<any, string>,
	valueFloat1?: number | undefined | null | Variable<any, string>,
	valueFloat2?: number | undefined | null | Variable<any, string>,
	valueFloat3?: number | undefined | null | Variable<any, string>,
	valueChar1?: string | undefined | null | Variable<any, string>,
	valueChar2?: string | undefined | null | Variable<any, string>,
	actUserId?: number | undefined | null | Variable<any, string>,
	actLoginTime?: number | undefined | null | Variable<any, string>,
	lastUserId?: number | undefined | null | Variable<any, string>,
	lastLoginTime?: number | undefined | null | Variable<any, string>
};
	["DeleteTerminalClientInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["TerminalClientMetadata"]: AliasType<{
	resolutions?:ValueTypes["SHARED_OptionItem"],
	users?:ValueTypes["SHARED_OptionItem"],
	clientTypes?:ValueTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ApplicationGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ApplicationGroupResult"]: AliasType<{
	applicationGroup?:ValueTypes["ApplicationGroup"],
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreateAppGroupInput"]: {
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>
};
	["EditAppGroupInput"]: {
	id: number | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["DeleteAppGroupInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["Application"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	command?:boolean | `@${string}`,
	parameter?:boolean | `@${string}`,
	tooltip?:boolean | `@${string}`,
	furnacePicture?:boolean | `@${string}`,
	iconGifName?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	typeId?:boolean | `@${string}`,
	groupId?:boolean | `@${string}`,
	startRecipeProcessFlow?:boolean | `@${string}`,
	modifyRecipeProcessFlow?:boolean | `@${string}`,
	stopRecipeProcessFlow?:boolean | `@${string}`,
	startCorrectionRecipe?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ApplicationResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ValueTypes["Error"],
	application?:ValueTypes["Application"],
		__typename?: boolean | `@${string}`
}>;
	["CreateApplicationInput"]: {
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	command?: string | undefined | null | Variable<any, string>,
	parameter?: string | undefined | null | Variable<any, string>,
	tooltip?: string | undefined | null | Variable<any, string>,
	furnacePicture?: string | undefined | null | Variable<any, string>,
	iconGifName?: string | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	typeId?: number | undefined | null | Variable<any, string>,
	groupId: number | Variable<any, string>,
	startRecipeProcessFlow: boolean | Variable<any, string>,
	modifyRecipeProcessFlow: boolean | Variable<any, string>,
	stopRecipeProcessFlow: boolean | Variable<any, string>,
	startCorrectionRecipe: boolean | Variable<any, string>
};
	["EditApplicationInput"]: {
	id: number | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	command?: string | undefined | null | Variable<any, string>,
	parameter?: string | undefined | null | Variable<any, string>,
	tooltip?: string | undefined | null | Variable<any, string>,
	furnacePicture?: string | undefined | null | Variable<any, string>,
	iconGifName?: string | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	typeId?: number | undefined | null | Variable<any, string>,
	groupId: number | Variable<any, string>,
	startRecipeProcessFlow: boolean | Variable<any, string>,
	modifyRecipeProcessFlow: boolean | Variable<any, string>,
	stopRecipeProcessFlow: boolean | Variable<any, string>,
	startCorrectionRecipe: boolean | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["DeleteApplicationInput"]: {
	id: number | Variable<any, string>,
	modifiedOn: number | Variable<any, string>
};
	["ApplicationMetadata"]: AliasType<{
	applicationGroupList?:ValueTypes["SHARED_OptionItem"],
	applicationTypeList?:ValueTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProcessFlow"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comments?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	operationType?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProcessFlowStep"]: AliasType<{
	ptime?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	workplace?:ValueTypes["SHARED_Workplace"],
	recipe?:ValueTypes["RecipeMenuItem"],
	operation?:ValueTypes["OperationMenuItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProcessFlowPartComparisonItem"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	description2?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserAccess"]: AliasType<{
	user?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	group?:boolean | `@${string}`,
	pgmId?:boolean | `@${string}`,
	application?:boolean | `@${string}`,
	run?:boolean | `@${string}`,
	newEntry?:boolean | `@${string}`,
	save?:boolean | `@${string}`,
	delete?:boolean | `@${string}`,
	special1?:boolean | `@${string}`,
	print?:boolean | `@${string}`,
	startRecipeProcessFlow?:boolean | `@${string}`,
	modifyRecipeProcessFlow?:boolean | `@${string}`,
	stopRecipeProcessFlow?:boolean | `@${string}`,
	startCorrectionRecipe?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["updateUserAccessInput"]: {
	userId: number | Variable<any, string>,
	pgmId: number | Variable<any, string>,
	run?: boolean | undefined | null | Variable<any, string>,
	newEntry?: boolean | undefined | null | Variable<any, string>,
	save?: boolean | undefined | null | Variable<any, string>,
	delete?: boolean | undefined | null | Variable<any, string>,
	special1?: boolean | undefined | null | Variable<any, string>,
	print?: boolean | undefined | null | Variable<any, string>,
	startRecipeProcessFlow?: boolean | undefined | null | Variable<any, string>,
	modifyRecipeProcessFlow?: boolean | undefined | null | Variable<any, string>,
	stopRecipeProcessFlow?: boolean | undefined | null | Variable<any, string>,
	startCorrectionRecipe?: boolean | undefined | null | Variable<any, string>
};
	["UserGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["createUserGroupInput"]: {
	description?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>
};
	["updateUserGroupInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["createUserInput"]: {
	firstName?: string | undefined | null | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	shortName: string | Variable<any, string>,
	function?: string | undefined | null | Variable<any, string>,
	password?: string | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	employeeNumber?: string | undefined | null | Variable<any, string>,
	plant?: string | undefined | null | Variable<any, string>,
	costCenter?: string | undefined | null | Variable<any, string>,
	maintenancePopups?: boolean | undefined | null | Variable<any, string>,
	playAlarmSound?: boolean | undefined | null | Variable<any, string>,
	accessLevel?: number | undefined | null | Variable<any, string>,
	language?: number | undefined | null | Variable<any, string>,
	userGroup: number | Variable<any, string>,
	picture?: string | undefined | null | Variable<any, string>
};
	["updateUserInput"]: {
	firstName?: string | undefined | null | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	function?: string | undefined | null | Variable<any, string>,
	password?: string | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	employeeNumber?: string | undefined | null | Variable<any, string>,
	plant?: string | undefined | null | Variable<any, string>,
	costCenter?: string | undefined | null | Variable<any, string>,
	maintenancePopups?: boolean | undefined | null | Variable<any, string>,
	playAlarmSound?: boolean | undefined | null | Variable<any, string>,
	accessLevel?: number | undefined | null | Variable<any, string>,
	language?: number | undefined | null | Variable<any, string>,
	userGroup?: number | undefined | null | Variable<any, string>,
	picture?: string | undefined | null | Variable<any, string>
};
	["TO_REMOVE_RecipeMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TO_REMOVE_ShopLayoutMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ResolverInputTypes = {
    ["Error"]: AliasType<{
	code?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	detail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChartPoint"]: AliasType<{
	timestamp?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Chart"]: AliasType<{
	chartName?:boolean | `@${string}`,
	varId?:boolean | `@${string}`,
	chartColor?:boolean | `@${string}`,
	chartUnit?:boolean | `@${string}`,
	data?:ResolverInputTypes["SHARED_ChartPoint"],
		__typename?: boolean | `@${string}`
}>;
	["UDM_ChartData"]: AliasType<{
	varId?:boolean | `@${string}`,
	data?:ResolverInputTypes["SHARED_ChartPoint"],
		__typename?: boolean | `@${string}`
}>;
	["ChartMetaData"]: AliasType<{
	varId?:boolean | `@${string}`,
	chartName?:boolean | `@${string}`,
	chartColor?:boolean | `@${string}`,
	chartUnit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LoadRecipeHeader"]: AliasType<{
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_PartData"]: AliasType<{
	pos?:boolean | `@${string}`,
	partName?:boolean | `@${string}`,
	partDescription?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	weightInKg?:boolean | `@${string}`,
	totalWeightInKg?:boolean | `@${string}`,
	orderName?:boolean | `@${string}`,
	orderRemark?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	customer?:boolean | `@${string}`,
	intern?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_FurnaceSystemStatus"]: AliasType<{
	entranceReady?:boolean | `@${string}`,
	exitReady?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Load"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
	earliestTimeStart?:boolean | `@${string}`,
	packedTime?:boolean | `@${string}`,
	packedUser?:boolean | `@${string}`,
	startedUser?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	recipe?:ResolverInputTypes["SHARED_LoadRecipeHeader"],
	parts?:ResolverInputTypes["SHARED_PartData"],
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Workplace"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	inventoryNumber?:boolean | `@${string}`,
	type?:ResolverInputTypes["SHARED_WorkplaceType"],
	active?:ResolverInputTypes["SHARED_ActiveState"],
	useMode?:ResolverInputTypes["SHARED_UseMode"],
	logicType?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
	chamberId?:boolean | `@${string}`,
	sharesController?:boolean | `@${string}`,
	belongsTo?:boolean | `@${string}`,
	ip?:boolean | `@${string}`,
	mGuardIp?:boolean | `@${string}`,
	device?:boolean | `@${string}`,
	telegramSplit?:boolean | `@${string}`,
	deactivateOperations?:boolean | `@${string}`,
	aliveBitErrorNum?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_WorkplaceType"]: AliasType<{
	number?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	prio?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ActiveState"]: AliasType<{
	string?:boolean | `@${string}`,
	int?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_UseMode"]: AliasType<{
	string?:boolean | `@${string}`,
	int?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_OptionItem"]: AliasType<{
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_SpEntries"]: AliasType<{
	furnaceSwitchEntry?:boolean | `@${string}`,
	recipeDescription2?:boolean | `@${string}`,
	recipeDescription3?:boolean | `@${string}`,
	recipeDescription4?:boolean | `@${string}`,
	gasEntryValues?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_DigitalOutput"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Material"]: AliasType<{
	carbonContent?:boolean | `@${string}`,
	alloyFactor?:boolean | `@${string}`,
	legFactorN?:boolean | `@${string}`,
	carbideLimit?:boolean | `@${string}`,
	temperature?:boolean | `@${string}`,
	specialNitrides?:boolean | `@${string}`,
	analysis?:ResolverInputTypes["SHARED_ElementValueList"],
	co?:boolean | `@${string}`,
	h2?:boolean | `@${string}`,
	quenchingIntensity?:boolean | `@${string}`,
	representativeDiameter?:boolean | `@${string}`,
	requiredHardness?:boolean | `@${string}`,
	grainSize?:boolean | `@${string}`,
	endTemperature?:boolean | `@${string}`,
	targetSurfC?:boolean | `@${string}`,
	customerMaterialId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_PropertyInfo"]: AliasType<{
	property?:boolean | `@${string}`,
	target?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_PartInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	materialId?:boolean | `@${string}`,
	materialName?:boolean | `@${string}`,
	description1?:boolean | `@${string}`,
	description2?:boolean | `@${string}`,
	drawingNumber?:boolean | `@${string}`,
	revisionNumber?:boolean | `@${string}`,
	testPlanId?:boolean | `@${string}`,
	testPlanDescription?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	properties?:ResolverInputTypes["SHARED_PropertyInfo"],
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_WorkplaceRecipe"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	version_no?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ElementValueList"]: AliasType<{
	c?:boolean | `@${string}`,
	si?:boolean | `@${string}`,
	mn?:boolean | `@${string}`,
	cr?:boolean | `@${string}`,
	ni?:boolean | `@${string}`,
	v?:boolean | `@${string}`,
	al?:boolean | `@${string}`,
	cu?:boolean | `@${string}`,
	p?:boolean | `@${string}`,
	s?:boolean | `@${string}`,
	mo?:boolean | `@${string}`,
	b?:boolean | `@${string}`,
	ti?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_OLD_CustomerMaterial"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	normId?:boolean | `@${string}`,
	normName?:boolean | `@${string}`,
	normDescription?:boolean | `@${string}`,
	groupId?:boolean | `@${string}`,
	groupName?:boolean | `@${string}`,
	elementValues?:ResolverInputTypes["SHARED_ElementValueList"],
	averageAlloyFactor?:boolean | `@${string}`,
	averageAlloyFactorN?:boolean | `@${string}`,
	carbideLimit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_DropDownListItem"]: AliasType<{
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	isActive?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_User"]: AliasType<{
	id?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	shortName?:boolean | `@${string}`,
	function?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	employeeNumber?:boolean | `@${string}`,
	plant?:boolean | `@${string}`,
	costCenter?:boolean | `@${string}`,
	maintenancePopups?:boolean | `@${string}`,
	playAlarmSound?:boolean | `@${string}`,
	loginTimeInMin?:boolean | `@${string}`,
	accessLevel?:boolean | `@${string}`,
	languageId?:boolean | `@${string}`,
	language?:ResolverInputTypes["SHARED_DropDownListItem"],
	userGroupId?:boolean | `@${string}`,
	userGroup?:ResolverInputTypes["SHARED_DropDownListItem"],
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
	picture?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Client"]: AliasType<{
	id?:boolean | `@${string}`,
	clientType?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adress?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	resolution?:boolean | `@${string}`,
	valueLong1?:boolean | `@${string}`,
	valueLong2?:boolean | `@${string}`,
	valueLong3?:boolean | `@${string}`,
	valueLong4?:boolean | `@${string}`,
	valueLong5?:boolean | `@${string}`,
	valueFloat1?:boolean | `@${string}`,
	valueFloat2?:boolean | `@${string}`,
	valueFloat3?:boolean | `@${string}`,
	valueChar1?:boolean | `@${string}`,
	valueChar2?:boolean | `@${string}`,
	actUserName?:boolean | `@${string}`,
	actLoginTime?:boolean | `@${string}`,
	lastUserName?:boolean | `@${string}`,
	lastLoginTime?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
	isActive?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Operation"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	operationType?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	relevantForStat?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ValueViewComponent"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	digitsBeforeComma?:boolean | `@${string}`,
	digitsAfterComma?:boolean | `@${string}`,
	textBefore?:boolean | `@${string}`,
	textAfter?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	numberFormat?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_BackgroundView"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_TextView"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_RecipeVersion"]: AliasType<{
	id?:boolean | `@${string}`,
	startingTime?:boolean | `@${string}`,
	versionText?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_RecipeShortHeader"]: AliasType<{
	recipeName?:boolean | `@${string}`,
	actualStep?:boolean | `@${string}`,
	loadIdentifier?:boolean | `@${string}`,
	loadName?:boolean | `@${string}`,
	versions?:ResolverInputTypes["SHARED_RecipeVersion"],
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LoadColumn"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LoadButton"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	chamberId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Bitmap"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Gif"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_MovingPic"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	filePath?:boolean | `@${string}`,
	startX?:boolean | `@${string}`,
	startY?:boolean | `@${string}`,
	endX?:boolean | `@${string}`,
	endY?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	componentZero?:boolean | `@${string}`,
	componentSpan?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Label"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_LabelPlus"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	text0?:boolean | `@${string}`,
	text1?:boolean | `@${string}`,
	text2?:boolean | `@${string}`,
	text3?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Line"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_EditBox"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	focusOrderNumber?:boolean | `@${string}`,
	variableType?:boolean | `@${string}`,
	isEditable?:boolean | `@${string}`,
	digitsBeforeComma?:boolean | `@${string}`,
	digitsAfterComma?:boolean | `@${string}`,
	minValue?:boolean | `@${string}`,
	maxValue?:boolean | `@${string}`,
	numberFormat?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
	editValue?:boolean | `@${string}`,
	componentTypeId?:boolean | `@${string}`,
	userInputId?:boolean | `@${string}`,
	outputId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ProgressBar"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	fullColor?:boolean | `@${string}`,
	emptyColor?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	minValue?:boolean | `@${string}`,
	maxValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Place"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	layoutOrder?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	colorPalette?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	systemColorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_Rectangle"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xPos0?:boolean | `@${string}`,
	yPos0?:boolean | `@${string}`,
	xPos1?:boolean | `@${string}`,
	yPos1?:boolean | `@${string}`,
	xPos2?:boolean | `@${string}`,
	yPos2?:boolean | `@${string}`,
	xPos3?:boolean | `@${string}`,
	yPos3?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorIndex0?:boolean | `@${string}`,
	colorIndex1?:boolean | `@${string}`,
	colorIndex2?:boolean | `@${string}`,
	colorIndex3?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChargeCarItem"]: AliasType<{
	wpId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	infoText?:boolean | `@${string}`,
	placeId?:boolean | `@${string}`,
	placeName?:boolean | `@${string}`,
	placeColor?:boolean | `@${string}`,
	placeColorBG?:boolean | `@${string}`,
	posX?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChargeCarAutFurnaceDriving"]: AliasType<{
	driverWpId?:boolean | `@${string}`,
	overWpId?:boolean | `@${string}`,
	furnaceWidth?:boolean | `@${string}`,
	furnaceHeight?:boolean | `@${string}`,
	furnaceOverviewNo?:boolean | `@${string}`,
	furnacePlace?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SHARED_ChargeCar"]: AliasType<{
	id?:boolean | `@${string}`,
	carType?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	layoutOrder?:boolean | `@${string}`,
	imageName?:boolean | `@${string}`,
	car?:ResolverInputTypes["SHARED_ChargeCarItem"],
	carList?:ResolverInputTypes["SHARED_ChargeCarItem"],
	railMaxPosPixels?:boolean | `@${string}`,
	railMaxPosMm?:boolean | `@${string}`,
	autFurnaceDriving?:ResolverInputTypes["SHARED_ChargeCarAutFurnaceDriving"],
	mode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutChargeStatus"]: AliasType<{
	id?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	colorBorder?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutView"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	systemStatus?:ResolverInputTypes["SHARED_FurnaceSystemStatus"],
	chargeStatusList?:ResolverInputTypes["ShopLayoutChargeStatus"],
	recipeHeader?:ResolverInputTypes["SHARED_RecipeShortHeader"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	text?:ResolverInputTypes["SHARED_TextView"],
	value?:ResolverInputTypes["SHARED_ValueViewComponent"],
	background?:ResolverInputTypes["SHARED_BackgroundView"],
	loadButton?:ResolverInputTypes["SHARED_LoadButton"],
	place?:ResolverInputTypes["SHARED_Place"],
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	xSize?:boolean | `@${string}`,
	ySize?:boolean | `@${string}`,
	bitmap?:ResolverInputTypes["SHARED_Bitmap"],
	gif?:ResolverInputTypes["SHARED_Gif"],
	label?:ResolverInputTypes["SHARED_Label"],
	labelPlus?:ResolverInputTypes["SHARED_LabelPlus"],
	line?:ResolverInputTypes["SHARED_Line"],
	editBox?:ResolverInputTypes["SHARED_EditBox"],
	progressBar?:ResolverInputTypes["SHARED_ProgressBar"],
	rectangle?:ResolverInputTypes["SHARED_Rectangle"],
	chargeCar?:ResolverInputTypes["SHARED_ChargeCar"],
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutViewData"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	systemStatus?:ResolverInputTypes["SHARED_FurnaceSystemStatus"],
	recipeHeader?:ResolverInputTypes["SHARED_RecipeShortHeader"],
	loadButton?:ResolverInputTypes["SHARED_LoadButton"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:ResolverInputTypes["SHARED_ValueViewComponent"],
	place?:ResolverInputTypes["SHARED_Place"],
	bitmap?:ResolverInputTypes["SHARED_Bitmap"],
	gif?:ResolverInputTypes["SHARED_Gif"],
	label?:ResolverInputTypes["SHARED_Label"],
	labelPlus?:ResolverInputTypes["SHARED_LabelPlus"],
	line?:ResolverInputTypes["SHARED_Line"],
	editBox?:ResolverInputTypes["SHARED_EditBox"],
	progressBar?:ResolverInputTypes["SHARED_ProgressBar"],
	rectangle?:ResolverInputTypes["SHARED_Rectangle"],
	chargeCar?:ResolverInputTypes["SHARED_ChargeCar"],
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutAlarm"]: AliasType<{
	alarmId?:boolean | `@${string}`,
	workplaceName?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	acknowledgeTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
	priority?:boolean | `@${string}`,
	alarmMessage?:boolean | `@${string}`,
	gate?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
	byte?:boolean | `@${string}`,
	bit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutAlarmSearch"]: AliasType<{
	workplaceName?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	event?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutLoadListGeneral"]: AliasType<{
	columnNames?:ResolverInputTypes["SHARED_LoadColumn"],
	workplaceGeneral?:ResolverInputTypes["SHARED_Workplace"],
	parts?:ResolverInputTypes["SHARED_PartInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ShopLayoutRecipeList"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
shopLayoutView?: [{	shopLayoutId: number},ResolverInputTypes["ShopLayoutView"]],
updateShopLayoutValues?: [{	shopLayoutId: number},ResolverInputTypes["ShopLayoutViewData"]],
shopLayoutAlarms?: [{	shopLayoutId: number},ResolverInputTypes["ShopLayoutAlarm"]],
shopLayoutAlarmsSearch?: [{	shopLayoutId: number,	startTime: number,	endTime: number},ResolverInputTypes["ShopLayoutAlarmSearch"]],
shopLayoutLoadList?: [{	shopLayoutId: number,	startTime: number,	endTime: number},ResolverInputTypes["SHARED_Load"]],
shopLayoutLoadListGeneral?: [{	shopLayoutId: number},ResolverInputTypes["ShopLayoutLoadListGeneral"]],
shopLayoutRecipeList?: [{	shopLayoutId: number},ResolverInputTypes["ShopLayoutRecipeList"]],
furnaceView?: [{	workplaceId: number},ResolverInputTypes["FurnaceView"]],
furnaceViewAlarms?: [{	workplaceId: number},ResolverInputTypes["FurnaceViewAlarm"]],
furnaceViewAlarmsSearch?: [{	workplaceId: number,	startTime: number,	endTime: number},ResolverInputTypes["FurnaceViewAlarmSearch"]],
updateFurnaceViews?: [{	workplaceId: number,	viewId?: number | undefined | null},ResolverInputTypes["updateFurnaceViewsResult"]],
furnaceChartUdmData?: [{	workplaceId: number,	startTime: number,	endTime: number,	varId?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["UDM_ChartData"]],
furnaceChartMetaData?: [{	workplaceId: number},ResolverInputTypes["ChartMetaData"]],
furnaceChartRecorder?: [{	workplaceId: number,	startTime: number,	endTime: number},ResolverInputTypes["SHARED_Chart"]],
furnaceLoadList?: [{	workplaceId: number,	startTime: number,	endTime: number},ResolverInputTypes["SHARED_Load"]],
actualRecipeOfWorkplace?: [{	workplaceId: number},ResolverInputTypes["ActRecipeVersion"]],
furnaceLoadListGeneral?: [{	workplaceId: number},ResolverInputTypes["FurnaceLoadListGeneral"]],
furnaceRecipeList?: [{	workplaceId: number},ResolverInputTypes["SHARED_WorkplaceRecipe"]],
	listOfAvailableFurnaces?:boolean | `@${string}`,
furnaceChartDataFirstTimestamp?: [{	workplaceId: number,	yearFrom?: number | undefined | null},boolean | `@${string}`],
alarmStatistics?: [{	workplaceId: number,	alarmNoList: Array<number | undefined | null>,	startTime: number,	endTime: number},ResolverInputTypes["AlarmStatisticInfo"]],
alarmList?: [{	workplaceId: number,	importantErrorsOnly?: boolean | undefined | null},ResolverInputTypes["AlarmInfo"]],
	alarmWorkplaces?:ResolverInputTypes["WorkplaceInfo"],
	manageUsage?:ResolverInputTypes["Usage"],
furnaceBookAlarms?: [{	workplaceId: string,	startTime: number,	endTime: number},ResolverInputTypes["FurnaceBookAlarm"]],
	furnaceBookFilters?:ResolverInputTypes["FurnaceBookFilters"],
getImages?: [{	loadName?: string | undefined | null},ResolverInputTypes["Image"]],
profileChart?: [{	loadName: string},ResolverInputTypes["ProfileChart"]],
furnaceBook?: [{	workplaceIds: Array<string | undefined | null>,	startTime: number,	endTime: number,	filter: ResolverInputTypes["Filter"]},ResolverInputTypes["ProductionLog"]],
productionLogsDummyCharts?: [{	numOfCharts: number,	numOfPoints: number,	maxValue: number},ResolverInputTypes["SHARED_Chart"]],
productionLogsChartRecorder?: [{	workplaceId: string,	startTime: number,	endTime: number},ResolverInputTypes["SHARED_Chart"]],
chargeConsumption?: [{	loadName?: string | undefined | null,	startTime: number,	endTime: number},ResolverInputTypes["ChargeConsumption"]],
usage?: [{	workplaceIds: Array<string | undefined | null>,	startTime: number,	endTime: number,	standBy: boolean},ResolverInputTypes["UsageEntry"]],
utilization?: [{	mode: ResolverInputTypes["UtilizationMode"],	workplaceIds: Array<string | undefined | null>,	startTime: number,	endTime: number,	operationId?: number | undefined | null,	minLoadRuntime?: number | undefined | null,	loadChangeover?: number | undefined | null,	isChangeCharge?: boolean | undefined | null,	calculationMode?: number | undefined | null},ResolverInputTypes["Utilization"]],
	cptEntries?:ResolverInputTypes["CptEntry"],
recipeOfLoad?: [{	loadName?: string | undefined | null,	workplaceId: string},ResolverInputTypes["RecipeROL"]],
partInfo?: [{	loadName?: string | undefined | null},ResolverInputTypes["Part"]],
partsStatistics?: [{	workplaceIds: Array<string | undefined | null>,	order: boolean,	startTime: number,	endTime: number,	filter: ResolverInputTypes["Filter"]},ResolverInputTypes["PartsStatistics"]],
	productionLogsOperationList?:ResolverInputTypes["SHARED_Operation"],
	failureReasonAdministration?:ResolverInputTypes["FailureReason"],
	fixtureAdministration?:ResolverInputTypes["Fixture"],
	fixtureTypes?:ResolverInputTypes["FixtureType"],
	customerSpecificData?:ResolverInputTypes["CustomerSpecificData"],
	engineeringUnitList?:ResolverInputTypes["EngineeringUnit"],
	userUnitsList?:ResolverInputTypes["UserUnits"],
	customerMaterialList?:ResolverInputTypes["SHARED_OLD_CustomerMaterial"],
	materialGroup?:ResolverInputTypes["OLD_MaterialGroup"],
	normMaterialList?:ResolverInputTypes["OLD_NormMaterial"],
	parts?:ResolverInputTypes["SHARED_PartInfo"],
partManagement?: [{	partId: string},ResolverInputTypes["PartManagementInfo"]],
partsWorkplaceRecipeList?: [{	workplaceId: string},ResolverInputTypes["SHARED_WorkplaceRecipe"]],
	testPlanList?:ResolverInputTypes["TestPlan"],
	toolingList?:ResolverInputTypes["Tooling"],
	partsCustomerMaterialList?:ResolverInputTypes["SHARED_OLD_CustomerMaterial"],
recipeList?: [{	recipePrefix: string},ResolverInputTypes["RecipeListItem"]],
recipeItem?: [{	recipePrefix: string,	recipe: string,	workplaceId: number},ResolverInputTypes["RecipeItemVersion"]],
getRecipeMetadata?: [{	recipePrefix: string,	workplaceId: number},ResolverInputTypes["RecipeMetadata"]],
	peAttributeType?:ResolverInputTypes["PeAttributeType"],
	peAttributeTypePhenNoList?:ResolverInputTypes["SHARED_OptionItem"],
	peProcessType?:ResolverInputTypes["PeProcessType"],
	peProcessTypePhenNoList?:ResolverInputTypes["SHARED_OptionItem"],
	physicalUnit?:ResolverInputTypes["PhysicalUnit"],
	physicalUnitMetadata?:ResolverInputTypes["SHARED_OptionItem"],
	workplaceTypeList?:ResolverInputTypes["SHARED_WorkplaceType"],
	backgroundProcessList?:ResolverInputTypes["BackgroundProcess"],
	getBackgroundProcessStateList?:boolean | `@${string}`,
	workplaceList?:ResolverInputTypes["SHARED_Workplace"],
	workplaceDataGeneralList?:ResolverInputTypes["SHARED_Workplace"],
	workplaceUseModeList?:ResolverInputTypes["SHARED_UseMode"],
	workplaceDataWpTypeList?:ResolverInputTypes["SHARED_WorkplaceType"],
	operationAdministrationOperationList?:ResolverInputTypes["Operation"],
	operationTypeList?:ResolverInputTypes["OperationType"],
operationPartList?: [{	operationId?: string | undefined | null},ResolverInputTypes["OperationPart"]],
operationProcessList?: [{	operationId?: number | undefined | null},ResolverInputTypes["OperationProcess"]],
	operationMetadata?:ResolverInputTypes["SHARED_OptionItem"],
	alarmAdministration?:ResolverInputTypes["Alarm"],
	alarmAdministrationMetadata?:ResolverInputTypes["AlarmAdministrationMetadata"],
	boxAdministration?:ResolverInputTypes["Box"],
	boxMetadata?:ResolverInputTypes["ColorBox"],
	colorAdministration?:ResolverInputTypes["Color"],
	languageList?:ResolverInputTypes["LanguageInfo"],
	externalizeTranslation?:ResolverInputTypes["ExternalizeTranslationConfigValue"],
	dictionaryAdministration?:ResolverInputTypes["DictionaryItem"],
	smsAdministration?:ResolverInputTypes["SmsSettings"],
	systemTaskAdministration?:ResolverInputTypes["Task"],
	clientAccessUserList?:ResolverInputTypes["SHARED_User"],
clientAccessClientList?: [{	userId?: string | undefined | null},ResolverInputTypes["SHARED_Client"]],
	terminalTypeList?:ResolverInputTypes["TerminalType"],
	terminalTypeMetadata?:ResolverInputTypes["SHARED_OptionItem"],
	terminalClientList?:ResolverInputTypes["TerminalClient"],
	terminalClientMetadata?:ResolverInputTypes["TerminalClientMetadata"],
	applicationGroup?:ResolverInputTypes["ApplicationGroup"],
	applicationList?:ResolverInputTypes["Application"],
	applicationMetadata?:ResolverInputTypes["ApplicationMetadata"],
	processFlowList?:ResolverInputTypes["ProcessFlow"],
processFlowStepList?: [{	processFlowId: string},ResolverInputTypes["ProcessFlowStep"]],
operationWorkplaceRecipeList?: [{	operationId: string,	workplaceId: string},ResolverInputTypes["RecipeMenuItem"]],
processFlowPartComparison?: [{	processFlowId: string},ResolverInputTypes["ProcessFlowPartComparisonItem"]],
	processFlowOperationList?:ResolverInputTypes["SHARED_Operation"],
operationWorkplaceList?: [{	operationId: string},ResolverInputTypes["SHARED_Workplace"]],
	userAccessList?:ResolverInputTypes["UserAccess"],
	userGroup?:ResolverInputTypes["UserGroup"],
	userList?:ResolverInputTypes["SHARED_User"],
	menuRecipes?:ResolverInputTypes["TO_REMOVE_RecipeMenuItem"],
	shopLayoutMenuItems?:ResolverInputTypes["TO_REMOVE_ShopLayoutMenuItem"],
	workplaceGeneralList?:ResolverInputTypes["SHARED_Workplace"],
	loadColumnList?:ResolverInputTypes["SHARED_LoadColumn"],
loadList?: [{	workplaceIds?: Array<number | undefined | null> | undefined | null,	startTime: number,	endTime: number},ResolverInputTypes["SHARED_Load"]],
chartRecorder?: [{	workplaceId: string,	startTime: number,	endTime: number},ResolverInputTypes["SHARED_Chart"]],
	partList?:ResolverInputTypes["SHARED_PartInfo"],
partWorkplaceRecipeList?: [{	workplaceId: string},ResolverInputTypes["SHARED_WorkplaceRecipe"]],
clientList?: [{	userId?: string | undefined | null},ResolverInputTypes["SHARED_Client"]],
	operationList?:ResolverInputTypes["SHARED_Operation"],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
shopLayoutAcknowledgeAlarms?: [{	shopLayoutId: number,	alarmIds?: Array<number | undefined | null> | undefined | null},ResolverInputTypes["ShopLayoutAlarm"]],
furnaceAcknowledgeAlarms?: [{	workplaceId: number,	alarmIds?: Array<number | undefined | null> | undefined | null},ResolverInputTypes["FurnaceViewAlarm"]],
setFurnaceEditBoxValues?: [{	workplaceId: number,	data: Array<ResolverInputTypes["EditBoxValueInput"] | undefined | null>},ResolverInputTypes["SetFurnaceEditBoxValuesResult"]],
setFurnaceButtonValue?: [{	workplaceId: number,	data: ResolverInputTypes["ButtonInput"]},ResolverInputTypes["ButtonValueResult"]],
createUsage?: [{	data: ResolverInputTypes["createUsageInput"]},ResolverInputTypes["UsageResult"]],
updateUsage?: [{	id: number,	data: ResolverInputTypes["updateUsageInput"]},ResolverInputTypes["UsageResult"]],
deleteUsage?: [{	id: number,	modifiedOn: number},ResolverInputTypes["UsageResult"]],
cloneUsage?: [{	id: number,	data: ResolverInputTypes["cloneUsageInput"]},ResolverInputTypes["UsageResult"]],
deleteImage?: [{	input: ResolverInputTypes["DeleteImageInput"]},boolean | `@${string}`],
createCustomerSpecificData?: [{	data: ResolverInputTypes["CreateCustomerSpecificDataInput"]},ResolverInputTypes["CustomerSpecificDataResult"]],
editCustomerSpecificData?: [{	data: ResolverInputTypes["EditCustomerSpecificDataInput"]},ResolverInputTypes["CustomerSpecificDataResult"]],
deleteCustomerSpecificData?: [{	data: ResolverInputTypes["DeleteCustomerSpecificDataInput"]},ResolverInputTypes["CustomerSpecificDataResult"]],
deleteRecipeVersions?: [{	data: ResolverInputTypes["RecipeVersionsHeaderInput"]},boolean | `@${string}`],
cloneRecipeAndSaveAs?: [{	data: ResolverInputTypes["CloneRecipeAndSaveAsInput"],	workplaceId: number},ResolverInputTypes["CloneRecipeAndSaveAsResult"]],
saveNewRecipe?: [{	data: ResolverInputTypes["SaveNewRecipeInput"],	workplaceId: number},ResolverInputTypes["SaveRecipeResult"]],
saveEditedRecipeVersion?: [{	data: ResolverInputTypes["saveEditedRecipeVersionInput"],	workplaceId: number},ResolverInputTypes["SaveRecipeResult"]],
createPeAttributeType?: [{	data: ResolverInputTypes["CreatePeAttributeTypeInput"]},ResolverInputTypes["PeAttributeTypeResult"]],
editPeAttributeType?: [{	data: ResolverInputTypes["EditPeAttributeTypeInput"]},ResolverInputTypes["PeAttributeTypeResult"]],
deletePeAttributeType?: [{	data: ResolverInputTypes["DeletePeAttributeTypeInput"]},ResolverInputTypes["PeAttributeTypeResult"]],
createPeProcessType?: [{	data: ResolverInputTypes["CreatePeProcessTypeInput"]},ResolverInputTypes["PeProcessTypeResult"]],
editPeProcessType?: [{	data: ResolverInputTypes["EditPeProcessTypeInput"]},ResolverInputTypes["PeProcessTypeResult"]],
deletePeProcessType?: [{	data: ResolverInputTypes["DeletePeProcessTypeInput"]},ResolverInputTypes["PeProcessTypeResult"]],
createPhysicalUnit?: [{	data: ResolverInputTypes["CreatePhysicalUnitInput"]},ResolverInputTypes["PhysicalUnitResult"]],
editPhysicalUnit?: [{	data: ResolverInputTypes["EditPhysicalUnitInput"]},ResolverInputTypes["PhysicalUnitResult"]],
deletePhysicalUnit?: [{	data: ResolverInputTypes["DeletePhysicalUnitInput"]},ResolverInputTypes["PhysicalUnitResult"]],
createWorkplaceType?: [{	data: ResolverInputTypes["createWorkplaceTypeInput"]},ResolverInputTypes["WorkplaceTypeResult"]],
updateWorkplaceType?: [{	number: number,	data: ResolverInputTypes["updateWorkplaceTypeInput"]},ResolverInputTypes["WorkplaceTypeResult"]],
deleteWorkplaceType?: [{	number: number,	modifiedOn: number},ResolverInputTypes["WorkplaceTypeResult"]],
cloneWorkplaceType?: [{	number: number,	data: ResolverInputTypes["cloneWorkplaceTypeInput"]},ResolverInputTypes["WorkplaceTypeResult"]],
deleteBackgroundProcess?: [{	data: ResolverInputTypes["DeleteProcessInput"]},ResolverInputTypes["ProcessResult"]],
cloneAndSaveAsBackgroundProcess?: [{	data: ResolverInputTypes["CloneProcessInput"]},ResolverInputTypes["ProcessResult"]],
editBackgroundProcess?: [{	data: ResolverInputTypes["EditProcessInput"]},ResolverInputTypes["ProcessResult"]],
createNewBackgroundProcess?: [{	data: ResolverInputTypes["CreateNewProcessInput"]},ResolverInputTypes["ProcessResult"]],
createWorkplace?: [{	data: ResolverInputTypes["createWorkplaceInput"]},ResolverInputTypes["WorkplaceResult"]],
updateWorkplace?: [{	id: number,	data: ResolverInputTypes["updateWorkplaceInput"]},ResolverInputTypes["WorkplaceResult"]],
deleteWorkplace?: [{	id: number,	modifiedOn: number},ResolverInputTypes["WorkplaceResult"]],
cloneWorkplace?: [{	id: number,	data: ResolverInputTypes["cloneWorkplaceInput"]},ResolverInputTypes["WorkplaceResult"]],
createOperation?: [{	data: ResolverInputTypes["CreateOperation"]},ResolverInputTypes["OperationResult"]],
editOperation?: [{	data: ResolverInputTypes["EditOperation"]},ResolverInputTypes["OperationResult"]],
deleteOperation?: [{	data: ResolverInputTypes["DeleteOperation"]},ResolverInputTypes["OperationResult"]],
cloneAndSaveAsAlarm?: [{	data: ResolverInputTypes["CloneAndSaveAsInput"]},ResolverInputTypes["AlarmResult"]],
createNewAlarm?: [{	data: ResolverInputTypes["CreateNewAlarmInput"]},ResolverInputTypes["AlarmResult"]],
editAlarm?: [{	data: ResolverInputTypes["EditAlarmInput"]},ResolverInputTypes["AlarmResult"]],
deleteAlarm?: [{	data: ResolverInputTypes["DeleteAlarmInput"]},ResolverInputTypes["AlarmResult"]],
deleteSelectedAlarmsForFurnace?: [{	data: ResolverInputTypes["DeleteSelectedAlarmsForFurnaceInput"]},ResolverInputTypes["Alarm"]],
createBox?: [{	data: ResolverInputTypes["CreateBoxInput"]},ResolverInputTypes["BoxResult"]],
editBox?: [{	data: ResolverInputTypes["EditBoxInput"]},ResolverInputTypes["BoxResult"]],
deleteBox?: [{	data: ResolverInputTypes["DeleteBoxInput"]},ResolverInputTypes["BoxResult"]],
createColor?: [{	data: ResolverInputTypes["CreateColorInput"]},ResolverInputTypes["ColorResult"]],
editColor?: [{	data: ResolverInputTypes["EditColorInput"]},ResolverInputTypes["ColorResult"]],
deleteColor?: [{	data: ResolverInputTypes["deleteColorInput"]},ResolverInputTypes["ColorResult"]],
createTask?: [{	data: ResolverInputTypes["createTaskInput"]},ResolverInputTypes["TaskResult"]],
updateTask?: [{	id: number,	data: ResolverInputTypes["updateTaskInput"]},ResolverInputTypes["TaskResult"]],
deleteTask?: [{	id: number,	modifiedOn: number},ResolverInputTypes["TaskResult"]],
cloneTask?: [{	id: number,	data: ResolverInputTypes["cloneTaskInput"]},ResolverInputTypes["TaskResult"]],
updateClientAccessClientList?: [{	userId: number,	data: Array<ResolverInputTypes["updateClientAccessClientListInput"] | undefined | null>},ResolverInputTypes["updateClientAccessResult"]],
createTerminalType?: [{	data: ResolverInputTypes["CreateTerminalTypeInput"]},ResolverInputTypes["TerminalTypeResult"]],
editTerminalType?: [{	data: ResolverInputTypes["EditTerminalTypeInput"]},ResolverInputTypes["TerminalTypeResult"]],
deleteTerminalType?: [{	data: ResolverInputTypes["DeleteTerminalTypeInput"]},ResolverInputTypes["TerminalTypeResult"]],
createTerminalClient?: [{	data: ResolverInputTypes["CreateTerminalClientInput"]},ResolverInputTypes["TerminalClientResult"]],
editTerminalClient?: [{	data: ResolverInputTypes["EditTerminalClientInput"]},ResolverInputTypes["TerminalClientResult"]],
deleteTerminalClient?: [{	data: ResolverInputTypes["DeleteTerminalClientInput"]},ResolverInputTypes["TerminalClientResult"]],
createApplicationGroup?: [{	data: ResolverInputTypes["CreateAppGroupInput"]},ResolverInputTypes["ApplicationGroupResult"]],
editApplicationGroup?: [{	data: ResolverInputTypes["EditAppGroupInput"]},ResolverInputTypes["ApplicationGroupResult"]],
deleteApplicationGroup?: [{	data: ResolverInputTypes["DeleteAppGroupInput"]},ResolverInputTypes["ApplicationGroupResult"]],
createApplication?: [{	data: ResolverInputTypes["CreateApplicationInput"]},ResolverInputTypes["ApplicationResult"]],
editApplication?: [{	data: ResolverInputTypes["EditApplicationInput"]},ResolverInputTypes["ApplicationResult"]],
deleteApplication?: [{	data: ResolverInputTypes["DeleteApplicationInput"]},ResolverInputTypes["ApplicationResult"]],
updateUserAccess?: [{	data: ResolverInputTypes["updateUserAccessInput"]},ResolverInputTypes["UserAccess"]],
createUserGroup?: [{	data: ResolverInputTypes["createUserGroupInput"]},ResolverInputTypes["UserGroup"]],
updateUserGroup?: [{	id: number,	data: ResolverInputTypes["updateUserGroupInput"]},ResolverInputTypes["UserGroup"]],
deleteUserGroup?: [{	id: number},boolean | `@${string}`],
createUser?: [{	data: ResolverInputTypes["createUserInput"]},ResolverInputTypes["SHARED_User"]],
updateUser?: [{	id: number,	data: ResolverInputTypes["updateUserInput"]},ResolverInputTypes["SHARED_User"]],
deleteUser?: [{	id: number},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["HourInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	hourType?:boolean | `@${string}`,
	zoneId?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoadInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	fontSize?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	infoType?:boolean | `@${string}`,
	colorIndex?:boolean | `@${string}`,
	colorBG?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Button"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	xPos?:boolean | `@${string}`,
	yPos?:boolean | `@${string}`,
	userInputId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	userLevel?:boolean | `@${string}`,
	btnText0?:boolean | `@${string}`,
	btnText1?:boolean | `@${string}`,
	helpText0?:boolean | `@${string}`,
	helpText1?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["furnaceViewButton"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	userLevel?:boolean | `@${string}`,
	btnText0?:boolean | `@${string}`,
	btnText1?:boolean | `@${string}`,
	helpText0?:boolean | `@${string}`,
	helpText1?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewComponents"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	background?:ResolverInputTypes["SHARED_BackgroundView"],
	text?:ResolverInputTypes["SHARED_TextView"],
	value?:ResolverInputTypes["SHARED_ValueViewComponent"],
	hourInfo?:ResolverInputTypes["HourInfo"],
	loadInfo?:ResolverInputTypes["LoadInfo"],
	loadButton?:ResolverInputTypes["SHARED_LoadButton"],
	bitmap?:ResolverInputTypes["SHARED_Bitmap"],
	label?:ResolverInputTypes["SHARED_Label"],
	labelPlus?:ResolverInputTypes["SHARED_LabelPlus"],
	rectangle?:ResolverInputTypes["SHARED_Rectangle"],
	button?:ResolverInputTypes["Button"],
	gif?:ResolverInputTypes["SHARED_Gif"],
	movingPic?:ResolverInputTypes["SHARED_MovingPic"],
	place?:ResolverInputTypes["SHARED_Place"],
	progressBar?:ResolverInputTypes["SHARED_ProgressBar"],
	editBox?:ResolverInputTypes["SHARED_EditBox"],
	line?:ResolverInputTypes["SHARED_Line"],
	windowSizeId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceValueViewComponent"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	value?:ResolverInputTypes["SHARED_ValueViewComponent"],
	hourInfo?:ResolverInputTypes["HourInfo"],
	loadInfo?:ResolverInputTypes["LoadInfo"],
	place?:ResolverInputTypes["SHARED_Place"],
	bitmap?:ResolverInputTypes["SHARED_Bitmap"],
	gif?:ResolverInputTypes["SHARED_Gif"],
	movingPic?:ResolverInputTypes["SHARED_MovingPic"],
	editBox?:ResolverInputTypes["SHARED_EditBox"],
	labelPlus?:ResolverInputTypes["SHARED_LabelPlus"],
	label?:ResolverInputTypes["SHARED_Label"],
	progressBar?:ResolverInputTypes["SHARED_ProgressBar"],
	loadButton?:ResolverInputTypes["SHARED_LoadButton"],
	rectangle?:ResolverInputTypes["SHARED_Rectangle"],
	button?:ResolverInputTypes["Button"],
	line?:ResolverInputTypes["SHARED_Line"],
		__typename?: boolean | `@${string}`
}>;
	["updateFurnaceViewsResult"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	hold?:boolean | `@${string}`,
	remote?:boolean | `@${string}`,
	connected?:boolean | `@${string}`,
	updateTime?:boolean | `@${string}`,
	furnaceViews?:ResolverInputTypes["FurnaceValueViewComponent"],
	recipeHeader?:ResolverInputTypes["SHARED_RecipeShortHeader"],
		__typename?: boolean | `@${string}`
}>;
	["SystemColor"]: AliasType<{
	id?:boolean | `@${string}`,
	r?:boolean | `@${string}`,
	g?:boolean | `@${string}`,
	b?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceView"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	hold?:boolean | `@${string}`,
	remote?:boolean | `@${string}`,
	connected?:boolean | `@${string}`,
	updateTime?:boolean | `@${string}`,
	colors?:ResolverInputTypes["SystemColor"],
	furnaceViews?:ResolverInputTypes["FurnaceViewComponents"],
	recipeHeader?:ResolverInputTypes["SHARED_RecipeShortHeader"],
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewAlarm"]: AliasType<{
	alarmId?:boolean | `@${string}`,
	workplaceName?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	acknowledgeTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
	priority?:boolean | `@${string}`,
	alarmMessage?:boolean | `@${string}`,
	gate?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
	byte?:boolean | `@${string}`,
	bit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewAlarmSearch"]: AliasType<{
	workplaceName?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	event?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
	alarmNum?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActRecipeHeader"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	version?:boolean | `@${string}`,
	processOption?:ResolverInputTypes["SHARED_OptionItem"],
	processType?:boolean | `@${string}`,
	processDepth?:boolean | `@${string}`,
	processDepthAt?:boolean | `@${string}`,
	correction?:boolean | `@${string}`,
	diff?:boolean | `@${string}`,
	loadIdentifier?:boolean | `@${string}`,
	loadName?:boolean | `@${string}`,
	actualStep?:boolean | `@${string}`,
	startingTime?:boolean | `@${string}`,
	user?:ResolverInputTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ActRecipePositionHeader"]: AliasType<{
	positionNo?:boolean | `@${string}`,
	chamberOption?:ResolverInputTypes["SHARED_OptionItem"],
	chamberOperationOption?:ResolverInputTypes["SHARED_OptionItem"],
	description?:boolean | `@${string}`,
	afterTimeInMin?:boolean | `@${string}`,
	estimatedDurationInMin?:boolean | `@${string}`,
	actualDurationInMin?:boolean | `@${string}`,
	afterOption?:ResolverInputTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ActSetPoint"]: AliasType<{
	userComponentId?:boolean | `@${string}`,
	valueType?:boolean | `@${string}`,
	meaning?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	control?:ResolverInputTypes["SHARED_OptionItem"],
	value?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	toleranceMax?:boolean | `@${string}`,
	toleranceMin?:boolean | `@${string}`,
	alarmStartTimeInMin?:boolean | `@${string}`,
	alarmToleranceTimeInMin?:boolean | `@${string}`,
	actualValue?:boolean | `@${string}`,
	endControl?:ResolverInputTypes["SHARED_OptionItem"],
	endValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActRecipePosition"]: AliasType<{
	header?:ResolverInputTypes["ActRecipePositionHeader"],
	setpoints?:ResolverInputTypes["ActSetPoint"],
	digitalOutputList?:ResolverInputTypes["SHARED_DigitalOutput"],
	digitalOutputs?:boolean | `@${string}`,
	endAfter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActRecipeVersion"]: AliasType<{
	header?:ResolverInputTypes["ActRecipeHeader"],
	positions?:ResolverInputTypes["ActRecipePosition"],
	spEntries?:ResolverInputTypes["SHARED_SpEntries"],
	material?:ResolverInputTypes["SHARED_Material"],
	correctionProfile?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceViewError"]: AliasType<{
	code?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	detail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetFurnaceEditBoxValuesResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["FurnaceViewError"],
		__typename?: boolean | `@${string}`
}>;
	["EditBoxValueInput"]: {
	componentTypeId: number,
	userInputId: number,
	outputId: number,
	value: number
};
	["FurnaceLoadListGeneral"]: AliasType<{
	columnNames?:ResolverInputTypes["SHARED_LoadColumn"],
	parts?:ResolverInputTypes["SHARED_PartInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonValueResult"]: AliasType<{
	value?:boolean | `@${string}`,
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["FurnaceViewError"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonInput"]: {
	userInputId: number,
	value: number
};
	["AlarmEvent"]: AliasType<{
	event?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AlarmStatisticInfo"]: AliasType<{
	alarmNo?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	durationInSec?:boolean | `@${string}`,
	quantityPercentage?:boolean | `@${string}`,
	durationPercentage?:boolean | `@${string}`,
	events?:ResolverInputTypes["AlarmEvent"],
		__typename?: boolean | `@${string}`
}>;
	["AlarmInfo"]: AliasType<{
	alarmNo?:boolean | `@${string}`,
	alarmSeverity?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkplaceInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Usage"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	divisor?:boolean | `@${string}`,
	summaryCounter?:boolean | `@${string}`,
	overflow?:boolean | `@${string}`,
	validSince?:boolean | `@${string}`,
	pricePerUnit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsageResult"]: AliasType<{
	usage?:ResolverInputTypes["Usage"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["createUsageInput"]: {
	name: string,
	description?: string | undefined | null,
	unit?: string | undefined | null,
	divisor?: number | undefined | null,
	summaryCounter?: boolean | undefined | null,
	overflow?: number | undefined | null,
	validSince?: number | undefined | null,
	pricePerUnit?: number | undefined | null
};
	["updateUsageInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	unit?: string | undefined | null,
	divisor?: number | undefined | null,
	summaryCounter?: boolean | undefined | null,
	overflow?: number | undefined | null,
	validSince?: number | undefined | null,
	pricePerUnit?: number | undefined | null,
	modifiedOn: number
};
	["cloneUsageInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null
};
	["SearchComponentType"]:SearchComponentType;
	["SearchFilter"]: AliasType<{
	filterName?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	isChecked?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceBookFilters"]: AliasType<{
	workplaces?:ResolverInputTypes["SHARED_Workplace"],
	filters?:ResolverInputTypes["SearchFilter"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLoadTreatment"]: AliasType<{
	recipeName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	loadIdentifier?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ContainerPosition"]: AliasType<{
	partName?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	totalWeightInKg?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductionLog"]: AliasType<{
	workplace?:ResolverInputTypes["SHARED_Workplace"],
	startingTime?:boolean | `@${string}`,
	endingTime?:boolean | `@${string}`,
	durationInSec?:boolean | `@${string}`,
	loadId?:boolean | `@${string}`,
	loadName?:boolean | `@${string}`,
	alarms?:boolean | `@${string}`,
	header?:ResolverInputTypes["HeaderLoadTreatment"],
	positions?:ResolverInputTypes["ContainerPosition"],
	box?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FurnaceBookAlarm"]: AliasType<{
	messageNo?:boolean | `@${string}`,
	event?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProfileChartData"]: AliasType<{
	yUnit?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProfileChart"]: AliasType<{
	restaust?:boolean | `@${string}`,
	surfC?:boolean | `@${string}`,
	xUnit?:boolean | `@${string}`,
	charts?:ResolverInputTypes["ProfileChartData"],
		__typename?: boolean | `@${string}`
}>;
	["Consumption"]: AliasType<{
	media?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	costs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChargeConsumption"]: AliasType<{
	consumption?:ResolverInputTypes["Consumption"],
	totalCosts?:boolean | `@${string}`,
	currency?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsageEntry"]: AliasType<{
	workplace?:boolean | `@${string}`,
	chargesCounter?:boolean | `@${string}`,
	production?:ResolverInputTypes["ChargeConsumption"],
	averagePerCharge?:ResolverInputTypes["ChargeConsumption"],
	standBy?:ResolverInputTypes["ChargeConsumption"],
	total?:ResolverInputTypes["ChargeConsumption"],
		__typename?: boolean | `@${string}`
}>;
	["UtilizationMode"]: {
	type?: string | undefined | null,
	value?: string | undefined | null
};
	["UsageValue"]: AliasType<{
	unit?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UtilizationTotal"]: AliasType<{
	workplace?:boolean | `@${string}`,
	numberOfLoads?:boolean | `@${string}`,
	numberOfLoadsEvaluated?:boolean | `@${string}`,
	loadsRatio?:ResolverInputTypes["UsageValue"],
	totalTime?:ResolverInputTypes["UsageValue"],
	productionTime?:ResolverInputTypes["UsageValue"],
	timeRatio?:ResolverInputTypes["UsageValue"],
	averageLoadingTime?:ResolverInputTypes["UsageValue"],
	availability?:ResolverInputTypes["UsageValue"],
	downtime?:ResolverInputTypes["UsageValue"],
	downtimeReason?:ResolverInputTypes["UsageValue"],
	downtimeNoReason?:ResolverInputTypes["UsageValue"],
	utilization?:ResolverInputTypes["UsageValue"],
	capacityLoss?:ResolverInputTypes["UsageValue"],
	kpi?:boolean | `@${string}`,
	targetWeight?:ResolverInputTypes["UsageValue"],
	produced?:ResolverInputTypes["UsageValue"],
	weightRatio?:ResolverInputTypes["UsageValue"],
	flowRate?:ResolverInputTypes["UsageValue"],
		__typename?: boolean | `@${string}`
}>;
	["UtilizationPeriod"]: AliasType<{
	period?:boolean | `@${string}`,
	target?:ResolverInputTypes["UsageValue"],
	actual?:ResolverInputTypes["UsageValue"],
	utilizationRate?:ResolverInputTypes["UsageValue"],
		__typename?: boolean | `@${string}`
}>;
	["UtilizationUsage"]: AliasType<{
	workplace?:boolean | `@${string}`,
	loadsEvaluated?:boolean | `@${string}`,
	runtime?:ResolverInputTypes["UsageValue"],
	weight?:ResolverInputTypes["UsageValue"],
	usage?:ResolverInputTypes["UsageValue"],
	usageLoad?:ResolverInputTypes["UsageValue"],
	usageRuntime?:ResolverInputTypes["UsageValue"],
		__typename?: boolean | `@${string}`
}>;
	["Utilization"]: AliasType<{
	totalInfo?:ResolverInputTypes["UtilizationTotal"],
	periodInfo?:ResolverInputTypes["UtilizationPeriod"],
	usageInfo?:ResolverInputTypes["UtilizationUsage"],
		__typename?: boolean | `@${string}`
}>;
	["CptEntry"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeHeaderROL"]: AliasType<{
	chargeIdentifier?:boolean | `@${string}`,
	recipeName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeVersionROL"]: AliasType<{
	id?:boolean | `@${string}`,
	startingTime?:boolean | `@${string}`,
	versionText?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetpointROL"]: AliasType<{
	name?:boolean | `@${string}`,
	control?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	toleranceMax?:boolean | `@${string}`,
	toleranceMin?:boolean | `@${string}`,
	alarmStartTimeInMin?:boolean | `@${string}`,
	alarmToleranceTimeInMin?:boolean | `@${string}`,
	actualValue?:boolean | `@${string}`,
	endControl?:boolean | `@${string}`,
	endValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DigitalOutputROL"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AfterTimeROL"]: AliasType<{
	activeOption?:boolean | `@${string}`,
	options?:ResolverInputTypes["SHARED_OptionItem"],
	durationInSec?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StepHeaderROL"]: AliasType<{
	chamberOptions?:ResolverInputTypes["SHARED_OptionItem"],
	operationOptions?:ResolverInputTypes["SHARED_OptionItem"],
	description?:boolean | `@${string}`,
	estimatedDurationInSec?:boolean | `@${string}`,
	afterOptions?:ResolverInputTypes["AfterTimeROL"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeStepROL"]: AliasType<{
	id?:boolean | `@${string}`,
	header?:ResolverInputTypes["StepHeaderROL"],
	chamber?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	operation?:boolean | `@${string}`,
	setpoints?:ResolverInputTypes["SetpointROL"],
	digitalOutputs?:boolean | `@${string}`,
	digitalOutputList?:ResolverInputTypes["DigitalOutputROL"],
	endAfter?:boolean | `@${string}`,
	actualDurationInMin?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeROL"]: AliasType<{
	header?:ResolverInputTypes["RecipeHeaderROL"],
	versions?:ResolverInputTypes["RecipeVersionROL"],
	steps?:ResolverInputTypes["RecipeStepROL"],
		__typename?: boolean | `@${string}`
}>;
	["PartHeader"]: AliasType<{
	contentName?:boolean | `@${string}`,
	weightInKg?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	modifiedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PartData"]: AliasType<{
	pos?:boolean | `@${string}`,
	partName?:boolean | `@${string}`,
	partDescription?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	weightInKg?:boolean | `@${string}`,
	totalWeightInKg?:boolean | `@${string}`,
	orderName?:boolean | `@${string}`,
	orderRemark?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	customer?:boolean | `@${string}`,
	intern?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Part"]: AliasType<{
	header?:ResolverInputTypes["PartHeader"],
	parts?:ResolverInputTypes["PartData"],
		__typename?: boolean | `@${string}`
}>;
	["PartsStatistics"]: AliasType<{
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	weight?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	no?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	dataBase64?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Filter"]: {
	orderName?: string | undefined | null,
	partDescription?: string | undefined | null,
	recipeName?: string | undefined | null,
	seriesNumber?: string | undefined | null,
	material?: string | undefined | null,
	loadIdentifier?: string | undefined | null,
	sample?: string | undefined | null,
	chdFrom?: number | undefined | null,
	chdTo?: number | undefined | null,
	trayName?: string | undefined | null,
	partName?: string | undefined | null,
	loadName?: string | undefined | null,
	suchfeld?: string | undefined | null,
	aufgepackt?: string | undefined | null,
	abgepackt?: string | undefined | null,
	cellLoadName?: string | undefined | null,
	processFlowName?: string | undefined | null,
	customer?: string | undefined | null,
	sampleCheckbox?: boolean | undefined | null,
	series?: boolean | undefined | null,
	addWork?: boolean | undefined | null,
	own?: boolean | undefined | null,
	foreign?: boolean | undefined | null,
	earnings?: boolean | undefined | null,
	withAlarms?: boolean | undefined | null,
	withOrderPartInfo?: boolean | undefined | null
};
	["DeleteImageInput"]: {
	loadName: string,
	no: number
};
	["FailureReason"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Fixture"]: AliasType<{
	id?:boolean | `@${string}`,
	no?:boolean | `@${string}`,
	fixture?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FixtureType"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	typeOfWorkplace?:boolean | `@${string}`,
	workplace?:boolean | `@${string}`,
	maximumHeight?:boolean | `@${string}`,
	maximumWidth?:boolean | `@${string}`,
	maximumDepth?:boolean | `@${string}`,
	maximumWeight?:boolean | `@${string}`,
	minimumWeight?:boolean | `@${string}`,
	loadWeight?:boolean | `@${string}`,
	ownWeight?:boolean | `@${string}`,
	maxNumOfLoads?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerSpecificData"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	floatValue?:boolean | `@${string}`,
	longValue?:boolean | `@${string}`,
	characterValue?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerSpecificDataResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
	customerSpecificData?:ResolverInputTypes["CustomerSpecificData"],
		__typename?: boolean | `@${string}`
}>;
	["CreateCustomerSpecificDataInput"]: {
	name?: string | undefined | null,
	floatValue?: number | undefined | null,
	longValue?: number | undefined | null,
	characterValue?: string | undefined | null
};
	["EditCustomerSpecificDataInput"]: {
	id: number,
	modifiedOn: number,
	name?: string | undefined | null,
	floatValue?: number | undefined | null,
	longValue?: number | undefined | null,
	characterValue?: string | undefined | null
};
	["DeleteCustomerSpecificDataInput"]: {
	id: number,
	modifiedOn: number
};
	["EngineeringUnit"]: AliasType<{
	id?:boolean | `@${string}`,
	shortDescription?:boolean | `@${string}`,
	descripton?:boolean | `@${string}`,
	physicalUnit?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	peToEuType?:boolean | `@${string}`,
	peToEu?:boolean | `@${string}`,
	euToPeType?:boolean | `@${string}`,
	euToPeString?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnitValue"]: AliasType<{
	unitValue?:boolean | `@${string}`,
	default?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Unit"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:ResolverInputTypes["UnitValue"],
		__typename?: boolean | `@${string}`
}>;
	["UserUnits"]: AliasType<{
	user?:boolean | `@${string}`,
	units?:ResolverInputTypes["Unit"],
		__typename?: boolean | `@${string}`
}>;
	["OLD_MaterialGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OLD_NormMaterial"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	groupId?:boolean | `@${string}`,
	groupName?:boolean | `@${string}`,
	elementValuesFrom?:ResolverInputTypes["SHARED_ElementValueList"],
	elementValuesTo?:ResolverInputTypes["SHARED_ElementValueList"],
	averageAlloyFactor?:boolean | `@${string}`,
	carbideLimit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPlan"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Setpoints"]: AliasType<{
	recipeId?:boolean | `@${string}`,
	recipeName?:boolean | `@${string}`,
	numberOfPartsPerTray?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ToolingInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tooling"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoadInformation"]: AliasType<{
	packInformation?:boolean | `@${string}`,
	image1Base64?:boolean | `@${string}`,
	image2Base64?:boolean | `@${string}`,
	image3Base64?:boolean | `@${string}`,
	toolings?:ResolverInputTypes["ToolingInfo"],
		__typename?: boolean | `@${string}`
}>;
	["PartWorkplaceInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	setpoints?:ResolverInputTypes["Setpoints"],
	loadInformation?:ResolverInputTypes["LoadInformation"],
		__typename?: boolean | `@${string}`
}>;
	["PartManagementInfo"]: AliasType<{
	partInfo?:ResolverInputTypes["SHARED_PartInfo"],
	partWorkplaceList?:ResolverInputTypes["PartWorkplaceInfo"],
		__typename?: boolean | `@${string}`
}>;
	["SetPointItem"]: AliasType<{
	userComponentId?:boolean | `@${string}`,
	relevant?:boolean | `@${string}`,
	valueType?:boolean | `@${string}`,
	meaning?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	control?:ResolverInputTypes["SHARED_OptionItem"],
	value?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	toleranceMax?:boolean | `@${string}`,
	toleranceMin?:boolean | `@${string}`,
	alarmStartTimeInMin?:boolean | `@${string}`,
	alarmToleranceTimeInMin?:boolean | `@${string}`,
	endControl?:ResolverInputTypes["SHARED_OptionItem"],
	endValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemPositionHeader"]: AliasType<{
	positionNo?:boolean | `@${string}`,
	chamberOption?:ResolverInputTypes["SHARED_OptionItem"],
	chamberOperationOption?:ResolverInputTypes["SHARED_OptionItem"],
	description?:boolean | `@${string}`,
	afterTimeInMin?:boolean | `@${string}`,
	estimatedDurationInMin?:boolean | `@${string}`,
	afterOption?:ResolverInputTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemPosition"]: AliasType<{
	header?:ResolverInputTypes["RecipeItemPositionHeader"],
	setpoints?:ResolverInputTypes["SetPointItem"],
	digitalOutputList?:ResolverInputTypes["SHARED_DigitalOutput"],
	digitalOutputs?:boolean | `@${string}`,
	endAfter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemHeader"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	version?:boolean | `@${string}`,
	processOption?:ResolverInputTypes["SHARED_OptionItem"],
	processType?:boolean | `@${string}`,
	processDepth?:boolean | `@${string}`,
	processDepthAt?:boolean | `@${string}`,
	correction?:boolean | `@${string}`,
	diff?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeItemVersion"]: AliasType<{
	header?:ResolverInputTypes["RecipeItemHeader"],
	positions?:ResolverInputTypes["RecipeItemPosition"],
	spEntries?:ResolverInputTypes["SHARED_SpEntries"],
	material?:ResolverInputTypes["SHARED_Material"],
	correctionProfile?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeListItem"]: AliasType<{
	id?:boolean | `@${string}`,
	recipe?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CloneRecipeAndSaveAsResult"]: AliasType<{
	recipeVersions?:ResolverInputTypes["RecipeItemVersion"],
	isSaved?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SaveRecipeResult"]: AliasType<{
	recipeVersion?:ResolverInputTypes["RecipeItemVersion"],
	isSaved?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MetaDataSetPoint"]: AliasType<{
	name?:boolean | `@${string}`,
	userComponentId?:boolean | `@${string}`,
	meaning?:boolean | `@${string}`,
	relevant?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	valueType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MetaDataMethods"]: AliasType<{
	setPoints?:ResolverInputTypes["MetaDataSetPoint"],
	digitalInputs?:ResolverInputTypes["MetaDataSetPoint"],
	digitalOutputs?:ResolverInputTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["OperationOptions"]: AliasType<{
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	validVarIds?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ControlMetadata"]: AliasType<{
	setPoints?:ResolverInputTypes["MetaDataSetPoint"],
	digitalInputs?:ResolverInputTypes["MetaDataSetPoint"],
	digitalOutputs?:ResolverInputTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["OperationMetadata"]: AliasType<{
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	setPoints?:ResolverInputTypes["MetaDataSetPoint"],
	digitalInputs?:ResolverInputTypes["MetaDataSetPoint"],
	digitalOutputs?:ResolverInputTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["MetaDataChamberOption"]: AliasType<{
	chamberId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	recipeControl?:ResolverInputTypes["ControlMetadata"],
	operationControl?:ResolverInputTypes["OperationMetadata"],
	operationOptions?:ResolverInputTypes["SHARED_OptionItem"],
	setPoints?:ResolverInputTypes["MetaDataSetPoint"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeMetadata"]: AliasType<{
	afterOptions?:ResolverInputTypes["SHARED_OptionItem"],
	controlOptions?:ResolverInputTypes["SHARED_OptionItem"],
	endControlOptions?:ResolverInputTypes["SHARED_OptionItem"],
	chamberOptions?:ResolverInputTypes["MetaDataChamberOption"],
	operationProcessList?:ResolverInputTypes["SHARED_Operation"],
	materialList?:ResolverInputTypes["SHARED_OLD_CustomerMaterial"],
		__typename?: boolean | `@${string}`
}>;
	["RecipeVersionsHeaderInput"]: {
	name: string,
	pgmPrefix: string,
	versionIds: Array<string | undefined | null>
};
	["CloneRecipeAndSaveAsInput"]: {
	oldRecipeName: string,
	newRecipeName: string,
	description: string,
	recipePrefix: string
};
	["SetPointInput"]: {
	meaning: number,
	userComponentId: number,
	relevant: number,
	value?: number | undefined | null,
	valueType?: number | undefined | null,
	toleranceMax?: number | undefined | null,
	toleranceMin?: number | undefined | null,
	alarmStartTimeInMin?: number | undefined | null,
	alarmToleranceTimeInMin?: number | undefined | null,
	controlOptionNo?: number | undefined | null,
	endControlOptionNo?: number | undefined | null,
	endValue?: number | undefined | null
};
	["PositionHeaderInput"]: {
	positionNo: number,
	afterTimeOptionNo: number,
	afterTimeInMin?: number | undefined | null,
	estimatedTimeInMin?: number | undefined | null,
	chamberId: number,
	description: string,
	operationId: number,
	endOr?: number | undefined | null,
	valueDepth?: number | undefined | null
};
	["RecipePositionInput"]: {
	header: ResolverInputTypes["PositionHeaderInput"],
	setPoints: Array<ResolverInputTypes["SetPointInput"] | undefined | null>
};
	["SpEntriesInput"]: {
	furnaceSwitchEntry?: Array<string | undefined | null> | undefined | null,
	recipeDescription2?: string | undefined | null,
	recipeDescription3?: string | undefined | null,
	recipeDescription4?: string | undefined | null,
	gasEntryValues?: Array<string | undefined | null> | undefined | null
};
	["AnalysisInput"]: {
	c?: number | undefined | null,
	si?: number | undefined | null,
	mn?: number | undefined | null,
	cr?: number | undefined | null,
	ni?: number | undefined | null,
	v?: number | undefined | null,
	al?: number | undefined | null,
	cu?: number | undefined | null,
	p?: number | undefined | null,
	mo?: number | undefined | null,
	s?: number | undefined | null,
	b?: number | undefined | null,
	ti?: number | undefined | null
};
	["MaterialInput"]: {
	carbonContent?: number | undefined | null,
	alloyFactor?: number | undefined | null,
	legFactorN?: number | undefined | null,
	carbideLimit?: number | undefined | null,
	temperature?: number | undefined | null,
	specialNitrides?: number | undefined | null,
	analysis?: ResolverInputTypes["AnalysisInput"] | undefined | null,
	co?: number | undefined | null,
	h2?: number | undefined | null,
	quenchingIntensity?: number | undefined | null,
	representativeDiameter?: number | undefined | null,
	requiredHardness?: number | undefined | null,
	grainSize?: number | undefined | null,
	endTemperature?: number | undefined | null,
	targetSurfC?: number | undefined | null,
	customerMaterialId?: number | undefined | null,
	partId?: number | undefined | null
};
	["RecipeHeaderInput"]: {
	recipeName: string,
	description: string,
	comment?: string | undefined | null,
	processId: number,
	processType: number,
	processDepth?: number | undefined | null,
	processDepthAt?: number | undefined | null,
	diff: boolean,
	correction: boolean
};
	["SaveNewRecipeInput"]: {
	recipePrefix: string,
	header: ResolverInputTypes["RecipeHeaderInput"],
	positions: Array<ResolverInputTypes["RecipePositionInput"] | undefined | null>,
	spEntries?: ResolverInputTypes["SpEntriesInput"] | undefined | null,
	material: ResolverInputTypes["MaterialInput"],
	correctionProfile?: Array<number | undefined | null> | undefined | null
};
	["saveEditedRecipeVersionInput"]: {
	recipePrefix: string,
	headId: number,
	editedVersionNo: number,
	editedRecipeName: string,
	createNewVersion: boolean,
	header: ResolverInputTypes["RecipeHeaderInput"],
	positions: Array<ResolverInputTypes["RecipePositionInput"] | undefined | null>,
	spEntries?: ResolverInputTypes["SpEntriesInput"] | undefined | null,
	material: ResolverInputTypes["MaterialInput"],
	correctionProfile?: Array<number | undefined | null> | undefined | null
};
	["PeAttributeType"]: AliasType<{
	id?:boolean | `@${string}`,
	peAttributeTypeId?:boolean | `@${string}`,
	forDiffCalculation?:boolean | `@${string}`,
	forNitriding?:boolean | `@${string}`,
	hasMinimumLimit?:boolean | `@${string}`,
	hasMaximumLimit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PeAttributeTypeResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	peAttributeType?:ResolverInputTypes["PeAttributeType"],
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePeAttributeTypeInput"]: {
	peAttributeTypeId: number,
	forDiffCalculation: boolean,
	forNitriding: boolean,
	hasMinimumLimit: boolean,
	hasMaximumLimit: boolean
};
	["EditPeAttributeTypeInput"]: {
	id: number,
	modifiedOn: number,
	peAttributeTypeId: number,
	forDiffCalculation: boolean,
	forNitriding: boolean,
	hasMinimumLimit: boolean,
	hasMaximumLimit: boolean
};
	["DeletePeAttributeTypeInput"]: {
	id: number,
	modifiedOn: number
};
	["PeProcessType"]: AliasType<{
	id?:boolean | `@${string}`,
	phenNoId?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PeProcessTypeResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	peProcessType?:ResolverInputTypes["PeProcessType"],
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePeProcessTypeInput"]: {
	phenNoId: number
};
	["EditPeProcessTypeInput"]: {
	id: number,
	phenNoId: number,
	modifiedOn: number
};
	["DeletePeProcessTypeInput"]: {
	id: number,
	modifiedOn: number
};
	["PhysicalUnit"]: AliasType<{
	id?:boolean | `@${string}`,
	shortDescription?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	typeId?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PhysicalUnitResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	physicalUnit?:ResolverInputTypes["PhysicalUnit"],
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePhysicalUnitInput"]: {
	shortDescription?: string | undefined | null,
	description?: string | undefined | null,
	typeId?: number | undefined | null,
	unit?: string | undefined | null
};
	["EditPhysicalUnitInput"]: {
	id: number,
	modifiedOn: number,
	shortDescription?: string | undefined | null,
	description?: string | undefined | null,
	typeId?: number | undefined | null,
	unit?: string | undefined | null
};
	["DeletePhysicalUnitInput"]: {
	id: number,
	modifiedOn: number
};
	["updateWorkplaceTypeInput"]: {
	number: number,
	description?: string | undefined | null,
	prio?: number | undefined | null,
	modifiedOn: number
};
	["createWorkplaceTypeInput"]: {
	number: number,
	description?: string | undefined | null,
	prio?: number | undefined | null
};
	["cloneWorkplaceTypeInput"]: {
	number: string,
	description?: string | undefined | null
};
	["WorkplaceTypeResult"]: AliasType<{
	workplaceType?:ResolverInputTypes["SHARED_WorkplaceType"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["BackgroundProcess"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	argument?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	pid?:boolean | `@${string}`,
	activeTime?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProcessResult"]: AliasType<{
	process?:ResolverInputTypes["BackgroundProcess"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteProcessInput"]: {
	id: number,
	modifiedOn: number
};
	["CloneProcessInput"]: {
	id: number,
	name: string,
	description: string
};
	["EditProcessInput"]: {
	id: number,
	name?: string | undefined | null,
	description?: string | undefined | null,
	order?: number | undefined | null,
	argument?: string | undefined | null,
	active?: string | undefined | null,
	pid?: number | undefined | null,
	activeTime?: number | undefined | null,
	modifiedOn: number
};
	["CreateNewProcessInput"]: {
	name: string,
	description: string,
	order?: number | undefined | null,
	argument?: string | undefined | null,
	active?: string | undefined | null,
	pid?: number | undefined | null,
	activeTime?: number | undefined | null
};
	["createWorkplaceInput"]: {
	id: string,
	name?: string | undefined | null,
	description?: string | undefined | null,
	inventoryNumber?: string | undefined | null,
	type?: number | undefined | null,
	active?: number | undefined | null,
	useMode?: number | undefined | null,
	logicType?: string | undefined | null,
	recipePrefix?: string | undefined | null,
	chamberId?: number | undefined | null,
	sharesController?: number | undefined | null,
	belongsTo?: string | undefined | null,
	ip?: string | undefined | null,
	mGuardIp?: string | undefined | null,
	device?: string | undefined | null,
	telegramSplit?: boolean | undefined | null,
	deactivateOperations?: boolean | undefined | null,
	aliveBitErrorNum?: number | undefined | null,
	comment?: string | undefined | null
};
	["updateWorkplaceInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	inventoryNumber?: string | undefined | null,
	type?: number | undefined | null,
	active?: number | undefined | null,
	useMode?: number | undefined | null,
	logicType?: string | undefined | null,
	recipePrefix?: string | undefined | null,
	chamberId?: number | undefined | null,
	sharesController?: number | undefined | null,
	belongsTo?: string | undefined | null,
	ip?: string | undefined | null,
	mGuardIp?: string | undefined | null,
	device?: string | undefined | null,
	telegramSplit?: boolean | undefined | null,
	deactivateOperations?: boolean | undefined | null,
	aliveBitErrorNum?: number | undefined | null,
	comment?: string | undefined | null,
	modifiedOn: number
};
	["cloneWorkplaceInput"]: {
	id: string,
	name?: string | undefined | null,
	description?: string | undefined | null
};
	["WorkplaceResult"]: AliasType<{
	workplace?:ResolverInputTypes["SHARED_Workplace"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["OperationType"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationPart"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationProcess"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	sap?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateOperationProcess"]: {
	order?: number | undefined | null,
	sap?: string | undefined | null,
	workplaceId: number
};
	["Operation"]: AliasType<{
	id?:boolean | `@${string}`,
	processTypeId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	operationType?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	relevantForStat?:boolean | `@${string}`,
	mode?:boolean | `@${string}`,
	typeId?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
	operationProcess?:ResolverInputTypes["OperationProcess"],
	operationParts?:ResolverInputTypes["OperationPart"],
		__typename?: boolean | `@${string}`
}>;
	["CreateOperation"]: {
	processTypeId?: number | undefined | null,
	name: string,
	comment: string,
	order: number,
	relevantForStat: boolean,
	mode: number,
	typeId: number,
	operationProcess?: Array<ResolverInputTypes["CreateOperationProcess"] | undefined | null> | undefined | null,
	operationParts?: Array<number | undefined | null> | undefined | null
};
	["EditOperation"]: {
	id: number,
	modifiedOn: number,
	processTypeId?: number | undefined | null,
	comment: string,
	order: number,
	relevantForStat: boolean,
	mode: number,
	typeId: number,
	operationProcess?: Array<ResolverInputTypes["CreateOperationProcess"] | undefined | null> | undefined | null,
	operationParts?: Array<number | undefined | null> | undefined | null
};
	["DeleteOperation"]: {
	id: number,
	modifiedOn: number
};
	["OperationResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	operation?:ResolverInputTypes["Operation"],
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["Alarm"]: AliasType<{
	id?:boolean | `@${string}`,
	workplace?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	alarm?:boolean | `@${string}`,
	alarmText?:boolean | `@${string}`,
	errorType?:boolean | `@${string}`,
	outputType?:boolean | `@${string}`,
	security?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	onlyForChamber?:boolean | `@${string}`,
	causesATestingLoad?:boolean | `@${string}`,
	alarmGroup?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChambersPerWorkplace"]: AliasType<{
	workplaceId?:boolean | `@${string}`,
	chamberList?:ResolverInputTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["AlarmAdministrationMetadata"]: AliasType<{
	workplaceList?:ResolverInputTypes["SHARED_OptionItem"],
	errorTypeList?:ResolverInputTypes["SHARED_OptionItem"],
	outputTypeList?:ResolverInputTypes["SHARED_OptionItem"],
	alarmGroupList?:ResolverInputTypes["SHARED_OptionItem"],
	importanceList?:ResolverInputTypes["SHARED_OptionItem"],
	colorList?:ResolverInputTypes["SHARED_OptionItem"],
	chambersList?:ResolverInputTypes["ChambersPerWorkplace"],
		__typename?: boolean | `@${string}`
}>;
	["AlarmResult"]: AliasType<{
	alarm?:ResolverInputTypes["Alarm"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreateNewAlarmInput"]: {
	workplaceId: number,
	alarmNo: number,
	alarmText: string,
	errorTypeId: number,
	outputTypeId: number,
	importanceId?: number | undefined | null,
	security: number,
	colorId: number,
	alarmGroupId: number,
	causeTestingLoad: boolean,
	onlyForChamber: number
};
	["CloneAndSaveAsInput"]: {
	id: number,
	alarmNo: number
};
	["EditAlarmInput"]: {
	id: number,
	alarmNo?: number | undefined | null,
	modifiedOn: number,
	workplaceId?: number | undefined | null,
	alarmText?: string | undefined | null,
	errorTypeId?: number | undefined | null,
	outputTypeId?: number | undefined | null,
	importanceId?: number | undefined | null,
	security?: number | undefined | null,
	colorId?: number | undefined | null,
	onlyForChamber?: number | undefined | null,
	alarmGroupId?: number | undefined | null,
	causeTestingLoad?: boolean | undefined | null
};
	["DeleteAlarmInput"]: {
	id: number,
	modifiedOn: number
};
	["DeleteSelectedAlarmsForFurnaceInput"]: {
	workplaceId: number,
	selectedAlarms: Array<ResolverInputTypes["DeleteAlarmInput"] | undefined | null>
};
	["Box"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	colorId?:boolean | `@${string}`,
	used?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorBox"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	redValue?:boolean | `@${string}`,
	greenValue?:boolean | `@${string}`,
	blueValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateBoxInput"]: {
	name: string,
	colorId: number,
	used: boolean
};
	["EditBoxInput"]: {
	id: number,
	name?: string | undefined | null,
	colorId?: number | undefined | null,
	used?: boolean | undefined | null,
	modifiedOn: number
};
	["DeleteBoxInput"]: {
	id: number,
	modifiedOn: number
};
	["BoxResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	box?:ResolverInputTypes["Box"],
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["Color"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	redValue?:boolean | `@${string}`,
	greenValue?:boolean | `@${string}`,
	blueValue?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	color?:ResolverInputTypes["Color"],
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreateColorInput"]: {
	name: string,
	redValue: number,
	greenValue: number,
	blueValue: number
};
	["EditColorInput"]: {
	id: number,
	modifiedOn: number,
	name?: string | undefined | null,
	redValue?: number | undefined | null,
	greenValue?: number | undefined | null,
	blueValue?: number | undefined | null
};
	["deleteColorInput"]: {
	id: number,
	modifiedOn: number
};
	["LanguageInfo"]: AliasType<{
	languageId?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TranslationInfo"]: AliasType<{
	languageId?:boolean | `@${string}`,
	translation?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DictionaryItem"]: AliasType<{
	textId?:boolean | `@${string}`,
	defaultText?:boolean | `@${string}`,
	peProperty?:boolean | `@${string}`,
	used?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	translations?:ResolverInputTypes["TranslationInfo"],
	customerTranslations?:ResolverInputTypes["TranslationInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ExternalizeTranslationConfigValue"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SmsSettings"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	addresseeMobilePhoneNo?:boolean | `@${string}`,
	pin?:boolean | `@${string}`,
	serialInterface?:boolean | `@${string}`,
	testMessage?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Task"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	command1?:boolean | `@${string}`,
	command2?:boolean | `@${string}`,
	command3?:boolean | `@${string}`,
	command4?:boolean | `@${string}`,
	command5?:boolean | `@${string}`,
	command6?:boolean | `@${string}`,
	command7?:boolean | `@${string}`,
	command8?:boolean | `@${string}`,
	command9?:boolean | `@${string}`,
	command10?:boolean | `@${string}`,
	startHour?:boolean | `@${string}`,
	intervalInMin?:boolean | `@${string}`,
	dateLastActive?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskResult"]: AliasType<{
	task?:ResolverInputTypes["Task"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["createTaskInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	active?: boolean | undefined | null,
	type?: string | undefined | null,
	command1?: string | undefined | null,
	command2?: string | undefined | null,
	command3?: string | undefined | null,
	command4?: string | undefined | null,
	command5?: string | undefined | null,
	command6?: string | undefined | null,
	command7?: string | undefined | null,
	command8?: string | undefined | null,
	command9?: string | undefined | null,
	command10?: string | undefined | null,
	startHour?: number | undefined | null,
	intervalInMin?: number | undefined | null,
	dateLastActive?: number | undefined | null,
	comment?: string | undefined | null
};
	["updateTaskInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	active?: boolean | undefined | null,
	type?: string | undefined | null,
	command1?: string | undefined | null,
	command2?: string | undefined | null,
	command3?: string | undefined | null,
	command4?: string | undefined | null,
	command5?: string | undefined | null,
	command6?: string | undefined | null,
	command7?: string | undefined | null,
	command8?: string | undefined | null,
	command9?: string | undefined | null,
	command10?: string | undefined | null,
	startHour?: number | undefined | null,
	intervalInMin?: number | undefined | null,
	comment?: string | undefined | null,
	modifiedOn: number
};
	["cloneTaskInput"]: {
	name: string,
	description?: string | undefined | null,
	comment?: string | undefined | null
};
	["updateClientAccessClientListInput"]: {
	clientId: number,
	isActive: boolean
};
	["updateClientAccessResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["TerminalType"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	osTypeId?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TerminalTypeResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
	terminalType?:ResolverInputTypes["TerminalType"],
		__typename?: boolean | `@${string}`
}>;
	["CreateTerminalTypeInput"]: {
	description?: string | undefined | null,
	osTypeId: number,
	remark?: string | undefined | null
};
	["EditTerminalTypeInput"]: {
	id: number,
	modifiedOn: number,
	description?: string | undefined | null,
	osTypeId?: number | undefined | null,
	remark?: string | undefined | null
};
	["DeleteTerminalTypeInput"]: {
	id: number,
	modifiedOn: number
};
	["TerminalClient"]: AliasType<{
	id?:boolean | `@${string}`,
	clientTypeId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adress?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	resolution?:boolean | `@${string}`,
	valueLong1?:boolean | `@${string}`,
	valueLong2?:boolean | `@${string}`,
	valueLong3?:boolean | `@${string}`,
	valueLong4?:boolean | `@${string}`,
	valueLong5?:boolean | `@${string}`,
	valueFloat1?:boolean | `@${string}`,
	valueFloat2?:boolean | `@${string}`,
	valueFloat3?:boolean | `@${string}`,
	valueChar1?:boolean | `@${string}`,
	valueChar2?:boolean | `@${string}`,
	actUserId?:boolean | `@${string}`,
	actLoginTime?:boolean | `@${string}`,
	lastUserId?:boolean | `@${string}`,
	lastLoginTime?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TerminalClientResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
	terminalClient?:ResolverInputTypes["TerminalClient"],
		__typename?: boolean | `@${string}`
}>;
	["CreateTerminalClientInput"]: {
	clientTypeId?: number | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	adress?: string | undefined | null,
	location?: string | undefined | null,
	remark?: string | undefined | null,
	resolution?: number | undefined | null,
	valueLong1?: number | undefined | null,
	valueLong2?: number | undefined | null,
	valueLong3?: number | undefined | null,
	valueLong4?: number | undefined | null,
	valueLong5?: number | undefined | null,
	valueFloat1?: number | undefined | null,
	valueFloat2?: number | undefined | null,
	valueFloat3?: number | undefined | null,
	valueChar1?: string | undefined | null,
	valueChar2?: string | undefined | null,
	actUserId?: number | undefined | null,
	actLoginTime?: number | undefined | null,
	lastUserId?: number | undefined | null,
	lastLoginTime?: number | undefined | null
};
	["EditTerminalClientInput"]: {
	id: number,
	modifiedOn: number,
	clientTypeId?: number | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	adress?: string | undefined | null,
	location?: string | undefined | null,
	remark?: string | undefined | null,
	resolution?: number | undefined | null,
	valueLong1?: number | undefined | null,
	valueLong2?: number | undefined | null,
	valueLong3?: number | undefined | null,
	valueLong4?: number | undefined | null,
	valueLong5?: number | undefined | null,
	valueFloat1?: number | undefined | null,
	valueFloat2?: number | undefined | null,
	valueFloat3?: number | undefined | null,
	valueChar1?: string | undefined | null,
	valueChar2?: string | undefined | null,
	actUserId?: number | undefined | null,
	actLoginTime?: number | undefined | null,
	lastUserId?: number | undefined | null,
	lastLoginTime?: number | undefined | null
};
	["DeleteTerminalClientInput"]: {
	id: number,
	modifiedOn: number
};
	["TerminalClientMetadata"]: AliasType<{
	resolutions?:ResolverInputTypes["SHARED_OptionItem"],
	users?:ResolverInputTypes["SHARED_OptionItem"],
	clientTypes?:ResolverInputTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ApplicationGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ApplicationGroupResult"]: AliasType<{
	applicationGroup?:ResolverInputTypes["ApplicationGroup"],
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["CreateAppGroupInput"]: {
	name: string,
	description?: string | undefined | null,
	order?: number | undefined | null
};
	["EditAppGroupInput"]: {
	id: number,
	name: string,
	description?: string | undefined | null,
	order?: number | undefined | null,
	modifiedOn: number
};
	["DeleteAppGroupInput"]: {
	id: number,
	modifiedOn: number
};
	["Application"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	command?:boolean | `@${string}`,
	parameter?:boolean | `@${string}`,
	tooltip?:boolean | `@${string}`,
	furnacePicture?:boolean | `@${string}`,
	iconGifName?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	typeId?:boolean | `@${string}`,
	groupId?:boolean | `@${string}`,
	startRecipeProcessFlow?:boolean | `@${string}`,
	modifyRecipeProcessFlow?:boolean | `@${string}`,
	stopRecipeProcessFlow?:boolean | `@${string}`,
	startCorrectionRecipe?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ApplicationResult"]: AliasType<{
	isSuccess?:boolean | `@${string}`,
	error?:ResolverInputTypes["Error"],
	application?:ResolverInputTypes["Application"],
		__typename?: boolean | `@${string}`
}>;
	["CreateApplicationInput"]: {
	name: string,
	description?: string | undefined | null,
	command?: string | undefined | null,
	parameter?: string | undefined | null,
	tooltip?: string | undefined | null,
	furnacePicture?: string | undefined | null,
	iconGifName?: string | undefined | null,
	order?: number | undefined | null,
	typeId?: number | undefined | null,
	groupId: number,
	startRecipeProcessFlow: boolean,
	modifyRecipeProcessFlow: boolean,
	stopRecipeProcessFlow: boolean,
	startCorrectionRecipe: boolean
};
	["EditApplicationInput"]: {
	id: number,
	name: string,
	description?: string | undefined | null,
	command?: string | undefined | null,
	parameter?: string | undefined | null,
	tooltip?: string | undefined | null,
	furnacePicture?: string | undefined | null,
	iconGifName?: string | undefined | null,
	order?: number | undefined | null,
	typeId?: number | undefined | null,
	groupId: number,
	startRecipeProcessFlow: boolean,
	modifyRecipeProcessFlow: boolean,
	stopRecipeProcessFlow: boolean,
	startCorrectionRecipe: boolean,
	modifiedOn: number
};
	["DeleteApplicationInput"]: {
	id: number,
	modifiedOn: number
};
	["ApplicationMetadata"]: AliasType<{
	applicationGroupList?:ResolverInputTypes["SHARED_OptionItem"],
	applicationTypeList?:ResolverInputTypes["SHARED_OptionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProcessFlow"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comments?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecipeMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	operationType?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProcessFlowStep"]: AliasType<{
	ptime?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	workplace?:ResolverInputTypes["SHARED_Workplace"],
	recipe?:ResolverInputTypes["RecipeMenuItem"],
	operation?:ResolverInputTypes["OperationMenuItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProcessFlowPartComparisonItem"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	description2?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserAccess"]: AliasType<{
	user?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	group?:boolean | `@${string}`,
	pgmId?:boolean | `@${string}`,
	application?:boolean | `@${string}`,
	run?:boolean | `@${string}`,
	newEntry?:boolean | `@${string}`,
	save?:boolean | `@${string}`,
	delete?:boolean | `@${string}`,
	special1?:boolean | `@${string}`,
	print?:boolean | `@${string}`,
	startRecipeProcessFlow?:boolean | `@${string}`,
	modifyRecipeProcessFlow?:boolean | `@${string}`,
	stopRecipeProcessFlow?:boolean | `@${string}`,
	startCorrectionRecipe?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["updateUserAccessInput"]: {
	userId: number,
	pgmId: number,
	run?: boolean | undefined | null,
	newEntry?: boolean | undefined | null,
	save?: boolean | undefined | null,
	delete?: boolean | undefined | null,
	special1?: boolean | undefined | null,
	print?: boolean | undefined | null,
	startRecipeProcessFlow?: boolean | undefined | null,
	modifyRecipeProcessFlow?: boolean | undefined | null,
	stopRecipeProcessFlow?: boolean | undefined | null,
	startCorrectionRecipe?: boolean | undefined | null
};
	["UserGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	modifiedOn?:boolean | `@${string}`,
	modifiedBy?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["createUserGroupInput"]: {
	description?: string | undefined | null,
	name: string
};
	["updateUserGroupInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null
};
	["createUserInput"]: {
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	shortName: string,
	function?: string | undefined | null,
	password?: string | undefined | null,
	comment?: string | undefined | null,
	employeeNumber?: string | undefined | null,
	plant?: string | undefined | null,
	costCenter?: string | undefined | null,
	maintenancePopups?: boolean | undefined | null,
	playAlarmSound?: boolean | undefined | null,
	accessLevel?: number | undefined | null,
	language?: number | undefined | null,
	userGroup: number,
	picture?: string | undefined | null
};
	["updateUserInput"]: {
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	function?: string | undefined | null,
	password?: string | undefined | null,
	comment?: string | undefined | null,
	employeeNumber?: string | undefined | null,
	plant?: string | undefined | null,
	costCenter?: string | undefined | null,
	maintenancePopups?: boolean | undefined | null,
	playAlarmSound?: boolean | undefined | null,
	accessLevel?: number | undefined | null,
	language?: number | undefined | null,
	userGroup?: number | undefined | null,
	picture?: string | undefined | null
};
	["TO_REMOVE_RecipeMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	workplaceId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	recipePrefix?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TO_REMOVE_ShopLayoutMenuItem"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Error"]: {
		code?: string | undefined,
	message?: string | undefined,
	detail?: string | undefined
};
	["SHARED_ChartPoint"]: {
		timestamp?: number | undefined,
	value?: number | undefined
};
	["SHARED_Chart"]: {
		chartName?: string | undefined,
	varId?: number | undefined,
	chartColor?: number | undefined,
	chartUnit?: string | undefined,
	data?: Array<ModelTypes["SHARED_ChartPoint"] | undefined> | undefined
};
	["UDM_ChartData"]: {
		varId?: number | undefined,
	data?: Array<ModelTypes["SHARED_ChartPoint"] | undefined> | undefined
};
	["ChartMetaData"]: {
		varId?: number | undefined,
	chartName?: string | undefined,
	chartColor?: number | undefined,
	chartUnit?: string | undefined
};
	["SHARED_LoadRecipeHeader"]: {
		name?: string | undefined,
	description?: string | undefined
};
	["SHARED_PartData"]: {
		pos?: number | undefined,
	partName?: string | undefined,
	partDescription?: string | undefined,
	amount?: number | undefined,
	weightInKg?: number | undefined,
	totalWeightInKg?: number | undefined,
	orderName?: string | undefined,
	orderRemark?: string | undefined,
	type?: string | undefined,
	customer?: string | undefined,
	intern?: number | undefined
};
	["SHARED_FurnaceSystemStatus"]: {
		entranceReady?: boolean | undefined,
	exitReady?: boolean | undefined
};
	["SHARED_Load"]: {
		id?: string | undefined,
	name?: string | undefined,
	identifier?: string | undefined,
	workplaceId?: string | undefined,
	start?: number | undefined,
	end?: number | undefined,
	earliestTimeStart?: number | undefined,
	packedTime?: number | undefined,
	packedUser?: string | undefined,
	startedUser?: string | undefined,
	status?: string | undefined,
	recipe?: ModelTypes["SHARED_LoadRecipeHeader"] | undefined,
	parts?: Array<ModelTypes["SHARED_PartData"] | undefined> | undefined
};
	["SHARED_Workplace"]: {
		id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined,
	inventoryNumber?: string | undefined,
	type?: ModelTypes["SHARED_WorkplaceType"] | undefined,
	active?: ModelTypes["SHARED_ActiveState"] | undefined,
	useMode?: ModelTypes["SHARED_UseMode"] | undefined,
	logicType?: string | undefined,
	recipePrefix?: string | undefined,
	chamberId?: number | undefined,
	sharesController?: number | undefined,
	belongsTo?: string | undefined,
	ip?: string | undefined,
	mGuardIp?: string | undefined,
	device?: string | undefined,
	telegramSplit?: boolean | undefined,
	deactivateOperations?: boolean | undefined,
	aliveBitErrorNum?: number | undefined,
	comment?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_WorkplaceType"]: {
		number?: number | undefined,
	description?: string | undefined,
	prio?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_ActiveState"]: {
		string?: string | undefined,
	int?: number | undefined
};
	["SHARED_UseMode"]: {
		string?: string | undefined,
	int?: number | undefined
};
	["SHARED_OptionItem"]: {
		id?: number | undefined,
	value?: string | undefined
};
	["SHARED_SpEntries"]: {
		furnaceSwitchEntry?: Array<string | undefined> | undefined,
	recipeDescription2?: string | undefined,
	recipeDescription3?: string | undefined,
	recipeDescription4?: string | undefined,
	gasEntryValues?: Array<string | undefined> | undefined
};
	["SHARED_DigitalOutput"]: {
		name?: string | undefined,
	value?: boolean | undefined
};
	["SHARED_Material"]: {
		carbonContent?: number | undefined,
	alloyFactor?: number | undefined,
	legFactorN?: number | undefined,
	carbideLimit?: number | undefined,
	temperature?: number | undefined,
	specialNitrides?: number | undefined,
	analysis?: ModelTypes["SHARED_ElementValueList"] | undefined,
	co?: number | undefined,
	h2?: number | undefined,
	quenchingIntensity?: number | undefined,
	representativeDiameter?: number | undefined,
	requiredHardness?: number | undefined,
	grainSize?: number | undefined,
	endTemperature?: number | undefined,
	targetSurfC?: number | undefined,
	customerMaterialId?: number | undefined
};
	["SHARED_PropertyInfo"]: {
		property?: string | undefined,
	target?: number | undefined,
	unit?: string | undefined
};
	["SHARED_PartInfo"]: {
		id?: number | undefined,
	name?: string | undefined,
	materialId?: number | undefined,
	materialName?: string | undefined,
	description1?: string | undefined,
	description2?: string | undefined,
	drawingNumber?: number | undefined,
	revisionNumber?: number | undefined,
	testPlanId?: number | undefined,
	testPlanDescription?: string | undefined,
	comment?: string | undefined,
	properties?: Array<ModelTypes["SHARED_PropertyInfo"] | undefined> | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_WorkplaceRecipe"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	version_no?: number | undefined
};
	["SHARED_ElementValueList"]: {
		c?: number | undefined,
	si?: number | undefined,
	mn?: number | undefined,
	cr?: number | undefined,
	ni?: number | undefined,
	v?: number | undefined,
	al?: number | undefined,
	cu?: number | undefined,
	p?: number | undefined,
	s?: number | undefined,
	mo?: number | undefined,
	b?: number | undefined,
	ti?: number | undefined
};
	["SHARED_OLD_CustomerMaterial"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	normId?: number | undefined,
	normName?: string | undefined,
	normDescription?: string | undefined,
	groupId?: number | undefined,
	groupName?: string | undefined,
	elementValues?: ModelTypes["SHARED_ElementValueList"] | undefined,
	averageAlloyFactor?: number | undefined,
	averageAlloyFactorN?: number | undefined,
	carbideLimit?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_DropDownListItem"]: {
		id?: number | undefined,
	value?: string | undefined,
	isActive?: boolean | undefined
};
	["SHARED_User"]: {
		id?: number | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	shortName?: string | undefined,
	function?: string | undefined,
	password?: string | undefined,
	comment?: string | undefined,
	employeeNumber?: string | undefined,
	plant?: string | undefined,
	costCenter?: string | undefined,
	maintenancePopups?: boolean | undefined,
	playAlarmSound?: boolean | undefined,
	loginTimeInMin?: number | undefined,
	accessLevel?: number | undefined,
	languageId?: number | undefined,
	language?: Array<ModelTypes["SHARED_DropDownListItem"] | undefined> | undefined,
	userGroupId?: number | undefined,
	userGroup?: Array<ModelTypes["SHARED_DropDownListItem"] | undefined> | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined,
	picture?: string | undefined
};
	["SHARED_Client"]: {
		id?: number | undefined,
	clientType?: string | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: string | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserName?: string | undefined,
	actLoginTime?: number | undefined,
	lastUserName?: string | undefined,
	lastLoginTime?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined,
	isActive?: boolean | undefined
};
	["SHARED_Operation"]: {
		id?: number | undefined,
	name?: string | undefined,
	operationType?: string | undefined,
	comment?: string | undefined,
	order?: number | undefined,
	relevantForStat?: number | undefined,
	mode?: string | undefined,
	type?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_ValueViewComponent"]: {
		id?: number | undefined,
	type?: string | undefined,
	value?: number | undefined,
	status?: number | undefined,
	digitsBeforeComma?: number | undefined,
	digitsAfterComma?: number | undefined,
	textBefore?: string | undefined,
	textAfter?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	numberFormat?: string | undefined,
	fontSize?: number | undefined
};
	["SHARED_BackgroundView"]: {
		id?: number | undefined,
	type?: string | undefined,
	filePath?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	width?: number | undefined,
	height?: number | undefined
};
	["SHARED_TextView"]: {
		id?: number | undefined,
	type?: string | undefined,
	value?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	color?: number | undefined,
	colorBG?: number | undefined,
	fontSize?: number | undefined
};
	["SHARED_RecipeVersion"]: {
		id?: number | undefined,
	startingTime?: number | undefined,
	versionText?: string | undefined
};
	["SHARED_RecipeShortHeader"]: {
		recipeName?: string | undefined,
	actualStep?: number | undefined,
	loadIdentifier?: string | undefined,
	loadName?: string | undefined,
	versions?: Array<ModelTypes["SHARED_RecipeVersion"] | undefined> | undefined
};
	["SHARED_LoadColumn"]: {
		name?: string | undefined,
	value?: string | undefined
};
	["SHARED_LoadButton"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	chamberId?: number | undefined
};
	["SHARED_Bitmap"]: {
		id?: number | undefined,
	type?: string | undefined,
	filePath?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined
};
	["SHARED_Gif"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	filePath?: string | undefined
};
	["SHARED_MovingPic"]: {
		id?: number | undefined,
	type?: string | undefined,
	filePath?: string | undefined,
	startX?: number | undefined,
	startY?: number | undefined,
	endX?: number | undefined,
	endY?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	componentZero?: number | undefined,
	componentSpan?: number | undefined
};
	["SHARED_Label"]: {
		id?: number | undefined,
	type?: string | undefined,
	text?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	fontSize?: number | undefined
};
	["SHARED_LabelPlus"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorIndex?: number | undefined,
	colorBG?: number | undefined,
	text?: string | undefined,
	text0?: string | undefined,
	text1?: string | undefined,
	text2?: string | undefined,
	text3?: string | undefined,
	fontSize?: number | undefined
};
	["SHARED_Line"]: {
		id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	type?: string | undefined,
	colorIndex?: number | undefined
};
	["SHARED_EditBox"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	focusOrderNumber?: number | undefined,
	variableType?: number | undefined,
	isEditable?: boolean | undefined,
	digitsBeforeComma?: number | undefined,
	digitsAfterComma?: number | undefined,
	minValue?: number | undefined,
	maxValue?: number | undefined,
	numberFormat?: string | undefined,
	fontSize?: number | undefined,
	editValue?: number | undefined,
	componentTypeId?: number | undefined,
	userInputId?: number | undefined,
	outputId?: number | undefined
};
	["SHARED_ProgressBar"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	fullColor?: number | undefined,
	emptyColor?: number | undefined,
	colorBG?: number | undefined,
	value?: number | undefined,
	minValue?: number | undefined,
	maxValue?: number | undefined
};
	["SHARED_Place"]: {
		id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	layoutOrder?: number | undefined,
	name?: string | undefined,
	colorPalette?: string | undefined,
	colorBG?: number | undefined,
	colorIndex?: number | undefined,
	systemColorBG?: number | undefined
};
	["SHARED_Rectangle"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined
};
	["SHARED_ChargeCarItem"]: {
		wpId?: number | undefined,
	name?: string | undefined,
	infoText?: string | undefined,
	placeId?: number | undefined,
	placeName?: string | undefined,
	placeColor?: number | undefined,
	placeColorBG?: number | undefined,
	posX?: number | undefined
};
	["SHARED_ChargeCarAutFurnaceDriving"]: {
		driverWpId?: number | undefined,
	overWpId?: number | undefined,
	furnaceWidth?: number | undefined,
	furnaceHeight?: number | undefined,
	furnaceOverviewNo?: number | undefined,
	furnacePlace?: number | undefined
};
	["SHARED_ChargeCar"]: {
		id?: number | undefined,
	carType?: string | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	layoutOrder?: number | undefined,
	imageName?: string | undefined,
	car?: ModelTypes["SHARED_ChargeCarItem"] | undefined,
	carList?: Array<ModelTypes["SHARED_ChargeCarItem"] | undefined> | undefined,
	railMaxPosPixels?: number | undefined,
	railMaxPosMm?: number | undefined,
	autFurnaceDriving?: ModelTypes["SHARED_ChargeCarAutFurnaceDriving"] | undefined,
	mode?: string | undefined
};
	["ShopLayoutChargeStatus"]: {
		id?: string | undefined,
	colorBG?: number | undefined,
	color?: number | undefined,
	colorBorder?: number | undefined
};
	["ShopLayoutView"]: {
		workplaceId?: number | undefined,
	state?: string | undefined,
	systemStatus?: ModelTypes["SHARED_FurnaceSystemStatus"] | undefined,
	chargeStatusList?: Array<ModelTypes["ShopLayoutChargeStatus"] | undefined> | undefined,
	recipeHeader?: ModelTypes["SHARED_RecipeShortHeader"] | undefined,
	id?: number | undefined,
	type?: string | undefined,
	name?: string | undefined,
	text?: Array<ModelTypes["SHARED_TextView"] | undefined> | undefined,
	value?: Array<ModelTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	background?: ModelTypes["SHARED_BackgroundView"] | undefined,
	loadButton?: Array<ModelTypes["SHARED_LoadButton"] | undefined> | undefined,
	place?: Array<ModelTypes["SHARED_Place"] | undefined> | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	bitmap?: Array<ModelTypes["SHARED_Bitmap"] | undefined> | undefined,
	gif?: Array<ModelTypes["SHARED_Gif"] | undefined> | undefined,
	label?: Array<ModelTypes["SHARED_Label"] | undefined> | undefined,
	labelPlus?: Array<ModelTypes["SHARED_LabelPlus"] | undefined> | undefined,
	line?: Array<ModelTypes["SHARED_Line"] | undefined> | undefined,
	editBox?: Array<ModelTypes["SHARED_EditBox"] | undefined> | undefined,
	progressBar?: Array<ModelTypes["SHARED_ProgressBar"] | undefined> | undefined,
	rectangle?: Array<ModelTypes["SHARED_Rectangle"] | undefined> | undefined,
	chargeCar?: Array<ModelTypes["SHARED_ChargeCar"] | undefined> | undefined
};
	["ShopLayoutViewData"]: {
		workplaceId?: number | undefined,
	state?: string | undefined,
	systemStatus?: ModelTypes["SHARED_FurnaceSystemStatus"] | undefined,
	recipeHeader?: ModelTypes["SHARED_RecipeShortHeader"] | undefined,
	loadButton?: Array<ModelTypes["SHARED_LoadButton"] | undefined> | undefined,
	id?: number | undefined,
	type?: string | undefined,
	name?: string | undefined,
	value?: Array<ModelTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	place?: Array<ModelTypes["SHARED_Place"] | undefined> | undefined,
	bitmap?: Array<ModelTypes["SHARED_Bitmap"] | undefined> | undefined,
	gif?: Array<ModelTypes["SHARED_Gif"] | undefined> | undefined,
	label?: Array<ModelTypes["SHARED_Label"] | undefined> | undefined,
	labelPlus?: Array<ModelTypes["SHARED_LabelPlus"] | undefined> | undefined,
	line?: Array<ModelTypes["SHARED_Line"] | undefined> | undefined,
	editBox?: Array<ModelTypes["SHARED_EditBox"] | undefined> | undefined,
	progressBar?: Array<ModelTypes["SHARED_ProgressBar"] | undefined> | undefined,
	rectangle?: Array<ModelTypes["SHARED_Rectangle"] | undefined> | undefined,
	chargeCar?: Array<ModelTypes["SHARED_ChargeCar"] | undefined> | undefined
};
	["ShopLayoutAlarm"]: {
		alarmId?: number | undefined,
	workplaceName?: string | undefined,
	startTime?: number | undefined,
	acknowledgeTime?: number | undefined,
	endTime?: number | undefined,
	priority?: string | undefined,
	alarmMessage?: string | undefined,
	gate?: number | undefined,
	alarmNum?: number | undefined,
	byte?: number | undefined,
	bit?: number | undefined
};
	["ShopLayoutAlarmSearch"]: {
		workplaceName?: string | undefined,
	time?: number | undefined,
	event?: string | undefined,
	alarmText?: string | undefined,
	alarmNum?: number | undefined
};
	["ShopLayoutLoadListGeneral"]: {
		columnNames?: Array<ModelTypes["SHARED_LoadColumn"] | undefined> | undefined,
	workplaceGeneral?: Array<ModelTypes["SHARED_Workplace"] | undefined> | undefined,
	parts?: Array<ModelTypes["SHARED_PartInfo"] | undefined> | undefined
};
	["ShopLayoutRecipeList"]: {
		workplaceId?: number | undefined,
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["Query"]: {
		shopLayoutView?: Array<ModelTypes["ShopLayoutView"] | undefined> | undefined,
	updateShopLayoutValues?: Array<ModelTypes["ShopLayoutViewData"] | undefined> | undefined,
	shopLayoutAlarms?: Array<ModelTypes["ShopLayoutAlarm"] | undefined> | undefined,
	shopLayoutAlarmsSearch?: Array<ModelTypes["ShopLayoutAlarmSearch"] | undefined> | undefined,
	shopLayoutLoadList?: Array<ModelTypes["SHARED_Load"] | undefined> | undefined,
	shopLayoutLoadListGeneral?: ModelTypes["ShopLayoutLoadListGeneral"] | undefined,
	shopLayoutRecipeList?: Array<ModelTypes["ShopLayoutRecipeList"] | undefined> | undefined,
	furnaceView?: ModelTypes["FurnaceView"] | undefined,
	furnaceViewAlarms?: Array<ModelTypes["FurnaceViewAlarm"] | undefined> | undefined,
	furnaceViewAlarmsSearch?: Array<ModelTypes["FurnaceViewAlarmSearch"] | undefined> | undefined,
	updateFurnaceViews?: Array<ModelTypes["updateFurnaceViewsResult"] | undefined> | undefined,
	furnaceChartUdmData?: Array<ModelTypes["UDM_ChartData"] | undefined> | undefined,
	furnaceChartMetaData?: Array<ModelTypes["ChartMetaData"] | undefined> | undefined,
	furnaceChartRecorder?: Array<ModelTypes["SHARED_Chart"] | undefined> | undefined,
	furnaceLoadList?: Array<ModelTypes["SHARED_Load"] | undefined> | undefined,
	actualRecipeOfWorkplace?: ModelTypes["ActRecipeVersion"] | undefined,
	furnaceLoadListGeneral?: ModelTypes["FurnaceLoadListGeneral"] | undefined,
	furnaceRecipeList?: Array<ModelTypes["SHARED_WorkplaceRecipe"] | undefined> | undefined,
	listOfAvailableFurnaces?: Array<number | undefined> | undefined,
	furnaceChartDataFirstTimestamp?: number | undefined,
	alarmStatistics?: Array<ModelTypes["AlarmStatisticInfo"] | undefined> | undefined,
	alarmList?: Array<ModelTypes["AlarmInfo"] | undefined> | undefined,
	alarmWorkplaces?: Array<ModelTypes["WorkplaceInfo"] | undefined> | undefined,
	manageUsage?: Array<ModelTypes["Usage"] | undefined> | undefined,
	furnaceBookAlarms?: Array<ModelTypes["FurnaceBookAlarm"] | undefined> | undefined,
	furnaceBookFilters?: ModelTypes["FurnaceBookFilters"] | undefined,
	getImages?: Array<ModelTypes["Image"] | undefined> | undefined,
	profileChart?: ModelTypes["ProfileChart"] | undefined,
	furnaceBook?: Array<ModelTypes["ProductionLog"] | undefined> | undefined,
	productionLogsDummyCharts?: Array<ModelTypes["SHARED_Chart"] | undefined> | undefined,
	productionLogsChartRecorder?: Array<ModelTypes["SHARED_Chart"] | undefined> | undefined,
	chargeConsumption?: ModelTypes["ChargeConsumption"] | undefined,
	usage?: Array<ModelTypes["UsageEntry"] | undefined> | undefined,
	utilization?: ModelTypes["Utilization"] | undefined,
	cptEntries?: Array<ModelTypes["CptEntry"] | undefined> | undefined,
	recipeOfLoad?: ModelTypes["RecipeROL"] | undefined,
	partInfo?: ModelTypes["Part"] | undefined,
	partsStatistics?: Array<ModelTypes["PartsStatistics"] | undefined> | undefined,
	productionLogsOperationList?: Array<ModelTypes["SHARED_Operation"] | undefined> | undefined,
	failureReasonAdministration?: Array<ModelTypes["FailureReason"] | undefined> | undefined,
	fixtureAdministration?: Array<ModelTypes["Fixture"] | undefined> | undefined,
	fixtureTypes?: Array<ModelTypes["FixtureType"] | undefined> | undefined,
	customerSpecificData?: Array<ModelTypes["CustomerSpecificData"] | undefined> | undefined,
	engineeringUnitList?: Array<ModelTypes["EngineeringUnit"] | undefined> | undefined,
	userUnitsList?: Array<ModelTypes["UserUnits"] | undefined> | undefined,
	customerMaterialList?: Array<ModelTypes["SHARED_OLD_CustomerMaterial"] | undefined> | undefined,
	materialGroup?: Array<ModelTypes["OLD_MaterialGroup"] | undefined> | undefined,
	normMaterialList?: Array<ModelTypes["OLD_NormMaterial"] | undefined> | undefined,
	parts?: Array<ModelTypes["SHARED_PartInfo"] | undefined> | undefined,
	partManagement?: ModelTypes["PartManagementInfo"] | undefined,
	partsWorkplaceRecipeList?: Array<ModelTypes["SHARED_WorkplaceRecipe"] | undefined> | undefined,
	testPlanList?: Array<ModelTypes["TestPlan"] | undefined> | undefined,
	toolingList?: Array<ModelTypes["Tooling"] | undefined> | undefined,
	partsCustomerMaterialList?: Array<ModelTypes["SHARED_OLD_CustomerMaterial"] | undefined> | undefined,
	recipeList?: Array<ModelTypes["RecipeListItem"] | undefined> | undefined,
	recipeItem?: Array<ModelTypes["RecipeItemVersion"] | undefined> | undefined,
	getRecipeMetadata?: ModelTypes["RecipeMetadata"] | undefined,
	peAttributeType?: Array<ModelTypes["PeAttributeType"] | undefined> | undefined,
	peAttributeTypePhenNoList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	peProcessType?: Array<ModelTypes["PeProcessType"] | undefined> | undefined,
	peProcessTypePhenNoList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	physicalUnit?: Array<ModelTypes["PhysicalUnit"] | undefined> | undefined,
	physicalUnitMetadata?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	workplaceTypeList?: Array<ModelTypes["SHARED_WorkplaceType"] | undefined> | undefined,
	backgroundProcessList?: Array<ModelTypes["BackgroundProcess"] | undefined> | undefined,
	getBackgroundProcessStateList?: Array<string | undefined> | undefined,
	workplaceList?: Array<ModelTypes["SHARED_Workplace"] | undefined> | undefined,
	workplaceDataGeneralList?: Array<ModelTypes["SHARED_Workplace"] | undefined> | undefined,
	workplaceUseModeList?: Array<ModelTypes["SHARED_UseMode"] | undefined> | undefined,
	workplaceDataWpTypeList?: Array<ModelTypes["SHARED_WorkplaceType"] | undefined> | undefined,
	operationAdministrationOperationList?: Array<ModelTypes["Operation"] | undefined> | undefined,
	operationTypeList?: Array<ModelTypes["OperationType"] | undefined> | undefined,
	operationPartList?: Array<ModelTypes["OperationPart"] | undefined> | undefined,
	operationProcessList?: Array<ModelTypes["OperationProcess"] | undefined> | undefined,
	operationMetadata?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	alarmAdministration?: Array<ModelTypes["Alarm"] | undefined> | undefined,
	alarmAdministrationMetadata?: ModelTypes["AlarmAdministrationMetadata"] | undefined,
	boxAdministration?: Array<ModelTypes["Box"] | undefined> | undefined,
	boxMetadata?: Array<ModelTypes["ColorBox"] | undefined> | undefined,
	colorAdministration?: Array<ModelTypes["Color"] | undefined> | undefined,
	languageList?: Array<ModelTypes["LanguageInfo"] | undefined> | undefined,
	externalizeTranslation?: ModelTypes["ExternalizeTranslationConfigValue"] | undefined,
	dictionaryAdministration?: Array<ModelTypes["DictionaryItem"] | undefined> | undefined,
	smsAdministration?: Array<ModelTypes["SmsSettings"] | undefined> | undefined,
	systemTaskAdministration?: Array<ModelTypes["Task"] | undefined> | undefined,
	clientAccessUserList?: Array<ModelTypes["SHARED_User"] | undefined> | undefined,
	clientAccessClientList?: Array<ModelTypes["SHARED_Client"] | undefined> | undefined,
	terminalTypeList?: Array<ModelTypes["TerminalType"] | undefined> | undefined,
	terminalTypeMetadata?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	terminalClientList?: Array<ModelTypes["TerminalClient"] | undefined> | undefined,
	terminalClientMetadata?: ModelTypes["TerminalClientMetadata"] | undefined,
	applicationGroup?: Array<ModelTypes["ApplicationGroup"] | undefined> | undefined,
	applicationList?: Array<ModelTypes["Application"] | undefined> | undefined,
	applicationMetadata?: ModelTypes["ApplicationMetadata"] | undefined,
	processFlowList?: Array<ModelTypes["ProcessFlow"] | undefined> | undefined,
	processFlowStepList?: Array<ModelTypes["ProcessFlowStep"] | undefined> | undefined,
	operationWorkplaceRecipeList?: Array<ModelTypes["RecipeMenuItem"] | undefined> | undefined,
	processFlowPartComparison?: Array<ModelTypes["ProcessFlowPartComparisonItem"] | undefined> | undefined,
	processFlowOperationList?: Array<ModelTypes["SHARED_Operation"] | undefined> | undefined,
	operationWorkplaceList?: Array<ModelTypes["SHARED_Workplace"] | undefined> | undefined,
	userAccessList?: Array<ModelTypes["UserAccess"] | undefined> | undefined,
	userGroup?: Array<ModelTypes["UserGroup"] | undefined> | undefined,
	userList?: Array<ModelTypes["SHARED_User"] | undefined> | undefined,
	menuRecipes?: Array<ModelTypes["TO_REMOVE_RecipeMenuItem"] | undefined> | undefined,
	shopLayoutMenuItems?: Array<ModelTypes["TO_REMOVE_ShopLayoutMenuItem"] | undefined> | undefined,
	workplaceGeneralList?: Array<ModelTypes["SHARED_Workplace"] | undefined> | undefined,
	loadColumnList?: Array<ModelTypes["SHARED_LoadColumn"] | undefined> | undefined,
	loadList?: Array<ModelTypes["SHARED_Load"] | undefined> | undefined,
	chartRecorder?: Array<ModelTypes["SHARED_Chart"] | undefined> | undefined,
	partList?: Array<ModelTypes["SHARED_PartInfo"] | undefined> | undefined,
	partWorkplaceRecipeList?: Array<ModelTypes["SHARED_WorkplaceRecipe"] | undefined> | undefined,
	clientList?: Array<ModelTypes["SHARED_Client"] | undefined> | undefined,
	operationList?: Array<ModelTypes["SHARED_Operation"] | undefined> | undefined
};
	["Mutation"]: {
		shopLayoutAcknowledgeAlarms?: Array<ModelTypes["ShopLayoutAlarm"] | undefined> | undefined,
	furnaceAcknowledgeAlarms?: Array<ModelTypes["FurnaceViewAlarm"] | undefined> | undefined,
	setFurnaceEditBoxValues?: ModelTypes["SetFurnaceEditBoxValuesResult"] | undefined,
	setFurnaceButtonValue?: ModelTypes["ButtonValueResult"] | undefined,
	createUsage?: ModelTypes["UsageResult"] | undefined,
	updateUsage?: ModelTypes["UsageResult"] | undefined,
	deleteUsage?: ModelTypes["UsageResult"] | undefined,
	cloneUsage?: ModelTypes["UsageResult"] | undefined,
	deleteImage?: boolean | undefined,
	createCustomerSpecificData?: ModelTypes["CustomerSpecificDataResult"] | undefined,
	editCustomerSpecificData?: ModelTypes["CustomerSpecificDataResult"] | undefined,
	deleteCustomerSpecificData?: ModelTypes["CustomerSpecificDataResult"] | undefined,
	deleteRecipeVersions?: boolean | undefined,
	cloneRecipeAndSaveAs?: ModelTypes["CloneRecipeAndSaveAsResult"] | undefined,
	saveNewRecipe?: ModelTypes["SaveRecipeResult"] | undefined,
	saveEditedRecipeVersion?: ModelTypes["SaveRecipeResult"] | undefined,
	createPeAttributeType?: ModelTypes["PeAttributeTypeResult"] | undefined,
	editPeAttributeType?: ModelTypes["PeAttributeTypeResult"] | undefined,
	deletePeAttributeType?: ModelTypes["PeAttributeTypeResult"] | undefined,
	createPeProcessType?: ModelTypes["PeProcessTypeResult"] | undefined,
	editPeProcessType?: ModelTypes["PeProcessTypeResult"] | undefined,
	deletePeProcessType?: ModelTypes["PeProcessTypeResult"] | undefined,
	createPhysicalUnit?: ModelTypes["PhysicalUnitResult"] | undefined,
	editPhysicalUnit?: ModelTypes["PhysicalUnitResult"] | undefined,
	deletePhysicalUnit?: ModelTypes["PhysicalUnitResult"] | undefined,
	createWorkplaceType?: ModelTypes["WorkplaceTypeResult"] | undefined,
	updateWorkplaceType?: ModelTypes["WorkplaceTypeResult"] | undefined,
	deleteWorkplaceType?: ModelTypes["WorkplaceTypeResult"] | undefined,
	cloneWorkplaceType?: ModelTypes["WorkplaceTypeResult"] | undefined,
	deleteBackgroundProcess?: ModelTypes["ProcessResult"] | undefined,
	cloneAndSaveAsBackgroundProcess?: ModelTypes["ProcessResult"] | undefined,
	editBackgroundProcess?: ModelTypes["ProcessResult"] | undefined,
	createNewBackgroundProcess?: ModelTypes["ProcessResult"] | undefined,
	createWorkplace?: ModelTypes["WorkplaceResult"] | undefined,
	updateWorkplace?: ModelTypes["WorkplaceResult"] | undefined,
	deleteWorkplace?: ModelTypes["WorkplaceResult"] | undefined,
	cloneWorkplace?: ModelTypes["WorkplaceResult"] | undefined,
	createOperation?: ModelTypes["OperationResult"] | undefined,
	editOperation?: ModelTypes["OperationResult"] | undefined,
	deleteOperation?: ModelTypes["OperationResult"] | undefined,
	cloneAndSaveAsAlarm?: ModelTypes["AlarmResult"] | undefined,
	createNewAlarm?: ModelTypes["AlarmResult"] | undefined,
	editAlarm?: ModelTypes["AlarmResult"] | undefined,
	deleteAlarm?: ModelTypes["AlarmResult"] | undefined,
	deleteSelectedAlarmsForFurnace?: Array<ModelTypes["Alarm"] | undefined> | undefined,
	createBox?: ModelTypes["BoxResult"] | undefined,
	editBox?: ModelTypes["BoxResult"] | undefined,
	deleteBox?: ModelTypes["BoxResult"] | undefined,
	createColor?: ModelTypes["ColorResult"] | undefined,
	editColor?: ModelTypes["ColorResult"] | undefined,
	deleteColor?: ModelTypes["ColorResult"] | undefined,
	createTask?: ModelTypes["TaskResult"] | undefined,
	updateTask?: ModelTypes["TaskResult"] | undefined,
	deleteTask?: ModelTypes["TaskResult"] | undefined,
	cloneTask?: ModelTypes["TaskResult"] | undefined,
	updateClientAccessClientList?: ModelTypes["updateClientAccessResult"] | undefined,
	createTerminalType?: ModelTypes["TerminalTypeResult"] | undefined,
	editTerminalType?: ModelTypes["TerminalTypeResult"] | undefined,
	deleteTerminalType?: ModelTypes["TerminalTypeResult"] | undefined,
	createTerminalClient?: ModelTypes["TerminalClientResult"] | undefined,
	editTerminalClient?: ModelTypes["TerminalClientResult"] | undefined,
	deleteTerminalClient?: ModelTypes["TerminalClientResult"] | undefined,
	createApplicationGroup?: ModelTypes["ApplicationGroupResult"] | undefined,
	editApplicationGroup?: ModelTypes["ApplicationGroupResult"] | undefined,
	deleteApplicationGroup?: ModelTypes["ApplicationGroupResult"] | undefined,
	createApplication?: ModelTypes["ApplicationResult"] | undefined,
	editApplication?: ModelTypes["ApplicationResult"] | undefined,
	deleteApplication?: ModelTypes["ApplicationResult"] | undefined,
	updateUserAccess?: ModelTypes["UserAccess"] | undefined,
	createUserGroup?: ModelTypes["UserGroup"] | undefined,
	updateUserGroup?: ModelTypes["UserGroup"] | undefined,
	deleteUserGroup?: boolean | undefined,
	createUser?: ModelTypes["SHARED_User"] | undefined,
	updateUser?: ModelTypes["SHARED_User"] | undefined,
	deleteUser?: boolean | undefined
};
	["HourInfo"]: {
		id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	fontSize?: number | undefined,
	text?: string | undefined,
	hourType?: number | undefined,
	zoneId?: number | undefined,
	time?: number | undefined,
	colorIndex?: number | undefined,
	colorBG?: number | undefined
};
	["LoadInfo"]: {
		id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	fontSize?: number | undefined,
	text?: string | undefined,
	time?: number | undefined,
	infoType?: number | undefined,
	colorIndex?: number | undefined,
	colorBG?: number | undefined
};
	["Button"]: {
		id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	userInputId?: number | undefined,
	name?: string | undefined,
	mode?: number | undefined,
	userLevel?: number | undefined,
	btnText0?: string | undefined,
	btnText1?: string | undefined,
	helpText0?: string | undefined,
	helpText1?: string | undefined,
	value?: number | undefined
};
	["furnaceViewButton"]: {
		name?: string | undefined,
	id?: number | undefined,
	mode?: number | undefined,
	userLevel?: number | undefined,
	btnText0?: string | undefined,
	btnText1?: string | undefined,
	helpText0?: string | undefined,
	helpText1?: string | undefined
};
	["FurnaceViewComponents"]: {
		id?: number | undefined,
	name?: string | undefined,
	background?: ModelTypes["SHARED_BackgroundView"] | undefined,
	text?: Array<ModelTypes["SHARED_TextView"] | undefined> | undefined,
	value?: Array<ModelTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	hourInfo?: Array<ModelTypes["HourInfo"] | undefined> | undefined,
	loadInfo?: Array<ModelTypes["LoadInfo"] | undefined> | undefined,
	loadButton?: Array<ModelTypes["SHARED_LoadButton"] | undefined> | undefined,
	bitmap?: Array<ModelTypes["SHARED_Bitmap"] | undefined> | undefined,
	label?: Array<ModelTypes["SHARED_Label"] | undefined> | undefined,
	labelPlus?: Array<ModelTypes["SHARED_LabelPlus"] | undefined> | undefined,
	rectangle?: Array<ModelTypes["SHARED_Rectangle"] | undefined> | undefined,
	button?: Array<ModelTypes["Button"] | undefined> | undefined,
	gif?: Array<ModelTypes["SHARED_Gif"] | undefined> | undefined,
	movingPic?: Array<ModelTypes["SHARED_MovingPic"] | undefined> | undefined,
	place?: Array<ModelTypes["SHARED_Place"] | undefined> | undefined,
	progressBar?: Array<ModelTypes["SHARED_ProgressBar"] | undefined> | undefined,
	editBox?: Array<ModelTypes["SHARED_EditBox"] | undefined> | undefined,
	line?: Array<ModelTypes["SHARED_Line"] | undefined> | undefined,
	windowSizeId?: number | undefined
};
	["FurnaceValueViewComponent"]: {
		id?: number | undefined,
	name?: string | undefined,
	status?: number | undefined,
	value?: Array<ModelTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	hourInfo?: Array<ModelTypes["HourInfo"] | undefined> | undefined,
	loadInfo?: Array<ModelTypes["LoadInfo"] | undefined> | undefined,
	place?: Array<ModelTypes["SHARED_Place"] | undefined> | undefined,
	bitmap?: Array<ModelTypes["SHARED_Bitmap"] | undefined> | undefined,
	gif?: Array<ModelTypes["SHARED_Gif"] | undefined> | undefined,
	movingPic?: Array<ModelTypes["SHARED_MovingPic"] | undefined> | undefined,
	editBox?: Array<ModelTypes["SHARED_EditBox"] | undefined> | undefined,
	labelPlus?: Array<ModelTypes["SHARED_LabelPlus"] | undefined> | undefined,
	label?: Array<ModelTypes["SHARED_Label"] | undefined> | undefined,
	progressBar?: Array<ModelTypes["SHARED_ProgressBar"] | undefined> | undefined,
	loadButton?: Array<ModelTypes["SHARED_LoadButton"] | undefined> | undefined,
	rectangle?: Array<ModelTypes["SHARED_Rectangle"] | undefined> | undefined,
	button?: Array<ModelTypes["Button"] | undefined> | undefined,
	line?: Array<ModelTypes["SHARED_Line"] | undefined> | undefined
};
	["updateFurnaceViewsResult"]: {
		workplaceId?: number | undefined,
	state?: string | undefined,
	hold?: number | undefined,
	remote?: number | undefined,
	connected?: number | undefined,
	updateTime?: number | undefined,
	furnaceViews?: Array<ModelTypes["FurnaceValueViewComponent"] | undefined> | undefined,
	recipeHeader?: ModelTypes["SHARED_RecipeShortHeader"] | undefined
};
	["SystemColor"]: {
		id?: number | undefined,
	r?: number | undefined,
	g?: number | undefined,
	b?: number | undefined
};
	["FurnaceView"]: {
		workplaceId?: number | undefined,
	state?: string | undefined,
	hold?: number | undefined,
	remote?: number | undefined,
	connected?: number | undefined,
	updateTime?: number | undefined,
	colors?: Array<ModelTypes["SystemColor"] | undefined> | undefined,
	furnaceViews?: Array<ModelTypes["FurnaceViewComponents"] | undefined> | undefined,
	recipeHeader?: ModelTypes["SHARED_RecipeShortHeader"] | undefined
};
	["FurnaceViewAlarm"]: {
		alarmId?: number | undefined,
	workplaceName?: string | undefined,
	startTime?: number | undefined,
	acknowledgeTime?: number | undefined,
	endTime?: number | undefined,
	priority?: string | undefined,
	alarmMessage?: string | undefined,
	gate?: number | undefined,
	alarmNum?: number | undefined,
	byte?: number | undefined,
	bit?: number | undefined
};
	["FurnaceViewAlarmSearch"]: {
		workplaceName?: string | undefined,
	time?: number | undefined,
	event?: string | undefined,
	alarmText?: string | undefined,
	alarmNum?: number | undefined
};
	["ActRecipeHeader"]: {
		id?: number | undefined,
	name?: string | undefined,
	recipePrefix?: string | undefined,
	description?: string | undefined,
	comment?: string | undefined,
	version?: number | undefined,
	processOption?: ModelTypes["SHARED_OptionItem"] | undefined,
	processType?: number | undefined,
	processDepth?: number | undefined,
	processDepthAt?: number | undefined,
	correction?: boolean | undefined,
	diff?: boolean | undefined,
	loadIdentifier?: string | undefined,
	loadName?: string | undefined,
	actualStep?: number | undefined,
	startingTime?: number | undefined,
	user?: ModelTypes["SHARED_OptionItem"] | undefined
};
	["ActRecipePositionHeader"]: {
		positionNo?: number | undefined,
	chamberOption?: ModelTypes["SHARED_OptionItem"] | undefined,
	chamberOperationOption?: ModelTypes["SHARED_OptionItem"] | undefined,
	description?: string | undefined,
	afterTimeInMin?: number | undefined,
	estimatedDurationInMin?: number | undefined,
	actualDurationInMin?: number | undefined,
	afterOption?: ModelTypes["SHARED_OptionItem"] | undefined
};
	["ActSetPoint"]: {
		userComponentId?: number | undefined,
	valueType?: number | undefined,
	meaning?: number | undefined,
	name?: string | undefined,
	control?: ModelTypes["SHARED_OptionItem"] | undefined,
	value?: number | undefined,
	unit?: string | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	actualValue?: number | undefined,
	endControl?: ModelTypes["SHARED_OptionItem"] | undefined,
	endValue?: number | undefined
};
	["ActRecipePosition"]: {
		header?: ModelTypes["ActRecipePositionHeader"] | undefined,
	setpoints?: Array<ModelTypes["ActSetPoint"] | undefined> | undefined,
	digitalOutputList?: Array<ModelTypes["SHARED_DigitalOutput"] | undefined> | undefined,
	digitalOutputs?: string | undefined,
	endAfter?: string | undefined
};
	["ActRecipeVersion"]: {
		header?: ModelTypes["ActRecipeHeader"] | undefined,
	positions?: Array<ModelTypes["ActRecipePosition"] | undefined> | undefined,
	spEntries?: ModelTypes["SHARED_SpEntries"] | undefined,
	material?: ModelTypes["SHARED_Material"] | undefined,
	correctionProfile?: Array<number | undefined> | undefined
};
	["FurnaceViewError"]: {
		code?: string | undefined,
	message?: string | undefined,
	detail?: string | undefined
};
	["SetFurnaceEditBoxValuesResult"]: {
		isSuccess?: boolean | undefined,
	error?: ModelTypes["FurnaceViewError"] | undefined
};
	["EditBoxValueInput"]: {
	componentTypeId: number,
	userInputId: number,
	outputId: number,
	value: number
};
	["FurnaceLoadListGeneral"]: {
		columnNames?: Array<ModelTypes["SHARED_LoadColumn"] | undefined> | undefined,
	parts?: Array<ModelTypes["SHARED_PartInfo"] | undefined> | undefined
};
	["ButtonValueResult"]: {
		value?: number | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["FurnaceViewError"] | undefined
};
	["ButtonInput"]: {
	userInputId: number,
	value: number
};
	["AlarmEvent"]: {
		event?: string | undefined,
	time?: number | undefined
};
	["AlarmStatisticInfo"]: {
		alarmNo?: number | undefined,
	description?: string | undefined,
	quantity?: number | undefined,
	durationInSec?: number | undefined,
	quantityPercentage?: number | undefined,
	durationPercentage?: number | undefined,
	events?: Array<ModelTypes["AlarmEvent"] | undefined> | undefined
};
	["AlarmInfo"]: {
		alarmNo?: number | undefined,
	alarmSeverity?: number | undefined,
	alarmText?: string | undefined
};
	["WorkplaceInfo"]: {
		id?: number | undefined,
	description?: string | undefined
};
	["Usage"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	unit?: string | undefined,
	divisor?: number | undefined,
	summaryCounter?: boolean | undefined,
	overflow?: number | undefined,
	validSince?: number | undefined,
	pricePerUnit?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["UsageResult"]: {
		usage?: ModelTypes["Usage"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["createUsageInput"]: {
	name: string,
	description?: string | undefined,
	unit?: string | undefined,
	divisor?: number | undefined,
	summaryCounter?: boolean | undefined,
	overflow?: number | undefined,
	validSince?: number | undefined,
	pricePerUnit?: number | undefined
};
	["updateUsageInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	unit?: string | undefined,
	divisor?: number | undefined,
	summaryCounter?: boolean | undefined,
	overflow?: number | undefined,
	validSince?: number | undefined,
	pricePerUnit?: number | undefined,
	modifiedOn: number
};
	["cloneUsageInput"]: {
	name?: string | undefined,
	description?: string | undefined
};
	["SearchComponentType"]:SearchComponentType;
	["SearchFilter"]: {
		filterName?: string | undefined,
	displayName?: string | undefined,
	category?: string | undefined,
	type?: ModelTypes["SearchComponentType"] | undefined,
	value?: string | undefined,
	isChecked?: boolean | undefined
};
	["FurnaceBookFilters"]: {
		workplaces?: Array<ModelTypes["SHARED_Workplace"] | undefined> | undefined,
	filters?: Array<ModelTypes["SearchFilter"] | undefined> | undefined
};
	["HeaderLoadTreatment"]: {
		recipeName?: string | undefined,
	description?: string | undefined,
	loadIdentifier?: string | undefined
};
	["ContainerPosition"]: {
		partName?: string | undefined,
	order?: string | undefined,
	amount?: number | undefined,
	totalWeightInKg?: number | undefined
};
	["ProductionLog"]: {
		workplace?: ModelTypes["SHARED_Workplace"] | undefined,
	startingTime?: number | undefined,
	endingTime?: number | undefined,
	durationInSec?: number | undefined,
	loadId?: number | undefined,
	loadName?: string | undefined,
	alarms?: string | undefined,
	header?: ModelTypes["HeaderLoadTreatment"] | undefined,
	positions?: Array<ModelTypes["ContainerPosition"] | undefined> | undefined,
	box?: number | undefined
};
	["FurnaceBookAlarm"]: {
		messageNo?: number | undefined,
	event?: string | undefined,
	time?: number | undefined,
	text?: string | undefined
};
	["ProfileChartData"]: {
		yUnit?: string | undefined,
	data?: Array<number | undefined> | undefined
};
	["ProfileChart"]: {
		restaust?: number | undefined,
	surfC?: number | undefined,
	xUnit?: string | undefined,
	charts?: Array<ModelTypes["ProfileChartData"] | undefined> | undefined
};
	["Consumption"]: {
		media?: string | undefined,
	amount?: number | undefined,
	unit?: string | undefined,
	costs?: number | undefined
};
	["ChargeConsumption"]: {
		consumption?: Array<ModelTypes["Consumption"] | undefined> | undefined,
	totalCosts?: number | undefined,
	currency?: string | undefined
};
	["UsageEntry"]: {
		workplace?: string | undefined,
	chargesCounter?: number | undefined,
	production?: ModelTypes["ChargeConsumption"] | undefined,
	averagePerCharge?: ModelTypes["ChargeConsumption"] | undefined,
	standBy?: ModelTypes["ChargeConsumption"] | undefined,
	total?: ModelTypes["ChargeConsumption"] | undefined
};
	["UtilizationMode"]: {
	type?: string | undefined,
	value?: string | undefined
};
	["UsageValue"]: {
		unit?: string | undefined,
	value?: number | undefined
};
	["UtilizationTotal"]: {
		workplace?: string | undefined,
	numberOfLoads?: number | undefined,
	numberOfLoadsEvaluated?: number | undefined,
	loadsRatio?: ModelTypes["UsageValue"] | undefined,
	totalTime?: ModelTypes["UsageValue"] | undefined,
	productionTime?: ModelTypes["UsageValue"] | undefined,
	timeRatio?: ModelTypes["UsageValue"] | undefined,
	averageLoadingTime?: ModelTypes["UsageValue"] | undefined,
	availability?: ModelTypes["UsageValue"] | undefined,
	downtime?: ModelTypes["UsageValue"] | undefined,
	downtimeReason?: ModelTypes["UsageValue"] | undefined,
	downtimeNoReason?: ModelTypes["UsageValue"] | undefined,
	utilization?: ModelTypes["UsageValue"] | undefined,
	capacityLoss?: ModelTypes["UsageValue"] | undefined,
	kpi?: number | undefined,
	targetWeight?: ModelTypes["UsageValue"] | undefined,
	produced?: ModelTypes["UsageValue"] | undefined,
	weightRatio?: ModelTypes["UsageValue"] | undefined,
	flowRate?: ModelTypes["UsageValue"] | undefined
};
	["UtilizationPeriod"]: {
		period?: string | undefined,
	target?: ModelTypes["UsageValue"] | undefined,
	actual?: ModelTypes["UsageValue"] | undefined,
	utilizationRate?: ModelTypes["UsageValue"] | undefined
};
	["UtilizationUsage"]: {
		workplace?: string | undefined,
	loadsEvaluated?: number | undefined,
	runtime?: ModelTypes["UsageValue"] | undefined,
	weight?: ModelTypes["UsageValue"] | undefined,
	usage?: ModelTypes["UsageValue"] | undefined,
	usageLoad?: ModelTypes["UsageValue"] | undefined,
	usageRuntime?: ModelTypes["UsageValue"] | undefined
};
	["Utilization"]: {
		totalInfo?: Array<ModelTypes["UtilizationTotal"] | undefined> | undefined,
	periodInfo?: Array<ModelTypes["UtilizationPeriod"] | undefined> | undefined,
	usageInfo?: Array<ModelTypes["UtilizationUsage"] | undefined> | undefined
};
	["CptEntry"]: {
		id?: string | undefined,
	name?: string | undefined
};
	["RecipeHeaderROL"]: {
		chargeIdentifier?: string | undefined,
	recipeName?: string | undefined,
	description?: string | undefined
};
	["RecipeVersionROL"]: {
		id?: number | undefined,
	startingTime?: number | undefined,
	versionText?: string | undefined
};
	["SetpointROL"]: {
		name?: string | undefined,
	control?: string | undefined,
	value?: number | undefined,
	unit?: string | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	actualValue?: number | undefined,
	endControl?: string | undefined,
	endValue?: number | undefined
};
	["DigitalOutputROL"]: {
		name?: string | undefined,
	value?: boolean | undefined
};
	["AfterTimeROL"]: {
		activeOption?: number | undefined,
	options?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	durationInSec?: number | undefined
};
	["StepHeaderROL"]: {
		chamberOptions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	operationOptions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	description?: string | undefined,
	estimatedDurationInSec?: number | undefined,
	afterOptions?: ModelTypes["AfterTimeROL"] | undefined
};
	["RecipeStepROL"]: {
		id?: number | undefined,
	header?: ModelTypes["StepHeaderROL"] | undefined,
	chamber?: string | undefined,
	description?: string | undefined,
	startTime?: number | undefined,
	operation?: string | undefined,
	setpoints?: Array<ModelTypes["SetpointROL"] | undefined> | undefined,
	digitalOutputs?: string | undefined,
	digitalOutputList?: Array<ModelTypes["DigitalOutputROL"] | undefined> | undefined,
	endAfter?: string | undefined,
	actualDurationInMin?: number | undefined
};
	["RecipeROL"]: {
		header?: ModelTypes["RecipeHeaderROL"] | undefined,
	versions?: Array<ModelTypes["RecipeVersionROL"] | undefined> | undefined,
	steps?: Array<ModelTypes["RecipeStepROL"] | undefined> | undefined
};
	["PartHeader"]: {
		contentName?: string | undefined,
	weightInKg?: number | undefined,
	amount?: number | undefined,
	createdAt?: number | undefined,
	modifiedAt?: number | undefined
};
	["PartData"]: {
		pos?: number | undefined,
	partName?: string | undefined,
	partDescription?: string | undefined,
	amount?: number | undefined,
	weightInKg?: number | undefined,
	totalWeightInKg?: number | undefined,
	orderName?: string | undefined,
	orderRemark?: string | undefined,
	type?: string | undefined,
	customer?: string | undefined,
	intern?: number | undefined
};
	["Part"]: {
		header?: ModelTypes["PartHeader"] | undefined,
	parts?: Array<ModelTypes["PartData"] | undefined> | undefined
};
	["PartsStatistics"]: {
		order?: string | undefined,
	name?: string | undefined,
	description?: string | undefined,
	amount?: number | undefined,
	weight?: number | undefined
};
	["Image"]: {
		no?: number | undefined,
	description?: string | undefined,
	dataBase64?: string | undefined
};
	["Filter"]: {
	orderName?: string | undefined,
	partDescription?: string | undefined,
	recipeName?: string | undefined,
	seriesNumber?: string | undefined,
	material?: string | undefined,
	loadIdentifier?: string | undefined,
	sample?: string | undefined,
	chdFrom?: number | undefined,
	chdTo?: number | undefined,
	trayName?: string | undefined,
	partName?: string | undefined,
	loadName?: string | undefined,
	suchfeld?: string | undefined,
	aufgepackt?: string | undefined,
	abgepackt?: string | undefined,
	cellLoadName?: string | undefined,
	processFlowName?: string | undefined,
	customer?: string | undefined,
	sampleCheckbox?: boolean | undefined,
	series?: boolean | undefined,
	addWork?: boolean | undefined,
	own?: boolean | undefined,
	foreign?: boolean | undefined,
	earnings?: boolean | undefined,
	withAlarms?: boolean | undefined,
	withOrderPartInfo?: boolean | undefined
};
	["DeleteImageInput"]: {
	loadName: string,
	no: number
};
	["FailureReason"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	type?: string | undefined,
	comment?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["Fixture"]: {
		id?: number | undefined,
	no?: number | undefined,
	fixture?: string | undefined
};
	["FixtureType"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	typeOfWorkplace?: number | undefined,
	workplace?: number | undefined,
	maximumHeight?: number | undefined,
	maximumWidth?: number | undefined,
	maximumDepth?: number | undefined,
	maximumWeight?: number | undefined,
	minimumWeight?: number | undefined,
	loadWeight?: number | undefined,
	ownWeight?: number | undefined,
	maxNumOfLoads?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["CustomerSpecificData"]: {
		id?: number | undefined,
	name?: string | undefined,
	floatValue?: number | undefined,
	longValue?: number | undefined,
	characterValue?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["CustomerSpecificDataResult"]: {
		isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined,
	customerSpecificData?: ModelTypes["CustomerSpecificData"] | undefined
};
	["CreateCustomerSpecificDataInput"]: {
	name?: string | undefined,
	floatValue?: number | undefined,
	longValue?: number | undefined,
	characterValue?: string | undefined
};
	["EditCustomerSpecificDataInput"]: {
	id: number,
	modifiedOn: number,
	name?: string | undefined,
	floatValue?: number | undefined,
	longValue?: number | undefined,
	characterValue?: string | undefined
};
	["DeleteCustomerSpecificDataInput"]: {
	id: number,
	modifiedOn: number
};
	["EngineeringUnit"]: {
		id?: number | undefined,
	shortDescription?: string | undefined,
	descripton?: string | undefined,
	physicalUnit?: string | undefined,
	unit?: string | undefined,
	peToEuType?: number | undefined,
	peToEu?: string | undefined,
	euToPeType?: number | undefined,
	euToPeString?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["UnitValue"]: {
		unitValue?: string | undefined,
	default?: boolean | undefined
};
	["Unit"]: {
		name?: string | undefined,
	values?: Array<ModelTypes["UnitValue"] | undefined> | undefined
};
	["UserUnits"]: {
		user?: string | undefined,
	units?: Array<ModelTypes["Unit"] | undefined> | undefined
};
	["OLD_MaterialGroup"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["OLD_NormMaterial"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	groupId?: number | undefined,
	groupName?: string | undefined,
	elementValuesFrom?: ModelTypes["SHARED_ElementValueList"] | undefined,
	elementValuesTo?: ModelTypes["SHARED_ElementValueList"] | undefined,
	averageAlloyFactor?: number | undefined,
	carbideLimit?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TestPlan"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["Setpoints"]: {
		recipeId?: number | undefined,
	recipeName?: string | undefined,
	numberOfPartsPerTray?: number | undefined
};
	["ToolingInfo"]: {
		id?: number | undefined,
	name?: string | undefined,
	amount?: number | undefined
};
	["Tooling"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["LoadInformation"]: {
		packInformation?: string | undefined,
	image1Base64?: string | undefined,
	image2Base64?: string | undefined,
	image3Base64?: string | undefined,
	toolings?: Array<ModelTypes["ToolingInfo"] | undefined> | undefined
};
	["PartWorkplaceInfo"]: {
		id?: number | undefined,
	name?: string | undefined,
	setpoints?: ModelTypes["Setpoints"] | undefined,
	loadInformation?: ModelTypes["LoadInformation"] | undefined
};
	["PartManagementInfo"]: {
		partInfo?: ModelTypes["SHARED_PartInfo"] | undefined,
	partWorkplaceList?: Array<ModelTypes["PartWorkplaceInfo"] | undefined> | undefined
};
	["SetPointItem"]: {
		userComponentId?: number | undefined,
	relevant?: number | undefined,
	valueType?: number | undefined,
	meaning?: number | undefined,
	name?: string | undefined,
	control?: ModelTypes["SHARED_OptionItem"] | undefined,
	value?: number | undefined,
	unit?: string | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	endControl?: ModelTypes["SHARED_OptionItem"] | undefined,
	endValue?: number | undefined
};
	["RecipeItemPositionHeader"]: {
		positionNo?: number | undefined,
	chamberOption?: ModelTypes["SHARED_OptionItem"] | undefined,
	chamberOperationOption?: ModelTypes["SHARED_OptionItem"] | undefined,
	description?: string | undefined,
	afterTimeInMin?: number | undefined,
	estimatedDurationInMin?: number | undefined,
	afterOption?: ModelTypes["SHARED_OptionItem"] | undefined
};
	["RecipeItemPosition"]: {
		header?: ModelTypes["RecipeItemPositionHeader"] | undefined,
	setpoints?: Array<ModelTypes["SetPointItem"] | undefined> | undefined,
	digitalOutputList?: Array<ModelTypes["SHARED_DigitalOutput"] | undefined> | undefined,
	digitalOutputs?: string | undefined,
	endAfter?: string | undefined
};
	["RecipeItemHeader"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	comment?: string | undefined,
	version?: number | undefined,
	processOption?: ModelTypes["SHARED_OptionItem"] | undefined,
	processType?: number | undefined,
	processDepth?: number | undefined,
	processDepthAt?: number | undefined,
	correction?: boolean | undefined,
	diff?: boolean | undefined
};
	["RecipeItemVersion"]: {
		header?: ModelTypes["RecipeItemHeader"] | undefined,
	positions?: Array<ModelTypes["RecipeItemPosition"] | undefined> | undefined,
	spEntries?: ModelTypes["SHARED_SpEntries"] | undefined,
	material?: ModelTypes["SHARED_Material"] | undefined,
	correctionProfile?: Array<number | undefined> | undefined
};
	["RecipeListItem"]: {
		id?: number | undefined,
	recipe?: string | undefined,
	description?: string | undefined
};
	["CloneRecipeAndSaveAsResult"]: {
		recipeVersions?: Array<ModelTypes["RecipeItemVersion"] | undefined> | undefined,
	isSaved?: boolean | undefined,
	comment?: string | undefined
};
	["SaveRecipeResult"]: {
		recipeVersion?: ModelTypes["RecipeItemVersion"] | undefined,
	isSaved?: boolean | undefined,
	comment?: string | undefined
};
	["MetaDataSetPoint"]: {
		name?: string | undefined,
	userComponentId?: number | undefined,
	meaning?: number | undefined,
	relevant?: number | undefined,
	unit?: string | undefined,
	valueType?: number | undefined
};
	["MetaDataMethods"]: {
		setPoints?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalInputs?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalOutputs?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["OperationOptions"]: {
		id?: number | undefined,
	value?: string | undefined,
	validVarIds?: Array<number | undefined> | undefined
};
	["ControlMetadata"]: {
		setPoints?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalInputs?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalOutputs?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["OperationMetadata"]: {
		name?: string | undefined,
	description?: string | undefined,
	setPoints?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalInputs?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalOutputs?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["MetaDataChamberOption"]: {
		chamberId?: number | undefined,
	name?: string | undefined,
	recipeControl?: ModelTypes["ControlMetadata"] | undefined,
	operationControl?: Array<ModelTypes["OperationMetadata"] | undefined> | undefined,
	operationOptions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	setPoints?: Array<ModelTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["RecipeMetadata"]: {
		afterOptions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	controlOptions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	endControlOptions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	chamberOptions?: Array<ModelTypes["MetaDataChamberOption"] | undefined> | undefined,
	operationProcessList?: Array<ModelTypes["SHARED_Operation"] | undefined> | undefined,
	materialList?: Array<ModelTypes["SHARED_OLD_CustomerMaterial"] | undefined> | undefined
};
	["RecipeVersionsHeaderInput"]: {
	name: string,
	pgmPrefix: string,
	versionIds: Array<string | undefined>
};
	["CloneRecipeAndSaveAsInput"]: {
	oldRecipeName: string,
	newRecipeName: string,
	description: string,
	recipePrefix: string
};
	["SetPointInput"]: {
	meaning: number,
	userComponentId: number,
	relevant: number,
	value?: number | undefined,
	valueType?: number | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	controlOptionNo?: number | undefined,
	endControlOptionNo?: number | undefined,
	endValue?: number | undefined
};
	["PositionHeaderInput"]: {
	positionNo: number,
	afterTimeOptionNo: number,
	afterTimeInMin?: number | undefined,
	estimatedTimeInMin?: number | undefined,
	chamberId: number,
	description: string,
	operationId: number,
	endOr?: number | undefined,
	valueDepth?: number | undefined
};
	["RecipePositionInput"]: {
	header: ModelTypes["PositionHeaderInput"],
	setPoints: Array<ModelTypes["SetPointInput"] | undefined>
};
	["SpEntriesInput"]: {
	furnaceSwitchEntry?: Array<string | undefined> | undefined,
	recipeDescription2?: string | undefined,
	recipeDescription3?: string | undefined,
	recipeDescription4?: string | undefined,
	gasEntryValues?: Array<string | undefined> | undefined
};
	["AnalysisInput"]: {
	c?: number | undefined,
	si?: number | undefined,
	mn?: number | undefined,
	cr?: number | undefined,
	ni?: number | undefined,
	v?: number | undefined,
	al?: number | undefined,
	cu?: number | undefined,
	p?: number | undefined,
	mo?: number | undefined,
	s?: number | undefined,
	b?: number | undefined,
	ti?: number | undefined
};
	["MaterialInput"]: {
	carbonContent?: number | undefined,
	alloyFactor?: number | undefined,
	legFactorN?: number | undefined,
	carbideLimit?: number | undefined,
	temperature?: number | undefined,
	specialNitrides?: number | undefined,
	analysis?: ModelTypes["AnalysisInput"] | undefined,
	co?: number | undefined,
	h2?: number | undefined,
	quenchingIntensity?: number | undefined,
	representativeDiameter?: number | undefined,
	requiredHardness?: number | undefined,
	grainSize?: number | undefined,
	endTemperature?: number | undefined,
	targetSurfC?: number | undefined,
	customerMaterialId?: number | undefined,
	partId?: number | undefined
};
	["RecipeHeaderInput"]: {
	recipeName: string,
	description: string,
	comment?: string | undefined,
	processId: number,
	processType: number,
	processDepth?: number | undefined,
	processDepthAt?: number | undefined,
	diff: boolean,
	correction: boolean
};
	["SaveNewRecipeInput"]: {
	recipePrefix: string,
	header: ModelTypes["RecipeHeaderInput"],
	positions: Array<ModelTypes["RecipePositionInput"] | undefined>,
	spEntries?: ModelTypes["SpEntriesInput"] | undefined,
	material: ModelTypes["MaterialInput"],
	correctionProfile?: Array<number | undefined> | undefined
};
	["saveEditedRecipeVersionInput"]: {
	recipePrefix: string,
	headId: number,
	editedVersionNo: number,
	editedRecipeName: string,
	createNewVersion: boolean,
	header: ModelTypes["RecipeHeaderInput"],
	positions: Array<ModelTypes["RecipePositionInput"] | undefined>,
	spEntries?: ModelTypes["SpEntriesInput"] | undefined,
	material: ModelTypes["MaterialInput"],
	correctionProfile?: Array<number | undefined> | undefined
};
	["PeAttributeType"]: {
		id?: number | undefined,
	peAttributeTypeId?: number | undefined,
	forDiffCalculation?: boolean | undefined,
	forNitriding?: boolean | undefined,
	hasMinimumLimit?: boolean | undefined,
	hasMaximumLimit?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["PeAttributeTypeResult"]: {
		isSuccess?: boolean | undefined,
	peAttributeType?: ModelTypes["PeAttributeType"] | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["CreatePeAttributeTypeInput"]: {
	peAttributeTypeId: number,
	forDiffCalculation: boolean,
	forNitriding: boolean,
	hasMinimumLimit: boolean,
	hasMaximumLimit: boolean
};
	["EditPeAttributeTypeInput"]: {
	id: number,
	modifiedOn: number,
	peAttributeTypeId: number,
	forDiffCalculation: boolean,
	forNitriding: boolean,
	hasMinimumLimit: boolean,
	hasMaximumLimit: boolean
};
	["DeletePeAttributeTypeInput"]: {
	id: number,
	modifiedOn: number
};
	["PeProcessType"]: {
		id?: number | undefined,
	phenNoId?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["PeProcessTypeResult"]: {
		isSuccess?: boolean | undefined,
	peProcessType?: ModelTypes["PeProcessType"] | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["CreatePeProcessTypeInput"]: {
	phenNoId: number
};
	["EditPeProcessTypeInput"]: {
	id: number,
	phenNoId: number,
	modifiedOn: number
};
	["DeletePeProcessTypeInput"]: {
	id: number,
	modifiedOn: number
};
	["PhysicalUnit"]: {
		id?: number | undefined,
	shortDescription?: string | undefined,
	description?: string | undefined,
	typeId?: number | undefined,
	unit?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["PhysicalUnitResult"]: {
		isSuccess?: boolean | undefined,
	physicalUnit?: ModelTypes["PhysicalUnit"] | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["CreatePhysicalUnitInput"]: {
	shortDescription?: string | undefined,
	description?: string | undefined,
	typeId?: number | undefined,
	unit?: string | undefined
};
	["EditPhysicalUnitInput"]: {
	id: number,
	modifiedOn: number,
	shortDescription?: string | undefined,
	description?: string | undefined,
	typeId?: number | undefined,
	unit?: string | undefined
};
	["DeletePhysicalUnitInput"]: {
	id: number,
	modifiedOn: number
};
	["updateWorkplaceTypeInput"]: {
	number: number,
	description?: string | undefined,
	prio?: number | undefined,
	modifiedOn: number
};
	["createWorkplaceTypeInput"]: {
	number: number,
	description?: string | undefined,
	prio?: number | undefined
};
	["cloneWorkplaceTypeInput"]: {
	number: string,
	description?: string | undefined
};
	["WorkplaceTypeResult"]: {
		workplaceType?: ModelTypes["SHARED_WorkplaceType"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["BackgroundProcess"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	argument?: string | undefined,
	active?: string | undefined,
	pid?: number | undefined,
	activeTime?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ProcessResult"]: {
		process?: ModelTypes["BackgroundProcess"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["DeleteProcessInput"]: {
	id: number,
	modifiedOn: number
};
	["CloneProcessInput"]: {
	id: number,
	name: string,
	description: string
};
	["EditProcessInput"]: {
	id: number,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	argument?: string | undefined,
	active?: string | undefined,
	pid?: number | undefined,
	activeTime?: number | undefined,
	modifiedOn: number
};
	["CreateNewProcessInput"]: {
	name: string,
	description: string,
	order?: number | undefined,
	argument?: string | undefined,
	active?: string | undefined,
	pid?: number | undefined,
	activeTime?: number | undefined
};
	["createWorkplaceInput"]: {
	id: string,
	name?: string | undefined,
	description?: string | undefined,
	inventoryNumber?: string | undefined,
	type?: number | undefined,
	active?: number | undefined,
	useMode?: number | undefined,
	logicType?: string | undefined,
	recipePrefix?: string | undefined,
	chamberId?: number | undefined,
	sharesController?: number | undefined,
	belongsTo?: string | undefined,
	ip?: string | undefined,
	mGuardIp?: string | undefined,
	device?: string | undefined,
	telegramSplit?: boolean | undefined,
	deactivateOperations?: boolean | undefined,
	aliveBitErrorNum?: number | undefined,
	comment?: string | undefined
};
	["updateWorkplaceInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	inventoryNumber?: string | undefined,
	type?: number | undefined,
	active?: number | undefined,
	useMode?: number | undefined,
	logicType?: string | undefined,
	recipePrefix?: string | undefined,
	chamberId?: number | undefined,
	sharesController?: number | undefined,
	belongsTo?: string | undefined,
	ip?: string | undefined,
	mGuardIp?: string | undefined,
	device?: string | undefined,
	telegramSplit?: boolean | undefined,
	deactivateOperations?: boolean | undefined,
	aliveBitErrorNum?: number | undefined,
	comment?: string | undefined,
	modifiedOn: number
};
	["cloneWorkplaceInput"]: {
	id: string,
	name?: string | undefined,
	description?: string | undefined
};
	["WorkplaceResult"]: {
		workplace?: ModelTypes["SHARED_Workplace"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["OperationType"]: {
		id?: number | undefined,
	description?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["OperationPart"]: {
		id?: number | undefined,
	description?: string | undefined,
	order?: number | undefined
};
	["OperationProcess"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	sap?: string | undefined
};
	["CreateOperationProcess"]: {
	order?: number | undefined,
	sap?: string | undefined,
	workplaceId: number
};
	["Operation"]: {
		id?: number | undefined,
	processTypeId?: number | undefined,
	name?: string | undefined,
	operationType?: string | undefined,
	comment?: string | undefined,
	order?: number | undefined,
	relevantForStat?: boolean | undefined,
	mode?: number | undefined,
	typeId?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined,
	operationProcess?: Array<ModelTypes["OperationProcess"] | undefined> | undefined,
	operationParts?: Array<ModelTypes["OperationPart"] | undefined> | undefined
};
	["CreateOperation"]: {
	processTypeId?: number | undefined,
	name: string,
	comment: string,
	order: number,
	relevantForStat: boolean,
	mode: number,
	typeId: number,
	operationProcess?: Array<ModelTypes["CreateOperationProcess"] | undefined> | undefined,
	operationParts?: Array<number | undefined> | undefined
};
	["EditOperation"]: {
	id: number,
	modifiedOn: number,
	processTypeId?: number | undefined,
	comment: string,
	order: number,
	relevantForStat: boolean,
	mode: number,
	typeId: number,
	operationProcess?: Array<ModelTypes["CreateOperationProcess"] | undefined> | undefined,
	operationParts?: Array<number | undefined> | undefined
};
	["DeleteOperation"]: {
	id: number,
	modifiedOn: number
};
	["OperationResult"]: {
		isSuccess?: boolean | undefined,
	operation?: ModelTypes["Operation"] | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["Alarm"]: {
		id?: number | undefined,
	workplace?: string | undefined,
	workplaceId?: number | undefined,
	alarm?: number | undefined,
	alarmText?: string | undefined,
	errorType?: number | undefined,
	outputType?: number | undefined,
	security?: number | undefined,
	color?: number | undefined,
	onlyForChamber?: number | undefined,
	causesATestingLoad?: boolean | undefined,
	alarmGroup?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ChambersPerWorkplace"]: {
		workplaceId?: number | undefined,
	chamberList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined
};
	["AlarmAdministrationMetadata"]: {
		workplaceList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	errorTypeList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	outputTypeList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	alarmGroupList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	importanceList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	colorList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	chambersList?: Array<ModelTypes["ChambersPerWorkplace"] | undefined> | undefined
};
	["AlarmResult"]: {
		alarm?: ModelTypes["Alarm"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["CreateNewAlarmInput"]: {
	workplaceId: number,
	alarmNo: number,
	alarmText: string,
	errorTypeId: number,
	outputTypeId: number,
	importanceId?: number | undefined,
	security: number,
	colorId: number,
	alarmGroupId: number,
	causeTestingLoad: boolean,
	onlyForChamber: number
};
	["CloneAndSaveAsInput"]: {
	id: number,
	alarmNo: number
};
	["EditAlarmInput"]: {
	id: number,
	alarmNo?: number | undefined,
	modifiedOn: number,
	workplaceId?: number | undefined,
	alarmText?: string | undefined,
	errorTypeId?: number | undefined,
	outputTypeId?: number | undefined,
	importanceId?: number | undefined,
	security?: number | undefined,
	colorId?: number | undefined,
	onlyForChamber?: number | undefined,
	alarmGroupId?: number | undefined,
	causeTestingLoad?: boolean | undefined
};
	["DeleteAlarmInput"]: {
	id: number,
	modifiedOn: number
};
	["DeleteSelectedAlarmsForFurnaceInput"]: {
	workplaceId: number,
	selectedAlarms: Array<ModelTypes["DeleteAlarmInput"] | undefined>
};
	["Box"]: {
		id?: number | undefined,
	name?: string | undefined,
	colorId?: number | undefined,
	used?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ColorBox"]: {
		id?: number | undefined,
	name?: string | undefined,
	redValue?: number | undefined,
	greenValue?: number | undefined,
	blueValue?: number | undefined
};
	["CreateBoxInput"]: {
	name: string,
	colorId: number,
	used: boolean
};
	["EditBoxInput"]: {
	id: number,
	name?: string | undefined,
	colorId?: number | undefined,
	used?: boolean | undefined,
	modifiedOn: number
};
	["DeleteBoxInput"]: {
	id: number,
	modifiedOn: number
};
	["BoxResult"]: {
		isSuccess?: boolean | undefined,
	box?: ModelTypes["Box"] | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["Color"]: {
		id?: number | undefined,
	name?: string | undefined,
	redValue?: number | undefined,
	greenValue?: number | undefined,
	blueValue?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ColorResult"]: {
		isSuccess?: boolean | undefined,
	color?: ModelTypes["Color"] | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["CreateColorInput"]: {
	name: string,
	redValue: number,
	greenValue: number,
	blueValue: number
};
	["EditColorInput"]: {
	id: number,
	modifiedOn: number,
	name?: string | undefined,
	redValue?: number | undefined,
	greenValue?: number | undefined,
	blueValue?: number | undefined
};
	["deleteColorInput"]: {
	id: number,
	modifiedOn: number
};
	["LanguageInfo"]: {
		languageId?: number | undefined,
	description?: string | undefined
};
	["TranslationInfo"]: {
		languageId?: number | undefined,
	translation?: string | undefined
};
	["DictionaryItem"]: {
		textId?: number | undefined,
	defaultText?: string | undefined,
	peProperty?: string | undefined,
	used?: boolean | undefined,
	type?: number | undefined,
	translations?: Array<ModelTypes["TranslationInfo"] | undefined> | undefined,
	customerTranslations?: Array<ModelTypes["TranslationInfo"] | undefined> | undefined
};
	["ExternalizeTranslationConfigValue"]: {
		value?: boolean | undefined
};
	["SmsSettings"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	addresseeMobilePhoneNo?: string | undefined,
	pin?: string | undefined,
	serialInterface?: string | undefined,
	testMessage?: boolean | undefined,
	active?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["Task"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	active?: boolean | undefined,
	type?: string | undefined,
	command1?: string | undefined,
	command2?: string | undefined,
	command3?: string | undefined,
	command4?: string | undefined,
	command5?: string | undefined,
	command6?: string | undefined,
	command7?: string | undefined,
	command8?: string | undefined,
	command9?: string | undefined,
	command10?: string | undefined,
	startHour?: number | undefined,
	intervalInMin?: number | undefined,
	dateLastActive?: number | undefined,
	comment?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TaskResult"]: {
		task?: ModelTypes["Task"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["createTaskInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	active?: boolean | undefined,
	type?: string | undefined,
	command1?: string | undefined,
	command2?: string | undefined,
	command3?: string | undefined,
	command4?: string | undefined,
	command5?: string | undefined,
	command6?: string | undefined,
	command7?: string | undefined,
	command8?: string | undefined,
	command9?: string | undefined,
	command10?: string | undefined,
	startHour?: number | undefined,
	intervalInMin?: number | undefined,
	dateLastActive?: number | undefined,
	comment?: string | undefined
};
	["updateTaskInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	active?: boolean | undefined,
	type?: string | undefined,
	command1?: string | undefined,
	command2?: string | undefined,
	command3?: string | undefined,
	command4?: string | undefined,
	command5?: string | undefined,
	command6?: string | undefined,
	command7?: string | undefined,
	command8?: string | undefined,
	command9?: string | undefined,
	command10?: string | undefined,
	startHour?: number | undefined,
	intervalInMin?: number | undefined,
	comment?: string | undefined,
	modifiedOn: number
};
	["cloneTaskInput"]: {
	name: string,
	description?: string | undefined,
	comment?: string | undefined
};
	["updateClientAccessClientListInput"]: {
	clientId: number,
	isActive: boolean
};
	["updateClientAccessResult"]: {
		isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["TerminalType"]: {
		id?: number | undefined,
	description?: string | undefined,
	osTypeId?: number | undefined,
	remark?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TerminalTypeResult"]: {
		isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined,
	terminalType?: ModelTypes["TerminalType"] | undefined
};
	["CreateTerminalTypeInput"]: {
	description?: string | undefined,
	osTypeId: number,
	remark?: string | undefined
};
	["EditTerminalTypeInput"]: {
	id: number,
	modifiedOn: number,
	description?: string | undefined,
	osTypeId?: number | undefined,
	remark?: string | undefined
};
	["DeleteTerminalTypeInput"]: {
	id: number,
	modifiedOn: number
};
	["TerminalClient"]: {
		id?: number | undefined,
	clientTypeId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: number | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserId?: number | undefined,
	actLoginTime?: number | undefined,
	lastUserId?: number | undefined,
	lastLoginTime?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TerminalClientResult"]: {
		isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined,
	terminalClient?: ModelTypes["TerminalClient"] | undefined
};
	["CreateTerminalClientInput"]: {
	clientTypeId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: number | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserId?: number | undefined,
	actLoginTime?: number | undefined,
	lastUserId?: number | undefined,
	lastLoginTime?: number | undefined
};
	["EditTerminalClientInput"]: {
	id: number,
	modifiedOn: number,
	clientTypeId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: number | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserId?: number | undefined,
	actLoginTime?: number | undefined,
	lastUserId?: number | undefined,
	lastLoginTime?: number | undefined
};
	["DeleteTerminalClientInput"]: {
	id: number,
	modifiedOn: number
};
	["TerminalClientMetadata"]: {
		resolutions?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	users?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	clientTypes?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined
};
	["ApplicationGroup"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ApplicationGroupResult"]: {
		applicationGroup?: ModelTypes["ApplicationGroup"] | undefined,
	isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined
};
	["CreateAppGroupInput"]: {
	name: string,
	description?: string | undefined,
	order?: number | undefined
};
	["EditAppGroupInput"]: {
	id: number,
	name: string,
	description?: string | undefined,
	order?: number | undefined,
	modifiedOn: number
};
	["DeleteAppGroupInput"]: {
	id: number,
	modifiedOn: number
};
	["Application"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	command?: string | undefined,
	parameter?: string | undefined,
	tooltip?: string | undefined,
	furnacePicture?: string | undefined,
	iconGifName?: string | undefined,
	order?: number | undefined,
	typeId?: number | undefined,
	groupId?: number | undefined,
	startRecipeProcessFlow?: boolean | undefined,
	modifyRecipeProcessFlow?: boolean | undefined,
	stopRecipeProcessFlow?: boolean | undefined,
	startCorrectionRecipe?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ApplicationResult"]: {
		isSuccess?: boolean | undefined,
	error?: ModelTypes["Error"] | undefined,
	application?: ModelTypes["Application"] | undefined
};
	["CreateApplicationInput"]: {
	name: string,
	description?: string | undefined,
	command?: string | undefined,
	parameter?: string | undefined,
	tooltip?: string | undefined,
	furnacePicture?: string | undefined,
	iconGifName?: string | undefined,
	order?: number | undefined,
	typeId?: number | undefined,
	groupId: number,
	startRecipeProcessFlow: boolean,
	modifyRecipeProcessFlow: boolean,
	stopRecipeProcessFlow: boolean,
	startCorrectionRecipe: boolean
};
	["EditApplicationInput"]: {
	id: number,
	name: string,
	description?: string | undefined,
	command?: string | undefined,
	parameter?: string | undefined,
	tooltip?: string | undefined,
	furnacePicture?: string | undefined,
	iconGifName?: string | undefined,
	order?: number | undefined,
	typeId?: number | undefined,
	groupId: number,
	startRecipeProcessFlow: boolean,
	modifyRecipeProcessFlow: boolean,
	stopRecipeProcessFlow: boolean,
	startCorrectionRecipe: boolean,
	modifiedOn: number
};
	["DeleteApplicationInput"]: {
	id: number,
	modifiedOn: number
};
	["ApplicationMetadata"]: {
		applicationGroupList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined,
	applicationTypeList?: Array<ModelTypes["SHARED_OptionItem"] | undefined> | undefined
};
	["ProcessFlow"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	comments?: Array<string | undefined> | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["RecipeMenuItem"]: {
		id?: number | undefined,
	workplaceId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	recipePrefix?: string | undefined
};
	["OperationMenuItem"]: {
		id?: number | undefined,
	name?: string | undefined,
	operationType?: string | undefined,
	comment?: string | undefined
};
	["ProcessFlowStep"]: {
		ptime?: number | undefined,
	order?: number | undefined,
	workplace?: ModelTypes["SHARED_Workplace"] | undefined,
	recipe?: ModelTypes["RecipeMenuItem"] | undefined,
	operation?: ModelTypes["OperationMenuItem"] | undefined
};
	["ProcessFlowPartComparisonItem"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	description2?: string | undefined
};
	["UserAccess"]: {
		user?: string | undefined,
	userId?: number | undefined,
	group?: string | undefined,
	pgmId?: number | undefined,
	application?: string | undefined,
	run?: boolean | undefined,
	newEntry?: boolean | undefined,
	save?: boolean | undefined,
	delete?: boolean | undefined,
	special1?: boolean | undefined,
	print?: boolean | undefined,
	startRecipeProcessFlow?: boolean | undefined,
	modifyRecipeProcessFlow?: boolean | undefined,
	stopRecipeProcessFlow?: boolean | undefined,
	startCorrectionRecipe?: boolean | undefined
};
	["updateUserAccessInput"]: {
	userId: number,
	pgmId: number,
	run?: boolean | undefined,
	newEntry?: boolean | undefined,
	save?: boolean | undefined,
	delete?: boolean | undefined,
	special1?: boolean | undefined,
	print?: boolean | undefined,
	startRecipeProcessFlow?: boolean | undefined,
	modifyRecipeProcessFlow?: boolean | undefined,
	stopRecipeProcessFlow?: boolean | undefined,
	startCorrectionRecipe?: boolean | undefined
};
	["UserGroup"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["createUserGroupInput"]: {
	description?: string | undefined,
	name: string
};
	["updateUserGroupInput"]: {
	name?: string | undefined,
	description?: string | undefined
};
	["createUserInput"]: {
	firstName?: string | undefined,
	lastName?: string | undefined,
	shortName: string,
	function?: string | undefined,
	password?: string | undefined,
	comment?: string | undefined,
	employeeNumber?: string | undefined,
	plant?: string | undefined,
	costCenter?: string | undefined,
	maintenancePopups?: boolean | undefined,
	playAlarmSound?: boolean | undefined,
	accessLevel?: number | undefined,
	language?: number | undefined,
	userGroup: number,
	picture?: string | undefined
};
	["updateUserInput"]: {
	firstName?: string | undefined,
	lastName?: string | undefined,
	function?: string | undefined,
	password?: string | undefined,
	comment?: string | undefined,
	employeeNumber?: string | undefined,
	plant?: string | undefined,
	costCenter?: string | undefined,
	maintenancePopups?: boolean | undefined,
	playAlarmSound?: boolean | undefined,
	accessLevel?: number | undefined,
	language?: number | undefined,
	userGroup?: number | undefined,
	picture?: string | undefined
};
	["TO_REMOVE_RecipeMenuItem"]: {
		id?: number | undefined,
	workplaceId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	recipePrefix?: string | undefined
};
	["TO_REMOVE_ShopLayoutMenuItem"]: {
		id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
}
    }

export type GraphQLTypes = {
    ["Error"]: {
	__typename: "Error",
	code?: string | undefined,
	message?: string | undefined,
	detail?: string | undefined
};
	["SHARED_ChartPoint"]: {
	__typename: "SHARED_ChartPoint",
	timestamp?: number | undefined,
	value?: number | undefined
};
	["SHARED_Chart"]: {
	__typename: "SHARED_Chart",
	chartName?: string | undefined,
	varId?: number | undefined,
	chartColor?: number | undefined,
	chartUnit?: string | undefined,
	data?: Array<GraphQLTypes["SHARED_ChartPoint"] | undefined> | undefined
};
	["UDM_ChartData"]: {
	__typename: "UDM_ChartData",
	varId?: number | undefined,
	data?: Array<GraphQLTypes["SHARED_ChartPoint"] | undefined> | undefined
};
	["ChartMetaData"]: {
	__typename: "ChartMetaData",
	varId?: number | undefined,
	chartName?: string | undefined,
	chartColor?: number | undefined,
	chartUnit?: string | undefined
};
	["SHARED_LoadRecipeHeader"]: {
	__typename: "SHARED_LoadRecipeHeader",
	name?: string | undefined,
	description?: string | undefined
};
	["SHARED_PartData"]: {
	__typename: "SHARED_PartData",
	pos?: number | undefined,
	partName?: string | undefined,
	partDescription?: string | undefined,
	amount?: number | undefined,
	weightInKg?: number | undefined,
	totalWeightInKg?: number | undefined,
	orderName?: string | undefined,
	orderRemark?: string | undefined,
	type?: string | undefined,
	customer?: string | undefined,
	intern?: number | undefined
};
	["SHARED_FurnaceSystemStatus"]: {
	__typename: "SHARED_FurnaceSystemStatus",
	entranceReady?: boolean | undefined,
	exitReady?: boolean | undefined
};
	["SHARED_Load"]: {
	__typename: "SHARED_Load",
	id?: string | undefined,
	name?: string | undefined,
	identifier?: string | undefined,
	workplaceId?: string | undefined,
	start?: number | undefined,
	end?: number | undefined,
	earliestTimeStart?: number | undefined,
	packedTime?: number | undefined,
	packedUser?: string | undefined,
	startedUser?: string | undefined,
	status?: string | undefined,
	recipe?: GraphQLTypes["SHARED_LoadRecipeHeader"] | undefined,
	parts?: Array<GraphQLTypes["SHARED_PartData"] | undefined> | undefined
};
	["SHARED_Workplace"]: {
	__typename: "SHARED_Workplace",
	id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined,
	inventoryNumber?: string | undefined,
	type?: GraphQLTypes["SHARED_WorkplaceType"] | undefined,
	active?: GraphQLTypes["SHARED_ActiveState"] | undefined,
	useMode?: GraphQLTypes["SHARED_UseMode"] | undefined,
	logicType?: string | undefined,
	recipePrefix?: string | undefined,
	chamberId?: number | undefined,
	sharesController?: number | undefined,
	belongsTo?: string | undefined,
	ip?: string | undefined,
	mGuardIp?: string | undefined,
	device?: string | undefined,
	telegramSplit?: boolean | undefined,
	deactivateOperations?: boolean | undefined,
	aliveBitErrorNum?: number | undefined,
	comment?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_WorkplaceType"]: {
	__typename: "SHARED_WorkplaceType",
	number?: number | undefined,
	description?: string | undefined,
	prio?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_ActiveState"]: {
	__typename: "SHARED_ActiveState",
	string?: string | undefined,
	int?: number | undefined
};
	["SHARED_UseMode"]: {
	__typename: "SHARED_UseMode",
	string?: string | undefined,
	int?: number | undefined
};
	["SHARED_OptionItem"]: {
	__typename: "SHARED_OptionItem",
	id?: number | undefined,
	value?: string | undefined
};
	["SHARED_SpEntries"]: {
	__typename: "SHARED_SpEntries",
	furnaceSwitchEntry?: Array<string | undefined> | undefined,
	recipeDescription2?: string | undefined,
	recipeDescription3?: string | undefined,
	recipeDescription4?: string | undefined,
	gasEntryValues?: Array<string | undefined> | undefined
};
	["SHARED_DigitalOutput"]: {
	__typename: "SHARED_DigitalOutput",
	name?: string | undefined,
	value?: boolean | undefined
};
	["SHARED_Material"]: {
	__typename: "SHARED_Material",
	carbonContent?: number | undefined,
	alloyFactor?: number | undefined,
	legFactorN?: number | undefined,
	carbideLimit?: number | undefined,
	temperature?: number | undefined,
	specialNitrides?: number | undefined,
	analysis?: GraphQLTypes["SHARED_ElementValueList"] | undefined,
	co?: number | undefined,
	h2?: number | undefined,
	quenchingIntensity?: number | undefined,
	representativeDiameter?: number | undefined,
	requiredHardness?: number | undefined,
	grainSize?: number | undefined,
	endTemperature?: number | undefined,
	targetSurfC?: number | undefined,
	customerMaterialId?: number | undefined
};
	["SHARED_PropertyInfo"]: {
	__typename: "SHARED_PropertyInfo",
	property?: string | undefined,
	target?: number | undefined,
	unit?: string | undefined
};
	["SHARED_PartInfo"]: {
	__typename: "SHARED_PartInfo",
	id?: number | undefined,
	name?: string | undefined,
	materialId?: number | undefined,
	materialName?: string | undefined,
	description1?: string | undefined,
	description2?: string | undefined,
	drawingNumber?: number | undefined,
	revisionNumber?: number | undefined,
	testPlanId?: number | undefined,
	testPlanDescription?: string | undefined,
	comment?: string | undefined,
	properties?: Array<GraphQLTypes["SHARED_PropertyInfo"] | undefined> | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_WorkplaceRecipe"]: {
	__typename: "SHARED_WorkplaceRecipe",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	version_no?: number | undefined
};
	["SHARED_ElementValueList"]: {
	__typename: "SHARED_ElementValueList",
	c?: number | undefined,
	si?: number | undefined,
	mn?: number | undefined,
	cr?: number | undefined,
	ni?: number | undefined,
	v?: number | undefined,
	al?: number | undefined,
	cu?: number | undefined,
	p?: number | undefined,
	s?: number | undefined,
	mo?: number | undefined,
	b?: number | undefined,
	ti?: number | undefined
};
	["SHARED_OLD_CustomerMaterial"]: {
	__typename: "SHARED_OLD_CustomerMaterial",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	normId?: number | undefined,
	normName?: string | undefined,
	normDescription?: string | undefined,
	groupId?: number | undefined,
	groupName?: string | undefined,
	elementValues?: GraphQLTypes["SHARED_ElementValueList"] | undefined,
	averageAlloyFactor?: number | undefined,
	averageAlloyFactorN?: number | undefined,
	carbideLimit?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_DropDownListItem"]: {
	__typename: "SHARED_DropDownListItem",
	id?: number | undefined,
	value?: string | undefined,
	isActive?: boolean | undefined
};
	["SHARED_User"]: {
	__typename: "SHARED_User",
	id?: number | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	shortName?: string | undefined,
	function?: string | undefined,
	password?: string | undefined,
	comment?: string | undefined,
	employeeNumber?: string | undefined,
	plant?: string | undefined,
	costCenter?: string | undefined,
	maintenancePopups?: boolean | undefined,
	playAlarmSound?: boolean | undefined,
	loginTimeInMin?: number | undefined,
	accessLevel?: number | undefined,
	languageId?: number | undefined,
	language?: Array<GraphQLTypes["SHARED_DropDownListItem"] | undefined> | undefined,
	userGroupId?: number | undefined,
	userGroup?: Array<GraphQLTypes["SHARED_DropDownListItem"] | undefined> | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined,
	picture?: string | undefined
};
	["SHARED_Client"]: {
	__typename: "SHARED_Client",
	id?: number | undefined,
	clientType?: string | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: string | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserName?: string | undefined,
	actLoginTime?: number | undefined,
	lastUserName?: string | undefined,
	lastLoginTime?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined,
	isActive?: boolean | undefined
};
	["SHARED_Operation"]: {
	__typename: "SHARED_Operation",
	id?: number | undefined,
	name?: string | undefined,
	operationType?: string | undefined,
	comment?: string | undefined,
	order?: number | undefined,
	relevantForStat?: number | undefined,
	mode?: string | undefined,
	type?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["SHARED_ValueViewComponent"]: {
	__typename: "SHARED_ValueViewComponent",
	id?: number | undefined,
	type?: string | undefined,
	value?: number | undefined,
	status?: number | undefined,
	digitsBeforeComma?: number | undefined,
	digitsAfterComma?: number | undefined,
	textBefore?: string | undefined,
	textAfter?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	numberFormat?: string | undefined,
	fontSize?: number | undefined
};
	["SHARED_BackgroundView"]: {
	__typename: "SHARED_BackgroundView",
	id?: number | undefined,
	type?: string | undefined,
	filePath?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	width?: number | undefined,
	height?: number | undefined
};
	["SHARED_TextView"]: {
	__typename: "SHARED_TextView",
	id?: number | undefined,
	type?: string | undefined,
	value?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	color?: number | undefined,
	colorBG?: number | undefined,
	fontSize?: number | undefined
};
	["SHARED_RecipeVersion"]: {
	__typename: "SHARED_RecipeVersion",
	id?: number | undefined,
	startingTime?: number | undefined,
	versionText?: string | undefined
};
	["SHARED_RecipeShortHeader"]: {
	__typename: "SHARED_RecipeShortHeader",
	recipeName?: string | undefined,
	actualStep?: number | undefined,
	loadIdentifier?: string | undefined,
	loadName?: string | undefined,
	versions?: Array<GraphQLTypes["SHARED_RecipeVersion"] | undefined> | undefined
};
	["SHARED_LoadColumn"]: {
	__typename: "SHARED_LoadColumn",
	name?: string | undefined,
	value?: string | undefined
};
	["SHARED_LoadButton"]: {
	__typename: "SHARED_LoadButton",
	id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	chamberId?: number | undefined
};
	["SHARED_Bitmap"]: {
	__typename: "SHARED_Bitmap",
	id?: number | undefined,
	type?: string | undefined,
	filePath?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined
};
	["SHARED_Gif"]: {
	__typename: "SHARED_Gif",
	id?: number | undefined,
	type?: string | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	filePath?: string | undefined
};
	["SHARED_MovingPic"]: {
	__typename: "SHARED_MovingPic",
	id?: number | undefined,
	type?: string | undefined,
	filePath?: string | undefined,
	startX?: number | undefined,
	startY?: number | undefined,
	endX?: number | undefined,
	endY?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	componentZero?: number | undefined,
	componentSpan?: number | undefined
};
	["SHARED_Label"]: {
	__typename: "SHARED_Label",
	id?: number | undefined,
	type?: string | undefined,
	text?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	fontSize?: number | undefined
};
	["SHARED_LabelPlus"]: {
	__typename: "SHARED_LabelPlus",
	id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorIndex?: number | undefined,
	colorBG?: number | undefined,
	text?: string | undefined,
	text0?: string | undefined,
	text1?: string | undefined,
	text2?: string | undefined,
	text3?: string | undefined,
	fontSize?: number | undefined
};
	["SHARED_Line"]: {
	__typename: "SHARED_Line",
	id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	type?: string | undefined,
	colorIndex?: number | undefined
};
	["SHARED_EditBox"]: {
	__typename: "SHARED_EditBox",
	id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	focusOrderNumber?: number | undefined,
	variableType?: number | undefined,
	isEditable?: boolean | undefined,
	digitsBeforeComma?: number | undefined,
	digitsAfterComma?: number | undefined,
	minValue?: number | undefined,
	maxValue?: number | undefined,
	numberFormat?: string | undefined,
	fontSize?: number | undefined,
	editValue?: number | undefined,
	componentTypeId?: number | undefined,
	userInputId?: number | undefined,
	outputId?: number | undefined
};
	["SHARED_ProgressBar"]: {
	__typename: "SHARED_ProgressBar",
	id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	fullColor?: number | undefined,
	emptyColor?: number | undefined,
	colorBG?: number | undefined,
	value?: number | undefined,
	minValue?: number | undefined,
	maxValue?: number | undefined
};
	["SHARED_Place"]: {
	__typename: "SHARED_Place",
	id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	layoutOrder?: number | undefined,
	name?: string | undefined,
	colorPalette?: string | undefined,
	colorBG?: number | undefined,
	colorIndex?: number | undefined,
	systemColorBG?: number | undefined
};
	["SHARED_Rectangle"]: {
	__typename: "SHARED_Rectangle",
	id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xPos0?: number | undefined,
	yPos0?: number | undefined,
	xPos1?: number | undefined,
	yPos1?: number | undefined,
	xPos2?: number | undefined,
	yPos2?: number | undefined,
	xPos3?: number | undefined,
	yPos3?: number | undefined,
	colorIndex?: number | undefined,
	colorIndex0?: number | undefined,
	colorIndex1?: number | undefined,
	colorIndex2?: number | undefined,
	colorIndex3?: number | undefined,
	colorBG?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined
};
	["SHARED_ChargeCarItem"]: {
	__typename: "SHARED_ChargeCarItem",
	wpId?: number | undefined,
	name?: string | undefined,
	infoText?: string | undefined,
	placeId?: number | undefined,
	placeName?: string | undefined,
	placeColor?: number | undefined,
	placeColorBG?: number | undefined,
	posX?: number | undefined
};
	["SHARED_ChargeCarAutFurnaceDriving"]: {
	__typename: "SHARED_ChargeCarAutFurnaceDriving",
	driverWpId?: number | undefined,
	overWpId?: number | undefined,
	furnaceWidth?: number | undefined,
	furnaceHeight?: number | undefined,
	furnaceOverviewNo?: number | undefined,
	furnacePlace?: number | undefined
};
	["SHARED_ChargeCar"]: {
	__typename: "SHARED_ChargeCar",
	id?: number | undefined,
	carType?: string | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	layoutOrder?: number | undefined,
	imageName?: string | undefined,
	car?: GraphQLTypes["SHARED_ChargeCarItem"] | undefined,
	carList?: Array<GraphQLTypes["SHARED_ChargeCarItem"] | undefined> | undefined,
	railMaxPosPixels?: number | undefined,
	railMaxPosMm?: number | undefined,
	autFurnaceDriving?: GraphQLTypes["SHARED_ChargeCarAutFurnaceDriving"] | undefined,
	mode?: string | undefined
};
	["ShopLayoutChargeStatus"]: {
	__typename: "ShopLayoutChargeStatus",
	id?: string | undefined,
	colorBG?: number | undefined,
	color?: number | undefined,
	colorBorder?: number | undefined
};
	["ShopLayoutView"]: {
	__typename: "ShopLayoutView",
	workplaceId?: number | undefined,
	state?: string | undefined,
	systemStatus?: GraphQLTypes["SHARED_FurnaceSystemStatus"] | undefined,
	chargeStatusList?: Array<GraphQLTypes["ShopLayoutChargeStatus"] | undefined> | undefined,
	recipeHeader?: GraphQLTypes["SHARED_RecipeShortHeader"] | undefined,
	id?: number | undefined,
	type?: string | undefined,
	name?: string | undefined,
	text?: Array<GraphQLTypes["SHARED_TextView"] | undefined> | undefined,
	value?: Array<GraphQLTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	background?: GraphQLTypes["SHARED_BackgroundView"] | undefined,
	loadButton?: Array<GraphQLTypes["SHARED_LoadButton"] | undefined> | undefined,
	place?: Array<GraphQLTypes["SHARED_Place"] | undefined> | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	xSize?: number | undefined,
	ySize?: number | undefined,
	bitmap?: Array<GraphQLTypes["SHARED_Bitmap"] | undefined> | undefined,
	gif?: Array<GraphQLTypes["SHARED_Gif"] | undefined> | undefined,
	label?: Array<GraphQLTypes["SHARED_Label"] | undefined> | undefined,
	labelPlus?: Array<GraphQLTypes["SHARED_LabelPlus"] | undefined> | undefined,
	line?: Array<GraphQLTypes["SHARED_Line"] | undefined> | undefined,
	editBox?: Array<GraphQLTypes["SHARED_EditBox"] | undefined> | undefined,
	progressBar?: Array<GraphQLTypes["SHARED_ProgressBar"] | undefined> | undefined,
	rectangle?: Array<GraphQLTypes["SHARED_Rectangle"] | undefined> | undefined,
	chargeCar?: Array<GraphQLTypes["SHARED_ChargeCar"] | undefined> | undefined
};
	["ShopLayoutViewData"]: {
	__typename: "ShopLayoutViewData",
	workplaceId?: number | undefined,
	state?: string | undefined,
	systemStatus?: GraphQLTypes["SHARED_FurnaceSystemStatus"] | undefined,
	recipeHeader?: GraphQLTypes["SHARED_RecipeShortHeader"] | undefined,
	loadButton?: Array<GraphQLTypes["SHARED_LoadButton"] | undefined> | undefined,
	id?: number | undefined,
	type?: string | undefined,
	name?: string | undefined,
	value?: Array<GraphQLTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	place?: Array<GraphQLTypes["SHARED_Place"] | undefined> | undefined,
	bitmap?: Array<GraphQLTypes["SHARED_Bitmap"] | undefined> | undefined,
	gif?: Array<GraphQLTypes["SHARED_Gif"] | undefined> | undefined,
	label?: Array<GraphQLTypes["SHARED_Label"] | undefined> | undefined,
	labelPlus?: Array<GraphQLTypes["SHARED_LabelPlus"] | undefined> | undefined,
	line?: Array<GraphQLTypes["SHARED_Line"] | undefined> | undefined,
	editBox?: Array<GraphQLTypes["SHARED_EditBox"] | undefined> | undefined,
	progressBar?: Array<GraphQLTypes["SHARED_ProgressBar"] | undefined> | undefined,
	rectangle?: Array<GraphQLTypes["SHARED_Rectangle"] | undefined> | undefined,
	chargeCar?: Array<GraphQLTypes["SHARED_ChargeCar"] | undefined> | undefined
};
	["ShopLayoutAlarm"]: {
	__typename: "ShopLayoutAlarm",
	alarmId?: number | undefined,
	workplaceName?: string | undefined,
	startTime?: number | undefined,
	acknowledgeTime?: number | undefined,
	endTime?: number | undefined,
	priority?: string | undefined,
	alarmMessage?: string | undefined,
	gate?: number | undefined,
	alarmNum?: number | undefined,
	byte?: number | undefined,
	bit?: number | undefined
};
	["ShopLayoutAlarmSearch"]: {
	__typename: "ShopLayoutAlarmSearch",
	workplaceName?: string | undefined,
	time?: number | undefined,
	event?: string | undefined,
	alarmText?: string | undefined,
	alarmNum?: number | undefined
};
	["ShopLayoutLoadListGeneral"]: {
	__typename: "ShopLayoutLoadListGeneral",
	columnNames?: Array<GraphQLTypes["SHARED_LoadColumn"] | undefined> | undefined,
	workplaceGeneral?: Array<GraphQLTypes["SHARED_Workplace"] | undefined> | undefined,
	parts?: Array<GraphQLTypes["SHARED_PartInfo"] | undefined> | undefined
};
	["ShopLayoutRecipeList"]: {
	__typename: "ShopLayoutRecipeList",
	workplaceId?: number | undefined,
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["Query"]: {
	__typename: "Query",
	shopLayoutView?: Array<GraphQLTypes["ShopLayoutView"] | undefined> | undefined,
	updateShopLayoutValues?: Array<GraphQLTypes["ShopLayoutViewData"] | undefined> | undefined,
	shopLayoutAlarms?: Array<GraphQLTypes["ShopLayoutAlarm"] | undefined> | undefined,
	shopLayoutAlarmsSearch?: Array<GraphQLTypes["ShopLayoutAlarmSearch"] | undefined> | undefined,
	shopLayoutLoadList?: Array<GraphQLTypes["SHARED_Load"] | undefined> | undefined,
	shopLayoutLoadListGeneral?: GraphQLTypes["ShopLayoutLoadListGeneral"] | undefined,
	shopLayoutRecipeList?: Array<GraphQLTypes["ShopLayoutRecipeList"] | undefined> | undefined,
	furnaceView?: GraphQLTypes["FurnaceView"] | undefined,
	furnaceViewAlarms?: Array<GraphQLTypes["FurnaceViewAlarm"] | undefined> | undefined,
	furnaceViewAlarmsSearch?: Array<GraphQLTypes["FurnaceViewAlarmSearch"] | undefined> | undefined,
	updateFurnaceViews?: Array<GraphQLTypes["updateFurnaceViewsResult"] | undefined> | undefined,
	furnaceChartUdmData?: Array<GraphQLTypes["UDM_ChartData"] | undefined> | undefined,
	furnaceChartMetaData?: Array<GraphQLTypes["ChartMetaData"] | undefined> | undefined,
	furnaceChartRecorder?: Array<GraphQLTypes["SHARED_Chart"] | undefined> | undefined,
	furnaceLoadList?: Array<GraphQLTypes["SHARED_Load"] | undefined> | undefined,
	actualRecipeOfWorkplace?: GraphQLTypes["ActRecipeVersion"] | undefined,
	furnaceLoadListGeneral?: GraphQLTypes["FurnaceLoadListGeneral"] | undefined,
	furnaceRecipeList?: Array<GraphQLTypes["SHARED_WorkplaceRecipe"] | undefined> | undefined,
	listOfAvailableFurnaces?: Array<number | undefined> | undefined,
	furnaceChartDataFirstTimestamp?: number | undefined,
	alarmStatistics?: Array<GraphQLTypes["AlarmStatisticInfo"] | undefined> | undefined,
	alarmList?: Array<GraphQLTypes["AlarmInfo"] | undefined> | undefined,
	alarmWorkplaces?: Array<GraphQLTypes["WorkplaceInfo"] | undefined> | undefined,
	manageUsage?: Array<GraphQLTypes["Usage"] | undefined> | undefined,
	furnaceBookAlarms?: Array<GraphQLTypes["FurnaceBookAlarm"] | undefined> | undefined,
	furnaceBookFilters?: GraphQLTypes["FurnaceBookFilters"] | undefined,
	getImages?: Array<GraphQLTypes["Image"] | undefined> | undefined,
	profileChart?: GraphQLTypes["ProfileChart"] | undefined,
	furnaceBook?: Array<GraphQLTypes["ProductionLog"] | undefined> | undefined,
	productionLogsDummyCharts?: Array<GraphQLTypes["SHARED_Chart"] | undefined> | undefined,
	productionLogsChartRecorder?: Array<GraphQLTypes["SHARED_Chart"] | undefined> | undefined,
	chargeConsumption?: GraphQLTypes["ChargeConsumption"] | undefined,
	usage?: Array<GraphQLTypes["UsageEntry"] | undefined> | undefined,
	utilization?: GraphQLTypes["Utilization"] | undefined,
	cptEntries?: Array<GraphQLTypes["CptEntry"] | undefined> | undefined,
	recipeOfLoad?: GraphQLTypes["RecipeROL"] | undefined,
	partInfo?: GraphQLTypes["Part"] | undefined,
	partsStatistics?: Array<GraphQLTypes["PartsStatistics"] | undefined> | undefined,
	productionLogsOperationList?: Array<GraphQLTypes["SHARED_Operation"] | undefined> | undefined,
	failureReasonAdministration?: Array<GraphQLTypes["FailureReason"] | undefined> | undefined,
	fixtureAdministration?: Array<GraphQLTypes["Fixture"] | undefined> | undefined,
	fixtureTypes?: Array<GraphQLTypes["FixtureType"] | undefined> | undefined,
	customerSpecificData?: Array<GraphQLTypes["CustomerSpecificData"] | undefined> | undefined,
	engineeringUnitList?: Array<GraphQLTypes["EngineeringUnit"] | undefined> | undefined,
	userUnitsList?: Array<GraphQLTypes["UserUnits"] | undefined> | undefined,
	customerMaterialList?: Array<GraphQLTypes["SHARED_OLD_CustomerMaterial"] | undefined> | undefined,
	materialGroup?: Array<GraphQLTypes["OLD_MaterialGroup"] | undefined> | undefined,
	normMaterialList?: Array<GraphQLTypes["OLD_NormMaterial"] | undefined> | undefined,
	parts?: Array<GraphQLTypes["SHARED_PartInfo"] | undefined> | undefined,
	partManagement?: GraphQLTypes["PartManagementInfo"] | undefined,
	partsWorkplaceRecipeList?: Array<GraphQLTypes["SHARED_WorkplaceRecipe"] | undefined> | undefined,
	testPlanList?: Array<GraphQLTypes["TestPlan"] | undefined> | undefined,
	toolingList?: Array<GraphQLTypes["Tooling"] | undefined> | undefined,
	partsCustomerMaterialList?: Array<GraphQLTypes["SHARED_OLD_CustomerMaterial"] | undefined> | undefined,
	recipeList?: Array<GraphQLTypes["RecipeListItem"] | undefined> | undefined,
	recipeItem?: Array<GraphQLTypes["RecipeItemVersion"] | undefined> | undefined,
	getRecipeMetadata?: GraphQLTypes["RecipeMetadata"] | undefined,
	peAttributeType?: Array<GraphQLTypes["PeAttributeType"] | undefined> | undefined,
	peAttributeTypePhenNoList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	peProcessType?: Array<GraphQLTypes["PeProcessType"] | undefined> | undefined,
	peProcessTypePhenNoList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	physicalUnit?: Array<GraphQLTypes["PhysicalUnit"] | undefined> | undefined,
	physicalUnitMetadata?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	workplaceTypeList?: Array<GraphQLTypes["SHARED_WorkplaceType"] | undefined> | undefined,
	backgroundProcessList?: Array<GraphQLTypes["BackgroundProcess"] | undefined> | undefined,
	getBackgroundProcessStateList?: Array<string | undefined> | undefined,
	workplaceList?: Array<GraphQLTypes["SHARED_Workplace"] | undefined> | undefined,
	workplaceDataGeneralList?: Array<GraphQLTypes["SHARED_Workplace"] | undefined> | undefined,
	workplaceUseModeList?: Array<GraphQLTypes["SHARED_UseMode"] | undefined> | undefined,
	workplaceDataWpTypeList?: Array<GraphQLTypes["SHARED_WorkplaceType"] | undefined> | undefined,
	operationAdministrationOperationList?: Array<GraphQLTypes["Operation"] | undefined> | undefined,
	operationTypeList?: Array<GraphQLTypes["OperationType"] | undefined> | undefined,
	operationPartList?: Array<GraphQLTypes["OperationPart"] | undefined> | undefined,
	operationProcessList?: Array<GraphQLTypes["OperationProcess"] | undefined> | undefined,
	operationMetadata?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	alarmAdministration?: Array<GraphQLTypes["Alarm"] | undefined> | undefined,
	alarmAdministrationMetadata?: GraphQLTypes["AlarmAdministrationMetadata"] | undefined,
	boxAdministration?: Array<GraphQLTypes["Box"] | undefined> | undefined,
	boxMetadata?: Array<GraphQLTypes["ColorBox"] | undefined> | undefined,
	colorAdministration?: Array<GraphQLTypes["Color"] | undefined> | undefined,
	languageList?: Array<GraphQLTypes["LanguageInfo"] | undefined> | undefined,
	externalizeTranslation?: GraphQLTypes["ExternalizeTranslationConfigValue"] | undefined,
	dictionaryAdministration?: Array<GraphQLTypes["DictionaryItem"] | undefined> | undefined,
	smsAdministration?: Array<GraphQLTypes["SmsSettings"] | undefined> | undefined,
	systemTaskAdministration?: Array<GraphQLTypes["Task"] | undefined> | undefined,
	clientAccessUserList?: Array<GraphQLTypes["SHARED_User"] | undefined> | undefined,
	clientAccessClientList?: Array<GraphQLTypes["SHARED_Client"] | undefined> | undefined,
	terminalTypeList?: Array<GraphQLTypes["TerminalType"] | undefined> | undefined,
	terminalTypeMetadata?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	terminalClientList?: Array<GraphQLTypes["TerminalClient"] | undefined> | undefined,
	terminalClientMetadata?: GraphQLTypes["TerminalClientMetadata"] | undefined,
	applicationGroup?: Array<GraphQLTypes["ApplicationGroup"] | undefined> | undefined,
	applicationList?: Array<GraphQLTypes["Application"] | undefined> | undefined,
	applicationMetadata?: GraphQLTypes["ApplicationMetadata"] | undefined,
	processFlowList?: Array<GraphQLTypes["ProcessFlow"] | undefined> | undefined,
	processFlowStepList?: Array<GraphQLTypes["ProcessFlowStep"] | undefined> | undefined,
	operationWorkplaceRecipeList?: Array<GraphQLTypes["RecipeMenuItem"] | undefined> | undefined,
	processFlowPartComparison?: Array<GraphQLTypes["ProcessFlowPartComparisonItem"] | undefined> | undefined,
	processFlowOperationList?: Array<GraphQLTypes["SHARED_Operation"] | undefined> | undefined,
	operationWorkplaceList?: Array<GraphQLTypes["SHARED_Workplace"] | undefined> | undefined,
	userAccessList?: Array<GraphQLTypes["UserAccess"] | undefined> | undefined,
	userGroup?: Array<GraphQLTypes["UserGroup"] | undefined> | undefined,
	userList?: Array<GraphQLTypes["SHARED_User"] | undefined> | undefined,
	menuRecipes?: Array<GraphQLTypes["TO_REMOVE_RecipeMenuItem"] | undefined> | undefined,
	shopLayoutMenuItems?: Array<GraphQLTypes["TO_REMOVE_ShopLayoutMenuItem"] | undefined> | undefined,
	workplaceGeneralList?: Array<GraphQLTypes["SHARED_Workplace"] | undefined> | undefined,
	loadColumnList?: Array<GraphQLTypes["SHARED_LoadColumn"] | undefined> | undefined,
	loadList?: Array<GraphQLTypes["SHARED_Load"] | undefined> | undefined,
	chartRecorder?: Array<GraphQLTypes["SHARED_Chart"] | undefined> | undefined,
	partList?: Array<GraphQLTypes["SHARED_PartInfo"] | undefined> | undefined,
	partWorkplaceRecipeList?: Array<GraphQLTypes["SHARED_WorkplaceRecipe"] | undefined> | undefined,
	clientList?: Array<GraphQLTypes["SHARED_Client"] | undefined> | undefined,
	operationList?: Array<GraphQLTypes["SHARED_Operation"] | undefined> | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	shopLayoutAcknowledgeAlarms?: Array<GraphQLTypes["ShopLayoutAlarm"] | undefined> | undefined,
	furnaceAcknowledgeAlarms?: Array<GraphQLTypes["FurnaceViewAlarm"] | undefined> | undefined,
	setFurnaceEditBoxValues?: GraphQLTypes["SetFurnaceEditBoxValuesResult"] | undefined,
	setFurnaceButtonValue?: GraphQLTypes["ButtonValueResult"] | undefined,
	createUsage?: GraphQLTypes["UsageResult"] | undefined,
	updateUsage?: GraphQLTypes["UsageResult"] | undefined,
	deleteUsage?: GraphQLTypes["UsageResult"] | undefined,
	cloneUsage?: GraphQLTypes["UsageResult"] | undefined,
	deleteImage?: boolean | undefined,
	createCustomerSpecificData?: GraphQLTypes["CustomerSpecificDataResult"] | undefined,
	editCustomerSpecificData?: GraphQLTypes["CustomerSpecificDataResult"] | undefined,
	deleteCustomerSpecificData?: GraphQLTypes["CustomerSpecificDataResult"] | undefined,
	deleteRecipeVersions?: boolean | undefined,
	cloneRecipeAndSaveAs?: GraphQLTypes["CloneRecipeAndSaveAsResult"] | undefined,
	saveNewRecipe?: GraphQLTypes["SaveRecipeResult"] | undefined,
	saveEditedRecipeVersion?: GraphQLTypes["SaveRecipeResult"] | undefined,
	createPeAttributeType?: GraphQLTypes["PeAttributeTypeResult"] | undefined,
	editPeAttributeType?: GraphQLTypes["PeAttributeTypeResult"] | undefined,
	deletePeAttributeType?: GraphQLTypes["PeAttributeTypeResult"] | undefined,
	createPeProcessType?: GraphQLTypes["PeProcessTypeResult"] | undefined,
	editPeProcessType?: GraphQLTypes["PeProcessTypeResult"] | undefined,
	deletePeProcessType?: GraphQLTypes["PeProcessTypeResult"] | undefined,
	createPhysicalUnit?: GraphQLTypes["PhysicalUnitResult"] | undefined,
	editPhysicalUnit?: GraphQLTypes["PhysicalUnitResult"] | undefined,
	deletePhysicalUnit?: GraphQLTypes["PhysicalUnitResult"] | undefined,
	createWorkplaceType?: GraphQLTypes["WorkplaceTypeResult"] | undefined,
	updateWorkplaceType?: GraphQLTypes["WorkplaceTypeResult"] | undefined,
	deleteWorkplaceType?: GraphQLTypes["WorkplaceTypeResult"] | undefined,
	cloneWorkplaceType?: GraphQLTypes["WorkplaceTypeResult"] | undefined,
	deleteBackgroundProcess?: GraphQLTypes["ProcessResult"] | undefined,
	cloneAndSaveAsBackgroundProcess?: GraphQLTypes["ProcessResult"] | undefined,
	editBackgroundProcess?: GraphQLTypes["ProcessResult"] | undefined,
	createNewBackgroundProcess?: GraphQLTypes["ProcessResult"] | undefined,
	createWorkplace?: GraphQLTypes["WorkplaceResult"] | undefined,
	updateWorkplace?: GraphQLTypes["WorkplaceResult"] | undefined,
	deleteWorkplace?: GraphQLTypes["WorkplaceResult"] | undefined,
	cloneWorkplace?: GraphQLTypes["WorkplaceResult"] | undefined,
	createOperation?: GraphQLTypes["OperationResult"] | undefined,
	editOperation?: GraphQLTypes["OperationResult"] | undefined,
	deleteOperation?: GraphQLTypes["OperationResult"] | undefined,
	cloneAndSaveAsAlarm?: GraphQLTypes["AlarmResult"] | undefined,
	createNewAlarm?: GraphQLTypes["AlarmResult"] | undefined,
	editAlarm?: GraphQLTypes["AlarmResult"] | undefined,
	deleteAlarm?: GraphQLTypes["AlarmResult"] | undefined,
	deleteSelectedAlarmsForFurnace?: Array<GraphQLTypes["Alarm"] | undefined> | undefined,
	createBox?: GraphQLTypes["BoxResult"] | undefined,
	editBox?: GraphQLTypes["BoxResult"] | undefined,
	deleteBox?: GraphQLTypes["BoxResult"] | undefined,
	createColor?: GraphQLTypes["ColorResult"] | undefined,
	editColor?: GraphQLTypes["ColorResult"] | undefined,
	deleteColor?: GraphQLTypes["ColorResult"] | undefined,
	createTask?: GraphQLTypes["TaskResult"] | undefined,
	updateTask?: GraphQLTypes["TaskResult"] | undefined,
	deleteTask?: GraphQLTypes["TaskResult"] | undefined,
	cloneTask?: GraphQLTypes["TaskResult"] | undefined,
	updateClientAccessClientList?: GraphQLTypes["updateClientAccessResult"] | undefined,
	createTerminalType?: GraphQLTypes["TerminalTypeResult"] | undefined,
	editTerminalType?: GraphQLTypes["TerminalTypeResult"] | undefined,
	deleteTerminalType?: GraphQLTypes["TerminalTypeResult"] | undefined,
	createTerminalClient?: GraphQLTypes["TerminalClientResult"] | undefined,
	editTerminalClient?: GraphQLTypes["TerminalClientResult"] | undefined,
	deleteTerminalClient?: GraphQLTypes["TerminalClientResult"] | undefined,
	createApplicationGroup?: GraphQLTypes["ApplicationGroupResult"] | undefined,
	editApplicationGroup?: GraphQLTypes["ApplicationGroupResult"] | undefined,
	deleteApplicationGroup?: GraphQLTypes["ApplicationGroupResult"] | undefined,
	createApplication?: GraphQLTypes["ApplicationResult"] | undefined,
	editApplication?: GraphQLTypes["ApplicationResult"] | undefined,
	deleteApplication?: GraphQLTypes["ApplicationResult"] | undefined,
	updateUserAccess?: GraphQLTypes["UserAccess"] | undefined,
	createUserGroup?: GraphQLTypes["UserGroup"] | undefined,
	updateUserGroup?: GraphQLTypes["UserGroup"] | undefined,
	deleteUserGroup?: boolean | undefined,
	createUser?: GraphQLTypes["SHARED_User"] | undefined,
	updateUser?: GraphQLTypes["SHARED_User"] | undefined,
	deleteUser?: boolean | undefined
};
	["HourInfo"]: {
	__typename: "HourInfo",
	id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	fontSize?: number | undefined,
	text?: string | undefined,
	hourType?: number | undefined,
	zoneId?: number | undefined,
	time?: number | undefined,
	colorIndex?: number | undefined,
	colorBG?: number | undefined
};
	["LoadInfo"]: {
	__typename: "LoadInfo",
	id?: number | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	fontSize?: number | undefined,
	text?: string | undefined,
	time?: number | undefined,
	infoType?: number | undefined,
	colorIndex?: number | undefined,
	colorBG?: number | undefined
};
	["Button"]: {
	__typename: "Button",
	id?: number | undefined,
	type?: string | undefined,
	xPos?: number | undefined,
	yPos?: number | undefined,
	userInputId?: number | undefined,
	name?: string | undefined,
	mode?: number | undefined,
	userLevel?: number | undefined,
	btnText0?: string | undefined,
	btnText1?: string | undefined,
	helpText0?: string | undefined,
	helpText1?: string | undefined,
	value?: number | undefined
};
	["furnaceViewButton"]: {
	__typename: "furnaceViewButton",
	name?: string | undefined,
	id?: number | undefined,
	mode?: number | undefined,
	userLevel?: number | undefined,
	btnText0?: string | undefined,
	btnText1?: string | undefined,
	helpText0?: string | undefined,
	helpText1?: string | undefined
};
	["FurnaceViewComponents"]: {
	__typename: "FurnaceViewComponents",
	id?: number | undefined,
	name?: string | undefined,
	background?: GraphQLTypes["SHARED_BackgroundView"] | undefined,
	text?: Array<GraphQLTypes["SHARED_TextView"] | undefined> | undefined,
	value?: Array<GraphQLTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	hourInfo?: Array<GraphQLTypes["HourInfo"] | undefined> | undefined,
	loadInfo?: Array<GraphQLTypes["LoadInfo"] | undefined> | undefined,
	loadButton?: Array<GraphQLTypes["SHARED_LoadButton"] | undefined> | undefined,
	bitmap?: Array<GraphQLTypes["SHARED_Bitmap"] | undefined> | undefined,
	label?: Array<GraphQLTypes["SHARED_Label"] | undefined> | undefined,
	labelPlus?: Array<GraphQLTypes["SHARED_LabelPlus"] | undefined> | undefined,
	rectangle?: Array<GraphQLTypes["SHARED_Rectangle"] | undefined> | undefined,
	button?: Array<GraphQLTypes["Button"] | undefined> | undefined,
	gif?: Array<GraphQLTypes["SHARED_Gif"] | undefined> | undefined,
	movingPic?: Array<GraphQLTypes["SHARED_MovingPic"] | undefined> | undefined,
	place?: Array<GraphQLTypes["SHARED_Place"] | undefined> | undefined,
	progressBar?: Array<GraphQLTypes["SHARED_ProgressBar"] | undefined> | undefined,
	editBox?: Array<GraphQLTypes["SHARED_EditBox"] | undefined> | undefined,
	line?: Array<GraphQLTypes["SHARED_Line"] | undefined> | undefined,
	windowSizeId?: number | undefined
};
	["FurnaceValueViewComponent"]: {
	__typename: "FurnaceValueViewComponent",
	id?: number | undefined,
	name?: string | undefined,
	status?: number | undefined,
	value?: Array<GraphQLTypes["SHARED_ValueViewComponent"] | undefined> | undefined,
	hourInfo?: Array<GraphQLTypes["HourInfo"] | undefined> | undefined,
	loadInfo?: Array<GraphQLTypes["LoadInfo"] | undefined> | undefined,
	place?: Array<GraphQLTypes["SHARED_Place"] | undefined> | undefined,
	bitmap?: Array<GraphQLTypes["SHARED_Bitmap"] | undefined> | undefined,
	gif?: Array<GraphQLTypes["SHARED_Gif"] | undefined> | undefined,
	movingPic?: Array<GraphQLTypes["SHARED_MovingPic"] | undefined> | undefined,
	editBox?: Array<GraphQLTypes["SHARED_EditBox"] | undefined> | undefined,
	labelPlus?: Array<GraphQLTypes["SHARED_LabelPlus"] | undefined> | undefined,
	label?: Array<GraphQLTypes["SHARED_Label"] | undefined> | undefined,
	progressBar?: Array<GraphQLTypes["SHARED_ProgressBar"] | undefined> | undefined,
	loadButton?: Array<GraphQLTypes["SHARED_LoadButton"] | undefined> | undefined,
	rectangle?: Array<GraphQLTypes["SHARED_Rectangle"] | undefined> | undefined,
	button?: Array<GraphQLTypes["Button"] | undefined> | undefined,
	line?: Array<GraphQLTypes["SHARED_Line"] | undefined> | undefined
};
	["updateFurnaceViewsResult"]: {
	__typename: "updateFurnaceViewsResult",
	workplaceId?: number | undefined,
	state?: string | undefined,
	hold?: number | undefined,
	remote?: number | undefined,
	connected?: number | undefined,
	updateTime?: number | undefined,
	furnaceViews?: Array<GraphQLTypes["FurnaceValueViewComponent"] | undefined> | undefined,
	recipeHeader?: GraphQLTypes["SHARED_RecipeShortHeader"] | undefined
};
	["SystemColor"]: {
	__typename: "SystemColor",
	id?: number | undefined,
	r?: number | undefined,
	g?: number | undefined,
	b?: number | undefined
};
	["FurnaceView"]: {
	__typename: "FurnaceView",
	workplaceId?: number | undefined,
	state?: string | undefined,
	hold?: number | undefined,
	remote?: number | undefined,
	connected?: number | undefined,
	updateTime?: number | undefined,
	colors?: Array<GraphQLTypes["SystemColor"] | undefined> | undefined,
	furnaceViews?: Array<GraphQLTypes["FurnaceViewComponents"] | undefined> | undefined,
	recipeHeader?: GraphQLTypes["SHARED_RecipeShortHeader"] | undefined
};
	["FurnaceViewAlarm"]: {
	__typename: "FurnaceViewAlarm",
	alarmId?: number | undefined,
	workplaceName?: string | undefined,
	startTime?: number | undefined,
	acknowledgeTime?: number | undefined,
	endTime?: number | undefined,
	priority?: string | undefined,
	alarmMessage?: string | undefined,
	gate?: number | undefined,
	alarmNum?: number | undefined,
	byte?: number | undefined,
	bit?: number | undefined
};
	["FurnaceViewAlarmSearch"]: {
	__typename: "FurnaceViewAlarmSearch",
	workplaceName?: string | undefined,
	time?: number | undefined,
	event?: string | undefined,
	alarmText?: string | undefined,
	alarmNum?: number | undefined
};
	["ActRecipeHeader"]: {
	__typename: "ActRecipeHeader",
	id?: number | undefined,
	name?: string | undefined,
	recipePrefix?: string | undefined,
	description?: string | undefined,
	comment?: string | undefined,
	version?: number | undefined,
	processOption?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	processType?: number | undefined,
	processDepth?: number | undefined,
	processDepthAt?: number | undefined,
	correction?: boolean | undefined,
	diff?: boolean | undefined,
	loadIdentifier?: string | undefined,
	loadName?: string | undefined,
	actualStep?: number | undefined,
	startingTime?: number | undefined,
	user?: GraphQLTypes["SHARED_OptionItem"] | undefined
};
	["ActRecipePositionHeader"]: {
	__typename: "ActRecipePositionHeader",
	positionNo?: number | undefined,
	chamberOption?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	chamberOperationOption?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	description?: string | undefined,
	afterTimeInMin?: number | undefined,
	estimatedDurationInMin?: number | undefined,
	actualDurationInMin?: number | undefined,
	afterOption?: GraphQLTypes["SHARED_OptionItem"] | undefined
};
	["ActSetPoint"]: {
	__typename: "ActSetPoint",
	userComponentId?: number | undefined,
	valueType?: number | undefined,
	meaning?: number | undefined,
	name?: string | undefined,
	control?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	value?: number | undefined,
	unit?: string | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	actualValue?: number | undefined,
	endControl?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	endValue?: number | undefined
};
	["ActRecipePosition"]: {
	__typename: "ActRecipePosition",
	header?: GraphQLTypes["ActRecipePositionHeader"] | undefined,
	setpoints?: Array<GraphQLTypes["ActSetPoint"] | undefined> | undefined,
	digitalOutputList?: Array<GraphQLTypes["SHARED_DigitalOutput"] | undefined> | undefined,
	digitalOutputs?: string | undefined,
	endAfter?: string | undefined
};
	["ActRecipeVersion"]: {
	__typename: "ActRecipeVersion",
	header?: GraphQLTypes["ActRecipeHeader"] | undefined,
	positions?: Array<GraphQLTypes["ActRecipePosition"] | undefined> | undefined,
	spEntries?: GraphQLTypes["SHARED_SpEntries"] | undefined,
	material?: GraphQLTypes["SHARED_Material"] | undefined,
	correctionProfile?: Array<number | undefined> | undefined
};
	["FurnaceViewError"]: {
	__typename: "FurnaceViewError",
	code?: string | undefined,
	message?: string | undefined,
	detail?: string | undefined
};
	["SetFurnaceEditBoxValuesResult"]: {
	__typename: "SetFurnaceEditBoxValuesResult",
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["FurnaceViewError"] | undefined
};
	["EditBoxValueInput"]: {
		componentTypeId: number,
	userInputId: number,
	outputId: number,
	value: number
};
	["FurnaceLoadListGeneral"]: {
	__typename: "FurnaceLoadListGeneral",
	columnNames?: Array<GraphQLTypes["SHARED_LoadColumn"] | undefined> | undefined,
	parts?: Array<GraphQLTypes["SHARED_PartInfo"] | undefined> | undefined
};
	["ButtonValueResult"]: {
	__typename: "ButtonValueResult",
	value?: number | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["FurnaceViewError"] | undefined
};
	["ButtonInput"]: {
		userInputId: number,
	value: number
};
	["AlarmEvent"]: {
	__typename: "AlarmEvent",
	event?: string | undefined,
	time?: number | undefined
};
	["AlarmStatisticInfo"]: {
	__typename: "AlarmStatisticInfo",
	alarmNo?: number | undefined,
	description?: string | undefined,
	quantity?: number | undefined,
	durationInSec?: number | undefined,
	quantityPercentage?: number | undefined,
	durationPercentage?: number | undefined,
	events?: Array<GraphQLTypes["AlarmEvent"] | undefined> | undefined
};
	["AlarmInfo"]: {
	__typename: "AlarmInfo",
	alarmNo?: number | undefined,
	alarmSeverity?: number | undefined,
	alarmText?: string | undefined
};
	["WorkplaceInfo"]: {
	__typename: "WorkplaceInfo",
	id?: number | undefined,
	description?: string | undefined
};
	["Usage"]: {
	__typename: "Usage",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	unit?: string | undefined,
	divisor?: number | undefined,
	summaryCounter?: boolean | undefined,
	overflow?: number | undefined,
	validSince?: number | undefined,
	pricePerUnit?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["UsageResult"]: {
	__typename: "UsageResult",
	usage?: GraphQLTypes["Usage"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["createUsageInput"]: {
		name: string,
	description?: string | undefined,
	unit?: string | undefined,
	divisor?: number | undefined,
	summaryCounter?: boolean | undefined,
	overflow?: number | undefined,
	validSince?: number | undefined,
	pricePerUnit?: number | undefined
};
	["updateUsageInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	unit?: string | undefined,
	divisor?: number | undefined,
	summaryCounter?: boolean | undefined,
	overflow?: number | undefined,
	validSince?: number | undefined,
	pricePerUnit?: number | undefined,
	modifiedOn: number
};
	["cloneUsageInput"]: {
		name?: string | undefined,
	description?: string | undefined
};
	["SearchComponentType"]: SearchComponentType;
	["SearchFilter"]: {
	__typename: "SearchFilter",
	filterName?: string | undefined,
	displayName?: string | undefined,
	category?: string | undefined,
	type?: GraphQLTypes["SearchComponentType"] | undefined,
	value?: string | undefined,
	isChecked?: boolean | undefined
};
	["FurnaceBookFilters"]: {
	__typename: "FurnaceBookFilters",
	workplaces?: Array<GraphQLTypes["SHARED_Workplace"] | undefined> | undefined,
	filters?: Array<GraphQLTypes["SearchFilter"] | undefined> | undefined
};
	["HeaderLoadTreatment"]: {
	__typename: "HeaderLoadTreatment",
	recipeName?: string | undefined,
	description?: string | undefined,
	loadIdentifier?: string | undefined
};
	["ContainerPosition"]: {
	__typename: "ContainerPosition",
	partName?: string | undefined,
	order?: string | undefined,
	amount?: number | undefined,
	totalWeightInKg?: number | undefined
};
	["ProductionLog"]: {
	__typename: "ProductionLog",
	workplace?: GraphQLTypes["SHARED_Workplace"] | undefined,
	startingTime?: number | undefined,
	endingTime?: number | undefined,
	durationInSec?: number | undefined,
	loadId?: number | undefined,
	loadName?: string | undefined,
	alarms?: string | undefined,
	header?: GraphQLTypes["HeaderLoadTreatment"] | undefined,
	positions?: Array<GraphQLTypes["ContainerPosition"] | undefined> | undefined,
	box?: number | undefined
};
	["FurnaceBookAlarm"]: {
	__typename: "FurnaceBookAlarm",
	messageNo?: number | undefined,
	event?: string | undefined,
	time?: number | undefined,
	text?: string | undefined
};
	["ProfileChartData"]: {
	__typename: "ProfileChartData",
	yUnit?: string | undefined,
	data?: Array<number | undefined> | undefined
};
	["ProfileChart"]: {
	__typename: "ProfileChart",
	restaust?: number | undefined,
	surfC?: number | undefined,
	xUnit?: string | undefined,
	charts?: Array<GraphQLTypes["ProfileChartData"] | undefined> | undefined
};
	["Consumption"]: {
	__typename: "Consumption",
	media?: string | undefined,
	amount?: number | undefined,
	unit?: string | undefined,
	costs?: number | undefined
};
	["ChargeConsumption"]: {
	__typename: "ChargeConsumption",
	consumption?: Array<GraphQLTypes["Consumption"] | undefined> | undefined,
	totalCosts?: number | undefined,
	currency?: string | undefined
};
	["UsageEntry"]: {
	__typename: "UsageEntry",
	workplace?: string | undefined,
	chargesCounter?: number | undefined,
	production?: GraphQLTypes["ChargeConsumption"] | undefined,
	averagePerCharge?: GraphQLTypes["ChargeConsumption"] | undefined,
	standBy?: GraphQLTypes["ChargeConsumption"] | undefined,
	total?: GraphQLTypes["ChargeConsumption"] | undefined
};
	["UtilizationMode"]: {
		type?: string | undefined,
	value?: string | undefined
};
	["UsageValue"]: {
	__typename: "UsageValue",
	unit?: string | undefined,
	value?: number | undefined
};
	["UtilizationTotal"]: {
	__typename: "UtilizationTotal",
	workplace?: string | undefined,
	numberOfLoads?: number | undefined,
	numberOfLoadsEvaluated?: number | undefined,
	loadsRatio?: GraphQLTypes["UsageValue"] | undefined,
	totalTime?: GraphQLTypes["UsageValue"] | undefined,
	productionTime?: GraphQLTypes["UsageValue"] | undefined,
	timeRatio?: GraphQLTypes["UsageValue"] | undefined,
	averageLoadingTime?: GraphQLTypes["UsageValue"] | undefined,
	availability?: GraphQLTypes["UsageValue"] | undefined,
	downtime?: GraphQLTypes["UsageValue"] | undefined,
	downtimeReason?: GraphQLTypes["UsageValue"] | undefined,
	downtimeNoReason?: GraphQLTypes["UsageValue"] | undefined,
	utilization?: GraphQLTypes["UsageValue"] | undefined,
	capacityLoss?: GraphQLTypes["UsageValue"] | undefined,
	kpi?: number | undefined,
	targetWeight?: GraphQLTypes["UsageValue"] | undefined,
	produced?: GraphQLTypes["UsageValue"] | undefined,
	weightRatio?: GraphQLTypes["UsageValue"] | undefined,
	flowRate?: GraphQLTypes["UsageValue"] | undefined
};
	["UtilizationPeriod"]: {
	__typename: "UtilizationPeriod",
	period?: string | undefined,
	target?: GraphQLTypes["UsageValue"] | undefined,
	actual?: GraphQLTypes["UsageValue"] | undefined,
	utilizationRate?: GraphQLTypes["UsageValue"] | undefined
};
	["UtilizationUsage"]: {
	__typename: "UtilizationUsage",
	workplace?: string | undefined,
	loadsEvaluated?: number | undefined,
	runtime?: GraphQLTypes["UsageValue"] | undefined,
	weight?: GraphQLTypes["UsageValue"] | undefined,
	usage?: GraphQLTypes["UsageValue"] | undefined,
	usageLoad?: GraphQLTypes["UsageValue"] | undefined,
	usageRuntime?: GraphQLTypes["UsageValue"] | undefined
};
	["Utilization"]: {
	__typename: "Utilization",
	totalInfo?: Array<GraphQLTypes["UtilizationTotal"] | undefined> | undefined,
	periodInfo?: Array<GraphQLTypes["UtilizationPeriod"] | undefined> | undefined,
	usageInfo?: Array<GraphQLTypes["UtilizationUsage"] | undefined> | undefined
};
	["CptEntry"]: {
	__typename: "CptEntry",
	id?: string | undefined,
	name?: string | undefined
};
	["RecipeHeaderROL"]: {
	__typename: "RecipeHeaderROL",
	chargeIdentifier?: string | undefined,
	recipeName?: string | undefined,
	description?: string | undefined
};
	["RecipeVersionROL"]: {
	__typename: "RecipeVersionROL",
	id?: number | undefined,
	startingTime?: number | undefined,
	versionText?: string | undefined
};
	["SetpointROL"]: {
	__typename: "SetpointROL",
	name?: string | undefined,
	control?: string | undefined,
	value?: number | undefined,
	unit?: string | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	actualValue?: number | undefined,
	endControl?: string | undefined,
	endValue?: number | undefined
};
	["DigitalOutputROL"]: {
	__typename: "DigitalOutputROL",
	name?: string | undefined,
	value?: boolean | undefined
};
	["AfterTimeROL"]: {
	__typename: "AfterTimeROL",
	activeOption?: number | undefined,
	options?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	durationInSec?: number | undefined
};
	["StepHeaderROL"]: {
	__typename: "StepHeaderROL",
	chamberOptions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	operationOptions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	description?: string | undefined,
	estimatedDurationInSec?: number | undefined,
	afterOptions?: GraphQLTypes["AfterTimeROL"] | undefined
};
	["RecipeStepROL"]: {
	__typename: "RecipeStepROL",
	id?: number | undefined,
	header?: GraphQLTypes["StepHeaderROL"] | undefined,
	chamber?: string | undefined,
	description?: string | undefined,
	startTime?: number | undefined,
	operation?: string | undefined,
	setpoints?: Array<GraphQLTypes["SetpointROL"] | undefined> | undefined,
	digitalOutputs?: string | undefined,
	digitalOutputList?: Array<GraphQLTypes["DigitalOutputROL"] | undefined> | undefined,
	endAfter?: string | undefined,
	actualDurationInMin?: number | undefined
};
	["RecipeROL"]: {
	__typename: "RecipeROL",
	header?: GraphQLTypes["RecipeHeaderROL"] | undefined,
	versions?: Array<GraphQLTypes["RecipeVersionROL"] | undefined> | undefined,
	steps?: Array<GraphQLTypes["RecipeStepROL"] | undefined> | undefined
};
	["PartHeader"]: {
	__typename: "PartHeader",
	contentName?: string | undefined,
	weightInKg?: number | undefined,
	amount?: number | undefined,
	createdAt?: number | undefined,
	modifiedAt?: number | undefined
};
	["PartData"]: {
	__typename: "PartData",
	pos?: number | undefined,
	partName?: string | undefined,
	partDescription?: string | undefined,
	amount?: number | undefined,
	weightInKg?: number | undefined,
	totalWeightInKg?: number | undefined,
	orderName?: string | undefined,
	orderRemark?: string | undefined,
	type?: string | undefined,
	customer?: string | undefined,
	intern?: number | undefined
};
	["Part"]: {
	__typename: "Part",
	header?: GraphQLTypes["PartHeader"] | undefined,
	parts?: Array<GraphQLTypes["PartData"] | undefined> | undefined
};
	["PartsStatistics"]: {
	__typename: "PartsStatistics",
	order?: string | undefined,
	name?: string | undefined,
	description?: string | undefined,
	amount?: number | undefined,
	weight?: number | undefined
};
	["Image"]: {
	__typename: "Image",
	no?: number | undefined,
	description?: string | undefined,
	dataBase64?: string | undefined
};
	["Filter"]: {
		orderName?: string | undefined,
	partDescription?: string | undefined,
	recipeName?: string | undefined,
	seriesNumber?: string | undefined,
	material?: string | undefined,
	loadIdentifier?: string | undefined,
	sample?: string | undefined,
	chdFrom?: number | undefined,
	chdTo?: number | undefined,
	trayName?: string | undefined,
	partName?: string | undefined,
	loadName?: string | undefined,
	suchfeld?: string | undefined,
	aufgepackt?: string | undefined,
	abgepackt?: string | undefined,
	cellLoadName?: string | undefined,
	processFlowName?: string | undefined,
	customer?: string | undefined,
	sampleCheckbox?: boolean | undefined,
	series?: boolean | undefined,
	addWork?: boolean | undefined,
	own?: boolean | undefined,
	foreign?: boolean | undefined,
	earnings?: boolean | undefined,
	withAlarms?: boolean | undefined,
	withOrderPartInfo?: boolean | undefined
};
	["DeleteImageInput"]: {
		loadName: string,
	no: number
};
	["FailureReason"]: {
	__typename: "FailureReason",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	type?: string | undefined,
	comment?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["Fixture"]: {
	__typename: "Fixture",
	id?: number | undefined,
	no?: number | undefined,
	fixture?: string | undefined
};
	["FixtureType"]: {
	__typename: "FixtureType",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	typeOfWorkplace?: number | undefined,
	workplace?: number | undefined,
	maximumHeight?: number | undefined,
	maximumWidth?: number | undefined,
	maximumDepth?: number | undefined,
	maximumWeight?: number | undefined,
	minimumWeight?: number | undefined,
	loadWeight?: number | undefined,
	ownWeight?: number | undefined,
	maxNumOfLoads?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["CustomerSpecificData"]: {
	__typename: "CustomerSpecificData",
	id?: number | undefined,
	name?: string | undefined,
	floatValue?: number | undefined,
	longValue?: number | undefined,
	characterValue?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["CustomerSpecificDataResult"]: {
	__typename: "CustomerSpecificDataResult",
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined,
	customerSpecificData?: GraphQLTypes["CustomerSpecificData"] | undefined
};
	["CreateCustomerSpecificDataInput"]: {
		name?: string | undefined,
	floatValue?: number | undefined,
	longValue?: number | undefined,
	characterValue?: string | undefined
};
	["EditCustomerSpecificDataInput"]: {
		id: number,
	modifiedOn: number,
	name?: string | undefined,
	floatValue?: number | undefined,
	longValue?: number | undefined,
	characterValue?: string | undefined
};
	["DeleteCustomerSpecificDataInput"]: {
		id: number,
	modifiedOn: number
};
	["EngineeringUnit"]: {
	__typename: "EngineeringUnit",
	id?: number | undefined,
	shortDescription?: string | undefined,
	descripton?: string | undefined,
	physicalUnit?: string | undefined,
	unit?: string | undefined,
	peToEuType?: number | undefined,
	peToEu?: string | undefined,
	euToPeType?: number | undefined,
	euToPeString?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["UnitValue"]: {
	__typename: "UnitValue",
	unitValue?: string | undefined,
	default?: boolean | undefined
};
	["Unit"]: {
	__typename: "Unit",
	name?: string | undefined,
	values?: Array<GraphQLTypes["UnitValue"] | undefined> | undefined
};
	["UserUnits"]: {
	__typename: "UserUnits",
	user?: string | undefined,
	units?: Array<GraphQLTypes["Unit"] | undefined> | undefined
};
	["OLD_MaterialGroup"]: {
	__typename: "OLD_MaterialGroup",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["OLD_NormMaterial"]: {
	__typename: "OLD_NormMaterial",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	groupId?: number | undefined,
	groupName?: string | undefined,
	elementValuesFrom?: GraphQLTypes["SHARED_ElementValueList"] | undefined,
	elementValuesTo?: GraphQLTypes["SHARED_ElementValueList"] | undefined,
	averageAlloyFactor?: number | undefined,
	carbideLimit?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TestPlan"]: {
	__typename: "TestPlan",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["Setpoints"]: {
	__typename: "Setpoints",
	recipeId?: number | undefined,
	recipeName?: string | undefined,
	numberOfPartsPerTray?: number | undefined
};
	["ToolingInfo"]: {
	__typename: "ToolingInfo",
	id?: number | undefined,
	name?: string | undefined,
	amount?: number | undefined
};
	["Tooling"]: {
	__typename: "Tooling",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["LoadInformation"]: {
	__typename: "LoadInformation",
	packInformation?: string | undefined,
	image1Base64?: string | undefined,
	image2Base64?: string | undefined,
	image3Base64?: string | undefined,
	toolings?: Array<GraphQLTypes["ToolingInfo"] | undefined> | undefined
};
	["PartWorkplaceInfo"]: {
	__typename: "PartWorkplaceInfo",
	id?: number | undefined,
	name?: string | undefined,
	setpoints?: GraphQLTypes["Setpoints"] | undefined,
	loadInformation?: GraphQLTypes["LoadInformation"] | undefined
};
	["PartManagementInfo"]: {
	__typename: "PartManagementInfo",
	partInfo?: GraphQLTypes["SHARED_PartInfo"] | undefined,
	partWorkplaceList?: Array<GraphQLTypes["PartWorkplaceInfo"] | undefined> | undefined
};
	["SetPointItem"]: {
	__typename: "SetPointItem",
	userComponentId?: number | undefined,
	relevant?: number | undefined,
	valueType?: number | undefined,
	meaning?: number | undefined,
	name?: string | undefined,
	control?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	value?: number | undefined,
	unit?: string | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	endControl?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	endValue?: number | undefined
};
	["RecipeItemPositionHeader"]: {
	__typename: "RecipeItemPositionHeader",
	positionNo?: number | undefined,
	chamberOption?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	chamberOperationOption?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	description?: string | undefined,
	afterTimeInMin?: number | undefined,
	estimatedDurationInMin?: number | undefined,
	afterOption?: GraphQLTypes["SHARED_OptionItem"] | undefined
};
	["RecipeItemPosition"]: {
	__typename: "RecipeItemPosition",
	header?: GraphQLTypes["RecipeItemPositionHeader"] | undefined,
	setpoints?: Array<GraphQLTypes["SetPointItem"] | undefined> | undefined,
	digitalOutputList?: Array<GraphQLTypes["SHARED_DigitalOutput"] | undefined> | undefined,
	digitalOutputs?: string | undefined,
	endAfter?: string | undefined
};
	["RecipeItemHeader"]: {
	__typename: "RecipeItemHeader",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	comment?: string | undefined,
	version?: number | undefined,
	processOption?: GraphQLTypes["SHARED_OptionItem"] | undefined,
	processType?: number | undefined,
	processDepth?: number | undefined,
	processDepthAt?: number | undefined,
	correction?: boolean | undefined,
	diff?: boolean | undefined
};
	["RecipeItemVersion"]: {
	__typename: "RecipeItemVersion",
	header?: GraphQLTypes["RecipeItemHeader"] | undefined,
	positions?: Array<GraphQLTypes["RecipeItemPosition"] | undefined> | undefined,
	spEntries?: GraphQLTypes["SHARED_SpEntries"] | undefined,
	material?: GraphQLTypes["SHARED_Material"] | undefined,
	correctionProfile?: Array<number | undefined> | undefined
};
	["RecipeListItem"]: {
	__typename: "RecipeListItem",
	id?: number | undefined,
	recipe?: string | undefined,
	description?: string | undefined
};
	["CloneRecipeAndSaveAsResult"]: {
	__typename: "CloneRecipeAndSaveAsResult",
	recipeVersions?: Array<GraphQLTypes["RecipeItemVersion"] | undefined> | undefined,
	isSaved?: boolean | undefined,
	comment?: string | undefined
};
	["SaveRecipeResult"]: {
	__typename: "SaveRecipeResult",
	recipeVersion?: GraphQLTypes["RecipeItemVersion"] | undefined,
	isSaved?: boolean | undefined,
	comment?: string | undefined
};
	["MetaDataSetPoint"]: {
	__typename: "MetaDataSetPoint",
	name?: string | undefined,
	userComponentId?: number | undefined,
	meaning?: number | undefined,
	relevant?: number | undefined,
	unit?: string | undefined,
	valueType?: number | undefined
};
	["MetaDataMethods"]: {
	__typename: "MetaDataMethods",
	setPoints?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalInputs?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalOutputs?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["OperationOptions"]: {
	__typename: "OperationOptions",
	id?: number | undefined,
	value?: string | undefined,
	validVarIds?: Array<number | undefined> | undefined
};
	["ControlMetadata"]: {
	__typename: "ControlMetadata",
	setPoints?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalInputs?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalOutputs?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["OperationMetadata"]: {
	__typename: "OperationMetadata",
	name?: string | undefined,
	description?: string | undefined,
	setPoints?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalInputs?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined,
	digitalOutputs?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["MetaDataChamberOption"]: {
	__typename: "MetaDataChamberOption",
	chamberId?: number | undefined,
	name?: string | undefined,
	recipeControl?: GraphQLTypes["ControlMetadata"] | undefined,
	operationControl?: Array<GraphQLTypes["OperationMetadata"] | undefined> | undefined,
	operationOptions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	setPoints?: Array<GraphQLTypes["MetaDataSetPoint"] | undefined> | undefined
};
	["RecipeMetadata"]: {
	__typename: "RecipeMetadata",
	afterOptions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	controlOptions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	endControlOptions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	chamberOptions?: Array<GraphQLTypes["MetaDataChamberOption"] | undefined> | undefined,
	operationProcessList?: Array<GraphQLTypes["SHARED_Operation"] | undefined> | undefined,
	materialList?: Array<GraphQLTypes["SHARED_OLD_CustomerMaterial"] | undefined> | undefined
};
	["RecipeVersionsHeaderInput"]: {
		name: string,
	pgmPrefix: string,
	versionIds: Array<string | undefined>
};
	["CloneRecipeAndSaveAsInput"]: {
		oldRecipeName: string,
	newRecipeName: string,
	description: string,
	recipePrefix: string
};
	["SetPointInput"]: {
		meaning: number,
	userComponentId: number,
	relevant: number,
	value?: number | undefined,
	valueType?: number | undefined,
	toleranceMax?: number | undefined,
	toleranceMin?: number | undefined,
	alarmStartTimeInMin?: number | undefined,
	alarmToleranceTimeInMin?: number | undefined,
	controlOptionNo?: number | undefined,
	endControlOptionNo?: number | undefined,
	endValue?: number | undefined
};
	["PositionHeaderInput"]: {
		positionNo: number,
	afterTimeOptionNo: number,
	afterTimeInMin?: number | undefined,
	estimatedTimeInMin?: number | undefined,
	chamberId: number,
	description: string,
	operationId: number,
	endOr?: number | undefined,
	valueDepth?: number | undefined
};
	["RecipePositionInput"]: {
		header: GraphQLTypes["PositionHeaderInput"],
	setPoints: Array<GraphQLTypes["SetPointInput"] | undefined>
};
	["SpEntriesInput"]: {
		furnaceSwitchEntry?: Array<string | undefined> | undefined,
	recipeDescription2?: string | undefined,
	recipeDescription3?: string | undefined,
	recipeDescription4?: string | undefined,
	gasEntryValues?: Array<string | undefined> | undefined
};
	["AnalysisInput"]: {
		c?: number | undefined,
	si?: number | undefined,
	mn?: number | undefined,
	cr?: number | undefined,
	ni?: number | undefined,
	v?: number | undefined,
	al?: number | undefined,
	cu?: number | undefined,
	p?: number | undefined,
	mo?: number | undefined,
	s?: number | undefined,
	b?: number | undefined,
	ti?: number | undefined
};
	["MaterialInput"]: {
		carbonContent?: number | undefined,
	alloyFactor?: number | undefined,
	legFactorN?: number | undefined,
	carbideLimit?: number | undefined,
	temperature?: number | undefined,
	specialNitrides?: number | undefined,
	analysis?: GraphQLTypes["AnalysisInput"] | undefined,
	co?: number | undefined,
	h2?: number | undefined,
	quenchingIntensity?: number | undefined,
	representativeDiameter?: number | undefined,
	requiredHardness?: number | undefined,
	grainSize?: number | undefined,
	endTemperature?: number | undefined,
	targetSurfC?: number | undefined,
	customerMaterialId?: number | undefined,
	partId?: number | undefined
};
	["RecipeHeaderInput"]: {
		recipeName: string,
	description: string,
	comment?: string | undefined,
	processId: number,
	processType: number,
	processDepth?: number | undefined,
	processDepthAt?: number | undefined,
	diff: boolean,
	correction: boolean
};
	["SaveNewRecipeInput"]: {
		recipePrefix: string,
	header: GraphQLTypes["RecipeHeaderInput"],
	positions: Array<GraphQLTypes["RecipePositionInput"] | undefined>,
	spEntries?: GraphQLTypes["SpEntriesInput"] | undefined,
	material: GraphQLTypes["MaterialInput"],
	correctionProfile?: Array<number | undefined> | undefined
};
	["saveEditedRecipeVersionInput"]: {
		recipePrefix: string,
	headId: number,
	editedVersionNo: number,
	editedRecipeName: string,
	createNewVersion: boolean,
	header: GraphQLTypes["RecipeHeaderInput"],
	positions: Array<GraphQLTypes["RecipePositionInput"] | undefined>,
	spEntries?: GraphQLTypes["SpEntriesInput"] | undefined,
	material: GraphQLTypes["MaterialInput"],
	correctionProfile?: Array<number | undefined> | undefined
};
	["PeAttributeType"]: {
	__typename: "PeAttributeType",
	id?: number | undefined,
	peAttributeTypeId?: number | undefined,
	forDiffCalculation?: boolean | undefined,
	forNitriding?: boolean | undefined,
	hasMinimumLimit?: boolean | undefined,
	hasMaximumLimit?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["PeAttributeTypeResult"]: {
	__typename: "PeAttributeTypeResult",
	isSuccess?: boolean | undefined,
	peAttributeType?: GraphQLTypes["PeAttributeType"] | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["CreatePeAttributeTypeInput"]: {
		peAttributeTypeId: number,
	forDiffCalculation: boolean,
	forNitriding: boolean,
	hasMinimumLimit: boolean,
	hasMaximumLimit: boolean
};
	["EditPeAttributeTypeInput"]: {
		id: number,
	modifiedOn: number,
	peAttributeTypeId: number,
	forDiffCalculation: boolean,
	forNitriding: boolean,
	hasMinimumLimit: boolean,
	hasMaximumLimit: boolean
};
	["DeletePeAttributeTypeInput"]: {
		id: number,
	modifiedOn: number
};
	["PeProcessType"]: {
	__typename: "PeProcessType",
	id?: number | undefined,
	phenNoId?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["PeProcessTypeResult"]: {
	__typename: "PeProcessTypeResult",
	isSuccess?: boolean | undefined,
	peProcessType?: GraphQLTypes["PeProcessType"] | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["CreatePeProcessTypeInput"]: {
		phenNoId: number
};
	["EditPeProcessTypeInput"]: {
		id: number,
	phenNoId: number,
	modifiedOn: number
};
	["DeletePeProcessTypeInput"]: {
		id: number,
	modifiedOn: number
};
	["PhysicalUnit"]: {
	__typename: "PhysicalUnit",
	id?: number | undefined,
	shortDescription?: string | undefined,
	description?: string | undefined,
	typeId?: number | undefined,
	unit?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["PhysicalUnitResult"]: {
	__typename: "PhysicalUnitResult",
	isSuccess?: boolean | undefined,
	physicalUnit?: GraphQLTypes["PhysicalUnit"] | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["CreatePhysicalUnitInput"]: {
		shortDescription?: string | undefined,
	description?: string | undefined,
	typeId?: number | undefined,
	unit?: string | undefined
};
	["EditPhysicalUnitInput"]: {
		id: number,
	modifiedOn: number,
	shortDescription?: string | undefined,
	description?: string | undefined,
	typeId?: number | undefined,
	unit?: string | undefined
};
	["DeletePhysicalUnitInput"]: {
		id: number,
	modifiedOn: number
};
	["updateWorkplaceTypeInput"]: {
		number: number,
	description?: string | undefined,
	prio?: number | undefined,
	modifiedOn: number
};
	["createWorkplaceTypeInput"]: {
		number: number,
	description?: string | undefined,
	prio?: number | undefined
};
	["cloneWorkplaceTypeInput"]: {
		number: string,
	description?: string | undefined
};
	["WorkplaceTypeResult"]: {
	__typename: "WorkplaceTypeResult",
	workplaceType?: GraphQLTypes["SHARED_WorkplaceType"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["BackgroundProcess"]: {
	__typename: "BackgroundProcess",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	argument?: string | undefined,
	active?: string | undefined,
	pid?: number | undefined,
	activeTime?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ProcessResult"]: {
	__typename: "ProcessResult",
	process?: GraphQLTypes["BackgroundProcess"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["DeleteProcessInput"]: {
		id: number,
	modifiedOn: number
};
	["CloneProcessInput"]: {
		id: number,
	name: string,
	description: string
};
	["EditProcessInput"]: {
		id: number,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	argument?: string | undefined,
	active?: string | undefined,
	pid?: number | undefined,
	activeTime?: number | undefined,
	modifiedOn: number
};
	["CreateNewProcessInput"]: {
		name: string,
	description: string,
	order?: number | undefined,
	argument?: string | undefined,
	active?: string | undefined,
	pid?: number | undefined,
	activeTime?: number | undefined
};
	["createWorkplaceInput"]: {
		id: string,
	name?: string | undefined,
	description?: string | undefined,
	inventoryNumber?: string | undefined,
	type?: number | undefined,
	active?: number | undefined,
	useMode?: number | undefined,
	logicType?: string | undefined,
	recipePrefix?: string | undefined,
	chamberId?: number | undefined,
	sharesController?: number | undefined,
	belongsTo?: string | undefined,
	ip?: string | undefined,
	mGuardIp?: string | undefined,
	device?: string | undefined,
	telegramSplit?: boolean | undefined,
	deactivateOperations?: boolean | undefined,
	aliveBitErrorNum?: number | undefined,
	comment?: string | undefined
};
	["updateWorkplaceInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	inventoryNumber?: string | undefined,
	type?: number | undefined,
	active?: number | undefined,
	useMode?: number | undefined,
	logicType?: string | undefined,
	recipePrefix?: string | undefined,
	chamberId?: number | undefined,
	sharesController?: number | undefined,
	belongsTo?: string | undefined,
	ip?: string | undefined,
	mGuardIp?: string | undefined,
	device?: string | undefined,
	telegramSplit?: boolean | undefined,
	deactivateOperations?: boolean | undefined,
	aliveBitErrorNum?: number | undefined,
	comment?: string | undefined,
	modifiedOn: number
};
	["cloneWorkplaceInput"]: {
		id: string,
	name?: string | undefined,
	description?: string | undefined
};
	["WorkplaceResult"]: {
	__typename: "WorkplaceResult",
	workplace?: GraphQLTypes["SHARED_Workplace"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["OperationType"]: {
	__typename: "OperationType",
	id?: number | undefined,
	description?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["OperationPart"]: {
	__typename: "OperationPart",
	id?: number | undefined,
	description?: string | undefined,
	order?: number | undefined
};
	["OperationProcess"]: {
	__typename: "OperationProcess",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	sap?: string | undefined
};
	["CreateOperationProcess"]: {
		order?: number | undefined,
	sap?: string | undefined,
	workplaceId: number
};
	["Operation"]: {
	__typename: "Operation",
	id?: number | undefined,
	processTypeId?: number | undefined,
	name?: string | undefined,
	operationType?: string | undefined,
	comment?: string | undefined,
	order?: number | undefined,
	relevantForStat?: boolean | undefined,
	mode?: number | undefined,
	typeId?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined,
	operationProcess?: Array<GraphQLTypes["OperationProcess"] | undefined> | undefined,
	operationParts?: Array<GraphQLTypes["OperationPart"] | undefined> | undefined
};
	["CreateOperation"]: {
		processTypeId?: number | undefined,
	name: string,
	comment: string,
	order: number,
	relevantForStat: boolean,
	mode: number,
	typeId: number,
	operationProcess?: Array<GraphQLTypes["CreateOperationProcess"] | undefined> | undefined,
	operationParts?: Array<number | undefined> | undefined
};
	["EditOperation"]: {
		id: number,
	modifiedOn: number,
	processTypeId?: number | undefined,
	comment: string,
	order: number,
	relevantForStat: boolean,
	mode: number,
	typeId: number,
	operationProcess?: Array<GraphQLTypes["CreateOperationProcess"] | undefined> | undefined,
	operationParts?: Array<number | undefined> | undefined
};
	["DeleteOperation"]: {
		id: number,
	modifiedOn: number
};
	["OperationResult"]: {
	__typename: "OperationResult",
	isSuccess?: boolean | undefined,
	operation?: GraphQLTypes["Operation"] | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["Alarm"]: {
	__typename: "Alarm",
	id?: number | undefined,
	workplace?: string | undefined,
	workplaceId?: number | undefined,
	alarm?: number | undefined,
	alarmText?: string | undefined,
	errorType?: number | undefined,
	outputType?: number | undefined,
	security?: number | undefined,
	color?: number | undefined,
	onlyForChamber?: number | undefined,
	causesATestingLoad?: boolean | undefined,
	alarmGroup?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ChambersPerWorkplace"]: {
	__typename: "ChambersPerWorkplace",
	workplaceId?: number | undefined,
	chamberList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined
};
	["AlarmAdministrationMetadata"]: {
	__typename: "AlarmAdministrationMetadata",
	workplaceList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	errorTypeList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	outputTypeList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	alarmGroupList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	importanceList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	colorList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	chambersList?: Array<GraphQLTypes["ChambersPerWorkplace"] | undefined> | undefined
};
	["AlarmResult"]: {
	__typename: "AlarmResult",
	alarm?: GraphQLTypes["Alarm"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["CreateNewAlarmInput"]: {
		workplaceId: number,
	alarmNo: number,
	alarmText: string,
	errorTypeId: number,
	outputTypeId: number,
	importanceId?: number | undefined,
	security: number,
	colorId: number,
	alarmGroupId: number,
	causeTestingLoad: boolean,
	onlyForChamber: number
};
	["CloneAndSaveAsInput"]: {
		id: number,
	alarmNo: number
};
	["EditAlarmInput"]: {
		id: number,
	alarmNo?: number | undefined,
	modifiedOn: number,
	workplaceId?: number | undefined,
	alarmText?: string | undefined,
	errorTypeId?: number | undefined,
	outputTypeId?: number | undefined,
	importanceId?: number | undefined,
	security?: number | undefined,
	colorId?: number | undefined,
	onlyForChamber?: number | undefined,
	alarmGroupId?: number | undefined,
	causeTestingLoad?: boolean | undefined
};
	["DeleteAlarmInput"]: {
		id: number,
	modifiedOn: number
};
	["DeleteSelectedAlarmsForFurnaceInput"]: {
		workplaceId: number,
	selectedAlarms: Array<GraphQLTypes["DeleteAlarmInput"] | undefined>
};
	["Box"]: {
	__typename: "Box",
	id?: number | undefined,
	name?: string | undefined,
	colorId?: number | undefined,
	used?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ColorBox"]: {
	__typename: "ColorBox",
	id?: number | undefined,
	name?: string | undefined,
	redValue?: number | undefined,
	greenValue?: number | undefined,
	blueValue?: number | undefined
};
	["CreateBoxInput"]: {
		name: string,
	colorId: number,
	used: boolean
};
	["EditBoxInput"]: {
		id: number,
	name?: string | undefined,
	colorId?: number | undefined,
	used?: boolean | undefined,
	modifiedOn: number
};
	["DeleteBoxInput"]: {
		id: number,
	modifiedOn: number
};
	["BoxResult"]: {
	__typename: "BoxResult",
	isSuccess?: boolean | undefined,
	box?: GraphQLTypes["Box"] | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["Color"]: {
	__typename: "Color",
	id?: number | undefined,
	name?: string | undefined,
	redValue?: number | undefined,
	greenValue?: number | undefined,
	blueValue?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ColorResult"]: {
	__typename: "ColorResult",
	isSuccess?: boolean | undefined,
	color?: GraphQLTypes["Color"] | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["CreateColorInput"]: {
		name: string,
	redValue: number,
	greenValue: number,
	blueValue: number
};
	["EditColorInput"]: {
		id: number,
	modifiedOn: number,
	name?: string | undefined,
	redValue?: number | undefined,
	greenValue?: number | undefined,
	blueValue?: number | undefined
};
	["deleteColorInput"]: {
		id: number,
	modifiedOn: number
};
	["LanguageInfo"]: {
	__typename: "LanguageInfo",
	languageId?: number | undefined,
	description?: string | undefined
};
	["TranslationInfo"]: {
	__typename: "TranslationInfo",
	languageId?: number | undefined,
	translation?: string | undefined
};
	["DictionaryItem"]: {
	__typename: "DictionaryItem",
	textId?: number | undefined,
	defaultText?: string | undefined,
	peProperty?: string | undefined,
	used?: boolean | undefined,
	type?: number | undefined,
	translations?: Array<GraphQLTypes["TranslationInfo"] | undefined> | undefined,
	customerTranslations?: Array<GraphQLTypes["TranslationInfo"] | undefined> | undefined
};
	["ExternalizeTranslationConfigValue"]: {
	__typename: "ExternalizeTranslationConfigValue",
	value?: boolean | undefined
};
	["SmsSettings"]: {
	__typename: "SmsSettings",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	addresseeMobilePhoneNo?: string | undefined,
	pin?: string | undefined,
	serialInterface?: string | undefined,
	testMessage?: boolean | undefined,
	active?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["Task"]: {
	__typename: "Task",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	active?: boolean | undefined,
	type?: string | undefined,
	command1?: string | undefined,
	command2?: string | undefined,
	command3?: string | undefined,
	command4?: string | undefined,
	command5?: string | undefined,
	command6?: string | undefined,
	command7?: string | undefined,
	command8?: string | undefined,
	command9?: string | undefined,
	command10?: string | undefined,
	startHour?: number | undefined,
	intervalInMin?: number | undefined,
	dateLastActive?: number | undefined,
	comment?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TaskResult"]: {
	__typename: "TaskResult",
	task?: GraphQLTypes["Task"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["createTaskInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	active?: boolean | undefined,
	type?: string | undefined,
	command1?: string | undefined,
	command2?: string | undefined,
	command3?: string | undefined,
	command4?: string | undefined,
	command5?: string | undefined,
	command6?: string | undefined,
	command7?: string | undefined,
	command8?: string | undefined,
	command9?: string | undefined,
	command10?: string | undefined,
	startHour?: number | undefined,
	intervalInMin?: number | undefined,
	dateLastActive?: number | undefined,
	comment?: string | undefined
};
	["updateTaskInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	active?: boolean | undefined,
	type?: string | undefined,
	command1?: string | undefined,
	command2?: string | undefined,
	command3?: string | undefined,
	command4?: string | undefined,
	command5?: string | undefined,
	command6?: string | undefined,
	command7?: string | undefined,
	command8?: string | undefined,
	command9?: string | undefined,
	command10?: string | undefined,
	startHour?: number | undefined,
	intervalInMin?: number | undefined,
	comment?: string | undefined,
	modifiedOn: number
};
	["cloneTaskInput"]: {
		name: string,
	description?: string | undefined,
	comment?: string | undefined
};
	["updateClientAccessClientListInput"]: {
		clientId: number,
	isActive: boolean
};
	["updateClientAccessResult"]: {
	__typename: "updateClientAccessResult",
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["TerminalType"]: {
	__typename: "TerminalType",
	id?: number | undefined,
	description?: string | undefined,
	osTypeId?: number | undefined,
	remark?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TerminalTypeResult"]: {
	__typename: "TerminalTypeResult",
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined,
	terminalType?: GraphQLTypes["TerminalType"] | undefined
};
	["CreateTerminalTypeInput"]: {
		description?: string | undefined,
	osTypeId: number,
	remark?: string | undefined
};
	["EditTerminalTypeInput"]: {
		id: number,
	modifiedOn: number,
	description?: string | undefined,
	osTypeId?: number | undefined,
	remark?: string | undefined
};
	["DeleteTerminalTypeInput"]: {
		id: number,
	modifiedOn: number
};
	["TerminalClient"]: {
	__typename: "TerminalClient",
	id?: number | undefined,
	clientTypeId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: number | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserId?: number | undefined,
	actLoginTime?: number | undefined,
	lastUserId?: number | undefined,
	lastLoginTime?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["TerminalClientResult"]: {
	__typename: "TerminalClientResult",
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined,
	terminalClient?: GraphQLTypes["TerminalClient"] | undefined
};
	["CreateTerminalClientInput"]: {
		clientTypeId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: number | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserId?: number | undefined,
	actLoginTime?: number | undefined,
	lastUserId?: number | undefined,
	lastLoginTime?: number | undefined
};
	["EditTerminalClientInput"]: {
		id: number,
	modifiedOn: number,
	clientTypeId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	adress?: string | undefined,
	location?: string | undefined,
	remark?: string | undefined,
	resolution?: number | undefined,
	valueLong1?: number | undefined,
	valueLong2?: number | undefined,
	valueLong3?: number | undefined,
	valueLong4?: number | undefined,
	valueLong5?: number | undefined,
	valueFloat1?: number | undefined,
	valueFloat2?: number | undefined,
	valueFloat3?: number | undefined,
	valueChar1?: string | undefined,
	valueChar2?: string | undefined,
	actUserId?: number | undefined,
	actLoginTime?: number | undefined,
	lastUserId?: number | undefined,
	lastLoginTime?: number | undefined
};
	["DeleteTerminalClientInput"]: {
		id: number,
	modifiedOn: number
};
	["TerminalClientMetadata"]: {
	__typename: "TerminalClientMetadata",
	resolutions?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	users?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	clientTypes?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined
};
	["ApplicationGroup"]: {
	__typename: "ApplicationGroup",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	order?: number | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ApplicationGroupResult"]: {
	__typename: "ApplicationGroupResult",
	applicationGroup?: GraphQLTypes["ApplicationGroup"] | undefined,
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined
};
	["CreateAppGroupInput"]: {
		name: string,
	description?: string | undefined,
	order?: number | undefined
};
	["EditAppGroupInput"]: {
		id: number,
	name: string,
	description?: string | undefined,
	order?: number | undefined,
	modifiedOn: number
};
	["DeleteAppGroupInput"]: {
		id: number,
	modifiedOn: number
};
	["Application"]: {
	__typename: "Application",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	command?: string | undefined,
	parameter?: string | undefined,
	tooltip?: string | undefined,
	furnacePicture?: string | undefined,
	iconGifName?: string | undefined,
	order?: number | undefined,
	typeId?: number | undefined,
	groupId?: number | undefined,
	startRecipeProcessFlow?: boolean | undefined,
	modifyRecipeProcessFlow?: boolean | undefined,
	stopRecipeProcessFlow?: boolean | undefined,
	startCorrectionRecipe?: boolean | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["ApplicationResult"]: {
	__typename: "ApplicationResult",
	isSuccess?: boolean | undefined,
	error?: GraphQLTypes["Error"] | undefined,
	application?: GraphQLTypes["Application"] | undefined
};
	["CreateApplicationInput"]: {
		name: string,
	description?: string | undefined,
	command?: string | undefined,
	parameter?: string | undefined,
	tooltip?: string | undefined,
	furnacePicture?: string | undefined,
	iconGifName?: string | undefined,
	order?: number | undefined,
	typeId?: number | undefined,
	groupId: number,
	startRecipeProcessFlow: boolean,
	modifyRecipeProcessFlow: boolean,
	stopRecipeProcessFlow: boolean,
	startCorrectionRecipe: boolean
};
	["EditApplicationInput"]: {
		id: number,
	name: string,
	description?: string | undefined,
	command?: string | undefined,
	parameter?: string | undefined,
	tooltip?: string | undefined,
	furnacePicture?: string | undefined,
	iconGifName?: string | undefined,
	order?: number | undefined,
	typeId?: number | undefined,
	groupId: number,
	startRecipeProcessFlow: boolean,
	modifyRecipeProcessFlow: boolean,
	stopRecipeProcessFlow: boolean,
	startCorrectionRecipe: boolean,
	modifiedOn: number
};
	["DeleteApplicationInput"]: {
		id: number,
	modifiedOn: number
};
	["ApplicationMetadata"]: {
	__typename: "ApplicationMetadata",
	applicationGroupList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined,
	applicationTypeList?: Array<GraphQLTypes["SHARED_OptionItem"] | undefined> | undefined
};
	["ProcessFlow"]: {
	__typename: "ProcessFlow",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	comments?: Array<string | undefined> | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["RecipeMenuItem"]: {
	__typename: "RecipeMenuItem",
	id?: number | undefined,
	workplaceId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	recipePrefix?: string | undefined
};
	["OperationMenuItem"]: {
	__typename: "OperationMenuItem",
	id?: number | undefined,
	name?: string | undefined,
	operationType?: string | undefined,
	comment?: string | undefined
};
	["ProcessFlowStep"]: {
	__typename: "ProcessFlowStep",
	ptime?: number | undefined,
	order?: number | undefined,
	workplace?: GraphQLTypes["SHARED_Workplace"] | undefined,
	recipe?: GraphQLTypes["RecipeMenuItem"] | undefined,
	operation?: GraphQLTypes["OperationMenuItem"] | undefined
};
	["ProcessFlowPartComparisonItem"]: {
	__typename: "ProcessFlowPartComparisonItem",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	description2?: string | undefined
};
	["UserAccess"]: {
	__typename: "UserAccess",
	user?: string | undefined,
	userId?: number | undefined,
	group?: string | undefined,
	pgmId?: number | undefined,
	application?: string | undefined,
	run?: boolean | undefined,
	newEntry?: boolean | undefined,
	save?: boolean | undefined,
	delete?: boolean | undefined,
	special1?: boolean | undefined,
	print?: boolean | undefined,
	startRecipeProcessFlow?: boolean | undefined,
	modifyRecipeProcessFlow?: boolean | undefined,
	stopRecipeProcessFlow?: boolean | undefined,
	startCorrectionRecipe?: boolean | undefined
};
	["updateUserAccessInput"]: {
		userId: number,
	pgmId: number,
	run?: boolean | undefined,
	newEntry?: boolean | undefined,
	save?: boolean | undefined,
	delete?: boolean | undefined,
	special1?: boolean | undefined,
	print?: boolean | undefined,
	startRecipeProcessFlow?: boolean | undefined,
	modifyRecipeProcessFlow?: boolean | undefined,
	stopRecipeProcessFlow?: boolean | undefined,
	startCorrectionRecipe?: boolean | undefined
};
	["UserGroup"]: {
	__typename: "UserGroup",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	modifiedOn?: number | undefined,
	modifiedBy?: string | undefined
};
	["createUserGroupInput"]: {
		description?: string | undefined,
	name: string
};
	["updateUserGroupInput"]: {
		name?: string | undefined,
	description?: string | undefined
};
	["createUserInput"]: {
		firstName?: string | undefined,
	lastName?: string | undefined,
	shortName: string,
	function?: string | undefined,
	password?: string | undefined,
	comment?: string | undefined,
	employeeNumber?: string | undefined,
	plant?: string | undefined,
	costCenter?: string | undefined,
	maintenancePopups?: boolean | undefined,
	playAlarmSound?: boolean | undefined,
	accessLevel?: number | undefined,
	language?: number | undefined,
	userGroup: number,
	picture?: string | undefined
};
	["updateUserInput"]: {
		firstName?: string | undefined,
	lastName?: string | undefined,
	function?: string | undefined,
	password?: string | undefined,
	comment?: string | undefined,
	employeeNumber?: string | undefined,
	plant?: string | undefined,
	costCenter?: string | undefined,
	maintenancePopups?: boolean | undefined,
	playAlarmSound?: boolean | undefined,
	accessLevel?: number | undefined,
	language?: number | undefined,
	userGroup?: number | undefined,
	picture?: string | undefined
};
	["TO_REMOVE_RecipeMenuItem"]: {
	__typename: "TO_REMOVE_RecipeMenuItem",
	id?: number | undefined,
	workplaceId?: number | undefined,
	name?: string | undefined,
	description?: string | undefined,
	recipePrefix?: string | undefined
};
	["TO_REMOVE_ShopLayoutMenuItem"]: {
	__typename: "TO_REMOVE_ShopLayoutMenuItem",
	id?: number | undefined,
	name?: string | undefined,
	description?: string | undefined
}
    }
export const enum SearchComponentType {
	String = "String",
	Checkbox = "Checkbox",
	Numeric = "Numeric",
	Select = "Select",
	Button = "Button"
}

type ZEUS_VARIABLES = {
	["EditBoxValueInput"]: ValueTypes["EditBoxValueInput"];
	["ButtonInput"]: ValueTypes["ButtonInput"];
	["createUsageInput"]: ValueTypes["createUsageInput"];
	["updateUsageInput"]: ValueTypes["updateUsageInput"];
	["cloneUsageInput"]: ValueTypes["cloneUsageInput"];
	["SearchComponentType"]: ValueTypes["SearchComponentType"];
	["UtilizationMode"]: ValueTypes["UtilizationMode"];
	["Filter"]: ValueTypes["Filter"];
	["DeleteImageInput"]: ValueTypes["DeleteImageInput"];
	["CreateCustomerSpecificDataInput"]: ValueTypes["CreateCustomerSpecificDataInput"];
	["EditCustomerSpecificDataInput"]: ValueTypes["EditCustomerSpecificDataInput"];
	["DeleteCustomerSpecificDataInput"]: ValueTypes["DeleteCustomerSpecificDataInput"];
	["RecipeVersionsHeaderInput"]: ValueTypes["RecipeVersionsHeaderInput"];
	["CloneRecipeAndSaveAsInput"]: ValueTypes["CloneRecipeAndSaveAsInput"];
	["SetPointInput"]: ValueTypes["SetPointInput"];
	["PositionHeaderInput"]: ValueTypes["PositionHeaderInput"];
	["RecipePositionInput"]: ValueTypes["RecipePositionInput"];
	["SpEntriesInput"]: ValueTypes["SpEntriesInput"];
	["AnalysisInput"]: ValueTypes["AnalysisInput"];
	["MaterialInput"]: ValueTypes["MaterialInput"];
	["RecipeHeaderInput"]: ValueTypes["RecipeHeaderInput"];
	["SaveNewRecipeInput"]: ValueTypes["SaveNewRecipeInput"];
	["saveEditedRecipeVersionInput"]: ValueTypes["saveEditedRecipeVersionInput"];
	["CreatePeAttributeTypeInput"]: ValueTypes["CreatePeAttributeTypeInput"];
	["EditPeAttributeTypeInput"]: ValueTypes["EditPeAttributeTypeInput"];
	["DeletePeAttributeTypeInput"]: ValueTypes["DeletePeAttributeTypeInput"];
	["CreatePeProcessTypeInput"]: ValueTypes["CreatePeProcessTypeInput"];
	["EditPeProcessTypeInput"]: ValueTypes["EditPeProcessTypeInput"];
	["DeletePeProcessTypeInput"]: ValueTypes["DeletePeProcessTypeInput"];
	["CreatePhysicalUnitInput"]: ValueTypes["CreatePhysicalUnitInput"];
	["EditPhysicalUnitInput"]: ValueTypes["EditPhysicalUnitInput"];
	["DeletePhysicalUnitInput"]: ValueTypes["DeletePhysicalUnitInput"];
	["updateWorkplaceTypeInput"]: ValueTypes["updateWorkplaceTypeInput"];
	["createWorkplaceTypeInput"]: ValueTypes["createWorkplaceTypeInput"];
	["cloneWorkplaceTypeInput"]: ValueTypes["cloneWorkplaceTypeInput"];
	["DeleteProcessInput"]: ValueTypes["DeleteProcessInput"];
	["CloneProcessInput"]: ValueTypes["CloneProcessInput"];
	["EditProcessInput"]: ValueTypes["EditProcessInput"];
	["CreateNewProcessInput"]: ValueTypes["CreateNewProcessInput"];
	["createWorkplaceInput"]: ValueTypes["createWorkplaceInput"];
	["updateWorkplaceInput"]: ValueTypes["updateWorkplaceInput"];
	["cloneWorkplaceInput"]: ValueTypes["cloneWorkplaceInput"];
	["CreateOperationProcess"]: ValueTypes["CreateOperationProcess"];
	["CreateOperation"]: ValueTypes["CreateOperation"];
	["EditOperation"]: ValueTypes["EditOperation"];
	["DeleteOperation"]: ValueTypes["DeleteOperation"];
	["CreateNewAlarmInput"]: ValueTypes["CreateNewAlarmInput"];
	["CloneAndSaveAsInput"]: ValueTypes["CloneAndSaveAsInput"];
	["EditAlarmInput"]: ValueTypes["EditAlarmInput"];
	["DeleteAlarmInput"]: ValueTypes["DeleteAlarmInput"];
	["DeleteSelectedAlarmsForFurnaceInput"]: ValueTypes["DeleteSelectedAlarmsForFurnaceInput"];
	["CreateBoxInput"]: ValueTypes["CreateBoxInput"];
	["EditBoxInput"]: ValueTypes["EditBoxInput"];
	["DeleteBoxInput"]: ValueTypes["DeleteBoxInput"];
	["CreateColorInput"]: ValueTypes["CreateColorInput"];
	["EditColorInput"]: ValueTypes["EditColorInput"];
	["deleteColorInput"]: ValueTypes["deleteColorInput"];
	["createTaskInput"]: ValueTypes["createTaskInput"];
	["updateTaskInput"]: ValueTypes["updateTaskInput"];
	["cloneTaskInput"]: ValueTypes["cloneTaskInput"];
	["updateClientAccessClientListInput"]: ValueTypes["updateClientAccessClientListInput"];
	["CreateTerminalTypeInput"]: ValueTypes["CreateTerminalTypeInput"];
	["EditTerminalTypeInput"]: ValueTypes["EditTerminalTypeInput"];
	["DeleteTerminalTypeInput"]: ValueTypes["DeleteTerminalTypeInput"];
	["CreateTerminalClientInput"]: ValueTypes["CreateTerminalClientInput"];
	["EditTerminalClientInput"]: ValueTypes["EditTerminalClientInput"];
	["DeleteTerminalClientInput"]: ValueTypes["DeleteTerminalClientInput"];
	["CreateAppGroupInput"]: ValueTypes["CreateAppGroupInput"];
	["EditAppGroupInput"]: ValueTypes["EditAppGroupInput"];
	["DeleteAppGroupInput"]: ValueTypes["DeleteAppGroupInput"];
	["CreateApplicationInput"]: ValueTypes["CreateApplicationInput"];
	["EditApplicationInput"]: ValueTypes["EditApplicationInput"];
	["DeleteApplicationInput"]: ValueTypes["DeleteApplicationInput"];
	["updateUserAccessInput"]: ValueTypes["updateUserAccessInput"];
	["createUserGroupInput"]: ValueTypes["createUserGroupInput"];
	["updateUserGroupInput"]: ValueTypes["updateUserGroupInput"];
	["createUserInput"]: ValueTypes["createUserInput"];
	["updateUserInput"]: ValueTypes["updateUserInput"];
}