export const createModule = (services) => {
  const {
    getMenuRecipes,
  } = services.recipes;

  // initial state
  return {
    state: {
      menuList: [],
    },
    namespaced: true,

    // getters
    getters: {
      getMenuItem: (state) => (workplaceId) => {
        return state.menuList.find((el) => el.workplaceId == workplaceId);
      },
    },

    // actions
    actions: {
      async getAllMenuRecipes({
        commit,
      }, location) {
        await getMenuRecipes(location)
          .then((res) => {
            if (res.data && res.data.menuRecipes) {
              commit('setMenuList', res.data.menuRecipes);
            }
          })
          .catch(() => {
            commit('setMenuList', []);
          });
      },
    },

    // mutations
    mutations: {
      setMenuList(state, list) {
        state.menuList = list;
      },
    },
  };
};
