import {
  portalApi, fetchApiClient,
} from '@/services/AxiosService';
import {
  SIMULATOR_CHART_PATH,
} from '@/constants/apiPaths';
import {
  ChunkTransformStream,
} from './utils/ChunkTransformStream';
import {
  JSONStreamReaderController,
} from './utils/JSONStreamReaderController';

let activeStreamReaderAbortController = null;

export const SimulatorService = {
  activeStreamReaderController: null,
  getSimulatorChartData(payload, {
    chartType, calculationModel,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${SIMULATOR_CHART_PATH}`, payload, {
          params: {
            chart: chartType,
            calculation_model: calculationModel,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  cancelSimulatorChartDataStream() {
    if (activeStreamReaderAbortController) {
      activeStreamReaderAbortController.abort();
      activeStreamReaderAbortController = null;
    }
  },
  async getSimulatorChartDataStream(
    payload,
    {
      calculationModel,
    },
    onStart,
    onData,
    onComplete,
  ) {
    SimulatorService.cancelSimulatorChartDataStream();

    activeStreamReaderAbortController = new AbortController();
    const signal = activeStreamReaderAbortController.signal;

    return fetchApiClient
      .put(`${SIMULATOR_CHART_PATH}/stream`, payload, {
        params: {
          calculation_model: calculationModel,
        },
        signal,
      })
      .then((response) => {
        if (onStart) {
          onStart();
        }
        return response.body.pipeThrough(
          new TransformStream(new ChunkTransformStream()),
          {
            signal,
          },
        );
      })
      .then((rs) => {
        return new JSONStreamReaderController(rs, onData, onComplete);
      });
  },
};
