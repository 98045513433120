/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		shopLayoutView:{

		},
		updateShopLayoutValues:{

		},
		shopLayoutAlarms:{

		},
		shopLayoutAlarmsSearch:{

		},
		shopLayoutLoadList:{

		},
		shopLayoutLoadListGeneral:{

		},
		shopLayoutRecipeList:{

		},
		furnaceView:{

		},
		furnaceViewAlarms:{

		},
		furnaceViewAlarmsSearch:{

		},
		updateFurnaceViews:{

		},
		furnaceChartUdmData:{

		},
		furnaceChartMetaData:{

		},
		furnaceChartRecorder:{

		},
		furnaceLoadList:{

		},
		actualRecipeOfWorkplace:{

		},
		furnaceLoadListGeneral:{

		},
		furnaceRecipeList:{

		},
		furnaceChartDataFirstTimestamp:{

		},
		alarmStatistics:{

		},
		alarmList:{

		},
		furnaceBookAlarms:{

		},
		getImages:{

		},
		profileChart:{

		},
		furnaceBook:{
			filter:"Filter"
		},
		productionLogsDummyCharts:{

		},
		productionLogsChartRecorder:{

		},
		chargeConsumption:{

		},
		usage:{

		},
		utilization:{
			mode:"UtilizationMode"
		},
		recipeOfLoad:{

		},
		partInfo:{

		},
		partsStatistics:{
			filter:"Filter"
		},
		partManagement:{

		},
		partsWorkplaceRecipeList:{

		},
		recipeList:{

		},
		recipeItem:{

		},
		getRecipeMetadata:{

		},
		operationPartList:{

		},
		operationProcessList:{

		},
		clientAccessClientList:{

		},
		processFlowStepList:{

		},
		operationWorkplaceRecipeList:{

		},
		processFlowPartComparison:{

		},
		operationWorkplaceList:{

		},
		loadList:{

		},
		chartRecorder:{

		},
		partWorkplaceRecipeList:{

		},
		clientList:{

		}
	},
	Mutation:{
		shopLayoutAcknowledgeAlarms:{

		},
		furnaceAcknowledgeAlarms:{

		},
		setFurnaceEditBoxValues:{
			data:"EditBoxValueInput"
		},
		setFurnaceButtonValue:{
			data:"ButtonInput"
		},
		createUsage:{
			data:"createUsageInput"
		},
		updateUsage:{
			data:"updateUsageInput"
		},
		deleteUsage:{

		},
		cloneUsage:{
			data:"cloneUsageInput"
		},
		deleteImage:{
			input:"DeleteImageInput"
		},
		createCustomerSpecificData:{
			data:"CreateCustomerSpecificDataInput"
		},
		editCustomerSpecificData:{
			data:"EditCustomerSpecificDataInput"
		},
		deleteCustomerSpecificData:{
			data:"DeleteCustomerSpecificDataInput"
		},
		deleteRecipeVersions:{
			data:"RecipeVersionsHeaderInput"
		},
		cloneRecipeAndSaveAs:{
			data:"CloneRecipeAndSaveAsInput"
		},
		saveNewRecipe:{
			data:"SaveNewRecipeInput"
		},
		saveEditedRecipeVersion:{
			data:"saveEditedRecipeVersionInput"
		},
		createPeAttributeType:{
			data:"CreatePeAttributeTypeInput"
		},
		editPeAttributeType:{
			data:"EditPeAttributeTypeInput"
		},
		deletePeAttributeType:{
			data:"DeletePeAttributeTypeInput"
		},
		createPeProcessType:{
			data:"CreatePeProcessTypeInput"
		},
		editPeProcessType:{
			data:"EditPeProcessTypeInput"
		},
		deletePeProcessType:{
			data:"DeletePeProcessTypeInput"
		},
		createPhysicalUnit:{
			data:"CreatePhysicalUnitInput"
		},
		editPhysicalUnit:{
			data:"EditPhysicalUnitInput"
		},
		deletePhysicalUnit:{
			data:"DeletePhysicalUnitInput"
		},
		createWorkplaceType:{
			data:"createWorkplaceTypeInput"
		},
		updateWorkplaceType:{
			data:"updateWorkplaceTypeInput"
		},
		deleteWorkplaceType:{

		},
		cloneWorkplaceType:{
			data:"cloneWorkplaceTypeInput"
		},
		deleteBackgroundProcess:{
			data:"DeleteProcessInput"
		},
		cloneAndSaveAsBackgroundProcess:{
			data:"CloneProcessInput"
		},
		editBackgroundProcess:{
			data:"EditProcessInput"
		},
		createNewBackgroundProcess:{
			data:"CreateNewProcessInput"
		},
		createWorkplace:{
			data:"createWorkplaceInput"
		},
		updateWorkplace:{
			data:"updateWorkplaceInput"
		},
		deleteWorkplace:{

		},
		cloneWorkplace:{
			data:"cloneWorkplaceInput"
		},
		createOperation:{
			data:"CreateOperation"
		},
		editOperation:{
			data:"EditOperation"
		},
		deleteOperation:{
			data:"DeleteOperation"
		},
		cloneAndSaveAsAlarm:{
			data:"CloneAndSaveAsInput"
		},
		createNewAlarm:{
			data:"CreateNewAlarmInput"
		},
		editAlarm:{
			data:"EditAlarmInput"
		},
		deleteAlarm:{
			data:"DeleteAlarmInput"
		},
		deleteSelectedAlarmsForFurnace:{
			data:"DeleteSelectedAlarmsForFurnaceInput"
		},
		createBox:{
			data:"CreateBoxInput"
		},
		editBox:{
			data:"EditBoxInput"
		},
		deleteBox:{
			data:"DeleteBoxInput"
		},
		createColor:{
			data:"CreateColorInput"
		},
		editColor:{
			data:"EditColorInput"
		},
		deleteColor:{
			data:"deleteColorInput"
		},
		createTask:{
			data:"createTaskInput"
		},
		updateTask:{
			data:"updateTaskInput"
		},
		deleteTask:{

		},
		cloneTask:{
			data:"cloneTaskInput"
		},
		updateClientAccessClientList:{
			data:"updateClientAccessClientListInput"
		},
		createTerminalType:{
			data:"CreateTerminalTypeInput"
		},
		editTerminalType:{
			data:"EditTerminalTypeInput"
		},
		deleteTerminalType:{
			data:"DeleteTerminalTypeInput"
		},
		createTerminalClient:{
			data:"CreateTerminalClientInput"
		},
		editTerminalClient:{
			data:"EditTerminalClientInput"
		},
		deleteTerminalClient:{
			data:"DeleteTerminalClientInput"
		},
		createApplicationGroup:{
			data:"CreateAppGroupInput"
		},
		editApplicationGroup:{
			data:"EditAppGroupInput"
		},
		deleteApplicationGroup:{
			data:"DeleteAppGroupInput"
		},
		createApplication:{
			data:"CreateApplicationInput"
		},
		editApplication:{
			data:"EditApplicationInput"
		},
		deleteApplication:{
			data:"DeleteApplicationInput"
		},
		updateUserAccess:{
			data:"updateUserAccessInput"
		},
		createUserGroup:{
			data:"createUserGroupInput"
		},
		updateUserGroup:{
			data:"updateUserGroupInput"
		},
		deleteUserGroup:{

		},
		createUser:{
			data:"createUserInput"
		},
		updateUser:{
			data:"updateUserInput"
		},
		deleteUser:{

		}
	},
	EditBoxValueInput:{

	},
	ButtonInput:{

	},
	createUsageInput:{

	},
	updateUsageInput:{

	},
	cloneUsageInput:{

	},
	SearchComponentType: "enum" as const,
	UtilizationMode:{

	},
	Filter:{

	},
	DeleteImageInput:{

	},
	CreateCustomerSpecificDataInput:{

	},
	EditCustomerSpecificDataInput:{

	},
	DeleteCustomerSpecificDataInput:{

	},
	RecipeVersionsHeaderInput:{

	},
	CloneRecipeAndSaveAsInput:{

	},
	SetPointInput:{

	},
	PositionHeaderInput:{

	},
	RecipePositionInput:{
		header:"PositionHeaderInput",
		setPoints:"SetPointInput"
	},
	SpEntriesInput:{

	},
	AnalysisInput:{

	},
	MaterialInput:{
		analysis:"AnalysisInput"
	},
	RecipeHeaderInput:{

	},
	SaveNewRecipeInput:{
		header:"RecipeHeaderInput",
		positions:"RecipePositionInput",
		spEntries:"SpEntriesInput",
		material:"MaterialInput"
	},
	saveEditedRecipeVersionInput:{
		header:"RecipeHeaderInput",
		positions:"RecipePositionInput",
		spEntries:"SpEntriesInput",
		material:"MaterialInput"
	},
	CreatePeAttributeTypeInput:{

	},
	EditPeAttributeTypeInput:{

	},
	DeletePeAttributeTypeInput:{

	},
	CreatePeProcessTypeInput:{

	},
	EditPeProcessTypeInput:{

	},
	DeletePeProcessTypeInput:{

	},
	CreatePhysicalUnitInput:{

	},
	EditPhysicalUnitInput:{

	},
	DeletePhysicalUnitInput:{

	},
	updateWorkplaceTypeInput:{

	},
	createWorkplaceTypeInput:{

	},
	cloneWorkplaceTypeInput:{

	},
	DeleteProcessInput:{

	},
	CloneProcessInput:{

	},
	EditProcessInput:{

	},
	CreateNewProcessInput:{

	},
	createWorkplaceInput:{

	},
	updateWorkplaceInput:{

	},
	cloneWorkplaceInput:{

	},
	CreateOperationProcess:{

	},
	CreateOperation:{
		operationProcess:"CreateOperationProcess"
	},
	EditOperation:{
		operationProcess:"CreateOperationProcess"
	},
	DeleteOperation:{

	},
	CreateNewAlarmInput:{

	},
	CloneAndSaveAsInput:{

	},
	EditAlarmInput:{

	},
	DeleteAlarmInput:{

	},
	DeleteSelectedAlarmsForFurnaceInput:{
		selectedAlarms:"DeleteAlarmInput"
	},
	CreateBoxInput:{

	},
	EditBoxInput:{

	},
	DeleteBoxInput:{

	},
	CreateColorInput:{

	},
	EditColorInput:{

	},
	deleteColorInput:{

	},
	createTaskInput:{

	},
	updateTaskInput:{

	},
	cloneTaskInput:{

	},
	updateClientAccessClientListInput:{

	},
	CreateTerminalTypeInput:{

	},
	EditTerminalTypeInput:{

	},
	DeleteTerminalTypeInput:{

	},
	CreateTerminalClientInput:{

	},
	EditTerminalClientInput:{

	},
	DeleteTerminalClientInput:{

	},
	CreateAppGroupInput:{

	},
	EditAppGroupInput:{

	},
	DeleteAppGroupInput:{

	},
	CreateApplicationInput:{

	},
	EditApplicationInput:{

	},
	DeleteApplicationInput:{

	},
	updateUserAccessInput:{

	},
	createUserGroupInput:{

	},
	updateUserGroupInput:{

	},
	createUserInput:{

	},
	updateUserInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Error:{
		code:"String",
		message:"String",
		detail:"String"
	},
	SHARED_ChartPoint:{
		timestamp:"Int",
		value:"Float"
	},
	SHARED_Chart:{
		chartName:"String",
		varId:"Int",
		chartColor:"Int",
		chartUnit:"String",
		data:"SHARED_ChartPoint"
	},
	UDM_ChartData:{
		varId:"Int",
		data:"SHARED_ChartPoint"
	},
	ChartMetaData:{
		varId:"Int",
		chartName:"String",
		chartColor:"Int",
		chartUnit:"String"
	},
	SHARED_LoadRecipeHeader:{
		name:"String",
		description:"String"
	},
	SHARED_PartData:{
		pos:"Int",
		partName:"String",
		partDescription:"String",
		amount:"Int",
		weightInKg:"Float",
		totalWeightInKg:"Float",
		orderName:"String",
		orderRemark:"String",
		type:"String",
		customer:"String",
		intern:"Int"
	},
	SHARED_FurnaceSystemStatus:{
		entranceReady:"Boolean",
		exitReady:"Boolean"
	},
	SHARED_Load:{
		id:"ID",
		name:"String",
		identifier:"String",
		workplaceId:"ID",
		start:"Int",
		end:"Int",
		earliestTimeStart:"Int",
		packedTime:"Int",
		packedUser:"String",
		startedUser:"String",
		status:"String",
		recipe:"SHARED_LoadRecipeHeader",
		parts:"SHARED_PartData"
	},
	SHARED_Workplace:{
		id:"ID",
		name:"String",
		description:"String",
		inventoryNumber:"String",
		type:"SHARED_WorkplaceType",
		active:"SHARED_ActiveState",
		useMode:"SHARED_UseMode",
		logicType:"String",
		recipePrefix:"String",
		chamberId:"Int",
		sharesController:"Int",
		belongsTo:"ID",
		ip:"String",
		mGuardIp:"String",
		device:"String",
		telegramSplit:"Boolean",
		deactivateOperations:"Boolean",
		aliveBitErrorNum:"Int",
		comment:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	SHARED_WorkplaceType:{
		number:"Int",
		description:"String",
		prio:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	SHARED_ActiveState:{
		string:"String",
		int:"Int"
	},
	SHARED_UseMode:{
		string:"String",
		int:"Int"
	},
	SHARED_OptionItem:{
		id:"Int",
		value:"String"
	},
	SHARED_SpEntries:{
		furnaceSwitchEntry:"String",
		recipeDescription2:"String",
		recipeDescription3:"String",
		recipeDescription4:"String",
		gasEntryValues:"String"
	},
	SHARED_DigitalOutput:{
		name:"String",
		value:"Boolean"
	},
	SHARED_Material:{
		carbonContent:"Float",
		alloyFactor:"Float",
		legFactorN:"Float",
		carbideLimit:"Int",
		temperature:"Int",
		specialNitrides:"Float",
		analysis:"SHARED_ElementValueList",
		co:"Float",
		h2:"Float",
		quenchingIntensity:"Float",
		representativeDiameter:"Float",
		requiredHardness:"Int",
		grainSize:"Int",
		endTemperature:"Int",
		targetSurfC:"Float",
		customerMaterialId:"Int"
	},
	SHARED_PropertyInfo:{
		property:"String",
		target:"Float",
		unit:"String"
	},
	SHARED_PartInfo:{
		id:"Int",
		name:"String",
		materialId:"Int",
		materialName:"String",
		description1:"String",
		description2:"String",
		drawingNumber:"Int",
		revisionNumber:"Int",
		testPlanId:"Int",
		testPlanDescription:"String",
		comment:"String",
		properties:"SHARED_PropertyInfo",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	SHARED_WorkplaceRecipe:{
		id:"Int",
		name:"String",
		description:"String",
		version_no:"Int"
	},
	SHARED_ElementValueList:{
		c:"Float",
		si:"Float",
		mn:"Float",
		cr:"Float",
		ni:"Float",
		v:"Float",
		al:"Float",
		cu:"Float",
		p:"Float",
		s:"Float",
		mo:"Float",
		b:"Float",
		ti:"Float"
	},
	SHARED_OLD_CustomerMaterial:{
		id:"Int",
		name:"String",
		description:"String",
		normId:"Int",
		normName:"String",
		normDescription:"String",
		groupId:"Int",
		groupName:"String",
		elementValues:"SHARED_ElementValueList",
		averageAlloyFactor:"Float",
		averageAlloyFactorN:"Float",
		carbideLimit:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	SHARED_DropDownListItem:{
		id:"Int",
		value:"String",
		isActive:"Boolean"
	},
	SHARED_User:{
		id:"Int",
		firstName:"String",
		lastName:"String",
		shortName:"String",
		function:"String",
		password:"String",
		comment:"String",
		employeeNumber:"String",
		plant:"String",
		costCenter:"String",
		maintenancePopups:"Boolean",
		playAlarmSound:"Boolean",
		loginTimeInMin:"Int",
		accessLevel:"Int",
		languageId:"Int",
		language:"SHARED_DropDownListItem",
		userGroupId:"Int",
		userGroup:"SHARED_DropDownListItem",
		modifiedOn:"Int",
		modifiedBy:"String",
		picture:"String"
	},
	SHARED_Client:{
		id:"Int",
		clientType:"String",
		name:"String",
		description:"String",
		adress:"String",
		location:"String",
		remark:"String",
		resolution:"String",
		valueLong1:"Int",
		valueLong2:"Int",
		valueLong3:"Int",
		valueLong4:"Int",
		valueLong5:"Int",
		valueFloat1:"Int",
		valueFloat2:"Int",
		valueFloat3:"Int",
		valueChar1:"String",
		valueChar2:"String",
		actUserName:"String",
		actLoginTime:"Int",
		lastUserName:"String",
		lastLoginTime:"Int",
		modifiedOn:"Int",
		modifiedBy:"String",
		isActive:"Boolean"
	},
	SHARED_Operation:{
		id:"Int",
		name:"String",
		operationType:"String",
		comment:"String",
		order:"Int",
		relevantForStat:"Int",
		mode:"String",
		type:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	SHARED_ValueViewComponent:{
		id:"Int",
		type:"String",
		value:"Float",
		status:"Int",
		digitsBeforeComma:"Int",
		digitsAfterComma:"Int",
		textBefore:"String",
		textAfter:"String",
		xPos:"Int",
		yPos:"Int",
		colorIndex:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		colorBG:"Int",
		numberFormat:"String",
		fontSize:"Int"
	},
	SHARED_BackgroundView:{
		id:"Int",
		type:"String",
		filePath:"String",
		xPos:"Int",
		yPos:"Int",
		width:"Int",
		height:"Int"
	},
	SHARED_TextView:{
		id:"Int",
		type:"String",
		value:"String",
		xPos:"Int",
		yPos:"Int",
		color:"Int",
		colorBG:"Int",
		fontSize:"Int"
	},
	SHARED_RecipeVersion:{
		id:"Int",
		startingTime:"Int",
		versionText:"String"
	},
	SHARED_RecipeShortHeader:{
		recipeName:"String",
		actualStep:"Int",
		loadIdentifier:"String",
		loadName:"String",
		versions:"SHARED_RecipeVersion"
	},
	SHARED_LoadColumn:{
		name:"String",
		value:"String"
	},
	SHARED_LoadButton:{
		id:"Int",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		xPos0:"Int",
		yPos0:"Int",
		xPos1:"Int",
		yPos1:"Int",
		xPos2:"Int",
		yPos2:"Int",
		xPos3:"Int",
		yPos3:"Int",
		colorIndex:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		xSize:"Int",
		ySize:"Int",
		chamberId:"Int"
	},
	SHARED_Bitmap:{
		id:"Int",
		type:"String",
		filePath:"String",
		xPos:"Int",
		yPos:"Int",
		xPos0:"Int",
		yPos0:"Int",
		xPos1:"Int",
		yPos1:"Int",
		colorIndex:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		colorBG:"Int"
	},
	SHARED_Gif:{
		id:"Int",
		type:"String",
		xPos0:"Int",
		yPos0:"Int",
		xPos1:"Int",
		yPos1:"Int",
		xPos2:"Int",
		yPos2:"Int",
		xPos3:"Int",
		yPos3:"Int",
		xPos:"Int",
		yPos:"Int",
		filePath:"String"
	},
	SHARED_MovingPic:{
		id:"Int",
		type:"String",
		filePath:"String",
		startX:"Int",
		startY:"Int",
		endX:"Int",
		endY:"Int",
		width:"Int",
		height:"Int",
		componentZero:"Int",
		componentSpan:"Int"
	},
	SHARED_Label:{
		id:"Int",
		type:"String",
		text:"String",
		xPos:"Int",
		yPos:"Int",
		colorIndex:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		colorBG:"Int",
		fontSize:"Int"
	},
	SHARED_LabelPlus:{
		id:"Int",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		colorIndex:"Int",
		colorBG:"Int",
		text:"String",
		text0:"String",
		text1:"String",
		text2:"String",
		text3:"String",
		fontSize:"Int"
	},
	SHARED_Line:{
		id:"Int",
		xPos:"Int",
		yPos:"Int",
		width:"Int",
		height:"Int",
		type:"String",
		colorIndex:"Int"
	},
	SHARED_EditBox:{
		id:"Int",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		xPos0:"Int",
		yPos0:"Int",
		xPos1:"Int",
		yPos1:"Int",
		xPos2:"Int",
		yPos2:"Int",
		xPos3:"Int",
		yPos3:"Int",
		colorIndex:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		colorBG:"Int",
		xSize:"Int",
		ySize:"Int",
		focusOrderNumber:"Int",
		variableType:"Int",
		isEditable:"Boolean",
		digitsBeforeComma:"Int",
		digitsAfterComma:"Int",
		minValue:"Float",
		maxValue:"Float",
		numberFormat:"String",
		fontSize:"Int",
		editValue:"Float",
		componentTypeId:"Int",
		userInputId:"Int",
		outputId:"Int"
	},
	SHARED_ProgressBar:{
		id:"Int",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		xSize:"Int",
		ySize:"Int",
		fullColor:"Int",
		emptyColor:"Int",
		colorBG:"Int",
		value:"Float",
		minValue:"Float",
		maxValue:"Float"
	},
	SHARED_Place:{
		id:"Int",
		xPos:"Int",
		yPos:"Int",
		xSize:"Int",
		ySize:"Int",
		layoutOrder:"Int",
		name:"String",
		colorPalette:"String",
		colorBG:"Int",
		colorIndex:"Int",
		systemColorBG:"Int"
	},
	SHARED_Rectangle:{
		id:"Int",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		xPos0:"Int",
		yPos0:"Int",
		xPos1:"Int",
		yPos1:"Int",
		xPos2:"Int",
		yPos2:"Int",
		xPos3:"Int",
		yPos3:"Int",
		colorIndex:"Int",
		colorIndex0:"Int",
		colorIndex1:"Int",
		colorIndex2:"Int",
		colorIndex3:"Int",
		colorBG:"Int",
		xSize:"Int",
		ySize:"Int"
	},
	SHARED_ChargeCarItem:{
		wpId:"Int",
		name:"String",
		infoText:"String",
		placeId:"Int",
		placeName:"String",
		placeColor:"Int",
		placeColorBG:"Int",
		posX:"Int"
	},
	SHARED_ChargeCarAutFurnaceDriving:{
		driverWpId:"Int",
		overWpId:"Int",
		furnaceWidth:"Int",
		furnaceHeight:"Int",
		furnaceOverviewNo:"Int",
		furnacePlace:"Int"
	},
	SHARED_ChargeCar:{
		id:"Int",
		carType:"String",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		xSize:"Int",
		ySize:"Int",
		layoutOrder:"Int",
		imageName:"String",
		car:"SHARED_ChargeCarItem",
		carList:"SHARED_ChargeCarItem",
		railMaxPosPixels:"Int",
		railMaxPosMm:"Int",
		autFurnaceDriving:"SHARED_ChargeCarAutFurnaceDriving",
		mode:"String"
	},
	ShopLayoutChargeStatus:{
		id:"String",
		colorBG:"Int",
		color:"Int",
		colorBorder:"Int"
	},
	ShopLayoutView:{
		workplaceId:"Int",
		state:"String",
		systemStatus:"SHARED_FurnaceSystemStatus",
		chargeStatusList:"ShopLayoutChargeStatus",
		recipeHeader:"SHARED_RecipeShortHeader",
		id:"Int",
		type:"String",
		name:"String",
		text:"SHARED_TextView",
		value:"SHARED_ValueViewComponent",
		background:"SHARED_BackgroundView",
		loadButton:"SHARED_LoadButton",
		place:"SHARED_Place",
		xPos:"Int",
		yPos:"Int",
		xSize:"Int",
		ySize:"Int",
		bitmap:"SHARED_Bitmap",
		gif:"SHARED_Gif",
		label:"SHARED_Label",
		labelPlus:"SHARED_LabelPlus",
		line:"SHARED_Line",
		editBox:"SHARED_EditBox",
		progressBar:"SHARED_ProgressBar",
		rectangle:"SHARED_Rectangle",
		chargeCar:"SHARED_ChargeCar"
	},
	ShopLayoutViewData:{
		workplaceId:"Int",
		state:"String",
		systemStatus:"SHARED_FurnaceSystemStatus",
		recipeHeader:"SHARED_RecipeShortHeader",
		loadButton:"SHARED_LoadButton",
		id:"Int",
		type:"String",
		name:"String",
		value:"SHARED_ValueViewComponent",
		place:"SHARED_Place",
		bitmap:"SHARED_Bitmap",
		gif:"SHARED_Gif",
		label:"SHARED_Label",
		labelPlus:"SHARED_LabelPlus",
		line:"SHARED_Line",
		editBox:"SHARED_EditBox",
		progressBar:"SHARED_ProgressBar",
		rectangle:"SHARED_Rectangle",
		chargeCar:"SHARED_ChargeCar"
	},
	ShopLayoutAlarm:{
		alarmId:"Int",
		workplaceName:"String",
		startTime:"Int",
		acknowledgeTime:"Int",
		endTime:"Int",
		priority:"String",
		alarmMessage:"String",
		gate:"Int",
		alarmNum:"Int",
		byte:"Int",
		bit:"Int"
	},
	ShopLayoutAlarmSearch:{
		workplaceName:"String",
		time:"Int",
		event:"String",
		alarmText:"String",
		alarmNum:"Int"
	},
	ShopLayoutLoadListGeneral:{
		columnNames:"SHARED_LoadColumn",
		workplaceGeneral:"SHARED_Workplace",
		parts:"SHARED_PartInfo"
	},
	ShopLayoutRecipeList:{
		workplaceId:"Int",
		id:"Int",
		name:"String",
		description:"String"
	},
	Query:{
		shopLayoutView:"ShopLayoutView",
		updateShopLayoutValues:"ShopLayoutViewData",
		shopLayoutAlarms:"ShopLayoutAlarm",
		shopLayoutAlarmsSearch:"ShopLayoutAlarmSearch",
		shopLayoutLoadList:"SHARED_Load",
		shopLayoutLoadListGeneral:"ShopLayoutLoadListGeneral",
		shopLayoutRecipeList:"ShopLayoutRecipeList",
		furnaceView:"FurnaceView",
		furnaceViewAlarms:"FurnaceViewAlarm",
		furnaceViewAlarmsSearch:"FurnaceViewAlarmSearch",
		updateFurnaceViews:"updateFurnaceViewsResult",
		furnaceChartUdmData:"UDM_ChartData",
		furnaceChartMetaData:"ChartMetaData",
		furnaceChartRecorder:"SHARED_Chart",
		furnaceLoadList:"SHARED_Load",
		actualRecipeOfWorkplace:"ActRecipeVersion",
		furnaceLoadListGeneral:"FurnaceLoadListGeneral",
		furnaceRecipeList:"SHARED_WorkplaceRecipe",
		listOfAvailableFurnaces:"Int",
		furnaceChartDataFirstTimestamp:"Int",
		alarmStatistics:"AlarmStatisticInfo",
		alarmList:"AlarmInfo",
		alarmWorkplaces:"WorkplaceInfo",
		manageUsage:"Usage",
		furnaceBookAlarms:"FurnaceBookAlarm",
		furnaceBookFilters:"FurnaceBookFilters",
		getImages:"Image",
		profileChart:"ProfileChart",
		furnaceBook:"ProductionLog",
		productionLogsDummyCharts:"SHARED_Chart",
		productionLogsChartRecorder:"SHARED_Chart",
		chargeConsumption:"ChargeConsumption",
		usage:"UsageEntry",
		utilization:"Utilization",
		cptEntries:"CptEntry",
		recipeOfLoad:"RecipeROL",
		partInfo:"Part",
		partsStatistics:"PartsStatistics",
		productionLogsOperationList:"SHARED_Operation",
		failureReasonAdministration:"FailureReason",
		fixtureAdministration:"Fixture",
		fixtureTypes:"FixtureType",
		customerSpecificData:"CustomerSpecificData",
		engineeringUnitList:"EngineeringUnit",
		userUnitsList:"UserUnits",
		customerMaterialList:"SHARED_OLD_CustomerMaterial",
		materialGroup:"OLD_MaterialGroup",
		normMaterialList:"OLD_NormMaterial",
		parts:"SHARED_PartInfo",
		partManagement:"PartManagementInfo",
		partsWorkplaceRecipeList:"SHARED_WorkplaceRecipe",
		testPlanList:"TestPlan",
		toolingList:"Tooling",
		partsCustomerMaterialList:"SHARED_OLD_CustomerMaterial",
		recipeList:"RecipeListItem",
		recipeItem:"RecipeItemVersion",
		getRecipeMetadata:"RecipeMetadata",
		peAttributeType:"PeAttributeType",
		peAttributeTypePhenNoList:"SHARED_OptionItem",
		peProcessType:"PeProcessType",
		peProcessTypePhenNoList:"SHARED_OptionItem",
		physicalUnit:"PhysicalUnit",
		physicalUnitMetadata:"SHARED_OptionItem",
		workplaceTypeList:"SHARED_WorkplaceType",
		backgroundProcessList:"BackgroundProcess",
		getBackgroundProcessStateList:"String",
		workplaceList:"SHARED_Workplace",
		workplaceDataGeneralList:"SHARED_Workplace",
		workplaceUseModeList:"SHARED_UseMode",
		workplaceDataWpTypeList:"SHARED_WorkplaceType",
		operationAdministrationOperationList:"Operation",
		operationTypeList:"OperationType",
		operationPartList:"OperationPart",
		operationProcessList:"OperationProcess",
		operationMetadata:"SHARED_OptionItem",
		alarmAdministration:"Alarm",
		alarmAdministrationMetadata:"AlarmAdministrationMetadata",
		boxAdministration:"Box",
		boxMetadata:"ColorBox",
		colorAdministration:"Color",
		languageList:"LanguageInfo",
		externalizeTranslation:"ExternalizeTranslationConfigValue",
		dictionaryAdministration:"DictionaryItem",
		smsAdministration:"SmsSettings",
		systemTaskAdministration:"Task",
		clientAccessUserList:"SHARED_User",
		clientAccessClientList:"SHARED_Client",
		terminalTypeList:"TerminalType",
		terminalTypeMetadata:"SHARED_OptionItem",
		terminalClientList:"TerminalClient",
		terminalClientMetadata:"TerminalClientMetadata",
		applicationGroup:"ApplicationGroup",
		applicationList:"Application",
		applicationMetadata:"ApplicationMetadata",
		processFlowList:"ProcessFlow",
		processFlowStepList:"ProcessFlowStep",
		operationWorkplaceRecipeList:"RecipeMenuItem",
		processFlowPartComparison:"ProcessFlowPartComparisonItem",
		processFlowOperationList:"SHARED_Operation",
		operationWorkplaceList:"SHARED_Workplace",
		userAccessList:"UserAccess",
		userGroup:"UserGroup",
		userList:"SHARED_User",
		menuRecipes:"TO_REMOVE_RecipeMenuItem",
		shopLayoutMenuItems:"TO_REMOVE_ShopLayoutMenuItem",
		workplaceGeneralList:"SHARED_Workplace",
		loadColumnList:"SHARED_LoadColumn",
		loadList:"SHARED_Load",
		chartRecorder:"SHARED_Chart",
		partList:"SHARED_PartInfo",
		partWorkplaceRecipeList:"SHARED_WorkplaceRecipe",
		clientList:"SHARED_Client",
		operationList:"SHARED_Operation"
	},
	Mutation:{
		shopLayoutAcknowledgeAlarms:"ShopLayoutAlarm",
		furnaceAcknowledgeAlarms:"FurnaceViewAlarm",
		setFurnaceEditBoxValues:"SetFurnaceEditBoxValuesResult",
		setFurnaceButtonValue:"ButtonValueResult",
		createUsage:"UsageResult",
		updateUsage:"UsageResult",
		deleteUsage:"UsageResult",
		cloneUsage:"UsageResult",
		deleteImage:"Boolean",
		createCustomerSpecificData:"CustomerSpecificDataResult",
		editCustomerSpecificData:"CustomerSpecificDataResult",
		deleteCustomerSpecificData:"CustomerSpecificDataResult",
		deleteRecipeVersions:"Boolean",
		cloneRecipeAndSaveAs:"CloneRecipeAndSaveAsResult",
		saveNewRecipe:"SaveRecipeResult",
		saveEditedRecipeVersion:"SaveRecipeResult",
		createPeAttributeType:"PeAttributeTypeResult",
		editPeAttributeType:"PeAttributeTypeResult",
		deletePeAttributeType:"PeAttributeTypeResult",
		createPeProcessType:"PeProcessTypeResult",
		editPeProcessType:"PeProcessTypeResult",
		deletePeProcessType:"PeProcessTypeResult",
		createPhysicalUnit:"PhysicalUnitResult",
		editPhysicalUnit:"PhysicalUnitResult",
		deletePhysicalUnit:"PhysicalUnitResult",
		createWorkplaceType:"WorkplaceTypeResult",
		updateWorkplaceType:"WorkplaceTypeResult",
		deleteWorkplaceType:"WorkplaceTypeResult",
		cloneWorkplaceType:"WorkplaceTypeResult",
		deleteBackgroundProcess:"ProcessResult",
		cloneAndSaveAsBackgroundProcess:"ProcessResult",
		editBackgroundProcess:"ProcessResult",
		createNewBackgroundProcess:"ProcessResult",
		createWorkplace:"WorkplaceResult",
		updateWorkplace:"WorkplaceResult",
		deleteWorkplace:"WorkplaceResult",
		cloneWorkplace:"WorkplaceResult",
		createOperation:"OperationResult",
		editOperation:"OperationResult",
		deleteOperation:"OperationResult",
		cloneAndSaveAsAlarm:"AlarmResult",
		createNewAlarm:"AlarmResult",
		editAlarm:"AlarmResult",
		deleteAlarm:"AlarmResult",
		deleteSelectedAlarmsForFurnace:"Alarm",
		createBox:"BoxResult",
		editBox:"BoxResult",
		deleteBox:"BoxResult",
		createColor:"ColorResult",
		editColor:"ColorResult",
		deleteColor:"ColorResult",
		createTask:"TaskResult",
		updateTask:"TaskResult",
		deleteTask:"TaskResult",
		cloneTask:"TaskResult",
		updateClientAccessClientList:"updateClientAccessResult",
		createTerminalType:"TerminalTypeResult",
		editTerminalType:"TerminalTypeResult",
		deleteTerminalType:"TerminalTypeResult",
		createTerminalClient:"TerminalClientResult",
		editTerminalClient:"TerminalClientResult",
		deleteTerminalClient:"TerminalClientResult",
		createApplicationGroup:"ApplicationGroupResult",
		editApplicationGroup:"ApplicationGroupResult",
		deleteApplicationGroup:"ApplicationGroupResult",
		createApplication:"ApplicationResult",
		editApplication:"ApplicationResult",
		deleteApplication:"ApplicationResult",
		updateUserAccess:"UserAccess",
		createUserGroup:"UserGroup",
		updateUserGroup:"UserGroup",
		deleteUserGroup:"Boolean",
		createUser:"SHARED_User",
		updateUser:"SHARED_User",
		deleteUser:"Boolean"
	},
	HourInfo:{
		id:"Int",
		xPos:"Int",
		yPos:"Int",
		fontSize:"Int",
		text:"String",
		hourType:"Int",
		zoneId:"Int",
		time:"Int",
		colorIndex:"Int",
		colorBG:"Int"
	},
	LoadInfo:{
		id:"Int",
		xPos:"Int",
		yPos:"Int",
		fontSize:"Int",
		text:"String",
		time:"Int",
		infoType:"Int",
		colorIndex:"Int",
		colorBG:"Int"
	},
	Button:{
		id:"Int",
		type:"String",
		xPos:"Int",
		yPos:"Int",
		userInputId:"Int",
		name:"String",
		mode:"Int",
		userLevel:"Int",
		btnText0:"String",
		btnText1:"String",
		helpText0:"String",
		helpText1:"String",
		value:"Int"
	},
	furnaceViewButton:{
		name:"String",
		id:"Int",
		mode:"Int",
		userLevel:"Int",
		btnText0:"String",
		btnText1:"String",
		helpText0:"String",
		helpText1:"String"
	},
	FurnaceViewComponents:{
		id:"Int",
		name:"String",
		background:"SHARED_BackgroundView",
		text:"SHARED_TextView",
		value:"SHARED_ValueViewComponent",
		hourInfo:"HourInfo",
		loadInfo:"LoadInfo",
		loadButton:"SHARED_LoadButton",
		bitmap:"SHARED_Bitmap",
		label:"SHARED_Label",
		labelPlus:"SHARED_LabelPlus",
		rectangle:"SHARED_Rectangle",
		button:"Button",
		gif:"SHARED_Gif",
		movingPic:"SHARED_MovingPic",
		place:"SHARED_Place",
		progressBar:"SHARED_ProgressBar",
		editBox:"SHARED_EditBox",
		line:"SHARED_Line",
		windowSizeId:"Int"
	},
	FurnaceValueViewComponent:{
		id:"Int",
		name:"String",
		status:"Int",
		value:"SHARED_ValueViewComponent",
		hourInfo:"HourInfo",
		loadInfo:"LoadInfo",
		place:"SHARED_Place",
		bitmap:"SHARED_Bitmap",
		gif:"SHARED_Gif",
		movingPic:"SHARED_MovingPic",
		editBox:"SHARED_EditBox",
		labelPlus:"SHARED_LabelPlus",
		label:"SHARED_Label",
		progressBar:"SHARED_ProgressBar",
		loadButton:"SHARED_LoadButton",
		rectangle:"SHARED_Rectangle",
		button:"Button",
		line:"SHARED_Line"
	},
	updateFurnaceViewsResult:{
		workplaceId:"Int",
		state:"String",
		hold:"Int",
		remote:"Int",
		connected:"Int",
		updateTime:"Int",
		furnaceViews:"FurnaceValueViewComponent",
		recipeHeader:"SHARED_RecipeShortHeader"
	},
	SystemColor:{
		id:"Int",
		r:"Int",
		g:"Int",
		b:"Int"
	},
	FurnaceView:{
		workplaceId:"Int",
		state:"String",
		hold:"Int",
		remote:"Int",
		connected:"Int",
		updateTime:"Int",
		colors:"SystemColor",
		furnaceViews:"FurnaceViewComponents",
		recipeHeader:"SHARED_RecipeShortHeader"
	},
	FurnaceViewAlarm:{
		alarmId:"Int",
		workplaceName:"String",
		startTime:"Int",
		acknowledgeTime:"Int",
		endTime:"Int",
		priority:"String",
		alarmMessage:"String",
		gate:"Int",
		alarmNum:"Int",
		byte:"Int",
		bit:"Int"
	},
	FurnaceViewAlarmSearch:{
		workplaceName:"String",
		time:"Int",
		event:"String",
		alarmText:"String",
		alarmNum:"Int"
	},
	ActRecipeHeader:{
		id:"Int",
		name:"String",
		recipePrefix:"String",
		description:"String",
		comment:"String",
		version:"Int",
		processOption:"SHARED_OptionItem",
		processType:"Int",
		processDepth:"Float",
		processDepthAt:"Float",
		correction:"Boolean",
		diff:"Boolean",
		loadIdentifier:"String",
		loadName:"String",
		actualStep:"Int",
		startingTime:"Int",
		user:"SHARED_OptionItem"
	},
	ActRecipePositionHeader:{
		positionNo:"Int",
		chamberOption:"SHARED_OptionItem",
		chamberOperationOption:"SHARED_OptionItem",
		description:"String",
		afterTimeInMin:"Int",
		estimatedDurationInMin:"Int",
		actualDurationInMin:"Int",
		afterOption:"SHARED_OptionItem"
	},
	ActSetPoint:{
		userComponentId:"Int",
		valueType:"Int",
		meaning:"Int",
		name:"String",
		control:"SHARED_OptionItem",
		value:"Float",
		unit:"String",
		toleranceMax:"Float",
		toleranceMin:"Float",
		alarmStartTimeInMin:"Int",
		alarmToleranceTimeInMin:"Int",
		actualValue:"Float",
		endControl:"SHARED_OptionItem",
		endValue:"Float"
	},
	ActRecipePosition:{
		header:"ActRecipePositionHeader",
		setpoints:"ActSetPoint",
		digitalOutputList:"SHARED_DigitalOutput",
		digitalOutputs:"String",
		endAfter:"String"
	},
	ActRecipeVersion:{
		header:"ActRecipeHeader",
		positions:"ActRecipePosition",
		spEntries:"SHARED_SpEntries",
		material:"SHARED_Material",
		correctionProfile:"Float"
	},
	FurnaceViewError:{
		code:"String",
		message:"String",
		detail:"String"
	},
	SetFurnaceEditBoxValuesResult:{
		isSuccess:"Boolean",
		error:"FurnaceViewError"
	},
	FurnaceLoadListGeneral:{
		columnNames:"SHARED_LoadColumn",
		parts:"SHARED_PartInfo"
	},
	ButtonValueResult:{
		value:"Int",
		isSuccess:"Boolean",
		error:"FurnaceViewError"
	},
	AlarmEvent:{
		event:"String",
		time:"Int"
	},
	AlarmStatisticInfo:{
		alarmNo:"Int",
		description:"String",
		quantity:"Int",
		durationInSec:"Int",
		quantityPercentage:"Float",
		durationPercentage:"Float",
		events:"AlarmEvent"
	},
	AlarmInfo:{
		alarmNo:"Int",
		alarmSeverity:"Int",
		alarmText:"String"
	},
	WorkplaceInfo:{
		id:"Int",
		description:"String"
	},
	Usage:{
		id:"Int",
		name:"String",
		description:"String",
		unit:"String",
		divisor:"Int",
		summaryCounter:"Boolean",
		overflow:"Int",
		validSince:"Int",
		pricePerUnit:"Float",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	UsageResult:{
		usage:"Usage",
		isSuccess:"Boolean",
		error:"Error"
	},
	SearchFilter:{
		filterName:"String",
		displayName:"String",
		category:"String",
		type:"SearchComponentType",
		value:"String",
		isChecked:"Boolean"
	},
	FurnaceBookFilters:{
		workplaces:"SHARED_Workplace",
		filters:"SearchFilter"
	},
	HeaderLoadTreatment:{
		recipeName:"String",
		description:"String",
		loadIdentifier:"String"
	},
	ContainerPosition:{
		partName:"String",
		order:"String",
		amount:"Int",
		totalWeightInKg:"Float"
	},
	ProductionLog:{
		workplace:"SHARED_Workplace",
		startingTime:"Int",
		endingTime:"Int",
		durationInSec:"Int",
		loadId:"Int",
		loadName:"String",
		alarms:"String",
		header:"HeaderLoadTreatment",
		positions:"ContainerPosition",
		box:"Int"
	},
	FurnaceBookAlarm:{
		messageNo:"Int",
		event:"String",
		time:"Int",
		text:"String"
	},
	ProfileChartData:{
		yUnit:"String",
		data:"Float"
	},
	ProfileChart:{
		restaust:"Float",
		surfC:"Float",
		xUnit:"String",
		charts:"ProfileChartData"
	},
	Consumption:{
		media:"String",
		amount:"Float",
		unit:"String",
		costs:"Float"
	},
	ChargeConsumption:{
		consumption:"Consumption",
		totalCosts:"Float",
		currency:"String"
	},
	UsageEntry:{
		workplace:"String",
		chargesCounter:"Int",
		production:"ChargeConsumption",
		averagePerCharge:"ChargeConsumption",
		standBy:"ChargeConsumption",
		total:"ChargeConsumption"
	},
	UsageValue:{
		unit:"String",
		value:"Float"
	},
	UtilizationTotal:{
		workplace:"String",
		numberOfLoads:"Int",
		numberOfLoadsEvaluated:"Int",
		loadsRatio:"UsageValue",
		totalTime:"UsageValue",
		productionTime:"UsageValue",
		timeRatio:"UsageValue",
		averageLoadingTime:"UsageValue",
		availability:"UsageValue",
		downtime:"UsageValue",
		downtimeReason:"UsageValue",
		downtimeNoReason:"UsageValue",
		utilization:"UsageValue",
		capacityLoss:"UsageValue",
		kpi:"Float",
		targetWeight:"UsageValue",
		produced:"UsageValue",
		weightRatio:"UsageValue",
		flowRate:"UsageValue"
	},
	UtilizationPeriod:{
		period:"String",
		target:"UsageValue",
		actual:"UsageValue",
		utilizationRate:"UsageValue"
	},
	UtilizationUsage:{
		workplace:"String",
		loadsEvaluated:"Float",
		runtime:"UsageValue",
		weight:"UsageValue",
		usage:"UsageValue",
		usageLoad:"UsageValue",
		usageRuntime:"UsageValue"
	},
	Utilization:{
		totalInfo:"UtilizationTotal",
		periodInfo:"UtilizationPeriod",
		usageInfo:"UtilizationUsage"
	},
	CptEntry:{
		id:"ID",
		name:"String"
	},
	RecipeHeaderROL:{
		chargeIdentifier:"String",
		recipeName:"String",
		description:"String"
	},
	RecipeVersionROL:{
		id:"Int",
		startingTime:"Int",
		versionText:"String"
	},
	SetpointROL:{
		name:"String",
		control:"String",
		value:"Float",
		unit:"String",
		toleranceMax:"Float",
		toleranceMin:"Float",
		alarmStartTimeInMin:"Int",
		alarmToleranceTimeInMin:"Int",
		actualValue:"Float",
		endControl:"String",
		endValue:"Float"
	},
	DigitalOutputROL:{
		name:"String",
		value:"Boolean"
	},
	AfterTimeROL:{
		activeOption:"Int",
		options:"SHARED_OptionItem",
		durationInSec:"Int"
	},
	StepHeaderROL:{
		chamberOptions:"SHARED_OptionItem",
		operationOptions:"SHARED_OptionItem",
		description:"String",
		estimatedDurationInSec:"Int",
		afterOptions:"AfterTimeROL"
	},
	RecipeStepROL:{
		id:"Int",
		header:"StepHeaderROL",
		chamber:"String",
		description:"String",
		startTime:"Int",
		operation:"String",
		setpoints:"SetpointROL",
		digitalOutputs:"String",
		digitalOutputList:"DigitalOutputROL",
		endAfter:"String",
		actualDurationInMin:"Int"
	},
	RecipeROL:{
		header:"RecipeHeaderROL",
		versions:"RecipeVersionROL",
		steps:"RecipeStepROL"
	},
	PartHeader:{
		contentName:"String",
		weightInKg:"Float",
		amount:"Int",
		createdAt:"Int",
		modifiedAt:"Int"
	},
	PartData:{
		pos:"Int",
		partName:"String",
		partDescription:"String",
		amount:"Int",
		weightInKg:"Float",
		totalWeightInKg:"Float",
		orderName:"String",
		orderRemark:"String",
		type:"String",
		customer:"String",
		intern:"Int"
	},
	Part:{
		header:"PartHeader",
		parts:"PartData"
	},
	PartsStatistics:{
		order:"String",
		name:"String",
		description:"String",
		amount:"Int",
		weight:"Float"
	},
	Image:{
		no:"Int",
		description:"String",
		dataBase64:"String"
	},
	FailureReason:{
		id:"Int",
		name:"String",
		description:"String",
		type:"String",
		comment:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	Fixture:{
		id:"Int",
		no:"Int",
		fixture:"String"
	},
	FixtureType:{
		id:"Int",
		name:"String",
		description:"String",
		typeOfWorkplace:"Int",
		workplace:"Int",
		maximumHeight:"Float",
		maximumWidth:"Float",
		maximumDepth:"Float",
		maximumWeight:"Float",
		minimumWeight:"Float",
		loadWeight:"Float",
		ownWeight:"Float",
		maxNumOfLoads:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	CustomerSpecificData:{
		id:"Int",
		name:"String",
		floatValue:"Float",
		longValue:"Int",
		characterValue:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	CustomerSpecificDataResult:{
		isSuccess:"Boolean",
		error:"Error",
		customerSpecificData:"CustomerSpecificData"
	},
	EngineeringUnit:{
		id:"Int",
		shortDescription:"String",
		descripton:"String",
		physicalUnit:"String",
		unit:"String",
		peToEuType:"Int",
		peToEu:"String",
		euToPeType:"Int",
		euToPeString:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	UnitValue:{
		unitValue:"String",
		default:"Boolean"
	},
	Unit:{
		name:"String",
		values:"UnitValue"
	},
	UserUnits:{
		user:"String",
		units:"Unit"
	},
	OLD_MaterialGroup:{
		id:"Int",
		name:"String",
		description:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	OLD_NormMaterial:{
		id:"Int",
		name:"String",
		description:"String",
		groupId:"Int",
		groupName:"String",
		elementValuesFrom:"SHARED_ElementValueList",
		elementValuesTo:"SHARED_ElementValueList",
		averageAlloyFactor:"Float",
		carbideLimit:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	TestPlan:{
		id:"Int",
		name:"String",
		description:"String"
	},
	Setpoints:{
		recipeId:"Int",
		recipeName:"String",
		numberOfPartsPerTray:"Int"
	},
	ToolingInfo:{
		id:"Int",
		name:"String",
		amount:"Int"
	},
	Tooling:{
		id:"Int",
		name:"String",
		description:"String"
	},
	LoadInformation:{
		packInformation:"String",
		image1Base64:"String",
		image2Base64:"String",
		image3Base64:"String",
		toolings:"ToolingInfo"
	},
	PartWorkplaceInfo:{
		id:"Int",
		name:"String",
		setpoints:"Setpoints",
		loadInformation:"LoadInformation"
	},
	PartManagementInfo:{
		partInfo:"SHARED_PartInfo",
		partWorkplaceList:"PartWorkplaceInfo"
	},
	SetPointItem:{
		userComponentId:"Int",
		relevant:"Int",
		valueType:"Int",
		meaning:"Int",
		name:"String",
		control:"SHARED_OptionItem",
		value:"Float",
		unit:"String",
		toleranceMax:"Float",
		toleranceMin:"Float",
		alarmStartTimeInMin:"Int",
		alarmToleranceTimeInMin:"Int",
		endControl:"SHARED_OptionItem",
		endValue:"Float"
	},
	RecipeItemPositionHeader:{
		positionNo:"Int",
		chamberOption:"SHARED_OptionItem",
		chamberOperationOption:"SHARED_OptionItem",
		description:"String",
		afterTimeInMin:"Int",
		estimatedDurationInMin:"Int",
		afterOption:"SHARED_OptionItem"
	},
	RecipeItemPosition:{
		header:"RecipeItemPositionHeader",
		setpoints:"SetPointItem",
		digitalOutputList:"SHARED_DigitalOutput",
		digitalOutputs:"String",
		endAfter:"String"
	},
	RecipeItemHeader:{
		id:"Int",
		name:"String",
		description:"String",
		comment:"String",
		version:"Int",
		processOption:"SHARED_OptionItem",
		processType:"Int",
		processDepth:"Float",
		processDepthAt:"Float",
		correction:"Boolean",
		diff:"Boolean"
	},
	RecipeItemVersion:{
		header:"RecipeItemHeader",
		positions:"RecipeItemPosition",
		spEntries:"SHARED_SpEntries",
		material:"SHARED_Material",
		correctionProfile:"Float"
	},
	RecipeListItem:{
		id:"Int",
		recipe:"String",
		description:"String"
	},
	CloneRecipeAndSaveAsResult:{
		recipeVersions:"RecipeItemVersion",
		isSaved:"Boolean",
		comment:"String"
	},
	SaveRecipeResult:{
		recipeVersion:"RecipeItemVersion",
		isSaved:"Boolean",
		comment:"String"
	},
	MetaDataSetPoint:{
		name:"String",
		userComponentId:"Int",
		meaning:"Int",
		relevant:"Int",
		unit:"String",
		valueType:"Int"
	},
	MetaDataMethods:{
		setPoints:"MetaDataSetPoint",
		digitalInputs:"MetaDataSetPoint",
		digitalOutputs:"MetaDataSetPoint"
	},
	OperationOptions:{
		id:"Int",
		value:"String",
		validVarIds:"Int"
	},
	ControlMetadata:{
		setPoints:"MetaDataSetPoint",
		digitalInputs:"MetaDataSetPoint",
		digitalOutputs:"MetaDataSetPoint"
	},
	OperationMetadata:{
		name:"String",
		description:"String",
		setPoints:"MetaDataSetPoint",
		digitalInputs:"MetaDataSetPoint",
		digitalOutputs:"MetaDataSetPoint"
	},
	MetaDataChamberOption:{
		chamberId:"Int",
		name:"String",
		recipeControl:"ControlMetadata",
		operationControl:"OperationMetadata",
		operationOptions:"SHARED_OptionItem",
		setPoints:"MetaDataSetPoint"
	},
	RecipeMetadata:{
		afterOptions:"SHARED_OptionItem",
		controlOptions:"SHARED_OptionItem",
		endControlOptions:"SHARED_OptionItem",
		chamberOptions:"MetaDataChamberOption",
		operationProcessList:"SHARED_Operation",
		materialList:"SHARED_OLD_CustomerMaterial"
	},
	PeAttributeType:{
		id:"Int",
		peAttributeTypeId:"Int",
		forDiffCalculation:"Boolean",
		forNitriding:"Boolean",
		hasMinimumLimit:"Boolean",
		hasMaximumLimit:"Boolean",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	PeAttributeTypeResult:{
		isSuccess:"Boolean",
		peAttributeType:"PeAttributeType",
		error:"Error"
	},
	PeProcessType:{
		id:"Int",
		phenNoId:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	PeProcessTypeResult:{
		isSuccess:"Boolean",
		peProcessType:"PeProcessType",
		error:"Error"
	},
	PhysicalUnit:{
		id:"Int",
		shortDescription:"String",
		description:"String",
		typeId:"Int",
		unit:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	PhysicalUnitResult:{
		isSuccess:"Boolean",
		physicalUnit:"PhysicalUnit",
		error:"Error"
	},
	WorkplaceTypeResult:{
		workplaceType:"SHARED_WorkplaceType",
		isSuccess:"Boolean",
		error:"Error"
	},
	BackgroundProcess:{
		id:"Int",
		name:"String",
		description:"String",
		order:"Int",
		argument:"String",
		active:"String",
		pid:"Int",
		activeTime:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	ProcessResult:{
		process:"BackgroundProcess",
		isSuccess:"Boolean",
		error:"Error"
	},
	WorkplaceResult:{
		workplace:"SHARED_Workplace",
		isSuccess:"Boolean",
		error:"Error"
	},
	OperationType:{
		id:"Int",
		description:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	OperationPart:{
		id:"Int",
		description:"String",
		order:"Int"
	},
	OperationProcess:{
		id:"Int",
		name:"String",
		description:"String",
		order:"Int",
		sap:"String"
	},
	Operation:{
		id:"Int",
		processTypeId:"Int",
		name:"String",
		operationType:"String",
		comment:"String",
		order:"Int",
		relevantForStat:"Boolean",
		mode:"Int",
		typeId:"Int",
		modifiedOn:"Int",
		modifiedBy:"String",
		operationProcess:"OperationProcess",
		operationParts:"OperationPart"
	},
	OperationResult:{
		isSuccess:"Boolean",
		operation:"Operation",
		error:"Error"
	},
	Alarm:{
		id:"Int",
		workplace:"String",
		workplaceId:"Int",
		alarm:"Int",
		alarmText:"String",
		errorType:"Int",
		outputType:"Int",
		security:"Int",
		color:"Int",
		onlyForChamber:"Int",
		causesATestingLoad:"Boolean",
		alarmGroup:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	ChambersPerWorkplace:{
		workplaceId:"Int",
		chamberList:"SHARED_OptionItem"
	},
	AlarmAdministrationMetadata:{
		workplaceList:"SHARED_OptionItem",
		errorTypeList:"SHARED_OptionItem",
		outputTypeList:"SHARED_OptionItem",
		alarmGroupList:"SHARED_OptionItem",
		importanceList:"SHARED_OptionItem",
		colorList:"SHARED_OptionItem",
		chambersList:"ChambersPerWorkplace"
	},
	AlarmResult:{
		alarm:"Alarm",
		isSuccess:"Boolean",
		error:"Error"
	},
	Box:{
		id:"Int",
		name:"String",
		colorId:"Int",
		used:"Boolean",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	ColorBox:{
		id:"Int",
		name:"String",
		redValue:"Int",
		greenValue:"Int",
		blueValue:"Int"
	},
	BoxResult:{
		isSuccess:"Boolean",
		box:"Box",
		error:"Error"
	},
	Color:{
		id:"Int",
		name:"String",
		redValue:"Int",
		greenValue:"Int",
		blueValue:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	ColorResult:{
		isSuccess:"Boolean",
		color:"Color",
		error:"Error"
	},
	LanguageInfo:{
		languageId:"Int",
		description:"String"
	},
	TranslationInfo:{
		languageId:"Int",
		translation:"String"
	},
	DictionaryItem:{
		textId:"Int",
		defaultText:"String",
		peProperty:"String",
		used:"Boolean",
		type:"Int",
		translations:"TranslationInfo",
		customerTranslations:"TranslationInfo"
	},
	ExternalizeTranslationConfigValue:{
		value:"Boolean"
	},
	SmsSettings:{
		id:"Int",
		name:"String",
		description:"String",
		addresseeMobilePhoneNo:"String",
		pin:"String",
		serialInterface:"String",
		testMessage:"Boolean",
		active:"Boolean",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	Task:{
		id:"Int",
		name:"String",
		description:"String",
		active:"Boolean",
		type:"String",
		command1:"String",
		command2:"String",
		command3:"String",
		command4:"String",
		command5:"String",
		command6:"String",
		command7:"String",
		command8:"String",
		command9:"String",
		command10:"String",
		startHour:"Int",
		intervalInMin:"Int",
		dateLastActive:"Int",
		comment:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	TaskResult:{
		task:"Task",
		isSuccess:"Boolean",
		error:"Error"
	},
	updateClientAccessResult:{
		isSuccess:"Boolean",
		error:"Error"
	},
	TerminalType:{
		id:"Int",
		description:"String",
		osTypeId:"Int",
		remark:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	TerminalTypeResult:{
		isSuccess:"Boolean",
		error:"Error",
		terminalType:"TerminalType"
	},
	TerminalClient:{
		id:"Int",
		clientTypeId:"Int",
		name:"String",
		description:"String",
		adress:"String",
		location:"String",
		remark:"String",
		resolution:"Int",
		valueLong1:"Int",
		valueLong2:"Int",
		valueLong3:"Int",
		valueLong4:"Int",
		valueLong5:"Int",
		valueFloat1:"Int",
		valueFloat2:"Int",
		valueFloat3:"Int",
		valueChar1:"String",
		valueChar2:"String",
		actUserId:"Int",
		actLoginTime:"Int",
		lastUserId:"Int",
		lastLoginTime:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	TerminalClientResult:{
		isSuccess:"Boolean",
		error:"Error",
		terminalClient:"TerminalClient"
	},
	TerminalClientMetadata:{
		resolutions:"SHARED_OptionItem",
		users:"SHARED_OptionItem",
		clientTypes:"SHARED_OptionItem"
	},
	ApplicationGroup:{
		id:"Int",
		name:"String",
		description:"String",
		order:"Int",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	ApplicationGroupResult:{
		applicationGroup:"ApplicationGroup",
		isSuccess:"Boolean",
		error:"Error"
	},
	Application:{
		id:"Int",
		name:"String",
		description:"String",
		command:"String",
		parameter:"String",
		tooltip:"String",
		furnacePicture:"String",
		iconGifName:"String",
		order:"Int",
		typeId:"Int",
		groupId:"Int",
		startRecipeProcessFlow:"Boolean",
		modifyRecipeProcessFlow:"Boolean",
		stopRecipeProcessFlow:"Boolean",
		startCorrectionRecipe:"Boolean",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	ApplicationResult:{
		isSuccess:"Boolean",
		error:"Error",
		application:"Application"
	},
	ApplicationMetadata:{
		applicationGroupList:"SHARED_OptionItem",
		applicationTypeList:"SHARED_OptionItem"
	},
	ProcessFlow:{
		id:"Int",
		name:"String",
		description:"String",
		comments:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	RecipeMenuItem:{
		id:"Int",
		workplaceId:"Int",
		name:"String",
		description:"String",
		recipePrefix:"String"
	},
	OperationMenuItem:{
		id:"Int",
		name:"String",
		operationType:"String",
		comment:"String"
	},
	ProcessFlowStep:{
		ptime:"Int",
		order:"Int",
		workplace:"SHARED_Workplace",
		recipe:"RecipeMenuItem",
		operation:"OperationMenuItem"
	},
	ProcessFlowPartComparisonItem:{
		id:"Int",
		name:"String",
		description:"String",
		description2:"String"
	},
	UserAccess:{
		user:"String",
		userId:"Int",
		group:"String",
		pgmId:"Int",
		application:"String",
		run:"Boolean",
		newEntry:"Boolean",
		save:"Boolean",
		delete:"Boolean",
		special1:"Boolean",
		print:"Boolean",
		startRecipeProcessFlow:"Boolean",
		modifyRecipeProcessFlow:"Boolean",
		stopRecipeProcessFlow:"Boolean",
		startCorrectionRecipe:"Boolean"
	},
	UserGroup:{
		id:"Int",
		name:"String",
		description:"String",
		modifiedOn:"Int",
		modifiedBy:"String"
	},
	TO_REMOVE_RecipeMenuItem:{
		id:"Int",
		workplaceId:"Int",
		name:"String",
		description:"String",
		recipePrefix:"String"
	},
	TO_REMOVE_ShopLayoutMenuItem:{
		id:"Int",
		name:"String",
		description:"String"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}