export default {
  header: {
    create: 'API-Schlüssel erstellen',
    update: 'API-Schlüssel aktualisieren',
  },
  warning: {
    title: 'API-Schlüssel ist nur einmal sichtbar',
    subtitle: 'Sie können den API-Schlüssel nicht erneut abrufen. Bitte kopieren und an einem sicheren Ort speichern.',
  },
};
