<template>
  <div>
    <table
      ref="tableRef"
      class="dynamic-table"
      :class="{ 'has-blocks': hasBlocks }"
      :style="{
        height: tableHeight
      }"
    >
      <!-- TABLE HEADER -->
      <thead
        v-if="!hideDefaultHeader"
        class="dynamic-table__head-section"
      >
        <tr ref="headersRef">
          <th
            v-for="(headerItem, index) of sortedHeader"
            :key="index"
            :class="{
              'dynamic-table__sticky-col': headerItem.fixed,
              'dynamic-table__actions': headerItem.hasActions,
              'dynamic-table__element': !headerItem.hasActions && !headerItem.hasBatchActions,
              'dynamic-table__batch-actions': headerItem.hasBatchActions,
              'dynamic-table__column-sorted': headerItem.sorted !== ESortingDirection.NONE
            }"
            class="dynamic-table__element--head"
            :style="returnStyle(index, headerItem)"
            @mouseenter="headerItem.hover = true"
            @mouseleave="headerItem.hover = false"
          >
            <div
              v-if="!headerItem.hidden"
              class="dynamic-table__head-section-element"
            >
              <div
                v-if="sortingEnabled && !hasBlocks"
                class="dynamic-table__header-sorting"
              >
                <!-- Sorting icons -->
                <v-icon
                  v-if="headerItem.sorted === ESortingDirection.NONE"
                  class="default-sorting-icon"
                  @click="sortColumn(headerItem, ESortingDirection.ASC)"
                >
                  mdi mdi-swap-vertical
                </v-icon>
                <v-icon
                  v-else-if="headerItem.sorted === ESortingDirection.ASC"
                  @click="sortColumn(headerItem, ESortingDirection.DESC)"
                >
                  mdi mdi-arrow-up-thin
                </v-icon>
                <v-icon
                  v-else
                  @click="sortColumn(headerItem, ESortingDirection.NONE)"
                >
                  mdi mdi-arrow-down-thin
                </v-icon>
              </div>

              <!-- TABLE TITLE -->
              <div class="dynamic-table__header-table-title">
                <div class="dynamic-table__header-table-title-texts">
                  <div class="dynamic-table__header-table-title-text">
                    {{ headerItem.name }}
                  </div>
                  <div
                    v-if="headerItem.unit"
                    class="dynamic-table__header-table-title-unit"
                  >
                    [{{ headerItem.unit }}]
                  </div>
                </div>
                <icon
                  v-if="hasHorizontalScroll && !headerItem.hasActions && !headerItem.hasBatchActions"
                  :name="headerItem.fixed ? EIcon.LOCK : EIcon.LOCK_OPENED"
                  class="dynamic-table__header-icon dynamic-table__header-icon--smaller"
                  :class="{
                    'dynamic-table__header-icon--active': headerItem.fixed,
                    'dynamic-table__header-icon--hover': headerItem.hover,
                  }"
                  @click="
                    headerItem.fixed = !headerItem.fixed;
                    sortHeader();
                  "
                />
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <!-- TABLE BODY -->
      <tbody
        v-if="!isLoading"
        class="dynamic-table__data-section"
      >
        <template v-for="(row, rowIndex) of workableData.slice(itemsAmount > 0 ? 0 : currentPageRows.start, currentPageRows.end)">
          <tr
            v-if="hasRowPrepend(row, rowIndex)"
            :key="row.uid"
            class="dynamic-table__row dynamic-table__row-prepend"
          >
            <td
              colspan="100%"
            >
              <slot
                :item="row"
                :row-index="rowIndex"
                name="row-prepend"
              />
            </td>
          </tr>
          <tr
            v-if="!isLoading"
            :key="row.uid"
            class="dynamic-table__row"
            :class="{
              ...rowClass(row),
              'dynamic-table__row--selected': rowsSelectedList.includes(row.uid) && !disableRowHighlight,
              'dynamic-table__row--hover': selectEnabled,
            }"
            @mouseenter="item = row.uid"
            @mouseleave="item = []"
            @click="selectEnabled ? addSelectedRows(row) : null"
          >
            <td
              v-for="(headerItem, index) of header"
              :key="index"
              :class="{ 'dynamic-table__sticky-col': headerItem.fixed,
                        'dynamic-table__actions': headerItem.hasActions,
                        'dynamic-table__element': !headerItem.hasActions && !headerItem.hasBatchActions,
                        'dynamic-table__batch-actions': headerItem.hasBatchActions, }"
              :style="[returnStyle(index, headerItem), rowColumnsStyle(rowIndex, row, headerItem)]"
              class="dynamic-table__element--data dynamic-table__element--data--no--expanded"
            >
              <slot
                v-if="headerItem.hasActions && $slots[`actions`]"
                name="actions"
                :item="row"
                :index="index"
                :row-index="rowIndex"
              />
              <slot
                v-else-if="headerItem.hasBatchActions && $slots[`batch-actions`]"
                name="batch-actions"
                :item="row"
                :index="index"
                :row-index="rowIndex"
              />
              <slot
                v-else-if="$slots[`item.${headerItem.key}`]"
                :name="`item.${headerItem.key}`"
                :item="row"
                :index="index"
                :row-index="rowIndex"
              />
              <p v-else>
                {{ row[headerItem.key] }}
              </p>
              <v-icon
                v-if="headerItem.key === 'data-table-expand' && expanded"
                :class="row.disabled && expanded ? 'icon-disabled' : 'icon-enabled'"
                @click="row.disabled && expanded ? null : toggle(row)"
              >
                {{ isOpened(row) ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down" }}
              </v-icon>
            </td>
            <td
              v-if="hasBlocks && opened.length === 0 && rowBlockSpan(row, rowIndex)"
              :rowspan="rowBlockSpan(row, rowIndex)"
              class="block-span"
            >
              <slot
                name="block-span"
                :item="row"
                :row-index="rowIndex"
              />
            </td>
          </tr>
          <tr
            v-if="(isOpened(row) && expanded) || additionalInfo"
            :key="row.id || row.uid"
            class="dynamic-table__row-expanded dynamic-table__row"
          >
            <td
              colspan="100%"
              class="dynamic-table__element dynamic-table__element--data"
            >
              <slot
                v-if="$slots[`expanded-item`]"
                :close="close"
                :item="row"
                name="expanded-item"
              />
            </td>
          </tr>
          <tr
            v-if="hasRowAppend(row, rowIndex)"
            :key="row.uid"
            class="dynamic-table__row dynamic-table__row-append"
          >
            <td
              colspan="100%"
            >
              <slot
                :item="row"
                :row-index="rowIndex"
                name="row-append"
              />
            </td>
          </tr>
        </template>
      </tbody>
      <tbody
        v-else
        class="loading"
      >
        <tr>
          <td colspan="100%">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
            />
          </td>
        </tr>
      </tbody>

      <!-- TABLE FOOTER -->
      <tfoot
        v-if="Object.keys(footerData).length && !isLoading"
        class="dynamic-table__foot-section"
        :style="{'top': 'calc(100% - ' + footerHeight + 'px)'}"
      >
        <tr
          ref="footerRowRef"
          class="dynamic-table__row"
        >
          <td
            v-for="(headerItem, index) of header"
            :key="index"
            :class="{ 'dynamic-table__sticky-col': headerItem.fixed,
                      'dynamic-table__element': !headerItem.hasActions && !headerItem.hasBatchActions,
                      'dynamic-table__actions': headerItem.hasActions,
                      'dynamic-table__batch-actions': headerItem.hasBatchActions, }"
            :style="returnStyle(index, headerItem)"
            class="dynamic-table__element--data dynamic-table__element--data--no--expanded test"
          >
            <slot
              v-if="headerItem.hasActions && $slots[`actions`]"
              name="actions"
              :item="footerData"
              :index="index"
            />
            <slot
              v-else-if="headerItem.hasBatchActions && $slots[`batch-actions`]"
              name="batch-actions"
              :item="footerData"
              :index="index"
            />
            <slot
              v-else-if="$slots[`item.${headerItem.key}`]"
              :name="`item.${headerItem.key}`"
              :item="footerData"
              :index="index"
            />
            <p v-else>
              {{ footerData[headerItem.key] }}
            </p>
          </td>
        </tr>
      </tfoot>
    </table>
    <!-- PAGINATION -->
    <div
      v-if="rowsPerPageData > 0"
      class="dynamic-table__pagination"
    >
      <div class="dynamic-table__pagination-container">
        <div
          class="dynamic-table__pagination-container-arrow"
          @click="currentPage === 0 ? null : currentPage = currentPage - 1"
        >
          <icon
            :name="EIcon.CHEVRON_LEFT"
            :class="currentPage === 0 ? 'dynamic-table__header-icon--hover' : 'dynamic-table__header-icon--active'"
          />
        </div>
        <template v-if="pagesTotal < 4">
          <div
            v-for="index in pagesTotal"
            :key="index"
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === index - 1
            }"
            @click="currentPage = index - 1"
          >
            {{ index }}
          </div>
        </template>
        <template v-else-if="(pagesTotal > 4 && (currentPage === 0 || currentPage === 1)) || (pagesTotal === 4 && currentPage === 0)">
          <div
            v-for="index in 3"
            :key="index"
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === index - 1
            }"
            @click="currentPage = index - 1"
          >
            {{ index }}
          </div>
          <div>
            ...
          </div>
          <div
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === pagesTotal - 1
            }"
            @click="currentPage = pagesTotal - 1"
          >
            {{ pagesTotal }}
          </div>
        </template>
        <template v-else-if="(pagesTotal > 4 && (currentPage === pagesTotal - 1 || currentPage === pagesTotal - 2)) || (pagesTotal === 4 && currentPage === 3)">
          <div
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === 0
            }"
            @click="currentPage = 0"
          >
            {{ 1 }}
          </div>
          <div>
            ...
          </div>
          <div
            v-for="index in range(pagesTotal - 2, pagesTotal)"
            :key="index"
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === index - 1
            }"
            @click="currentPage = index - 1"
          >
            {{ index }}
          </div>
        </template>
        <template v-else>
          <div
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === 0
            }"
            @click="currentPage = 0"
          >
            {{ 1 }}
          </div>
          <div>
            ...
          </div>
          <div
            v-for="index in range(currentPage, currentPage + 2)"
            :key="index"
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === index - 1
            }"
            @click="currentPage = index - 1"
          >
            {{ index }}
          </div>
          <div>
            ...
          </div>
          <div
            class="dynamic-table__pagination-container-number"
            :class="{
              'dynamic-table__pagination-container-number_active': currentPage === pagesTotal - 1
            }"
            @click="currentPage = pagesTotal - 1"
          >
            {{ pagesTotal }}
          </div>
        </template>
        <div
          class="dynamic-table__pagination-container-arrow dynamic-table__pagination-container-arrow_right"
          @click="currentPage === pagesTotal - 1 ? null : currentPage = currentPage + 1"
        >
          <icon
            :class="currentPage === pagesTotal - 1 ? 'dynamic-table__header-icon--hover' : 'dynamic-table__header-icon--active'"
            :name="EIcon.CHEVRON_RIGHT"
          />
        </div>
      </div>

      <div class="dynamic-table__pagination-dropdown">
        <span class="row-per-page">Rows per page.</span>
        <select
          id="rowsPerPageSelect"
          v-model="rowsPerPageData"
          class="dynamic-table__pagination-dropdown-select"
        >
          <option
            v-for="option of rowsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, nextTick, ref,
} from 'vue';
import {
  guiID,
} from '../../util/id.js';
import EIcon from '../icon/icon-enum';
import Icon from '../icon/icon.vue';

export enum ESortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = ''
}

export interface IHeaderItem {
  key: string;
  name: string;
  unit: string | undefined;
  fixed: boolean;
  hidden: boolean;
  sortable: boolean;
  sorted: ESortingDirection;
  hover: boolean;
  width: string;
  filterable: boolean;
  hasActions: boolean;
  hasBatchActions: boolean;
}

export interface ICurrentPageRows {
  start: number;
  end: number;
  allEntriesShown: number[];
}

export type TTableKey = string;
export type TTableRow = Record<TTableKey, any>;
export type TSortingFunction = (
  headerItem: IHeaderItem
) => (firstRawDataItem: Record<string, any>, secondRawDataItem: Record<string, any>) => number;
export type TSortingFunctionMap = Record<TTableKey, TSortingFunction>;
export const ACTIONS = {
  key: 'actions',
  name: '',
  unit: '',
  fixed: false,
  hidden: true,
  sortable: false,
  sorted: ESortingDirection.NONE,
  hover: false,
  width: 'default',
  filterable: false,
  hasActions: true,
  hasBatchActions: false,
};

export const BATCH_ACTIONS = {
  key: 'batch-actions',
  name: '',
  unit: '',
  fixed: true,
  hidden: true,
  sortable: false,
  sorted: ESortingDirection.NONE,
  hover: false,
  width: 'default',
  filterable: false,
  hasActions: false,
  hasBatchActions: true,
};

const blockHeaderItem: IHeaderItem = {
  key: 'block-span',
  name: '',
  unit: '',
  fixed: false,
  hidden: true,
  sortable: false,
  sorted: ESortingDirection.NONE,
  hover: false,
  width: '44px',
  filterable: false,
  hasActions: false,
  hasBatchActions: false,
};

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    // will take keys from headerMap to map it to columns
    rawData: {
      type: Array as () => TTableRow[],
      required: true,
    },
    footerData: {
      type: Object,
      default: () => ({}),
    },
    headersToHide: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    selectEnabled: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    sortingEnabled: {
      type: Boolean,
      default: true,
    },
    rowsPerPage: {
      type: Number,
      default: 0,
    },
    tableHeight: {
      type: String,
      default: 'auto',
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    // should have same keys as raw data
    tableHeaderMap: {
      type: Object as () => Record<TTableKey, string>,
      default: () => ({}),
    },
    // will be used if tableHeaderMap is not specified
    tableHeaderArray: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    // Each column can have sorting function
    // Here should be specified a Map, where each key corresponds to rawData key
    // and a function, which will sort that key, or standard function will be used
    customSortingFunctionMap: {
      type: Object as () => TSortingFunctionMap,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    additionalInfo: {
      type: Boolean,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      default: false,
    },
    newItem: {
      type: Object,
      default: () => ({}),
    },
    itemsAmount: {
      type: Number,
      default: 0,
    },
    disableRowHighlight: {
      type: Boolean,
      default: false,
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    hasBatchActions: {
      type: Boolean,
      default: false,
    },
    hasBlocks: {
      type: Boolean,
      default: false,
    },
    rowBlockSpan: {
      type: Function,
      default: () => 1,
    },
    hasRowPrepend: {
      type: Function,
      default: () => false,
    },
    hasRowAppend: {
      type: Function,
      default: () => false,
    },
    rowColumnsStyle: {
      type: Function,
      default: () => () => {},
    },
    rowClass: {
      type: Function,
      default: () => () => {},
    },
  },
  emits: [
    'update:expandedItems',
    'rowsSelected',
    'rowsPerPage',
    'currentPage',
  ],

  setup() {
    const footerRowRef = ref<HTMLElement | null>(null);
    const tableRef = ref<HTMLElement | null>(null);
    const headersRef = ref<HTMLElement | null>(null);
    return {
      blockHeaderItem,
      footerRowRef,
      tableRef,
      headersRef,
    };
  },

  data() {
    return {
      item: [],
      opened: [] as TTableRow[],
      header: [] as IHeaderItem[],
      currentPage: 0,
      rowsPerPageData: this.rowsPerPage,
      rowsSelectedList: [] as string[],
      workableData: [] as TTableRow[],
      ESortingDirection,
      EIcon,
      footerHeight: 0,
      hasHorizontalScroll: false,
    };
  },

  computed: {
    // we display first columns, which are fixed
    sortedHeader(): IHeaderItem[] {
      const sorted = [
        ...this.header,
      ].sort((firstItem, secondItem) => {
        const firstItemFixed = firstItem.fixed;
        const secondItemFixed = secondItem.fixed;
        let shouldSort;
        if (firstItemFixed === secondItemFixed) {
          shouldSort = 0;
        } else {
          shouldSort = firstItemFixed ? -1 : 1;
        }
        return shouldSort;
      });
      // This will cause an update for all headers - thus we will get correct widths
      if (sorted.length) {
        sorted[0].hover = !sorted[0].hover;
      }
      if (this.hasBlocks && this.opened.length === 0) {
        sorted.push({
          ...blockHeaderItem,
        });
      }
      return sorted;
    },
    rowsPerPageOptions(): number[] {
      const standartOptions = [
        25,
        50,
        100,
        250,
      ];
      if (this.rowsPerPage > 0) {
        standartOptions.push(this.rowsPerPage);
      }
      const options = new Set(standartOptions.sort((a, b) => a - b));
      return [
        ...options,
      ];
    },
    pagesTotal(): number {
      if (this.rowsPerPageData <= 0) {
        return 1;
      }
      if (this.itemsAmount > 0) {
        return Math.ceil(this.itemsAmount / this.rowsPerPageData);
      }
      return Math.ceil(this.workableData.length / this.rowsPerPageData);
    },
    currentPageRows(): ICurrentPageRows {
      let end;
      let start;
      if (this.rowsPerPageData > 0) {
        start = this.currentPage * this.rowsPerPageData;
        end = Math.min((this.currentPage + 1) * this.rowsPerPageData, this.workableData.length);
      } else {
        start = 0;
        end = this.workableData.length;
      }
      const allEntriesShown = [];
      for (let i = start; i < end; i += 1) {
        allEntriesShown.push(i);
      }
      return {
        start,
        end,
        allEntriesShown,
      };
    },
  },
  watch: {
    // Not the most optimal solution, but it works
    // couldn't find better approach without refactoring the entire component
    'footerRowRef.clientHeight': {
      handler() {
        setTimeout(() => {
          // style: top: 100% - footerHeight to make it stick to the bottom + extra height
          // to make footer higher than standard rows, same height removes sticky effect when returning from browser zooms
          this.footerHeight = this.footerRowRef ? this.footerRowRef.clientHeight + 1 : 0;
        });
      },
    },
    pagesTotal: {
      immediate: true,
      deep: true,
      handler() {
        this.currentPage = this.currentPage >= this.pagesTotal ? (this.pagesTotal - 1) : this.currentPage;
      },
    },
    tableHeaderMap: {
      immediate: true,
      deep: true,
      handler() {
        this.generateHeader();
        nextTick(() => {
          this.checkOverflow();
        });
      },
    },
    tableHeaderArray: {
      immediate: true,
      deep: true,
      handler() {
        this.generateHeader();
        nextTick(() => {
          this.checkOverflow();
        });
      },
    },
    rawData: {
      immediate: true,
      deep: true,
      handler() {
        this.mountData();
        this.applySearchFilter();
        this.currentPage = 0;
        this.applySorting();
      },
    },
    search: {
      handler() {
        this.mountData();
        this.applySearchFilter();
        this.applySorting();
      },
    },
    newItem: {
      immediate: true,
      handler() {
        if (Object.keys(this.newItem).length !== 0) {
          this.expandNewItem();
        }
      },
    },
    rowsPerPageData: {
      immediate: true,
      handler() {
        this.$emit('rowsPerPage', this.rowsPerPageData);
      },
    },
    currentPage: {
      immediate: true,
      handler() {
        this.$emit('currentPage', this.currentPage + 1);
      },
    },
  },

  mounted() {
    this.generateHeader();
    this.mountData();
  },

  methods: {
    range(start: number, end: number) {
      const range = [];
      for (let i = start; i <= end; i += 1) {
        range.push(i);
      }
      return range;
    },
    checkOverflow() {
      if (this.tableRef) {
        this.hasHorizontalScroll = this.tableRef.scrollWidth !== this.tableRef.clientWidth;
      }
    },
    applySearchFilter() {
      if (this.search) {
        this.workableData = this.workableData.filter((row) => Object.entries(row).some(
          (value) => value[1]
            && !Array.isArray(value[1])
            && this.header.find((head) => head.key === value[0])?.filterable
            && String(value[1])
              .toLowerCase()
              .includes(this.search.toLowerCase()),
        ));
      }
    },
    applySorting() {
      const sortableHeaderItem = this.header.find((head: IHeaderItem) => head.sorted !== ESortingDirection.NONE);
      if (sortableHeaderItem) {
        this.sortColumn(sortableHeaderItem, sortableHeaderItem.sorted);
      }
    },
    returnStyle(index: number, item: IHeaderItem) {
      const style = {
        '--position': index,
      };
      if (item.fixed) {
        let totalPreviousWidth;
        if (this.headersRef && this.headersRef.children[index]) {
          const previousColumns = [
            ...this.headersRef.children,
          ].filter((column) => {
            const position = window.getComputedStyle(column).getPropertyValue('--position');
            return parseInt(position, 10) <= index - 1;
          });
          totalPreviousWidth = previousColumns.map((column) => column.clientWidth).reduce((partialSum, a) => partialSum + a, 0);
        }
        const stickyStyle = {
          left: `${totalPreviousWidth}px`,
        };
        Object.assign(style, stickyStyle);
      }
      if (item.hasActions) {
        const actionsStyles = {
          display: 'table-cell',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        };
        Object.assign(style, actionsStyles);
      }
      if (item.hasBatchActions) {
        const actionsStyles = {
          display: 'table-cell',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        };
        Object.assign(style, actionsStyles);
      }
      if (item.width && item.width !== 'default') {
        Object.assign(style, {
          width: item.width,
          'min-width': 'unset',
          'max-width': 'unset',
        });
      }
      return style;
    },
    /// this.header can't be a computed property because it gets modified from outside via locking of rows
    generateHeader() {
      this.header = [];
      if (!this.tableHeaderArray.length) {
        Object.keys(this.tableHeaderMap).forEach((key, index) => {
          this.header.push({
            key,
            name: this.tableHeaderMap[key],
            unit: undefined,
            fixed: index === 0,
            hidden: this.headersToHide.includes(key),
            sortable: true,
            sorted: ESortingDirection.NONE,
            hover: false,
            width: 'default',
            filterable: true,
            hasActions: false,
            hasBatchActions: false,
          });
        });
      } else {
        this.tableHeaderArray.forEach((header: any, index: number) => {
          this.header.push({
            key: header.value,
            name: header.text,
            unit: header.unit,
            fixed: index === 0,
            hidden: header.hidden,
            sortable: true,
            sorted: ESortingDirection.NONE,
            hover: false,
            width: header.width,
            filterable: header.filterable === undefined ? true : header.filterable,
            hasActions: false,
            hasBatchActions: false,
          });
        });
      }
      if (this.hasActions) {
        this.header.push({
          ...ACTIONS,
        });
      }
      if (this.hasBatchActions) {
        this.header.unshift({
          ...BATCH_ACTIONS,
        });
      }
    },

    mountData() {
      this.workableData = this.selectEnabled
        ? this.rawData.map((row) => {
          const item = {
            uid: guiID(),
          };
          Object.assign(item, row);
          return item;
        })
        : this.rawData;
    },

    // every property might have it's own sorting function
    // if no sorting function is found the data is sorted by a default function
    sortColumn(headerItem: IHeaderItem, order: ESortingDirection) {
      if (headerItem.sortable) {
        this.header.forEach((header) => {
          header.sorted = ESortingDirection.NONE;
        });
        headerItem.sorted = order;
        // @ts-ignore
        this.workableData.sort((a, b) => {
          let aValue = a[headerItem.key];
          let bValue = b[headerItem.key];
          if ((typeof aValue !== 'number' || typeof bValue !== 'number')
            && (typeof aValue !== 'boolean' || typeof bValue !== 'boolean')) {
            aValue = String(aValue).toLowerCase();
            bValue = String(bValue).toLowerCase();
          }
          if (aValue < bValue) {
            return order === ESortingDirection.ASC ? -1 : 1;
          }
          if (aValue > bValue) {
            return order === ESortingDirection.ASC ? 1 : -1;
          }
          return 0;
        });
      }
    },

    addSelectedRows(row: TTableRow) {
      const {
        uid,
      } = row;
      if (this.multiSelect) {
        if (this.rowsSelectedList.includes(uid)) {
          this.rowsSelectedList = this.rowsSelectedList.filter((rowId) => rowId !== uid);
        } else {
          this.rowsSelectedList.push(uid);
        }
      } else {
        /// single select
        this.rowsSelectedList = [
          uid,
        ];
      }

      this.$emit(
        'rowsSelected',
        this.workableData.filter((item) => this.rowsSelectedList.includes(item.uid)),
      );
    },

    sortHeader() {
      this.header.sort(
        // @ts-ignore we're using implicit coercion from boolean to number
        (a, b) => b.fixed - a.fixed,
      );
    },

    close(row: any) {
      if (!this.singleExpand) {
        this.opened = this.opened.filter(({
          uid,
        }) => row.uid !== uid);
      } else {
        this.opened = [];
      }
    },

    toggle(row: any) {
      if (!this.singleExpand) {
        const index = this.opened.findIndex((opened) => opened.id === row.id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(row);
        }
      } else if (this.opened.length !== 0) {
        if (this.opened.includes(row)) {
          this.opened = [];
        } else {
          this.opened = [];
          this.opened.push(row);
        }
      } else {
        this.opened.push(row);
      }
      this.$emit('update:expandedItems', this.opened);
    },

    isOpened(row: any) {
      return this.opened.find(({
        id,
      }) => row.id === id);
    },

    expandNewItem() {
      if (this.expanded) {
        const addNewItem = this.workableData.find(({
          id,
        }) => id === this.newItem.id);
        if (addNewItem) {
          this.opened = [];
          this.opened.push(addNewItem);
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use './dynamic-table.scss';
</style>
