import {
  PROTOCOLS_USAGE_PATH,
  EQUIPMENT_JOB_AUDIT,
  EQUIPMENT_JOB_CHART,
  EQUIPMENT_JOB_INFO,
  EQUIPMENT_ALARMS_INFO,
  EQUIPMENT_BATCH_RATING,
  NOTES_PATH,
  CHART_CONFIG_PATH,
  CHART_CONFIG_SIGNALS,
  CHART_CONFIG_OPERATION_DASHBOARD_SIGNALS,
  EQUIPMENT_ID,
  KPIS_PATH,
  FURNACES_PATH,
  EQUIPMENT_STAGES_PATH,

  EQUIPMENTS_PATH,
} from '@/constants/apiPaths';
import {
  portalApi, portalV2Api,
} from '@/services/AxiosService';

export const JobService = {
  createAudit(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENT_JOB_AUDIT}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getAudits(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENT_JOB_AUDIT}/${payload.locationName}/${payload.jobId}`)
        .then((response) => {
          response.data = response.data.map((item) => {
            return {
              ...item,
            };
          });
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getNotes(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENTS_PATH}/${NOTES_PATH}/${payload.locationName}/${payload.jobId}`,
        )
        .then((response) => {
          response.data = response.data.map((item) => {
            return {
              chart_note_id: item.chart_note_id,
              job_id: item.job_id,
              x: item.x_axis,
              author: item.author_name,
              note: item.note,
            };
          });
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteNote(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(
          `${EQUIPMENTS_PATH}/${NOTES_PATH}/${payload.location_name}/${payload.chart_note_id}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateNotes(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}/${NOTES_PATH}/`, payload)
        .then((response) => {
          response.data = {
            chart_note_id: response.data.chart_note_id,
            job_id: response.data.job_id,
            x: response.data.x_axis,
            author: response.data.author_name,
            note: response.data.note,
          };
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobUsage(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${PROTOCOLS_USAGE_PATH}/${payload.location}/${payload.jobId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobAlarms(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENT_ALARMS_INFO}/${payload.locationName}/${payload.jobId}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobStageChange(payload) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(
          `${FURNACES_PATH}/${payload.equipmentId}/${EQUIPMENT_JOB_INFO}/${payload.jobId}/${EQUIPMENT_STAGES_PATH}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobInfo(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENT_JOB_INFO}/${payload.locationName}/${payload.jobId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobChart(payload) {
    return new Promise((resolve, reject) => {
      const params = {};
      if ('maxDatapoints' in payload) {
        params.maxDatapoints = payload.maxDatapoints;
      }

      portalApi
        .get(
          `${EQUIPMENT_JOB_CHART}/${payload.locationName}/${payload.jobId}`,
          {
            params,
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getChartSignals() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CHART_CONFIG_PATH}/${CHART_CONFIG_SIGNALS}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOdChartSignals() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CHART_CONFIG_PATH}/${CHART_CONFIG_OPERATION_DASHBOARD_SIGNALS}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addODChartSignal(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(
          `${CHART_CONFIG_PATH}/${CHART_CONFIG_OPERATION_DASHBOARD_SIGNALS}`,
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteODChartSignal(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(
          `${CHART_CONFIG_PATH}/${CHART_CONFIG_OPERATION_DASHBOARD_SIGNALS}/${payload.common_sensor_data_id}/${payload.is_main}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateKPIs(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${CHART_CONFIG_PATH}/${KPIS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getBatchRating(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENTS_PATH}/${EQUIPMENT_BATCH_RATING}/${payload.locationName}/${payload.jobId}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateBatchRating(batchRun) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}/${EQUIPMENT_BATCH_RATING}`, batchRun)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateChartSignal(signalPayload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${CHART_CONFIG_PATH}/${CHART_CONFIG_SIGNALS}`, signalPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  searchForEquipment(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENTS_PATH}/${EQUIPMENT_ID}`, {
          params: {
            ...payload,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
