<template>
  <div
    style="
      padding: 0.75rem;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: var(--gap-section);
      border-bottom: solid 1px var(--color-102);
    "
  >
    <component
      :is="notificationMap?.icon"
      :style="{
        width: '2rem',
        height: '2rem',
        color: 'var(--color-224)',
      }"
    />
    <div
      style="
        display: flex;
        flex-direction: column;
        gap: var(--gap-union);
      "
    >
      <div style="color: var(--color-104);">
        {{ dayjs(props.notification.createdAtUtc).fromNow(false) }}, {{ formatDate(props.notification.createdAtUtc) }}
      </div>
      <div>
        {{ i18n.global.t(`${I18N_PREFIX}.event.${props.notification.eventType}.label`, notificationContext) }}
      </div>
      <div
        :style="{
          color: 'var(--color-107)',
          'text-overflow': 'ellipsis',
          overflow: 'hidden',
          display: '-webkit-box',
          'line-clamp': '3',
          '-webkit-box-orient': 'vertical',
          'white-space': 'pre-line',
        }"
      >
        {{ i18n.global.t(`${I18N_PREFIX}.event.${props.notification.eventType}.description`, notificationContext) }}
      </div>
    </div>
    <div>
      <qmulus-button
        v-if="!markedAsRead"
        :label="i18n.global.t(`${I18N_PREFIX}.markAsRead`)"
        :loading="isLoading"
        icon-only
        is-inline-action
        severity="primary"
        icon="pi pi-circle-fill"
        @click="markNotificationAsRead"
      />
      <router-link
        v-if="notificationMap?.navigationTarget"
        :to="notificationMap.navigationTarget"
      >
        <qmulus-button
          :label="i18n.global.t(`${I18N_PREFIX}.goToEvent`)"
          icon-only
          is-inline-action
          severity="primary"
          icon="pi pi-arrow-right"
          @click="emit('close')"
        />
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  FunctionalComponent,
  shallowRef,
  SVGAttributes,
} from 'vue';
import {
  i18n,
} from '@i18n';
import dayjs from 'dayjs';
import {
  appListMap,
} from '@/types/app-util.ts';
import {
  markRead,
  TGetInAppNotificationsResponse,
} from '@/generated/axios/openapi/core.ts';
import {
  useNotificationStore,
} from '@/store/pinia/notification.ts';
import QmulusButton from '@/components/button/qmulus-button.vue';
import {
  EAppIdMap,
} from '@/types/app-type';
import {
  ERouteName,
} from '@/router/route-name';
import {
  EEventType,
} from '@/components/item-notification/item-notification.ts';
import {
  ECustomRangeType,
  IRange,
} from '@/components/date-range-picker/date-range-picker.ts';
import {
  formatDate,
} from '@/utils/date-formats.ts';
import {
  RANGE_QUERY_KEY,
} from '@/composition/use-query-date-range';
import UpgradeIcon from '@/assets/icon/upgrade.svg?component';

const I18N_PREFIX = 'components/item-notification/item-notification';

const notificationStore = useNotificationStore();

const props = defineProps<{
  notification: TGetInAppNotificationsResponse['items'][number],
}>();

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'close'): void,
}>();

const notificationContext = computed(() => {
  if (
    props.notification.eventType === EEventType.JOB_STARTED
    || props.notification.eventType === EEventType.JOB_FINISHED
  ) {
    const context = props.notification.context;
    context.startedAtUtc = formatDate(context.startedAtUtc);
    context.finishedAtUtc = context.finishedAtUtc ? formatDate(context.finishedAtUtc) : context.finishedAtUtc;
    return context;
  }

  return props.notification.context;
});

type TNotificationMap = {
  icon: FunctionalComponent<SVGAttributes, {}, any>,
  navigationTarget: {
    name: ERouteName,
    params?: {
      jobId: number,
    } | {
      equipmentId: number,
    },
    query?: {
      range: string,
    },
  },
}
const notificationMap = computed<TNotificationMap>(() => {
  switch (props.notification.eventType) {
    case EEventType.JOB_STARTED:
    case EEventType.JOB_FINISHED:
      return {
        icon: appListMap[EAppIdMap.PRODUCTION_LOGS].icon,
        navigationTarget: {
          name: ERouteName.JOB_CHART,
          params: {
            jobId: props.notification.context.jobId,
          },
        },
      };
    case EEventType.TIMESERIES_LAG_DETECTED:
      return {
        icon: appListMap[EAppIdMap.LIVE].icon,
        navigationTarget: {
          name: ERouteName.LIVE_APP_EQUIPMENT,
          params: {
            equipmentId: props.notification.context.assetId,
          },
        },
      };
    case EEventType.ALARM_SEVERITY_ALARM_OCCURRED:
    case EEventType.ALARM_SEVERITY_EMERGENCY_OCCURRED:
    case EEventType.ALARM_SEVERITY_WARNING_OCCURRED: {
      const rangeMap: IRange = {
        id: 'CALENDAR_CUSTOM_RANGE_ID',
        from: dayjs(props.notification.createdAtUtc).subtract(30, 'minute').toDate(),
        to: dayjs(props.notification.createdAtUtc).add(30, 'minute').toDate(),
        label: 'Custom',
        isCustom: true,
        type: ECustomRangeType.ABSOLUTE,
        unit: 'isoWeek',
      };
      return {
        icon: appListMap[EAppIdMap.LIVE].icon,
        navigationTarget: {
          name: ERouteName.LIVE_APP_EQUIPMENT,
          params: {
            equipmentId: props.notification.context.assetId,
          },
          query: {
            [RANGE_QUERY_KEY]: JSON.stringify(rangeMap),
          },
        },
      }; }
    case EEventType.NEW_QMULUS_RELEASE:
      return {
        icon: UpgradeIcon,
        navigationTarget: {
          name: ERouteName.RELEASE_NOTES,
        },
      };
    default:
      throw new Error('Unspecified notification');
  }
});

const markedAsRead = shallowRef(!!props.notification.seenAtUtc);

const isLoading = shallowRef(false);
function markNotificationAsRead() {
  isLoading.value = true;
  markRead({
    bodyParams: {
      notificationIds: [
        props.notification.notificationId,
      ],
    },
  })
    .then((response) => {
      markedAsRead.value = response.status === 204;
      notificationStore.loadNotificationUnreadCount(true);
    })
    .finally(() => {
      isLoading.value = false;
    });
}
</script>
