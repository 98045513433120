const CHUNK_SEPARATOR = 0x17;
const END_OF_TRANSMISSION = 0x4;

/**
 * @implements {TransformStreamTransformer}
 */
class ChunkTransformStream {
  constructor() {
    this.buffer = new Uint8Array();
    this.decoder = new TextDecoder();
  }

  log() {
    console.log(this.constructor.name, ...arguments);
  }

  concatBuffers(buffer1, buffer2) {
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp;
  }

  processChunk(chunk, controller) {
    this.buffer = this.concatBuffers(this.buffer, chunk);

    const results = [];

    let chunkEnd = this.buffer.findIndex(
      (v) => v === CHUNK_SEPARATOR || v === END_OF_TRANSMISSION,
    );

    while (chunkEnd !== -1) {
      const chunk = this.buffer.slice(0, chunkEnd);
      const rest = this.buffer.slice(chunkEnd + 1);

      const string = this.decoder.decode(chunk);
      results.push(string);

      this.buffer = new Uint8Array(rest);
      chunkEnd = this.buffer.findIndex(
        (v) => v === CHUNK_SEPARATOR || v === END_OF_TRANSMISSION,
      );
    }

    if (results.length > 0) {
      controller.enqueue(results);
    }
  }

  transform(chunk, controller) {
    this.processChunk(chunk, controller);

    if (chunk.some((v) => v === END_OF_TRANSMISSION)) {
      this.log('found EOT', controller, 'buffer', this.buffer);
      controller.terminate();
    }
  }

  flush(controller) {
    this.log('flush buffer', this.buffer);
    this.processChunk(this.buffer, controller);
  }
}

export {
  ChunkTransformStream,
};
