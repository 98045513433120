import random from 'lodash/random';
import {
  MaintenanceDashboardService,
} from '@/services/MaintenanceDashboard';
import {
  GraphsService,
} from '@/services/GraphsService';
import {
  historyAndTrendColors,
} from '../../config/colorsMap';

export default {
  state: {
    furnaces: [],
    plannedMaintenanceTasks: [],
    heatLossChart: {},
    furnaceDetails: {},
    alarmHistory: [],
    furnacesLocation: '',
    furnacesLoading: false,
    plannedMaintenanceTasksLoading: false,
    furnaceDetailsLoading: false,
    alarmHistoryLoading: false,
    loadingGraphs: true,
    loadingHeatLoss: false,
  },
  mutations: {
    setMaintenanceDashboardFurnaces(state, furnaces) {
      state.furnaces = furnaces;
    },
    setFurnacesLoading(state, isLoading) {
      state.furnacesLoading = isLoading;
    },
    setPlannedMaintenanceTasksLoading(state, isLoading) {
      state.plannedMaintenanceTasksLoading = isLoading;
    },
    setMaintenanceDashboardFurnacesLocation(state, locationId) {
      state.furnacesLocation = locationId;
    },
    setFurnaceDetailsLoading(state, isLoading) {
      state.furnaceDetailsLoading = isLoading;
    },
    setPlannedMaintenanceTasks(state, jobs) {
      state.plannedMaintenanceTasks = jobs;
    },
    setMaintenanceDashboardFurnaceDetails(state, furnace) {
      state.furnaceDetails = furnace;
    },
    setIsLoadingGraphs(state, isLoading) {
      state.loadingGraphs = isLoading;
    },
    setAlarmHistory(state, notes) {
      state.alarmHistory = notes;
    },
    setAlarmHistoryLoading(state, isLoading) {
      state.alarmHistoryLoading = isLoading;
    },
    setHeatLossLoading(state, isLoading) {
      state.loadingHeatLoss = isLoading;
    },
    setHeatLossChart(state, chartData) {
      state.heatLossChart = chartData;
    },
    clearHeatLossChart(state, val) {
      state.heatLossChart = val;
    },
  },
  actions: {
    async SET_ALARMS_HISTORY(context) {
      context.commit('setAlarmHistoryLoading', true);
      context.commit('setAlarmHistory', []);
      context.commit('setAlarmHistoryLoading', false);
    },
    async SET_MAINTENANCE_DASHBOARD_DETAILS(context, equipmentId) {
      try {
        context.commit('clearHeatLossChart', {});
        context.commit('setFurnaceDetailsLoading', true);
        const res = await MaintenanceDashboardService.getFurnaceDetails(
          equipmentId,
        );
        const furnace = res[0];
        context.commit('setIsLoadingGraphs', true);
        context.commit('setMaintenanceDashboardFurnaceDetails', furnace);
        context.commit('setFurnaceDetailsLoading', false);
        context.commit('setIsLoadingGraphs', false);
      } catch (e) {
        console.log(e);
      }
    },
    async GET_HEAT_LOSS_CHART(context, payload) {
      const {
        // eslint-disable-next-line camelcase
        start_time, end_time,
      } = payload;
      try {
        context.commit('setHeatLossLoading', true);
        const res = await GraphsService.getHeatLossChart(
          (new Date(start_time).getTime() / 1000).toFixed(0),
          (new Date(end_time).getTime() / 1000).toFixed(0),
          context.state.furnaceDetails.id,
        );
        const chartWithColors = {
          datasets: res.datasets.map((dataset) => {
            if (!historyAndTrendColors[dataset.id]) {
              const R = random(0, 230);
              let G;
              let B;
              if (R < 100) {
                G = random(0, 230);
                B = random(120, 230);
              } else {
                G = random(0, 230);
                B = random(0, 230);
              }
              dataset.backgroundColor = `rgb(${R},${G},${B})`;
              dataset.borderColor = `rgb(${R},${G},${B})`;
            } else {
              dataset.backgroundColor = historyAndTrendColors[dataset.id];
              dataset.borderColor = historyAndTrendColors[dataset.id];
            }
            dataset.yAxisID = dataset.y_axis_id;
            return dataset;
          }),
        };
        context.commit('setHeatLossChart', chartWithColors);
        context.commit('setHeatLossLoading', false);
      } catch (e) {
        console.log(e);
      }
    },
    async SET_MAINTENANCE_DASHBOARD(context, payload) {
      try {
        let furnaces = [];
        const locationId = payload.customerId || payload.locationId || payload.groupId;
        context.commit('setFurnacesLoading', true);
        context.commit('setMaintenanceDashboardFurnaces', []);
        furnaces = await MaintenanceDashboardService.getFurnaces(payload);
        if (this.getters.getSelectedLocation.id === locationId) {
          context.commit('setFurnacesLoading', false);
          context.commit('setMaintenanceDashboardFurnaces', furnaces);
          context.commit('setMaintenanceDashboardFurnacesLocation', locationId);
        } else {
          // eslint-disable-next-line no-throw-literal
          throw {
            msg: 'Warning: Changed location during load',
          };
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    maintenanceDashboardGetFurnaces(state) {
      return state.furnaces;
    },
    maintenanceDashboardGetFurnaceDetails(state) {
      return state.furnaceDetails;
    },
    maintenanceDashboardGetFurnaceDetailsLoading(state) {
      return state.furnaceDetailsLoading;
    },
    maintenanceDashboardGetFurnacesLoading(state) {
      return state.furnacesLoading;
    },
    maintenanceDashboardGetGraphsLoading(state) {
      return state.loadingGraphs;
    },
    maintenanceDashboardGetFurnacesLocation(state) {
      return state.furnacesLocation;
    },
    maintenanceDashboardGetAlarmHistory(state) {
      return state.alarmHistory;
    },
    maintenanceDashboardGetAlarmHistoryLoading(state) {
      return state.alarmHistoryLoading;
    },
    maintenanceDashboardGetHeatLossChart(state) {
      return state.heatLossChart;
    },
    maintenanceDashboardIsLoadingHeatLossChart(state) {
      return state.loadingHeatLoss;
    },
  },
};
