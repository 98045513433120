export default {
  title: 'Wartungsauftrag: {id}',
  addAttachments: 'Anhänge hinzufügen',
  addReport: 'Bericht beifügen',
  isValidated: 'Bestätigt',
  stateAction: {
    accept: 'Akzeptieren',
    finish: 'Beenden',
    reject: 'Ablehnen',
    restart: 'Neustart',
    start: 'Start',
    stop: 'Stoppen',
    validate: 'Bestätigen',
  },
  stateChangeSuccessMessage: 'Der Status wurde geändert',
  stateChangeErrorMessage: 'Statuswechsel fehlgeschlagen',
  restartConfirmation: 'Das Neustarten löscht alle existierenden Reports für den Auftrag. Möchten Sie dennoch Neustarten?',
  validateConfirmation: 'Bestätigen Sie die Validierung.',
};
