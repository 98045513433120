export const COLOR = Object.freeze({
  'Carburizing depth': 'rgb(253,152,37,1)',
  'Case depth': 'rgb(253,152,37,1)',
  'Carbon profile': 'rgb(253,152,37,1)',
  'Carbon profile min': 'rgb(253,152,37,1)',
  'Carbon profile max': 'rgb(253,152,37,1)',
  Temperature: 'rgba(210,54,0,1)',
  'Soot limit': 'rgb(253,152,37,1)',
  'Nitrogen profile': 'rgba(121,201,68,1)',
  'Nitrogen profile min': 'rgba(121,201,68,1)',
  'Nitrogen profile max': 'rgba(121,201,68,1)',
  'Carbide profile': 'rgba(255,0,0,0.5)',
  'Carbide profile min': 'rgba(255,0,0,0.5)',
  'Carbide profile max': 'rgba(255,0,0,0.5)',
  'Actual carbide limit': 'rgba(255,0,0,0.5)',
  'Nitride profile min': 'rgb(0,52,178)',
  'Nitride profile max': 'rgb(0,52,178)',
  'Nitride profile': 'rgb(0,52,178)',
  'Actual nitride limit': 'rgb(0,52,178)',
  'Hardness curve': 'rgb(118,3,136)',
  'Hardness curve min': 'rgb(118,3,136)',
  'Hardness curve max': 'rgb(118,3,136)',
  'Required surface hardness': 'rgb(118,3,136)',
  'Carbon potential': 'rgb(253,152,37,1)',
  'Surface carbon': 'rgb(115,6,6)',
  'Nitrogen potential': 'rgba(121,201,68,1)',
  'Surface nitrogen': 'rgb(0,27,168)',
  'Surface carbide': 'rgb(118,3,136)',
});
