import {
  TComponentLegacyKeys,
  TComponentKeys,
  TComponentPT9800Keys,
  TComputedPT9800PlannedPartInput,
  ETimeseriesMeaning,
} from '@/types/openapi-type';
import {
  EPhysicalDimension,
} from '@/types/physical-dimension.ts';
import {
  ERouteName,
} from '@/router/route-name.ts';
import {
  ECalendarEventType,
  EMaintenanceState,
  EMaintenanceServiceTab,
  EMaintenancePriority,
  ECalendarPeriod,
  EMaintenanceRecurrencePeriod,
  EMaintenanceRecurrenceMonthDayValue,
  EMaintenanceRecurrenceMonthDayDimension,
  EMaintenanceRecurrenceEndCondition,
} from '@/types/calendar.ts';
import {
  EAssetProfileStatus,
} from '@/types/asset-profile-status.ts';
import {
  EAssetProfileCombinator,
} from '@/types/asset-profile-combinator.ts';
import {
  TFurnacePanelStatus,
} from '@/components/furnace-panel/furnace-panel.ts';
import {
  components,
} from '@/types/openapi.ts';
import {
  EObservationType,
} from '@/types/observation-type.ts';
import {
  EVisualisation,
} from '@/types/performance-dashboard.ts';
import {
  EMonth,
} from '@/types/date.ts';
import {
  EConsumptionMeaningMap,
  EJobFeatureMeaningMap,
  EPhysicalPropertyMeaningMap,
} from '@/types/meaning-type.ts';
import {
  EMeasurementUnitMap,
} from '@/types/measurement.ts';
import {
  EAttributeLabel,
} from '@/types/attribute-type.ts';
import {
  TFunctionCategory,
  TFunctionDefinition,
  TOperatorDefinition,
} from '@/types/function.ts';
import {
  EAppGroupSemantic,
  EAppIdMap,
  EAppGroupCommercial,
} from '@/types/app-type.ts';
import {
  getComponentScopedTranslationMap,
} from '@/utils/i18n.ts';
import {
  EDataFormat,
} from '@/types/metadata.ts';
import {
  EActivityCoefficients,
  ECarburizingCarbonControlType,
  ECarburizingEndControlType,
  ECarburizingNitrogenControlType,
  ECarburizingRecipeSimulationValuesType,
  ECarburizingSimulationPartShape,
  ECarburizingSimulatorRecipeType,
  ECarburizingTemperatureControlType,
  ECarburizingVolumeControlType,
  ESimulationStep,
} from '@/types/simulator.ts';
import {
  EChamberType,
  EHeatTreatmentProcessType,
} from '@/types/chamber';
import {
  EHierarchy,
} from '@/types/asset-tree.ts';
import {
  EAuditResultType,
} from '@/types/audit-result.ts';
import {
  ESubscriptionTreeLabel,
} from '@/types/notification';
import npm from './npm_de.ts';

/**
 * better to ask Felix if you're not sure if something is general term or not
 * do not mistake with "simple" translations like "submit" or "cancel"
 * never use for composition
 *
 * generalTerm translations are something, which are specific to project "domain" or "project"
 * "common" translations are everything, which can be reused across different projects
 *
 * TODO: extract "common" above this object in order to reuse some translations
 */
const generalTerm = {
  // common
  loadId: 'Ladungs id',
  // do not mistake with "Load ID"
  // "Load ID" might or might not be indexing field for database
  // "Load Identifier" is a customer-written "comment" or "description" which they later use for internal usage
  loadIdentifier: 'Ladungsbezeichner',
  date: 'Datum',
  name: 'Name',
  description: 'Beschreibung',
  event: 'Ereignis | Ereignisse',
  mappedPT9800user: 'Der gemappte PT9800 Benutzer',
  // equipment || asset
  asset: 'Asset',
  assetId: 'Asset id',
  assetType: 'Asset Typ',
  customer: 'Kunde',
  composition: 'Zusammensetzung',
  element: 'Element | Elemente',
  root: 'Wurzel | Wurzeln',
  location: 'Standort | Standorte',
  locationId: 'Standort id',
  // job || load
  job: 'Job',
  jobDisplayName: 'Load No',
  jobStartedAtUtc: 'Startzeitpunkt',
  jobFinishedAtUtc: 'Endzeitpunkt',
  // sensor || meaning || signal
  sensor: 'Sensor',
  signal: 'Signal | Signale',
  unit: 'Einheit | Einheiten',
  recipe: 'Rezept | Rezepte',
  scadaType: 'Scada type',
  materialSystemList: 'Materials Systeme',
  measure: 'Messwert | Messwerte',
  setpoint: 'Sollwert | Sollwerte',
  reportId: 'Berichtsnummer',
  report: 'Bericht',
  reporter: 'Berichterstatter',
  jobId: 'Auftrags id',
  partId: 'Teile id',
  part: 'Teil',
  step: 'Schritt',
  recipeStep: 'Rezeptschritt',
  material: 'Material',
  drawingReference: 'Zeichnungsreferenz',
  displayName: 'Name',
  preset: 'Voreinstellung',
  materialCalculationModel: 'Materialberechnungsmodell',
  data: 'Daten',
  chart: 'Diagramm | Diagramme',
  austenite: 'Austenit',
  limitMinus: 'Limit -',
  limitPlus: 'Limit +',
  envelope: 'Grenze',

  // TODO: migrate to "common"
  createdAt: 'Erstellt am',
  showDeleted: 'Gelöschte anzeigen',
  overwrite: 'Überschreiben',
  requirement: 'Anforderung',
  result: 'Ergebnis',

  schaefflerDiagram: 'Schäffler Diagramm',
  CCTDiagram: 'CCT diagramm',
  temperingCurveDiagram: 'Tempering curve',
  jominyDiagram: 'Jominy curve',

  carbonPotential: 'C-Pegel Status',
  nitrogenPotential: 'Stickstoffpotential',
  surfaceCarbon: 'Oberflächenkohlenstoff',
  surfaceNitrogen: 'Oberflächenstickstoff',
  surfaceCarbide: 'Oberflächenkarbide',

  recipeStage: 'Rezeptschritt',
  component: 'Komponente | Komponenten',
  equipment: 'Gerät | Geräte',
  equipmentGroup: 'Gerätegruppe | Gerätegruppen',
  sparePart: 'Ersatzteil | Ersatzteile',
  manufacturer: 'Hersteller',
  partNumber: 'Teilenummer',
  chamber: 'Kammer | Kammern',
  company: 'Unternehmen | Unternehmen',
  heatTreatmentCapability: 'Wärmebehandlungsfähigkeit | Wärmebehandlungsfähigkeiten',
  preference: 'Präferenz | Präferenzen',
  heatTreatmentProcess: 'Wärmebehandlungsprozess | Wärmebehandlungsprozessen',
};

// used in tables or other places where you need to filter by general term
const generalTermFilter = {
  asset: 'Suche Asset',
};

const SparePart: Partial<TComponentKeys<'SparePart'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  manufacturerId: generalTerm.manufacturer,
  manufacturerDisplayName: generalTerm.manufacturer,
};

const HeatTreatmentCapability: Partial<TComponentKeys<'HeatTreatmentCapability'>> = {
  heatTreatmentProcess: generalTerm.heatTreatmentProcess,
  preference: generalTerm.preference,
};

const Chamber: Partial<TComponentKeys<'Chamber'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
};

const MeasurementDisplayName: Record<EMeasurementUnitMap, string> = {
  [EMeasurementUnitMap.AMPERE]: 'Ampere',
  [EMeasurementUnitMap.ATMOSPHERE]: 'Atmosphäre',
  [EMeasurementUnitMap.BAR]: 'Bar',
  [EMeasurementUnitMap.BAR_PER_MINUTE]: 'Bar pro Minute',
  [EMeasurementUnitMap.BRINELL_HARDNESS]: 'Brinellhärte',
  [EMeasurementUnitMap.BRINELL_HARDNESS_10_1500_10]: 'Brinellhärte 10/1500/10',
  [EMeasurementUnitMap.BRINELL_HARDNESS_10_1500_15]: 'Brinellhärte 10/1500/15',
  [EMeasurementUnitMap.BRINELL_HARDNESS_10_3000_10]: 'Brinellhärte 10/3000/10',
  [EMeasurementUnitMap.BRINELL_HARDNESS_10_3000_15]: 'Brinellhärte 10/3000/15',
  [EMeasurementUnitMap.BRINELL_HARDNESS_10_500_10]: 'Brinellhärte 10/500/10',
  [EMeasurementUnitMap.BRINELL_HARDNESS_10_500_15]: 'Brinellhärte 10/500/15',
  [EMeasurementUnitMap.BRINELL_HARDNESS_6_1500_10]: 'Brinellhärte 6/1500/10',
  [EMeasurementUnitMap.BRINELL_HARDNESS_6_1500_15]: 'Brinellhärte 6/1500/15',
  [EMeasurementUnitMap.BRINELL_HARDNESS_6_3000_10]: 'Brinellhärte 6/3000/10',
  [EMeasurementUnitMap.BRINELL_HARDNESS_6_3000_15]: 'Brinellhärte 6/3000/15',
  [EMeasurementUnitMap.BRINELL_HARDNESS_6_500_10]: 'Brinellhärte 6/500/10/',
  [EMeasurementUnitMap.BRINELL_HARDNESS_6_500_15]: 'Brinellhärte 6/500/15',
  [EMeasurementUnitMap.BTU]: 'Britische Wärmeeinheit',
  [EMeasurementUnitMap.BTU_PER_HOUR]: 'Britische Wärmeeinheit pro Stunde',
  [EMeasurementUnitMap.BTU_PER_MINUTE]: 'Britische Wärmeeinheit pro Minute',
  [EMeasurementUnitMap.CUBIC_CENTIMETRE_PER_MINUTE]: 'Kubikzentimeter pro Minute',
  [EMeasurementUnitMap.CUBIC_FEET]: 'Kubikfuß',
  [EMeasurementUnitMap.CUBIC_FEET_PER_HOUR]: 'Kubikfuß pro Stunde',
  [EMeasurementUnitMap.CUBIC_FEET_PER_MINUTE]: 'Kubikfuß pro Minute',
  [EMeasurementUnitMap.CUBIC_METRES]: 'Kubikmeter',
  [EMeasurementUnitMap.CUBIC_METRE_PER_HOUR]: 'Kubikmeter pro Stunde',
  [EMeasurementUnitMap.CUBIC_METRE_PER_MINUTE]: 'Kubikmeter pro Minute',
  [EMeasurementUnitMap.CUBIC_METRE_PER_SECOND]: 'Kubikmeter pro Sekunde',
  [EMeasurementUnitMap.DAY]: 'Tag',
  [EMeasurementUnitMap.DEGREES_CELSIUS]: 'Grad Celsius',
  [EMeasurementUnitMap.DEGREES_CELSIUS_PER_MINUTE]: 'Grad Celsius pro Minute',
  [EMeasurementUnitMap.DEGREES_FAHRENHEIT]: 'Grad Fahrenheit',
  [EMeasurementUnitMap.DEGREES_FAHRENHEIT_PER_MINUTE]: 'Grad Fahrenheit pro Minute',
  [EMeasurementUnitMap.DEGREES_KELVIN]: 'Grad Kelvin',
  [EMeasurementUnitMap.DELTA_DEGREES_CELSIUS]: 'Delta Grad Celsius',
  [EMeasurementUnitMap.DELTA_DEGREES_FAHRENHEIT]: 'Delta Grad Fahrenheit',
  [EMeasurementUnitMap.DELTA_DEGREES_KELVIN]: 'Delta Grad Kelvin',
  [EMeasurementUnitMap.FEET_OF_WATER_COLUMN]: 'Fuß der Wassersäule',
  [EMeasurementUnitMap.GALLONS_PER_HOUR]: 'Gallonen pro Stunde',
  [EMeasurementUnitMap.GALLONS_PER_MINUTE]: 'Gallonen pro Minute',
  [EMeasurementUnitMap.GRAM]: 'Gramm',
  [EMeasurementUnitMap.HORSEPOWER]: 'Pferdestärken',
  [EMeasurementUnitMap.HOUR]: 'Stunde',
  [EMeasurementUnitMap.INCH]: 'Zoll',
  [EMeasurementUnitMap.INCH_OF_MERCURY]: 'Zoll Quecksilber',
  [EMeasurementUnitMap.INCH_OF_WATER_COLUMN]: 'Zoll der Wassersäule',
  [EMeasurementUnitMap.KILOGRAM]: 'Kilogramm',
  [EMeasurementUnitMap.KILOMETER]: 'Kilometer',
  [EMeasurementUnitMap.KILOVOLT]: 'Kilovolt',
  [EMeasurementUnitMap.KILOVOLT_AMPERE]: 'Kilovolt-Ampere',
  [EMeasurementUnitMap.KILOWATT]: 'Kilowatt',
  [EMeasurementUnitMap.KILOWATT_HOUR]: 'Kilowatt pro Stunde',
  [EMeasurementUnitMap.KILO_PASCAL]: 'Kilo-Pascal',
  [EMeasurementUnitMap.KNOOP_HARDNESS]: 'Knoophärte',
  [EMeasurementUnitMap.KNOOP_HARDNESS_100]: 'Knoophärte 100',
  [EMeasurementUnitMap.KNOOP_HARDNESS_200]: 'Knoophärte 200',
  [EMeasurementUnitMap.KNOOP_HARDNESS_50]: 'Knoophärte 05',
  [EMeasurementUnitMap.KNOOP_HARDNESS_500]: 'Knoophärte 500',
  [EMeasurementUnitMap.LITRE]: 'Liter',
  [EMeasurementUnitMap.LITRE_PER_HOUR]: 'Liter pro Stunde',
  [EMeasurementUnitMap.LITRE_PER_MINUTE]: 'Liter pro Mitute',
  [EMeasurementUnitMap.MEGAWATT]: 'Megawatt',
  [EMeasurementUnitMap.METRE]: 'Meter',
  [EMeasurementUnitMap.METRES_PER_MINUTE]: 'Meter pro Minute',
  [EMeasurementUnitMap.METRES_PER_SECOND]: 'Meter pro Sekunde',
  [EMeasurementUnitMap.MICROBAR]: 'Mikrobar',
  [EMeasurementUnitMap.MICROBAR_PER_MINUTE]: 'Mikrobar pro Minute',
  [EMeasurementUnitMap.MICRON]: 'Mikron',
  [EMeasurementUnitMap.MICRON_HG_PER_HOUR]: 'Mikron HG pro Stunde',
  [EMeasurementUnitMap.MICRON_HG_PER_MINUTE]: 'Mikron HG pro Minute',
  [EMeasurementUnitMap.MICRON_OF_MERCURY]: 'Mikron Quecksilber',
  [EMeasurementUnitMap.MILLIBAR]: 'Millibar',
  [EMeasurementUnitMap.MILLIBAR_PER_MINUTE]: 'Millibar pro Minute',
  [EMeasurementUnitMap.MILLIGRAM]: 'Milligramm',
  [EMeasurementUnitMap.MILLILITRE]: 'Milliliter',
  [EMeasurementUnitMap.MILLILITRE_PER_MINUTE]: 'Milliliter pro Minute',
  [EMeasurementUnitMap.MILLIMETRE]: 'Millimeter',
  [EMeasurementUnitMap.MILLIMETRE_OF_MERCURY]: 'Millimeter Quecksilber',
  [EMeasurementUnitMap.MILLIMETRE_OF_WATER_COLUMN]: 'Millimeter der Wassersäule',
  [EMeasurementUnitMap.MILLIVOLT]: 'Millivolt',
  [EMeasurementUnitMap.MILLI_AMPERE]: 'Milli Ampere',
  [EMeasurementUnitMap.MINUTE]: 'Minute',
  [EMeasurementUnitMap.MONTH]: 'Monat',
  [EMeasurementUnitMap.NUMBER]: 'Nummer',
  [EMeasurementUnitMap.NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR]: 'Normal Liter pro Quadratmeter pro Stunde',
  [EMeasurementUnitMap.PARTS_PER_MILLION]: 'Teile pro Million',
  [EMeasurementUnitMap.PASCAL]: 'Pascal',
  [EMeasurementUnitMap.PERCENT]: 'Prozentsatz',
  [EMeasurementUnitMap.PERCENT_CARBON]: 'Prozentsatz Kohlenstoff',
  [EMeasurementUnitMap.PERCENT_HYDROGEN]: 'Wasserstoff in Prozent',
  [EMeasurementUnitMap.PERCENT_NITROGEN]: 'Stickstoff in Prozent',
  [EMeasurementUnitMap.POUND]: 'Pfund',
  [EMeasurementUnitMap.PSI]: 'PSI',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS]: 'Rockwellhärte',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_15_N]: 'Rockwellhärte 15N',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_15_T]: 'Rockwellhärte 15T',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_15_W]: 'Rockwellhärte 15W',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_15_X]: 'Rockwellhärte 15X',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_15_Y]: 'Rockwellhärte 15Y',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_30_N]: 'Rockwellhärte 30N',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_30_T]: 'Rockwellhärte 30T',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_30_W]: 'Rockwellhärte 30W',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_30_X]: 'Rockwellhärte 30X',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_30_Y]: 'Rockwellhärte 30Y',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_45_N]: 'Rockwellhärte 45N',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_45_T]: 'Rockwellhärte 45T',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_45_W]: 'Rockwellhärte 45W',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_45_X]: 'Rockwellhärte 45X',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_45_Y]: 'Rockwellhärte 45Y',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_A]: 'Rockwellhärte A',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_B]: 'Rockwellhärte B',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_C]: 'Rockwellhärte C',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_D]: 'Rockwellhärte D',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_E]: 'Rockwellhärte E',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_F]: 'Rockwellhärte F',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_G]: 'Rockwellhärte G',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_H]: 'Rockwellhärte H',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_K]: 'Rockwellhärte K',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_L]: 'Rockwellhärte L',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_M]: 'Rockwellhärte M',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_P]: 'Rockwellhärte P',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_R]: 'Rockwellhärte R',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_S]: 'Rockwellhärte S',
  [EMeasurementUnitMap.ROCKWELL_HARDNESS_V]: 'Rockwellhärte V',
  [EMeasurementUnitMap.RPM]: 'RPM',
  [EMeasurementUnitMap.SECOND]: 'Sekunde',
  [EMeasurementUnitMap.TONNE]: 'Tonne',
  [EMeasurementUnitMap.TORR]: 'Torr',
  [EMeasurementUnitMap.TORR_PER_MINUTE]: 'Torr pro Minute',
  [EMeasurementUnitMap.UNIT_LESS]: 'Keine Angabe',
  [EMeasurementUnitMap.VICKERS_HARDNESS]: 'Vickershärte',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_1]: 'Vickershärte 0.1',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_2]: 'Vickershärte 0.2',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_3]: 'Vickershärte 0.3',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_4]: 'Vickershärte 0.4',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_5]: 'Vickershärte 0.5',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_6]: 'Vickershärte 0.6',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_7]: 'Vickershärte 0.7',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_8]: 'Vickershärte 0.8',
  [EMeasurementUnitMap.VICKERS_HARDNESS_0_9]: 'Vickershärte 0.9',
  [EMeasurementUnitMap.VICKERS_HARDNESS_1]: 'Vickershärte 1',
  [EMeasurementUnitMap.VICKERS_HARDNESS_10]: 'Vickershärte 10',
  [EMeasurementUnitMap.VICKERS_HARDNESS_100]: 'Vickershärte 100',
  [EMeasurementUnitMap.VICKERS_HARDNESS_20]: 'Vickershärte 20',
  [EMeasurementUnitMap.VICKERS_HARDNESS_30]: 'Vickershärte 30',
  [EMeasurementUnitMap.VICKERS_HARDNESS_40]: 'Vickershärte 40',
  [EMeasurementUnitMap.VICKERS_HARDNESS_50]: 'Vickershärte 50',
  [EMeasurementUnitMap.VICKERS_HARDNESS_60]: 'Vickershärte 60',
  [EMeasurementUnitMap.VICKERS_HARDNESS_70]: 'Vickershärte 70',
  [EMeasurementUnitMap.VICKERS_HARDNESS_80]: 'Vickershärte 80',
  [EMeasurementUnitMap.VICKERS_HARDNESS_90]: 'Vickershärte 90',
  [EMeasurementUnitMap.VOLT]: 'Volt',
  [EMeasurementUnitMap.VOLT_AMPERE]: 'Volt Ampere',
  [EMeasurementUnitMap.WATT]: 'Watt',
  [EMeasurementUnitMap.WEEK]: 'Woche',
  [EMeasurementUnitMap.WEIGHT_PERCENT]: 'Gewicht in Prozent',
  [EMeasurementUnitMap.YEAR]: 'Jahr',
};

const AuditResultType: Record<EAuditResultType, string> = {
  [EAuditResultType.FAILED]: 'Fehlgeschlagen',
  [EAuditResultType.INCONCLUSIVE]: 'Unschlüssig',
  [EAuditResultType.PASSED]: 'Bestanden',
};

const PhysicalPropertyMeaning: Record<EPhysicalPropertyMeaningMap, string> = {
  [EPhysicalPropertyMeaningMap.LOAD_MASS]: 'Ladungsmasse',
};

const ConsumptionMeaning: Record<EConsumptionMeaningMap, string> = {
  [EConsumptionMeaningMap.DEPRECATED_AMMONIA_CONSUMPTION]: 'Ammoniaverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_AMMONIA_COST]: 'Ammoniakosten',
  [EConsumptionMeaningMap.DEPRECATED_CARBON_DIOXIDE_CONSUMPTION]: 'Kohlendioxidverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_CARBON_DIOXIDE_COST]: 'Kohlendioxidkosten',
  [EConsumptionMeaningMap.DEPRECATED_CO_GAS_CONSUMPTION]: 'CO-Gasverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_CO_GAS_COST]: 'CO-Gaskosten',
  [EConsumptionMeaningMap.DEPRECATED_DISSOCIATED_AMMONIA_CONSUMPTION]: 'Verbrauch von dissociated Ammonia',
  [EConsumptionMeaningMap.DEPRECATED_DISSOCIATED_AMMONIA_COST]: 'Kosten für dissociated Ammonia',
  [EConsumptionMeaningMap.DEPRECATED_ELECTRICITY_CONSUMPTION]: 'Stromverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_ELECTRICITY_COST]: 'Stromkosten',
  [EConsumptionMeaningMap.DEPRECATED_ENDO_GAS_CONSUMPTION]: 'Endo-Gasverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_ENDO_GAS_COST]: 'Endo-Gaskosten',
  [EConsumptionMeaningMap.DEPRECATED_HYDROGEN_CONSUMPTION]: 'Wasserstoffverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_HYDROGEN_COST]: 'Wasserstoffkosten',
  [EConsumptionMeaningMap.DEPRECATED_NITROGEN_CONSUMPTION]: 'Stickstoffverbrauch',
  [EConsumptionMeaningMap.DEPRECATED_NITROGEN_COST]: 'Stickstoffkosten',
  [EConsumptionMeaningMap.ACETYLENE_C2H2_CONSUMPTION]: 'Acetylen (C2H2) Verbrauch',
  [EConsumptionMeaningMap.AIR_CONSUMPTION]: 'Luftverbrauch',
  [EConsumptionMeaningMap.AMMONIA_NH3_CONSUMPTION]: 'Ammoniak (NH3) Verbrauch',
  [EConsumptionMeaningMap.ARGON_CONSUMPTION]: 'Argonverbrauch',
  [EConsumptionMeaningMap.CARBON_DIOXIDE_CO2_CONSUMPTION]: 'Kohlendioxid (CO2) Verbrauch',
  [EConsumptionMeaningMap.CARBON_MONOXIDE_CO_CONSUMPTION]: 'Kohlenmonoxid (CO) Verbrauch',
  [EConsumptionMeaningMap.DISSOCIATED_AMMONIA_NH3_CONSUMPTION]: 'Dissoziierter Ammoniak (NH3) Verbrauch',
  [EConsumptionMeaningMap.ENDO_GAS_NATURAL_CONSUMPTION]: 'Endogas (Naturgas) Verbrauch',
  [EConsumptionMeaningMap.ENDO_GAS_PROPANE_CONSUMPTION]: 'Endogas (Propan) Verbrauch',
  [EConsumptionMeaningMap.ETHENE_C2H4_CONSUMPTION]: 'Ethen (C2H4) Verbrauch',
  [EConsumptionMeaningMap.HELIUM_CONSUMPTION]: 'Heliumverbrauch',
  [EConsumptionMeaningMap.HYDROGEN_H2_CONSUMPTION]: 'Wasserstoff (H2) Verbrauch',
  [EConsumptionMeaningMap.METHANOL_CH3OH_CONSUMPTION]: 'Methanol (CH3OH) Verbrauch',
  [EConsumptionMeaningMap.NATURAL_GAS_CONSUMPTION]: 'Erdgasverbrauch',
  [EConsumptionMeaningMap.NITROGEN_CONSUMPTION]: 'Stickstoffverbrauch',
  [EConsumptionMeaningMap.NITROUS_OXIDE_N2O_CONSUMPTION]: 'Distickstoffoxid (N2O) Verbrauch',
  [EConsumptionMeaningMap.PROPANE_C3H8_CONSUMPTION]: 'Propan (C3H8) Verbrauch',
  [EConsumptionMeaningMap.WATER_CONSUMPTION]: 'Wasserverbrauch',
  [EConsumptionMeaningMap.ETHANE_C2H6_CONSUMPTION]: 'Ethan (C2H6) Verbrauch',
  [EConsumptionMeaningMap.METHANE_CH4_CONSUMPTION]: 'Methan (CH4) Verbrauch',
  [EConsumptionMeaningMap.USER_DEFINED_001]: 'Benutzerdefinierter Verbrauch 001',
  [EConsumptionMeaningMap.USER_DEFINED_002]: 'Benutzerdefinierter Verbrauch 002',
  [EConsumptionMeaningMap.USER_DEFINED_003]: 'Benutzerdefinierter Verbrauch 003',
  [EConsumptionMeaningMap.USER_DEFINED_004]: 'Benutzerdefinierter Verbrauch 004',
  [EConsumptionMeaningMap.USER_DEFINED_005]: 'Benutzerdefinierter Verbrauch 005',
  [EConsumptionMeaningMap.USER_DEFINED_006]: 'Benutzerdefinierter Verbrauch 006',
  [EConsumptionMeaningMap.USER_DEFINED_007]: 'Benutzerdefinierter Verbrauch 007',
  [EConsumptionMeaningMap.USER_DEFINED_008]: 'Benutzerdefinierter Verbrauch 008',
  [EConsumptionMeaningMap.USER_DEFINED_009]: 'Benutzerdefinierter Verbrauch 009',
  [EConsumptionMeaningMap.USER_DEFINED_010]: 'Benutzerdefinierter Verbrauch 010',
  [EConsumptionMeaningMap.USER_DEFINED_011]: 'Benutzerdefinierter Verbrauch 011',
  [EConsumptionMeaningMap.USER_DEFINED_012]: 'Benutzerdefinierter Verbrauch 012',
  [EConsumptionMeaningMap.USER_DEFINED_013]: 'Benutzerdefinierter Verbrauch 013',
  [EConsumptionMeaningMap.USER_DEFINED_014]: 'Benutzerdefinierter Verbrauch 014',
  [EConsumptionMeaningMap.USER_DEFINED_015]: 'Benutzerdefinierter Verbrauch 015',
  [EConsumptionMeaningMap.USER_DEFINED_016]: 'Benutzerdefinierter Verbrauch 016',
  [EConsumptionMeaningMap.USER_DEFINED_017]: 'Benutzerdefinierter Verbrauch 017',
  [EConsumptionMeaningMap.USER_DEFINED_018]: 'Benutzerdefinierter Verbrauch 018',
  [EConsumptionMeaningMap.USER_DEFINED_019]: 'Benutzerdefinierter Verbrauch 019',
  [EConsumptionMeaningMap.USER_DEFINED_020]: 'Benutzerdefinierter Verbrauch 020',
};

const JobFeatureMeaning: Record<EJobFeatureMeaningMap, string> = {
  [EJobFeatureMeaningMap.HEAT_LOSS_MIN_POWER]: 'Wärmeverlust minimale Leistung',
  [EJobFeatureMeaningMap.HEAT_LOSS_MAX_TEMPERATURE]: 'Wärmeverlust maximale Temperatur',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_ACCURACY]: 'Temperatur Regelgenauigkeit',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_PRECISION]: 'Temperatur Regelpräzision',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_ACCURACY]: 'Heizleistung Regelgenauigkeit',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_PRECISION]: 'Heizleistung Regelpräzision',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_ACCURACY]: 'Elektrische Leistungs Regelgenauigkeit',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_PRECISION]: 'Elektrische Leistungs Regelpräzision',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_ACCURACY]: 'Kühlleistung Regelgenauigkeit',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_PRECISION]: 'Kühlleistung Regelpräzision',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_ACCURACY]: 'Relative Druck Regelgenauigkeit',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_PRECISION]: 'Relative Druck Regelpräzision',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_ACCURACY]: 'Kammertemperatur Regelgenauigkeit',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_PRECISION]: 'Kammertemperatur Regelpräzision',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_ACCURACY]: 'Absolute Druck Regelgenauigkeit',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_PRECISION]: 'Absolute Druck Regelpräzision',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Vakuumdruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_PRECISION]: 'Vakuumdruck Regelpräzision',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_ACCURACY]: 'Atmosphärendruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_PRECISION]: 'Atmosphärendruck Regelpräzision',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_ACCURACY]: 'Raumtemperatur Regelgenauigkeit',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_PRECISION]: 'Raumtemperatur Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_ACCURACY]: 'Aktivität aH Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_PRECISION]: 'Aktivität aH Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_ACCURACY]: 'Potential HP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_PRECISION]: 'Potential HP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_ACCURACY]: 'Potential KH- Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_PRECISION]: 'Potential KH- Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_ACCURACY]: 'Aktivität aO Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_PRECISION]: 'Aktivität aO Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_ACCURACY]: 'Potential OP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_PRECISION]: 'Potential OP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_ACCURACY]: 'Potential KO Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_PRECISION]: 'Potential KO Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_ACCURACY]: 'Aktivität aC Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_PRECISION]: 'Aktivität aC Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_ACCURACY]: 'Potential CP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_PRECISION]: 'Potential CP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_ACCURACY]: 'Potential KC Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_PRECISION]: 'Potential KC Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_ACCURACY]: 'Aktivität aN Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_PRECISION]: 'Aktivität aN Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_ACCURACY]: 'Potential NP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_PRECISION]: 'Potential NP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_ACCURACY]: 'Potential KN Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_PRECISION]: 'Potential KN Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_ACCURACY]: 'Aktivität aB Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_PRECISION]: 'Aktivität aB Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_ACCURACY]: 'Potential BP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_PRECISION]: 'Potential BP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_ACCURACY]: 'Potential kB Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_PRECISION]: 'Potential kB Regelpräzision',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_ACCURACY]: 'Aktivität aS Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_PRECISION]: 'Aktivität aS Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_ACCURACY]: 'Potential SP Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_PRECISION]: 'Potential SP Regelpräzision',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_ACCURACY]: 'Potential kS Regelgenauigkeit',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_PRECISION]: 'Potential kS Regelpräzision',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_ACCURACY]: 'Acetylen C2H2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_PRECISION]: 'Acetylen C2H2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_ACCURACY]: 'Luftstrom Regelgenauigkeit',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_PRECISION]: 'Luftstrom Regelpräzision',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_ACCURACY]: 'Ammoniak NH3 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_PRECISION]: 'Ammoniak NH3 Flow Regelpräzision',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_ACCURACY]: 'Argon Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_PRECISION]: 'Argon Fluss Regelpräzision',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_ACCURACY]: 'Kohlendioxid CO2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_PRECISION]: 'Kohlendioxid CO2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_ACCURACY]: 'Kohlenmonoxid CO Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_PRECISION]: 'Kohlenmonoxid CO Flow Regelpräzision',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_ACCURACY]: 'Dissoziiertes Ammoniak NH3 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_PRECISION]: 'Dissoziiertes Ammoniak NH3 Fluss Regelpräzision',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_ACCURACY]: 'Endogas natürliche Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_PRECISION]: 'Endogas natürliche Fluss Regelpräzision',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_ACCURACY]: 'Endogas Propan Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_PRECISION]: 'Endogas Propan Fluss Regelpräzision',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_ACCURACY]: 'Äthylen C2H4 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_PRECISION]: 'Äthylen C2H4 Flow Regelpräzision',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_ACCURACY]: 'Helium Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_PRECISION]: 'Helium Fluss Regelpräzision',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_ACCURACY]: 'Wasserstoff H2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_PRECISION]: 'Wasserstoff H2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_ACCURACY]: 'Methanol CH3OH Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_PRECISION]: 'Methanol CH3OH Flow Regelpräzision',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_ACCURACY]: 'Erdgas Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_PRECISION]: 'Erdgas Fluss Regelpräzision',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_ACCURACY]: 'Stickstoff Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_PRECISION]: 'Stickstoff Fluss Regelpräzision',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_ACCURACY]: 'Propan C3H8 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_PRECISION]: 'Propan C3H8 Flow Regelpräzision',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_ACCURACY]: 'Wasser Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_PRECISION]: 'Wasser Fluss Regelpräzision',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_ACCURACY]: 'Lachgas N2O Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_PRECISION]: 'Lachgas N2O Fluss Regelpräzision',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_AGILITY]: 'Temperatur Regelagilität',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_AGILITY]: 'Heizleistung Regelagilität',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_AGILITY]: 'Elektrische Leistungs Regelagilität',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_AGILITY]: 'Kühlleistung Regelagilität',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_AGILITY]: 'Relative Druck Regelagilität',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_AGILITY]: 'Kammertemperatur Regelagilität',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_AGILITY]: 'Absolute Druck Regelagilität',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_AGILITY]: 'Vakuumdruck Regelagilität',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_AGILITY]: 'Atmosphärendruck Regelagilität',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_AGILITY]: 'Raumtemperatur Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_AGILITY]: 'Aktivität aH Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_AGILITY]: 'Potential HP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_AGILITY]: 'Potential kH Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_AGILITY]: 'Aktivität aO Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_AGILITY]: 'Potential OP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_AGILITY]: 'Potential kO Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_AGILITY]: 'Aktivität aC Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_AGILITY]: 'Potential CP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_AGILITY]: 'Potential kC Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_AGILITY]: 'Aktivität aN Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_AGILITY]: 'Potential NP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_AGILITY]: 'Potential kN Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_AGILITY]: 'Aktivität aB Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_AGILITY]: 'Potential BP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_AGILITY]: 'Potential kB Regelagilität',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_AGILITY]: 'Aktivität aS Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_AGILITY]: 'Potential SP Regelagilität',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_AGILITY]: 'Potential kS Regelagilität',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_AGILITY]: 'Acetylen C2H2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_AGILITY]: 'Luftstrom Regelagilität',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_AGILITY]: 'Ammoniak NH3 Fluss Regelagilität',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_AGILITY]: 'Argon Fluss Regelagilität',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_AGILITY]: 'Kohlendioxid CO2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_AGILITY]: 'Kohlenmonoxid CO Fluss Regelagilität',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_AGILITY]: 'Dissoziiertes Ammoniak NH3 Fluss Regelagilität',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_AGILITY]: 'Endogas natürliche Fluss Regelagilität',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_AGILITY]: 'Endogas Propan Fluss Regelagilität',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_AGILITY]: 'Äthylen C2H4 Fluss Regelagilität',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_AGILITY]: 'Helium Fluss Regelagilität',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_AGILITY]: 'Wasserstoff H2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_AGILITY]: 'Methanol CH3OH Fluss Regelagilität',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_AGILITY]: 'Erdgas Fluss Regelagilität',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_AGILITY]: 'Stickstoff Fluss Regelagilität',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_AGILITY]: 'Propan- C3H8 Fluss Regelagilität',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_AGILITY]: 'Wasser Fluss Regelagilität',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_AGILITY]: 'Lachgas N2O Fluss Regelagilität',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_ACCURACY]: 'Sauerstoff O2 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_PRECISION]: 'Sauerstoff O2 Flow Regelpräzision',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_AGILITY]: 'Sauerstoff O2 Fluss Regelagilität',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_ACCURACY]: 'Methan CH4 Fluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_PRECISION]: 'Methan CH4 Flow Regelpräzision',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_AGILITY]: 'Methan CH4 Fluss Regelagilität',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_ACCURACY]: 'Dissoziations Regelgenauigkeit',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_PRECISION]: 'Dissoziations Regelpräzision',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_AGILITY]: 'Dissoziations Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_ACCURACY]: 'Partialdruck Acetylen C2H2 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_PRECISION]: 'Partialdruck Acetylen C2H2 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_AGILITY]: 'Partialdruck Acetylen C2H2 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_ACCURACY]: 'Partialdruck Luft Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_PRECISION]: 'Partialdruck Luft Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_AGILITY]: 'Partialdruck Luft Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_ACCURACY]: 'Partialdruck Ammoniak NH3 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_PRECISION]: 'Partialdruck Ammoniak NH3 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_AGILITY]: 'Partialdruck Ammoniak NH3 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_ACCURACY]: 'Partialdruck Argon Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_PRECISION]: 'Partialdruck Argon Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_AGILITY]: 'Partialdruck Argon Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_ACCURACY]: 'Partialdruck Kohlendioxid CO2 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_PRECISION]: 'Partialdruck Kohlendioxid CO2 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_AGILITY]: 'Partialdruck Kohlendioxid CO2 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_ACCURACY]: 'Partialdruck Kohlenmonoxid CO Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_PRECISION]: 'Partialdruck Kohlenmonoxid CO Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_AGILITY]: 'Partialdruck Kohlenmonoxid CO Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_ACCURACY]: 'Partialdruck dissoziiertes Ammoniak NH3 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_PRECISION]: 'Partialdruck dissoziiertes Ammoniak NH3 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_AGILITY]: 'Partialdruck dissoziiertes Ammoniak NH3 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_ACCURACY]: 'Partialdruck Endogas natürlich Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_PRECISION]: 'Partialdruck Endogas natürlich Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_AGILITY]: 'Partialdruck Endogas natürlich Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_ACCURACY]: 'Partialdruck Endogas Propan Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_PRECISION]: 'Partialdruck Endogas Propan Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_AGILITY]: 'Partialdruck Endogas Propan Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_ACCURACY]: 'Partialdruck Ethylen C2H4 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_PRECISION]: 'Partialdruck Ethylen C2H4 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_AGILITY]: 'Partialdruck Ethylen C2H4 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_ACCURACY]: 'Partialdruck Helium Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_PRECISION]: 'Partialdruck Helium Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_AGILITY]: 'Partialdruck Helium Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_ACCURACY]: 'Partialdruck Wasserstoff H2 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_PRECISION]: 'Partialdruck Wasserstoff H2 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_AGILITY]: 'Partialdruck Wasserstoff H2 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_ACCURACY]: 'Partialdruck Methanol CH3OH Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_PRECISION]: 'Partialdruck Methanol CH3OH Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_AGILITY]: 'Partialdruck Methanol CH3OH Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_ACCURACY]: 'Partialdruck Erdgas Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_PRECISION]: 'Partialdruck Erdgas Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_AGILITY]: 'Partialdruck Erdgas Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_ACCURACY]: 'Partialdruck Stickstoff Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_PRECISION]: 'Partialdruck Stickstoff Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_AGILITY]: 'Partialdruck Stickstoff Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_ACCURACY]: 'Partialdruck Lachgas N2O Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_PRECISION]: 'Partialdruck Lachgas N2O Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_AGILITY]: 'Partialdruck Lachgas N2O Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_ACCURACY]: 'Partialdruck Propan C3H8 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_PRECISION]: 'Partialdruck Propan C3H8 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_AGILITY]: 'Partialdruck Propan C3H8 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_ACCURACY]: 'Partialdruck Wasser Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_PRECISION]: 'Partialdruck Wasser Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_AGILITY]: 'Partialdruck Wasser Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Acetylen C2H2 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_PRECISION]: 'Partialdruck Acetylen C2H2 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_AGILITY]: 'Partialdruck Acetylen C2H2 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Luft Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_PRECISION]: 'Partialdruck Luft Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_AGILITY]: 'Partialdruck Luft Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Ammoniak NH3 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_PRECISION]: 'Partialdruck Ammoniak NH3 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_AGILITY]: 'Partialdruck Ammoniak NH3 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Argon Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_PRECISION]: 'Partialdruck Argon Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_AGILITY]: 'Partialdruck Argon Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Kohlendioxid CO2 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_PRECISION]: 'Partialdruck Kohlendioxid CO2 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_AGILITY]: 'Partialdruck Kohlendioxid CO2 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Kohlenmonoxid CO Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_PRECISION]: 'Partialdruck Kohlenmonoxid CO Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_AGILITY]: 'Partialdruck Kohlenmonoxid CO Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY]: 'Partialdruck dissoziiertes Ammoniak NH3 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_PRECISION]: 'Partialdruck dissoziiertes Ammoniak NH3 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_AGILITY]: 'Partialdruck dissoziiertes Ammoniak NH3 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Endogas natürlich Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_PRECISION]: 'Partialdruck Endogas natürlich Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_AGILITY]: 'Partialdruck Endogas natürlich Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Endogas Propan Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_PRECISION]: 'Partialdruck Endogas Propan Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_AGILITY]: 'Partialdruck Endogas Propan Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Ethylen C2H4 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_PRECISION]: 'Partialdruck Ethylen C2H4 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_AGILITY]: 'Partialdruck Ethylen C2H4 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Helium Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_PRECISION]: 'Partialdruck Helium Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_AGILITY]: 'Partialdruck Helium Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Wasserstoff H2 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_PRECISION]: 'Partialdruck Wasserstoff H2 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_AGILITY]: 'Partialdruck Wasserstoff H2 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Methanol CH3OH Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_PRECISION]: 'Partialdruck Methanol CH3OH Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_AGILITY]: 'Partialdruck Methanol CH3OH Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Erdgas Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_PRECISION]: 'Partialdruck Erdgas Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_AGILITY]: 'Partialdruck Erdgas Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Stickstoff Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_PRECISION]: 'Partialdruck Stickstoff Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_AGILITY]: 'Partialdruck Stickstoff Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Lachgas N2O Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_PRECISION]: 'Partialdruck Lachgas N2O Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_AGILITY]: 'Partialdruck Lachgas N2O Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Propan C3H8 Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_PRECISION]: 'Partialdruck Propan C3H8 Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_AGILITY]: 'Partialdruck Propan C3H8 Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Wasser Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_PRECISION]: 'Partialdruck Wasser Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_AGILITY]: 'Partialdruck Wasser Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_ACCURACY]: 'Partialdruck Sauerstoff Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_PRECISION]: 'Partialdruck Sauerstoff Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_AGILITY]: 'Partialdruck Sauerstoff Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_ACCURACY]: 'Partialdruck Kohlenstoff Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_PRECISION]: 'Partialdruck Kohlenstoff Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_AGILITY]: 'Partialdruck Kohlenstoff Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_ACCURACY]: 'Partialdruck Bor Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_PRECISION]: 'Partialdruck Bor Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_AGILITY]: 'Partialdruck Bor Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_ACCURACY]: 'Partialdruck Schwefel Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_PRECISION]: 'Partialdruck Schwefel Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_AGILITY]: 'Partialdruck Schwefel Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Sauerstoff Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_PRECISION]: 'Partialdruck Sauerstoff Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_AGILITY]: 'Partialdruck Sauerstoff Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Kohlenstoff Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_PRECISION]: 'Partialdruck Kohlenstoff Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_AGILITY]: 'Partialdruck Kohlenstoff Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Bor Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_PRECISION]: 'Partialdruck Bor Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_AGILITY]: 'Partialdruck Bor Prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Schwefel Prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_PRECISION]: 'Partialdruck Schwefel Prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_AGILITY]: 'Partialdruck Schwefel Prozent Regelagilität',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_ACCURACY]: 'Ethan C2H6 Durchfluss Regelgenauigkeit',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_AGILITY]: 'Ethan C2H6 Durchfluss Regelagilität',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_PRECISION]: 'Ethan C2H6 Durchfluss Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_ACCURACY]: 'Partialdruck Ethan C2H6 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_AGILITY]: 'Partialdruck Ethan C2H6 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_PRECISION]: 'Partialdruck Ethan C2H6 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Ethan C2H6 prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_AGILITY]: 'Partialdruck Ethan C2H6 prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_PRECISION]: 'Partialdruck Ethan C2H6 prozent Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_ACCURACY]: 'Partialdruck Methan CH4 Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_AGILITY]: 'Partialdruck Methan CH4 Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_PRECISION]: 'Partialdruck Methan CH4 Regelpräzision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_ACCURACY]: 'Partialdruck Methan CH4 prozent Regelgenauigkeit',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_AGILITY]: 'Partialdruck Methan CH4 prozent Regelagilität',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_PRECISION]: 'Partialdruck Methan CH4 prozent Regelpräzision',
  [EJobFeatureMeaningMap.HEAT_LOSS_AT_500C]: 'Heat loss (v2)',
  [EJobFeatureMeaningMap.ASSET_DWELLING_TIME]: 'Asset Verweildauer pro Job',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Hochvakuumdruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_AGILITY]: 'Hochvakuumdruck Niderigvakuumdruck',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_PRECISION]: 'Hochvakuumdruck Regelpräzision',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Niderigvakuumdruck Regelgenauigkeit',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_AGILITY]: 'Niderigvakuumdruck Niderigvakuumdruck',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_PRECISION]: 'Niderigvakuumdruck Regelpräzision',
  [EJobFeatureMeaningMap.VACUUM_TIME_TO_CROSS_OVER]: 'Zeit bis Vakuum cross over',
  [EJobFeatureMeaningMap.VACUUM_MAX_LEAK_UP_RATE]: 'Maximale leak up Zeit',
  [EJobFeatureMeaningMap.PEARSON_CORRELATION]: 'Pearson Korrelation',
  [EJobFeatureMeaningMap.SPEARMAN_CORRELATION]: 'Spearman Korrelation',
  [EJobFeatureMeaningMap.USER_DEFINED_001]: 'Benutzerdefinierter Jobwert 001',
  [EJobFeatureMeaningMap.USER_DEFINED_002]: 'Benutzerdefinierter Jobwert 002',
  [EJobFeatureMeaningMap.USER_DEFINED_003]: 'Benutzerdefinierter Jobwert 003',
  [EJobFeatureMeaningMap.USER_DEFINED_004]: 'Benutzerdefinierter Jobwert 004',
  [EJobFeatureMeaningMap.USER_DEFINED_005]: 'Benutzerdefinierter Jobwert 005',
  [EJobFeatureMeaningMap.USER_DEFINED_006]: 'Benutzerdefinierter Jobwert 006',
  [EJobFeatureMeaningMap.USER_DEFINED_007]: 'Benutzerdefinierter Jobwert 007',
  [EJobFeatureMeaningMap.USER_DEFINED_008]: 'Benutzerdefinierter Jobwert 008',
  [EJobFeatureMeaningMap.USER_DEFINED_009]: 'Benutzerdefinierter Jobwert 009',
  [EJobFeatureMeaningMap.USER_DEFINED_010]: 'Benutzerdefinierter Jobwert 010',
  [EJobFeatureMeaningMap.USER_DEFINED_011]: 'Benutzerdefinierter Jobwert 011',
  [EJobFeatureMeaningMap.USER_DEFINED_012]: 'Benutzerdefinierter Jobwert 012',
  [EJobFeatureMeaningMap.USER_DEFINED_013]: 'Benutzerdefinierter Jobwert 013',
  [EJobFeatureMeaningMap.USER_DEFINED_014]: 'Benutzerdefinierter Jobwert 014',
  [EJobFeatureMeaningMap.USER_DEFINED_015]: 'Benutzerdefinierter Jobwert 015',
  [EJobFeatureMeaningMap.USER_DEFINED_016]: 'Benutzerdefinierter Jobwert 016',
  [EJobFeatureMeaningMap.USER_DEFINED_017]: 'Benutzerdefinierter Jobwert 017',
  [EJobFeatureMeaningMap.USER_DEFINED_018]: 'Benutzerdefinierter Jobwert 018',
  [EJobFeatureMeaningMap.USER_DEFINED_019]: 'Benutzerdefinierter Jobwert 019',
  [EJobFeatureMeaningMap.USER_DEFINED_020]: 'Benutzerdefinierter Jobwert 020',
  [EJobFeatureMeaningMap.USER_DEFINED_021]: 'Benutzerdefinierter Jobwert 021',
  [EJobFeatureMeaningMap.USER_DEFINED_022]: 'Benutzerdefinierter Jobwert 022',
  [EJobFeatureMeaningMap.USER_DEFINED_023]: 'Benutzerdefinierter Jobwert 023',
  [EJobFeatureMeaningMap.USER_DEFINED_024]: 'Benutzerdefinierter Jobwert 024',
  [EJobFeatureMeaningMap.USER_DEFINED_025]: 'Benutzerdefinierter Jobwert 025',
  [EJobFeatureMeaningMap.USER_DEFINED_026]: 'Benutzerdefinierter Jobwert 026',
  [EJobFeatureMeaningMap.USER_DEFINED_027]: 'Benutzerdefinierter Jobwert 027',
  [EJobFeatureMeaningMap.USER_DEFINED_028]: 'Benutzerdefinierter Jobwert 028',
  [EJobFeatureMeaningMap.USER_DEFINED_029]: 'Benutzerdefinierter Jobwert 029',
  [EJobFeatureMeaningMap.USER_DEFINED_030]: 'Benutzerdefinierter Jobwert 030',
  [EJobFeatureMeaningMap.USER_DEFINED_031]: 'Benutzerdefinierter Jobwert 031',
  [EJobFeatureMeaningMap.USER_DEFINED_032]: 'Benutzerdefinierter Jobwert 032',
  [EJobFeatureMeaningMap.USER_DEFINED_033]: 'Benutzerdefinierter Jobwert 033',
  [EJobFeatureMeaningMap.USER_DEFINED_034]: 'Benutzerdefinierter Jobwert 034',
  [EJobFeatureMeaningMap.USER_DEFINED_035]: 'Benutzerdefinierter Jobwert 035',
  [EJobFeatureMeaningMap.USER_DEFINED_036]: 'Benutzerdefinierter Jobwert 036',
  [EJobFeatureMeaningMap.USER_DEFINED_037]: 'Benutzerdefinierter Jobwert 037',
  [EJobFeatureMeaningMap.USER_DEFINED_038]: 'Benutzerdefinierter Jobwert 038',
  [EJobFeatureMeaningMap.USER_DEFINED_039]: 'Benutzerdefinierter Jobwert 039',
  [EJobFeatureMeaningMap.USER_DEFINED_040]: 'Benutzerdefinierter Jobwert 040',
  [EJobFeatureMeaningMap.USER_DEFINED_041]: 'Benutzerdefinierter Jobwert 041',
  [EJobFeatureMeaningMap.USER_DEFINED_042]: 'Benutzerdefinierter Jobwert 042',
  [EJobFeatureMeaningMap.USER_DEFINED_043]: 'Benutzerdefinierter Jobwert 043',
  [EJobFeatureMeaningMap.USER_DEFINED_044]: 'Benutzerdefinierter Jobwert 044',
  [EJobFeatureMeaningMap.USER_DEFINED_045]: 'Benutzerdefinierter Jobwert 045',
  [EJobFeatureMeaningMap.USER_DEFINED_046]: 'Benutzerdefinierter Jobwert 046',
  [EJobFeatureMeaningMap.USER_DEFINED_047]: 'Benutzerdefinierter Jobwert 047',
  [EJobFeatureMeaningMap.USER_DEFINED_048]: 'Benutzerdefinierter Jobwert 048',
  [EJobFeatureMeaningMap.USER_DEFINED_049]: 'Benutzerdefinierter Jobwert 049',
  [EJobFeatureMeaningMap.USER_DEFINED_050]: 'Benutzerdefinierter Jobwert 050',
  [EJobFeatureMeaningMap.USER_DEFINED_051]: 'Benutzerdefinierter Jobwert 051',
  [EJobFeatureMeaningMap.USER_DEFINED_052]: 'Benutzerdefinierter Jobwert 052',
  [EJobFeatureMeaningMap.USER_DEFINED_053]: 'Benutzerdefinierter Jobwert 053',
  [EJobFeatureMeaningMap.USER_DEFINED_054]: 'Benutzerdefinierter Jobwert 054',
  [EJobFeatureMeaningMap.USER_DEFINED_055]: 'Benutzerdefinierter Jobwert 055',
  [EJobFeatureMeaningMap.USER_DEFINED_056]: 'Benutzerdefinierter Jobwert 056',
  [EJobFeatureMeaningMap.USER_DEFINED_057]: 'Benutzerdefinierter Jobwert 057',
  [EJobFeatureMeaningMap.USER_DEFINED_058]: 'Benutzerdefinierter Jobwert 058',
  [EJobFeatureMeaningMap.USER_DEFINED_059]: 'Benutzerdefinierter Jobwert 059',
  [EJobFeatureMeaningMap.USER_DEFINED_060]: 'Benutzerdefinierter Jobwert 060',
  [EJobFeatureMeaningMap.USER_DEFINED_061]: 'Benutzerdefinierter Jobwert 061',
  [EJobFeatureMeaningMap.USER_DEFINED_062]: 'Benutzerdefinierter Jobwert 062',
  [EJobFeatureMeaningMap.USER_DEFINED_063]: 'Benutzerdefinierter Jobwert 063',
  [EJobFeatureMeaningMap.USER_DEFINED_064]: 'Benutzerdefinierter Jobwert 064',
  [EJobFeatureMeaningMap.USER_DEFINED_065]: 'Benutzerdefinierter Jobwert 065',
  [EJobFeatureMeaningMap.USER_DEFINED_066]: 'Benutzerdefinierter Jobwert 066',
  [EJobFeatureMeaningMap.USER_DEFINED_067]: 'Benutzerdefinierter Jobwert 067',
  [EJobFeatureMeaningMap.USER_DEFINED_068]: 'Benutzerdefinierter Jobwert 068',
  [EJobFeatureMeaningMap.USER_DEFINED_069]: 'Benutzerdefinierter Jobwert 069',
  [EJobFeatureMeaningMap.USER_DEFINED_070]: 'Benutzerdefinierter Jobwert 070',
  [EJobFeatureMeaningMap.USER_DEFINED_071]: 'Benutzerdefinierter Jobwert 071',
  [EJobFeatureMeaningMap.USER_DEFINED_072]: 'Benutzerdefinierter Jobwert 072',
  [EJobFeatureMeaningMap.USER_DEFINED_073]: 'Benutzerdefinierter Jobwert 073',
  [EJobFeatureMeaningMap.USER_DEFINED_074]: 'Benutzerdefinierter Jobwert 074',
  [EJobFeatureMeaningMap.USER_DEFINED_075]: 'Benutzerdefinierter Jobwert 075',
  [EJobFeatureMeaningMap.USER_DEFINED_076]: 'Benutzerdefinierter Jobwert 076',
  [EJobFeatureMeaningMap.USER_DEFINED_077]: 'Benutzerdefinierter Jobwert 077',
  [EJobFeatureMeaningMap.USER_DEFINED_078]: 'Benutzerdefinierter Jobwert 078',
  [EJobFeatureMeaningMap.USER_DEFINED_079]: 'Benutzerdefinierter Jobwert 079',
  [EJobFeatureMeaningMap.USER_DEFINED_080]: 'Benutzerdefinierter Jobwert 080',
};

const TimeseriesMeaning: Record<ETimeseriesMeaning, string> = {
  [ETimeseriesMeaning.TEMPERATURE_SETPOINT]: 'Temperature Sollwert',
  [ETimeseriesMeaning.TEMPERATURE_READING]: 'Temperature Istwert',
  [ETimeseriesMeaning.HEATING_POWER_OUTPUT_SETPOINT]: 'Heating power output Sollwert',
  [ETimeseriesMeaning.HEATING_POWER_OUTPUT_READING]: 'Heating power output Istwert',
  [ETimeseriesMeaning.HEATING_POWER_ENABLED]: 'Heating power enabled',
  [ETimeseriesMeaning.ELECTRICAL_POWER_SETPOINT]: 'Electrical power Sollwert',
  [ETimeseriesMeaning.ELECTRICAL_POWER_READING]: 'Electrical power Istwert',
  [ETimeseriesMeaning.TOTAL_HEATING_GAS_SUPPLY_FLOW_READING]: 'Total heating gas supply Durchfluss Istwert',
  [ETimeseriesMeaning.COOLING_POWER_OUTPUT_SETPOINT]: 'Cooling power output Sollwert',
  [ETimeseriesMeaning.COOLING_POWER_OUTPUT_READING]: 'Cooling power output Istwert',
  [ETimeseriesMeaning.COOLING_POWER_ENABLED]: 'Cooling power enabled',
  [ETimeseriesMeaning.TOTAL_COOLING_GAS_SUPPLY_FLOW_READING]: 'Total cooling gas supply Durchfluss Istwert',
  [ETimeseriesMeaning.RELATIVE_PRESSURE_SETPOINT]: 'Relative pressure Sollwert',
  [ETimeseriesMeaning.RELATIVE_PRESSURE_READING]: 'Relative pressure Istwert',
  [ETimeseriesMeaning.CHAMBER_TEMPERATURE_SETPOINT]: 'Chamber temperature Sollwert',
  [ETimeseriesMeaning.CHAMBER_TEMPERATURE_READING]: 'Chamber temperature Istwert',
  [ETimeseriesMeaning.ABSOLUTE_PRESSURE_SETPOINT]: 'Absolute pressure Sollwert',
  [ETimeseriesMeaning.ABSOLUTE_PRESSURE_READING]: 'Absolute pressure Istwert',
  [ETimeseriesMeaning.VACUUM_PRESSURE_SETPOINT]: 'Vacuum pressure Sollwert',
  [ETimeseriesMeaning.VACUUM_PRESSURE_READING]: 'Vacuum pressure Istwert',
  [ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_SETPOINT]: 'Atmospheric pressure Sollwert',
  [ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_READING]: 'Atmospheric pressure Istwert',
  [ETimeseriesMeaning.ROOM_TEMPERATURE_SETPOINT]: 'Room temperature Sollwert',
  [ETimeseriesMeaning.ROOM_TEMPERATURE_READING]: 'Room temperature Istwert',
  [ETimeseriesMeaning.ACTIVITY_AH_SETPOINT]: 'Activity aH Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AH_CALCULATION]: 'Activity aH calculation',
  [ETimeseriesMeaning.POTENTIAL_HP_SETPOINT]: 'Potential HP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_HP_CALCULATION]: 'Potential HP calculation',
  [ETimeseriesMeaning.POTENTIAL_KH_SETPOINT]: 'Potential kH Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KH_CALCULATION]: 'Potential kH calculation',
  [ETimeseriesMeaning.ACTIVITY_AO_SETPOINT]: 'Activity aO Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AO_CALCULATION]: 'Activity aO calculation',
  [ETimeseriesMeaning.POTENTIAL_OP_SETPOINT]: 'Potential OP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_OP_CALCULATION]: 'Potential OP calculation',
  [ETimeseriesMeaning.POTENTIAL_KO_SETPOINT]: 'Potential kO Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KO_CALCULATION]: 'Potential kO calculation',
  [ETimeseriesMeaning.ACTIVITY_AC_SETPOINT]: 'Activity aC Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AC_CALCULATION]: 'Activity aC calculation',
  [ETimeseriesMeaning.POTENTIAL_CP_SETPOINT]: 'Potential CP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_CP_CALCULATION]: 'Potential CP calculation',
  [ETimeseriesMeaning.POTENTIAL_KC_SETPOINT]: 'Potential kC Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KC_CALCULATION]: 'Potential kC calculation',
  [ETimeseriesMeaning.ACTIVITY_AN_SETPOINT]: 'Activity aN Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AN_CALCULATION]: 'Activity aN calculation',
  [ETimeseriesMeaning.POTENTIAL_NP_SETPOINT]: 'Potential NP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_NP_CALCULATION]: 'Potential NP calculation',
  [ETimeseriesMeaning.POTENTIAL_KN_SETPOINT]: 'Potential kN Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KN_CALCULATION]: 'Potential kN calculation',
  [ETimeseriesMeaning.ACTIVITY_AB_SETPOINT]: 'Activity aB Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AB_CALCULATION]: 'Activity aB calculation',
  [ETimeseriesMeaning.POTENTIAL_BP_SETPOINT]: 'Potential BP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_BP_CALCULATION]: 'Potential BP calculation',
  [ETimeseriesMeaning.POTENTIAL_KB_SETPOINT]: 'Potential kB Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KB_CALCULATION]: 'Potential kB calculation',
  [ETimeseriesMeaning.ACTIVITY_AS_SETPOINT]: 'Activity aS Sollwert',
  [ETimeseriesMeaning.ACTIVITY_AS_CALCULATION]: 'Activity aS calculation',
  [ETimeseriesMeaning.POTENTIAL_SP_SETPOINT]: 'Potential SP Sollwert',
  [ETimeseriesMeaning.POTENTIAL_SP_CALCULATION]: 'Potential SP calculation',
  [ETimeseriesMeaning.POTENTIAL_KS_SETPOINT]: 'Potential kS Sollwert',
  [ETimeseriesMeaning.POTENTIAL_KS_CALCULATION]: 'Potential kS calculation',
  [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT]: 'Acetylene C2H2 Durchfluss Sollwert',
  [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_READING]: 'Acetylene C2H2 Durchfluss Istwert',
  [ETimeseriesMeaning.AIR_FLOW_SETPOINT]: 'Air Durchfluss Sollwert',
  [ETimeseriesMeaning.AIR_FLOW_READING]: 'Air Durchfluss Istwert',
  [ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT]: 'Ammonia NH3 Durchfluss Sollwert',
  [ETimeseriesMeaning.AMMONIA_NH3_FLOW_READING]: 'Ammonia NH3 Durchfluss Istwert',
  [ETimeseriesMeaning.ARGON_FLOW_SETPOINT]: 'Argon Durchfluss Sollwert',
  [ETimeseriesMeaning.ARGON_FLOW_READING]: 'Argon Durchfluss Istwert',
  [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT]: 'Carbon dioxide CO2 Durchfluss Sollwert',
  [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_READING]: 'Carbon dioxide CO2 Durchfluss Istwert',
  [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT]: 'Carbon monoxide CO Durchfluss Sollwert',
  [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_READING]: 'Carbon monoxide CO Durchfluss Istwert',
  [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT]: 'Dissociated ammonia NH3 Durchfluss Sollwert',
  [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_READING]: 'Dissociated ammonia NH3 Durchfluss Istwert',
  [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT]: 'Endo gas natural Durchfluss Sollwert',
  [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_READING]: 'Endo gas natural Durchfluss Istwert',
  [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT]: 'Endo gas propane Durchfluss Sollwert',
  [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_READING]: 'Endo gas propane Durchfluss Istwert',
  [ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT]: 'Ethene C2H4 Durchfluss Sollwert',
  [ETimeseriesMeaning.ETHENE_C2H4_FLOW_READING]: 'Ethene C2H4 Durchfluss Istwert',
  [ETimeseriesMeaning.HELIUM_FLOW_SETPOINT]: 'Helium Durchfluss Sollwert',
  [ETimeseriesMeaning.HELIUM_FLOW_READING]: 'Helium Durchfluss Istwert',
  [ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT]: 'Hydrogen H2 Durchfluss Sollwert',
  [ETimeseriesMeaning.HYDROGEN_H2_FLOW_READING]: 'Hydrogen H2 Durchfluss Istwert',
  [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT]: 'Methanol ch3oh Durchfluss Sollwert',
  [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_READING]: 'Methanol ch3oh Durchfluss Istwert',
  [ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT]: 'Natural gas Durchfluss Sollwert',
  [ETimeseriesMeaning.NATURAL_GAS_FLOW_READING]: 'Natural gas Durchfluss Istwert',
  [ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT]: 'Nitrogen Durchfluss Sollwert',
  [ETimeseriesMeaning.NITROGEN_FLOW_READING]: 'Nitrogen Durchfluss Istwert',
  [ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT]: 'Propane C3H8 Durchfluss Sollwert',
  [ETimeseriesMeaning.PROPANE_C3H8_FLOW_READING]: 'Propane C3H8 Durchfluss Istwert',
  [ETimeseriesMeaning.WATER_FLOW_SETPOINT]: 'Water Durchfluss Sollwert',
  [ETimeseriesMeaning.WATER_FLOW_READING]: 'Water Durchfluss Istwert',
  [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT]: 'Nitrous oxide N2O Durchfluss Sollwert',
  [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_READING]: 'Nitrous oxide N2O Durchfluss Istwert',
  [ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT]: 'MEthan CH4 Durchfluss Sollwert',
  [ETimeseriesMeaning.METHANE_CH4_FLOW_READING]: 'MEthan CH4 Durchfluss Istwert',
  [ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT]: 'Oxygen O2 Durchfluss Sollwert',
  [ETimeseriesMeaning.OXYGEN_O2_FLOW_READING]: 'Oxygen O2 Durchfluss Istwert',
  [ETimeseriesMeaning.DISSOCIATION_SETPOINT]: 'Dissociation Sollwert',
  [ETimeseriesMeaning.DISSOCIATION_READING]: 'Dissociation Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_SETPOINT]: 'Partialdruck acetylene C2H2 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_READING]: 'Partialdruck acetylene C2H2 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_SETPOINT]: 'Partialdruck air Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_READING]: 'Partialdruck air Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_SETPOINT]: 'Partialdruck ammonia NH3 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_READING]: 'Partialdruck ammonia NH3 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_SETPOINT]: 'Partialdruck argon Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_READING]: 'Partialdruck argon Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_SETPOINT]: 'Partialdruck carbon dioxide CO2 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_READING]: 'Partialdruck carbon dioxide CO2 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_SETPOINT]: 'Partialdruck carbon monoxide CO Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_READING]: 'Partialdruck carbon monoxide CO Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_SETPOINT]: 'Partialdruck dissociated ammonia NH3 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_READING]: 'Partialdruck dissociated ammonia NH3 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_SETPOINT]: 'Partialdruck endo gas natural Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_READING]: 'Partialdruck endo gas natural Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_SETPOINT]: 'Partialdruck endo gas propane Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_READING]: 'Partialdruck endo gas propane Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_SETPOINT]: 'Partialdruck ethene C2H4 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_READING]: 'Partialdruck ethene C2H4 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_SETPOINT]: 'Partialdruck helium Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_READING]: 'Partialdruck helium Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_SETPOINT]: 'Partialdruck hydrogen H2 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_READING]: 'Partialdruck hydrogen H2 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_SETPOINT]: 'Partialdruck methanol ch3oh Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_READING]: 'Partialdruck methanol ch3oh Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_SETPOINT]: 'Partialdruck natural gas Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_READING]: 'Partialdruck natural gas Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_SETPOINT]: 'Partialdruck nitrogen Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_READING]: 'Partialdruck nitrogen Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_SETPOINT]: 'Partialdruck nitrous oxide N2O Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_READING]: 'Partialdruck nitrous oxide N2O Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_SETPOINT]: 'Partialdruck propane C3H8 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_READING]: 'Partialdruck propane C3H8 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_SETPOINT]: 'Partialdruck water Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_READING]: 'Partialdruck water Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_SETPOINT]: 'Partialdruck acetylene C2H2 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_READING]: 'Partialdruck acetylene C2H2 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_SETPOINT]: 'Partialdruck air prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_READING]: 'Partialdruck air prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_SETPOINT]: 'Partialdruck ammonia NH3 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_READING]: 'Partialdruck ammonia NH3 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_SETPOINT]: 'Partialdruck argon prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_READING]: 'Partialdruck argon prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_SETPOINT]: 'Partialdruck carbon dioxide CO2 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_READING]: 'Partialdruck carbon dioxide CO2 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_SETPOINT]: 'Partialdruck carbon monoxide CO prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_READING]: 'Partialdruck carbon monoxide CO prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_SETPOINT]: 'Partialdruck dissociated ammonia NH3 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_READING]: 'Partialdruck dissociated ammonia NH3 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_SETPOINT]: 'Partialdruck endo gas natural prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_READING]: 'Partialdruck endo gas natural prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_SETPOINT]: 'Partialdruck endo gas propane prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_READING]: 'Partialdruck endo gas propane prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_SETPOINT]: 'Partialdruck ethene C2H4 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_READING]: 'Partialdruck ethene C2H4 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_SETPOINT]: 'Partialdruck helium prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_READING]: 'Partialdruck helium prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_SETPOINT]: 'Partialdruck hydrogen H2 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_READING]: 'Partialdruck hydrogen H2 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_SETPOINT]: 'Partialdruck methanol ch3oh prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_READING]: 'Partialdruck methanol ch3oh prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_SETPOINT]: 'Partialdruck natural gas prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_READING]: 'Partialdruck natural gas prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_SETPOINT]: 'Partialdruck nitrogen prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_READING]: 'Partialdruck nitrogen prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_SETPOINT]: 'Partialdruck nitrous oxide N2O prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_READING]: 'Partialdruck nitrous oxide N2O prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_SETPOINT]: 'Partialdruck propane C3H8 prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_READING]: 'Partialdruck propane C3H8 prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_SETPOINT]: 'Partialdruck water prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_READING]: 'Partialdruck water prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_SETPOINT]: 'Partialdruck oxygen Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_READING]: 'Partialdruck oxygen Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_SETPOINT]: 'Partialdruck carbon Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_READING]: 'Partialdruck carbon Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_SETPOINT]: 'Partialdruck boron Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_READING]: 'Partialdruck boron Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_SETPOINT]: 'Partialdruck sulfur Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_READING]: 'Partialdruck sulfur Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_SETPOINT]: 'Partialdruck oxygen prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_READING]: 'Partialdruck oxygen prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_SETPOINT]: 'Partialdruck carbon prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_READING]: 'Partialdruck carbon prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_SETPOINT]: 'Partialdruck boron prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_READING]: 'Partialdruck boron prozent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_SETPOINT]: 'Partialdruck sulfur prozent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_READING]: 'Partialdruck sulfur prozent Istwert',
  [ETimeseriesMeaning.ETHANE_C2H6_FLOW_SETPOINT]: 'Ethan C2H6 Durchfluss Sollwert',
  [ETimeseriesMeaning.ETHANE_C2H6_FLOW_READING]: 'Ethan C2H6 Durchfluss Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_SETPOINT]: 'Partialdruck Ethan C2H6 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_READING]: 'Partialdruck Ethan C2H6 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_SETPOINT]: 'Partialdruck Ethan C2H6 percent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_READING]: 'Partialdruck Ethan C2H6 percent Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_SETPOINT]: 'Partialdruck mEthan CH4 Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_READING]: 'Partialdruck mEthan CH4 Istwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_SETPOINT]: 'Partialdruck mEthan CH4 percent Sollwert',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_READING]: 'Partialdruck mEthan CH4 percent Istwert',
  [ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_SETPOINT]: 'Hochvakuumdruck Sollwert',
  [ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_READING]: 'Hochvakuumdruck Istwert',
  [ETimeseriesMeaning.LOW_VACUUM_PRESSURE_SETPOINT]: 'Niedrigvakuumdruck Sollwert',
  [ETimeseriesMeaning.LOW_VACUUM_PRESSURE_READING]: 'Niedrigvakuumdruck Istwert',
  [ETimeseriesMeaning.USER_DEFINED_001]: 'Benutzerdefinierte Zeitreihe 001',
  [ETimeseriesMeaning.USER_DEFINED_002]: 'Benutzerdefinierte Zeitreihe 002',
  [ETimeseriesMeaning.USER_DEFINED_003]: 'Benutzerdefinierte Zeitreihe 003',
  [ETimeseriesMeaning.USER_DEFINED_004]: 'Benutzerdefinierte Zeitreihe 004',
  [ETimeseriesMeaning.USER_DEFINED_005]: 'Benutzerdefinierte Zeitreihe 005',
  [ETimeseriesMeaning.USER_DEFINED_006]: 'Benutzerdefinierte Zeitreihe 006',
  [ETimeseriesMeaning.USER_DEFINED_007]: 'Benutzerdefinierte Zeitreihe 007',
  [ETimeseriesMeaning.USER_DEFINED_008]: 'Benutzerdefinierte Zeitreihe 008',
  [ETimeseriesMeaning.USER_DEFINED_009]: 'Benutzerdefinierte Zeitreihe 009',
  [ETimeseriesMeaning.USER_DEFINED_010]: 'Benutzerdefinierte Zeitreihe 010',
  [ETimeseriesMeaning.USER_DEFINED_011]: 'Benutzerdefinierte Zeitreihe 011',
  [ETimeseriesMeaning.USER_DEFINED_012]: 'Benutzerdefinierte Zeitreihe 012',
  [ETimeseriesMeaning.USER_DEFINED_013]: 'Benutzerdefinierte Zeitreihe 013',
  [ETimeseriesMeaning.USER_DEFINED_014]: 'Benutzerdefinierte Zeitreihe 014',
  [ETimeseriesMeaning.USER_DEFINED_015]: 'Benutzerdefinierte Zeitreihe 015',
  [ETimeseriesMeaning.USER_DEFINED_016]: 'Benutzerdefinierte Zeitreihe 016',
  [ETimeseriesMeaning.USER_DEFINED_017]: 'Benutzerdefinierte Zeitreihe 017',
  [ETimeseriesMeaning.USER_DEFINED_018]: 'Benutzerdefinierte Zeitreihe 018',
  [ETimeseriesMeaning.USER_DEFINED_019]: 'Benutzerdefinierte Zeitreihe 019',
  [ETimeseriesMeaning.USER_DEFINED_020]: 'Benutzerdefinierte Zeitreihe 020',
  [ETimeseriesMeaning.USER_DEFINED_021]: 'Benutzerdefinierte Zeitreihe 021',
  [ETimeseriesMeaning.USER_DEFINED_022]: 'Benutzerdefinierte Zeitreihe 022',
  [ETimeseriesMeaning.USER_DEFINED_023]: 'Benutzerdefinierte Zeitreihe 023',
  [ETimeseriesMeaning.USER_DEFINED_024]: 'Benutzerdefinierte Zeitreihe 024',
  [ETimeseriesMeaning.USER_DEFINED_025]: 'Benutzerdefinierte Zeitreihe 025',
  [ETimeseriesMeaning.USER_DEFINED_026]: 'Benutzerdefinierte Zeitreihe 026',
  [ETimeseriesMeaning.USER_DEFINED_027]: 'Benutzerdefinierte Zeitreihe 027',
  [ETimeseriesMeaning.USER_DEFINED_028]: 'Benutzerdefinierte Zeitreihe 028',
  [ETimeseriesMeaning.USER_DEFINED_029]: 'Benutzerdefinierte Zeitreihe 029',
  [ETimeseriesMeaning.USER_DEFINED_030]: 'Benutzerdefinierte Zeitreihe 030',
  [ETimeseriesMeaning.USER_DEFINED_031]: 'Benutzerdefinierte Zeitreihe 031',
  [ETimeseriesMeaning.USER_DEFINED_032]: 'Benutzerdefinierte Zeitreihe 032',
  [ETimeseriesMeaning.USER_DEFINED_033]: 'Benutzerdefinierte Zeitreihe 033',
  [ETimeseriesMeaning.USER_DEFINED_034]: 'Benutzerdefinierte Zeitreihe 034',
  [ETimeseriesMeaning.USER_DEFINED_035]: 'Benutzerdefinierte Zeitreihe 035',
  [ETimeseriesMeaning.USER_DEFINED_036]: 'Benutzerdefinierte Zeitreihe 036',
  [ETimeseriesMeaning.USER_DEFINED_037]: 'Benutzerdefinierte Zeitreihe 037',
  [ETimeseriesMeaning.USER_DEFINED_038]: 'Benutzerdefinierte Zeitreihe 038',
  [ETimeseriesMeaning.USER_DEFINED_039]: 'Benutzerdefinierte Zeitreihe 039',
  [ETimeseriesMeaning.USER_DEFINED_040]: 'Benutzerdefinierte Zeitreihe 040',
  [ETimeseriesMeaning.USER_DEFINED_041]: 'Benutzerdefinierte Zeitreihe 041',
  [ETimeseriesMeaning.USER_DEFINED_042]: 'Benutzerdefinierte Zeitreihe 042',
  [ETimeseriesMeaning.USER_DEFINED_043]: 'Benutzerdefinierte Zeitreihe 043',
  [ETimeseriesMeaning.USER_DEFINED_044]: 'Benutzerdefinierte Zeitreihe 044',
  [ETimeseriesMeaning.USER_DEFINED_045]: 'Benutzerdefinierte Zeitreihe 045',
  [ETimeseriesMeaning.USER_DEFINED_046]: 'Benutzerdefinierte Zeitreihe 046',
  [ETimeseriesMeaning.USER_DEFINED_047]: 'Benutzerdefinierte Zeitreihe 047',
  [ETimeseriesMeaning.USER_DEFINED_048]: 'Benutzerdefinierte Zeitreihe 048',
  [ETimeseriesMeaning.USER_DEFINED_049]: 'Benutzerdefinierte Zeitreihe 049',
  [ETimeseriesMeaning.USER_DEFINED_050]: 'Benutzerdefinierte Zeitreihe 050',
  [ETimeseriesMeaning.USER_DEFINED_051]: 'Benutzerdefinierte Zeitreihe 051',
  [ETimeseriesMeaning.USER_DEFINED_052]: 'Benutzerdefinierte Zeitreihe 052',
  [ETimeseriesMeaning.USER_DEFINED_053]: 'Benutzerdefinierte Zeitreihe 053',
  [ETimeseriesMeaning.USER_DEFINED_054]: 'Benutzerdefinierte Zeitreihe 054',
  [ETimeseriesMeaning.USER_DEFINED_055]: 'Benutzerdefinierte Zeitreihe 055',
  [ETimeseriesMeaning.USER_DEFINED_056]: 'Benutzerdefinierte Zeitreihe 056',
  [ETimeseriesMeaning.USER_DEFINED_057]: 'Benutzerdefinierte Zeitreihe 057',
  [ETimeseriesMeaning.USER_DEFINED_058]: 'Benutzerdefinierte Zeitreihe 058',
  [ETimeseriesMeaning.USER_DEFINED_059]: 'Benutzerdefinierte Zeitreihe 059',
  [ETimeseriesMeaning.USER_DEFINED_060]: 'Benutzerdefinierte Zeitreihe 060',
  [ETimeseriesMeaning.USER_DEFINED_061]: 'Benutzerdefinierte Zeitreihe 061',
  [ETimeseriesMeaning.USER_DEFINED_062]: 'Benutzerdefinierte Zeitreihe 062',
  [ETimeseriesMeaning.USER_DEFINED_063]: 'Benutzerdefinierte Zeitreihe 063',
  [ETimeseriesMeaning.USER_DEFINED_064]: 'Benutzerdefinierte Zeitreihe 064',
  [ETimeseriesMeaning.USER_DEFINED_065]: 'Benutzerdefinierte Zeitreihe 065',
  [ETimeseriesMeaning.USER_DEFINED_066]: 'Benutzerdefinierte Zeitreihe 066',
  [ETimeseriesMeaning.USER_DEFINED_067]: 'Benutzerdefinierte Zeitreihe 067',
  [ETimeseriesMeaning.USER_DEFINED_068]: 'Benutzerdefinierte Zeitreihe 068',
  [ETimeseriesMeaning.USER_DEFINED_069]: 'Benutzerdefinierte Zeitreihe 069',
  [ETimeseriesMeaning.USER_DEFINED_070]: 'Benutzerdefinierte Zeitreihe 070',
  [ETimeseriesMeaning.USER_DEFINED_071]: 'Benutzerdefinierte Zeitreihe 071',
  [ETimeseriesMeaning.USER_DEFINED_072]: 'Benutzerdefinierte Zeitreihe 072',
  [ETimeseriesMeaning.USER_DEFINED_073]: 'Benutzerdefinierte Zeitreihe 073',
  [ETimeseriesMeaning.USER_DEFINED_074]: 'Benutzerdefinierte Zeitreihe 074',
  [ETimeseriesMeaning.USER_DEFINED_075]: 'Benutzerdefinierte Zeitreihe 075',
  [ETimeseriesMeaning.USER_DEFINED_076]: 'Benutzerdefinierte Zeitreihe 076',
  [ETimeseriesMeaning.USER_DEFINED_077]: 'Benutzerdefinierte Zeitreihe 077',
  [ETimeseriesMeaning.USER_DEFINED_078]: 'Benutzerdefinierte Zeitreihe 078',
  [ETimeseriesMeaning.USER_DEFINED_079]: 'Benutzerdefinierte Zeitreihe 079',
  [ETimeseriesMeaning.USER_DEFINED_080]: 'Benutzerdefinierte Zeitreihe 080',
  [ETimeseriesMeaning.USER_DEFINED_081]: 'Benutzerdefinierte Zeitreihe 081',
  [ETimeseriesMeaning.USER_DEFINED_082]: 'Benutzerdefinierte Zeitreihe 082',
  [ETimeseriesMeaning.USER_DEFINED_083]: 'Benutzerdefinierte Zeitreihe 083',
  [ETimeseriesMeaning.USER_DEFINED_084]: 'Benutzerdefinierte Zeitreihe 084',
  [ETimeseriesMeaning.USER_DEFINED_085]: 'Benutzerdefinierte Zeitreihe 085',
  [ETimeseriesMeaning.USER_DEFINED_086]: 'Benutzerdefinierte Zeitreihe 086',
  [ETimeseriesMeaning.USER_DEFINED_087]: 'Benutzerdefinierte Zeitreihe 087',
  [ETimeseriesMeaning.USER_DEFINED_088]: 'Benutzerdefinierte Zeitreihe 088',
  [ETimeseriesMeaning.USER_DEFINED_089]: 'Benutzerdefinierte Zeitreihe 089',
  [ETimeseriesMeaning.USER_DEFINED_090]: 'Benutzerdefinierte Zeitreihe 090',
  [ETimeseriesMeaning.USER_DEFINED_091]: 'Benutzerdefinierte Zeitreihe 091',
  [ETimeseriesMeaning.USER_DEFINED_092]: 'Benutzerdefinierte Zeitreihe 092',
  [ETimeseriesMeaning.USER_DEFINED_093]: 'Benutzerdefinierte Zeitreihe 093',
  [ETimeseriesMeaning.USER_DEFINED_094]: 'Benutzerdefinierte Zeitreihe 094',
  [ETimeseriesMeaning.USER_DEFINED_095]: 'Benutzerdefinierte Zeitreihe 095',
  [ETimeseriesMeaning.USER_DEFINED_096]: 'Benutzerdefinierte Zeitreihe 096',
  [ETimeseriesMeaning.USER_DEFINED_097]: 'Benutzerdefinierte Zeitreihe 097',
  [ETimeseriesMeaning.USER_DEFINED_098]: 'Benutzerdefinierte Zeitreihe 098',
  [ETimeseriesMeaning.USER_DEFINED_099]: 'Benutzerdefinierte Zeitreihe 099',
  [ETimeseriesMeaning.USER_DEFINED_100]: 'Benutzerdefinierte Zeitreihe 100',
};

const PhysicalDimension: Record<EPhysicalDimension, string> = {
  [EPhysicalDimension.ACCELERATION]: 'Beschleunigung',
  [EPhysicalDimension.ADMITTANCE]: 'Admittanz',
  [EPhysicalDimension.AMOUNT]: 'Betrag',
  [EPhysicalDimension.ANGLE]: 'Winkel',
  [EPhysicalDimension.ANGULAR_ACCELERATION]: 'Winkelbeschleunigung',
  [EPhysicalDimension.ANGULAR_MOMENTUM]: 'Drehimpuls',
  [EPhysicalDimension.ANGULAR_VELOCITY]: 'Winkelgeschwindigkeit',
  [EPhysicalDimension.APPARENT_ELECTRICAL_POWER]: 'Elektrische Leistung',
  [EPhysicalDimension.AREA]: 'Fläche',
  [EPhysicalDimension.CAPACITANCE]: 'Kapazität',
  [EPhysicalDimension.COOLING_RATE]: 'Abkühlungsrate',
  [EPhysicalDimension.CURRENCY]: 'Währung',
  [EPhysicalDimension.DATETIME]: 'Zeitpunkt',
  [EPhysicalDimension.DATE]: 'Datum',
  [EPhysicalDimension.DENSITY]: 'Dichte',
  [EPhysicalDimension.DURATION]: 'Dauer',
  [EPhysicalDimension.ELECTRICAL_REACTIVE_POWER]: 'Elektrische Blindleistung',
  [EPhysicalDimension.ELECTRICAL_RESISTANCE]: 'Elektrischer Widerstand',
  [EPhysicalDimension.ELECTRIC_CHARGE]: 'Elektrische Ladung',
  [EPhysicalDimension.ELECTRIC_CURRENT]: 'Elektrischer Strom',
  [EPhysicalDimension.ENERGY]: 'Energie',
  [EPhysicalDimension.FLOW]: 'Durchfluss',
  [EPhysicalDimension.FLOW_PER_AREA]: 'Durchfluss pro Fläche',
  [EPhysicalDimension.FORCE]: 'Kraft',
  [EPhysicalDimension.FREQUENCY]: 'Frequenz',
  [EPhysicalDimension.HARDNESS]: 'Härte',
  [EPhysicalDimension.HEAT_FLUX]: 'Wärmestrom',
  [EPhysicalDimension.IDENTITY]: 'Identität',
  [EPhysicalDimension.INDUCTIVITY]: 'Induktivität',
  [EPhysicalDimension.INTERVAL]: 'Intervall',
  [EPhysicalDimension.LENGTH]: 'Länge',
  [EPhysicalDimension.MAGNETIC_FLUX]: 'Magnetischer Fluss',
  [EPhysicalDimension.MASS]: 'Masse',
  [EPhysicalDimension.MASS_FLOW_RATE]: 'Massendurchsatz',
  [EPhysicalDimension.MASS_FRACTION]: 'Massenanteil',
  [EPhysicalDimension.MODULUS_OF_ELASTICITY]: 'Elastizitätsmodul',
  [EPhysicalDimension.MOMENTUM]: 'Momentum',
  [EPhysicalDimension.MOMENT_OF_INERTIA]: 'Trägheitsmoment',
  [EPhysicalDimension.POWER]: 'Leistung',
  [EPhysicalDimension.POWER_DENSITY]: 'Leistungsdichte',
  [EPhysicalDimension.PRESSURE]: 'Druck',
  [EPhysicalDimension.PRESSURE_RATE]: 'Druckrate',
  [EPhysicalDimension.QUANTITY]: 'Menge',
  [EPhysicalDimension.RATIO]: 'Verhältnis',
  [EPhysicalDimension.ROTATIONAL_VELOCITY]: 'Rotationsgeschwindigkeit',
  [EPhysicalDimension.SOUND_PRESSURE]: 'Schalldruck',
  [EPhysicalDimension.STRESS]: 'Spannung',
  [EPhysicalDimension.SURFACE_TENSION]: 'Oberflächenspannung',
  [EPhysicalDimension.TEMPERATURE]: 'Temperatur',
  [EPhysicalDimension.THERMAL_EXPANSION]: 'Thermische Ausdehnung',
  [EPhysicalDimension.THERMAL_RESISTANCE]: 'Thermischer Widerstand',
  [EPhysicalDimension.TIME]: 'Zeit',
  [EPhysicalDimension.TORQUE]: 'Drehmoment',
  [EPhysicalDimension.VELOCITY]: 'Geschwindigkeit',
  [EPhysicalDimension.VISCOSITY]: 'Viskosität',
  [EPhysicalDimension.VOLTAGE]: 'Spannung',
  [EPhysicalDimension.VOLUME]: 'Volumen',
  [EPhysicalDimension.WORK]: 'Arbeit',
};

const CalendarEventType: Record<ECalendarEventType, string> = {
  [ECalendarEventType.ACTUAL_UNIT_DOWNTIME]: 'Tatsächliche Ausfallzeit',
  [ECalendarEventType.PLANNED_UNIT_DOWNTIME]: 'Geplante Ausfallzeit',
};

const HierarchyLabel: Record<EHierarchy, string> = {
  [EHierarchy.CHAMBER]: generalTerm.chamber,
  [EHierarchy.COMPANY]: generalTerm.company,
  [EHierarchy.COMPONENT]: generalTerm.component,
  [EHierarchy.EQUIPMENT]: generalTerm.equipment,
  [EHierarchy.EQUIPMENT_GROUP]: generalTerm.equipmentGroup,
  [EHierarchy.LOCATION]: generalTerm.location,
  [EHierarchy.ROOT]: generalTerm.root,
};

const SimulationStep: Record<ESimulationStep, string> = {
  [ESimulationStep.RUNTIME_SECONDS]: 'Laufzeit in Sekunden',
  [ESimulationStep.STAGE_INDEX]: 'Stufenindex',
  [ESimulationStep.STAGE_RUNTIME_SECONDS]: 'Stufenlaufzeit in Sekunden',
  [ESimulationStep.TEMPERATURE]: 'Temperatur',
  [ESimulationStep.CARBON_POTENTIAL]: 'Kohlenstoffpotential',
  [ESimulationStep.NITROGEN_POTENTIAL]: 'Stickstoffpotential',
  [ESimulationStep.CARBURIZING_DEPTH]: 'Eindringtiefe der Aufkohlung',
  [ESimulationStep.CARBURIZING_DEPTH_SET_POINT]: 'Festgelegte Eindringtiefe der Aufkohlung',
  [ESimulationStep.CARBURIZING_DEPTH_CARBON_CONTENT]: 'Kohlenstoffgehalt bei der Eindringtiefe',
  [ESimulationStep.SURFACE_CARBON]: 'Oberflächenkohlenstoff',
  [ESimulationStep.SURFACE_NITROGEN]: 'Oberflächenstickstoff',
  [ESimulationStep.SURFACE_CARBIDE]: 'Oberflächenkarbid',
  [ESimulationStep.SURFACE_HARDNESS_SETPOINT]: 'Festgelegte Oberflächenhärte',
  [ESimulationStep.EFFECTIVE_CASE_HARDNESS]: 'Effektive Randschichthärte',
  [ESimulationStep.CARBIDE_LIMIT]: 'Karbidgrenze',
  [ESimulationStep.SOOT_LIMIT]: 'Rußgrenze',
  [ESimulationStep.CASE_DEPTH]: 'Randschichttiefe',
  [ESimulationStep.CARBON_PROFILE]: 'Kohlenstoffprofil durch die Tiefe',
  [ESimulationStep.CARBON_PROFILE_MIN]: 'Minimaler Kohlenstoffprofilwert',
  [ESimulationStep.CARBON_PROFILE_MAX]: 'Maximaler Kohlenstoffprofilwert',
  [ESimulationStep.NITROGEN_PROFILE]: 'Stickstoffprofil durch die Tiefe',
  [ESimulationStep.NITROGEN_PROFILE_MIN]: 'Minimaler Stickstoffprofilwert',
  [ESimulationStep.NITROGEN_PROFILE_MAX]: 'Maximaler Stickstoffprofilwert',
  [ESimulationStep.CARBIDE_PROFILE]: 'Karbidprofil durch die Tiefe',
  [ESimulationStep.CARBIDE_PROFILE_MIN]: 'Minimaler Karbidprofilwert',
  [ESimulationStep.CARBIDE_PROFILE_MAX]: 'Maximaler Karbidprofilwert',
  [ESimulationStep.HARDNESS_PROFILE]: 'Härteprofil durch die Tiefe',
  [ESimulationStep.HARDNESS_PROFILE_MIN]: 'Minimaler Härteprofilwert',
  [ESimulationStep.HARDNESS_PROFILE_MAX]: 'Maximaler Härteprofilwert',
  [ESimulationStep.N_SOLUBILITY_PROFILE]: 'Aktuelle Nitridgrenze',
  [ESimulationStep.NITRIDE_PROFILE]: 'Nitridprofil durch die Tiefe',
  [ESimulationStep.NITRIDE_PROFILE_MIN]: 'Minimaler Nitridprofilwert',
  [ESimulationStep.NITRIDE_PROFILE_MAX]: 'Maximaler Nitridprofilwert',
  [ESimulationStep.PROFILE_X_VALUES]: 'Werte entlang des Profils von außen nach innen in mm',
};

const Asset: Partial<TComponentKeys<'Asset'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
};

const Visualisation: Record<EVisualisation, string> = {
  [EVisualisation.CLUSTERED_STACKED_BAR_CHART]: 'Gestapeltes Balkendiagramm gruppieren',
  [EVisualisation.PARETO_CHART]: 'Pareto-Diagramm',
  [EVisualisation.STACKED_BAR_CHART]: 'Gestapeltes Balkendiagramm',
  [EVisualisation.STACKED_POLAR_CHART]: 'Gestapeltes Polardiagramm',
  [EVisualisation.TABLE]: 'Tabelle',
};

const AssetProfileStatus: Record<EAssetProfileStatus, string> = {
  [EAssetProfileStatus.COMPLETE]: 'Vollständig',
  [EAssetProfileStatus.INCOMPLETE]: 'Unvollständig',
  [EAssetProfileStatus.INVALID]: 'Ungültig',
};

const AssetProfileCombinator: Record<EAssetProfileCombinator, string> = {
  [EAssetProfileCombinator.ANY_OF]: 'Beliebige',
  [EAssetProfileCombinator.ALL_OF]: 'Alle',
  [EAssetProfileCombinator.ONE_OF]: 'Einer',
};

const ObservationType: Record<EObservationType, string> = {
  [EObservationType.LOWER_CONTROL_LIMIT_EXCEEDED]: 'Untere Kontrollgrenze unterschritten',
  [EObservationType.UPPER_CONTROL_LIMIT_EXCEEDED]: 'Obere Kontrollgrenze überschritten',
  [EObservationType.NEGATIVE_TREND_DETECTED]: 'Negativer Trend erkannt',
  [EObservationType.POSITIVE_TREND_DETECTED]: 'Positiver Trend erkannt',
  [EObservationType.LOWER_CUSTOM_LIMIT_EXCEEDED]: 'Untere benutzerdefinierte Grenze überschritten',
  [EObservationType.UPPER_CUSTOM_LIMIT_EXCEEDED]: 'Obere benutzerdefinierte Grenze überschritten',
};

const ChamberType: Record<EChamberType, string> = {
  [EChamberType.HOT]: 'Heiß',
  [EChamberType.ISO_THERMAL]: 'Isothermal',
  [EChamberType.QUENCH]: 'Quenching',
  [EChamberType.VACUM]: 'Vacuum',
};

const HeatTreatmentProcessType: Record<EHeatTreatmentProcessType, string> = {
  [EHeatTreatmentProcessType.ANNEALING]: 'Glühen',
  [EHeatTreatmentProcessType.QUENCHING]: 'Abschrecken',
  [EHeatTreatmentProcessType.NEUTRAL_HARDENING]: 'Neutralglühen',
  [EHeatTreatmentProcessType.CRYOGENIC_TREATMENT]: 'Kryogene Behandlung',
  [EHeatTreatmentProcessType.TEMPERING]: 'Anlassen',
  [EHeatTreatmentProcessType.AGING]: 'Altern',
  [EHeatTreatmentProcessType.CARBURIZING]: 'Vergüten',
  [EHeatTreatmentProcessType.CARBONITRIDING]: 'Carbonitrieren',
  [EHeatTreatmentProcessType.NITRIDING]: 'Nitrieren',
  [EHeatTreatmentProcessType.NITROCARBURIZING]: 'Nitrocarburieren',
  [EHeatTreatmentProcessType.PRE_OXIDATION]: 'Voroxidation',
  [EHeatTreatmentProcessType.POST_OXIDATION]: 'Nachoxidation',
  [EHeatTreatmentProcessType.ACTIVATION]: 'Aktivierung',
};

const AppGroupSemantic: Record<EAppGroupSemantic, string> = {
  [EAppGroupSemantic.OPERATION]: 'Betrieb',
  [EAppGroupSemantic.DATA]: 'Data/Insight',
  [EAppGroupSemantic.MAINTENANCE]: 'Wartung',
  [EAppGroupSemantic.PROCESS]: 'Prozess',
  [EAppGroupSemantic.CONFIGURATION]: 'Konfiguration',
};

const AppId: Record<EAppIdMap, string> = {
  [EAppIdMap.ALARM_STATISTICS]: 'Alarmstatistik',
  [EAppIdMap.ANOMALY_DETECTION]: 'Anomalie-Erkennung',
  [EAppIdMap.ASSET_MANAGEMENT]: 'Asset Management',
  [EAppIdMap.CERTIFICATION]: 'Audit & Quality',
  [EAppIdMap.CUSTOMER]: 'Kundendatenbank',
  [EAppIdMap.DEPRECATED_MODEL_PROCESS]: 'Modelprozesse',
  [EAppIdMap.DEPRECATED_NITREX_LIVE]: 'Nitrex Connect',
  [EAppIdMap.DEPRECATED_PT9800_RECIPES]: 'Rezepte (PT9800)',
  [EAppIdMap.DEPRECATED_PT_9800_CLOUD]: 'PT9800 Webclient',
  [EAppIdMap.DEPRECATED_RECIPE_NPM]: 'Rezepte (Nitride)',
  [EAppIdMap.DEPRECATED_USER_SETTINGS]: 'Einstellungen',
  [EAppIdMap.GRAFANA]: 'Grafana',
  [EAppIdMap.HISTORY_AND_TRENDS]: 'History and Trends',
  [EAppIdMap.LIVE]: 'Live app',
  [EAppIdMap.MAINTENANCE_CALENDAR]: 'Wartungskalender',
  [EAppIdMap.MAINTENANCE_DASHBOARD]: 'Wartungsdashboard',
  [EAppIdMap.MATERIALS]: 'Materialdatenbank',
  [EAppIdMap.ML_PROTOTYPING]: 'ML Prototyping',
  [EAppIdMap.OPERATION_CALENDAR]: 'Produktionskalender',
  [EAppIdMap.PARTS]: 'Parts',
  [EAppIdMap.PERFORMANCE_DASHBOARD]: 'Auslastungsübersicht',
  [EAppIdMap.PRODUCTION_LOGS]: 'Produktionshistorie',
  [EAppIdMap.PRODUCTION_STATISTICS]: 'Produktionsstatistik',
  [EAppIdMap.PT9800_CONFIGURATION]: 'PT9800 Konfiguration',
  [EAppIdMap.RECIPE]: 'Rezepte',
  [EAppIdMap.RECIPE_ON_DEMAND]: 'Rezeptsuche',
  [EAppIdMap.REMOTE_CONTROL]: 'Fernsteuerung',
  [EAppIdMap.SHOP_LAYOUT]: 'Hallen-Layout',
  [EAppIdMap.SIMULATOR]: 'Simulatoren',
  [EAppIdMap.TENANT_SETTINGS]: 'Einstellungen',
  [EAppIdMap.UPC_PRO]: 'UPC Werkzeugkasten',
  [EAppIdMap.USER_MANAGEMENT]: 'Benutzerverwaltung',
};

const CarburizingSimulationPartShape: Record<ECarburizingSimulationPartShape, string> = {
  [ECarburizingSimulationPartShape.ROUND_STEEL]: 'Rundstahl',
  [ECarburizingSimulationPartShape.HEXAGON_OR_OCTAGON_STEEL]: 'Sechseck- oder Achteckstahl',
  [ECarburizingSimulationPartShape.SQUARE_OR_FLAT_STEEL]: 'Quadrat- oder Flachstahl',
  [ECarburizingSimulationPartShape.SOLID_STEEL]: 'Vollstahl (Scheibeninnendurchmesser < 200 mm)',
  [ECarburizingSimulationPartShape.RING]: 'Ring (Scheibeninnendurchmesser > 200 mm)',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_80]: 'Zyl. Hohlkörper Innendurchmesser < 80 mm',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_200]: 'Zyl. Hohlkörper Innendurchmesser < 200 mm',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_GREATER_200]: 'Zyl. Hohlkörper Innendurchmesser > 200 mm',
  [ECarburizingSimulationPartShape.HOLLOW_BODY_CLOSED]: 'Hohlkörper, geschlossen von einer oder zwei Seiten',
};

const CarburizingSimulationPartShapeSize: Record<ECarburizingSimulationPartShape, string> = {
  [ECarburizingSimulationPartShape.ROUND_STEEL]: 'Nenndurchmesser',
  [ECarburizingSimulationPartShape.HEXAGON_OR_OCTAGON_STEEL]: 'Nennabstand zwischen gegenüberl. Seiten',
  [ECarburizingSimulationPartShape.SQUARE_OR_FLAT_STEEL]: 'Kleinste Kantenlänge',
  [ECarburizingSimulationPartShape.SOLID_STEEL]: 'Kleinste Kantenlänge',
  [ECarburizingSimulationPartShape.RING]: 'Kleinste Kantenlänge',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_80]: 'Wandstärke',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_200]: 'Wandstärke',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_GREATER_200]: 'Wandstärke',
  [ECarburizingSimulationPartShape.HOLLOW_BODY_CLOSED]: 'Wandstärke',
};

const CarburizingEndControlType: Record<ECarburizingEndControlType, string> = {
  [ECarburizingEndControlType.END_TEMPERATURE]: 'Endtemperatur',
  [ECarburizingEndControlType.CARBON_DEPTH]: 'Aufkohlungstiefe',
  [ECarburizingEndControlType.RUNTIME]: 'Laufzeit',
  [ECarburizingEndControlType.SURFACE_CARBON]: 'Rand C %',
  [ECarburizingEndControlType.CP_PERCENT]: 'C Potenzial %',
};

const CarburizingSimulatorRecipeType: Record<ECarburizingSimulatorRecipeType, string> = {
  [ECarburizingSimulatorRecipeType.CARBURIZING]: 'Aufkohlen [C]',
  [ECarburizingSimulatorRecipeType.CARBONITRIDING]: 'Carbonitrieren [CN]',
  [ECarburizingSimulatorRecipeType.LOW_PRESSURE_CARBURIZING]: 'Niederdruckaufkohlen [LPC]',
};

const PartMaterialProperties: TComponentKeys<'PartMaterialProperties'> = {
  surfaceCarbon: 'Oberflächenkohle [%]',
  referenceCarbon: 'Referenzkohle [%]',
  alloyFactorCAustenite: 'Legierungsfaktor [Carbon]',
  carbideLimit: 'Carbidgrenze [%aC=1]',
  alloyFactorNAustenite: 'Legierungsfaktor [Nitrogen]',
  nitrideLimit: 'Nitridgrenze [%FexNy]',
  coreHardness: 'Kernhärte (unbehandelt) (HV)',
};

const SimulatorRecipe: Record<
  keyof components['schemas']['CarburizingRecipe']
  | keyof components['schemas']['CarbonitridingRecipe']
  | keyof components['schemas']['LowPressureCarburizingRecipe'],
  string> = {
    c2H2Content: 'C2H2',
    c3H8Content: 'C3H8',
    coContent: 'CO',
    h2Content: 'H2',
    description: generalTerm.description, // Beschreibung
    displayName: generalTerm.name, // Anzeigename
    isGasValuesConstant: '',
    recipeType: 'Wärmebehandlungsart',
    simulationValuesType: 'Art',
    simulatorCarburizingRecipeId: '',
    simulatorPartId: '',
    stages: '',
    userDefinedAlloyFactor: 'Legierungsfaktor C',
    userDefinedCarbideLimit: 'Carbidgrenze',
    userDefinedCarbonContent: 'Kernkohlenstoffgehalt',
    userDefinedCarburizingDepth: 'Aufkohlungstiefe',
    userDefinedReferenceCarbon: 'Grenzkohlenstoffgehalt',
  };

const NotificationTable: Record<ESubscriptionTreeLabel, Record<'event' | 'description', string>> = {
  [ESubscriptionTreeLabel.ALARMS]: {
    event: 'Alarme',
    description: 'Erhalte eine Benachrichtigung sobald ein Alarmereignis beliebiger schwere auftritt.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_ALARM_OCCURRED]: {
    event: 'Alarm',
    description: 'Benachrichtigung bei Alarmereignissen',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_EMERGENCY_OCCURRED]: {
    event: 'Notfall',
    description: 'Benachrichtigung bei Notfallereignissen. Notfallereignisse werden nicht von allen Controller typen unterstützt. Notfallereignisse sorgen dafür das der jeweilge Job sofort ab- oder unterbrochen wird und evtl. ein Eingreifen eines Menschen benötigen.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_WARNING_OCCURRED]: {
    event: 'Warnung',
    description: 'Benachrichtigung bei Warnungsereignissen. Warnungen werden nicht von allen Controller typen unterstützt.',
  },
  [ESubscriptionTreeLabel.JOBS]: {
    event: 'Job',
    description: 'Erhalte eine Benachrichtigung wenn immer sich der status eines Jobs ändert.',
  },
  [ESubscriptionTreeLabel.JOB_FINISHED]: {
    event: 'Job beendet',
    description: 'Ein Job wurde beendet.',
  },
  [ESubscriptionTreeLabel.JOB_STARTED]: {
    event: 'Job gestartet',
    description: 'Ein Job wurde gestartet.',
  },
  [ESubscriptionTreeLabel.TIMESERIES_LAG_DETECTED]: {
    event: 'Zeitreihen nicht mehr aktuell',
    description: 'Mindestens eine Zeitreihe eines Ofen hängt mehr als 30 Minuten hinterher.',
  },
  [ESubscriptionTreeLabel.NEW_QMULUS_RELEASE]: {
    event: 'Neue Qmulus Version',
    description: 'Eine neue Version von Qmulus wurde veröffentlicht.',
  },
};

const SubscriptionTreeNode: Partial<TComponentKeys<'SubscriptionTreeNode'>> = {
  notificationMetadataId: generalTerm.description,
  label: generalTerm.event,
  children: '',
  subscriptions: '',
  isLeaf: '',
};

const CarburizingRecipeSimulationValuesType: Record<ECarburizingRecipeSimulationValuesType, string> = {
  [ECarburizingRecipeSimulationValuesType.AVG_VAL]: 'Durchschnitt',
  [ECarburizingRecipeSimulationValuesType.COMP_VAL]: 'Zusammensetzung',
  [ECarburizingRecipeSimulationValuesType.USER_VAL]: 'Benutzerdefiniert',
};

const AppGroupCommercial: Record<EAppGroupCommercial, string> = {
  [EAppGroupCommercial.QMULUS_ESSENTIAL]: 'QMULUS Essential',
  [EAppGroupCommercial.QMULUS_LITE]: 'QMULUS Lite',
  [EAppGroupCommercial.SETTINGS]: 'Settings',
  [EAppGroupCommercial.QMULUS_EXPERT]: 'QMULUS Expert',
  [EAppGroupCommercial.SIMULATORS]: 'Simulators',
  [EAppGroupCommercial.QMULUS_OPERATIONS]: 'QMULUS Operations',
  [EAppGroupCommercial.QMULUS_PERFORMANCE]: 'QMULUS Performance',
  [EAppGroupCommercial.QMULUS_AI]: 'QMULUS Ai',
  [EAppGroupCommercial.QMULUS_ANALYTICS]: 'QMULUS Analytics',
  [EAppGroupCommercial.QMULUS_AUDIT]: 'QMULUS Audit',
  [EAppGroupCommercial.QMULUS_QUALITY]: 'QMULUS Quality',
  [EAppGroupCommercial.QMULUS_PLANNER]: 'QMULUS Planner',
  [EAppGroupCommercial.QMULUS_TOOLING_AND_GAUGE_MANAGER]: 'QMULUS Tooling and Gauge Manager',
  [EAppGroupCommercial.QMULUS_AUTOMATION]: 'QMULUS Automation',
};

const DataFormat: Record<EDataFormat, string> = {
  [EDataFormat.TIME_SERIES]: 'Zeitreihe',
  [EDataFormat.JOB_AGGREGATE]: 'Jobwert',
  [EDataFormat.EVENT]: 'Event',
  [EDataFormat.ALARM_EVENT]: 'Alarm',
  [EDataFormat.BLOB]: 'Binary Large Object (BLOB)',
};

const CarburizingTemperatureControlType: Record<ECarburizingTemperatureControlType, string> = {
  [ECarburizingTemperatureControlType.HEAT]: 'Heizen',
  [ECarburizingTemperatureControlType.COOL]: 'Kühlen',
  [ECarburizingTemperatureControlType.HEAT_UP]: 'Aufheizen',
  [ECarburizingTemperatureControlType.HEAT_DOWN]: 'Abkühlen',
  [ECarburizingTemperatureControlType.HOLD]: 'Halten',
};

const CarburizingVolumeControlType: Record<ECarburizingVolumeControlType, string> = {
  [ECarburizingVolumeControlType.OFF]: 'Aus',
  [ECarburizingVolumeControlType.ON]: 'Ein',
  [ECarburizingVolumeControlType.AUTO]: 'Auto',
};

const CarburizingCarbonControlType: Record<ECarburizingCarbonControlType, string> = {
  [ECarburizingCarbonControlType.OFF]: 'Aus',
  [ECarburizingCarbonControlType.ON]: 'Ein',
  [ECarburizingCarbonControlType.SOOT_LIMIT]: 'Rußgrenze %',
  [ECarburizingCarbonControlType.AUTO_100_X]: 'Auto (100-x) %',
  [ECarburizingCarbonControlType.SURFACE_CARBON]: 'Rand C %',
  [ECarburizingCarbonControlType.PLUS_C]: 'Plus C %',
  [ECarburizingCarbonControlType.MINUS_C]: 'Minus C %',
};

const CarburizingEndControlTypeStrings: Record<ECarburizingEndControlType, string> = {
  [ECarburizingEndControlType.END_TEMPERATURE]: 'Endtemperatur',
  [ECarburizingEndControlType.CARBON_DEPTH]: 'Aufkohlungstiefe',
  [ECarburizingEndControlType.RUNTIME]: 'Laufzeit',
  [ECarburizingEndControlType.SURFACE_CARBON]: 'Rand C %',
  [ECarburizingEndControlType.CP_PERCENT]: 'C Potenzial %',
};

const CarburizingNitrogenControlType: Record<ECarburizingNitrogenControlType, string> = {
  [ECarburizingNitrogenControlType.OFF]: 'Aus',
  [ECarburizingNitrogenControlType.ON]: 'Ein',
  [ECarburizingNitrogenControlType.NITRIDE_100_X]: 'Nitride (100-x)%',
  [ECarburizingNitrogenControlType.SURF_N]: 'Rand N %',
};

const ObservationTypeHeader: Record<string, string> = {
  name: 'Name des Beobachtungstyps',
};

const LegacyEquipmentResponse: TComponentLegacyKeys<'EquipmentResponse'> = {
  customerName: generalTerm.customer,
  department: 'Abteilung',
  equipmentId: generalTerm.assetId,
  imageURL: 'Bild',
  location: generalTerm.location,
  locationId: generalTerm.locationId,
  modelName: generalTerm.assetType,
  name: generalTerm.asset,
  scadaType: generalTerm.scadaType,
  shortDescription: 'Beschreibung',
  status: 'Status',
  systemId: 'System id',
};

const MaintenanceJob: TComponentLegacyKeys<'MaintenanceJob'> = {
  description: 'Beschreibung',
  displayName: 'Wartungsjobname',
  durationOverheadMinutes: 'Gesamtdauer',
  id: 'Id',
  tasks: 'Aufgaben',
};

const MaintenanceTask: TComponentLegacyKeys<'MaintenanceTask'> = {
  description: 'Beschreibung',
  displayName: 'Aufgabenname',
  durationMinutes: 'Dauer',
  id: 'Id',
};

const AssetUtilization: Partial<TComponentKeys<'AssetUtilization'>> = {
  assetDisplayName: generalTerm.asset,
  noProductionSeconds: 'Keine Produktion',
  actualProductionTimeSeconds: 'Produktive Zeit',
  actualUnitDowntimeSeconds: 'Tatsächliche Stillstandszeit',
  plannedDowntimeSeconds: 'Geplante Stillstandszeit',
  noProductionPercentage: 'Keine Produktion',
  actualProductionTimePercentage: 'Produktive Zeit',
  actualUnitDowntimePercentage: 'Tatsächliche Stillstandszeit',
  plannedDowntimePercentage: 'Geplante Stillstandszeit',
  effectiveTargetProductivityPercentage: 'Zielproduktivität',
};

const AuditSpecificationResultOverview: TComponentKeys<'AuditSpecificationResultOverview'> = {
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  auditResultId: 'Report No',
  auditedAtUtc: generalTerm.date,
  auditedByUserName: 'Auditor',
  jobName: generalTerm.jobDisplayName,
  recipeDisplayName: generalTerm.recipe,
  jobId: '',
};

const AuditEnvelopeNonPassedReasons: Record<components['schemas']['AuditEnvelopeNonPassedReasons'], string> = {
  no_start_point_found: 'Kein Startpunkt gefunden',
  values_out_of_band: 'Werte außerhalb des Bands',
  max_duration_exceeded: 'Maximale Dauer überschritten',
  min_duration_not_reached: 'Minimale Dauer nicht erreicht',
  job_is_running: 'Job ist nicht beendet',
  meanings_missing: 'Keine Signale mit Bedeutung vorhanden fuer das Asset.',
  no_data: 'Keine Daten',
  data_ended_unexpected: 'Daten endeten unerwartet',
};

const Part: Partial<TComponentKeys<'Part'>> = {
  displayName: 'Teil',
  description: 'Teil Beschreibung',
  materialDisplayName: 'Material',
  treatmentRemarks: 'Hinweis zur Behandlung',
  customerDisplayName: 'Kunde',
  drawingReference: 'Zeichnung',
  lastModifiedAtUtc: 'Geändert am',
  lastModifiedByUserDisplayName: 'Geändert von',
};

const Customer: Partial<TComponentKeys<'Customer'>> = {
  displayName: 'Unternehmen',
  email: 'E-Mail',
  phone: 'Telefon',
  cellphone: 'Mobiltelefon',
  fax: 'Fax',
};

const Material: Partial<TComponentKeys<'Material-Output'>> = {
  displayName: 'Materialname',
  materialComposition: 'Materialzusammensetzung',
  materialGroups: 'Materialgruppen',
  materialNames: 'Materialnamen',
  materialId: 'Material-ID',
};

const MaterialNamingSystem: Partial<TComponentKeys<'MaterialNamingSystem'>> = {
  displayName: 'Name',
};

const ChartAssessmentTestRequirementType: Record<components['schemas']['ChartAssessmentTestRequirementType'], string> = {
  setpoints_greater_than_measures: 'Messwerte < Sollwerte',
  setpoints_greater_than_or_equal_to_measures: 'Messwerte ≤ Sollwerte',
  setpoints_less_than_measures: 'Messwerte > Sollwerte',
  setpoints_less_than_or_equal_to_measures: 'Messwerte ≥ Sollwerte',
};

const User: Partial<TComponentKeys<'User'>> = {
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'Email',
  rolesDisplayName: 'Rollen',
};

const OpenAPIPrimitiveType: Record<components['schemas']['OpenAPIPrimitiveType'], string> = {
  boolean: 'Boolean',
  integer: 'Ganzzahl',
  number: 'Fließkommazahl',
  string: 'Text',
};

const ProductionStatistics: Partial<TComponentKeys<'ProductionStatistics'>> = {
  displayName: generalTerm.name,
  earliestJobStartedAtUtc: 'Frühester Job Startzeitpunkt',
  latestJobFinishedAtUtc: 'Spätester Job Endzeitpunkt',
  jobCnt: 'Anzahl Jobs',
};

const plannedPartInput: Partial<Record<keyof TComputedPT9800PlannedPartInput, string>> = {
  pos: 'Position',
  partNumber: 'Teilenummer',
  partDescription: 'Teilebeschreibung',
  orderNo: generalTerm.loadIdentifier,
  orderRemark: 'Bestellbemerkung',
  customerOrderNumber: 'Kundenbestellnummer',
  amount: 'Menge',
  weight: 'Gewicht',
  totalWeight: 'Gesamtgewicht',
};

const planLoadInput: Partial<TComponentPT9800Keys<'planLoadInput'>> = {
  workplaceWoNumber: generalTerm.loadIdentifier,
};

const CsvSeperator: Record<components['schemas']['CsvSeperator'], string> = {
  comma: 'Komma',
  semicolon: 'Semikolon',
  tab: 'Tabulator',
  pipe: 'Rohr',
  space: 'Leerzeichen',
};

const CsvDatetimeFormat: Record<components['schemas']['CsvDatetimeFormat'], string> = {
  iso_8601: 'ISO 8601',
  unix_timestamp: 'UNIX',
  us: 'US',
};

const CsvLineTerminator: Record<components['schemas']['CsvLineTerminator'], string> = {
  posix: 'POSIX',
  windows: 'Windows',
};

const CsvQuoteStyle: Record<components['schemas']['CsvQuoteStyle'], string> = {
  necessary: 'Erforderlich',
  always: 'Immer',
  non_numeric: 'Nicht numerisch',
  never: 'Nie',
};

const CsvQuoteStyleHint: Record<components['schemas']['CsvQuoteStyle'], string> = {
  necessary: 'Sie sind notwendig, wenn Felder ein Anführungszeichen, einen Separator oder einen Datensatzabschluss enthalten. Anführungszeichen sind auch notwendig, wenn ein leerer Datensatz geschrieben wird (der sich nicht von einem Datensatz mit einem leeren Feld unterscheidet). Dies ist der Standard.',
  always: 'Dies setzt Anführungszeichen um jedes Feld. Immer.',
  non_numeric: 'Dies setzt Anführungszeichen um alle Felder, die nicht numerisch sind. Nämlich, wenn ein Feld geschrieben wird, das nicht als gültige Fließkommazahl oder Ganzzahl geparst werden kann, dann werden Anführungszeichen verwendet, auch wenn sie nicht unbedingt erforderlich sind.',
  never: 'Dies setzt niemals Anführungszeichen um Felder, selbst wenn dies zu ungültigen CSV-Daten führt (z.B.: indem keine Anführungszeichen bei Zeichenfolgen gesetzt werden, die den Separator enthalten).',
};

const CsvQuoteCharacter: Record<components['schemas']['CsvQuoteCharacter'], string> = {
  single_quote: 'Einzelnes Zitatzeichen',
  double_quote: 'Doppeltes Zitatzeichen',
};

const PartAssetQualification: Partial<TComponentKeys<'PartAssetQualification'>> = {
  assetDisplayName: generalTerm.asset,
  assetId: generalTerm.asset,
  maxNumberOfPartsPerRost: 'Maximale Anzahl der Teile pro Rost',
  recipeDisplayName: generalTerm.recipe,
  recipeId: generalTerm.recipe,
};

const PartAttribute: Partial<TComponentKeys<'PartAttribute'>> & {
  value: string,
} = {
  displayName: 'Attribute',
  unitOfMeasureId: 'Einheit',
  value: 'Wert',
  valueType: 'Datentyp',
};

const AuditSpecification: TComponentKeys<'AuditSpecification'> = {
  auditSpecificationId: 'Audit Spezifikations ID',
  description: 'Beschreibung',
  displayName: 'Spezifikationsname',
  envelopes: 'Anzahl der Umschläge',
  revision: 'Revisions ID',
  isOneTimeSpec: '',
};

const AlarmCollection: TComponentKeys<'AlarmCollection'> = {
  dataId: 'Data id',
  displayName: 'Name',
  eventType: 'Alarm type',
  occurrenceData: generalTerm.date,
  severity: 'Alarm type',
};

const BusinessConfigurationOutput: Partial<TComponentLegacyKeys<'BusinessConfiguration'>> = {
  planned_outage_days_per_year: 'Geplante Ausfalltage pro Jahr',
  working_shifts_per_week: 'Arbeitsschichten pro Woche',
  hours_per_shift: 'Stunden pro Schicht',
  hourly_billing_rate: 'Stündlicher Abrechnungssatz',
  hourly_depreciation_rate: 'Stündliche Abschreibungsrate',
};

const CounterAction: Partial<TComponentKeys<'CounterAction'>> = {
  displayName: 'Gegenmaßnahme',
  description: generalTerm.description,
};

const JobFeature: Partial<TComponentKeys<'Metadata'>> = {
  displayName: 'Name der Job-Funktion',
  description: generalTerm.description,
};

const Sample: Partial<TComponentKeys<'Sample'>> = {
  displayName: 'Muster',
};

const TableAssessmentTest: Partial<TComponentKeys<'TableAssessmentTest'>> = {
  displayName: generalTerm.name,
  isOverwritten: generalTerm.overwrite,
};

const TableAssessmentTestItem: Partial<Record<keyof components['schemas']['TableAssessmentTest']['items'][number], string>> = {
  displayName: generalTerm.name,
  requirement: generalTerm.requirement,
  result: generalTerm.result,
};

const AssessmentTestResultType = 'Test result';

const ChartAssessmentTest: Partial<TComponentKeys<'ChartAssessmentTest'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  xUnitId: 'X-Einheit',
  yUnitId: 'Y-Einheit',
  requirement: 'Überprüfung',
  isOverwritten: generalTerm.overwrite,
};

const MaterialPropertySpectrum: Record<keyof components['schemas']['MaterialPropertySpectrum']['avg'], string> = {
  alloyFactorCAustenite: 'Legierungsfaktor [Carbon]',
  carbideLimit: 'Carbidgrenze [%aC=1]',
  alloyFactorNAustenite: 'Legierungsfaktor [Nitrogen]',
  nitrideLimit: 'Nitridgrenze [%FexNy]',
  coreHardness: 'Kernhärte (unbehandelt) [HV]',
};

const FileAssessmentTest: Partial<TComponentKeys<'FileAssessmentTest'>> = {
  displayName: generalTerm.name,
  result: 'Testergebnis',
  resultComment: 'Kommentar',
  isOverwritten: generalTerm.overwrite,
};

const ReportOverview: Partial<TComponentKeys<'ReportOverview'>> = {
  reportId: generalTerm.reportId,
  reporterDisplayName: generalTerm.reporter,
  jobId: generalTerm.jobId,
  jobDisplayName: generalTerm.job,
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  assetLocation: generalTerm.location,
  recipeDisplayName: generalTerm.recipe,
  partId: generalTerm.partId,
  partDisplayName: generalTerm.part,
  partCustomerDisplayName: generalTerm.customer,
  partMaterialDisplayName: generalTerm.material,
  partDrawingReference: generalTerm.drawingReference,
  orderNumbers: 'Auftrag',
  isAutogenerated: 'Importiert aus Fremdsystem',
  createdAtUtc: generalTerm.createdAt,
  isDeleted: generalTerm.showDeleted,
};

const Job: Partial<TComponentKeys<'Job'>> = {
  assetDisplayName: generalTerm.asset,
  displayName: generalTerm.jobDisplayName,
  locationDisplayName: 'Location',
  durationSeconds: 'Dauer',
  description: 'Beschreibung',
  startedAtUtc: generalTerm.jobStartedAtUtc,
  finishedAtUtc: generalTerm.jobFinishedAtUtc,
  recipeDisplayName: generalTerm.recipe,
  hasAlarms: 'Alarms',
  jobVerdict: 'Bewertung',
};

const MaterialCalculationModelOutput: Partial<TComponentKeys<'MaterialCalculationModel-Output'>> = {
  displayName: 'Name',
  description: 'Beschreibung',
};

const JobStage: Partial<TComponentKeys<'JobStage'>> | Record<'state', string> = {
  displayName: 'Schritt',
  state: 'Status',
  jobDisplayName: generalTerm.jobDisplayName,
  startedAtUtc: generalTerm.jobStartedAtUtc,
  finishedAtUtc: generalTerm.jobFinishedAtUtc,
  durationSeconds: 'Dauer',
  stageNo: 'Nr',
};

const AlarmSeverity: Partial<Record<components['schemas']['AlarmSeverity'], string>> = {
  0: 'INFO',
  1: 'WARNING',
  2: 'ALARM',
  3: 'EMERGENCY',
};

const MaintenanceOccurrenceIdentifier: Partial<TComponentLegacyKeys<'MaintenanceOccurrenceIdentifier'>> = {
  id: 'Anfrage-ID',
  maintenanceSeriesDisplayName: 'Beschreibung',
  state: 'Anfragestatus',
  assignedTo: 'Rechtsnachfolger',
  locationDisplayName: 'Location',
  assetDisplayName: 'Asset',
  scheduledCompletedAtUtc: generalTerm.date,
  scheduledAtUtc: generalTerm.date,
  maintenanceJobDisplayName: 'Load No',
};

const MaintenanceOccurrence: Partial<TComponentLegacyKeys<'MaintenanceOccurrence'>> = {
  assetDisplayName: 'Name des Ofens',
  scheduledAtUtc: 'Wartungsbeginn',
  scheduledCompletedAtUtc: 'Wartungsende',
  maintenanceSeriesDisplayName: 'Wartungsart',
  description: 'Beschreibung',
  report: 'Bericht',
};

const MaintenanceSeries: Partial<TComponentLegacyKeys<'MaintenanceSeries'>> = {
  displayName: 'Name der Aufgabe',
  description: 'Beschreibung',
  maintenanceJobId: 'Wartungsarbeit',
  recurrence: 'Wiederholung',
  priority: 'Priorität',
  color: 'Farbe',
  validFromUtc: 'Geplantes Datum',
  assetId: 'Asset',
  ownerId: 'Zuordnen zu',
};

const Equipment: Partial<TComponentLegacyKeys<'EquipmentResponse'>> = {
  modelName: 'Ofentyp',
};

const RootCauseGroup: Partial<TComponentKeys<'RootCauseGroup'>> = {
  displayName: 'Name der Ursachengruppe',
  description: generalTerm.description,
};

const EquipmentProfile: Partial<TComponentKeys<'ProfileTreeNode'>> = {
  label: 'Profilname',
  combinator: 'Kombination Typ',
};

// eslint-disable-next-line no-underscore-dangle, camelcase
const TimeBucket_AssetUtilization_: Partial<TComponentKeys<'TimeBucket_AssetUtilization_'>> = {
  earliestDateUtc: 'Start date',
  latestDateUtc: 'End date',
};

const EquipmentProfileMeaning: Partial<TComponentKeys<'Meaning'>> = {
  label: 'Bedeutung des Namens',
  description: 'Beschreibung',
  physicalDimensionId: 'Physikalische Größe',
  preferredUnitId: 'Bevorzugte Einheit',
};

const EquipmentTreeNavigation: Partial<TComponentKeys<'AssetTreeNode'>> = {
  displayName: 'Name',
};

const MaterialGroup: Partial<TComponentKeys<'MaterialGroup'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  materialIds: 'Materialien',
};

const SettingsActivityCoefficients: Record<EActivityCoefficients, string> = {
  [EActivityCoefficients.ALLOYING_ELEMENT_ID]: 'Element',
  [EActivityCoefficients.DESCRIPTION]: 'Comment',
  [EActivityCoefficients.E_JC_AUSTENITE]: 'eJC (Austenite)',
  [EActivityCoefficients.E_JC_FERRITE]: 'eJC (Ferrite)',
  [EActivityCoefficients.E_JN_AUSTENITE]: 'eJN (Austenite)',
  [EActivityCoefficients.E_JN_FERRITE]: 'eJN (Ferrite)',
  [EActivityCoefficients.R_JJC_AUSTENITE]: 'rJJC (Austenite)',
  [EActivityCoefficients.R_JJC_FERRITE]: 'rJJC (Ferrite)',
  [EActivityCoefficients.R_JJN_AUSTENITE]: 'rJJN (Austenite)',
  [EActivityCoefficients.R_JJN_FERRITE]: 'rJJN (Ferrite)',
};

const MaintenanceState: Record<EMaintenanceState, string> = {
  [EMaintenanceState.PENDING]: 'Pending',
  [EMaintenanceState.ACCEPTED]: 'Angenommen',
  [EMaintenanceState.IN_PROGRESS]: 'In Bearbeitung',
  [EMaintenanceState.DONE]: 'Erledigt',
  [EMaintenanceState.VALIDATED]: 'Validiert',
};

const MaintenancePriority: Record<EMaintenancePriority, string> = {
  [EMaintenancePriority.LOW]: 'Niedrig',
  [EMaintenancePriority.NORMAL]: 'Normal',
  [EMaintenancePriority.HIGH]: 'Hoch',
};

const MaintenancePeriod: Record<ECalendarPeriod, string> = {
  [ECalendarPeriod.WEEK]: 'Woche',
  [ECalendarPeriod.MONTH]: 'Monat',
  [ECalendarPeriod.YEAR]: 'Jahr',
};

const MaintenanceRecurrenceMonthDayValue: Record<EMaintenanceRecurrenceMonthDayValue, string> = {
  [EMaintenanceRecurrenceMonthDayValue.FIRST]: 'Erste',
  [EMaintenanceRecurrenceMonthDayValue.SECOND]: 'Zweite',
  [EMaintenanceRecurrenceMonthDayValue.THIRD]: 'Dritter',
  [EMaintenanceRecurrenceMonthDayValue.FOURTH]: 'Vierte',
  [EMaintenanceRecurrenceMonthDayValue.LAST]: 'Letzter',
};

const MaintenanceRecurrenceMonthDayDimension: Record<EMaintenanceRecurrenceMonthDayDimension, string> = {
  [EMaintenanceRecurrenceMonthDayDimension.WEEKDAY]: 'Wochentag',
  [EMaintenanceRecurrenceMonthDayDimension.WEEKEND_DAY]: 'Wochenendtag',
  [EMaintenanceRecurrenceMonthDayDimension.DAY]: 'Tag',
};

const MaintenanceRecurrenceEndCondition: Record<EMaintenanceRecurrenceEndCondition, string> = {
  [EMaintenanceRecurrenceEndCondition.AFTER]: 'nach',
  [EMaintenanceRecurrenceEndCondition.ON_THIS_DAY]: 'an diesem Tag',
};

const MetadataWithChartConfigInput: Partial<TComponentKeys<'MetadataWithChartConfig'>> = {
  isHidden: 'Sichtbarkeit',
  displayName: generalTerm.name,
  color: 'Farbe',
  guaranteedColor: 'Farbe',
  description: generalTerm.description,
  unitOfMeasureId: 'Einheit',
  dataSourceReference: 'Referenz der Datenquelle',
  meaningId: 'Meaning',
  significantDigits: 'Signifikante Ziffern',
};

const RootCause: Partial<TComponentKeys<'RootCause'> & {
  count: string,
}> = {
  displayName: 'Ursache',
  description: generalTerm.description,
  count: 'Anzahl',
};

const JobValueData: Partial<TComponentKeys<'JobValueData'>> = {
  jobId: 'JobId',
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartUtc: generalTerm.jobStartedAtUtc,
  jobEndUtc: generalTerm.jobFinishedAtUtc,
  value: 'Wert',
  recipeId: 'RecipeId',
  recipeRevision: 'RecipeRevision',
  recipeDisplayName: generalTerm.recipe,
};

const QualityPreset: Partial<TComponentKeys<'QualityPreset'>> = {
  displayName: generalTerm.name,
};

const Observation: Partial<TComponentKeys<'Observation'>> = {
  displayName: 'Beobachtung',
  observationTimeAtUtc: 'Zeitpunkt der Beobachtung',
  jobFeatureDisplayName: 'Job Feature',
  observationType: 'Beobachtungstyp',
  jobValue: 'Wert',
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartedAtUtc: generalTerm.jobStartedAtUtc,
  jobFinishedAtUtc: generalTerm.jobFinishedAtUtc,
};

const EquipmentDocument: Partial<TComponentLegacyKeys<'EquipmentDocument'>> = {
  name: 'Dateiname',
  description: 'Beschreibung',
  category: 'Kategorie',
  username: 'Hochgeladen von',
};

const InstructionEquipment: Partial<TComponentLegacyKeys<'EquipmentDocument'>> = {
  name: 'Dateiname',
  description: 'Beschreibung',
  username: 'Hochgeladen von',
};

const QuenchingMedia: Partial<TComponentKeys<'QuenchingMedia'>> = {
  displayName: generalTerm.name,
  maxIntensity: 'Maximale Intensität',
  minIntensity: 'Minimale Intensität',
  quenchingMediaId: 'ID',
};

const expressionFunctionCategories: Record<TFunctionCategory, string> & {all: string} = {
  all: 'Alle',
  arithmetic: 'Arithmetische Funktionen',
  cumulative: 'Kumulative Funktionen',
  custom: 'Benutzerdefinierte Funktionen',
  exponential: 'Exponential Funktionen',
  generative: 'Generative Funktionen',
  imputation: 'Imputations Funktionen',
  logical: 'Logische Funktionen',
  numerical: 'Numerische Funktionen',
  operator: 'Operatoren',
  other: 'Andere Funktionen',
  sections: 'Bereichsfunktionen',
  statistical: 'Statistische Funktionen',
  trigonometric: 'Trigonometrische Funktionen',
};

const expressionFunctionsExplanations: {
  functions: Record<TFunctionDefinition['functionType'], string>,
  operators: Record<TOperatorDefinition['operatorType'], string>,
} = {
  functions: {
    abs: 'Diese Funktion berechnet die absoluten Werte eines Signals elementweise.',
    acos: 'Diese Funktion berechnet den Arkuskosinus jedes Elements eines Signals.',
    add: 'Addition. Addiert zwei Operanden zusammen.',
    asin: 'Diese Funktion berechnet den Arkussinus jedes Elements eines Signals.',
    atan2: 'Diese Funktion berechnet den Arkustangens von a/b für jedes entsprechende Elementenpaar.',
    atan: 'Diese Funktion berechnet den Arkustangens jedes Elements eines Signals.',
    avg: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion den Durchschnitt über jedes Signal. Andernfalls berechnet sie den Durchschnitt aller Proben.',
    avg_windowed: 'Diese Funktion berechnet den Mittelwert der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    bfill: 'Füllt NaN-Werte, indem der nächste gültige Wert verwendet wird, um die Lücke zu füllen.',
    ceil: 'Diese Funktion rundet jedes Element eines Signals auf die nächste Ganzzahl auf.',
    clamp: 'Diese Funktion klemmt die Werte eines Signals innerhalb eines angegebenen Bereichs ab.',
    cos: 'Diese Funktion berechnet den Kosinus jedes Elements eines Signals.',
    count_samples: 'Accumulates the number of samples where the input signal evaluates to true.',
    count_seconds: 'Accumulates the seconds where the input signal evaluates to true.',
    counter: 'Diese Funktion erhöht einen Zähler für jeden Zeitschritt um 1.',
    cumulative_avg: 'Gibt den kumulierten Durchschnitt des Signals zurück.',
    cumulative_integrate: 'Gibt das kumulierte Integral (unter Verwendung der Trapezregel über Sekunden) zurück.',
    cumulative_max: 'Gibt das kumulierte Maximum des Signals zurück.',
    cumulative_mean: 'Gibt den kumulierten Mittelwert des Signals zurück.',
    cumulative_median: 'Gibt den kumulierten Median des Signals zurück.',
    cumulative_min: 'Gibt das kumulierte Minimum des Signals zurück.',
    cumulative_range: 'Gibt den kumulierten Bereich des Signals zurück.',
    cumulative_std: 'Gibt die kumulierte Standardabweichung des Signals zurück.',
    cumulative_sum: 'Gibt die kumulierte Summe des Signals zurück, wobei "Not a Number" (NaN) als Null behandelt wird. Die kumulierte Summe ändert sich nicht, wenn NaNs auftreten, und führende NaNs werden durch Nullen ersetzt. Für Signale, die nur NaN oder leer sind, werden Nullen zurückgegeben.',
    cumulative_var: 'Gibt die kumulierte Varianz des Signals zurück.',
    degrees: 'Diese Funktion wandelt jedes Element eines Signals von Radianten in Grad um.',
    denormalize: 'Diese Funktion denormalisiert die Werte eines Signals vom Bereich [0, 1] in den angegebenen Bereich.',
    diff: 'Diese Funktion berechnet die Differenz zwischen aufeinanderfolgenden Elementen eines Signals.',
    divide: 'Division. Teilt den ersten Operanden durch den zweiten.',
    do_nothing: 'Diese Funktion gibt das Eingangssignal zurück.',
    elapsed_seconds: 'Diese Funktion gibt die Anzahl der vergangenen Sekunden seit Beginn des Zeitrahmens zurück.',
    equals: 'Gleichheit. Gibt true zurück, wenn die Operanden gleich sind, ansonsten false.',
    exp: 'Diese Funktion berechnet die Exponentialfunktion jedes Elements eines Signals.',
    fill: 'Füllt NaN-Werte in Signal a mit Werten von Signal b.',
    ffill: 'Füllt NaN-Werte, indem der letzte gültige Wert zum nächsten gültigen propagiert wird.',
    floor: 'Diese Funktion rundet jedes Element eines Signals auf die nächste Ganzzahl ab.',
    floor_divide: 'Ganzzahlige Division. Gibt den Quotienten der Division zurück und verwirft dabei den Rest.',
    gradient: 'Numerische Ableitung des Eingangssignals pro Sekunde (1/s).',
    gradient_windowed: 'Numerische Ableitung über ein gegebenes dx (in Sekunden). Falls dx negativ oder null ist, ist der Rückgabewert NaN. Der parameter dx wird als Konstante interpretiert.',
    greater_than: 'Größer als. Gibt true zurück, wenn der erste Operand größer als der zweite ist.',
    greater_than_or_equal: 'Größer als oder gleich. Gibt true zurück, wenn der erste Operand größer oder gleich dem zweiten ist.',
    if: 'Diese Funktion führt eine bedingte Operation elementweise basierend auf einer Bedingung durch.',
    integrate: 'Diese Funktion integriert das Signal über Sekunden unter Verwendung der Trapezregel.',
    interpfill: 'Füllt NaN-Werte, indem linear zwischen dem letzten und dem nächsten gültigen Wert interpoliert wird.',
    is_finite: 'Diese Funktion überprüft, ob die Elemente eines Signals elementweise endlich sind.',
    less_than: 'Kleiner als. Gibt true zurück, wenn der erste Operand kleiner als der zweite ist.',
    less_than_or_equal: 'Kleiner als oder gleich. Gibt true zurück, wenn der erste Operand kleiner oder gleich dem zweiten ist.',
    ln: 'Diese Funktion berechnet den natürlichen Logarithmus jedes Elements eines Signals.',
    log10: 'Diese Funktion berechnet den Logarithmus zur Basis 10 jedes Elements eines Signals.',
    log2: 'Diese Funktion berechnet den Logarithmus zur Basis 2 jedes Elements eines Signals.',
    log: 'Diese Funktion berechnet den Logarithmus jedes Elements eines Signals zur Basis b.',
    logical_and: 'Logisches UND. Gibt true zurück, wenn beide Operanden true sind, ansonsten false.',
    logical_not: 'Unäres logisches NICHT. Gibt true zurück, wenn das Operand falsch ist, und false, wenn das Operand wahr ist.',
    logical_or: 'Logisches ODER. Gibt true zurück, wenn mindestens einer der Operanden true ist, ansonsten false.',
    logical_xor: 'Logisches XOR. Gibt false zurück, wenn beide Operatoren den gleichen logischen Zustand haben, ansonsten true.',
    max: 'Wenn mehrere Argumente gegeben sind, findet diese Funktion den maximalen Wert unter den Signalen. Andernfalls findet sie den maximalen Wert unter allen Proben.',
    max_windowed: 'Diese Funktion berechnet das Maximum der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    mean: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion den Durchschnitt über jedes Signal. Andernfalls berechnet sie den Durchschnitt aller Proben.',
    mean_windowed: 'Diese Funktion berechnet den Mittelwert der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    median: 'Wenn mehrere Argumente gegeben sind, findet diese Funktion den Medianwert unter den Signalen. Andernfalls findet sie den Medianwert unter allen Proben.',
    median_windowed: 'Diese Funktion berechnet den Median der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    min: 'Wenn mehrere Argumente gegeben sind, findet diese Funktion den maximalen Wert unter den Signalen. Andernfalls findet sie den maximalen Wert unter allen Proben.',
    min_windowed: 'Diese Funktion berechnet das Minimum der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    modulo: 'Modulus. Gibt den Rest der Division des ersten Operanden durch den zweiten zurück.',
    multiply: 'Multiplikation. Multipliziert zwei Operanden miteinander.',
    negate: 'Diese Funktion negiert jedes Element eines Signals.',
    normalize: 'Diese Funktion normalisiert die Werte eines Signals auf den Bereich [0, 1].',
    not_equals: 'Ungleichheit. Gibt true zurück, wenn die Operanden ungleich sind, ansonsten false.',
    percentile: 'Diese Funktion berechnet das Perzentil der Signalwerte.',
    power: 'Exponentialfunktion. Erhöht den ersten Operanden zur Potenz des zweiten Operanden.',
    radians: 'Diese Funktion wandelt jedes Element eines Signals von Grad in Radianten um.',
    ramp: 'Diese Funktion gibt eine lineare Rampe von 0 bis 1 zurück.',
    random: 'Diese Funktion gibt für jeden Zeitschritt eine Zufallszahl zwischen 0 und 1 zurück.',
    range: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion den Bereich (max - min) über jedes Signal. Andernfalls berechnet sie den Bereich aller Proben.',
    range_windowed: 'Diese Funktion berechnet den Bereich der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    relative_error: 'Berechnet den relativen Fehler in % an Stellen wo reference != 0.',
    round: 'Diese Funktion rundet jedes Element eines Signals auf die nächste Ganzzahl.',
    sections_join: 'Kombiniert alle Abschnitte deren Abstand kürzer als die gewählte Grenze ist.',
    sections_first: 'Gibt den ersten verfügbaren Abschnitt zurück. Kann keinen Abschnitt zurückgeben, wenn keiner existiert.',
    sections_last: 'Gibt den letzten verfügbaren Abschnitt zurück. Kann keinen Abschnitt zurückgeben, wenn keiner existiert.',
    sections_nth: 'Gibt den n-ten Abschnitt zurück, falls existent. Um von hinten zu addressieren, kann negative Indizierung verwendet werden. Die Indizierung beginnt bei 0.',
    sections_shortest: 'Gibt die kürzeste(n) Abschnitt(e) zurück. Kann mehrere Abschnitte zurückgeben, falls es mehrere Abschnitte mit minimaler Länge gibt.',
    sections_median_duration: 'Gibt den/die Abschnitt(e) mit der mittleren Dauer zurück. Kann mehrere Abschnitte zurückgeben, wenn es mehrere Abschnitte mit der mittleren Länge gibt.',
    sections_longest: 'Gibt den/die längsten Abschnitt(e) zurück. Kann mehrere Abschnitte zurückgeben, wenn es mehrere Abschnitte mit maximaler Länge gibt.',
    sections_max_value: 'Gibt den/die Abschnitt(e) mit dem größten Wert zurück. Kann mehrere Abschnitte zurückgeben, wenn mehrere Abschnitte mit dem absoluten Maximalwert vorhanden sind.',
    sections_median_value: 'Gibt den Abschnitt zurück, der den Medianwert enthält. Kann mehrere Abschnitte zurückgeben, wenn mehrere Abschnitte mit dem Medianwert vorhanden sind.',
    sections_min_value: 'Gibt den/die Abschnitt(e) mit dem kleinsten Wert zurück. Kann mehrere Abschnitte zurückgeben, wenn mehrere Abschnitte mit dem absoluten Minimalwert vorhanden sind.',
    sign: 'Diese Funktion gibt das Vorzeichen jedes Elements eines Signals zurück.',
    sin: 'Diese Funktion berechnet den Sinus jedes Elements eines Signals.',
    sqrt: 'Diese Funktion berechnet die Quadratwurzel jedes Elements eines Signals.',
    stage_number: 'Liefert die Nummer des aktuellen Rezeptschrittes, sofern ein job aktiv ist. Ansonsten NaN.',
    std: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion die Standardabweichung über jedes Signal. Andernfalls berechnet sie die Standardabweichung aller Proben.',
    std_windowed: 'Diese Funktion berechnet die Standardabweichung der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    steady_state_detection: 'Diese Funktion erlaubt es Bereiche mit nahezu Konstanten werten zu finden. Die Windowlength erlaubt das Glätten des Eingangssignals und der Grenzwert erlaubt es eine maximale Steigung zu definieren bei das Signal noch als Konstant angenommen wird.',
    subtract: 'Subtraktion. Subtrahiert den zweiten Operanden vom ersten.',
    sum: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion die Summe über jedes Signal. Andernfalls berechnet sie die Summe aller Proben.',
    sum_windowed: 'Diese Funktion berechnet die Summe der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
    tan: 'Diese Funktion berechnet den Tangens jedes Elements eines Signals.',
    timestamp: 'Diese Funktion gibt die Millisekunden seit der Epoche (1970-01-01 00:00:00 UTC) zurück.',
    total_seconds: 'Gibt die Gesamtzeit des angezeigen Zeitraum in Sekunden als Konstante zurück.',
    trunc: 'Diese Funktion kürzt jedes Element eines Signals in Richtung Null ab.',
    var: 'Wenn mehrere Argumente gegeben sind, berechnet diese Funktion die Varianz über jedes Signal. Andernfalls berechnet sie die Varianz aller Proben.',
    var_windowed: 'Diese Funktion berechnet die Varianz der Signalwerte über die angegebene Fensterlänge in Sekunden. Eine negative Fensterlänge wird in eine Fensterlänge von 0 umgewandelt, was zu NaN führt.',
  },
  operators: {
    '!': 'Logisches NICHT. Gibt true zurück, wenn das Operand falsch ist, und false, wenn das Operand wahr ist.',
    '-': 'Subtraktion. Subtrahiert den zweiten Operanden vom ersten.',
    '+': 'Addition. Addiert zwei Operanden zusammen.',
    '^': 'Bitweise XOR. Gibt an jeder Bitposition eine 1 zurück, an der die entsprechenden Bits beider Operanden unterschiedlich sind.',
    '**': 'Exponentialfunktion. Erhöht den ersten Operanden zur Potenz des zweiten Operanden.',
    '*': 'Multiplikation. Multipliziert zwei Operanden miteinander.',
    '//': 'Ganzzahlige Division. Gibt den Quotienten der Division zurück und verwirft dabei den Rest.',
    '/': 'Division. Teilt den ersten Operanden durch den zweiten.',
    '%': 'Modulo. Gibt den Rest der Division des ersten Operanden durch den zweiten zurück.',
    '==': 'Gleichheit. Gibt true zurück, wenn die Operanden gleich sind, ansonsten false.',
    '!=': 'Ungleichheit. Gibt true zurück, wenn die Operanden ungleich sind, ansonsten false.',
    '<': 'Kleiner als. Gibt true zurück, wenn der erste Operand kleiner als der zweite ist.',
    '<=': 'Kleiner als oder gleich. Gibt true zurück, wenn der erste Operand kleiner oder gleich dem zweiten ist.',
    '>': 'Größer als. Gibt true zurück, wenn der erste Operand größer als der zweite ist.',
    '>=': 'Größer als oder gleich. Gibt true zurück, wenn der erste Operand größer oder gleich dem zweiten ist.',
    '&&': 'Logisches UND. Gibt true zurück, wenn beide Operanden true sind, ansonsten false.',
    '||': 'Logisches ODER. Gibt true zurück, wenn mindestens einer der Operanden true ist, ansonsten false.',
  },
};

const Pt9800RecipeListItem: TComponentPT9800Keys<'recipeListItem'> = {
  id: 'id',
  description: generalTerm.description,
  recipe: generalTerm.recipe,
  recipePrefix: 'Rezeptpräfix',
  version: 'Rezept version',
};

const Pt9800RecipeItemHeader: Partial<TComponentPT9800Keys<'recipeItemHeader'>> = {
  recipeName: 'Name',
  recipePrefix: 'Gruppe',
  description: 'Beschreibung',
  processType: 'Prozess',
  processId: 'Betrieb',
  comment: 'Kommentar',
  diff: 'Aktivieren',
  correction: 'Korrektur',
};

const Pt9800Material: Partial<TComponentPT9800Keys<'material'>> = {
  carbonContent: 'Kohlenstoffgehalt',
  carbideLimit: 'Karbidgrenze',
  alloyFactor: 'Legierungsfaktor',
};

const Pt9800RecipeItemPosition: Partial<TComponentPT9800Keys<'recipeItemPosition'>> = {};

const Pt9800RecipeItemPositionHeader: Partial<TComponentPT9800Keys<'recipeItemPositionHeader'>> = {
  positionNo: 'Schritt',
  description: 'Schrittname',
  chamberId: 'Kammer',
  chamberOperationId: 'Kammerbetrieb',
  afterId: 'Endbedingung',
  estimatedDurationInMin: 'Geschätzte Dauer',
};

const Pt9800SetPointItem: Partial<TComponentPT9800Keys<'setPointItem'>> & {
  unit: string,
} = {
  userComponentId: 'Sollwert',
  controlId: 'Ctrl',
  value: 'Wert',
  unit: 'Einheit',
  toleranceMax: 'Tol +',
  toleranceMin: 'Tol -',
  alarmStartTimeInMin: 'Zeit 1',
  alarmToleranceTimeInMin: 'Zeit 2',
  endControlId: 'Ctrl',
  endValue: 'Endbedingung',
};

const DataMigrationState: TComponentKeys<'DataMigrationState'> = {
  assetId: generalTerm.assetId,
  displayName: generalTerm.displayName,
  assetDisplayName: generalTerm.asset,
  lastTimestampAtUtc: 'Neuste Daten',
  lastEtlRunAtUtc: 'Letzte Migration',
  etlLagSeconds: 'Migration lag',
  dataId: 'Identifizierer',
  dataFormat: 'Typ',
  dataSourceReference: 'Referenz in der Datenquelle',
};

const JobMigrationState: TComponentKeys<'JobMigrationState'> = {
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  lastTimestampAtUtc: 'Neuste Daten',
  lastEtlRunAtUtc: 'Letzte Migration',
  etlLagSeconds: 'Migration lag',
};

const CarburizingSimulationRecipe: Record<keyof components['schemas']['CarbonitridingRecipeStageMutation']
| (keyof components['schemas']['CarbonitridingRecipeStageMutation'])
| (keyof components['schemas']['LowPressureCarburizingRecipeStageMutation'])
| 'duration', string> = {
  stageId: 'Stufe',
  cControl: 'C-Pegel Status',
  coContent: 'CO',
  h2Content: 'H2',
  tempControl: 'Temperatur',
  endControl: 'Endzustand',
  duration: 'Dauer',
  nControl: 'Stickstoffpotenzial',
  volControl: 'Volumenstrom',
  // keys with "Value" will not be used
  volValue: 'Volume potential',
  endValue: 'Endzustand',
  tempValue: 'Temperatur',
  nValue: 'Nitrogen potential',
  cValue: 'Kohlenstoffpotenzial',
};

const QmulusDriver = {
  PT9800: {
    DataMigrationState,
    JobMigrationState,
  },
};

export default {
  generalTermFilter,
  generalTerm,
  api: {
    Asset,
    CsvQuoteCharacter,
    CsvLineTerminator,
    CsvSeperator,
    CsvDatetimeFormat,
    CsvQuoteStyle,
    CsvQuoteStyleHint,
    AuditResultType,
    AuditEnvelopeNonPassedReasons,
    QualityPreset,
    SparePart,
    HeatTreatmentCapability,
    Chamber,
    QuenchingMedia,
    MaterialCalculationModelOutput,
    CarburizingSimulationRecipe,
    DataFormat,
    QmulusDriver,
    MeasurementDisplayName,
    CounterAction,
    ProductionStatistics,
    PhysicalDimension,
    CalendarEventType,
    HierarchyLabel,
    SimulationStep,
    ObservationType,
    AppGroupSemantic,
    ChamberType,
    HeatTreatmentProcessType,
    AppId,
    AppGroupCommercial,
    ObservationTypeHeader,
    AssetProfileStatus,
    AssetProfileCombinator,
    MaintenanceJob,
    MaintenanceTask,
    Material,
    AuditSpecification,
    AssetUtilization,
    AuditSpecificationResultOverview,
    Part,
    SettingsActivityCoefficients,
    Customer,
    User,
    PartAttribute,
    MetadataWithChartConfigInput,
    JobFeature,
    RootCauseGroup,
    // eslint-disable-next-line camelcase
    TimeBucket_AssetUtilization_,
    CarburizingRecipeSimulationValuesType,

    MaintenanceState,
    MaintenancePriority,
    MaintenancePeriod,
    MaintenanceRecurrenceMonthDayValue,
    MaintenanceRecurrenceMonthDayDimension,
    MaintenanceRecurrenceEndCondition,

    BusinessConfigurationOutput,
    AlarmCollection,
    AlarmSeverity,
    LegacyEquipmentResponse,
    MaintenanceOccurrenceIdentifier,
    MaintenanceOccurrence,
    MaintenanceSeries,
    Equipment,
    EquipmentProfile,
    EquipmentProfileMeaning,
    EquipmentTreeNavigation,
    RootCause,
    JobValueData,
    JobStage,
    Job,
    Observation,
    EquipmentDocument,
    InstructionEquipment,
    PartAssetQualification,
    OpenAPIPrimitiveType,
    MaterialGroup,
    MaterialNamingSystem,
    ReportOverview,
    Sample,
    TableAssessmentTest,
    ChartAssessmentTest,
    FileAssessmentTest,
    AssessmentTestResultType,
    TableAssessmentTestItem,
    ChartAssessmentTestRequirementType,
    CarburizingSimulationPartShape,
    CarburizingSimulationPartShapeSize,
    CarburizingSimulatorRecipeType,
    MaterialPropertySpectrum,
    CarburizingTemperatureControlType,
    CarburizingVolumeControlType,
    CarburizingCarbonControlType,
    CarburizingEndControlTypeStrings,
    CarburizingNitrogenControlType,
    CarburizingEndControlType,
    SimulatorRecipe,
    NotificationTable,
    SubscriptionTreeNode,
    PartMaterialProperties,

    plannedPartInput,
    planLoadInput,

    expressionFunctionCategories,
    expressionFunctionsExplanations,

    util: {
      timeBucketName: 'Zeitperiode',
    },

    meaning: {
      ...TimeseriesMeaning,
      ...ConsumptionMeaning,
      ...JobFeatureMeaning,
      ...PhysicalPropertyMeaning,
    },

    pt9800: {
      recipeListItem: Pt9800RecipeListItem,
      recipeItemHeader: Pt9800RecipeItemHeader,
      material: Pt9800Material,
      recipeItemPosition: Pt9800RecipeItemPosition,
      recipeItemPositionHeader: Pt9800RecipeItemPositionHeader,
      setPointItem: Pt9800SetPointItem,
    },
  },
  npm,
  actions: {
    abort: 'Abbrechen',
    accept: 'Akzeptieren',
    addFilter: 'Filter hinzufügen',
    back: 'Zurück',
    cancel: 'Abbrechen',
    change: 'Ändern',
    clear: 'Leeren',
    close: 'Schließen',
    confirm: 'Bestätigen',
    delete: 'Löschen',
    deselectAll: 'Alle abwählen',
    edit: 'Bearbeiten',
    finish: 'Beenden',
    finishMaintenanceOccurrence: 'Wartungsauftrag beenden',
    goBack: 'Zurückgehen',
    login: 'Login',
    next: 'Nächste',
    ok: 'Ok',
    reject: 'Ablehnen',
    rejectMaintenanceOccurrence: 'Wartungsauftrag ablehnen',
    reset: 'Zurücksetzen',
    resume: 'Fortsetzen',
    restart: 'Neustart',
    restoreRecipe: 'Rezept wiederherstellen',
    retry: 'Wiederholen',
    save: 'Speichern',
    saveAs: 'Speichern zu',
    search: 'Suchen',
    select: 'Auswählen',
    selectAll: 'Alle auswählen',
    skip: 'überspringen',
    start: 'Start',
    stop: 'Stoppen',
    unlink: 'Verknüpfung aufheben',
    update: 'Update',
    validate: 'Bestätigen',
    validated: 'Bestätigt',
  },
  alarmStatistics: {
    alarmsCausingStop: 'Nur Alarme, die zum Anhalten führen',
    chartjsLimit: 'Chartjs kann nicht so viele Ereignisse verarbeiten',
    duration: 'Dauer',
    evaluationFrom: 'Datum von',
    history: 'Historie',
    list: 'Liste',
    noAlarms: 'Keine Alarme',
    paretoOccurrences: 'Pareto (Ereignisse)',
    paretoTime: 'Pareto (Zeit)',
    quantity: 'Menge',
    resultStatistics: 'Statistiken',
    resultVisualization: 'Visuelle Statistiken',
    to: 'Datum bis',
  },
  alarms: {
    ackTime: 'Ack',
    bit: 'Bit',
    byte: 'Byte',
    endTime: 'Ende',
    event: 'Ereignis',
    gate: 'Gate',
    id: 'Id',
    message: 'Nachricht',
    priority: 'Priorität',
    search: 'Alarmsuche',
    startTime: 'Start',
  },
  alerts: {
    cancelCurrentStage: 'Schließen Sie die aktuelle Behandlungsschritt ab, bevor Sie andere Behandlungsschritte bearbeiten.',
    cancelOrValidateEditedStage: 'Behandlungsschritt wurde bearbeitet. Bitte akzeptieren oder verwerfen Sie die Änderungen befor Sie fortfahren.',
    changeStateFailed: 'Statuswechsel fehlgeschlagen',
    default: '(Standard)',
    delete: 'Löschen!',
    deleteFilter: 'Möchten Sie den Filter löschen?',
    deleted: 'Gelöscht',
    digitsAbove: 'Der Wert sollte unter 9',
    digitsBelow: 'Der Wert sollte höher 0',
    edited: '(Bearbeitet)',
    filterSaved: 'Ihr Filter wurde gespeichert.',
    groupsDeleted: 'Materialiengruppe gelöscht',
    materialDeleted: 'Material gelöscht',
    missingChartData: 'Keine Diagrammdaten',
    needValidateOrClose: 'Sie müssen die geöffnete Phase validieren/schließen, bevor Sie fortfahren.',
    noDescription: 'Keine Beschreibung',
    noPermission: 'Kein NPM Benutzer hinterlegt, oder die Zugangsdaten sind falsch.',
    noStageSetup: 'Kein Stage Setup für diesen Stage Change',
    overwrite: 'Soll der Wert überschrieben werden?',
    rejectOccurrence: 'Bitte geben Sie einen Grund für die Ablehnung an.',
    saveFailed: 'Speichern fehlgeschlagen',
    saveSuccess: 'Erfolgreich gespeichert',
    selectElements: 'Bitte wählen Sie die Elemente in der Dropdown-Liste oben aus',
    stageValid: 'STAGE {stage} : Gültig',
    success: 'Erfolg',
    validReport: 'Bestätigen Sie die Validierung.',
    valueAboveMax: 'Der Wert muss kleiner als {value} sein.',
    warning: 'Warnung!',
  },
  anomalyDetection: {
    createRootCause: 'Grundursache erstellen',
    createSuggestedAction: 'Vorgeschlagene Aktion erstellen',
    detectionTime: 'Erkennungszeit',
    jobFeatures: 'Auftragsmerkmal',
    linkRootCause: 'Verknüpfen Sie eine Grundursache',
    linkSuggestedAction: 'Link Vorgeschlagene Aktionen',
    observations: 'Beobachtungen',
    possibleCauses: 'Mögliche Ursachen',
    rootCausesAnalysis: 'Analyse der Grundursachen',
    searchJobFeatures: 'Jobmerkmal suchen',
    searchObservationTypes: 'Suche Beobachtungsarten',
    searchRootCause: 'Suche RootCause',
    searchSuggestedAction: 'Suche vorgeschlagene Aktion',
    selectJobFeature: 'Auftragsmerkmal auswählen',
    selectObservationType: 'Beobachtungsart auswählen',
    selectRootCause: 'Grundursache auswählen',
    selectSuggestedAction: 'Vorgeschlagene Aktion auswählen',
  },
  appOverview: {
    search: 'Apps durchsuchen',
    dataInsight: 'Data/Insight',
    maxNumberOfPinnedApps: 'Maximale Anzahl ({max}) an gepinnten Apps ist erreich.',
    showOnlyAvailableApps: 'Zeige nur verfügbare Apps.',
  },
  assetManagement: {
    settings: {
      categories: {
        costs: 'Kosten',
        maintenance: 'Wartung',
        operations: 'Betrieb',
      },
    },
  },
  audit: {
    addBand: 'Band hinzufügen',
    audits: 'Prüfungen',
    band: 'Band',
    bandMinus: 'unteres Limit',
    bandPlus: 'oberes Limit',
    comment: 'Kommentar',
    completeRateComment: 'Bewerten und kommentieren',
    configureBands: 'Band hinzufügen und konfigurieren',
    createAudit: 'Prüfung erstellen',
    deleteBand: 'Band löschen',
    noAudits: 'Keine Audits',
    selectAudit: 'Audit auswählen',
    selectSensors: 'Wählen Sie Sensoren für die Prüfung aus',
    selectStartTimeOnChart: 'Klicken Sie auf den Diagrammbereich, um die Startzeit auszuwählen.',
    step: 'Schritt {step}:',
    title: 'Audit-Bericht',
    totalTimeInBand: 'Gesamtzeit im Band',
    viewAudit: 'Audit',
  },
  auth: {
    userDoesNotHavePermission: 'User does not have permission {0}',
  },
  batchRating: {
    areYouSure: 'Sind Sie sicher?',
    confirmation: 'Sie sind dabei, diese Charge zu bewerten',
    notRated: 'Nicht bewertet',
    ratedBy: 'Akzeptiert von',
  },
  batchStatuses: {
    allGood: 'Alles Gut',
    notGood: 'Nicht Gut',
    notPassed: 'Audit nicht bestanden',
    passed: 'Audit bestanden',
  },
  buttons: {
    SetInputEndCondition: 'Eingabe-Ende-Bedingung festlegen',
    ack: 'Quittieren',
    add: 'Hinzufügen',
    addAttachments: 'Anhänge hinzufügen',
    addComponent: 'Komponente hinzufügen',
    addEquipment: 'Asset hinzufügen',
    addNewPart: 'Fügen Sie ein neues Ersatzteil hinzu',
    addPlant: 'Standort hinzufügen',
    addStage: 'Behandlungsschritt hinzufügen',
    addUser: 'Nutzer hinzufügen',
    alarm: 'Alarm',
    analogInputs: 'analogeEingänge',
    apply: 'Anwenden',
    attachReport: 'Bericht beifügen',
    cancel: 'Abbrechen',
    changeDateFormat: 'Zeitformat ändern',
    changeLanguage: 'Sprache wechseln',
    changeNumberFormat: 'Dezimaltrennzeichen ändern',
    changePassword: 'Passwort ändern',
    chooseKPIs: 'KPIs auswählen',
    close: 'Schließen',
    connect: 'Verbinden',
    createNewPart: 'Neues Teil erstellen',
    createNewRecipe: 'Neues Rezept erstellen',
    createReport: 'Report erstellen',
    customerRequests: 'Serviceanfrage',
    delete: 'Löschen',
    digitalInputs: 'Digitale Eingänge',
    download: 'Herunterladen {fileName}',
    editOverview: 'Übersicht bearbeiten',
    export: 'Export',
    insertStage: 'Behandlungsschritt einfügen',
    loadList: 'Produktionsliste',
    logout: 'Ausloggen',
    manageSpareParts: 'Ersatzteile verwalten',
    modify: 'Bearbeiten',
    new: 'Neue',
    newCustomerRequests: 'Neue Serviceanfrage',
    pdf: 'PDF erstellen',
    preview: 'Vorschau',
    print: 'Drucken',
    rejectReason: 'Grund der Ablehnung',
    remove: 'Entfernen',
    save: 'Speichern',
    saveRecipe: 'Behandlungsschritt speichern',
    saveUser: 'Benutzer speichern',
    selectSensors: 'Sensoren auswählen',
    startCalculation: 'Berechnen',
    submit: 'Bestätigen',
    tryAgain: 'Versuchen Sie es nochmal',
    updateComponent: 'Komponente aktualisieren',
    updateEquimpent: 'Asset aktualisieren',
    updateSparePart: 'Ersatzteil aktualisieren',
    upload: 'Hochladen',
    uploadDocuments: 'Dokumente hochladen',
    uploadInstructions: 'Anleitungen hochladen',
    uploadPlural: 'Dateien hochladen | Dateien hochladen',
    view: 'Ansicht',
    viewReport: 'Report Ansehen',
  },
  calendar: {
    accepted: 'Angenommen',
    addJob: 'Wartungsjob hinzufügen',
    addOccurrence: 'Wartungsauftrag hinzufügen',
    addTask: 'Arbeitsschritt hinzufügen',
    after: 'nach',
    assignedTo: 'Zuordnen zu',
    color: 'Farbe',
    date: generalTerm.date,
    day: 'Tag',
    days: 'Tage',
    description: 'Beschreibung',
    done: 'Erledigt',
    downloadReport: 'Bericht herunterladen',
    durationOverhead: 'Zusatzaufwand',
    editMaintenanceJob: 'Wartungsjob bearbeiten',
    editMaintenanceTask: 'Arbeitsschritt bearbeiten',
    end: 'Ende',
    endTime: 'Endzeitpunkt',
    every: 'Jeder',
    first: 'Erste',
    fourth: 'Vierte',
    hideCompletedEvents: 'Abgeschlossene Aufträge ausblenden',
    hours: 'Stunden',
    inProgress: 'In Bearbeitung',
    invalidDate: 'Ungültiges Datum',
    last: 'Letzter',
    listMaintenance: 'Wartungsaufträge',
    maintenanceEnd: 'Wartungsende',
    maintenanceJob: 'Wartungsarbeit',
    maintenanceOccurrence: 'Wartungsauftrag: {id}',
    maintenanceStart: 'Wartungsbeginn',
    maintenanceType: 'Wartungsart',
    minutes: 'Minuten',
    month: 'Monat',
    monthS: 'Monat(e)',
    monthly: 'Monatlich',
    never: 'Niemals',
    occurrence: 'Ereignis | Ereignisse',
    occurs: 'Erfolgt',
    on: 'Ein',
    onThisDay: 'an diesem Tag',
    pending: 'Pending',
    projectedDate: 'Geplantes Datum',
    recurrence: 'Wiederholung',
    repeat: 'Wiederholung',
    restartOccurrenceWarningMessage: 'Das Neustarten löscht alle existierenden Reports für den Auftrag. Möchten Sie dennoch Neustarten?',
    searchReport: 'Suchbericht',
    second: 'Zweite',
    startTime: 'Startzeit',
    startingOn: 'beginnend am',
    status: 'Status',
    task: 'Aufgabe',
    taskName: 'Name der Aufgabe',
    tasks: 'Aufgaben',
    third: 'Dritter',
    to: 'Zu',
    today: 'Heute',
    validated: 'Validiert',
    view: 'Ansicht',
    week: 'Woche',
    weekS: 'Woche(n)',
    weekday: 'Wochentag',
    weekendDay: 'Wochenendtag',
    weekly: 'Wöchentlich',
    withoutEndDate: 'ohne Enddatum',
    year: 'Jahr',
    yearly: 'Jährlich',
  },
  cards: {
    criticality: 'Kritikalität: ',
    duration: 'Erwartete Dauer: ',
  },
  chartComparison: {
    chartDuration: 'Dauer der Grafik',
    clickOnFurnaceMsg: 'Klicken Sie auf die Ofen-ID, um zur Sensorzuordnung zu gelangen.',
    compareBtn: 'Vergleiche',
    create: 'Modellprozess erstellen',
    deselectBtn: 'Alle deaktivieren',
    loadingComplete: 'Laden abgeschlossen',
    loadingJob: 'Charge laden',
    noMainTC: "Keine 'Main TC'-Sensordaten in diesem Rezept gefunden",
    noMappedSensors: 'keine zugeordneten Sensoren',
    of: 'von',
    recurringData: 'Wiederkehrende Daten mit ID:',
    unavailableData: 'Daten nicht verfügbar für Chargen-ID : ',
    warningMsg: 'Warnung: Mehr als 10 Chargen ausgewählt. Es kann zu langen Wartezeiten und Leistungsproblemen kommen',
  },
  charts: {
    checkAll: 'Alle auswählen',
    fullscreen: 'Vollbild',
    groups: {
      derivedValues: 'Abgeleitete Werte',
      gasFlows: 'Gasflüsse',
      others: 'Andere',
      potentials: 'Potenziale',
      pressures: 'Drücke',
      temperatures: 'Temperaturen',
    },
    skaleTypes: {
      linear: 'linear',
      logarithmic: 'logarithmisch',
    },
    position: {
      left: 'links',
      right: 'rechts',
    },
    newAxis: 'Neue Achse',
    hoverOnGraph: 'Um Werte zu sehen über dem Graph hovern oder klicken',
    lineWidthSlider: 'Linienbreite',
    lostProductiveTime: 'Verlorene Produktionszeit',
    noAlarms: 'Keine Alarme gefunden',
    notRealizedByShiftModel: 'Nicht realisiert durch das Schichtmodell',
    plannedOutage: 'Geplanter Ausfall',
    productiveTime: 'Produktivzeit',
    resetZoom: 'Zoom zurücksetzen',
    tooltips: {
      lostProductiveTime: 'die Differenz in Tagen zwischen der geplanten Produktionszeit und der "produktiven" Zeit',
      notRealizedByShiftModel: 'gibt die Anzahl der Tage pro Monat an, die aufgrund des zugrunde liegenden Schichtmodells nicht durch die Arbeitszeit abgedeckt sind',
      plannedOutage: 'gibt die Anzahl der Tage pro Monat an, die im Durchschnitt für Wartung und Reparatur vorgesehen sind',
      productiveTime: 'die Summe aller Produktionszeiten der ausgeführten Chargen, angezeigt in Tagen pro Monat',
    },
    totalTime: 'Gesamtdauer',
    uncheckAll: 'Alle abwählen',
  },
  chartsVisualization: {
    checkSensorMapping1: 'Bitte prüfen',
    checkSensorMapping2: 'in der Anlagenverwaltung.',
    dataNotDisplayed: 'Es konnten keine Daten angezeigt werden. Bitte überprüfen Sie die Sensorzuordnung in der Anlagenverwaltung',
    noModelProcess: 'Kein Modell Prozess',
    noSensorMapping: 'Für diese Graph wurde keine Sensorzuordnung gefunden.',
    selectModelProcess: 'Modellprozess auswählen',
    sensorMapping: 'Sensorzuordnung',
  },
  measurementUnit: {
    noGroup: 'Andere',
  },
  attributeUnit: {
    [EAttributeLabel.WEIGHT]: 'Gewicht',
    [EAttributeLabel.LENGTH]: 'Länge',
    [EAttributeLabel.DIAMETER]: 'Durchmesser',
    [EAttributeLabel.SURFACE_HARDNESS]: 'Oberflächehärte',
    [EAttributeLabel.SURFACE_HARDNESS_MIN]: 'Minimale Oberflächehärte',
    [EAttributeLabel.SURFACE_HARDNESS_MAX]: 'Maximale Oberflächehärte',
    [EAttributeLabel.CORE_HARDNESS]: 'Kernhärte',
    [EAttributeLabel.CORE_HARDNESS_MIN]: 'Minimale Kernhärte',
    [EAttributeLabel.CORE_HARDNESS_MAX]: 'Maximale Kernhärte',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_MIN]: 'EGT min',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_MAX]: 'EGT max',
    [EAttributeLabel.EFFECTIVE_CASE_DEPTH_HARDNESS]: 'EGT Härte',
    neuAttributePlaceholder: 'Attribut Label',
  },
  equipmentGasName: {
    [ETimeseriesMeaning.AIR_FLOW_SETPOINT]: 'Luft',
    [ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT]: 'Wasserstoff',
    [ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT]: 'Stickstoff',
    [ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT]: 'Sauerstoff',
    [ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT]: 'Ammoniak',
    [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT]: 'Dissoziiertes Ammoniak',
    [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT]: 'CO2',
    [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT]: 'CO',
    [ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT]: 'Methan',
    [ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT]: 'Propan',
    [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT]: 'N2O',
    [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT]: 'Methanol',
    [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT]: 'Ethylen',
    [ETimeseriesMeaning.ARGON_FLOW_SETPOINT]: 'Argon',
    [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT]: 'Endgas Natürlich',
    [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT]: 'Endgas Propan',
    [ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT]: 'Ethen',
    [ETimeseriesMeaning.HELIUM_FLOW_SETPOINT]: 'Helium',
    [ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT]: 'Erdgas',
    [ETimeseriesMeaning.WATER_FLOW_SETPOINT]: 'Wasser',
  },
  // single words or small phrases WITHOUT CONTEXT and NO LINK
  common: {
    noAccessToAction: 'Du hast keinen Zugriff auf diese Aktion.',
    tiny: 'Klein',
    exampleText: 'Mein Text',
    large: 'Groß',
    size: 'Größe',
    profile: 'Profil',
    appearance: 'Erscheinungsbild',
    home: 'Startseite',
    notification: 'Meldung | Meldungen',
    meanings: 'Bedeutungen',
    result: 'Ergebnis',
    toggle: 'Umschalten',
    statistics: 'Statistik',
    rating: 'Bewertung',
    showItem: 'Zeige {item}',
    showTable: 'Tabelle anzeigen',
    showChart: 'Diagramm anzeigen',
    table: 'Tabelle',
    configuration: 'Konfiguration',
    sensor: 'Sensor | Sensoren',
    passed: 'Bestanden',
    failed: 'Gescheitert',
    section: 'Sektion',
    filterName: 'Filtername',
    capacity: 'Kapazität | Kapazitäten',
    gas: 'Gas | Gase',
    capability: 'Fähigkeit | Fähigkeiten',
    certificate: 'Zertifikat | Zertifikate',
    type: 'Typ | Typen',
    model: 'Modell | Modelle',
    createItem: 'Erstelle {item}',
    overview: 'Übersicht',
    documents: 'Dokumente',
    communications: 'Kommunikation',
    percentageC: '%C',
    legend: 'Legende',
    linkItem: 'Verknüpfe {item}',
    today: 'Heute',
    earlier: 'Früher',
    addItem: '{item} hinzufügen',
    min: 'Min',
    max: 'Max',
    avg: 'Durchschnitt',
    comp: 'Zusammensetzung',
    file: 'Datei',
    image: 'Bild',
    pasteFromClipboard: 'Aus Zwischenablage einfügen',
    noItemInClipboard: 'Keine {item} in der Zwischenablage',
    assetNavigation: 'Asset Navigation',
    noRecipeSelected: 'Kein Rezept ausgewählt',
    addAssessment: 'Bewertung hinzufügen',
    addRow: 'Zeile hinzufügen',
    description: 'Beschreibung',
    setAll: 'Alle setzen',
    resetAll: 'Alle zurücksetzen',
    unlock: 'Entsperren',
    set: 'Setzen',
    notAvailable: 'N/A',
    stop: 'Stopp',
    modify: 'Ändern',
    itemNotFound: '{item} nicht gefunden',
    user: 'User',
    read: 'Read',
    load: 'Laden',
    loadItem: '{item} laden',
    actions: 'Aktionen',
    add: 'Hinzufügen',
    valueAtTime: '{value} bei {time}',
    timeline: 'Zeitachse',
    duration: 'Dauer',
    temperature: 'Temperatur',
    Ferrite: 'Ferrit',
    Austenite: 'Austenit',
    Martensite: 'Martensit',
    labelInUnit: '{label} in {unit}',
    default: 'Standard',
    settings: 'Einstellungen',
    version: 'Version',
    logout: 'Abmelden',
    confirm: 'Bestätigen',
    edit: 'Bearbeiten',
    start: 'Starten',
    end: 'Ende',
    confirmUnlinkItem: 'Sind Sie sicher, dass Sie "{item}" entkoppeln möchten?',
    confirmDeleteItem: 'Bist du dir sicher, dass du "{item}" löschen möchtest?',
    confirmUpdateItem: 'Sind Sie sicher, dass Sie "{item}" aktualisieren möchten?',
    selectAll: 'Alle auswählen',
    selectItem: 'Wähle {item} aus',
    deselectAll: 'Nichts auswählen',
    value: 'Wert | Werte',
    username: 'Benutzername',
    name: 'Name',
    password: 'Passwort',
    validate: 'Überprüfen',
    selectEquipment: 'Bitte wählen Sie in Asset aus der Asset Navigation auf der linken Seite aus um Details zu sehen',
    required: 'Erforderlich',
    date: 'Datum',
    traveller: 'Reisender',
    new: 'Neu',
    calendarWeekAbbreviation: 'KW',
    unlink: 'Trennen',
    clear: 'Zurücksetzen',
    other: 'Andere',
    cancel: 'Abbrechen',
    error: 'Fehler',
    errorMessage: {
      errorSignalLoading: 'Fehler beim Laden von {displayName}',
      noAccess: '{entity} hat keinen "{accessType}" Zugang zu {item}',
      default: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu. Wenn der Fehler weiterhin besteht, wenden Sie sich bitte an den Support.',
      pt9800: {
        AxiosError401: 'Benutzerzuordnung nicht erfolgt oder ungültig',
        AxiosError401Item: 'Benutzerzuordnung nicht erfolgt oder ungültig für {item}',
        AxiosError403: 'Der Benutzer hat nicht die erforderlichen Berechtigungen für den Zugriff auf PT9800-Daten',
        AxiosError403Item: 'Der Benutzer hat nicht die erforderlichen Berechtigungen für den Zugriff auf PT9800-Daten für {item}',
      },
    },
    fileGeneration: {
      summary: 'Datei wird generiert',
      detail: 'Die generation von Datei kann etwas länger dauern. Bitte lassen Sie das Browserfenster ofen. Diese Nachricht schließt sich automatisch nachdem der Report erstellt wurde.',
    },
    defaultError: 'Fehler',
    update: 'Aktualisieren',
    create: 'Erstellen',
    createCopy: 'Kopie erstellen',
    delete: 'Löschen',
    save: 'Speichern',
    saveItem: '{item} speichern',
    saveAsNew: 'Speichern unter ...',
    yes: 'Ja',
    no: 'Nein',
    export: 'Export',
    import: 'Importieren',
    search: 'Suche',
    selected: 'Ausgewählt',
    back: 'Zurück',
    forward: 'Vorwärts',
    accept: 'Akzeptieren',
    print: 'Drucken',
    download: '{item} Herunterladen',
    sensorSuffix: {
      actual: '(ACT)',
      setpoint: '(SET)',
    },
    submit: 'Submit',
    success: 'Erfolg',
    fail: 'Scheitern',
    close: 'Schließen',
    from: 'von',
    to: 'bis',
    month: {
      [EMonth.JANUARY]: {
        long: 'Januar',
        short: 'Jan',
      },
      [EMonth.FEBRUARY]: {
        long: 'Februar',
        short: 'Feb',
      },
      [EMonth.MARCH]: {
        long: 'März',
        short: 'Mär',
      },
      [EMonth.APRIL]: {
        long: 'April',
        short: 'Apr',
      },
      [EMonth.MAY]: {
        long: 'Mai',
        short: 'Mai',
      },
      [EMonth.JUNE]: {
        long: 'Juni',
        short: 'Jun',
      },
      [EMonth.JULY]: {
        long: 'Juli',
        short: 'Jul',
      },
      [EMonth.AUGUST]: {
        long: 'August',
        short: 'Aug',
      },
      [EMonth.SEPTEMBER]: {
        long: 'September',
        short: 'Sep',
      },
      [EMonth.OCTOBER]: {
        long: 'Oktober',
        short: 'Okt',
      },
      [EMonth.NOVEMBER]: {
        long: 'November',
        short: 'Nov',
      },
      [EMonth.DECEMBER]: {
        long: 'Dezember',
        short: 'Dez',
      },
    },
    time: {
      second: 'Sekunde | Sekunden',
      day: 'Tag | Tage',
      week: 'Woche | Wochen',
      month: 'Monat | Monate',
      quarter: 'Quartal | Quartale',
      year: 'Jahr | Jahre',
    },
    timeAbbreviation: {
      days: 'd',
      hours: 'h',
      minutes: 'min',
      months: 'm',
      weeks: 'KW',
      years: 'a',
    },
    sum: 'Summe',
    average: {
      long: 'Durchschnitt',
      short: 'Avg',
    },
    reset: 'Zurücksetzen',
    comment: 'Bemerkung',
    none: 'None',
    copy: 'Kopieren',
    copyNoun: 'Kopie',
  },
  views: {
    loadList: {
      selectLoad: {
        selectPartButton: 'Teil auswählen',
        addPartButton: 'Teil hinzufügen',
      },
    },
  },
  component: {
    qualityForm: {
      jobSelection: 'Job auswählen',
      partSelection: 'Teil auswählen',
    },
    furnaceViewRecipe: {
      stopConfirm: 'Sind Sie sicher, dass Sie das Rezept "{0}" stoppen möchten?',
      startConfirm: 'Sind Sie sicher, dass Sie das Rezept "{0}" starten möchten?',
      modifyConfirm: 'Sind Sie sicher, dass Sie das Rezept "{0}" ändern möchten?',
    },
    createUser: {
      headline: 'Benutzer erstellen',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      role: 'Rolle',
      permissionsCheckbox: 'Benutzerdefinierte Berechtigungen',
      appPermissions: 'App Berechtigungen',
      assetPermissions: 'Asset Berechtigungen',
    },
    pt9800RecipeForm: {
      recipeName: 'Rezeptname',
      totalDuration: 'Gesamtdauer',
      processSteps: 'Prozessschritte',
      generalHeader: 'Allgemeine Informationen',
      diffusionModel: 'Diffusionsmodell',
      version: 'Version',
      parameters: 'Parameter',
      profile: 'Profil',
      recipeExists: 'Ein Rezept mit diesem Namen existiert bereits.',
    },
    userSettingsModal: {
      title: 'User settings',
      language: 'Language',
      timeFormat: 'Time format',
    },
    editOrCreateRoleModal: {
      editHeadline: 'Rolle bearbeiten',
      createHeadline: 'Rolle erstellen',
      appPermission: 'App-Berechtigungen',
      assetPermission: 'Asset-Berechtigungen',
    },
    appAndAssetPermissionTree: {
      appPermission: 'App-Berechtigungen',
      assetPermission: 'Asset-Berechtigungen',
    },
    rootCauseLinkModal: {
      title: 'Link root cause',
    },
    sensorTable: {
      tableHeader: 'Asset-Signale',
      showHidden: 'Versteckte Asset-Signale anzeigen',
      dataFormat: 'Typ',
    },
    materialSelect: {
      placeholder: 'Material auswählen',
      unit: 'Materialien',
    },
    materialGroupTable: {
      tableHeader: 'Material groups',
    },
    dashboardPanel: {
      add: 'Panel hinzufügen',
      defaultTitle: 'Mein Panel',
    },
    presetSelection: {
      modal: {
        defaultTile: 'Mein Preset',
      },
    },
    userUpdateModal: {
      tab: {
        permissionList: 'Berechtigungen',
        controllerUserMapping: 'Controller-Benutzerzuordnung',
      },
      permissionList: 'Berechtigungsliste',
      groupList: 'Gruppenliste',
      selectPT9800: 'PT9800-Benutzer auswählen',
      enterCredentials: 'Anmeldeinformationen eingeben',
    },
    loadModal: {
      startingLoad: 'Job wird gestartet...',
      confirmPartHint: 'Bitte bestätigen Sie, dass alle Teile geladen sind',
      confirmStart: 'Bestätigen Sie den Start des Jobs.',
      workplaceWoNumber: generalTerm.loadIdentifier,
      createOrUpdate: 'Job plannen',
      description: 'Planen Sie Ihre nächste Ladung, indem Sie Ihre',
      addTraveller: 'Neuen Reisenden hinzufügen',
      emptyPartsTable: 'Keine Teile hinzugefügt',
      partNotFound: 'Teil mit der ID "{id}" wurde nicht gefunden',
      partDoNotMatch: 'Nicht alle Teile entsprechen dem Rezept',
      readyToScan: 'Bereit zum Scannen',
    },
    rootCauseModal: {
      create: 'Ursache erstellen',
      update: 'Ursache aktualisieren',
    },
    rootCauseGroupModal: {
      create: 'Ursachengruppe erstellen',
      update: 'Ursachengruppe aktualisieren',
    },
    counterActionModal: {
      create: 'Gegenmaßnahme erstellen',
      update: 'Gegenmaßnahme aktualisieren',
    },
    counterActionTable: {
      create: 'Gegenmaßnahme erstellen',
      confirmDelete: 'Gegenmaßnahme "{counterActionName}" löschen?',
      confirmUnlink: 'Ursache "{rootCauseName}" von Gegenmaßnahme "{counterActionName}" trennen?',
    },
    rootCauseTable: {
      create: 'Ursache erstellen',
      confirmDelete: 'Ursache "{rootCauseName}" löschen?',
      confirmUnlink: 'Gegenmaßnahme "{counterActionName}" von Ursache "{rootCauseName}" trennen?',
    },
    rootCauseGroupTable: {
      create: 'Ursachengruppe erstellen',
      confirmDelete: 'Ursachengruppe "{rootCauseGroupName}" löschen?',
      confirmUnlink: 'Ursache "{rootCauseName}" von Ursachengruppe "{rootCauseGroupName}" trennen?',
    },
    observationTypeTable: {
      unlinkConfirm: 'Ursache "{rootCauseName}" von Beobachtungstyp "{observationTypeName}" trennen?',
    },
    productionLogsTable: {
      heatTreatmentProcess: 'Wärmebehandlungsprozess',
      hasAlarms: 'Hat Alarme',
      tab: {
        table: 'Tabelle',
        chart: 'Diagramm',
      },
    },
    dateRangePicker: {
      currentWeek: 'Aktuelle Woche',
      custom: 'Benutzerdefiniert',
      absolute: 'Absolut',
      customRange: 'Zeitraum',
      relative: 'Relativ',
      timeUnit: 'Zeiteinheit',
      relativeMinDateError: 'Das Datum darf nicht vor dem {0} liegen',
      operatingAbdoluteErrorMessage: 'Bitte wählen Sie ein Datum innerhalb des angegebenen Bereichs: {min} - {max}',
    },
    specificationModal: {
      header: 'Specification',
      specificationSubmitSuccess: 'Specification saved',
      error: {
        envelopeInvalid: 'Envelope is invalid',
        emptyZones: 'Zones cannot be empty',
        nameMissing: 'Specification must have name',
      },
    },
    treeSidebarTemplate: {
      defaultTitle: 'Asset Navigation',
    },
    TableJobStages: {
      header: 'Rezeptstufen',
    },
    observationView: {
      header: 'Beobachtungen',
    },
    furnacePanel: {
      status: 'Status',
      process: 'Load No',
      recipeName: 'Rezeptname',
      stageNumber: 'Stufennummer',
      stageName: 'Stufenname',
      activeAlarms: 'Aktive Alarme',
      furnaceStatus: {
        IDLE: 'Leerlauf',
        MAINTENANCE: 'Wartung',
        OFFLINE: 'Aus',
        RUNNING: 'In Betrieb',
        RUNNING_NO_ALARMS: 'In Betrieb',
        RUNNING_ALARM: 'Alarm',
        RUNNING_EMERGENCY: 'Notfall',
        RUNNING_NORMAL: 'Info',
        RUNNING_WARNING: 'Warnung',
        LOADING: 'Wird geladen...',
        LOADING_ERROR: 'Fehler beim Laden',
      } as Record<TFurnacePanelStatus, string>,
    },
    alarmView: {
      header: 'Alarme',
      empty: 'Keine Alarme gefunden',
    },
    accordionAudit: {
      envelopeNamePlaceholder: 'Grenzenname',
    },
    page: {
      performanceDashboard: {
        stackedPolarChart: {
          title: 'Productivity (Polar)',
        },
        clusteredStackedBarChart: {
          title: 'Productivity (Clustered Stacked bar)',
        },
        table: {
          title: 'Productivity (Table)',
        },
        stackedBarChart: {
          title: 'Productivity (Stacked bar)',
        },
        paretoChart: {
          title: 'Productivity (Pareto)',
        },
        visualisationModal: {
          title: 'Anzeigeeinstellungen',
          subtitle: 'Wählen Sie die Diagramme aus, die Sie auf dem Dashboard anzeigen möchten, aus den unten stehenden Listen aus.',
          aggregationTitle: 'Aggregation',
          aggregationDescription: 'Diese Diagramme zeigen die Effizienz für den gesamten ausgewählten Zeitraum an.',
        },
      },
      productionLog: {
        presetEditForm: {
          saveSuccessMessage: 'Ihr Filter wurde gespeichert.',
          deleteConfirmation: 'Möchten Sie den Filter löschen?',
          deleteSuccessMessage: 'Filter erfolgreich gelöscht',
        },
      },
      maintenanceCalendar: {
        maintenanceCalendarTemplate: {
          routeName: {
            [ERouteName.MAINTENANCE_CALENDAR]: 'Kalender',
            [ERouteName.MAINTENANCE_CALENDAR_REPORT_LIST]: 'Berichte',
            [ERouteName.MAINTENANCE_CALENDAR_JOB_LIST]: 'Jobs',
            [ERouteName.MAINTENANCE_CALENDAR_TASK_LIST]: 'Tasks',
          },
        },
      },
      quality: {
        draft: {
          steps: {
            loadSelection: 'Job auswählen',
            partSelection: 'Teil auswählen',
            sampling: 'Probenahme',
          },
        },
      },
    },
    processChartComponent: {
      treeView: {
        deselectAll: 'Alles abwählen',
      },
      defaultCustomConfigName: 'New unsaved custom config',
      deselect: 'Deselect All',
      presetConfigModal: {
        configMapPlaceholder: 'Select signal to edit',
        presetConfigSaveSuccess: 'Preset config saved',
        presetNameLabel: 'Preset name',
        presetSaveSuccess: 'Preset saved',
      },
      presetEdit: 'Select preset to edit',
      signalHintList: 'Sensor-Datenvergleich',
    },
    maintenanceCalendarJobModal: {
      titleCreate: 'Wartungsjob erstellen',
      titleEdit: 'Wartungsjob bearbeiten',
      createTask: 'Aufgabe erstellen',
    },
    maintenanceCalendarTaskModal: {
      titleCreate: 'Arbeitsschritt erstellen',
      titleEdit: 'Arbeitsschritt bearbeiten',
    },
    maintenanceOccurrenceTable: {
      occurrenceListTitle: 'Wartungsaufträge',
      hideCompletedEvents: 'Abgeschlossene Aufträge ausblenden',
    },
    maintenanceServiceReportModal: {
      finish: 'Wartungsauftrag beenden',
      reject: 'Wartungsauftrag ablehnen',
      rejectLabel: 'Bitte geben Sie einen Grund für die Ablehnung an.',
    },
    equipmentDocumentUploadModal: {
      headline: 'Dokumente hochladen',
      categoryTitle: 'Kategorie',
      descriptionTitle: 'Beschreibung',
      uploadFilesTitle: 'Dateien hochladen',
      errorMessage: 'Datei existiert bereits',
    },
    PartAttribute: {
      headline: 'Part settings',
      subheadline: 'Die in dieser Tabelle definierten Attribute werden bei der Erstellung/Änderung von Teilen abgefragt.',
    },
    materialFilter: {
      multiselectPlaceholder: 'Filtern nach Material',
      unit: 'Materials',
    },
    partForm: {
      partSpecification: 'Teilespezifikation',
      treatmentInformation: 'Information zur Behandlung',
      treatmentRemark: 'Anmerkung zur Behandlung',
      assetsAndRecipes: 'Qualifiziertes Assete & Rezepte',
    },
    expressionMeaningSelection: {
      onlyMapped: 'Show mapped meanings only',
    },
    expressionPreviewModal: {
      title: 'Neues virtuelles Signal',
    },
    specificationSaveModal: {
      nameLabel: 'Name',
      nameDescription: 'Beschreibung',
    },
  },
  confirmationMessages: {
    componentAdded: 'Die Komponente wurde hinzugefügt',
    componentUpdated: 'Komponente {component} wurde erfolgreich aktualisiert',
    confirmDelete: 'Möchten sie {name} löschen?',
    deleteComponent: 'Möchten Sie löschen {part}?',
    deleteConnection: 'Möchten sie diese Verbindung Löschen?',
    deleteFile: 'Möchten Sie diese Datei löschen?',
    deleteMaterial: 'Möchten Sie {material} löschen?',
    deletePart: 'Möchten Sie das Teil {name} löschen?',
    deleteRecipe: 'Möchten Sie das Rezept {name} löschen?',
    deleteRootCauseLink: 'Möchten Sie die Verknüpfung von Grundursache {rootCause} wirklich aufheben?',
    deleteRootCauses: 'Möchten Sie die {rootCauses} löschen?',
    deleteStage: 'Möchten Sie {name} löschen?',
    deleteUser: 'Möchten Sie den Benutzer {Benutzername} löschen?',
    deletedAlready: 'Benutzer {username} wurde bereits gelöscht',
    deletedComponent: 'Die Komponente wurde gelöscht',
    deletedPart: 'Das Teil wurde gelöscht',
    deletedRecipe: 'Das Rezept wurde gelöscht',
    deletedSparePart: 'Ersatzteil wurde gelöscht',
    deletedUser: 'Benutzer {Benutzername} wurde gelöscht',
    equipmentAdded: 'Asset erfolgreich hinzugefügt',
    equipmentEdited: 'Ausrüstung erfolgreich bearbeitet',
    equipmentUpdated: 'Asset {equipment} wurde erfolgreich aktualisiert',
    materialAdded: 'Material erfolgreich hinzugefügt',
    materialUpdated: 'Material erfolgreich aktualisiert',
    materialsGroupAdded: 'Materialgruppe erfolgreich hinzugefügt',
    materialsGroupUpdated: 'Materialgruppe erfolgreich aktualisiert',
    npmControllerJobs: 'Bitte bestätigen Sie das {action} des Rezeptes {recipeName} im Ofen {furnaceId} ({location})',
    partAdded: 'Teil erfolgreich hinzugefügt',
    recipeAdded: 'Rezept erfolgreich hinzugefügt',
    registrationAlreadyConfirmed: 'Benutzer {Benutzername} hat die Registrierung bereits bestätigt',
    registrationEmailResend: 'Die Registrierungs-E-Mail wurde erneut an {Benutzername} gesendet.',
    resendUserEmail: 'Möchten Sie die E-Mail zur Benutzerregistrierung erneut an {Benutzername} senden?',
    restoreRecipe: 'Bitte bestätigen Sie die Wiederherstellung von {recipe} Rezept?',
    saveMaterials: 'Möchten Sie Material hinzufügen?',
    savePart: 'Möchten Sie einen Teil speichern?',
    saveRecipe: 'Bitte bestätigen Sie die Speicherung des Rezepts {recipe}?',
    sensorCopied: 'Sensorzuordnung wurde erfolgreich kopiert',
    sensorNotCopied: 'Sensorzuordnung konnte nicht kopiert werden',
    sparePartAdded: 'Ersatzteile erfolgreich hinzugefügt',
    sparePartUpdated: 'Ersatzteil {sparePart} erfolgreich aktualisiert',
    userAdded: 'Benutzer {user} hinzugefügt',
    userTilesUpdated: 'Apps für {user} erfolgreich aktualisiert',
  },
  consumables: {
    currMonth: 'Aktueller Monat',
    forecast: 'Prognose',
    prevMonth: 'Vorheriger Monat',
  },
  customerSettings: {
    actions: 'Aktionen',
    errorMsg: 'Falscher Wert!',
    currencyError: 'Die Währung sollte aus 3 Buchstaben der Währung bestehen, z.B.: EUR, USD',
    hourlyBillingRate: 'Abrechnungssatz pro Stunde',
    hourlyDepreciationRate: 'Abschreibungssatz pro Stunde',
    hoursPerShift: 'Stunden pro Schicht',
    plannedOutageDaysPerYear: 'Geplante Stillstände pro Jahr (in Tagen)',
    rateCurrency: 'Währung',
    workingShiftsPerWeek: 'Schichten pro Woche',
  },
  dateRangePicker: {
    currentWeek: 'Aktuelle Woche',
    custom: 'Benutzerdefinierte',
    days: 'Tage',
    months: 'Monate',
    weeks: 'Wochen',
    years: 'Jahre',
  },
  details: {
    chart: 'Grafik',
    images: 'Bilder',
  },
  dialog: {
    saveChangesConfirm: 'Wollen Sie die Aenderungen speichern ?',
    saveChangesFailed: 'Die Änderungen konnten nicht gespeichert werden.',
  },
  durationFormat: {
    withValue: {
      minute: '{0} @:durationFormat.minute',
      hour: '{0} @:durationFormat.hour',
      day: '{0} @:durationFormat.day',
      week: '{0} @:durationFormat.week',
      month: '{0} @:durationFormat.month',
      year: '{0} @:durationFormat.year',
    },
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tage',
    week: 'Woche | Wochen',
    month: 'Monat | Monate',
    year: 'Jahr | Jahre',
  },
  errorMessages: {
    componentAdded: 'Die Komponente ist nicht hinzugefügt worden',
    componentUpdated: 'Die Komponente wurde nicht aktualisiert',
    credentials: 'Falscher Benutzername oder Passwort',
    deletedUser: 'Benutzer {Benutzername} wurde seit der letzten Aktualisierung der Seite gelöscht',
    equipmentAdded: 'Das Asset ist nicht hinzugefügt worden',
    equipmentEdited: 'Das Gerät wurde nicht bearbeitet',
    equipmentUpdated: 'Das Asset wurde aktualisiert',
    equipmentViewError: 'Keine Ansicht für das angegebene Asset gefunden',
    error: 'Fehler',
    furnaceNotAvailable: 'Ansicht nicht verfügbar für diesen Ofentyp',
    generalMessage: 'Etwas ist schief gelaufen. Versuchen Sie es später nochmal',
    materialNotAdded: 'Das Material wurde nicht hinzugefügt',
    materialNotUpdated: 'Das Material wurde nicht aktualisiert',
    materialsGroupAdded: 'Die Materialgruppe wurde nicht hinzugefügt',
    materialsGroupUpdated: 'Die Materialgruppe wurde nicht aktualisiert',
    noEquipmentSelected: 'Bitte wählen Sie in Asset aus der Asset Navigation auf der linken Seite aus um Details zu sehen',
    oauth2Fail: 'Oauth2 fehlgeschlagen',
    outOfRangeTemperature: 'Die Temperatur liegt außerhalb des zulässigen Bereichs: -200°C - 850°C (-328°F - 1562°F). Bitte passen Sie den RG oder RL Wert an',
    partNotAdded: 'Das Teil wurde nicht hinzugefügt',
    recipeNotAdded: 'Das Rezept wurde nicht hinzugefügt',
    serverMessage: 'Server Probleme, versuchen Sie es später nochmal',
    unauthorized: 'Unberechtigt',
  },
  filters: {
    dateFrom: 'Datum von',
    dateTo: 'Datum bis',
    filter: 'Filter',
    filterSensors: 'Sensoren filtern',
    savedFilters: 'Gespeicherte Filter',
  },
  footer: {
    privacyPolicy: 'Datenschutzrichtlinie',
  },
  forms: {
    JobValue: 'Chargenwert',
    addNewPlant: 'Standort hinzufügen',
    addNewUser: 'Neuen Benutzer hinzufügen',
    additionalInformation: 'Weitere Informationen',
    assetId: 'Asset nummer',
    configureValue: 'Wert konfigurieren',
    createRootCause: 'Ursachen erstellen',
    credentials: 'Zugangsdaten',
    credentialsValid: 'Zugangsdaten gültig',
    customerName: 'Customer Name',
    customerRequestAdded: 'Die File wurde hochgeladen',
    delete: 'Datei gelöscht',
    department: 'Abteilung',
    depth: 'Tiefe',
    derivedValues: 'Abgeleitete Werte',
    description: 'Beschreibung',
    diameter: 'Durchmesser',
    dimensionsOfLoad: 'Abmessungen der Ladung',
    documentsCategoryCreated: 'Kategorie hinzugefügt',
    editMaterial: 'Material bearbeiten',
    editMaterialGroup: 'Materialgruppe bearbeiten',
    editRootCause: 'Grundursachen bearbeiten',
    editUser: 'Aktualisier Nutzer {user}',
    email: 'E-Mail',
    fileUploaded: 'Die Datei wurde hochgeladen',
    filterCriteria: '4 Filterkriterien',
    fullName: 'Vollständiger Name',
    furnaceClass: 'Klasse der Öfen',
    furnaceName: 'Name des Ofens',
    heatTreatment: 'AMS2759: Wärmebehandlung von Stahlteilen',
    height: 'Höhe',
    host: 'Host',
    information: 'Informationen',
    instrumentationType: 'Art der Instrumentierung',
    jobFeatures: 'Chargeneigenschaften',
    jobValue: 'Load Value',
    jobValues: 'Chargenwert',
    locationName: 'Standort Name',
    logValues: 'Log-Werte',
    malfunctionStartDate: 'Beginn der Störung',
    manufacturer: 'Hersteller',
    manufacturerPartNumber: 'Original-Teilenummer',
    mapTiles: 'Benutzerzuordnung: Controller',
    maxFlow: 'Max Flow',
    maxLoad: 'Max Load',
    maxTemp: 'Max Temp',
    minTemp: 'Min Temp',
    name: 'Name',
    newMaterial: 'Neues Material',
    newMaterialGroup: 'Neue Materialgruppe',
    otherGasses: 'Andere Fähigkeiten',
    password: 'Passwort',
    persistCredentials: 'Zugangsdaten speichern',
    plantName: 'Name',
    port: 'Port',
    predictions: 'Vorhersagen',
    pyrometry: 'AMS2750: Pyrometry',
    requestCategory: 'Kategorie',
    requiredEndDate: 'Benötigter Endtermin',
    selectBatchesOrTimePeriod: '3 Wählen Sie Chargen oder Zeitraum aus',
    selectFurnace: 'Kopieren der Sensorzuordnung vom aktuellen Ofen für den Ofen:',
    selectedBatchesOrTimePeriod: '3 ausgewählte Chargen oder Zeiträume',
    selectedLocationsAndAssets: '1 Ausgewählte Standorte und Assets',
    selectedValue: '2 Ausgewählter Wert',
    spareParts: 'Ersatzteile',
    stateChanged: 'Der Status wurde geändert',
    ticketStatus: 'Ticket-Status',
    type: 'Typ',
    uploadCategory: 'Kategorie auswählen oder erstellen',
    uploadEquipment: 'Asset aktualisieren',
    uploadFile: 'Dateien hochladen',
    uploadFiles: 'Dateien hochladen',
    uploadedFiles: 'Hochgeladene Dateien',
    username: 'Benutzername',
    width: 'Breite',
  },
  furnaceStatus: {
    alarm: 'Alarm',
    disconnected: 'Nicht verbunden',
    emergencyProcedure: 'Verfahren für Notfälle',
    idle: 'Leerlauf',
    noRemoteConnection: 'Keine Remote-Verbindung',
    running: 'Laufendes Rezept',
    switchedOff: 'Ausgeschaltet',
  },
  global: {
    all: 'Alle',
    comment: 'Bemerkung',
    order: 'Auftrag',
    part: 'Teil',
    workplace: 'Arbeitsplatz',
  },
  hubspotTicketStatus: {
    1: 'Neu',
    2: 'Warten auf Kontakt',
    3: 'Warten auf uns',
    4: 'Geschlossen',
    5: 'Entfernt',
    34633361: 'Neu',
    34633362: 'In Bearbeitung',
    34633364: 'Geschlossen',
  },
  images: {
    addImage: 'Bild hinzufügen',
    delete: 'Löschen',
    download: 'Herunterladen',
    images: 'Bilder',
    maximumNumberOfImages: 'Sie können bis zu 4 Bilder hinzufügen',
    noImages: 'Keine Bilder',
    print: 'Drucken',
    save: 'Speichern',
    upload: 'Hochladen',
    uploadImageDescription: 'Laden Sie ein Bild hoch',
    zoom: 'Zoom',
  },
  inputLabels: {
    from: 'von',
    groupname: 'Name der Gruppe',
    searchRecipe: 'Rezept suchen',
    to: 'bis',
  },
  kpis: {
    cost_avg_ammonia: 'Cost Avg Ammonia',
    cost_avg_carbon_dioxide: 'Cost Avg Carbon Dioxide',
    cost_avg_co_gas: 'Cost Avg CO gas',
    cost_avg_dissociated_ammonia: 'Cost Avg Dissociated Ammonia',
    cost_avg_electricity: 'Cost Avg Electricity',
    cost_avg_endo_gas: 'Cost Avg Endo Gas',
    cost_avg_h2: 'Cost Avg H2',
    cost_avg_nitrogen: 'Cost Avg Nitrogen',
    cost_stddev_ammonia: 'Kosten-Standardabweichung Ammoniak',
    cost_stddev_carbon_dioxide: 'Kosten-Standardabweichung Kohlendioxid',
    cost_stddev_co_gas: 'Kosten-Standardabweichung CO Gas',
    cost_stddev_dissociated_ammonia: 'Kosten-Standardabweichung dissoziiertes Ammoniak',
    cost_stddev_endo_gas: 'Kosten-Standardabweichung Endo Gas',
    cost_stddev_h2: 'Kosten-Standardabweichung H2',
    cost_stddev_nitrogen: 'Kosten-Standardabweichung Nitrogen',
    cost_sum_ammonia: 'Cost Sum Ammonia',
    cost_sum_carbon_dioxide: 'Cost Sum Carbon Dioxide',
    cost_sum_co_gas: 'Cost Sum CO gas',
    cost_sum_dissociated_ammonia: 'Cost Sum Dissociated Ammonia',
    cost_sum_electricity: 'Cost Sum Electricity',
    cost_sum_endo_gas: 'Cost Sum Endo Gas',
    cost_sum_h2: 'Cost Sum H2',
    cost_sum_nitrogen: 'Cost Sum Nitrogen',
    time_avg_load_duration: 'Avg Duration',
    time_max_start_date: 'Start date latest',
    time_min_start_date: 'Start date oldest',
    time_stddev_load_duration: 'Standardabweichung Zeit',
    time_sum_load_duration: 'Sum Duration',
    value_availability: 'Ofen Verfügbarkeit',
    value_avg_ammonia: 'Avg Ammonia',
    value_avg_carbon_dioxide: 'Avg Carbon Dioxide',
    value_avg_co_gas: 'Avg CO gas',
    value_avg_dissociated_ammonia: 'Avg Dissociated Ammonia',
    value_avg_electricity: 'Avg Electricity',
    value_avg_endo_gas: 'Avg End Endo Gas',
    value_avg_h2: 'Avg H2',
    value_avg_nitrogen: 'Avg Nitrogen',
    value_cnt_batch: 'Chargenanzahl',
    value_furnace_brutto_productivity: 'Ofen Produktivität (brutto)',
    value_furnace_netto_productivity: 'Ofen Produktivität (Netto)',
    value_stddev_ammonia: 'Standardabweichung Ammoniak',
    value_stddev_carbon_dioxide: 'Standardabweichung Kohlendioxid',
    value_stddev_co_gas: 'Standardabweichung CO Gas',
    value_stddev_dissociated_ammonia: 'Standardabweichung dissoziiertes Ammoniak',
    value_stddev_endo_gas: 'Standardabweichung Endo Gas',
    value_stddev_h2: 'Standardabweichung H2',
    value_stddev_nitrogen: 'Standardabweichung Nitrogen',
    value_sum_ammonia: 'Sum Ammonia',
    value_sum_carbon_dioxide: 'Sum Carbon Dioxide',
    value_sum_co_gas: 'Sum CO gas',
    value_sum_dissociated_ammonia: 'Sum Dissociated Ammonia',
    value_sum_electricity: 'Sum Electricity',
    value_sum_endo_gas: 'Sum End Endo Gas',
    value_sum_h2: 'Sum H2',
    value_sum_nitrogen: 'Sum Nitrogen',
  },
  levels: {
    critical: 'Kritisch',
    high: 'Hoch',
    low: 'Niedrig',
    medium: 'Mittel',
  },
  loadList: {
    chargeId: 'Ch-Kennung',
    sortingTime: 'Anzeige-Reihenfolge',
    statusSequence: 'Status der Chargenfolge',
  },
  localizationDialogs: {
    '12hourTime': '12 Stunden Zeit',
    '24hourTime': '24 Stunden Zeit',
    decimalComma: 'Komma',
    decimalPoint: 'Punkt',
  },
  maintenanceDashboard: {
    activeAlarms: 'Aktive Alarme',
    alarmsFrequency: 'Häufigkeit der Alarme',
    plannedTasks: 'Geplante Wartungsaufgaben',
    rollingAverageSize: 'Größe der Teilmenge des gleitenden Durchschnitts',
    showAlarms: 'Alarm-Statistik',
    showRollingAverage: 'Gleitenden Durchschnitt anzeigen',
    showShewhartGraph: 'Shewhart-Graph anzeigen',
  },
  maintenanceDetails: {
    noDatabaseForFurnace: 'Asset hat keine Datenbank',
  },
  maintenanceRecurrencePeriod: {
    [EMaintenanceRecurrencePeriod.NEVER]: 'Niemals',
    [EMaintenanceRecurrencePeriod.WEEKLY]: 'Wöchentlich',
    [EMaintenanceRecurrencePeriod.MONTHLY]: 'Monatlich',
    [EMaintenanceRecurrencePeriod.YEARLY]: 'Jährlich',
  },
  maintenanceServiceTab: {
    [EMaintenanceServiceTab.ATTACHMENTS]: 'Anhänge',
    [EMaintenanceServiceTab.DETAILS]: 'Details',
    [EMaintenanceServiceTab.REPORT]: 'Bericht',
  },
  materials: {
    elements: 'Elemente',
    filterGroups: 'Materialgruppe filtern',
    materialElements: 'Material Elemente',
    materialGroup: 'Materialgruppe',
    materialGroups: 'Materialgruppen',
    searchMaterialGroup: 'Materialgruppe suchen',
    searchMaterials: 'Materialien suchen',
  },
  modals: {
    imagesOf: 'Bilder zu {entity}',
  },
  modelProcess: {
    STAGE_WISE_TUNNEL: 'Phasenbezogener Tunnel',
    STATIC_TUNNEL: 'Statischer Tunnel',
    createModel: 'Modellprozess erstellen',
    createNewModel: 'Neuen Modellprozess erstellen',
    creationPage: 'Erstellungsseite anzeigen',
    deleteModelProcess: 'Wollen Sie Model Process wirklich löschen {model}?',
    modelDescription: 'Beschreibung des Modellprozesses',
    modelProcessName: 'Modellprozessname',
    notAllGood: 'Nicht alle für den Modellprozess ausgewählten Chargen wurden als gut eingestuft',
    removeProcess: 'Modellprozess entfernen',
    tunnelType: 'Tunnel Typ',
    viewProcess: 'Modellprozess anzeigen',
  },
  modifyMenu: {
    addNew: 'Neuer {0}',
    createNew: 'Neuen Hintergrundprozess erstellen',
    delete: 'Löschen',
    edit: 'Ändern',
    print: 'Drucken',
    saveAs: 'Speichern unter',
  },
  monitoringDashboard: {
    consumables: 'Verbräuche',
    furnaceState: 'Status',
    productivity: 'Produktivität',
    sankeyChartLoadingError: 'Fehler beim Laden der Daten. Überprüfen Sie die Asset Einstellungen',
  },
  nitrexConnect: {
    dashboard: 'Dashboard',
    description: 'Beschreibung',
    endCondition: 'Abschlussbedingung',
    furnPress: 'Ofendruck',
    host: 'Host',
    kn: 'Kɴ',
    mainTCSetValue: 'Main TC SP',
    mainTc: 'Main TC',
    port: 'Port',
    process: 'Prozess',
    processTime: 'Prozessdauer',
    stageName: 'Name des Behandlungsschrittes',
    stageNumber: 'Nummer des Behandlungsschrittes',
    stageTime: 'Dauer des Behandlungsschrittes',
    status: 'Status',
    time: 'Zeit',
    totalFlow: 'Durchfluss (gesamt)',
  },
  notes: {
    add: 'Notiz hinzufügen',
    author: 'Autor:',
    edit: 'Notiz bearbeiten',
    noAuthor: 'Schreiben Sie hier Ihre Notiz',
    timestamp: 'Zeitstempel: ',
  },
  notifications: {
    noPending: 'Keine Benachrichtigungen',
  },
  npmController: {
    currentTimeOnSystem: 'Aktuelle Zeit im System',
    customerCode: 'Kundencode',
    jobNumber: 'Job Number:',
    jobStartTime: 'Startzeit des Auftrags',
    loadJob: 'Job laden',
    loadRecipe: 'Rezept laden',
    loaded: 'Geladen',
    loadedBy: 'Geladen von',
    none: 'Nichts ausgewählt',
    operator: 'Betreiber',
    partCount: 'Anzahl der Teile',
    partNumbers: 'Teilenummer(n)',
    process: 'Process:',
    processStartTime: 'Process Start time:',
    processTime: 'Process Time:',
    refresh: 'Auffrischen',
    resumeJob: 'Job fortsetzen',
    schedule: 'Zeitplan',
    selectedProcess: 'Ausgewählter Prozess',
    shopOrder: 'Shop Bestellung',
    stageName: 'Stage Name:',
    stageNumber: 'Stage Number:',
    stageTime: 'Stage Time:',
    startJob: 'Start job',
    status: 'Status:',
    abortJob: 'Job abbrechen',
    subSystemId: 'Subsystem-id',
    systemName: 'System Name:',
  },
  operationDashboard: {
    AMMONIA_FLOW: 'Ammoniak Durchfluss',
    C2H2_FLOW: 'Acetylen Durchfluss',
    C2H4_FLOW: 'Ethylen Durchfluss',
    C2H6_FLOW: 'Ethan Durchfluss',
    C3H8_FLOW: 'Propan Durchfluss',
    CH4_FLOW: 'Methan Durchfluss',
    CO2_FLOW: 'Kohlendioxid Durchfluss',
    CO_FLOW: 'Kohlenmonoxid Durchfluss',
    CP: 'C Potenzial',
    C_POTENTIAL: 'C-Potenzial',
    FURNACE_PRESSURE: 'Ofendruck',
    FURNACE_TEMPERATURE: 'Ofen Temperatur',
    H2_FLOW: 'Wasserstoff Durchfluss',
    KN_POTENTIAL: 'Kn-Potenzial',
    TEMPERATURE: 'Temperatur',
    expCompletionTime: 'Voraussichtliche Fertigstellungszeit',
    finishedJobs: 'Chargen',
    ganttView: 'Gantt Diagramm',
    hoursUntilCompletion: 'Stunden bis zur Fertigstellung',
    jobs: 'Chargen',
    listView: 'Listenansicht',
    missingSensorsMapping: 'Nicht zugeordnete Sensoren',
    mustSelectLocation: 'Wählen Sie einen Standort oder eine Asset, um fortzufahren',
    noKpiData: 'Keine KPI-Daten',
    recipeName: 'Rezeptname',
    recipeStatus: 'Rezeptstatus',
    status: 'Status',
    tableView: 'Tabellenansicht',
    tileView: 'Kachelansicht',
    workOrderNumber: 'Auftragsnummer',
  },
  page: {
    notification: {
      title: 'Meldungen',
    },
    customerDetails: {
      title: 'Customer details',
    },
    customerEdit: {
      title: 'Edit customer',
    },
    customerCreate: {
      title: 'Create customer',
    },
    userManagement: {
      manageRole: 'Rollen verwalten',
      headline: 'Benutzer ({0})',
      addUser: 'Benutzer hinzufügen',
      customPermissions: 'Benutzerdefinierte Zugriffsrechte',
      searchPlaceholder: {
        name: 'Suche nach Name',
        email: 'Suche nach E-Mail',
        role: 'Suche nach Rolle',
      },
    },
    roleManagement: {
      roles: 'Rollen',
      createRole: 'Rolle erstellen',
      appPermission: 'App-Berechtigungen',
      assetPermission: 'Asset-Berechtigungen',
    },
    pt9800RecipeDetails: {
      general: 'Allgemein',
      steps: 'Schritte',
    },
    home: {
      title: 'Startseite',
      description: 'Willkommen bei einer neuen cloudbasierten Lösung, die darauf ausgelegt ist, Ihnen zu helfen, jeden Ihrer Standorte effizient aus der Ferne zu verwalten.',
    },
    releaseNotes: {
      title: 'Versionshinweise',
    },
    privacyPolicy: {
      title: 'Datenschutzrichtlinie',
    },
    userManual: {
      title: 'Benutzerhandbuch',
    },
    appOverview: {
      title: 'App-Übersicht',
      showOnlyAvailableApp: 'Nur verfügbare Apps anzeigen',
      search: 'App suchen',
    },
    partCreate: {
      title: 'Teil erstellen',
    },
    partEdit: {
      title: 'Teil bearbeiten',
    },
    partDetails: {
      title: 'Teilinformationen',
    },
    performanceDashboardDetails: {
      datePickerRange: {
        currentWeek: 'Aktuelle Woche',
        lastMonth: 'Vorheriger Monat',
        currentMonth: 'Aktueller Monat',
        lastQuarter: 'Vorheriges Quartal',
        currentQuarter: 'Aktuelles Quartal',
        currentYear: 'Aktuelles Jahr',
      },
    },
    productionStatisticsDetails: {
      panelSettings: {
        header: 'Panel Einstellungen',
        helpText: 'Wähle einen Titel für das Panel und die KPIs, die angezeigt werden sollen. Die Art des Diagramms kann im Panel ausgewählt werden.',
        labelTitle: 'Titel',
        labelSize: 'Größe',
        labelKpiSelection: 'KPI Auswahl',
      },
    },
    productionStatistics: {
      table: {
        header: {
          displayName: 'Name',
        },
      },
    },
    assetManagement: {
      title: 'Asset overview',
      overview: {
        title: 'Asset overview',
        tableHeader: 'Asset tree',
        filter: {
          name: 'Nach Name filtern',
        },
      },
      sensorSettings: {
        placeholder: {
          meaningId: 'Bedeutung auswählen',
          meaningInstanceNumber: 'Instanznummer',
        },
      },
      equipmentTab: {
        [ERouteName.ASSET_MANAGEMENT_MAIN]: 'Haupt',
        [ERouteName.ASSET_MANAGEMENT_DOCUMENT]: 'Dokumente',
        [ERouteName.ASSET_MANAGEMENT_SENSOR_MAPPING]: 'Sensor-mapping',
        [ERouteName.ASSET_MANAGEMENT_SENSOR_SETTINGS]: 'Sensor-Einstellungen',
        [ERouteName.ASSET_MANAGEMENT_DATA_SOURCES]: 'Data sources',
      },
    },
    equipmentSettings: {
      headline: 'Profil-Einstellungen',
      subheadline: 'Aktualisieren Sie Ihre Informationen',
      currencyRate: 'Währungskurs',
      dropdownLabel: {
        usd: '$ USD',
        eur: '€ EUR',
      },
    },
    equipmentDocument: {
      deleteMessage: 'Datei "{name}" löschen?',
      documentList: {
        headline: 'Dokumente',
        button: 'Dokument hochladen',
        empty: 'Keine hochgeladenen Dokumente',
        column: {
          fileName: 'Dateiname',
          description: 'Beschreibung',
          category: 'Kategorie',
          uploadedBy: 'Hochgeladen von',
        },
      },
      instructionList: {
        headline: 'Anweisungen',
        button: 'Anleitung hochladen',
        empty: 'Keine hochgeladenen Anleitungen',
        column: {
          fileName: 'Dateiname',
          description: 'Beschreibung',
          uploadedBy: 'Hochgeladen von',
        },
      },
    },
    liveApp: {
      equipment: {
        running: 'Läuft',
        autoUpdate: 'Automatisch aktualisieren (jede {0})',
        noActiveJob: 'Kein aktiver Job gefunden',
        noJobs: 'Keine Jobs gefunden',
        chart: 'Chart',
        pinnedSensorModalHeader: 'Wähle angepinnte Sensoren aus',
      },
    },
    assetManagementEquipmentSensorMapping: {
      showHiddenSignals: 'Versteckte Signale anzeigen',
      sensorTableTitle: 'Sensor-Kartierung',
      signalTableTitle: 'Asset-Signale',
      mappedSignalTitle: 'Abgebildetes Signal',
    },
    maintenanceJobList: {
      jobDeleteConfirm: 'Sind Sie sicher, dass Sie den Wartungsjob {0} löschen möchten?',
      createJob: 'Wartungsjob erstellen',
    },
    maintenanceCalendar: {
      scheduleMaintenance: 'Wartungsauftrag hinzufügen',
    },
    maintenanceDashboardDetail: {
      createOccurrence: 'Wartungsauftrag hinzufügen',
      alarmStatisticReference: 'Alarm-Statistik',
      alarmTableTitle: 'Alarme',
    },
    pt9800: {
      common: {
        id: 'ID',
        name: 'Name',
        description: 'Beschreibung',
        modifiedBy: 'Geändert von',
        modifiedOn: 'Letze Änderung',
        number: 'Nummer',
        comment: 'Bemerkung',
      },
      controllerStatus: {
        title: 'Controller status:',
        template: "connected={connected} ({timeSinceUpdateTime} since update time) {'|'} actualStep={actualStep} {'|'} remote={remote} {'|'} hold={hold}",
      },
      deleteConfirm: 'Are you sure to delete entry {0}? This action cannot be undone.',
      error: {
        create: '{0} konnte nicht erstellt werden',
        update: '{0} konnte nicht bearbeitet werden',
        delete: '{0} konnte nicht gelöscht werden',
        clone: '{0} konnte nicht kopiert werden',
        fetch: '{0} konnte nicht abgerufen werden',
      },
      form: {
        header: {
          create: '{0} erstellen',
          edit: '{0} bearbeiten',
          delete: '{0} löschen',
        },
      },
      entryType: {
        alarm: 'Alarm',
        application: 'Programm',
        applicationGroup: 'Programmgruppe',
        box: 'Box',
        clientAccess: 'Rechte-Client',
        color: 'Color',
        sms: 'SMS',
        terminal: 'Client',
        terminalType: 'Clienttyp',
        user: 'Benutzer',
        userAccess: 'Rechte_pro_Benutzer',
        userGroup: 'Benutzergruppe',
        backgroundProcess: 'Hintergrund Prozess',
        tasks: 'Aufgaben',
        workplace: 'Arbeitsplatz',
        workplaceType: 'Arbeitsplatz-Typ',
        operation: 'Operation',
      },
      backgroundProcess: {
        order: 'Auftrag',
        parameter: 'Parameter',
        active: 'Aktiv',
        activeTime: 'Aktiv Zeit',
        pid: 'PID',
        header: 'Hintergrund Prozess',
      },
      loadList: {
        selectedLoad: 'Selected load',
        forWorkplace: 'For workplace',
        recipe: 'Recipe',
        loadIdentifier: 'WP - Load-identifier',
        dateSorting: 'Date-Time for sorting',
        pos: 'Pos',
        amount: 'Amount',
        intern: 'Intern',
        customer: 'Customer',
        orderName: 'Order Description',
        orderRemark: 'Order remark',
        weightInKg: 'Weight [kg]',
        totalWeightInKg: 'Tot. weight [kg]',
      },
      operation: {
        header: 'Operation',
        property: 'Eigenschaft',
        operationType: 'Vorgangsart',
        type: 'Typ',
        mode: 'Modus',
        order: 'Auftrag',
        operationName: 'Vorgangsname',
        peProcessType: 'PE-Prozesstyp',
        sortingNo: 'Sortierung Nr.',
        automatingPlanningMode: 'Automatischer Planungsmodus',
        relevantForProductionStatistics: 'Relevant für die Produktionsstatistik',
        relevantForStat: 'Relevant für Statistik',
        selectProcess: 'Prozess auswählen',
        selectPart: 'Teil auswählen',
        sapNo: 'Sap-Nr.',
        workplaceName: 'Arbeitsplatz/ WP-Gruppen',
        placeDescription: 'Ortsbeschreibung',
      },
      tasks: {
        header: 'Verwaltung der Aufgaben',
        active: 'Aktiv',
        type: 'Typ',
        command1: 'Befehl 1',
        command2: 'Befehl 2',
        command3: 'Befehl 3',
        command4: 'Befehl 4',
        command5: 'Befehl 5',
        command6: 'Befehl 6',
        command7: 'Befehl 7',
        command8: 'Befehl 8',
        command9: 'Befehl 9',
        command10: 'Befehl 10',
        startHour: 'Start Stunde',
        dateLastActive: 'Datum zuletzt aktiv',
        intervalInMin: 'Intervall in min [min]',
      },
      workplace: {
        logicTypes: 'Logiktypen',
        header: 'Arbeitsort',
        inventoryNumber: 'Ausrüstungsnummer',
        belongsTo: 'Gehört zu Arbeitsplatz-ID',
        ip: 'IP-Adresse',
        mGuardIp: 'MGuard IP',
        device: 'Gerät',
        telegramSplit: 'RFC-Telegramm-Split',
        deactivateOperations: 'Operationen deaktivieren',
        aliveBitErrorNum: 'Alive-Bit-Fehlernummer',
        logicType: 'Logiktyp',
        recipePrefix: 'Rezept-Präfix',
        chamberId: 'Kammer-Id',
        sharesController: 'Teilt Controller/Kopie von chamber-id',
        mainUseMode: 'Hauptverwendungsmodus',
        workplaceType: 'Arbeitsplatztyp',
        isActive: 'Ist aktiv',
        importantSettings: 'Wichtige Einstellungen',
      },
      alarm: {
        alarmAdministration: 'Stoerung',
        alarm: 'Stoerung',
        alarmText: 'Stoertext',
        causesTestingLoad: 'Wird zu Pruef-Charge',
        onlyForChamber: 'Nur fuer Kammer',
        security: 'Pfoertner',
        color: 'Farbe',
        errorType: 'Stoer-Typ',
        outputType: 'Ausgabe-Typ',
        alarmGroup: 'Stoerungs-Gruppe',
      },
      application: {
        header: 'Programm',
        command: 'Command',
        description: 'Description',
        groupId: 'Group',
        iconGifName: 'Icon-Gif-Name',
        name: 'Name',
        furnacePicture: 'Furnace picture',
        order: 'Order',
        parameter: 'Parameter',
        tooltip: 'Tooltip',
        typeId: 'Type',
        uid: 'UID',
      },
      applicationGroup: {
        header: 'Programmgruppe',
      },
      recipe: {
        modifyRecipe: 'Rezept bearbeiten',
        recipeName: 'Rezeptname',
      },
      systemData: {
        active: 'Active',
        addresseeMobilePhoneNo: 'Addressee mobile phone no',
        boxHeader: 'Box Administration',
        colorHeader: 'Color Administration',
        colorValue: 'Color value',
        pin: 'PIN',
        serialInterface: 'Serial interface',
        smsHeader: 'SMS',
        testMessage: 'Test message (1=YES)',
        used: 'Used',
      },
      terminal: {
        header: 'Client',
        id: 'Id',
        clientType: 'Client Typ',
        clientAccess: 'Rechte-Client',
        name: 'Name',
        osType: 'Betriebssystem:',
        remark: 'Bemerkung',
        address: 'Adresse',
        description: 'Beschreibung',
        location: 'Aufstellungs-Ort:',
        valueLong2: '',
        valueLong3: 'Integer Wert 3:',
        valueLong4: 'Integer Wert 4:',
        valueLong5: 'Integer Wert 5:',
        valueChar1: 'Character Wert 1:',
        valueChar2: 'Character Wert 2:',
        actUserName: 'Aktueller Benutzer:',
        actLoginTime: 'Aktuelle Login Zeit',
        terminalNo: 'Client-No:',
        lastLoginTime: 'Letzte Login Zeit:',
        lastUserName: 'Letzter Benutzer',
        resolution: 'Bildschirmauflösung',
        users: 'Users',
        permission: 'Erlaubnis',
        rightsToTerminals: 'Rechte auf Clients',
      },
      terminalType: {
        header: 'Client Typ',
      },
      user: {
        header: 'Benutzer',
        accessLevel: 'Benutzerrecht',
        changePicture: 'Change Picture',
        comment: 'Bemerkung',
        costCenter: 'Kostenstelle',
        employeeNumber: 'Personalnummer',
        shortName: 'Kurzname',
        firstName: 'Vorname',
        function: 'Funktion',
        lastName: 'Nachname',
        language: 'Sprache',
        loginTimeInMin: 'Login-Zeit in min."',
        maintenancePopups: 'Wartungsplan Popups',
        password: 'Passwort',
        plant: 'Werk',
        playAlarmSound: 'AlarmTon wenn Stoerung',
        userGroup: 'Benutzergruppe',
        applicationGroup: 'Application Group',
        alarmSound: 'Alarmton abspielen',
        access: 'Zugriffsstufe',
      },
      userAccess: {
        header: 'Rechte_pro_Benutzer',
        delete: 'Loeschen',
        newEntry: 'Eintrag Neu',
        print: 'Drucken',
        run: 'Ausfuehren',
        save: 'Speichern',
        special1: 'Special-1',
        startRecipeProcessFlow: 'Start Beh/Ablauf',
        modifyRecipeProcessFlow: 'Eingriff Beh/Ablauf',
        startCorrectionRecipe: 'Recht Korrekturprogr. Starten',
        stopRecipeProcessFlow: 'Stop Beh/Ablauf',
      },
      userGroup: {
        header: 'Benutzergruppe',
      },
      workplaceType: {
        header: 'Arbeitsplatz-Typ',
        prio: 'Prio',
      },
    },
    login: {
      title: 'Login',
    },
    anomalyDetectionJobFeatureDetail: {
      title: 'Job-Funktion',
      tab: {
        observationTable: 'Beobachtungen',
        rootCauseTable: 'Ursachen',
      },
    },
    anomalyDetectionEquipmentSettings: {
      title: 'Einstellungen',
      tab: {
        counterActionTable: 'Gegenmaßnahmen',
        rootCauseGroupTable: 'Ursachen-Gruppen',
        rootCauseTable: 'Ursachen',
        jobFeatureTable: 'Job-Funktionen',
      },
    },
    anomalyDetectionEquipment: {
      title: 'Anomaly detection',
      confirmUnlink: 'Gegenmaßnahme "{counterActionName}" von der Ursache "{rootCauseName}" trennen?',
      tab: {
        rootCauseTable: 'Möglichen Ursachen',
        jobFeatureTable: 'Beobachtungen pro Job Feature',
      },
      jobFeatureTable: {
        header: {
          jobFeatureName: 'Job Feature',
          observationCount: 'Anzahl der Beobachtungen',
          rootCauseCount: 'Anzahl der Ursachen',
        },
      },
      observationTypeByJobFeatureTable: {
        header: {
          observationTypeName: 'Name des Beobachtungstyps',
          observationCount: 'Anzahl der Beobachtungen',
          rootCauseIdList: 'Anzahl der Ursachen',
        },
      },
    },
    anomalyDetection: {
      noObservations: 'Keine Beobachtungen gefunden',
      jobFeatures: {
        title: 'Chargeneigenschaften',
        tab: {
          rootCauseTable: 'Grundlegende Kausalitäten',
          jobFeatureTable: 'Beobachtungen',
        },
      },
      title: 'Anomalie-Erkennung',
    },
    audit: {
      navigation: {
        configure: 'Configure audit',
        load: 'Select load',
        verify: 'Rate audit',
      },
      selectLoad: {
        selectTime: 'Select time range',
        tableHeader: {
          durationInSec: 'Duration',
          endingTime: 'End time',
          header: {
            recipeName: 'Recipe',
          },
          loadName: 'Load No',
          locationName: 'Location',
          startingTime: 'Start time',
          workplace: {
            fullName: 'Asset',
          },
        },
        title: 'Audit / Select load',
      },
      title: 'Audit',
    },
    quality: {
      title: 'Audit & Qualität',
      header: {
        title: 'Qualität',
        createReport: 'Bericht erstellen',
      },
    },
    auditResult: {
      passed: 'Audit bestanden',
      notPassed: 'Audit nicht bestanden',
      emptySecondarySensor: 'Kein ausgewählter sekundärer Sensor',
      asset: 'Asset',
      comment: 'Kommentar',
      duration: 'Dauer',
      endTime: 'Endzeitpunkt',
      headerTitle: 'Audit Report',
      loadNo: 'Load No',
      location: 'Standort',
      rating: 'Bewertung',
      recipe: 'Rezept',
      reportNo: 'Report No.',
      reporter: 'Auditor',
      signature: {
        customer: 'Kunde',
        engineering: 'Engineering',
        manufacturing: 'Fertigung',
        material: 'Material',
        program: 'Programm',
        quality: 'Qualitätssicherung',
      },
      startTime: 'Start time',
      title: 'Audit result',
    },
    certification: {
      audit: {
        title: 'Audit & Quality / Audit',
      },
      auditConfiguration: {
        title: 'Audit & Quality / Audit Configuration',
        deleteConfirm: 'Are you sure you want to delete specification {0}',
        deleteSuccess: 'Specification successfully deleted',
      },
      quality: {
        title: 'Audit & Quality / Quality',
      },
      qualityConfiguration: {
        title: 'Audit & Quality / Quality configuration',
      },
      title: 'Audit & Quality',
    },
    chartRecorder: {
      defaultGroup: 'Other',
    },
    createRootCause: {
      linkRootCause: {
        title: 'Verknüpfen Sie eine Grundursache',
      },
      linkSuggestedAction: {
        title: 'Link Vorgeschlagene Aktionen',
      },
      selectJobFeature: {
        title: 'Auftragsmerkmal auswählen',
      },
      selectObservationType: {
        title: 'Beobachtungsart auswählen',
      },
      title: 'Ursachen erstellen',
    },
    productionLogList: {
      compare: 'Vergleiche',
      export: 'Export',
      filterPreset: 'Gespeicherte Filter',
      furnaceType: 'Ofentyp',
      presetEditTitle: 'Voreinstellung bearbeiten',
      includeRunningJobs: 'Laufende Jobs inkludieren',
    },
  },
  part: {
    noRecipes: 'Keine Rezepte...',
  },
  parts: {
    amount: 'Menge',
    defineParameterForPartStatistics: 'Teilestatistik-Parameter definieren',
    description: 'Beschreibung',
    forWorkplace: 'für Asset',
    name: 'Name',
    order: 'Auftrag',
    orderNo: generalTerm.loadIdentifier,
    part: 'Teil',
    partDesc: 'Teilebezeichnung',
    partNo: 'Teil-Nr:',
    parts: 'Teile',
    recipe: 'Rezept',
    recipeNo: 'Behandlung-Nr.',
    statistics: 'Teile-Statistik',
    statisticsFor: 'Statistik für:',
    weight: 'Gewicht',
  },
  passwordDialog: {
    confirm: 'Password ändern',
    important: 'Wichtig',
    newPassword: 'Neues Passwort',
    newUserPassword: 'Geben Sie dieses Passwort dem neu angelegten Benutzer: {password}',
    oldPassword: 'Altes Passwort',
    passwordChanged: 'Passwort wurde geändert!',
    retypePassword: 'Neues Passwort erneut eingeben',
    title: 'Passwort ändern',
  },
  productionLogs: {
    default: 'Standard',
    partDataToLoad: 'Teile-Daten für Charge',
    profileForLoad: 'Profil für Charge',
    showInGraph: 'Im Graph anzeigen',
  },
  productionStatistics: {
    assets: 'Assets',
    compareHierarchyTooltip: 'Es können nur Elemente der gleichen Ebene verglichen werden',
    comparing: 'Vergleichen',
    customers: 'Kunden',
    loads: 'Chargen',
    locations: 'Standorte',
    recipes: 'Rezepte',
    selectedItems: 'Ausgewählte Gegenstände',
  },
  recipeBuilder: {
    alarmDeactivated: 'Alarm ausgeschaltet',
    alarmDelay: 'Alarmverzögerung',
    description: 'Beschreibung',
    equi: 'Equi',
    highAlarmOffsets: 'HIGH Alarm Offsets',
    highWarningOffsets: 'HIGH Warnung Offsets',
    lowAlarmOffsets: 'LOW Alarm Offsets',
    lowWarningOffsets: 'LOW Warnung Offsets',
    nitrexProcess: 'Geschützter Nitrex Process',
    recipeName: 'Rezeptname',
    referenceValue: 'Referenzwert',
    setAlarmLimit: 'Alarmgrenze einstellen',
    setAlarmLimits: 'Alarmgrenzen festlegen',
    setPoints: {
      'Cooling Ramp': 'Abkühlkurve',
      Dissociation: 'Dissoziation',
      'Dissociation H2Smart': 'Dissoziation H2Smart',
      Furn: {
        Press: 'Ofendruck',
      },
      'Heating Ramp': 'Aufheizkurve',
      Kn: 'Kn',
      Kc: 'Kc',
      Turbo: 'Turbo',
      'Main TC': 'Main TC',
      Time: 'Dauer',
      'Total Flow': 'Gesamt Durchfluss',
    },
    setValue: 'Wert einstellen',
    totalDuration: 'Gesamtdauer',
  },
  recipeOnDemand: {
    ammonia: 'Ammoniak',
    chart: {
      gasCompositionLabel: {
        ammonia: 'Ammoniak: {value} %',
        dissociatedAmmonia: 'Dissoziiertes Ammoniak: {value} %',
        nitrogen: 'Stickstoff: {value} %',
        carbonOxide: 'Carbon Oxide: {value} %',
        carbonDioxide: 'Carbon Dioxide: {value} %',
      },
      processLabel: {
        knAct: 'Kn Act: {value}',
      },
    },
    compoundLayerThickness: 'Dicke der Verbundschicht',
    coreHardnessHV: 'Kernhärte (HV)',
    diffusionLayerThickness: 'Dicke der Diffusionsschicht',
    dissAmmonia: 'Dissoziiertes Ammoniak',
    distance: 'Distanz',
    effective: 'Wirksam',
    gasFlowChart: 'Gas-flow chart',
    hardness: 'Härte',
    material: 'Material',
    nitridetHardness: 'Tiefe der Nitrierhärte',
    nitriding: 'Nitrieren',
    nitrocarburizing: 'Nitrocarburieren',
    nitrogen: 'Stickstoff',
    oxideLayerThickness: 'Dicke der Oxidschicht',
    porosity: 'Porosität %',
    postoxidation: 'Postoxidation',
    process: 'Prozess',
    processChart: 'Process chart',
    profileChart: 'Profile chart',
    stepNumber: 'Schritt {number}',
    surfaceHardness: 'Härte der Oberfläche',
    unit: 'Einheit',
    unitSystems: {
      imperial: 'Imperial',
      metric: 'Metrisch',
    },
    vickersHardness: 'Vickers-Härte',
  },
  recipeStep: {
    actualConditions: 'Istwert Zustaende',
    actualValue: 'Istwert',
    addSetpoint: 'Sollwert hinzufügen',
    addStep: 'Rezeptschritt hinzufügen',
    chamber: 'Kammer',
    chamberOperation: 'Betrieb der Kammer',
    ctrl: 'Strg.',
    description: 'Beschreibung',
    digitalOutput: 'Digitaler Ausgang',
    digitalOutputs: 'Spuren',
    endAfter: 'Ende nach',
    endCondition: 'Endebedingung',
    endStates: 'Ende bei Zustaenden',
    estimatedDuration: 'gesch. Dauer:',
    modifyStep: 'Behandlungsschritt bearbeiten',
    next: 'Naechster',
    operation: 'Verfahren',
    previous: 'Voriger',
    runtime: 'Laufzeit',
    setpoint: 'Sollwert',
    setpoint1: 'Sollwert 1',
    setpoint2: 'Sollwert 2',
    step: 'Schritt',
    time1: 'AZt1',
    time2: 'AZt2',
    tolMinus: 'Tol-',
    tolPlus: 'Tol+',
    unit: 'Einheit',
    value: 'Wert',
    viewStep: 'Rezeptschritt anzeigen',
  },
  recipes: {
    alloyFactor: 'Legierungsfaktor',
    analysis: 'Analyse ...',
    calculateParameters: 'Analyse Parameter Berechnen',
    carbideLimit: 'Carbidgrenze',
    carbonContent: 'C-Gehalt',
    carbonContentPc: 'C-Gehalt [%C]',
    carburizingDepth: 'Aufkohlungstiefe',
    comment: 'Bemerkung',
    correction: 'Korrektur',
    correctionProfile: 'Korrektur Profil eingeben',
    deleteConfirm: 'Wollen Sie das Rezept wirklich löschen?',
    deleteVersionConfirm: 'Willst du die Rezeptversion wirklich löschen?',
    depth: 'Tiefe',
    desc: 'Bez.',
    desc2: 'Bez. 2:',
    description: 'Beschreibung',
    diff: 'Diff',
    endTemperature: 'Endtemperatur',
    general: 'General',
    grainSize: 'Korngroesse:',
    incorrectAmountOfElementsInCorrectionProfile: 'Die Anzahl der Elemente im Korrekturprofil ist nicht korrekt.',
    legFactorN: 'Bein. Factor N',
    material: 'Werkstoff',
    name: 'Name',
    nameAlreadyInUse: 'Name {name} existiert bereits.',
    nameChangeNotAllowedInNewRecipeVersionMode: 'Der Name des Rezepts kann nicht geändert werden, da die Länge der Versionen von eins abweicht',
    nitridingDepth: 'Nitriertiefe',
    operation: 'Verfahren',
    process: 'P-Typ:',
    profile: 'Profil',
    quenchingIntensity: 'Abschreckintensitaet',
    recipe: 'Rezept',
    representativeDiameter: 'Repräsentativer Durchmesser',
    requiredHardnessHV: 'Grenzhaerte [HV]:',
    saveNewVersion: 'Als neue Version speichern',
    selectRecipe: 'Behandlung auswaehlen',
    steps: 'Positionen',
    thsd: 'thsd',
    versionNo: 'Version Nb:',
    noAccess: 'Benutzer hat keinen Zugriff auf {0}',
  },
  recipesPt9800: {
    unknownRecipeMenu: 'Unbekannte Rezeptgruppe',
  },
  report: {
    actions: 'Aktionen',
    approvals: 'Zulassungen',
    batchNumber: 'Chargennummer',
    companyName: 'Firma',
    customer: 'Kunde',
    date: generalTerm.date,
    dateTime: 'Datum/Uhrzeit',
    engineering: 'Technik',
    in: 'IN',
    manufacturing: 'Herstellung',
    materiel: 'Materie',
    maxBand: 'oberes Limit',
    minBand: 'unteres Limit',
    out: 'AUS',
    program: 'Programm',
    quality: 'Qualität',
    recipeCode: 'Rezeptname',
    reporter: 'Author',
    request: 'ANTRAG NR.',
    systemId: 'Assetname',
    timeInBand: 'Zeit in der Band',
    title: 'ERSUCHEN UM MASSNAHMEN DES MRB',
    totalTimeInBand: 'Gesamtzeit innerhalb der Grenzen',
  },
  requestCategory: {
    bom: 'BOM',
    configuration: 'Konfiguration',
    drawings: 'Zeichnungen',
    emergency: 'Notfall',
    sales: 'Vertrieb',
    service: 'Service',
    software: 'Software Service',
  },
  settings: {
    commonSensors: {
      customSensorName: 'Benutzerdefinierter Sensorname',
      introduction: 'Klicke auf einen der unten aufgelisteten Sensoren um den Name oder dessen Farbe zu ändern.',
      sensorName: 'Sensorname',
      title: 'Generische Sensoreinstellung',
    },
    equipmentGroups: {
      deleteEquipmentGroup: 'Wollen Sie die Ausrüstungsgruppe {group} wirklich löschen?',
      deleteSensorGroup: 'Möchten Sie die Sensorgruppe {group} wirklich löschen?',
      dragEquipment: 'Ziehen Sie das Equipment nach unten',
      equipmentSelect: 'Asset',
      filterEquipments: 'Assets filtern',
      introduction: 'Wähle einen Standort aus der Liste auf der linken Seite um eigene Asset Gruppen fuer den Standort zu konfigurieren.',
      noLocationSelected: 'Kein Standort ausgewählt',
      title: 'Asset Gruppen',
    },
    operationDashboard: {
      introduction: 'Wähle die Sensor für den primären und sekundären Graph im Operation Dashboard.',
      mainChart: 'Primäres Diagramm',
      secondaryChart: 'Sekundäres Diagramm',
      title: 'Operation Dashboard Sensoren',
    },
    sensorGroups: {
      createSensorGroup: 'Sensorgruppe erstellen',
      dragSensor: 'Ziehen Sie den Sensor unter',
      introduction: 'Erstelle eine neue Gruppe und verknüpfe diese per drag & drop mit den gewünschten Sensoren.',
      title: 'Sensor Gruppen',
    },
    statistics: {
      introduction: 'Choose the sensors to be displayed in Production Statistics. If no sensor is selected all sensors will be selected.',
      title: 'Einstellungen für Statistiken',
    },
  },
  simulator: {
    addNewStage: 'Neuer Behandlungsschritt',
    alloyFactor: 'Legierungsfaktor C',
    alloyFactorCarbon: 'Legierungsfaktor [Carbon]',
    alloyFactorNitride: 'Legierungsfaktor [Nitrogen]',
    auto: 'Auto',
    awt: 'Awt',
    buffering: 'Buffering...',
    carbDepth: 'Aufkohlungstiefe (CHD)',
    carbideLimit: 'Carbidgrenze [%aC=1]',
    carbonAtDepth: 'Grenzkohlenstoffgehalt',
    carbonitriding: 'Carbonitrieren',
    carburizing: 'Aufkohlen',
    'co+co2': 'CO + CO2',
    composition: 'Material Zusammensetzung',
    cool: 'Kühlen',
    coolDown: 'Abkühlen',
    coreCarbon: 'Kernkohlenstoffgehalt',
    coreHardnessUntreatedHv: 'Kernhärte (unbehandelt) [HV]',
    diameter: 'Durchmesser',
    effectiveCaseDepth: 'Aufkohlungstiefe (CHD)',
    effectiveCaseHardness: 'Grenzhärte',
    endTemperature: 'End Temperatur',
    estTemperingConditions: 'Geschätzte Anlassbedingungen',
    fixed: 'Fest',
    generalShapeOfPart: 'Teilegeometrie',
    grabke: 'Grabke',
    grainSize: 'Korngröße',
    gunnarson: 'Gunnarson',
    hardnessNormalized: 'Normalisierte Härte',
    hardnessQuenched: 'Abgeschreckte Härte',
    heat: 'Heizen',
    heatUp: 'Aufheizen',
    hold: 'Halten',
    logReplay: 'Log-Wiedergabe',
    lowPressureCarbonizing: 'Niederdruck-Aufkohlung',
    material: 'Material',
    materialModel: 'Material Model',
    materialSimulationData: 'Daten zur Materialsimulation',
    minSurfaceHardness: 'Min. Oberflächenhärte',
    minus: 'Minus {type}',
    models: 'Material Modelle',
    neumann: 'Neumann',
    nitride: 'Nitrid',
    nitrideLimit: 'Nitridgrenze [%FexNy]',
    normalized: 'Normalisiert',
    nothingSelected: 'Nichts ausgewählt',
    nxc: 'NX C - Diff',
    'nxc+n': 'NX C[+N] - Diff',
    off: 'Aus',
    on: 'Ein',
    overwrite: 'Überschreiben',
    part: 'Teil',
    plus: 'Plus {type}',
    preHeatTreatment: 'Zustand vor der Wärmbehandlung',
    processChart: 'Prozesswert Chart',
    profileChart: 'Profil Chart',
    profileTable: 'Profil Table',
    properties: 'Eigenschaften',
    quenched: 'Abgeschreckt',
    quenchingIntensity: 'Abschreckintensität',
    recipe: 'Rezept',
    recipeStages: 'Behandlungsschritte',
    representativeCoupon: 'Repräsentativer Bauteilabmessung',
    restartToApplyParameters: 'Restart to apply new parameters',
    retainedAustenite: 'Restaustenit',
    runtime: 'Laufzeit',
    selectMaterial: 'Material auswählen',
    selectPart: 'Teil auswählen',
    selectRecipe: 'Rezept auswählen',
    selectRecipeFirst: 'Sie müssen zuerst ein Rezept auswählen',
    setPoints: 'Sollwert',
    shapes: {
      CYL_HOLLOW_OVER_200MM: 'Hohlzylinder mit Innendurchmesser > 200 mm',
      CYL_HOLLOW_UPTO_200MM: 'Hohlzylinder mit Innendurchmesser < 200 mm',
      CYL_HOLLOW_UPTO_80MM: 'Hohlzylinder mit Innendurchmesser < 80 mm',
      HEXAGON_OR_OCTAGON_STEEL: 'Hexagon oder Oktagon',
      HOLLOW_BODY_CLOSED: 'Hohlkörper geschlossen von einer oder zwei Seiten',
      RING_OVER_200MM: 'Ring (Innendurchmesser > 200 mm)',
      ROUND_STEEL: 'Rundstahl',
      SOLID_STEEL_UPTO_200MM: 'Stahlblock (Durchmesser < 200 mm)',
      SQUARE_OR_FLAT_STEEL: 'Flachstahl',
    },
    simulation: 'Simulation',
    sootLimit: 'Rußgrenze',
    steelGradeEstimation: 'Geschätze Stahlqualität',
    surf: 'Rand {type}',
    tempered: 'gehärtet',
    temperingConditions: 'Geschätzte Temperierungsbedingungen',
    variable: 'Variabel',
    visualizations: 'Visualisierungen',
  },
  tabHeaders: {
    readings: 'Messwerte',
    setPoints: 'Sollwerte',
  },
  table: {
    getQuote: 'Nehmen Sie Kontakt auf und fordern Sie ein Angebot an',
  },
  tableHeaders: {
    NoAnalogOutputsAvailable: 'Keine analogen Ausgänge verfügbar',
    RowsPerPage: 'Zeilen pro Seite',
    actions: 'Aktionen',
    activity: 'Aktivität',
    advisedStockQuantity: 'Empfohlene Lagermenge',
    aggregatedProbability: 'Aggregierte Wahrscheinlichkeit [%]',
    alarm: 'Alarm',
    alarmNumber: 'Alarmnummer',
    alarmText: 'Alarmtext',
    alarmType: 'Alarmtyp',
    amount: 'Betrag',
    analogOutputs: 'Analoge Ausgänge',
    announcementId: 'Benachrichtigungs ID',
    announcementType: 'Benachrichtigungs Typ',
    apps: 'Apps',
    assetSensors: 'Anlagensensoren',
    assetType: 'Asset-Typ',
    assignee: 'Rechtsnachfolger',
    atmosphere: 'Atmosphäre',
    auditEnvelope: {
      duration: 'Duration (min)',
      durationLimitMinus: 'Duration limi(-)',
      durationLimitPlus: 'Duration limit(+)',
      name: 'Envelope label',
      selectedMainSensor: 'Main sensor',
      selectedSecondarySensorList: 'Secondary sensors',
      selectedStartTime: 'Start timer at',
      setpoint: 'Setpoint',
      setpointLimit: 'Setpoint Limit(±)',
    },
    austenite: 'Austenit',
    avg: 'Avg {unit}',
    batch: 'Charge',
    batchCount: 'Chargenanzahl',
    batchDuration: 'Dauer',
    batchNumber: 'Chargennummer',
    batchRating: 'Chargenbewertung',
    bomQuantity: 'Stücklistenmenge',
    c2h2: 'C2H2',
    c8H8: 'C8H8',
    carbonPotential: 'C-Pegel Status',
    carburizingPotential: 'Aufkohlungspotenzial',
    category: 'Kategorie',
    cause: 'Ursache',
    chartToolsData: 'Legende und Diagrammwerkzeuge',
    co: 'CO',
    commonSensor: 'Generischer Sensor',
    commonSensors: 'Generische Sensoren',
    comp: 'Comp',
    contentName: 'Inhaltsname',
    coolingRamp: 'Kühlrampe',
    cost: 'Kosten',
    count: 'Anzahl',
    created: 'Erstellt',
    createdAt: 'Erstellt am',
    createdBy: 'Erstellt von',
    customAnalogControllers: 'Benutzerdefinierte analoge Controller',
    customDigitalControllers: 'Benutzerdefinierte digitale Controller',
    customerCode: 'Unternehmen',
    customerRequestId: 'Serviceanfrage ID',
    date: generalTerm.date,
    defaultDeliveredQuantity: 'Standard Liefermenge',
    delete: 'Löschen',
    description: 'Beschreibung',
    digitalOutputs: 'Digitalausgang - Verzögerung',
    digits: 'Ziffern',
    dinNumber: 'DIN Nummer',
    dissociation: 'Dissoziation',
    documentId: 'Dokumenten ID',
    duration: 'Dauer',
    durationAvg: 'Dauer Durchschnitt',
    durationSum: 'Gesamtdauer',
    email: 'E-Mail',
    enabled: 'Aktiviert',
    endCondition: 'Endzustand',
    endDate: 'Enddatum',
    endTemp: 'Endtemp',
    equipmentId: 'Asset Name',
    expectedDuration: 'Erwartete Dauer',
    explanation: 'Erklärung',
    featureObservationMapping: 'Kartierung der Beobachtung von Merkmalen',
    fileName: 'Dateiname',
    filterName: 'Filtername',
    finishedAt: 'Beendet am',
    firstOccurrence: 'Erster Wartungsauftrag',
    fullName: 'Vollständiger Name',
    furnace: 'Ofen',
    furnaceName: 'Name des Ofens',
    furnaceSensor: 'Ofensensor',
    furnaceType: 'Ofentyp',
    group: 'Nutzer Gruppe',
    groupSize: 'Größe der Gruppe',
    h2: 'H2',
    heatingRamp: 'Heizrampe',
    highlighted: 'Hervorgehoben',
    hvResult: 'HV Ergebnis',
    imperialUnit: 'Imperiale Einheit',
    instructionId: 'Anweisungs ID',
    instructionType: 'Anweisungs Typ',
    intern: 'Intern',
    jobCost: 'Chargenkosten',
    jobFeaturesName: 'Name des Jobmerkmals',
    jobValue: 'Chargenwert',
    jobsLocationDetail: {
      asset_name: 'Asset',
      batch_duration_in_sec: 'Duration',
      end_date_utc: 'End time',
      job_name: 'Load No',
      location_name: 'Location',
      recipe: 'Recipe',
      start_date_utc: 'Start time',
    },
    latestOccurrence: 'Letztes Vorkommen',
    linkedObservations: 'Verknüpfte Beobachtungen',
    linkedRootCauses: 'Verknüpfte Grundlegende Kausalitäten',
    load: 'Charge',
    loadIdentifier: 'Kennung der Charge',
    loadNo: 'Charge N*',
    location: 'Standort',
    logInterval: 'Protokollintervall',
    manufacturer: 'Hersteller',
    manufacturerPartNumber: 'Original-Teilenummer',
    materialGroup: 'Material Gruppe(n)',
    max: 'Max {unit}',
    maxFlow: 'Maximaler Durchfluss',
    maxProbability: 'Maximale Wahrscheinlichkeit [%]',
    measurement: 'Messung',
    message: 'Nachricht',
    metricUnit: 'Metrische Einheit',
    min: 'Min {unit}',
    minFlow: 'minimaler Durchfluss',
    modifiedAt: 'Modifiziert am',
    n2: 'N2',
    name: 'Name',
    nitridingPotential: 'Nitrierpotenzial',
    nitrogenPotential: 'Stickstoffpotenzial',
    noCustomAnalogControllersAvailable: 'Keine benutzerdefinierten analogen Controller verfügbar',
    noCustomDigitalControllersAvailable: 'Keine benutzerdefinierten digitalen Controller verfügbar',
    notificationId: 'Meldungs ID',
    numberOfObservation: 'Anzahl der Beobachtungen',
    numberOfOccurrences: 'Anzahl der Vorkommnisse',
    numberOfRootCauses: 'Anzahl der Grundursachen',
    observation: 'Beobachtung',
    observationType: 'Typ der Beobachtung',
    occurrences: 'Vorkommnisse',
    ofTotalFlow: '{type} des Gesamtflusses',
    orderName: 'Auftragsname',
    orderRemark: 'Auftragsanmerkung',
    outputs: 'Ausgänge',
    partDescription: 'Teilebeschreibung',
    partName: 'Teilname',
    plantName: 'Standort',
    pos: 'Position',
    possibleRootCauses: 'Mögliche Grundursachen',
    probability: 'Wahrscheinlichkeit',
    probabilityPercentages: 'Wahrscheinlichkeit [%]',
    process: 'Prozess',
    processMode: 'Prozess-Modus',
    protected: 'geschützt',
    publishedOn: 'Veröffentlicht am',
    recent: 'letzte Änderung',
    recipe: 'Rezept',
    recipeCode: 'Rezeptcode',
    recipeDescription: 'Rezept Beschreibung',
    recipeHash: 'Rezepthashwert',
    recipeName: 'Rezeptname',
    requestId: 'Anfrage-ID',
    requestStatus: 'Anfragestatus',
    rootCause: 'Grundlegende Kausalitäten',
    rootCauses: 'Grundlegende Kausalitäten',
    sensorName: 'Sensorname',
    sensorSelection: 'Sensor Auswahl',
    set: 'Set',
    setPoint: 'Sollwert',
    setPoints: 'Sollwerte',
    siUnit: 'SI-Einheit',
    sparePartsId: 'Ersatzteile ID',
    stage: 'Behandlungsschritt',
    stageDuration: 'Dauer des Behandlungsschrittes',
    stageName: 'Name des Behandlungsschrittes',
    startDate: 'Startdatum',
    startDateLatest: 'Startdatum (ältestes)',
    startDateOldest: 'Startdatum (ältestes)',
    status: 'Status',
    step: 'Schritt',
    suggestedAction: 'Vorgeschlagene Maßnahmen',
    systemId: 'Asset Name',
    task: 'Aufgabe',
    temperature: 'Temperatur',
    time: 'Zeit',
    timeOfDeclaration: 'Zeitpunkt der Erklärung',
    timeStamp: 'Zeitstempel',
    toolsAndSpareParts: 'Hilfsmittel und Ersatzteile',
    totalDuration: 'Gesamtdauer',
    totalFlow: 'Gesamtfluss',
    totalWeightInKg: 'Gesamtgewicht [kg]',
    tunnelFunction: 'Tunnel-Funktion',
    type: 'Typ',
    updated: 'Aktualisiert',
    upload: 'Hochgeladen von',
    username: 'Nutzername',
    value: 'Wert',
    valueWhenDeclared: 'Wert bei Deklaration',
    volumeFlow: 'Volumenstrom',
    weightInKg: 'Gewicht [kg]',
    workOrderNo: 'Arbeitsauftrag Nr.',
  },
  tabs: {
    job_list: 'Jobs',
    task_list: 'Tasks',
    absolute: 'Absolut',
    alarms: 'Alarme',
    attachments: 'Anhänge',
    audit: 'Audit',
    calendar: 'Kalender',
    chartView: 'Diagrammwerkzeuge',
    charts: 'Diagramme',
    communication: 'Korrespondenzen',
    controller: 'Controller',
    customerSettings: 'Einstellungen',
    data: 'Daten',
    details: 'Details',
    documents: 'Dokumente',
    equipments: 'Assets',
    filters: 'Filter',
    graph: 'Graph',
    legend: 'Legende',
    main: 'Haupt',
    'material-groups': 'Materialgruppen',
    materials: 'Materialien',
    materialsGroups: 'Materialiengruppen',
    modify: 'Ändern Sie',
    notifications: 'Meldungen',
    relative: 'Relativ',
    report: 'Bericht',
    reports: 'Berichte',
    save: 'Speichern',
    sensorGroups: 'Sensorgruppen',
    sensorMapping: 'Sensor-mapping',
    singleSensor: 'Einzelner Sensor',
    stages: 'Behandlungsschritte',
    status: 'Status',
  },
  tiles: {
    userManagement: 'Benutzerverwaltung',
    agentChat: 'Agenten-Chat',
    alarmStatistics: 'Alarmstatistik',
    anomalyDetection: 'Anomalie-Erkennung',
    appOverview: 'Applikationsübersicht',
    assetManagement: 'Asset Management',
    certification: 'Audit & Quality',
    customerSettings: 'Asseteinstellungen',
    drSommerMaterialDatabase: 'Dr. Sommer Materialdatenbank',
    grafana: 'Grafana',
    historyAndTrends: 'History and Trends',
    home: 'Startseite',
    liveApp: 'Live app',
    maintenanceCalendar: 'Wartungskalender',
    materials: 'Materialdatenbank',
    mlPrototyping: 'ML Prototyping',
    modelProcess: 'Modelprozesse',
    nitrexLive: 'Nitrex Connect',
    part: 'Teile',
    performanceDashboard: 'Auslastungsübersicht',
    productionLogs: 'Produktionshistorie',
    productionStatistics: 'Produktionsstatistik',
    pt9800Cloud: 'PT9800 Webclient',
    pt9800Configuration: 'PT9800 Konfiguration',
    recipeOnDemand: 'Rezeptsuche',
    recipes: 'Rezepte',
    recipesNitride: 'Rezepte (Nitride)',
    recipesPt9800: 'Rezepte (PT9800)',
    remoteControl: 'Fernsteuerung',
    shopLayout: 'Hallen-Layout',
    simulator: 'Simulatoren',
    universalChart: 'Universelles Diagramm',
    upcPro: 'UPC Werkzeugkasten',
    userManual: 'Benutzer Handbuch',
    userSettings: 'Einstellungen',
    customer: 'Customer',
  },
  titles: {
    alarms: 'Alarme',
    ams: 'AMS',
    announcements: 'QMULUS Benachrichtigungen',
    capabilities: 'Fähigkeiten',
    certificates: 'Zertifikate',
    components: 'Komponenten',
    composition: 'Zusammensetzung',
    copySensors: 'Zuordnung kopieren',
    customerRequests: 'Serviceanfrage',
    dimensions: 'Abmessungen',
    documents: 'Dokumente',
    drawings: 'Zeichnungen',
    equipment: 'Asset',
    filters: 'Filter',
    gasses: 'Gase',
    general: 'General',
    heatTreatmentType: 'Art der Wärmebehandlung',
    instructions: 'Anweisungen',
    jobUsage: 'Chargenverbrauch',
    locations: 'Standorte',
    materialsGroups: 'Materialgruppen',
    modelProcess: 'Modell Prozess',
    modelProcesses: 'Modellprozesse',
    nitridingTemplates: 'Nitriervorlagen',
    no: 'Nein',
    noData: 'Keine Daten vorhanden',
    notifications: 'Meldungen',
    npmController: 'NPM Controller',
    others: 'Andere',
    overview: 'Übersicht',
    partsSimulationData: 'Daten zur Teilesimulation',
    plants: 'Standorte',
    pt9800Controller: 'PT9800 Controller',
    recipe: 'Rezept',
    recipeStages: 'Behandlungsschritte',
    selectAnalogInputForEndCondition: 'Analogen Eingang für Endbedingung auswählen',
    selectDigitalInputForEndCondition: 'Digitaleingang für Endbedingung auswählen',
    selectedBatch: 'Ausgewählte Chargen',
    settings: 'Einstellungen',
    showAlarms: 'Alarme zeigen',
    spareParts: 'Ersatzteile',
    stageChange: 'Stage Change',
    stageSetup: 'Stage Setup',
    step: 'Schritt',
    steps: 'Schritte',
    temperature: 'Temperatur',
    threeDots: '...',
    users: 'Nutzer',
    valuesAndCalculations: 'Werte und Berechnungen',
    yes: 'Ja',
  },
  type: {
    Visualisation,
    auditEnvelope: {
      duration: 'Dauer',
      durationLimitMinus: 'Untergrenze',
      durationLimitPlus: 'Obergrenze',
      name: 'Name',
      selectedMainSensor: 'Primärer Sensor',
      selectedSecondarySensorList: 'Sekundäre Sensoren',
      selectedStartTime: 'Beginn',
      selectedStartTimeLimit: 'Grenze',
      setpoint: 'Sollwert',
      setpointLimit: 'Sollwert Grenze (±)',
    },
  },
  units: {
    days: 'Tage',
    hours: 'h',
    minutes: 'min',
    months: 'm',
    weeks: 'Woche(n)',
    years: 'a',
  },
  upcPro: {
    analyzerReading: 'Analyzer Reading',
    convertsTo: 'ergibt',
    endoGeneratorDataError: 'DATEN EINGABEFEHLER:\nCO[%] + CO₂[%] + CH₄[%] + H₂[%] > 100 \nBITTE DIE WERTE KORRIGIEREN.',
    floMeterGlossary: 'Glossar:\n\nSG1 = Spezifische Dichte des Gases, für das das Durchflussmessgerät kalibriert ist\n\nSG2 = Spezifisches Gewicht des Gases, für das Flo-Meter verwendet wird\n\nTemp.1 = Temperatur, für die der Durchflussmesser kalibriert ist\n\nTemp.2 = Temperatur, für die der Durchflussmesser verwendet wird\n\nP1 = Druck, für den der Durchflussmesser kalibriert ist\n\nP2 = Druck, für den der Durchflussmesser verwendet werden soll\n\nÜbersetzt mit www.DeepL.com/Translator (kostenlose Version)',
    flows: 'Eingangsströme',
    furnaceAtmosphere: 'Ofenatmosphäre',
    imperialUnits: 'imperial',
    inputGroup: 'Input',
    inputs: {
      CELCIUS_TO_FAHRENHEIT: 'Celsius to Fahrenheit',
      FAHRENHEIT_TO_CELCIUS: 'Fahrenheit to Celsius',
      FT3PH_TO_M3PH: 'cfh to m³/h',
      GPM_TO_LMIN: 'US gpm to l/min',
      HARDNESS_TABLE1: 'Rockwell C Range',
      HARDNESS_TABLE2: 'Rockwell B Range',
      HBS: {
        name: 'HBS',
        subtitle: 'Brinell Scale',
      },
      HBS2: {
        name: 'HBS',
        subtitle: 'Brinell Scale',
      },
      HBW: {
        name: 'HBW',
        subtitle: 'Brinell Scale',
      },
      HK: {
        name: 'HK',
        subtitle: 'Knoop Hardness',
      },
      HK2: {
        name: 'HK',
        subtitle: 'Knoop Hardness',
      },
      HR15N: {
        name: 'HR15N',
        subtitle: 'Rockwell 15-N Scale',
      },
      HR15T: {
        name: 'HR15T',
        subtitle: 'Rockwell 15-T Scale',
      },
      HR30N: {
        name: 'HR30N',
        subtitle: 'Rockwell 30-N Scale',
      },
      HR30T: {
        name: 'HR30T',
        subtitle: 'Rockwell 30-T Scale',
      },
      HR45N: {
        name: 'HR45N',
        subtitle: 'Rockwell 45-N Scale',
      },
      HR45T: {
        name: 'HR45T',
        subtitle: 'Rockwell 45-T Scale',
      },
      HRA: {
        name: 'HRA',
        subtitle: 'Rockwell A Scale',
      },
      HRA2: {
        name: 'HRA',
        subtitle: 'Rockwell A Scale',
      },
      HRB: {
        name: 'HRB',
        subtitle: 'Rockwell B Hardness',
      },
      HRC: {
        name: 'HRC',
        subtitle: 'Rockwell C Hardness',
      },
      HRD: {
        name: 'HRD',
        subtitle: 'Rockwell D Scale',
      },
      HRF: {
        name: 'HRF',
        subtitle: 'Rockwell F Hardness',
      },
      HSC: {
        name: 'HSc',
        subtitle: 'HSc Scleroscope Hardness',
      },
      HV: {
        name: 'HV',
        subtitle: 'Vickers Hardness',
      },
      HV2: {
        name: 'HV',
        subtitle: 'Vickers Hardness',
      },
      LMIN_TO_GPM: 'l/min to US gpm',
      M3PH_TO_FT3PH: 'm³/h to cfh',
      ac: 'ac',
      actualScale: 'Actual Scale',
      aisi: 'AISI',
      al: 'Al',
      alloyFactor: 'Legierungsfaktor',
      baseC: 'Base C',
      c: 'C',
      carbonPotential: 'Carbon Potenzial',
      cch4: 'C + CH₄',
      ccontent: 'C-Content',
      ch3oh: 'CH₃OH',
      ch4: 'CH₄',
      ch4Factor: 'CH₄ Factor',
      co: 'CO',
      co2: 'CO₂',
      coTotalFlow: 'CO/Total Flow',
      coco2: 'CO/CO₂',
      coldJunctionTemp: 'Cold-Junction Temp.',
      cpatm: 'CPatm',
      cr: 'Cr',
      cu: 'Cu',
      dewPoint: 'Dew Point',
      din: 'DIN',
      dissBurette: 'Diss. Burette',
      dissDegree: 'Diss. Degree',
      dnh3: 'D-NH₃',
      dpt: 'DPT',
      emf: 'EMF',
      floMeterScale: 'Flo-Meter Scale',
      flow: 'Flow',
      gas: 'Gas',
      h2: 'H₂',
      h2n2: 'H₂:N₂',
      h2o: 'H₂O',
      hardness: 'Hardness',
      kcb: 'Kᴄʙ',
      kh: 'Kʜ',
      kn: 'Kɴ',
      ko: 'Kᴏ',
      mn: 'Mn',
      mo: 'Mo',
      mv: 'mV',
      n2: 'N₂',
      n2Percentage: 'N₂',
      n2ch3oh: 'N₂/CH₃OH',
      nc2: 'NC₂',
      nh3: 'NH₃',
      ni: 'Ni',
      o2: 'O₂',
      o2Percentage: 'O₂',
      o2Probe: 'O₂ Probe',
      others: 'Others',
      p1: 'P1',
      p2: 'P2',
      po2: 'pO₂',
      preload: 'Preload',
      pt10: {
        description: '(Pt10) Platinum RTD with Nominal Resistance of 10 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt10) Platinum RTD',
      },
      pt100: {
        description: '(Pt100) Platinum RTD with Nominal Resistance of 100 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt100) Platinum RTD',
      },
      pt1000: {
        description: '(Pt1000) Platinum RTD with Nominal Resistance of 1000 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt1000) Platinum RTD',
      },
      pt500: {
        description: '(Pt500) Platinum RTD with Nominal Resistance of 500 Ohms at 0°C (32°F).\nRange: -200°C - 850°C (-328°F - 1562°F) as per IEC 751 / DIN EN 60751.',
        name: '(Pt500) Platinum RTD',
      },
      resistance: 'Resistance',
      rg: 'RG',
      rl: 'RL',
      scaleFactor: 'Scale Factor',
      sg1: 'SG1',
      sg2: 'SG2',
      si: 'Si',
      sootLimit: 'Soot Limit',
      tc_b: {
        description: 'Plus:Platinum 30% Rhodium(Pt30Rh)\nMinus:Platinum 6% Rhodium (Pt6Rh)\nRange: -50°C-1820°C (-58°F-3308°F)\nColors according to ANSI 96.6.\nAccording to NFC 42-324, colors are yellow and gray',
        name: 'Type B = Pt30Rh-Pt6Rh',
      },
      tc_e: {
        description: 'Plus:Nickel-Chromium (NiCr), also\nknown as Chromel\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -270°C-1000°C (-454°F-1832°F)\nColors according to DIN EN 60584-1',
        name: 'Type E = NiCr-CuNi',
      },
      tc_j: {
        description: 'Plus:Copper(Cu), Copper Color\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -270°C-400°C (-454°F-752°F)\nColors according to DIN EN 60584-1',
        name: 'Type J = Fe-CuNi',
      },
      tc_k: {
        description: 'Plus:Nickel-Chromium (NiCr), also\nknown as Chromel\nMinus:Nickel Aluminum (NiAl) also\nknown as Alumel, magnetic wire\nRange: -270°C-1372°C (-454°F-2502°F)\nColors according to DIN EN 60584-1',
        name: 'Type K = NiCr-Ni',
      },
      tc_l: {
        description: 'Plus:Iron (Fe), magnetic wire\nMinus:Copper-Nicker(CuNi), also\nknown as Constantan\nRange: 0°C-900°C (32°F-1652°F)\nColors according to DIN EN 43714',
        name: 'Type L = Fe-CuNi',
      },
      tc_n: {
        description: 'Plus:Nickel-Chromium-Silicon (NiCrSi),\nalso known as Nicrosil\nMinus:Nickel-Silicon (NiSi), also\nknown as Nisil\nRange: -270°C-1300°C (-454°F-2372°F)\nColors according to DIN EN 60584-1',
        name: 'Type N = NiCrSi-NiSi',
      },
      tc_r: {
        description: 'Plus:Platinum 13% Rhodium (Pt13Rh)\nMinus:Platinum(Pt), minus wire softer\nthan plus wire\nRange: -50°C-1768°C (-58°F-3214°F)\nColors according to DIN EN 60584-1',
        name: 'Type R = Pt13Rh-Pt',
      },
      tc_s: {
        description: 'Plus:Platinum 10% Rhodium (Pt10Rh)\nMinus:Platinum(Pt), minus wire softer\nthan plus wire\nRange: -50°C-1768°C (-58°F-3214°F)\nColors according to DIN EN 60584-1',
        name: 'Type S = Pt10Rh-Pt',
      },
      tc_t: {
        description: 'Plus:Iron (Fe), magnetic wire\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: -210°C-1200°C (-346°F-2192°F)\nColors according to DIN EN 60584-1',
        name: 'Type T = Cu-CuNi',
      },
      tc_u: {
        description: 'Plus:Copper(Cu), Copper Color\nMinus:Copper-Nickel (CuNi), also\nknown as Constantan\nRange: 0°C-600°C (32°F-1112°F)\nColors according to DIN EN 43714',
        name: 'Type U = Cu-CuNi',
      },
      temp1: 'Temp.1',
      temp2: 'Temp.2',
      temperature: 'Temperatur',
      total: 'Total',
      totalFlow: 'Total Flow',
      v: 'V',
      voltage: 'Voltage',
      weight1: 'Weight₁',
      weight2: 'Weight₂',
    },
    metricUnits: 'metrisch',
    mustBeInteger: 'Muss ganze Zahl sein',
    norm: 'Norm',
    outOfRange: 'Außerhalb des zulässigen Wertebereichs',
    potentials: 'Potenziale',
    range: 'Bereich',
    units: 'Einheiten',
  },
  validation: {
    ProcessCodeRequired: 'Vorgangscode und Beschreibung erforderliches Feld',
    decimalValue: 'Max {decimalValue} Nachkommastellen',
    file: 'Datei ist erforderlich',
    filesTooBig: 'Die Gesamtgröße der Dateien sollte weniger als 5 MB betragen.',
    greaterBandValue: 'Umschlag Plus muss größer sein als Minus',
    greaterOrEqual: 'Wert sollte gleich oder höher sein {minValue}',
    idIsUsed: 'Id wird verwendet',
    integerValue: 'Der Wert sollte eine ganze Zahl sein',
    invalidEmailFormat: 'Ungültiges E-Mail-Format',
    isInteger: 'Der Wert muss eine ganze Zahl sein',
    lessOrEqual: 'Wert sollte gleich oder niedriger sein {maxValue}',
    lowerBandValue: 'Hüllkurvenminus muss niedriger sein als Plus',
    maxValue: 'Der Wert sollte darunter liegen {maxValue}',
    minValue: 'Wert sollte oben sein {minValue}',
    nameAlreadyExists: 'Name existiert bereits',
    notEmptySpace: 'Text darf keine Leerzeichen beinhalten',
    numberMustBeGreaterThan: 'Zahl muss größer als {number} sein',
    oneValue: 'Muss mindestens einen Wert enthalten',
    required: 'Pflichtfeld',
    requiredFormat: 'Erforderliches Format {format}',
    tooBigFile: 'Die Dateigröße sollte weniger als 50 MB betragen',
    tooManyFiles: 'Sie können nicht mehr als 3 Dateien hochladen',
    userAlreadyExists: 'Benutzer mit angegebener E-Mail existiert bereits',
    valueBelowMin: 'Wert ist kleiner als Min',
    // new
    fileTooBig: 'Die Dateigröße sollte weniger als {size} betragen.',
  },
  util: {
    schaefflerChart: {
      title: 'Schäffler Diagramm',
      CrEquivalent: 'Cr-Äquivalent',
      NiEquivalent: 'Ni-Äquivalent',
    },
  },
  ...getComponentScopedTranslationMap('page', 'de'),
  ...getComponentScopedTranslationMap('components', 'de'),
  ...getComponentScopedTranslationMap('pt9800', 'de'),
};
