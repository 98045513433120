import {
  i18n,
} from '@i18n';
import dayjs, {
  UnitType,
} from 'dayjs';
import {
  TSupportedLocale,
} from '@/types/i18n.ts';

export const CUSTOM_RANGE_ID = 'CALENDAR_CUSTOM_RANGE_ID';

export enum ECustomRangeType {
  CUSTOM = 'custom',
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

export type TTimeUnit = Extract<UnitType, 'days' | 'months' | 'years'> | 'weeks' | 'quarters' | 'isoWeek';
export type TRangeId = string | number;
export type TAbsolutTimeUnit = 'isoWeek' | 'months' | 'quarters' | 'years';
export interface IRange {
  id: TRangeId,
  from: Date,
  to: Date,
  label: string,
  // #region will be emitted for custom range
  isCustom?: boolean,
  unit?: TTimeUnit,
  type?: ECustomRangeType,
  // #endregion
}

export function getCurrentWeekRange(locale: TSupportedLocale): {
  label: string,
  from: Date,
  to: Date,
} {
  return {
    label: i18n.global.t('component.dateRangePicker.currentWeek'),
    from: dayjs().locale(locale).startOf('week').toDate(),
    to: new Date(),
  };
}

// enum for reusing same IDs on different pages
export enum ERrangeId {
  CURRENT_WEEK = 'currentWeek',
  MINUTE_30 = '30min',
  HOUR_1 = '1h',
  HOUR_2 = '2h',
  HOUR_4 = '4h',
  HOUR_12 = '12h',
  HOUR_24 = '24h',
  DAY_7 = '7d',
  WEEK_1 = '1w',
  MONTH_1 = '1m',
  MONTH_3 = '3m',
  MONTH_6 = '6m',
  YEAR_1 = '1y',
  YEAR_2 = '2y',
  YEAR_5 = '5y',
}

export function getDefaultRangeList(locale: TSupportedLocale) {
  return [
    {
      id: ERrangeId.CURRENT_WEEK,
      ...getCurrentWeekRange(locale),
    },
    {
      id: ERrangeId.DAY_7,
      label: '7d',
      from: dayjs().subtract(7, 'days').toDate(),
      to: new Date(),
    },
    {
      id: ERrangeId.MONTH_1,
      label: '1m',
      from: dayjs().subtract(1, 'month').toDate(),
      to: new Date(),
    },
    {
      id: ERrangeId.MONTH_3,
      label: '3m',
      from: dayjs().subtract(3, 'months').toDate(),
      to: new Date(),
    },
    {
      id: ERrangeId.YEAR_1,
      label: '1y',
      from: dayjs().subtract(1, 'year').toDate(),
      to: new Date(),
    },
  ];
}
