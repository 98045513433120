export default {
  unitLabel: {
    potential: 'C/N Potential',
    content: 'C/N Content',
    depth: 'Depth',
    hardness: 'Hardness',
    carbDepth: 'Carburizing Depth',
  },
  profile: {
    carbonProfile: 'Carbon profile',
    nitrogenProfile: 'Nitrogen profile',
    carbideProfile: 'Carbide profile',
  },
  legendLabel: 'Legend',
};
