export const statusColor = [
  '#CB3301',
  '#FF4508',
  '#FF875A',
  '#FFCC00',
  '#0F9D0E',
];

export const requestCategories = (i18n) => [
  {
    text: i18n.tm('requestCategory.sales'),
    value: 1,
  },
  {
    text: i18n.tm('requestCategory.service'),
    value: 2,
  },
  {
    text: i18n.tm('requestCategory.emergency'),
    value: 3,
  },
  {
    text: i18n.tm('requestCategory.software'),
    value: 4,
  },
];

export const customerRequestPriority = (i18n) => [
  {
    text: i18n.t('levels.low'),
    value: '1',
  },
  {
    text: i18n.t('levels.medium'),
    value: '2',
  },
  {
    text: i18n.t('levels.high'),
    value: '3',
  },
];

export const batchRating = (i18n) => [
  {
    text: i18n.t('batchStatuses.notGood'),
    value: false,
  },
  {
    text: i18n.t('batchStatuses.allGood'),
    value: true,
  },
];

export const criticality = (i18n) => [
  {
    value: '1',
    text: i18n.t('levels.low'),
  },
  {
    value: '2',
    text: i18n.t('levels.medium'),
  },
  {
    value: '3',
    text: i18n.t('levels.high'),
  },
  {
    value: '4',
    text: i18n.t('levels.critical'),
  },
];

export const ainObjectType = {
  FL: 'FL',
  EQU: 'EQU',
  PRT: 'PRT',
};

export const locLevels = {
  customer: 'customer',
  location: 'location',
  group: 'group',
  equipment: 'equipment',
  load: 'load',
};

export const SCADA_TYPE = Object.freeze({
  NPM: 'npm',
  PT9800: 'pt9800',
});
