import {
  components,
} from '@/types/openapi.ts';

export enum ESettingsSectionType {
  DEFAULT = 'DEFAULT',
  REMOTE_CONTROL_PT9800 = 'REMOTE_CONTROL_PT9800',
}

export const settingsSectionType: Record<components['schemas']['SettingsSectionType'], keyof typeof ESettingsSectionType> = {
  DEFAULT: 'DEFAULT',
  REMOTE_CONTROL_PT9800: 'REMOTE_CONTROL_PT9800',
};
