export default {
  title: 'Maintenance Occurrence: {id}',
  addAttachments: 'Add attachments',
  addReport: 'Attach report',
  isValidated: 'Validated',
  stateAction: {
    accept: 'Accept',
    finish: 'Finish',
    reject: 'Reject',
    restart: 'Restart',
    start: 'Start',
    stop: 'Stop',
    validate: 'Validate',
  },
  stateChangeSuccessMessage: 'The state has been changed',
  stateChangeErrorMessage: 'Change state failed',
  restartConfirmation: 'Restarting will delete any already uploaded report. Do you want to proceed?',
  validateConfirmation: 'Do you want to validate it?',
};
