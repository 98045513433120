export default {
  addSample: 'Muster hinzufügen',
  createOneTimePart: 'Einmaliges Teil erstellen',
  sample: {
    defaultDisplayName: 'Muster',
  },
  tableTest: {
    defaultDisplayName: 'Tabelle',
  },
  chartTest: {
    defaultDisplayName: 'Diagramm',
  },
  imageTest: {
    defaultDisplayName: 'Bild',
  },
  fileTest: {
    defaultDisplayName: 'Datei',
  },
};
