import {
  TSupportedLocale,
} from '@/types/i18n.ts';

/**
 * Converts the path of a local translations file into a key to be used in i18n.
 *
 * @param path - example: "/src/page/quality/quality.i18n.en.ts"
 * @param fileSuffix - example: .i18n.en.ts
 * @retuns example: page/quality/quality
 */
function pathToKey(path: string, fileSuffix: string) {
  const trimmedSrc = path.split('/src/')[1];
  const parts = trimmedSrc.split('/')
    .map((_part) => (_part.endsWith(fileSuffix)
      ? _part.substring(0, _part.length - fileSuffix.length)
      : _part));
  return parts.join('/');
}

interface ITranslation {
  [key: string]: ITranslation | string,
}
type TImportedTranslation = {
  default: ITranslation,
}

/*
 * Read translations from local *.i18n.LOCALE.ts files.
 *
 * @param location - To fetch for pages or components
 * @param locale - Determines which files to read by reading only *.i18n.LOCALE.ts
 */
export function getComponentScopedTranslationMap(location: 'page' | 'components' | 'pt9800', locale: TSupportedLocale) {
  const fileSuffix = `.i18n.${locale}.ts`;

  // This nested switch case is required, because import.meta.glob requires literals
  // It can not work with variables not template literals.
  let importedTranslationsMap: Record<string, TImportedTranslation> = {};
  switch (location) {
    case 'pt9800': {
      switch (locale) {
        case ('en'): {
          importedTranslationsMap = import.meta.glob('@/pt9800/**/*.i18n.en.ts', {
            eager: true,
          });
          break;
        }
        case ('de'): {
          importedTranslationsMap = import.meta.glob('@/pt9800/**/*.i18n.de.ts', {
            eager: true,
          });
          break;
        }
        default:
          break;
      }
      break;
    }
    case 'components': {
      switch (locale) {
        case ('en'): {
          importedTranslationsMap = import.meta.glob('@/components/**/*.i18n.en.ts', {
            eager: true,
          });
          break;
        }
        case ('de'): {
          importedTranslationsMap = import.meta.glob('@/components/**/*.i18n.de.ts', {
            eager: true,
          });
          break;
        }
        default:
          break;
      }
      break;
    }
    case 'page': {
      switch (locale) {
        case ('en'): {
          importedTranslationsMap = import.meta.glob('@/page/**/*.i18n.en.ts', {
            eager: true,
          });
          break;
        }
        case ('de'): {
          importedTranslationsMap = import.meta.glob('@/page/**/*.i18n.de.ts', {
            eager: true,
          });
          break;
        }
        default: break;
      }
      break;
    }
    default: break;
  }

  const translationsKeyList = Object.entries(importedTranslationsMap).map(([
    path,
    trans,
  ]) => ({
    key: pathToKey(path, fileSuffix),
    trl: trans.default,
  }));

  const translationMap: Record<string, ITranslation> = {};
  translationsKeyList.forEach((_trl) => {
    translationMap[_trl.key] = _trl.trl;
  });
  return translationMap;
}
