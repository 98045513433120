export default {
  overview: {
    locationDepartment: 'Standort/Abteilung:',
    certificate: {
      pyrometry: 'AMS2750: Pyrometrie',
      furnaceClass: 'Ofenklasse {value}',
      instrumentationType: 'Instrumentierungstyp {value}',
      heatTreatment: 'AMS2759: Wärmebehandlung von Stahlteilen',
    },
    capability: {
      gasChip: '{name} - {maxFlow} L/min',
    },
    capacity: {
      minTemp: 'Mindesttemperatur: {value} °C',
      maxTemp: 'Maximaltemperatur: {value} °C',
      dimensions: 'Abmessungen: {value} mm',
      maxLoad: 'Maximale Belastung: {value} kg',
    },
    statistics: {
      targetProductivity: 'Produktivitätsziel: {value}%',
    },
  },
};
