export const ResponseUtils = {
  /**
   * Checks if error has 401 status code
   * @param {Error} error
   * @returns {Boolean} true if it has 401 status code, false otherwise
   */
  is401StatusCode(error) {
    return this.isStatusCode(error, 401);
  },

  /**
   * Checks if error is axios error and has status code specified in params
   * @param {Error} error
   * @param {Number} statusCode number representing http status code
   * @returns {Boolean}
   */
  isStatusCode(error, statusCode) {
    return error.response && error.response.status == statusCode;
  },

  /**
   * Checks if error response url contains keyword
   * @param {Error} error
   * @param {String} keyWord
   */
  urlContains(error, keyWord) {
    return error.config.url.includes(keyWord);
  },

  /**
   * Parses the given header to get the file name specified in it
   * @param {String} header value of the header
   * @returns {String}
   */
  getFileNameFromHeader(header) {
    let fileName = header.replace("UTF-8''", ' ');
    fileName = fileName.substring(fileName.indexOf('=') + 1);
    return fileName.replace('+', ' ');
  },

  /**
   *Converts string to pascal case
   * @param {String} word
   * @returns {String|null}
   */
  wordToPascalCase(word) {
    if (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    }
    return null;
  },

  /**
   * Maps the equipment response to an object with the necessary attributes
   * @param {object} equipment
   * @returns {Object}
   */
  mapEquipment(equipment) {
    try {
      return {
        id: equipment.equipmentId,
        equipmentID: equipment.name,
        type: equipment.modelName
          ? equipment.modelName
          : this.nullTypePlaceholder,
        scadaType: equipment.scadaType,
        status: equipment.status,
        notifications: equipment.notificationStatus
          ? equipment.notificationStatus.totalCount
          : undefined,
        location: equipment.location,
        locationID: equipment.locationId,
        systemId: equipment.systemId,
        customerName: equipment.customerName,
        description: equipment.shortDescription,
        imageURL: equipment.imageURL,
        shortName: equipment.shortName,
        department: equipment.department,
      };
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  /**
   * Checks if id param has a value
   * @returns {Boolean} true if it has a value, throw error otherwise
   */
  validateId(id, paramName) {
    if (!id) {
      throw new Error(`Missing value ${paramName}`);
    }
    return true;
  },
};
