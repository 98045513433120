import {
  createStore,
} from 'vuex';
import mainModule from './mainModule';
import equipmentJobStore from '@/store/modules/equipmentJobStore';
import graphStore from '@/store/modules/graphStore';
import eventsStore from '@/store/modules/eventsStore';
import ProductionLogsStore from '@/store/modules/productionLogsStore';
import operationDashboard from '@/store/modules/operationDashboard';
import maintenanceDashboard from '@/store/modules/maintenanceDashboard';
import anomalyDetection from '@/store/modules/anomalyDetection';
import partStore from '@/store/modules/simulator/partStore';
import recipeStore from '@/store/modules/simulator/recipeStore';
import chartStore from '@/store/modules/simulator/chartStore';

mainModule.modules.equipmentDetailsStore.modules.equipmentJobStore = equipmentJobStore;
mainModule.modules.equipmentDetailsStore.modules.graphStore = graphStore;
mainModule.modules.equipmentDetailsStore.modules.eventsStore = eventsStore;
mainModule.modules.equipmentDetailsStore.modules.ProductionLogsStore = ProductionLogsStore;
mainModule.modules.equipmentDetailsStore.modules.operationDashboard = operationDashboard;
mainModule.modules.equipmentDetailsStore.modules.maintenanceDashboard = maintenanceDashboard;
mainModule.modules.equipmentDetailsStore.modules.anomalyDetection = anomalyDetection;

mainModule.modules.simulatorStore.modules.partStore = partStore;
mainModule.modules.simulatorStore.modules.recipeStore = recipeStore;
mainModule.modules.simulatorStore.modules.chartStore = chartStore;

mainModule.modules.equipmentDetailsStore.actions.REQUEST_EQUIPMENT_DETAILS = (
  context,
  {
    id, status, documentImageWidth,
  },
) => {
  context.dispatch('REQUEST_EQUIPMENT_DETAILS_CORE', {
    id,
    status,
    documentImageWidth,
  });
};

mainModule.modules.equipmentDetailsStore.actions.CLEAR_EQUIPMENT_DETAILS = (
  context,
) => {
  context.dispatch('CLEAR_EQUIPMENT_DETAILS_CORE');
  context.dispatch('CLEAR_EQUIPMENT_JOB');
};

const store = createStore(mainModule);
export default store;
