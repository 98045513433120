import {
  CALENDAR_PATH,
  EVENTS_PATH,
  SEARCH_PATH,
  USERS_PATH,
  GROUPS_PATH,
  WORKPLACES_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const EventsService = {
  getEventsList(from, to) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${EVENTS_PATH}?from=${from}&to=${to}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getEventsDelayedList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${EVENTS_PATH}?type=delayed`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getEventsUpcomingList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${EVENTS_PATH}?type=upcoming`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getEventItem(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${EVENTS_PATH}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getUsersList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${SEARCH_PATH}/${USERS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getUsersGroupsList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${SEARCH_PATH}/${GROUPS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getWorkplacesList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${CALENDAR_PATH}/${SEARCH_PATH}/${WORKPLACES_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   *
   * @param {Object} data
   * @returns {Promise<Object>}
   */
  async createEvent(data) {
    const response = await portalApi.post(
      `${CALENDAR_PATH}/${EVENTS_PATH}`,
      data,
    );
    return response.data;
  },
  /**
   * @param {Object} data
   * @returns {Promise<Object>}
   */
  async updateEvent(data) {
    const response = await portalApi.put(
      `${CALENDAR_PATH}/${EVENTS_PATH}`,
      data,
    );
    return response.data;
  },
  /**
   * @param {String} id
   * @returns {Promise<Object>}
   */
  async deleteEvent(id) {
    const response = await portalApi.delete(
      `${CALENDAR_PATH}/${EVENTS_PATH}/${id}`,
    );
    return response.data;
  },
};
