import {
  TCsvQuoteStyleTranslations,
} from './select-csv-quote-style.ts';

const translations: TCsvQuoteStyleTranslations = {
  necessary: {
    title: 'Notwendig',
    description: 'Dies setzt Anführungszeichen um Felder nur, wenn es notwendig ist. Sie sind notwendig, wenn Felder ein Anführungszeichen, Trennzeichen oder einen Datensatzbegrenzer enthalten. Anführungszeichen sind auch notwendig, wenn ein leerer Datensatz geschrieben wird (der nicht von einem Datensatz mit einem leeren Feld unterschieden werden kann). Dies ist die Standardoption.',
  },
  always: {
    title: 'Immer',
    description: 'Dies setzt Anführungszeichen um jedes Feld. Immer.',
  },
  non_numeric: {
    title: 'Nicht-numerisch',
    description: 'Dies setzt Anführungszeichen um alle Felder, die nicht-numerisch sind. Nämlich, wenn ein Feld geschrieben wird, das nicht als gültige Fließkommazahl oder Ganzzahl interpretiert werden kann, werden Anführungszeichen verwendet, selbst wenn sie nicht strikt notwendig sind.',
  },
  never: {
    title: 'Nie',
    description: 'Dies setzt niemals Anführungszeichen um Felder, selbst wenn dies zu ungültigen CSV-Daten führt (z. B.: indem Strings, die das Trennzeichen enthalten, nicht in Anführungszeichen gesetzt werden).',
  },
};

export default {
  placeholder: '',
  label: 'Quote style',
  options: translations,
};
