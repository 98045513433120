export default {
  header: 'Edit',
  general: {
    tabLabel: 'General',
    furnaceNameLabel: 'Furnace name',
    furnaceTypeLabel: 'Furnace Type',
    departmentLabel: 'Department',
  },
  certificate: {
    tabLabel: 'Certificates',
    ams: {
      amsGroupLabel: 'AMS',
      pyrometry: {
        pyrometryCheckbox: 'AMS2750: Pyrometry',
        furnaceClass: 'Furnace class',
        instrumentationType: 'Instrumentation type',
      },
      heatTreatmentOfSteelParts: 'AMS2759: Heat Treatment of steel parts',
    },
    others: {
      objectthersGroupLabel: 'Others',
      newCertificatesPlaceholder: 'new other certificates',
      instrumentationTypePlaceholder: 'Instrumentation type',
    },
  },
  capability: {
    tabLabel: 'Capabilities',
    otherCapabilitiesPlaceholder: 'Other Capabilities',
    temperature: {
      temperatureGroupLabel: 'Temperature',
      maxTempLabel: 'Max Temp',
      minTempLabel: 'Min Temp',
    },
    dimensions: {
      dimensionsGroupLabel: 'Dimensions',
      diameterLabel: 'Diameter',
      widthLabel: 'Width',
      lengthLabel: 'Length',
      heightLabel: 'Height',
      maxLoadLabel: 'Max Load',
    },
  },
  statistics: {
    targetProductivityLabel: 'Target productivity',
  },
};
