import groupBy from 'lodash/groupBy';
import {
  EventsService,
} from '@/services/EventsService';

const getEventIndexById = (array, id) => {
  return array.findIndex((e) => e.id === id);
};

export const getEventStatus = (event) => {
  const now = new Date().getTime();
  if (event.time_planed > now) {
    return 1;
  } if (
    event.time_end < now
    && (event.remind_status === 0
      || event.remind_status === 1
      || event.remind_status === 2)
  ) {
    return -1;
  }
  return 0;
};

export default {
  state: {
    events: [],
    eventsDelayed: [],
    eventsUpcoming: [],
    event: {},
    users: [],
    groups: [],
    workplaces: [],
    isLoading: false,
  },
  mutations: {
    setEventsList(state, eventsList) {
      state.events = eventsList;
    },
    setEventsDelayedList(state, eventsList) {
      state.eventsDelayed = eventsList;
    },
    setEventsUpcomingList(state, eventsList) {
      state.eventsUpcoming = eventsList;
    },
    setEventItem(state, event) {
      state.event = event;
    },
    addEventItem(state, event) {
      state.events.push(event);
      const status = getEventStatus(event);
      if (status === -1) {
        state.eventsDelayed.push(event);
      } else if (status === 1) {
        state.eventsUpcoming.push(event);
      }
    },
    updateEventItem(state, event) {
      const index = getEventIndexById(state.events, event.id);
      if (index !== -1) {
        const newEvents = [
          ...state.events,
        ];
        newEvents[index] = event;
        state.events = newEvents;
      }

      const list = [
        ...state.eventsDelayed,
        ...state.eventsUpcoming,
      ];
      const delayedUpcomingIndex = getEventIndexById(list, event.id);
      const status = getEventStatus(event);
      if (delayedUpcomingIndex !== -1) {
        if (status === 0) {
          list.splice(delayedUpcomingIndex, 1);
        } else {
          list[delayedUpcomingIndex] = event;
        }
      } else if (status !== 0) {
        list.push(event);
      }
      const groupedList = groupBy(list, (e) => getEventStatus(e));
      state.eventsDelayed = groupedList[-1] || [];
      state.eventsUpcoming = groupedList[1] || [];
    },
    deleteEventItem(state, event) {
      const index = getEventIndexById(state.events, event.id);
      if (index !== -1) {
        state.events.splice(index, 1);
      }
      const delayedIndex = getEventIndexById(state.eventsDelayed, event.id);
      if (delayedIndex !== -1) {
        state.eventsDelayed.splice(delayedIndex, 1);
      }
      const upcomingIndex = getEventIndexById(state.eventsUpcoming, event.id);
      if (upcomingIndex !== -1) {
        state.eventsUpcoming.splice(upcomingIndex, 1);
      }
    },
    setUsersList(state, users) {
      state.users = users;
    },
    setGroupsList(state, groups) {
      state.groups = groups;
    },
    setWorkplacesList(state, workplaces) {
      state.workplaces = workplaces;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    clearEvents(state) {
      state.events = [];
      state.event = {};
    },
  },
  actions: {
    CLEAR_EVENTS(context) {
      context.commit('clearEvents');
    },
    async SET_EVENTS(context, {
      from, to,
    }) {
      context.commit('setIsLoading', true);
      context.commit('setEventsList', []);
      try {
        context.commit(
          'setEventsList',
          await EventsService.getEventsList(from, to),
        );
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async SET_EVENTS_DELAYED(context) {
      context.commit('setIsLoading', true);
      try {
        context.commit(
          'setEventsDelayedList',
          await EventsService.getEventsDelayedList(),
        );
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async SET_EVENTS_UPCOMING(context) {
      context.commit('setIsLoading', true);
      try {
        context.commit(
          'setEventsUpcomingList',
          await EventsService.getEventsUpcomingList(),
        );
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async SET_EVENT(context, eventId) {
      context.commit('setIsLoading', true);
      context.commit('setEventItem', {});
      try {
        if (eventId) {
          context.commit(
            'setEventItem',
            await EventsService.getEventItem(eventId),
          );
        }
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async CREATE_EVENT(context, event) {
      context.commit('setIsLoading', true);
      try {
        context.commit('addEventItem', await EventsService.createEvent(event));
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async UPDATE_EVENT(context, event) {
      context.commit('setIsLoading', true);
      try {
        context.commit(
          'updateEventItem',
          await EventsService.updateEvent(event),
        );
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async DELETE_EVENT(context, id) {
      context.commit('setIsLoading', true);
      try {
        context.commit('deleteEventItem', await EventsService.deleteEvent(id));
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async SET_USERS_LIST(context) {
      context.commit('setIsLoading', true);
      context.commit('setUsersList', []);
      try {
        context.commit('setUsersList', await EventsService.getUsersList());
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async SET_GROUPS_LIST(context) {
      context.commit('setIsLoading', true);
      context.commit('setGroupsList', []);
      try {
        context.commit(
          'setGroupsList',
          await EventsService.getUsersGroupsList(),
        );
      } finally {
        context.commit('setIsLoading', false);
      }
    },
    async SET_WORKPLACES_LIST(context) {
      context.commit('setIsLoading', true);
      context.commit('setWorkplacesList', []);
      try {
        context.commit(
          'setWorkplacesList',
          await EventsService.getWorkplacesList(),
        );
      } finally {
        context.commit('setIsLoading', false);
      }
    },
  },
};
