import {
  TokenService,
} from '@/services/TokenService';

const authenticationHeaderPrefix = 'Bearer ';

class FetchApiClient {
  constructor(config) {
    const {
      baseURL, headers,
    } = config;
    this.baseURL = baseURL;
    this.headers = headers || {};
  }

  setHeader(key, value) {
    this.headers[key] = value;
  }

  removeHeader(key) {
    delete this.headers[key];
  }

  fetch(method, path, data, opts = {}) {
    let fullUrl = this.baseURL
      ? `${this.baseURL.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`
      : path;

    if (Object.keys(opts.params).length > 0 && path.indexOf('?') === -1) {
      const queryParams = new URLSearchParams(opts.params);
      fullUrl = `${fullUrl}?${queryParams.toString()}`;
    }

    this.headers.Authorization = authenticationHeaderPrefix + TokenService.getAccessToken();

    const init = {
      headers: {
        ...(this.baseURL ? this.headers : {}),
        'Content-Type': 'application/json',
      },
      method,
      signal: opts.signal,
    };

    if (data) {
      init.body = JSON.stringify(data);
    }

    return fetch(fullUrl, init);
  }

  get(path, opts) {
    return this.fetch('GET', path, null, opts);
  }

  delete(path, opts) {
    return this.fetch('DELETE', path, null, opts);
  }

  post(path, data, opts) {
    return this.fetch('POST', path, data, opts);
  }

  put(path, data, opts) {
    return this.fetch('PUT', path, data, opts);
  }
}

export default {
  create: (config) => new FetchApiClient(config),
};
