import {
  portalApi,
} from '@/services/AxiosService';
import {
  MODEL_PROCESS_PATH, CHART,
} from '@/constants/apiPaths';

export const ModelProcessService = {
  getTunnelChart(id = '') {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MODEL_PROCESS_PATH}/${CHART}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getModelProcessList(location, furnace, recipe) {
    let params = '';
    if (location) {
      params += `?location=${location}`;
    }
    if (furnace) {
      params += `&furnace=${furnace}`;
    }
    if (recipe) {
      params += `&recipe=${encodeURIComponent(recipe)}`;
    }
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MODEL_PROCESS_PATH}${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createNewModelProcess(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${MODEL_PROCESS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  removeModelProcess(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${MODEL_PROCESS_PATH}`, {
          params: {
            model_process_id: id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
