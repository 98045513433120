import {
  OperationDashboardService,
} from '@/services/OperationDashboard.js';
import {
  GraphsService,
} from '@/services/GraphsService';
import {
  JobService,
} from '@/services/JobService';
import {
  chartColors,
} from '@/config/colorsMap';
import moment from '@/utils/moment';
import {
  ProductionLogsService,
} from '@/services/ProductionLogsService';

export default {
  state: {
    furnaces: [],
    finishedJobs: [],
    furnaceDetails: {},
    notifications: [],
    ganttData: [],
    kpis: [],
    furnacesLocation: '',
    furnacesLoading: false,
    finishedJobsLoading: false,
    furnaceDetailsLoading: false,
    loadingNotifications: false,
    loadingGraphs: true,
    loadingGantt: true,
    kpisLoading: false,
  },
  mutations: {
    setFurnaces(state, furnaces) {
      state.furnaces = furnaces;
    },
    setGanttData(state, furnaces) {
      state.ganttData = furnaces;
    },
    setFurnacesLoading(state, isLoading) {
      state.furnacesLoading = isLoading;
    },
    setFinishedJobsLoading(state, isLoading) {
      state.finishedJobsLoading = isLoading;
    },
    setFurnacesLocation(state, locationId) {
      state.furnacesLocation = locationId;
    },
    setFurnaceDetailsLoading(state, isLoading) {
      state.furnaceDetailsLoading = isLoading;
    },
    setFinishedJobs(state, jobs) {
      state.finishedJobs = jobs;
    },
    setFurnaceDetails(state, furnace) {
      state.furnaceDetails = furnace;
    },
    setIsLoadingGraphs(state, isLoading) {
      state.loadingGraphs = isLoading;
    },
    setIsLoadingGanttData(state, isLoading) {
      state.loadingGantt = isLoading;
    },
    setNotifications(state, notes) {
      state.notifications = notes;
    },
    setLoadingNotifications(state, isLoading) {
      state.loadingNotifications = isLoading;
    },
    setKpisLoading(state, isLoading) {
      state.kpisLoading = isLoading;
    },
    setKpis(state, kpis) {
      state.kpis = kpis;
    },
  },
  actions: {
    async SET_NOTES(context, {
      jobId, locationName,
    }) {
      context.commit('setLoadingNotifications', true);
      context.commit(
        'setNotifications',
        await JobService.getNotes({
          jobId,
          locationName,
        }),
      );
      context.commit('setLoadingNotifications', false);
    },
    async SET_OPERATION_DASHBOARD_DETAILS(context, equipment_id) {
      try {
        context.commit('setFurnaceDetailsLoading', true);
        const furnace = await OperationDashboardService.getFurnaceDetail(
          equipment_id,
        );
        context.commit('setIsLoadingGraphs', true);
        if (!furnace.details.status) {
          furnace.details.no_data = true;
          return;
        }
        let mainGraphDatasets = [];
        let secondaryGraphDatasets = [];
        const mainGraph = await GraphsService.getOverviewGraph(
          equipment_id,
          true,
        );
        let labels = [];
        if (!('no_data' in mainGraph) && mainGraph.chart.datasets.length > 0) {
          labels = [
            ...mainGraph.chart.labels,
          ];
          mainGraphDatasets = mainGraph.chart.datasets.map((dataset) => {
            if (dataset.borderColor === '#000000') {
              if (dataset.is_data_mapped === false) {
                dataset.borderColor = chartColors[dataset.descMod];
                dataset.backgroundColor = chartColors[dataset.descMod];
              } else {
                dataset.backgroundColor = dataset.borderColor;
              }
            }
            return dataset;
          });
          furnace.is_data_mapped = mainGraph.is_data_mapped;
          furnace.mainGraph = {
            datasets: mainGraphDatasets,
            labels,
          };
        } else {
          furnace.is_data_mapped = mainGraph.is_data_mapped;
          furnace.mainGraph = {
            no_data: true,
          };
        }
        furnace.mainGraph.missingSensorsMapping = mainGraph.missing_sensors_mapping;
        context.commit('setIsLoadingGraphs', false);
        context.commit('setIsLoadingGraphs', true);

        const secondaryGraph = await GraphsService.getOverviewGraph(
          equipment_id,
          false,
        );
        if (
          !('no_data' in secondaryGraph)
          && secondaryGraph.chart.datasets.length > 0
        ) {
          labels = [
            ...secondaryGraph.chart.labels,
          ];
          secondaryGraphDatasets = secondaryGraph.chart.datasets.map(
            (dataset) => {
              if (dataset.borderColor === '#000000') {
                if (dataset.is_data_mapped === false) {
                  dataset.borderColor = chartColors[dataset.descMod];
                  dataset.backgroundColor = chartColors[dataset.descMod];
                } else {
                  dataset.backgroundColor = dataset.borderColor;
                }
              }
              return dataset;
            },
          );
          furnace.is_data_mapped = secondaryGraph.is_data_mapped;
          furnace.secondaryGraph = {
            datasets: secondaryGraphDatasets,
            labels,
          };
        } else {
          furnace.is_data_mapped = secondaryGraph.is_data_mapped;
          furnace.secondaryGraph = {
            no_data: true,
          };
        }
        furnace.secondaryGraph.missingSensorsMapping = secondaryGraph.missing_sensors_mapping;
        context.commit('setFurnaceDetails', furnace);
        context.commit('setIsLoadingGraphs', false);
        context.commit('setFurnaceDetailsLoading', false);
      } catch (e) {
        console.log(e);
      }
    },
    async SET_OPERATION_DASHBOARD_KPIS(context, equipment_id) {
      try {
        context.commit('setKpisLoading', true);
        context.commit('setKpis', []);
        const kpis = await OperationDashboardService.getFurnaceKpis(
          equipment_id,
        );
        context.commit('setKpis', kpis);
      } catch (e) {
        console.log(e);
      } finally {
        context.commit('setKpisLoading', false);
      }
    },
    async SET_OPERATION_DASHBOARD(context, payload) {
      try {
        let furnaces = [];
        const locationId = payload.customerId || payload.locationId || payload.groupId;
        context.commit('setFurnacesLoading', true);
        context.commit('setFurnaces', []);
        // need to clear all data connected to previous location/group
        context.commit('setFinishedJobs', []);
        context.commit('setGanttData', []);
        context.commit('setFinishedJobsLoading', true);
        context.commit('setIsLoadingGanttData', true);
        furnaces = await OperationDashboardService.getFurnaces(payload);
        if (this.getters.getSelectedLocation.id === locationId) {
          context.commit('setFurnacesLoading', false);
          context.commit('setFurnacesLocation', locationId);
          context.commit('setFurnaces', furnaces);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async SET_OPERATION_DASHBOARD_JOBS_LIST(context, locationId) {
      context.commit('setFinishedJobs', []);
      context.commit('setFinishedJobsLoading', true);
      const furnaceList = this.getters.operationDashboardGetFurnaces;
      if (!furnaceList.length) {
        context.commit('setFinishedJobsLoading', false);
        return;
      }
      try {
        const equipmentsList = [];
        for (const eq of furnaceList) {
          equipmentsList.push(eq.id);
        }
        const payload = {
          equipments: equipmentsList,
          startTime: moment().add(-24, 'hours').unix(),
          endTime: moment().unix(),
          advancedFilters: {
            recipe: '',
            loadName: '',
            rating: [],
          },
        };
        const logs_list = await ProductionLogsService.getProductionLogs(
          payload,
        );
        if (this.getters.getSelectedLocation.id !== locationId) {
          return;
        }
        context.commit('setFinishedJobs', logs_list.logs);
      } catch (e) {
        console.log(e);
      } finally {
        context.commit('setFinishedJobsLoading', false);
      }
    },
    async SET_GANTT_DATA(context, payload) {
      try {
        const locationId = payload.customerId || payload.locationId || payload.groupId;
        context.commit('setIsLoadingGanttData', true);
        context.commit('setGanttData', []);
        const gantt_logs_list = await OperationDashboardService.getGanttData(
          payload,
        );
        if (this.getters.getSelectedLocation.id !== locationId) {
          return;
        }
        context.commit('setGanttData', gantt_logs_list.logs);
      } catch (e) {
        console.log(e);
      } finally {
        context.commit('setIsLoadingGanttData', false);
      }
    },
  },
  getters: {
    operationDashboardGetFurnaces(state) {
      return state.furnaces;
    },
    operationDashboardGetFinishedJobs(state) {
      return state.finishedJobs;
    },
    operationDashboardGetFurnaceDetails(state) {
      return state.furnaceDetails;
    },
    operationDashboardGetFurnaceDetailsLoading(state) {
      return state.furnaceDetailsLoading;
    },
    operationDashboardGetFurnacesLoading(state) {
      return state.furnacesLoading;
    },
    operationDashboardGetFinishedJobsLoading(state) {
      return state.finishedJobsLoading;
    },
    operationDashboardGetGraphsLoading(state) {
      return state.loadingGraphs;
    },
    operationDashboardGetFurnacesLocation(state) {
      return state.furnacesLocation;
    },
    operationDashboardGetNotifications(state) {
      return state.notifications;
    },
    operationDashboardGetNotificationsLoading(state) {
      return state.loadingNotifications;
    },
    operationDashboardGetGanttData(state) {
      return state.ganttData;
    },
    operationDashboardGetGanttDataLoading(state) {
      return state.loadingGantt;
    },
    operationDashboardGetKpis(state) {
      return state.kpis;
    },
    operationDashboardGetKpisLoading(state) {
      return state.kpisLoading;
    },
  },
};
