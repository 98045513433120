import {
  GraphsService,
} from '@/services/GraphsService';

export default {
  state: {
    location: 'default',
    alarmFreqParetoData: [],
    isLoadingAlarmFreqParetoData: true,
    dashboardList: [],
    isLoadingDashboardList: false,
    sankeyChart: {},
    isLoadingSankeyChart: true,
    sankeyChartLoadingError: false,
    consumables: [],
    isLoadingConsumables: true,
    oee: {},
    isLoadingOee: true,
    ohi: {},
    isLoadingOhi: true,
  },
  getters: {
    currlocation: (state) => state.location,
  },
  mutations: {
    setLocation(state, location) {
      state.location = location;
    },
    setAlarmFreqParetoData(state, alarmFreqParetoData) {
      state.alarmFreqParetoData = alarmFreqParetoData;
    },
    setIsLoadingAlarmFreqParetoData(state, isLoading) {
      state.isLoadingAlarmFreqParetoData = isLoading;
    },
    setDashboardList(state, dashboardList) {
      state.dashboardList = dashboardList;
    },
    setIsLoadingDashboardList(state, isLoading) {
      state.isLoadingDashboardList = isLoading;
    },
    setSankeyChart(state, sankeyChart) {
      state.sankeyChart = sankeyChart;
    },
    setSankeyChartLoadingError(state, error) {
      state.sankeyChartLoadingError = error;
    },
    setIsLoadingSankeyChart(state, isLoading) {
      state.isLoadingSankeyChart = isLoading;
    },
    setConsumables(state, consumables) {
      state.consumables = consumables;
    },
    setIsLoadingConsumables(state, isLoading) {
      state.isLoadingConsumables = isLoading;
    },
    clearGraphs(state) {
      state.alarmFreqParetoData = [];
      state.consumables = [];
      state.sankeyChart = {};
      state.isLoadingSankeyChart = true;
      state.isLoadingConsumables = true;
    },
  },
  actions: {
    CLEAR_GRAPHS(context) {
      context.commit('clearGraphs');
    },
    async SET_ALARM_FREQ_PARETO_DATA(context, jobId) {
      context.commit('setIsLoadingAlarmFreqParetoData', true);
      context.commit('setAlarmFreqParetoData', []);
      try {
        context.commit(
          'setAlarmFreqParetoData',
          await GraphsService.getAlarmFreqPareto(jobId),
        );
      } finally {
        context.commit('setIsLoadingAlarmFreqParetoData', false);
      }
    },
    async SET_DASHBOARD_LIST(
      context,
      {
        locationId, is_eq = false,
      },
    ) {
      context.commit('setIsLoadingDashboardList', false);
      context.commit('setDashboardList', []);
      context.commit('setLocation', locationId);
      try {
        context.commit('setIsLoadingDashboardList', true);
        context.commit(
          'setDashboardList',
          await GraphsService.getDashboardList(locationId, is_eq),
        );
      } catch (err) {
        console.log(err.msg || err);
      } finally {
        context.commit('setIsLoadingDashboardList', false);
      }
    },
    async SET_SANKEY_CHART_DATA(context, {
      equipmentId,
    }) {
      context.commit('setIsLoadingSankeyChart', true);
      context.commit('setSankeyChart', {});
      context.commit('setSankeyChartLoadingError', false);
      try {
        const sankeyChart = await GraphsService.getSankeyChart(equipmentId);
        context.commit('setSankeyChart', sankeyChart);
      } catch (ex) {
        if (ex.response.status === 422) {
          context.commit('setSankeyChartLoadingError', true);
        }
      } finally {
        context.commit('setIsLoadingSankeyChart', false);
      }
    },
    async SET_CONSUMABLES(context, equipmentId) {
      context.commit('setIsLoadingConsumables', true);
      context.commit('setConsumables', []);
      try {
        context.commit(
          'setConsumables',
          await GraphsService.getConsumables(equipmentId),
        );
      } finally {
        context.commit('setIsLoadingConsumables', false);
      }
    },
  },
};
