import {
  components,
} from './openapi';

export enum EObservationType {
  LOWER_CONTROL_LIMIT_EXCEEDED = 1,
  UPPER_CONTROL_LIMIT_EXCEEDED = 2,
  NEGATIVE_TREND_DETECTED = 5,
  POSITIVE_TREND_DETECTED = 6,
  LOWER_CUSTOM_LIMIT_EXCEEDED = 9,
  UPPER_CUSTOM_LIMIT_EXCEEDED = 10,
}

export const observationTypeMap: Record<components['schemas']['ObservationType'], keyof typeof EObservationType> = {
  1: 'LOWER_CONTROL_LIMIT_EXCEEDED',
  2: 'UPPER_CONTROL_LIMIT_EXCEEDED',
  5: 'NEGATIVE_TREND_DETECTED',
  6: 'POSITIVE_TREND_DETECTED',
  9: 'LOWER_CUSTOM_LIMIT_EXCEEDED',
  10: 'UPPER_CUSTOM_LIMIT_EXCEEDED',
};
