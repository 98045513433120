/**
 * Strips the trailing slash from the url
 * @param {Optional: String} url
 * @returns {Optional: String}
 */
export function normalizeUrl(url) {
  if (!url) {
    return url;
  }
  if (url.slice(-1) === '/') {
    return url.slice(0, -1);
  }
  return url;
}
