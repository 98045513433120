export default {
  setpoints: {
    header: 'Setpoints',
    surfaceHardnessLabel: 'Min. surface hardness',
    effectiveCaseDepthLabel: 'Effective case depth',
    effectiveCasehardnessLabel: 'Effective case hardness',
  },
  properties: {
    header: 'Properties',
    generalShapeOfPartLabel: 'General shape of part',
    grainSizeLabel: 'Grain size',
    diameterLabel: 'Diameter',
    quenchingMediaLabel: 'Quenching media',
    representativeCouponDiameterLabel: 'Representative coupon diameter',
    quenchingIntensityLabel: 'Quenching intensity',
    shapeSimulationTypeLabel: 'Shape simulation type',
    halfInfiniteRadio: 'Half-infinite',
    shapeOrientedRadio: 'Shape oriented',
    endTemperatureLabel: 'End temperature',
  },
  materialSimulationData: {
    header: 'Material simulation data',
  },
  savePartAs: {
    header: 'Save part as',
  },
};
