export default {
  errors: {
    downloadPdfFailed: 'Der Download des Qualitätsberichts ist fehlgeschlagen: {report}. Sollte dieser Fehler vermehrt auftreten bitte wenden Sie sich an den Qmulus support.',
    contentUrlNotSet: 'Die URL des Report PDFs für Report {report} konnte nicht gefunden werden.',
  },
  longRunning: {
    summary: 'Report wird generiert',
    detail: 'Die generation von Reports kann etwas länger dauern. Bitte lassen Sie das Browserfenster ofen. Diese Nachricht schließt sich automatisch nachdem der Report erstellt wurde.',
  },
};
