export default {
  title: {
    create: 'Neues virtuelles Signal',
    edit: 'Signal Einstellungen',
  },
  tabs: {
    signal: 'Virtuelles Signal Einstellungen',
    preset: 'Preseteinstellungen',
    metadata: 'Signaleinstellungen',
  },
};
