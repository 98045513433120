export enum EFontSize {
  TINY = 12,
  SMALL = 14,
  NORMAL = 16,
  LARGE = 18,
}

export const DEFAULT_FONT_SIZE: EFontSize = EFontSize.NORMAL;

export const SUPPORTED_FONT_SIZES: EFontSize[] = [
  EFontSize.TINY,
  EFontSize.SMALL,
  EFontSize.NORMAL,
  EFontSize.LARGE,
];
