export default {
  title: 'Y-Achsen Einstellungen',
  selectAxisLabel: 'Ausgewählte Achse',
  selectAxisPlaceholder: 'Achse auswählen oder erstellen',
  axisLabel: 'Achsenbeschriftung',
  selectType: 'Achsentyp',
  selectPosition: 'Achsenposition',
  limitMin: 'Untere Grenze',
  limitMax: 'Obere Grenze',
  auto: 'Auto',
  copy: 'Kopieren',
  sensorSelection: 'Sensorenauswahl',
  sensorSettingsHeader: 'Sensoreinstellungen',
  selectSignal: 'Ausgewählter Sensor',
  signalColor: 'Sensorfarbe',
  selectThickness: 'Linienstärke',
  stepped: 'Gestuft',
  addSensor: 'Sensor zur Achse hinzufügen',
};
