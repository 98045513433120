import {
  portalV2Api,
} from '@/services/AxiosService';

// TODO: Add docstrings to this once we decide on a docstring format.. Since this is not a typescript repo
export const sensorGroups = {
  get: async (userId) => {
    const response = await portalV2Api.get(
      `users/${userId}/settings/sensor-groups`,
    );
    return response.data;
  },
  update: async (userId, sensorGroups) => {
    const response = await portalV2Api.put(
      `users/${userId}/settings/sensor-groups`,
      sensorGroups,
    );
    return response.data;
  },
};
