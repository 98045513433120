import config from '@/config/config.js';

const authUrl = config.VITE_APP_AUTH_API;
const realmName = config.VITE_APP_TENANT_NAME;

export const initOptions = {
  loginTimeout: 30000, // Set login timeout to 30 seconds
  realm: config.VITE_APP_TENANT_NAME,
  clientId: 'qmulus',
  checkLoginIframe: false,
  flow: 'hybrid',
  onLoad: 'login-required',
  logoutUrl: `${authUrl}/realms/${realmName}/protocol/openid-connect/logout`,
};
