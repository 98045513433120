import {
  EquipmentService,
} from '../../services/EquipmentService.js';
import EventBus from '@/utils/event-bus';
import moment from '@/utils/moment';
import {
  requestCategories,
} from '../../constants/enumerations';
import {
  i18n,
} from '@/plugins/i18n';

export default {
  state: {
    announcements: [],
    isLoadingAnnouncements: false,
    customerRequests: [],
    isLoadingCustomerRequests: false,
  },

  mutations: {
    setAnnouncements(state, announcements) {
      state.announcements = announcements;
    },

    setisLoadingAnnouncements(state, isLoading) {
      state.isLoadingAnnouncements = isLoading;
    },

    setCustomerRequests(state, customerRequests) {
      state.customerRequests = customerRequests;
    },

    setIsLoadingCustomerRequests(state, isLoading) {
      state.isLoadingCustomerRequests = isLoading;
    },

    clearEquipmentCommunication(state) {
      state.announcements = [];
      state.customerRequests = [];
    },
  },

  actions: {
    CLEAR_ANNOUNCEMENTS(context) {
      context.commit('setAnnouncements', []);
    },

    SET_ANNOUNCEMENTS(context, status) {
      const id = context.rootState.equipmentDetailsStore.equipmentId;
      context.commit('setisLoadingAnnouncements', true);
      context.commit('setAnnouncements', []);
      EquipmentService.getEquipmentAnnouncements(id, status)
        .then((response) => {
          const mappedResponse = response.map((announcement) => {
            return {
              ...announcement,
              publishDate: getLocaleDateString(announcement.publishDate),
            };
          });
          context.commit('setAnnouncements', mappedResponse);
        })
        .catch((e) => EventBus.$emit('api_error', e))
        .finally(() => context.commit('setisLoadingAnnouncements', false));
    },

    SET_CUSTOMER_REQUESTS(context, {
      id, type,
    }) {
      context.commit('setIsLoadingCustomerRequests', true);
      context.commit('setCustomerRequests', []);
      const categoryNames = requestCategories(i18n.global);
      EquipmentService.getEquipmentCustomerRequests(id, type)
        .then((response) => {
          const mappedResponse = response.map((request) => {
            return {
              ...request,
              createdOn: moment(request.createdOn).format(
                'MM/DD/YYYY hh:mm:ss a',
              ),
              category: categoryNames.find(
                (cat) => cat.value === request.category,
              ).text,
            };
          });
          context.commit('setCustomerRequests', mappedResponse);
        })
        .catch((e) => EventBus.$emit('api_error', e))
        .finally(() => context.commit('setIsLoadingCustomerRequests', false));
    },
  },
};
