import upperFirst from 'lodash/upperFirst';
import moment from '@/utils/moment';
import 'moment-duration-format';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage.ts';
// import { i18n } from "@/plugins/i18n.ts";

export default {
  methods: {
    formatTime(value) {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      let hourValue;
      let minuteValue;
      if (hours < 10) {
        hourValue = `0${hours}`;
      }
      if (minutes < 10) {
        minuteValue = `0${minutes}`;
      }
      if (hours >= 10) {
        hourValue = hours;
      }
      if (minutes >= 10) {
        minuteValue = minutes;
      }
      if (hours >= 100) {
        hourValue = hours;
      }
      return `${hourValue}:${minuteValue}`;
    },

    /**
     * Convert to date format
     * @param {String|Number} value can be timestamp or string,
     * @param {Boolean} short set to true if you want to convert to short format,
     * @returns {String} returns the date in the short (DD/MM/YYYY HH:mm) or long (YYYY-MM-DD HH:mm:ss) format
     */
    formatDate(value, short = false) {
      const timeFormat = this.dateFormat === 'en' ? 'hh:mm:ss a' : 'HH:mm:ss';
      const shorterTimeFormat = this.dateFormat === 'en' ? 'hh:mm a' : 'HH:mm';
      const local = moment(value);
      local.locale(this.language);
      return short
        ? local.format(`L, ${shorterTimeFormat}`)
        : local.format(`ll, ${timeFormat}`);
    },

    dailyFormatDate(value) {
      const local = moment(value);
      local.locale(this.language);
      return local.format('L');
    },

    removeUnderscore(item) {
      return item.replaceAll('_', ' ');
    },

    setFirstCapitalLetter(word) {
      if (word.length) {
        const lowerCaseWord = this.removeUnderscore(word.toLowerCase());
        return upperFirst(lowerCaseWord);
      }
      return word;
    },

    formatTimeDays(value) {
      const {
        minutes, hours, days, months, years,
        // eslint-disable-next-line no-underscore-dangle
      } = moment.duration(
        value,
        'seconds',
      )._data;
      let str = '';
      const dayName = this.$i18n.tc('durationFormat.withValue.day', days, [
        days,
      ]);
      const yearName = this.$i18n.tc('durationFormat.withValue.year', years, [
        years,
      ]);
      const hourName = this.$i18n.tc('durationFormat.withValue.hour', hours, [
        hours,
      ]);
      const minutesName = this.$i18n.tc('durationFormat.withValue.minute', minutes, [
        minutes,
      ]);
      const monthsName = this.$i18n.tc('durationFormat.withValue.month', months, [
        months,
      ]);
      if (years) {
        str += `${yearName} `;
      }
      if (months) {
        str += `${monthsName} `;
      }
      if (days) {
        str += `${dayName} `;
      }
      if (hours) {
        str += `${hourName} `;
      }
      if (minutes) {
        str += `${minutesName} `;
      }
      return str;
    },

    unixTimestamp(value, isSecond = false, multiplier = 1000) {
      if (typeof value === 'number') {
        return isSecond ? value : value * 1000;
      }
      return new Date(value).getTime() / multiplier;
    },

    /**
     * @param {Date} date
     * @returns {String} from date with yyy-dd-mm format
     */
    formatDateToIso(date) {
      let month = `${date.getMonth() + 1}`;
      let day = `${date.getDate()}`;
      const year = date.getFullYear();
      if (month.length < 2) {
        month = `0${month}`;
      }
      if (day.length < 2) {
        day = `0${day}`;
      }
      return [
        year,
        month,
        day,
      ].join('-');
    },

    /**
     * If param is null or it cannot be converted returns null else returns formatted string from
     * date in dd Mmm yyyy format
     * @param {String|Number|null} timeParam can be timestamp or string
     * @returns {String|null}
     */
    getLocaleDateString(timeParam) {
      const options = {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      };
      if (timeParam) {
        const date = new Date(timeParam);
        if (!Number.isNaN(date)) {
          return date.toLocaleDateString('en-GB', options);
        }
      }
      return null;
    },

    /**
     * @param {String} isoString iso date string
     * @returns {String} returns the date
     */
    getLocaleStringFromIsoString(isoString) {
      return new Date(isoString).toLocaleString('en-GB');
    },

    /**
     * Convert to date format supported by all browsers
     * @param {String|Number} date date string
     * @returns {String} returns the date in the format YYYY-MM-DD HH:mm
     */
    convertToDateTimeString(date) {
      return moment(date).format('YYYY-MM-DDTHH:mm');
    },

    /**
     * Convert a duration in seconds to a string in the format HH:mm:ss
     * @param {Number} value duration in seconds
     * @returns {String} returns the duration in the format HH:mm:ss
     */
    formatDuration(value, showSeconds = true) {
      let format = 'hh:mm';
      if (showSeconds) {
        format += ':ss';
      }

      const duration = moment.duration(value, 'seconds');
      if (duration.days() > 1) {
        return duration.format('d[d] hh:mm', {
          trim: false,
        });
      }
      return duration.format(format, {
        trim: false,
      });
    },
  },
  computed: {
    dateFormat() {
      return this.$store.getters.getLocalizationSettings.dateFormat;
    },
    language() {
      return useCustomUserStorageStore().customUserSettings.language;
    },
  },
};
