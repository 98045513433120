export default {
  title: 'Y Axis settings',
  selectAxisLabel: 'Selected axis',
  selectAxisPlaceholder: 'Select or create axis',
  axisLabel: 'Axis label',
  selectType: 'Axis type',
  selectPosition: 'Axis position',
  limitMin: 'Limit: min',
  limitMax: 'Limit: max',
  auto: 'Auto',
  copy: 'Copy',
  sensorSelection: 'Sensor selection',
  sensorSettingsHeader: 'Sensor settings',
  selectSignal: 'Selected sensor',
  signalColor: 'Sensor color',
  selectThickness: 'Sensor line thickness',
  stepped: 'Stepped',
  addSensor: 'Add sensor to axis',
};
