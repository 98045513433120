<template>
  <component
    :is="view"
    class="upc-icon"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Component,
  defineAsyncComponent,
} from 'vue';
import EIcon from './icon-enum';

export default defineComponent({
  props: {
    name: {
      type: String as PropType<EIcon>,
      required: true,
    },
  },

  computed: {
    view(): Component {
      return defineAsyncComponent(() => import(`../../assets/icon/${this.name}.svg?component`));
    },
  },
});

export {
  EIcon,
};
</script>

<style scoped lang="scss">
@use './icon.scss';
</style>
