export function rafCallback(callbackFunction: (...args: any[]) => void) {
  let rafId:number = 0;

  const call = (...args: any[]) => {
    cancelAnimationFrame(rafId);
    rafId = requestAnimationFrame(() => {
      callbackFunction(...args);
    });
  };

  return call;
}
