const MAX_REQUEST_LOGS = 1000;

export default {
  namespaced: true,
  state: {
    requestErrors: [],
  },
  actions: {
    async ADD_REQUEST_ERROR(context, error) {
      context.commit('addRequestError', error);
    },
    async CLEAR_REQUEST_ERRORS(context) {
      context.commit('clearRequestErrors');
    },
  },
  mutations: {
    addRequestError(state, error) {
      const time = Date.now();
      state.requestErrors = [
        {
          ...error,
          time,
        },
        ...state.requestErrors,
      ];
      if (state.requestErrors.length > MAX_REQUEST_LOGS) {
        state.requestErrors = state.requestErrors.slice(0, MAX_REQUEST_LOGS);
      }
    },
    clearRequestError(state, error) {
      const index = state.requestErrors.indexOf(error);
      if (index !== -1) {
        state.requestErrors.splice(index, 1);
      }
    },
    clearRequestErrors(state) {
      state.requestErrors = [];
    },
  },
  getters: {
    requestErrors(state) {
      return state.requestErrors;
    },
  },
};
