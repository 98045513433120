import {
  NpmService,
} from '@/services/NpmService.js';

const findCredentialIndex = (store, systemIdentifier) => {
  return store.credentialList.findIndex((item) => item.systemIdentifier.systemId === systemIdentifier.systemId
      && item.systemIdentifier.location === systemIdentifier.location
      && item.systemIdentifier.customerCode === systemIdentifier.customerCode);
};

export default {
  namespaced: true,
  state: {
    isLoadingCredentialList: false,
    // stored per NPM furnace
    credentialList: [],
  },
  mutations: {
    setIsLoadingCredentialList(state, isLoading) {
      state.isLoadingCredentialList = isLoading;
    },
    setCredentialList(state, credentialList) {
      state.credentialList = credentialList;
    },
    // this thing is stored per npmSystemId
    updateCredential(state, {
      systemIdentifier, ...fields
    }) {
      const index = findCredentialIndex(state, systemIdentifier);
      if (index !== -1) {
        const credential = {
          ...state.credentialList[index],
          validity: {},
          updatedAt: new Date(),
          ...fields,
        };
        state.credentialList.splice(index, 1, credential);
      } else {
        state.credentialList.push({
          systemIdentifier: {
            customerCode: systemIdentifier.customerCode,
            location: systemIdentifier.location,
            systemId: systemIdentifier.systemId,
          },
          validity: {},
          ...fields,
        });
      }
    },
  },
  actions: {
    async SAVE_CHANGES(context) {
      try {
        context.commit('setIsLoadingCredentialList', true);

        const credentials = context.state.credentialList
          .map((c) => ({
            systemIdentifier: c.systemIdentifier,
            userName: c.userName,
            password: c.password,
          }));

        if (context.state.credentialList.some((c) => c.updatedAt)) {
          await NpmService.saveCredentials(credentials);
        }
      } catch (e) {
        console.log('e', e);
      } finally {
        context.commit('setIsLoadingCredentialList', false);
      }
    },
    async FETCH_CREDENTIALS(context) {
      let credentials = [];
      try {
        context.commit('setIsLoadingCredentialList', true);
        credentials = await NpmService.getCredentials();
      } catch (e) {
        credentials = [];
      } finally {
        context.commit('setCredentialList', credentials.map(
          (c) => ({
            validity: {},
            ...c,
          }),
        ));
        context.commit('setIsLoadingCredentialList', false);
      }
    },
    async VALIDATE_CREDENTIALS(context, {
      systemIdentifier,
    }) {
      let valid = null;
      let message = '';
      try {
        const index = findCredentialIndex(context.state, systemIdentifier);
        const credentials = context.state.credentialList[index];

        context.commit('updateCredential', {
          systemIdentifier,
          validity: {
            valid,
            message,
            loading: true,
          },
        });

        const payload = {
          systemIdentifier,
          userName: credentials.userName,
          password: credentials.password,
        };

        await NpmService.validateAssetCredentials(systemIdentifier, payload);
        valid = true;
      } catch (e) {
        valid = false;
        message = e.message;
      } finally {
        context.commit('updateCredential', {
          systemIdentifier,
          validity: {
            valid,
            message,
            loading: false,
          },
        });
      }
    },
    UPDATE_CREDENTIAL_FIELD(context, {
      systemIdentifier, field, value,
    }) {
      if ([
        'userName',
        'password',
        'validity',
      ].includes(field)) {
        context.commit('updateCredential', {
          systemIdentifier,
          [field]: value,
        });
      }
    },
    CLEAR_CREDENTIALS(context) {
      context.commit('setCredentialList', []);
    },
  },
  getters: {
    getCredentials: (state) => (systemIdentifier) => {
      const index = findCredentialIndex(state, systemIdentifier);
      return index !== -1 ? state.credentialList[index] : null;
    },
  },
};
