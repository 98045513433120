import {
  FilterService,
} from 'primevue/api';
import {
  TGetMaterialListResponse,
} from '@/api/material.ts';
import {
  tokenizedContainsFilterFunction,
} from '@/utils/tokenized-contains-filter-function.ts';

FilterService.register('tokenizedContains', tokenizedContainsFilterFunction);

FilterService.register('materialFilter', (value: TGetMaterialListResponse['items'] | string, filter: string) => {
  const filterWords = filter.split(' ').map((word) => word.toLowerCase());

  if (Array.isArray(value)) {
    const result = Boolean(value.findIndex((item) => {
      return filterWords.every((word) => item.displayName.toLowerCase().includes(word));
    }));
    return result;
  }

  return filterWords.every((word) => value.toLowerCase().includes(word));
});
