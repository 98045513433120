export const ENTRY_MODIFIED = 'ENTRY_MODIFIED';
export const ENTRY_DOES_NOT_EXIST = 'ENTRY_DOES_NOT_EXIST';
export const ERROR_NAME_USED = '23505';

export const create = (apiClient) => {
  const {
    fetchGraphJSON,
  } = apiClient;

  const TYPE_MATERIAL_GROUP = `{
  id
  name
  remark
}`;

  const TYPE_MATERIAL_VALUES = `{
  min
  max
  avg
  comp
  user
}`;

  const TYPE_ELEMENT_COEFFICIENT = `{
  id
  calculationModelId
  ECFerrite
  RCFerrite
  ENFerrite
  RNFerrite
  ECAustenite
  RCAustenite
  ENAustenite
  RNAustenite
}`;

  const TYPE_BASE_ELEMENT = `{
  id,
  name,
  symbol,
  position,
  hardnessNumber,
  kFactor,
  baseHardness,
  elementCoefficients ${TYPE_ELEMENT_COEFFICIENT}
}`;

  const TYPE_MATERIAL_CALCULATION_FACTORS = `{
  alloyFactorCarbide ${TYPE_MATERIAL_VALUES}
  alloyFactorNitride ${TYPE_MATERIAL_VALUES}
  carbideLimit ${TYPE_MATERIAL_VALUES}
  nitrideLimit ${TYPE_MATERIAL_VALUES}
}`;

  const TYPE_MATERIAL_CALCULATION_MODEL = `{
  id
  name
  description
  austenite ${TYPE_MATERIAL_CALCULATION_FACTORS}
  ferrite ${TYPE_MATERIAL_CALCULATION_FACTORS}
}`;

  const TYPE_MATERIAL_ELEMENT = `{
  id
  baseElementId
  minValue
  maxValue
  avgValue
  atomicAvgValue
}`;

  const TYPE_OPTION_ITEM = `{
  id
  value
}`;

  const TYPE_NORM_MATERIAL = `{
  id
  sourceId
  name
  number
  group ${TYPE_OPTION_ITEM}
  calculationModels ${TYPE_MATERIAL_CALCULATION_MODEL}
  coreHardness ${TYPE_MATERIAL_VALUES}
  hardnessNumber ${TYPE_MATERIAL_VALUES}
  meanCContent ${TYPE_MATERIAL_VALUES}
  selectedPreHTConditionId
  nTemperedHV
  nTemperedNmm
  materialElements ${TYPE_MATERIAL_ELEMENT}
  lastModifiedTimeInSec
}`;

  const TYPE_MATERIAL_ITEM = `{
  materialGroups ${TYPE_MATERIAL_GROUP}
  preHTConditionOptions ${TYPE_OPTION_ITEM}
  baseElements ${TYPE_BASE_ELEMENT}
  normMaterials ${TYPE_NORM_MATERIAL}
}`;

  const INPUT_N_TEMPERED = (data) => `{
  unit: ${data.unit}
  value: ${data.value}
}`;

  const INPUT_MATERIAL_ELEMENT = (data) => `{
  baseElementId: ${data.baseElementId}
  minValue: ${data.minValue}
  maxValue: ${data.maxValue}
}`;

  const INPUT_SAVE_NEW_NORM_MATERIAL = (data) => `{
  name: '${data.name}'
  number: '${data.number}'
  groupId: ${data.groupId}
  selectedPreHTConditionId: ${data.selectedPreHTConditionId}
  nTempered: ${INPUT_N_TEMPERED(data.nTempered)}
  materialElements: [${data.materialElements.map((element) => INPUT_MATERIAL_ELEMENT(element))}]
}`;

  const INPUT_SAVE_EDITED_NORM_MATERIAL = (data) => `{
  id: ${data.id}
  name: "${data.name}"
  number: "${data.number}"
  groupId: ${data.groupId}
  selectedPreHTConditionId: ${data.selectedPreHTConditionId}
  nTempered: ${data.nTempered ? INPUT_N_TEMPERED(data.nTempered) : null}
  materialElements: [${data.materialElements.map((element) => INPUT_MATERIAL_ELEMENT(element))}]
  lastModifiedTimeInSec: ${data.lastModifiedTimeInSec}
}`;

  const INPUT_DELETE_NORM_MATERIAL = (data) => `{
  id: ${data.id}
  lastModifiedTimeInSec: ${data.lastModifiedTimeInSec}
}`;

  const TYPE_ERROR = `{
  code
  message
  detail
}`;

  const TYPE_SAVE_NORM_MATERIAL_RESULT = `{
  normMaterial ${TYPE_NORM_MATERIAL}
  isSuccess
  error ${TYPE_ERROR}
}`;

  const TYPE_DELETE_NORM_MATERIAL_RESULT = `{
  normMaterial ${TYPE_NORM_MATERIAL}
  isSuccess
  error ${TYPE_ERROR}
}`;

  const getNormMaterialListPortal = async (location) => {
    const query = `
  query {
    normMaterialListPortal ${TYPE_MATERIAL_ITEM}
  }`;

    try {
      let result = await fetchGraphJSON(query, {
        location,
      });
      result = result.data.normMaterialListPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  const saveNewNormMaterialPortal = async (location, data) => {
    const mutation = `
  mutation {
    saveNewNormMaterialPortal(data: ${INPUT_SAVE_NEW_NORM_MATERIAL(
    data,
  )}) ${TYPE_SAVE_NORM_MATERIAL_RESULT}
  }`;

    try {
      let result = await fetchGraphJSON(mutation, {
        location,
      });
      result = result.data.saveNewNormMaterialPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  const saveEditedNormMaterialPortal = async (location, data) => {
    const mutation = `
  mutation {
    saveEditedNormMaterialPortal(data: ${INPUT_SAVE_EDITED_NORM_MATERIAL(
    data,
  )}) ${TYPE_SAVE_NORM_MATERIAL_RESULT}
  }`;

    try {
      let result = await fetchGraphJSON(mutation, {
        location,
      });
      result = result.data.saveEditedNormMaterialPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  const deleteNormMaterialPortal = async (location, data) => {
    const mutation = `
  mutation {
    deleteNormMaterialPortal(data: ${INPUT_DELETE_NORM_MATERIAL(
    data,
  )}) ${TYPE_DELETE_NORM_MATERIAL_RESULT}
  }`;

    try {
      let result = await fetchGraphJSON(mutation, {
        location,
      });
      result = result.data.deleteNormMaterialPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  function getCustomerMaterialList(location) {
    const query = `
    query{
      customerMaterialList {
        id
        name
        description
        normId
        normName
        normDescription
        groupId
        groupName
        elementValues {
          c
          si
          mn
          cr
          ni
          v
          al
          cu
          p
          s
          mo
          b
        }
        averageAlloyFactor
        carbideLimit
        modifiedOn
        modifiedBy
      }
    }`;

    return fetchGraphJSON(query, {
      location,
    });
  }

  return {
    deleteNormMaterialPortal,
    getCustomerMaterialList,
    getNormMaterialListPortal,
    saveEditedNormMaterialPortal,
    saveNewNormMaterialPortal,
  };
};
