import {
  AxiosResponse,
} from 'axios';
import {
  computedUrl,
  EOpenapiLegacyPaths,
  EOpenapiPaths,
} from '@/types/openapi-type';
import {
  paths,
} from '@/types/openapi';
import {
  paths as legacyPaths,
} from '@/types/openapi_legacy';
import {
  TApiPayload,
} from './type/export';
import axiosClientV3 from './client';
import {
  portalV2Api,
} from '@/services/AxiosService';

export type TGetCurrentUserResponse = paths[EOpenapiPaths.USER_MAP_GET]['get']['responses']['200']['content']['application/json'];
export async function getCurrentUser(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserResponse>> {
  return axiosClientV3.get(EOpenapiPaths.USER_MAP_GET, {
    signal: abortSignal,
  });
}

export type TGetPtDbUserListPath = paths[EOpenapiPaths.PT9800_DATABASES_USER]['get']['parameters']['path'];
export type TGetPtDbUserListPayload = TApiPayload<TGetPtDbUserListPath>;
export type TGetPtDbUserListResponse = paths[EOpenapiPaths.PT9800_DATABASES_USER]['get']['responses']['200']['content']['application/json'];
export async function getPtDbUserList(payload: TGetPtDbUserListPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetPtDbUserListResponse>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.PT9800_DATABASES_USER](payload.path), {
    signal: abortSignal,
  });
}

export type TGetPTUserMappingQuery = paths[EOpenapiPaths.PT9800_USER_MAPPING_GET_PUT]['get']['parameters']['query'];
export type TGetPTUserMappingPayload = TApiPayload<undefined, TGetPTUserMappingQuery>;
export type TGetPTUserMappingResponse = paths[EOpenapiPaths.PT9800_USER_MAPPING_GET_PUT]['get']['responses']['200']['content']['application/json'];
export async function getPTUserMapping(payload: TGetPTUserMappingPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetPTUserMappingResponse>> {
  return axiosClientV3.get(EOpenapiPaths.PT9800_USER_MAPPING_GET_PUT, {
    params: payload.query,
    signal: abortSignal,
  });
}

export type TUpdatePTUserMappingBodyParams = paths[EOpenapiPaths.PT9800_USER_MAPPING_GET_PUT]['put']['requestBody']['content']['application/json'];
export type TUpdatePTUserMappingPayload = TApiPayload<undefined, undefined, TUpdatePTUserMappingBodyParams>;
export type TUpdatePTUserMappingResponse = paths[EOpenapiPaths.PT9800_USER_MAPPING_GET_PUT]['put']['responses']['200']['content']['application/json'];
export async function updatePTUserMapping(payload: TUpdatePTUserMappingPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdatePTUserMappingResponse>> {
  return axiosClientV3.put(EOpenapiPaths.PT9800_USER_MAPPING_GET_PUT, payload.bodyParams, {
    signal: abortSignal,
  });
}

export type TUpdateUserPermissionsPath = paths[EOpenapiPaths.USER_PERMISSIONS_GET_UPDATE_DELETE]['put']['parameters']['path'];
export type TUpdateUserPermissionsBodyParams = paths[EOpenapiPaths.USER_PERMISSIONS_GET_UPDATE_DELETE]['put']['requestBody']['content']['application/json'];
// because of how TApiPayload behaves it can be one of 2 objects: bodyParams: string[] or a request with bodyParams: {
//   Comment
// appPermissions?: string[] | undefined;
// { assetPermissions?: number[] | undefined;
// }
// here we manually create single object with 2 possible types
export type TUpdateUserPermissionsPayload = {path: TUpdateUserPermissionsPath, bodyParams: TUpdateUserPermissionsBodyParams};
export type TUpdateUserPermissionsResponse = paths[EOpenapiPaths.USER_PERMISSIONS_GET_UPDATE_DELETE]['put']['responses']['200']['content']['application/json'];
export async function updateUserPermissions(payload: TUpdateUserPermissionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserPermissionsResponse>> {
  return axiosClientV3.put(computedUrl[EOpenapiPaths.USER_PERMISSIONS_GET_UPDATE_DELETE](payload.path), payload.bodyParams, {
    signal: abortSignal,
  });
}

export type TGetUserListQuery = paths[EOpenapiPaths.USER_LIST_GET_CREATE]['get']['parameters']['query'];
export type TGetUserListPayload = TApiPayload<undefined, TGetUserListQuery>;
export type TGetUserListResponse = paths[EOpenapiPaths.USER_LIST_GET_CREATE]['get']['responses']['200']['content']['application/json'];
export async function getUserList(payload: TGetUserListPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserListResponse>> {
  return axiosClientV3.get(EOpenapiPaths.USER_LIST_GET_CREATE, {
    params: {
      ...payload.query,
    },
    signal: abortSignal,
  });
}

export type TGetUserListV2Response = legacyPaths[EOpenapiLegacyPaths.V2_USERS]['get']['responses']['200']['content']['application/json'];
/** @deprecated */
export async function getUserListV2(): Promise<AxiosResponse<TGetUserListV2Response>> {
  return portalV2Api.get(computedUrl[EOpenapiLegacyPaths.V2_USERS]());
}

export type TGetCreateUserParams = paths[EOpenapiPaths.USER_LIST_GET_CREATE]['post']['requestBody']['content']['application/json'];
export type TGetCreateUserPayload = TApiPayload<undefined, undefined, TGetCreateUserParams>;
export type TGetCreateUserResponse = paths[EOpenapiPaths.USER_LIST_GET_CREATE]['post']['responses']['201']['content']['application/json'];

export async function createUser(payload: TGetCreateUserPayload): Promise<AxiosResponse<TGetCreateUserResponse>> {
  return axiosClientV3.post(EOpenapiPaths.USER_LIST_GET_CREATE, {
    ...payload,
  });
}

export type TGetUserPath = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['get']['parameters']['path'];
export type TGetUserPayload = TApiPayload<TGetUserPath>;
export type TGetUserResponse = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['get']['responses']['200']['content']['application/json'];
export async function getUser(payload: TGetUserPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserResponse>> {
  return axiosClientV3.get(computedUrl[EOpenapiPaths.USER_GET_UPDATE_DELETE](payload.path), {
    signal: abortSignal,
  });
}

export type TUpdateUserPath = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['put']['parameters']['path'];
export type TUpdateUserBodyParams = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['put']['requestBody']['content']['application/json'];
export type TUpdateUserPayload = TApiPayload<TUpdateUserPath, undefined, TUpdateUserBodyParams>;
export type TUpdateUserResponse = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['put']['responses']['200']['content']['application/json'];
export async function updateUser(payload: TUpdateUserPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserResponse>> {
  return axiosClientV3.put(computedUrl[EOpenapiPaths.USER_GET_UPDATE_DELETE](payload.path), payload.bodyParams, {
    signal: abortSignal,
  });
}

export type TDeleteUserPath = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['delete']['parameters']['path'];
export type TDeleteUserPayload = TApiPayload<TDeleteUserPath>;
export type TDeleteUserResponse = paths[EOpenapiPaths.USER_GET_UPDATE_DELETE]['delete']['responses']['200']['content']['application/json'];
export async function deleteUser(payload: TDeleteUserPayload): Promise<AxiosResponse<TDeleteUserResponse>> {
  return axiosClientV3.delete(computedUrl[EOpenapiPaths.USER_GET_UPDATE_DELETE](payload.path));
}
