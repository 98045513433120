import {
  TCsvDatetimeFormatTranslations,
} from './select-csv-datetime-format.ts';

const options: TCsvDatetimeFormatTranslations = {
  iso_8601: 'ISO 8601 / RFC 3339',
  unix_timestamp: 'UNIX Timestamp',
  us: 'US',
};

export default {
  placeholder: '',
  label: 'Datetime format',
  options,
};
