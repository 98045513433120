import {
  ProductionLogsService,
} from '@/services/ProductionLogsService';

export default {
  state: {
    statistics: [],
    statisticsComparisonBasedOn: null,
    statisticsComparisonKpi: null,
    statisticsComparisonLoading: false,
    statisticsComparisonSelectedRows: [],
    isLoadingStatistics: false,
    filters: [],
    statisticsDateFilter: {},
    isLoadingKPIs: true,
    kpis: {},
    locations: [],
    furnaceTypes: [],
    isLoadingFurnaceTypes: false,
    furnaceNames: [],
    isLoadingFurnaceNames: false,
    report: {},
    isLoadingReport: false,
    isSavingReport: false,
    selectedLoads: [],
    statisticsParent: {},
  },
  mutations: {
    setStatisticsList(state, list) {
      state.statistics = list;
    },
    setStatisticsDateFilter(state, dateRange) {
      state.statisticsDateFilter = dateRange;
    },
    setStatisticsParent(state, object) {
      state.statisticsParent = object;
    },
    setIsLoadingStatistics(state, isLoading) {
      state.isLoadingStatistics = isLoading;
    },
    clearStatistics(state) {
      state.statistics = [];
    },
    setIsLoadingKPIs(state, isLoading) {
      state.isLoadingKPIs = isLoading;
    },
    setKPIs(state, kpis) {
      if (kpis.all_kpis) {
        kpis.all_kpis = kpis.all_kpis.filter((kpi) => !kpi.includes('stddev'));
      }
      state.kpis = kpis;
    },
    setPLLocations(state, locationsList) {
      state.locations = locationsList;
    },
    setIsLoadingLocations(state, loading) {
      state.isLoadingLocations = loading;
    },
    setFurnaceTypes(state, typesList) {
      state.furnaceTypes = typesList;
    },
    setIsLoadingFurnaceTypes(state, loading) {
      state.isLoadingFurnaceTypes = loading;
    },
    setFurnaceNames(state, furnaceNames) {
      state.furnaceNames = furnaceNames;
    },
    setIsLoadingFurnaceNames(state, loading) {
      state.isLoadingFurnaceNames = loading;
    },
    setReport(state, report) {
      state.report = report;
    },
    setIsLoadingReport(state, loading) {
      state.isLoadingReport = loading;
    },
    setIsSavingReport(state, saving) {
      state.isSavingReport = saving;
    },
    setStatisticsComparisonLoading(state, loading) {
      state.statisticsComparisonLoading = loading;
    },
    setStatisticsComparisonSelectedRows(state, rows) {
      state.statisticsComparisonSelectedRows = rows;
    },
    updateStatisticsComparisonSelectedRow(state, {
      item, list,
    }) {
      const getItemPath = (unit) => {
        if (unit.process_name) {
          return `${unit.path}\\\\${unit.process_name}`;
        } if (unit.system_id) {
          return `${unit.path}\\\\${unit.system_id}`;
        } if (unit.plant_name) {
          return `${unit.path}\\\\${unit.plant_name}`;
        } if (unit.customer_code) {
          return `${unit.path}\\\\${unit.customer_code}`;
        } return unit.path;
      };
      const updatedItemPath = getItemPath(item);

      state.statisticsComparisonSelectedRows = state.statisticsComparisonSelectedRows.map((row) => (getItemPath(row.item) === updatedItemPath ? {
        ...row,
        list,
      } : row));
    },
    setStatisticsComparisonBasedOn(state, basedOn) {
      state.statisticsComparisonBasedOn = basedOn;
    },
    setStatisticsComparisonKpi(state, comparisonKpi) {
      state.statisticsComparisonKpi = comparisonKpi;
    },
    clearSelectedLoads(state) {
      state.selectedLoads = [];
    },
  },
  actions: {
    CLEAR_STATISTICS(context) {
      context.commit('clearStatistics');
    },
    async CLEAR_COMPARISON(context) {
      context.commit('setStatisticsComparisonSelectedRows', []);
    },
    async CLEAR_SELECTED_LOADS(context) {
      context.commit('clearSelectedLoads');
    },
    async SET_KPIS(context) {
      context.commit('setIsLoadingKPIs', true);
      context.commit('setKPIs', {});
      try {
        const kpis = await ProductionLogsService.getKPIs();
        context.commit('setKPIs', kpis);
      } finally {
        context.commit('setIsLoadingKPIs', false);
      }
    },
    async REFRESH_FILTERS(context, payload) {
      try {
        context.commit('setIsLoadingFurnaceNames', true);
        if (!payload.locations || payload.locations.length <= 2) {
          context.commit('setIsLoadingLocations', true);
          context.commit(
            'setPLLocations',
            await ProductionLogsService.getFilteredLocations(payload),
          );
        }
        if (!payload.furnace_types || payload.furnace_types.length <= 2) {
          context.commit('setIsLoadingFurnaceTypes', true);
          context.commit(
            'setFurnaceTypes',
            await ProductionLogsService.getFilteredTypes(payload),
          );
        }
        context.commit(
          'setFurnaceNames',
          await ProductionLogsService.getFilteredNames(payload),
        );
      } finally {
        context.commit('setIsLoadingLocations', false);
        context.commit('setIsLoadingFurnaceTypes', false);
        context.commit('setIsLoadingFurnaceNames', false);
      }
    },
    async SET_STATISTICS(context) {
      context.commit('setIsLoadingStatistics', true);
      context.commit('setStatisticsList', []);
      try {
        const dateFilter = this.getters.getStatisticsDateFilter;
        if (dateFilter.to && dateFilter.from) {
          context.commit(
            'setStatisticsList',
            await ProductionLogsService.getStatisticsList(
              '',
              '',
              '',
              '',
              dateFilter.from,
              dateFilter.to,
            ),
          );
        } else {
          context.commit(
            'setStatisticsList',
            await ProductionLogsService.getStatisticsList(),
          );
        }
      } finally {
        context.commit('setIsLoadingStatistics', false);
      }
    },
    async UPDATE_STATISTICS_COMPARISON(context) {
      context.commit('setStatisticsComparisonLoading', true);

      const rows = context.state.statisticsComparisonSelectedRows;
      const dateFilter = context.getters.getStatisticsDateFilter;

      try {
        let p = Promise.resolve([]);
        rows.forEach(({
          item,
        }) => {
          const parents = item.path.split('\\\\');
          const params = {
            customerId: item.customer_code ? item.customer_code : parents[0],
            plantId: item.plant_name ? item.plant_name : parents[1],
            systemId: item.system_id ? item.system_id : parents[2],
            processName: item.process_name ? item.process_name : parents[3],
          };

          p = p.then(async (results) => {
            const list = await ProductionLogsService.getStatisticsList(
              params.customerId,
              params.plantId,
              params.systemId,
              params.processName,
              dateFilter.from,
              dateFilter.to,
            );
            results.push({
              item,
              list,
            });
            return results;
          });
        });
        const results = await p;
        results.forEach((result) => context.commit('updateStatisticsComparisonSelectedRow', result));
      } finally {
        context.commit('setStatisticsComparisonLoading', false);
      }
    },
    async SET_STATISTICS_DATE_FILTER(context, dateRange) {
      context.commit('setStatisticsDateFilter', dateRange);
    },
    async EXPAND_STATISTICS(
      context,
      {
        customerId = null,
        plantId = null,
        systemId = null,
        processName = null,
        from = null,
        to = null,
      },
    ) {
      context.commit('setIsLoadingStatistics', true);
      try {
        context.commit(
          'setStatisticsList',
          await ProductionLogsService.getStatisticsList(
            customerId,
            plantId,
            systemId,
            processName,
            from,
            to,
          ),
        );
      } finally {
        context.commit('setIsLoadingStatistics', false);
      }
    },
    async SET_STATISTICS_PARENT(
      context,
      {
        customerId = null,
        plantId = null,
        systemId = null,
        processName = null,
        from = null,
        to = null,
      },
    ) {
      context.commit('setIsLoadingStatistics', true);
      context.commit('setStatisticsParent', {});
      try {
        context.commit(
          'setStatisticsParent',
          await ProductionLogsService.getStatisticsParent(
            customerId,
            plantId,
            systemId,
            processName,
            from,
            to,
          ),
        );
      } finally {
        context.commit('setIsLoadingStatistics', false);
      }
    },
    async SAVE_REPORT(context, payload) {
      try {
        context.commit('setIsSavingReport', true);
        await ProductionLogsService.saveReport(payload);
      } finally {
        context.commit('setIsSavingReport', false);
      }
    },
    async LOAD_REPORT(context, auditId) {
      try {
        context.commit('setIsLoadingReport', true);
        context.commit(
          'setReport',
          await ProductionLogsService.getReport(auditId),
        );
      } finally {
        context.commit('setIsLoadingReport', false);
      }
    },
  },
  getters: {
    isLoadingKPIs: (state) => {
      return state.isLoadingKPIs;
    },
    getSelectedLoads: (state) => {
      return state.selectedLoads;
    },
    getStatistics: (state) => {
      return state.statistics;
    },
    getStatisticsParent: (state) => {
      return state.statisticsParent;
    },
    getStatisticsDateFilter: (state) => {
      return state.statisticsDateFilter;
    },
    getFilteredLocations: (state) => {
      return state.locations;
    },
    getFilteredFurnaceTypes: (state) => {
      return state.furnaceTypes;
    },
    getFilteredFurnaceNames: (state) => {
      return state.furnaceNames;
    },
    getIsLoadingLocations: (state) => {
      return state.isLoadingLocations;
    },
    getIsLoadingFurnaceTypes: (state) => {
      return state.isLoadingFurnaceTypes;
    },
    getIsLoadingFurnaceNames: (state) => {
      return state.isLoadingFurnaceNames;
    },
    getReport: (state) => {
      return state.report;
    },
    getIsLoadingReport: (state) => {
      return state.isLoadingReport;
    },
    getIsSavingReport: (state) => {
      return state.isSavingReport;
    },
  },
};
