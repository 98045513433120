import {
  EAppIdMap,
} from '@/types/app-type.ts';
import {
  ESettingsSectionType,
} from '@/types/settings.ts';
import {
  TTranslation,
} from './generic-settings-item';

const translation: TTranslation = {
  [EAppIdMap.REMOTE_CONTROL]: {
    [ESettingsSectionType.REMOTE_CONTROL_PT9800]: {
      title: 'PT9800 Controller',
      subtitle: 'Die folgenden Einstellungen bestimmen das Verhalten des PT9800-Controllers für jeden Benutzer.',
      settingsMap: {
        enforceBarcodeScanner: {
          label: 'Barcode scanner forcieren.',
          tooltip: 'Wenn angewählt, wird die Bestätigung von Teilen per Barcode scanner im Dialog zum starten von Jobs erzwungen.',
        },
      },
    },
  },
};

export default translation;
