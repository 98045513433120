import {
  TSelectCsvSeperatorTranslations,
} from './select-csv-seperator.ts';

const options: TSelectCsvSeperatorTranslations = {
  comma: 'Komma',
  pipe: 'Senkrechter Strich',
  semicolon: 'Semikolon',
  space: 'Leerzeichen',
  tab: 'Tabulator',
};

export default {
  placeholder: '',
  label: 'Seperator',
  options,
};
