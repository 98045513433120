import {
  Selector,
} from '@/pt9800/generated/zeus';

export const userQuery = Selector('Query')({
  applicationMetadata: {
    applicationTypeList: {
      id: true,
      value: true,
    },
    applicationGroupList: {
      id: true,
      value: true,
    },
  },
  applicationList: {
    id: true,
    name: true,
    description: true,
    command: true,
    parameter: true,
    tooltip: true,
    furnacePicture: true,
    iconGifName: true,
    order: true,
    typeId: true,
    groupId: true,
    startRecipeProcessFlow: true,
    modifyRecipeProcessFlow: true,
    stopRecipeProcessFlow: true,
    startCorrectionRecipe: true,
    modifiedOn: true,
    modifiedBy: true,
  },
  applicationGroup: {
    id: true,
    name: true,
    description: true,
    order: true,
    modifiedOn: true,
    modifiedBy: true,
  },
  userGroup: {
    id: true,
    name: true,
    description: true,
    modifiedOn: true,
    modifiedBy: true,
  },
  userAccessList: {
    userId: true,
    group: true,
    pgmId: true,
    application: true,
    run: true,
    newEntry: true,
    save: true,
    delete: true,
    special1: true,
    print: true,
    startRecipeProcessFlow: true,
    modifyRecipeProcessFlow: true,
    stopRecipeProcessFlow: true,
    startCorrectionRecipe: true,
  },
  userList: {
    id: true,
    shortName: true,
    lastName: true,
    firstName: true,
    function: true,
    password: true,
    comment: true,
    employeeNumber: true,
    plant: true,
    costCenter: true,
    maintenancePopups: true,
    playAlarmSound: true,
    loginTimeInMin: true,
    accessLevel: true,
    languageId: true,
    userGroupId: true,
    modifiedOn: true,
    modifiedBy: true,
    picture: true,
  },
});
