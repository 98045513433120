import {
  TSelectCsvSeperatorTranslations,
} from './select-csv-seperator.ts';

const options: TSelectCsvSeperatorTranslations = {
  comma: 'Comma',
  pipe: 'Pipe',
  semicolon: 'Semicolon',
  space: 'Space',
  tab: 'Tab',
};

export default {
  placeholder: '',
  label: 'Seperator',
  options,
};
