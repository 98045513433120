import {
  SIMULATORS_RECIPE_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const RecipeService = {
  getRecipesList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${SIMULATORS_RECIPE_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getRecipeDetails(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${SIMULATORS_RECIPE_PATH}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addNewRecipe(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${SIMULATORS_RECIPE_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteRecipe(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${SIMULATORS_RECIPE_PATH}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateRecipe(id, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${SIMULATORS_RECIPE_PATH}/${id}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
