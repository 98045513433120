<template>
  <v-btn-toggle
    v-model="selectBtnGroup"
    class="btn-group"
    divided
  >
    <v-btn
      v-if="showSelectedDateFromAbsoluteDatePicker"
      class="display-selected-date"
      @click="hideSelectedDate"
    >
      <span>{{ formatDate(selectedDate.from) }}</span>
      <span>&#8211;</span>
      <span>{{ formatDate(selectedDate.to) }}</span>
    </v-btn>
    <template v-else>
      <v-btn
        v-for="(item, index) in selectPeriodList"
        :key="index"
        :value="item.text"
        class="options-buttons"
        @click="setDate(item.value, item.period)"
      >
        {{ item.text === 'currentWeek' ? $t('dateRangePicker.currentWeek') : item.text }}
      </v-btn>
    </template>
  </v-btn-toggle>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    location-strategy="connected"
    location="bottom"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        :class="{ 'custom-btn-selected': isCustomBtnSelected}"
        class="options-buttons custom-button"
        append-icon="mdi mdi-calendar-month-outline"
        flat
      >
        {{ $t('dateRangePicker.custom') }}{{ isCustomBtnSelected ? textFromRelativeDatePicker : '' }}
      </v-btn>
    </template>

    <v-card
      min-width="530"
      height="485px"
      style="overflow: hidden"
    >
      <v-tabs
        v-model="selectedView"
        slider-color="accent"
        class="mt-1"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab"
          :value="tab"
        >
          {{ $t("tabs." + tab) }}
        </v-tab>
      </v-tabs>

      <v-window v-model="selectedView">
        <v-window-item value="absolute">
          <v-card
            :elevation="0"
            class="py-2"
          >
            <date-range-picker-absolute
              v-model="selectedDate"
              :locale="locale"
            />
          </v-card>
        </v-window-item>
        <v-window-item value="relative">
          <v-card
            :elevation="0"
            class="py-2"
          >
            <date-range-picker-relative
              v-model="selectedDate"
              :selected-period="textFromRelativeDatePicker === '' ? selectBtnGroup : textFromRelativeDatePicker"
              @selectedRelativePeriodText="setTextFromRelativeDatePicker"
              @selectBtnFromMainPanel="selectBtnFromMainPanel"
            />
          </v-card>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="text-secondaryButton"
          @click="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          class="text-primaryButton"
          @click="applyDate(selectedView), (isMenuOpen = false)"
        >
          {{ $t('buttons.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  DateUtils,
} from '../../util/date';
import DateRangePickerAbsolute from './date-range-picker-absolute.vue';
import DateRangePickerRelative from './date-range-picker-relative.vue';

export const selectPeriodList = [
  {
    value: 1,
    text: 'currentWeek',
    period: 'isoWeek',
  },
  {
    value: 7,
    text: '7d',
    period: 'days',
  },
  {
    value: 1,
    text: '1m',
    period: 'months',
  },
  {
    value: 3,
    text: '3m',
    period: 'months',
  },
  {
    value: 1,
    text: '1y',
    period: 'years',
  },
];
export const TAB_TYPES = Object.freeze({
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
});
export default {
  name: 'DateRangePicker',
  components: {
    DateRangePickerAbsolute,
    DateRangePickerRelative,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      relativeTextBackup: null,
      showSelectedDateBackup: null,
      selectBtnGroupBackup: null,
      isCustomBtnSelected: false,
      isMenuOpen: false,
      selectBtnGroup: null,
      showSelectedDateFromAbsoluteDatePicker: false,
      selectedDate: null,
      textFromRelativeDatePicker: '',
      selectedView: TAB_TYPES.ABSOLUTE,
      tabs: [
        TAB_TYPES.ABSOLUTE,
        TAB_TYPES.RELATIVE,
      ],
      selectPeriodList,
    };
  },
  watch: {
    isMenuOpen() {
      if (this.isMenuOpen) {
        this.selectedDate = cloneDeep(this.modelValue);
        this.relativeTextBackup = this.textFromRelativeDatePicker;
        this.showSelectedDateBackup = this.showSelectedDateFromAbsoluteDatePicker;
        this.selectBtnGroupBackup = this.selectBtnGroup;
      }
    },
    modelValue: {
      handler() {
        this.selectDate();
      },
      deep: true,
    },
  },
  mounted() {
    this.selectDate();
  },
  methods: {
    selectDate() {
      this.selectedDate = cloneDeep(this.modelValue);
      if (this.selectBtnGroup === 'currentWeek') {
        return;
      }
      const endDate = moment(this.selectedDate.to);
      const differentDays = endDate.diff(this.selectedDate.from, 'days');
      if (differentDays === 7) {
        this.selectBtnGroup = '7d';
      } else {
        const differentMonths = endDate.diff(this.selectedDate.from, 'months');
        if (differentMonths === 1) {
          this.selectBtnGroup = '1m';
        } else if (differentMonths === 3) {
          this.selectBtnGroup = '3m';
        } else {
          const differentYears = endDate.diff(this.selectedDate.from, 'years');
          if (differentYears === 1) {
            this.selectBtnGroup = '1y';
          } else {
            this.selectBtnGroup = null;
            this.isCustomBtnSelected = true;
          }
        }
      }
    },
    setTextFromRelativeDatePicker(text) {
      this.textFromRelativeDatePicker = ` (${text})`;
    },
    selectBtnFromMainPanel(value) {
      this.showSelectedDateFromAbsoluteDatePicker = false;
      this.selectBtnGroup = value;
    },
    hideSelectedDate() {
      this.showSelectedDateFromAbsoluteDatePicker = false;
      this.isCustomBtnSelected = false;
      this.selectBtnGroup = null;
    },
    setDate(value, period) {
      let startDate = null;
      if (period === 'isoWeek') {
        startDate = moment().startOf('isoWeek');
      } else {
        startDate = moment().subtract(value, period);
      }
      this.selectedDate.to = moment();
      this.selectedDate.from = startDate;
      this.applyDate();
    },
    formatDate(date) {
      return DateUtils.formatDate(date);
    },
    applyDate(selectedView = null) {
      const startDate = DateUtils.getStartOfDayDate(this.selectedDate.from);
      const endDate = DateUtils.getEndOfDayDate(this.selectedDate.to);
      if (selectedView === TAB_TYPES.ABSOLUTE) {
        this.textFromRelativeDatePicker = '';
        this.showSelectedDateFromAbsoluteDatePicker = true;
        this.isCustomBtnSelected = true;
      } else if (selectedView === TAB_TYPES.RELATIVE && !this.selectBtnGroup) {
        this.showSelectedDateFromAbsoluteDatePicker = false;
        this.isCustomBtnSelected = true;
      } else {
        this.textFromRelativeDatePicker = '';
        this.isCustomBtnSelected = false;
      }
      this.$emit('update:modelValue', {
        from: startDate,
        to: endDate,
      });
    },
    closeModal() {
      this.selectedDate = cloneDeep(this.modelValue);
      this.textFromRelativeDatePicker = this.relativeTextBackup;
      this.showSelectedDateFromAbsoluteDatePicker = this.showSelectedDateBackup;
      this.selectBtnGroup = this.selectBtnGroupBackup;
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
@use './date-range-picker.scss';
</style>
