export default {
  headline: 'Benutzer bearbeiten',
  permissions: 'Berechtigungen',
  controllerUserMapping: 'Controller-Benutzerzuordnung',
  role: 'Rollen',
  customPermission: 'Benutzerdefinierte Berechtigungen',
  appPermission: 'App-Berechtigungen',
  assetPermission: 'Asset-Berechtigungen',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail',
  warningHeadline: 'Sie verlieren den Zugang zur Benutzerverwaltung',
  warningText: 'Sie sind dabei, sich selbst aus der Benutzerverwaltung zu sperren. Sie können nur dann wieder Zugang erhalten, wenn ein anderer Admin Ihnen wieder Zugang gewährt.',
};
