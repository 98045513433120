import {
  sensorGroups,
} from '@/services/qmulusService/users/settings/sensorGroups';
import {
  equipmentGroups,
} from '@/services/qmulusService/users/settings/equipmentGroups';

export const settings = {
  sensorGroups,
  equipmentGroups,
};
