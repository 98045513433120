import {
  SIMULATOR_PART_CHART_PATH,
  SIMULATOR_PARTS_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const PartService = {
  getSinglePart(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${SIMULATOR_PARTS_PATH}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getPartList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${SIMULATOR_PARTS_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updatePart(id, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${SIMULATOR_PARTS_PATH}/${id}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addNewPart(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${SIMULATOR_PARTS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deletePart(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${SIMULATOR_PARTS_PATH}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getPartCharts(payload, {
    calculationModel,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${SIMULATOR_PART_CHART_PATH}`, payload, {
          params: {
            calculation_model: calculationModel,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
