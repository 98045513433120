import {
  EquipmentService,
} from '@/services/EquipmentService.js';
import EventBus from '@/utils/event-bus';

export default {
  namespaced: true,
  state: {
    header: {},
    headerUpdatedAtTime: null,
    isLoadingHeader: false,
    guiConfig: [],
    isLoadingGuiConfig: false,
    alarms: {},
    isLoadingAlarms: false,
    readings: {},
    setpoints: {},
    isLoadingReadings: false,
    identifiers: {},
    isLoadingIdentifiers: false,
  },

  mutations: {
    setHeader(state, header) {
      state.header = header;
      state.headerUpdatedAtTime = Date.now();
    },
    setIsLoadingHeader(state, isLoading) {
      state.isLoadingHeader = isLoading;
    },
    setGuiConfig(state, config) {
      state.guiConfig = config;
    },
    setIsLoadingGuiConfig(state, isLoading) {
      state.isLoadingGuiConfig = isLoading;
    },
    setAlarms(state, alarms) {
      state.alarms = alarms;
    },
    setIsLoadingAlarms(state, isLoading) {
      state.isLoadingAlarms = isLoading;
    },
    setReadings(state, readings) {
      state.readings = readings;
    },
    setSetpoints(state, setpoints) {
      state.setpoints = setpoints;
    },
    setIsLoadingReadings(state, isLoading) {
      state.isLoadingReadings = isLoading;
    },
    setIdentifiers(state, identifiers) {
      state.identifiers = identifiers;
    },
    setIsLoadingIdentifiers(state, isLoading) {
      state.isLoadingIdentifiers = isLoading;
    },
  },

  actions: {
    RESET({
      commit,
    }) {
      commit('setIdentifiers', {});
      commit('setSetpoints', {});
      commit('setReadings', {});
      commit('setGuiConfig', []);
    },
    async LOAD_HEADER(context, {
      customerCode, systemId, location,
    } = {}) {
      let header = {};
      try {
        context.commit('setIsLoadingHeader', true);
        context.commit('setHeader', header);
        header = await EquipmentService.getNpmControllerViewHeader({
          customerCode,
          systemId,
          location,
        });
      } catch (error) {
        EventBus.$emit('api_error', error);
      } finally {
        context.commit('setHeader', header);
        context.commit('setIsLoadingHeader', false);
      }
    },
    async LOAD_GUI_CONFIG(context, {
      customerCode, systemId, location,
    } = {}) {
      try {
        context.commit('setIsLoadingGuiConfig', true);
        const response = await EquipmentService.getNpmControllerViewGUI({
          customerCode,
          systemId,
          location,
        });
        context.commit('setGuiConfig', response);
      } catch (error) {
        EventBus.$emit('api_error', error);
        context.commit('setGuiConfig', null);
      } finally {
        context.commit('setIsLoadingGuiConfig', false);
      }
    },
    async LOAD_ALARMS(
      context,
      {
        customerCode, systemId, location, jobId,
      } = {},
    ) {
      try {
        context.commit('setIsLoadingAlarms', true);
        const response = await EquipmentService.getNpmControllerViewAlarms({
          customerCode,
          systemId,
          location,
          jobId,
        });

        const sensorTypeMapping = {
          AI: 0,
          AO: 1,
          DI: 2,
          DO: 3,
        };
        const groupByIdType = (items) => items.reduce((acc, item) => {
          const idType = `${item.compId}_${
            sensorTypeMapping[item.sensorType]
          }`;
          acc[idType] = item;
          return acc;
        }, {});
        const alarms = groupByIdType(response);

        context.commit('setAlarms', alarms);
      } catch (error) {
        EventBus.$emit('api_error', error);
        context.commit('setAlarms', null);
      } finally {
        context.commit('setIsLoadingAlarms', false);
      }
    },
    async LOAD_IDENTIFIERS(context, {
      customerCode, systemId, location,
    } = {}) {
      try {
        context.commit('setIsLoadingIdentifiers', true);
        const response = await EquipmentService.getNpmControllerViewIdentifiers(
          {
            customerCode,
            systemId,
            location,
          },
        );

        const sensorTypeMapping = {
          A: 0,
          a: 1,
          D: 2,
          d: 3,
        };
        const objectsByIdType = (items) => items.reduce((acc, item) => {
          const idType = `${item.compId}_${sensorTypeMapping[item.type]}`;
          acc[idType] = item;
          return acc;
        }, {});
        const identifiers = objectsByIdType(
          response.filter((item) => [
            'A',
            'a',
            'D',
            'd',
          ].includes(item.type)),
        );
        context.commit('setIdentifiers', identifiers);
      } catch (error) {
        EventBus.$emit('api_error', error);
        context.commit('setIdentifiers', {});
      } finally {
        context.commit('setIsLoadingIdentifiers', false);
      }
    },
    async LOAD_READINGS(
      context,
      {
        customerCode, systemId, location, jobId, idTypes,
      } = {},
    ) {
      if (context.state.isLoadingReadings) {
        EventBus.$emit(
          'api_error',
          new Error(
            'Cannot start LOAD_READINGS before previous LOAD_READINGS is finished',
          ),
        );
        return;
      }
      let readings = {};
      let setpoints = {};
      try {
        context.commit('setIsLoadingReadings', true);
        const response = await EquipmentService.getNpmControllerViewReadings({
          customerCode,
          systemId,
          location,
          jobId,
          idTypes,
        });

        const sensorTypeMapping = {
          AI: 0,
          AO: 1,
          DI: 2,
          DO: 3,
        };
        const groupByIdType = (readings) => readings.reduce((acc, reading) => {
          const idType = `${reading.compId}_${
            sensorTypeMapping[reading.sensorType]
          }`;
          acc[idType] = reading;
          return acc;
        }, {});
        readings = groupByIdType(response.filter((reading) => !reading.isSet));
        setpoints = groupByIdType(response.filter((reading) => reading.isSet));
      } catch (error) {
        EventBus.$emit('api_error', error);
      } finally {
        context.commit('setReadings', readings);
        context.commit('setSetpoints', setpoints);
        context.commit('setIsLoadingReadings', false);
      }
    },
  },
};
