enum EIcon {
  ADD = 'add',
  ARROW_BACKWARD = 'arrow-backward',
  ARROW_DOWN = 'arrow-down',
  ARROW_UP = 'arrow-up',
  CALENDAR_MONTH = 'calendar-month',
  CARET_DOWN = 'caret-down',
  CARET_UP = 'caret-up',
  CHECK = 'check',
  CHECKBOX_ON = 'checkbox-on',
  CHECKBOX_OFF = 'checkbox-off',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CLEAR = 'clear',
  CLOSE = 'close',
  EDIT = 'edit',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  LOAD_STATUS = 'load-status',
  LOCK_OPENED = 'lock-opened',
  LOCK = 'lock',
  MENU_HAMBURGER = 'menu-hamburger',
  OPEN_IN_NEW = 'open-in-new',
  SEARCH = 'search',
  SETTINGS = 'settings',
  RADIO_ON = 'radio-on',
  RADIO_OFF = 'radio-off',
  TWITTER = 'twitter',
  USER = 'user',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
}

export default EIcon;
