// @ts-ignore
import {
  AxiosResponse,
} from 'axios';
// @ts-ignore
import {
  paths,
} from '@/types/openapi';
// @ts-ignore
import {
  TApiPayload,
} from '@/api/type/export';
// @ts-ignore
import {
  axiosClientV3,
} from '@/api/client';
// @ts-ignore
import {
  interpolateUrl,
} from '@/utils/url.ts';
// @ts-ignore
import {
  QueryKey,
} from '@tanstack/vue-query';

export type TGetAllAppsWithPermissionsResponse = paths['/core/apps']['get']['responses']['200']['content']['application/json'];
export const getAllAppsWithPermissionsQueryKey = (): QueryKey => ([...["core","apps"]]);
export function getAllAppsWithPermissions(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAllAppsWithPermissionsResponse>> {
  return axiosClientV3.get('/core/apps', { signal: abortSignal });
}

export type TFindAssetsQuery = paths['/core/assets']['get']['parameters']['query'];
export type TFindAssetsPayload = TApiPayload<undefined, TFindAssetsQuery, undefined>;
export type TFindAssetsResponse = paths['/core/assets']['get']['responses']['200']['content']['application/json'];
export const findAssetsQueryKey = (query?: TFindAssetsQuery): QueryKey => ([...["core","assets"], ...(query ? [query] : [])]);
export function findAssets(payload: TFindAssetsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetsResponse>> {
  return axiosClientV3.get('/core/assets', { signal: abortSignal, params: payload.query });
}

export type TGetAssetDetailsPath = paths['/core/assets/{assetId}']['get']['parameters']['path'];
export type TGetAssetDetailsPayload = TApiPayload<TGetAssetDetailsPath, undefined, undefined>;
export type TGetAssetDetailsResponse = paths['/core/assets/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getAssetDetailsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets"], assetId]);
export function getAssetDetails(payload: TGetAssetDetailsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetDetailsResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}', payload.path), { signal: abortSignal });
}

export type TDeleteAssetRoutePath = paths['/core/assets/{assetId}']['delete']['parameters']['path'];
export type TDeleteAssetRoutePayload = TApiPayload<TDeleteAssetRoutePath, undefined, undefined>;
export type TDeleteAssetRouteResponse = paths['/core/assets/{assetId}']['delete']['responses']['200']['content']['application/json'];
export const deleteAssetRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets"], assetId]);
export function deleteAssetRoute(payload: TDeleteAssetRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAssetRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/assets/{assetId}', payload.path), { signal: abortSignal });
}

export type TFindAssetBlobsRoutePath = paths['/core/assets/{assetId}/blobs']['get']['parameters']['path'];
export type TFindAssetBlobsRoutePayload = TApiPayload<TFindAssetBlobsRoutePath, undefined, undefined>;
export type TFindAssetBlobsRouteResponse = paths['/core/assets/{assetId}/blobs']['get']['responses']['200']['content']['application/json'];
export const findAssetBlobsRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId]);
export function findAssetBlobsRoute(payload: TFindAssetBlobsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetBlobsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/blobs', payload.path), { signal: abortSignal });
}

export type TCreateAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs']['post']['parameters']['path'];
export type TCreateAssetBlobRouteBodyParams = paths['/core/assets/{assetId}/blobs']['post']['requestBody']['content']['application/json'];
export type TCreateAssetBlobRoutePayload = TApiPayload<TCreateAssetBlobRoutePath, undefined, TCreateAssetBlobRouteBodyParams>;
export type TCreateAssetBlobRouteResponse = paths['/core/assets/{assetId}/blobs']['post']['responses']['200']['content']['application/json'];
export const createAssetBlobRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId]);
export function createAssetBlobRoute(payload: TCreateAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateAssetBlobRouteResponse>> {
  return axiosClientV3.post(interpolateUrl('/core/assets/{assetId}/blobs', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs/{blobId}']['get']['parameters']['path'];
export type TGetAssetBlobRoutePayload = TApiPayload<TGetAssetBlobRoutePath, undefined, undefined>;
export type TGetAssetBlobRouteResponse = paths['/core/assets/{assetId}/blobs/{blobId}']['get']['responses']['200']['content']['application/json'];
export const getAssetBlobRouteQueryKey = (assetId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId, blobId]);
export function getAssetBlobRoute(payload: TGetAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetBlobRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/blobs/{blobId}', payload.path), { signal: abortSignal });
}

export type TUpdateAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs/{blobId}']['put']['parameters']['path'];
export type TUpdateAssetBlobRouteBodyParams = paths['/core/assets/{assetId}/blobs/{blobId}']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetBlobRoutePayload = TApiPayload<TUpdateAssetBlobRoutePath, undefined, TUpdateAssetBlobRouteBodyParams>;
export type TUpdateAssetBlobRouteResponse = paths['/core/assets/{assetId}/blobs/{blobId}']['put']['responses']['200']['content']['application/json'];
export const updateAssetBlobRouteQueryKey = (assetId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId, blobId]);
export function updateAssetBlobRoute(payload: TUpdateAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetBlobRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/assets/{assetId}/blobs/{blobId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteAssetBlobRoutePath = paths['/core/assets/{assetId}/blobs/{blobId}']['delete']['parameters']['path'];
export type TDeleteAssetBlobRoutePayload = TApiPayload<TDeleteAssetBlobRoutePath, undefined, undefined>;
export type TDeleteAssetBlobRouteResponse = paths['/core/assets/{assetId}/blobs/{blobId}']['delete']['responses']['200']['content']['application/json'];
export const deleteAssetBlobRouteQueryKey = (assetId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","assets","blobs"], assetId, blobId]);
export function deleteAssetBlobRoute(payload: TDeleteAssetBlobRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAssetBlobRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/assets/{assetId}/blobs/{blobId}', payload.path), { signal: abortSignal });
}

export type TGetDataSourcesForAssetPath = paths['/core/assets/{assetId}/data-sources']['get']['parameters']['path'];
export type TGetDataSourcesForAssetPayload = TApiPayload<TGetDataSourcesForAssetPath, undefined, undefined>;
export type TGetDataSourcesForAssetResponse = paths['/core/assets/{assetId}/data-sources']['get']['responses']['200']['content']['application/json'];
export const getDataSourcesForAssetQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","data-sources"], assetId]);
export function getDataSourcesForAsset(payload: TGetDataSourcesForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDataSourcesForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/data-sources', payload.path), { signal: abortSignal });
}

export type TGetAssetStagesRouteQuery = paths['/core/assets/{assetId}/data/stages']['get']['parameters']['query'];
export type TGetAssetStagesRoutePath = paths['/core/assets/{assetId}/data/stages']['get']['parameters']['path'];
export type TGetAssetStagesRoutePayload = TApiPayload<TGetAssetStagesRoutePath, TGetAssetStagesRouteQuery, undefined>;
export type TGetAssetStagesRouteResponse = paths['/core/assets/{assetId}/data/stages']['get']['responses']['200']['content']['application/json'];
export const getAssetStagesRouteQueryKey = (assetId: QueryKey[number], query?: TGetAssetStagesRouteQuery): QueryKey => ([...["core","assets","data","stages"], assetId, ...(query ? [query] : [])]);
export function getAssetStagesRoute(payload: TGetAssetStagesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetStagesRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/data/stages', payload.path), { signal: abortSignal, params: payload.query });
}

export type TFindAlarmEventMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/alarm-event']['get']['parameters']['query'];
export type TFindAlarmEventMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/alarm-event']['get']['parameters']['path'];
export type TFindAlarmEventMetadataForAssetPayload = TApiPayload<TFindAlarmEventMetadataForAssetPath, TFindAlarmEventMetadataForAssetQuery, undefined>;
export type TFindAlarmEventMetadataForAssetResponse = paths['/core/assets/{assetId}/metadata/alarm-event']['get']['responses']['200']['content']['application/json'];
export const findAlarmEventMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindAlarmEventMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","alarm-event"], assetId, ...(query ? [query] : [])]);
export function findAlarmEventMetadataForAsset(payload: TFindAlarmEventMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAlarmEventMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/metadata/alarm-event', payload.path), { signal: abortSignal, params: payload.query });
}

export type TFindBlobMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/blob']['get']['parameters']['query'];
export type TFindBlobMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/blob']['get']['parameters']['path'];
export type TFindBlobMetadataForAssetPayload = TApiPayload<TFindBlobMetadataForAssetPath, TFindBlobMetadataForAssetQuery, undefined>;
export type TFindBlobMetadataForAssetResponse = paths['/core/assets/{assetId}/metadata/blob']['get']['responses']['200']['content']['application/json'];
export const findBlobMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindBlobMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","blob"], assetId, ...(query ? [query] : [])]);
export function findBlobMetadataForAsset(payload: TFindBlobMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindBlobMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/metadata/blob', payload.path), { signal: abortSignal, params: payload.query });
}

export type TFindEventMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/event']['get']['parameters']['query'];
export type TFindEventMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/event']['get']['parameters']['path'];
export type TFindEventMetadataForAssetPayload = TApiPayload<TFindEventMetadataForAssetPath, TFindEventMetadataForAssetQuery, undefined>;
export type TFindEventMetadataForAssetResponse = paths['/core/assets/{assetId}/metadata/event']['get']['responses']['200']['content']['application/json'];
export const findEventMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindEventMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","event"], assetId, ...(query ? [query] : [])]);
export function findEventMetadataForAsset(payload: TFindEventMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindEventMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/metadata/event', payload.path), { signal: abortSignal, params: payload.query });
}

export type TFindJobAggregateMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/job-aggregate']['get']['parameters']['query'];
export type TFindJobAggregateMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/job-aggregate']['get']['parameters']['path'];
export type TFindJobAggregateMetadataForAssetPayload = TApiPayload<TFindJobAggregateMetadataForAssetPath, TFindJobAggregateMetadataForAssetQuery, undefined>;
export type TFindJobAggregateMetadataForAssetResponse = paths['/core/assets/{assetId}/metadata/job-aggregate']['get']['responses']['200']['content']['application/json'];
export const findJobAggregateMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindJobAggregateMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","job-aggregate"], assetId, ...(query ? [query] : [])]);
export function findJobAggregateMetadataForAsset(payload: TFindJobAggregateMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindJobAggregateMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/metadata/job-aggregate', payload.path), { signal: abortSignal, params: payload.query });
}

export type TFindTimeSeriesMetadataForAssetQuery = paths['/core/assets/{assetId}/metadata/time-series']['get']['parameters']['query'];
export type TFindTimeSeriesMetadataForAssetPath = paths['/core/assets/{assetId}/metadata/time-series']['get']['parameters']['path'];
export type TFindTimeSeriesMetadataForAssetPayload = TApiPayload<TFindTimeSeriesMetadataForAssetPath, TFindTimeSeriesMetadataForAssetQuery, undefined>;
export type TFindTimeSeriesMetadataForAssetResponse = paths['/core/assets/{assetId}/metadata/time-series']['get']['responses']['200']['content']['application/json'];
export const findTimeSeriesMetadataForAssetQueryKey = (assetId: QueryKey[number], query?: TFindTimeSeriesMetadataForAssetQuery): QueryKey => ([...["core","assets","metadata","time-series"], assetId, ...(query ? [query] : [])]);
export function findTimeSeriesMetadataForAsset(payload: TFindTimeSeriesMetadataForAssetPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindTimeSeriesMetadataForAssetResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/metadata/time-series', payload.path), { signal: abortSignal, params: payload.query });
}

export type TGetAssetProductivityQuery = paths['/core/assets/{assetId}/productivity']['get']['parameters']['query'];
export type TGetAssetProductivityPath = paths['/core/assets/{assetId}/productivity']['get']['parameters']['path'];
export type TGetAssetProductivityPayload = TApiPayload<TGetAssetProductivityPath, TGetAssetProductivityQuery, undefined>;
export type TGetAssetProductivityResponse = paths['/core/assets/{assetId}/productivity']['get']['responses']['200']['content']['application/json'];
export const getAssetProductivityQueryKey = (assetId: QueryKey[number], query?: TGetAssetProductivityQuery): QueryKey => ([...["core","assets","productivity"], assetId, ...(query ? [query] : [])]);
export function getAssetProductivity(payload: TGetAssetProductivityPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetProductivityResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/productivity', payload.path), { signal: abortSignal, params: payload.query });
}

export type TGetEffectiveAssetProfileQuery = paths['/core/assets/{assetId}/profiles']['get']['parameters']['query'];
export type TGetEffectiveAssetProfilePath = paths['/core/assets/{assetId}/profiles']['get']['parameters']['path'];
export type TGetEffectiveAssetProfilePayload = TApiPayload<TGetEffectiveAssetProfilePath, TGetEffectiveAssetProfileQuery, undefined>;
export type TGetEffectiveAssetProfileResponse = paths['/core/assets/{assetId}/profiles']['get']['responses']['200']['content']['application/json'];
export const getEffectiveAssetProfileQueryKey = (assetId: QueryKey[number], query?: TGetEffectiveAssetProfileQuery): QueryKey => ([...["core","assets","profiles"], assetId, ...(query ? [query] : [])]);
export function getEffectiveAssetProfile(payload: TGetEffectiveAssetProfilePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEffectiveAssetProfileResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/profiles', payload.path), { signal: abortSignal, params: payload.query });
}

export type TGetProfileMappingStatusPath = paths['/core/assets/{assetId}/profile-mapping-status']['get']['parameters']['path'];
export type TGetProfileMappingStatusPayload = TApiPayload<TGetProfileMappingStatusPath, undefined, undefined>;
export type TGetProfileMappingStatusResponse = paths['/core/assets/{assetId}/profile-mapping-status']['get']['responses']['200']['content']['application/json'];
export const getProfileMappingStatusQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","profile-mapping-status"], assetId]);
export function getProfileMappingStatus(payload: TGetProfileMappingStatusPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetProfileMappingStatusResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/profile-mapping-status', payload.path), { signal: abortSignal });
}

export type TFindAssetSettingsRouteByAssetAndCategoryQuery = paths['/core/assets/{assetId}/settings/{category}']['get']['parameters']['query'];
export type TFindAssetSettingsRouteByAssetAndCategoryPath = paths['/core/assets/{assetId}/settings/{category}']['get']['parameters']['path'];
export type TFindAssetSettingsRouteByAssetAndCategoryPayload = TApiPayload<TFindAssetSettingsRouteByAssetAndCategoryPath, TFindAssetSettingsRouteByAssetAndCategoryQuery, undefined>;
export type TFindAssetSettingsRouteByAssetAndCategoryResponse = paths['/core/assets/{assetId}/settings/{category}']['get']['responses']['200']['content']['application/json'];
export const findAssetSettingsRouteByAssetAndCategoryQueryKey = (assetId: QueryKey[number], category: QueryKey[number], query?: TFindAssetSettingsRouteByAssetAndCategoryQuery): QueryKey => ([...["core","assets","settings"], assetId, category, ...(query ? [query] : [])]);
export function findAssetSettingsRouteByAssetAndCategory(payload: TFindAssetSettingsRouteByAssetAndCategoryPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetSettingsRouteByAssetAndCategoryResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/settings/{category}', payload.path), { signal: abortSignal, params: payload.query });
}

export type TGetAssetSparePartsPath = paths['/core/assets/{assetId}/spare-parts']['get']['parameters']['path'];
export type TGetAssetSparePartsPayload = TApiPayload<TGetAssetSparePartsPath, undefined, undefined>;
export type TGetAssetSparePartsResponse = paths['/core/assets/{assetId}/spare-parts']['get']['responses']['200']['content']['application/json'];
export const getAssetSparePartsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId]);
export function getAssetSpareParts(payload: TGetAssetSparePartsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetSparePartsResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/assets/{assetId}/spare-parts', payload.path), { signal: abortSignal });
}

export type TAssociateSparePartsPath = paths['/core/assets/{assetId}/spare-parts']['put']['parameters']['path'];
export type TAssociateSparePartsBodyParams = paths['/core/assets/{assetId}/spare-parts']['put']['requestBody']['content']['application/json'];
export type TAssociateSparePartsPayload = TApiPayload<TAssociateSparePartsPath, undefined, TAssociateSparePartsBodyParams>;
export type TAssociateSparePartsResponse = paths['/core/assets/{assetId}/spare-parts']['put']['responses']['200']['content']['application/json'];
export const associateSparePartsQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId]);
export function associateSpareParts(payload: TAssociateSparePartsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TAssociateSparePartsResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/assets/{assetId}/spare-parts', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TAddSparePartToAssetRoutePath = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['put']['parameters']['path'];
export type TAddSparePartToAssetRoutePayload = TApiPayload<TAddSparePartToAssetRoutePath, undefined, undefined>;
export type TAddSparePartToAssetRouteResponse = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['put']['responses']['200']['content']['application/json'];
export const addSparePartToAssetRouteQueryKey = (assetId: QueryKey[number], sparePartId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId, sparePartId]);
export function addSparePartToAssetRoute(payload: TAddSparePartToAssetRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TAddSparePartToAssetRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/assets/{assetId}/spare-parts/{sparePartId}', payload.path), { signal: abortSignal });
}

export type TRemoveSparePartsFromAssetRoutePath = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['delete']['parameters']['path'];
export type TRemoveSparePartsFromAssetRoutePayload = TApiPayload<TRemoveSparePartsFromAssetRoutePath, undefined, undefined>;
export type TRemoveSparePartsFromAssetRouteResponse = paths['/core/assets/{assetId}/spare-parts/{sparePartId}']['delete']['responses']['200']['content']['application/json'];
export const removeSparePartsFromAssetRouteQueryKey = (assetId: QueryKey[number], sparePartId: QueryKey[number]): QueryKey => ([...["core","assets","spare-parts"], assetId, sparePartId]);
export function removeSparePartsFromAssetRoute(payload: TRemoveSparePartsFromAssetRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TRemoveSparePartsFromAssetRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/assets/{assetId}/spare-parts/{sparePartId}', payload.path), { signal: abortSignal });
}

export type TFindChambersRouteQuery = paths['/core/asset/chambers']['get']['parameters']['query'];
export type TFindChambersRoutePayload = TApiPayload<undefined, TFindChambersRouteQuery, undefined>;
export type TFindChambersRouteResponse = paths['/core/asset/chambers']['get']['responses']['200']['content']['application/json'];
export const findChambersRouteQueryKey = (query?: TFindChambersRouteQuery): QueryKey => ([...["core","asset","chambers"], ...(query ? [query] : [])]);
export function findChambersRoute(payload: TFindChambersRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindChambersRouteResponse>> {
  return axiosClientV3.get('/core/asset/chambers', { signal: abortSignal, params: payload.query });
}

export type TCreateChamberRouteBodyParams = paths['/core/asset/chambers']['post']['requestBody']['content']['application/json'];
export type TCreateChamberRoutePayload = TApiPayload<undefined, undefined, TCreateChamberRouteBodyParams>;
export type TCreateChamberRouteResponse = paths['/core/asset/chambers']['post']['responses']['200']['content']['application/json'];
export const createChamberRouteQueryKey = (): QueryKey => ([...["core","asset","chambers"]]);
export function createChamberRoute(payload: TCreateChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateChamberRouteResponse>> {
  return axiosClientV3.post('/core/asset/chambers', payload.bodyParams, { signal: abortSignal });
}

export type TGetChamberRoutePath = paths['/core/asset/chambers/{assetId}']['get']['parameters']['path'];
export type TGetChamberRoutePayload = TApiPayload<TGetChamberRoutePath, undefined, undefined>;
export type TGetChamberRouteResponse = paths['/core/asset/chambers/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getChamberRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","chambers"], assetId]);
export function getChamberRoute(payload: TGetChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetChamberRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/chambers/{assetId}', payload.path), { signal: abortSignal });
}

export type TUpdateChamberRoutePath = paths['/core/asset/chambers/{assetId}']['put']['parameters']['path'];
export type TUpdateChamberRouteBodyParams = paths['/core/asset/chambers/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateChamberRoutePayload = TApiPayload<TUpdateChamberRoutePath, undefined, TUpdateChamberRouteBodyParams>;
export type TUpdateChamberRouteResponse = paths['/core/asset/chambers/{assetId}']['put']['responses']['200']['content']['application/json'];
export const updateChamberRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","chambers"], assetId]);
export function updateChamberRoute(payload: TUpdateChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateChamberRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/chambers/{assetId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteChamberRoutePath = paths['/core/asset/chambers/{assetId}']['delete']['parameters']['path'];
export type TDeleteChamberRoutePayload = TApiPayload<TDeleteChamberRoutePath, undefined, undefined>;
export type TDeleteChamberRouteResponse = paths['/core/asset/chambers/{assetId}']['delete']['responses']['200']['content']['application/json'];
export const deleteChamberRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","chambers"], assetId]);
export function deleteChamberRoute(payload: TDeleteChamberRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteChamberRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/asset/chambers/{assetId}', payload.path), { signal: abortSignal });
}

export type TFindCompanyRouteQuery = paths['/core/asset/companies']['get']['parameters']['query'];
export type TFindCompanyRoutePayload = TApiPayload<undefined, TFindCompanyRouteQuery, undefined>;
export type TFindCompanyRouteResponse = paths['/core/asset/companies']['get']['responses']['200']['content']['application/json'];
export const findCompanyRouteQueryKey = (query?: TFindCompanyRouteQuery): QueryKey => ([...["core","asset","companies"], ...(query ? [query] : [])]);
export function findCompanyRoute(payload: TFindCompanyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindCompanyRouteResponse>> {
  return axiosClientV3.get('/core/asset/companies', { signal: abortSignal, params: payload.query });
}

export type TGetCompanyRoutePath = paths['/core/asset/companies/{assetId}']['get']['parameters']['path'];
export type TGetCompanyRoutePayload = TApiPayload<TGetCompanyRoutePath, undefined, undefined>;
export type TGetCompanyRouteResponse = paths['/core/asset/companies/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getCompanyRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","companies"], assetId]);
export function getCompanyRoute(payload: TGetCompanyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCompanyRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/companies/{assetId}', payload.path), { signal: abortSignal });
}

export type TUpdateCompanyRoutePath = paths['/core/asset/companies/{assetId}']['put']['parameters']['path'];
export type TUpdateCompanyRouteBodyParams = paths['/core/asset/companies/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateCompanyRoutePayload = TApiPayload<TUpdateCompanyRoutePath, undefined, TUpdateCompanyRouteBodyParams>;
export type TUpdateCompanyRouteResponse = paths['/core/asset/companies/{assetId}']['put']['responses']['200']['content']['application/json'];
export const updateCompanyRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","companies"], assetId]);
export function updateCompanyRoute(payload: TUpdateCompanyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCompanyRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/companies/{assetId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TFindComponentsRouteQuery = paths['/core/asset/components']['get']['parameters']['query'];
export type TFindComponentsRoutePayload = TApiPayload<undefined, TFindComponentsRouteQuery, undefined>;
export type TFindComponentsRouteResponse = paths['/core/asset/components']['get']['responses']['200']['content']['application/json'];
export const findComponentsRouteQueryKey = (query?: TFindComponentsRouteQuery): QueryKey => ([...["core","asset","components"], ...(query ? [query] : [])]);
export function findComponentsRoute(payload: TFindComponentsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindComponentsRouteResponse>> {
  return axiosClientV3.get('/core/asset/components', { signal: abortSignal, params: payload.query });
}

export type TCreateComponentRouteBodyParams = paths['/core/asset/components']['post']['requestBody']['content']['application/json'];
export type TCreateComponentRoutePayload = TApiPayload<undefined, undefined, TCreateComponentRouteBodyParams>;
export type TCreateComponentRouteResponse = paths['/core/asset/components']['post']['responses']['200']['content']['application/json'];
export const createComponentRouteQueryKey = (): QueryKey => ([...["core","asset","components"]]);
export function createComponentRoute(payload: TCreateComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateComponentRouteResponse>> {
  return axiosClientV3.post('/core/asset/components', payload.bodyParams, { signal: abortSignal });
}

export type TGetComponentRoutePath = paths['/core/asset/components/{assetId}']['get']['parameters']['path'];
export type TGetComponentRoutePayload = TApiPayload<TGetComponentRoutePath, undefined, undefined>;
export type TGetComponentRouteResponse = paths['/core/asset/components/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getComponentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","components"], assetId]);
export function getComponentRoute(payload: TGetComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetComponentRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/components/{assetId}', payload.path), { signal: abortSignal });
}

export type TUpdateComponentRoutePath = paths['/core/asset/components/{assetId}']['put']['parameters']['path'];
export type TUpdateComponentRouteBodyParams = paths['/core/asset/components/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateComponentRoutePayload = TApiPayload<TUpdateComponentRoutePath, undefined, TUpdateComponentRouteBodyParams>;
export type TUpdateComponentRouteResponse = paths['/core/asset/components/{assetId}']['put']['responses']['200']['content']['application/json'];
export const updateComponentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","components"], assetId]);
export function updateComponentRoute(payload: TUpdateComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateComponentRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/components/{assetId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteComponentRoutePath = paths['/core/asset/components/{assetId}']['delete']['parameters']['path'];
export type TDeleteComponentRoutePayload = TApiPayload<TDeleteComponentRoutePath, undefined, undefined>;
export type TDeleteComponentRouteResponse = paths['/core/asset/components/{assetId}']['delete']['responses']['200']['content']['application/json'];
export const deleteComponentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","components"], assetId]);
export function deleteComponentRoute(payload: TDeleteComponentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteComponentRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/asset/components/{assetId}', payload.path), { signal: abortSignal });
}

export type TGetEquipmentPath = paths['/core/asset/equipments/{assetId}']['get']['parameters']['path'];
export type TGetEquipmentPayload = TApiPayload<TGetEquipmentPath, undefined, undefined>;
export type TGetEquipmentResponse = paths['/core/asset/equipments/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getEquipmentQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments"], assetId]);
export function getEquipment(payload: TGetEquipmentPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/equipments/{assetId}', payload.path), { signal: abortSignal });
}

export type TUpdateEquipmentRoutePath = paths['/core/asset/equipments/{assetId}']['put']['parameters']['path'];
export type TUpdateEquipmentRouteBodyParams = paths['/core/asset/equipments/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateEquipmentRoutePayload = TApiPayload<TUpdateEquipmentRoutePath, undefined, TUpdateEquipmentRouteBodyParams>;
export type TUpdateEquipmentRouteResponse = paths['/core/asset/equipments/{assetId}']['put']['responses']['200']['content']['application/json'];
export const updateEquipmentRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments"], assetId]);
export function updateEquipmentRoute(payload: TUpdateEquipmentRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateEquipmentRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/equipments/{assetId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetEquipmentStatusPath = paths['/core/asset/equipments/{assetId}/status']['get']['parameters']['path'];
export type TGetEquipmentStatusPayload = TApiPayload<TGetEquipmentStatusPath, undefined, undefined>;
export type TGetEquipmentStatusResponse = paths['/core/asset/equipments/{assetId}/status']['get']['responses']['200']['content']['application/json'];
export const getEquipmentStatusQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","status"], assetId]);
export function getEquipmentStatus(payload: TGetEquipmentStatusPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentStatusResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/equipments/{assetId}/status', payload.path), { signal: abortSignal });
}

export type TGetEquipmentCertificatesRoutePath = paths['/core/asset/equipments/{assetId}/certificates']['get']['parameters']['path'];
export type TGetEquipmentCertificatesRoutePayload = TApiPayload<TGetEquipmentCertificatesRoutePath, undefined, undefined>;
export type TGetEquipmentCertificatesRouteResponse = paths['/core/asset/equipments/{assetId}/certificates']['get']['responses']['200']['content']['application/json'];
export const getEquipmentCertificatesRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","certificates"], assetId]);
export function getEquipmentCertificatesRoute(payload: TGetEquipmentCertificatesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentCertificatesRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/equipments/{assetId}/certificates', payload.path), { signal: abortSignal });
}

export type TSetEquipmentCertificatesRoutePath = paths['/core/asset/equipments/{assetId}/certificates']['put']['parameters']['path'];
export type TSetEquipmentCertificatesRouteBodyParams = paths['/core/asset/equipments/{assetId}/certificates']['put']['requestBody']['content']['application/json'];
export type TSetEquipmentCertificatesRoutePayload = TApiPayload<TSetEquipmentCertificatesRoutePath, undefined, TSetEquipmentCertificatesRouteBodyParams>;
export type TSetEquipmentCertificatesRouteResponse = paths['/core/asset/equipments/{assetId}/certificates']['put']['responses']['200']['content']['application/json'];
export const setEquipmentCertificatesRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","certificates"], assetId]);
export function setEquipmentCertificatesRoute(payload: TSetEquipmentCertificatesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetEquipmentCertificatesRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/equipments/{assetId}/certificates', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetEquipmentLimitsRoutePath = paths['/core/asset/equipments/{assetId}/meaning-limits']['get']['parameters']['path'];
export type TGetEquipmentLimitsRoutePayload = TApiPayload<TGetEquipmentLimitsRoutePath, undefined, undefined>;
export type TGetEquipmentLimitsRouteResponse = paths['/core/asset/equipments/{assetId}/meaning-limits']['get']['responses']['200']['content']['application/json'];
export const getEquipmentLimitsRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","meaning-limits"], assetId]);
export function getEquipmentLimitsRoute(payload: TGetEquipmentLimitsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentLimitsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/equipments/{assetId}/meaning-limits', payload.path), { signal: abortSignal });
}

export type TSetEquipmentLimitsRoutePath = paths['/core/asset/equipments/{assetId}/meaning-limits']['put']['parameters']['path'];
export type TSetEquipmentLimitsRouteBodyParams = paths['/core/asset/equipments/{assetId}/meaning-limits']['put']['requestBody']['content']['application/json'];
export type TSetEquipmentLimitsRoutePayload = TApiPayload<TSetEquipmentLimitsRoutePath, undefined, TSetEquipmentLimitsRouteBodyParams>;
export type TSetEquipmentLimitsRouteResponse = paths['/core/asset/equipments/{assetId}/meaning-limits']['put']['responses']['200']['content']['application/json'];
export const setEquipmentLimitsRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipments","meaning-limits"], assetId]);
export function setEquipmentLimitsRoute(payload: TSetEquipmentLimitsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetEquipmentLimitsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/equipments/{assetId}/meaning-limits', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TFindEquipmentGroupsRouteQuery = paths['/core/asset/equipment-groups']['get']['parameters']['query'];
export type TFindEquipmentGroupsRoutePayload = TApiPayload<undefined, TFindEquipmentGroupsRouteQuery, undefined>;
export type TFindEquipmentGroupsRouteResponse = paths['/core/asset/equipment-groups']['get']['responses']['200']['content']['application/json'];
export const findEquipmentGroupsRouteQueryKey = (query?: TFindEquipmentGroupsRouteQuery): QueryKey => ([...["core","asset","equipment-groups"], ...(query ? [query] : [])]);
export function findEquipmentGroupsRoute(payload: TFindEquipmentGroupsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindEquipmentGroupsRouteResponse>> {
  return axiosClientV3.get('/core/asset/equipment-groups', { signal: abortSignal, params: payload.query });
}

export type TCreateEquipmentGroupRouteBodyParams = paths['/core/asset/equipment-groups']['post']['requestBody']['content']['application/json'];
export type TCreateEquipmentGroupRoutePayload = TApiPayload<undefined, undefined, TCreateEquipmentGroupRouteBodyParams>;
export type TCreateEquipmentGroupRouteResponse = paths['/core/asset/equipment-groups']['post']['responses']['200']['content']['application/json'];
export const createEquipmentGroupRouteQueryKey = (): QueryKey => ([...["core","asset","equipment-groups"]]);
export function createEquipmentGroupRoute(payload: TCreateEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateEquipmentGroupRouteResponse>> {
  return axiosClientV3.post('/core/asset/equipment-groups', payload.bodyParams, { signal: abortSignal });
}

export type TGetEquipmentGroupRoutePath = paths['/core/asset/equipment-groups/{assetId}']['get']['parameters']['path'];
export type TGetEquipmentGroupRoutePayload = TApiPayload<TGetEquipmentGroupRoutePath, undefined, undefined>;
export type TGetEquipmentGroupRouteResponse = paths['/core/asset/equipment-groups/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getEquipmentGroupRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipment-groups"], assetId]);
export function getEquipmentGroupRoute(payload: TGetEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetEquipmentGroupRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/equipment-groups/{assetId}', payload.path), { signal: abortSignal });
}

export type TUpdateEquipmentGroupRoutePath = paths['/core/asset/equipment-groups/{assetId}']['put']['parameters']['path'];
export type TUpdateEquipmentGroupRouteBodyParams = paths['/core/asset/equipment-groups/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateEquipmentGroupRoutePayload = TApiPayload<TUpdateEquipmentGroupRoutePath, undefined, TUpdateEquipmentGroupRouteBodyParams>;
export type TUpdateEquipmentGroupRouteResponse = paths['/core/asset/equipment-groups/{assetId}']['put']['responses']['200']['content']['application/json'];
export const updateEquipmentGroupRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipment-groups"], assetId]);
export function updateEquipmentGroupRoute(payload: TUpdateEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateEquipmentGroupRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/equipment-groups/{assetId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteEquipmentGroupRoutePath = paths['/core/asset/equipment-groups/{assetId}']['delete']['parameters']['path'];
export type TDeleteEquipmentGroupRoutePayload = TApiPayload<TDeleteEquipmentGroupRoutePath, undefined, undefined>;
export type TDeleteEquipmentGroupRouteResponse = paths['/core/asset/equipment-groups/{assetId}']['delete']['responses']['200']['content']['application/json'];
export const deleteEquipmentGroupRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","equipment-groups"], assetId]);
export function deleteEquipmentGroupRoute(payload: TDeleteEquipmentGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteEquipmentGroupRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/asset/equipment-groups/{assetId}', payload.path), { signal: abortSignal });
}

export type TFindLocationsRouteQuery = paths['/core/asset/locations']['get']['parameters']['query'];
export type TFindLocationsRoutePayload = TApiPayload<undefined, TFindLocationsRouteQuery, undefined>;
export type TFindLocationsRouteResponse = paths['/core/asset/locations']['get']['responses']['200']['content']['application/json'];
export const findLocationsRouteQueryKey = (query?: TFindLocationsRouteQuery): QueryKey => ([...["core","asset","locations"], ...(query ? [query] : [])]);
export function findLocationsRoute(payload: TFindLocationsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindLocationsRouteResponse>> {
  return axiosClientV3.get('/core/asset/locations', { signal: abortSignal, params: payload.query });
}

export type TGetLocationRoutePath = paths['/core/asset/locations/{assetId}']['get']['parameters']['path'];
export type TGetLocationRoutePayload = TApiPayload<TGetLocationRoutePath, undefined, undefined>;
export type TGetLocationRouteResponse = paths['/core/asset/locations/{assetId}']['get']['responses']['200']['content']['application/json'];
export const getLocationRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","locations"], assetId]);
export function getLocationRoute(payload: TGetLocationRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetLocationRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset/locations/{assetId}', payload.path), { signal: abortSignal });
}

export type TUpdateLocationRoutePath = paths['/core/asset/locations/{assetId}']['put']['parameters']['path'];
export type TUpdateLocationRouteBodyParams = paths['/core/asset/locations/{assetId}']['put']['requestBody']['content']['application/json'];
export type TUpdateLocationRoutePayload = TApiPayload<TUpdateLocationRoutePath, undefined, TUpdateLocationRouteBodyParams>;
export type TUpdateLocationRouteResponse = paths['/core/asset/locations/{assetId}']['put']['responses']['200']['content']['application/json'];
export const updateLocationRouteQueryKey = (assetId: QueryKey[number]): QueryKey => ([...["core","asset","locations"], assetId]);
export function updateLocationRoute(payload: TUpdateLocationRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateLocationRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset/locations/{assetId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TFindAssetSettingsRouteQuery = paths['/core/asset-settings']['get']['parameters']['query'];
export type TFindAssetSettingsRoutePayload = TApiPayload<undefined, TFindAssetSettingsRouteQuery, undefined>;
export type TFindAssetSettingsRouteResponse = paths['/core/asset-settings']['get']['responses']['200']['content']['application/json'];
export const findAssetSettingsRouteQueryKey = (query?: TFindAssetSettingsRouteQuery): QueryKey => ([...["core","asset-settings"], ...(query ? [query] : [])]);
export function findAssetSettingsRoute(payload: TFindAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindAssetSettingsRouteResponse>> {
  return axiosClientV3.get('/core/asset-settings', { signal: abortSignal, params: payload.query });
}

export type TCreateAssetSettingsRouteBodyParams = paths['/core/asset-settings']['post']['requestBody']['content']['application/json'];
export type TCreateAssetSettingsRoutePayload = TApiPayload<undefined, undefined, TCreateAssetSettingsRouteBodyParams>;
export type TCreateAssetSettingsRouteResponse = paths['/core/asset-settings']['post']['responses']['200']['content']['application/json'];
export const createAssetSettingsRouteQueryKey = (): QueryKey => ([...["core","asset-settings"]]);
export function createAssetSettingsRoute(payload: TCreateAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateAssetSettingsRouteResponse>> {
  return axiosClientV3.post('/core/asset-settings', payload.bodyParams, { signal: abortSignal });
}

export type TGetAssetSettingsRoutePath = paths['/core/asset-settings/{assetSettingsId}']['get']['parameters']['path'];
export type TGetAssetSettingsRoutePayload = TApiPayload<TGetAssetSettingsRoutePath, undefined, undefined>;
export type TGetAssetSettingsRouteResponse = paths['/core/asset-settings/{assetSettingsId}']['get']['responses']['200']['content']['application/json'];
export const getAssetSettingsRouteQueryKey = (assetSettingsId: QueryKey[number]): QueryKey => ([...["core","asset-settings"], assetSettingsId]);
export function getAssetSettingsRoute(payload: TGetAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetSettingsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset-settings/{assetSettingsId}', payload.path), { signal: abortSignal });
}

export type TUpdateAssetSettingsRoutePath = paths['/core/asset-settings/{assetSettingsId}']['put']['parameters']['path'];
export type TUpdateAssetSettingsRouteBodyParams = paths['/core/asset-settings/{assetSettingsId}']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetSettingsRoutePayload = TApiPayload<TUpdateAssetSettingsRoutePath, undefined, TUpdateAssetSettingsRouteBodyParams>;
export type TUpdateAssetSettingsRouteResponse = paths['/core/asset-settings/{assetSettingsId}']['put']['responses']['200']['content']['application/json'];
export const updateAssetSettingsRouteQueryKey = (assetSettingsId: QueryKey[number]): QueryKey => ([...["core","asset-settings"], assetSettingsId]);
export function updateAssetSettingsRoute(payload: TUpdateAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetSettingsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset-settings/{assetSettingsId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteAssetSettingsRoutePath = paths['/core/asset-settings/{assetSettingsId}']['delete']['parameters']['path'];
export type TDeleteAssetSettingsRoutePayload = TApiPayload<TDeleteAssetSettingsRoutePath, undefined, undefined>;
export type TDeleteAssetSettingsRouteResponse = paths['/core/asset-settings/{assetSettingsId}']['delete']['responses']['200']['content']['application/json'];
export const deleteAssetSettingsRouteQueryKey = (assetSettingsId: QueryKey[number]): QueryKey => ([...["core","asset-settings"], assetSettingsId]);
export function deleteAssetSettingsRoute(payload: TDeleteAssetSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteAssetSettingsRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/asset-settings/{assetSettingsId}', payload.path), { signal: abortSignal });
}

export type TFindSparePartsRouteQuery = paths['/core/asset-spare-parts/parts']['get']['parameters']['query'];
export type TFindSparePartsRoutePayload = TApiPayload<undefined, TFindSparePartsRouteQuery, undefined>;
export type TFindSparePartsRouteResponse = paths['/core/asset-spare-parts/parts']['get']['responses']['200']['content']['application/json'];
export const findSparePartsRouteQueryKey = (query?: TFindSparePartsRouteQuery): QueryKey => ([...["core","asset-spare-parts","parts"], ...(query ? [query] : [])]);
export function findSparePartsRoute(payload: TFindSparePartsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindSparePartsRouteResponse>> {
  return axiosClientV3.get('/core/asset-spare-parts/parts', { signal: abortSignal, params: payload.query });
}

export type TCreateSparePartRouteBodyParams = paths['/core/asset-spare-parts/parts']['post']['requestBody']['content']['application/json'];
export type TCreateSparePartRoutePayload = TApiPayload<undefined, undefined, TCreateSparePartRouteBodyParams>;
export type TCreateSparePartRouteResponse = paths['/core/asset-spare-parts/parts']['post']['responses']['200']['content']['application/json'];
export const createSparePartRouteQueryKey = (): QueryKey => ([...["core","asset-spare-parts","parts"]]);
export function createSparePartRoute(payload: TCreateSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateSparePartRouteResponse>> {
  return axiosClientV3.post('/core/asset-spare-parts/parts', payload.bodyParams, { signal: abortSignal });
}

export type TGetSparePartRoutePath = paths['/core/asset-spare-parts/parts/{sparePartId}']['get']['parameters']['path'];
export type TGetSparePartRoutePayload = TApiPayload<TGetSparePartRoutePath, undefined, undefined>;
export type TGetSparePartRouteResponse = paths['/core/asset-spare-parts/parts/{sparePartId}']['get']['responses']['200']['content']['application/json'];
export const getSparePartRouteQueryKey = (sparePartId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","parts"], sparePartId]);
export function getSparePartRoute(payload: TGetSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetSparePartRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset-spare-parts/parts/{sparePartId}', payload.path), { signal: abortSignal });
}

export type TUpdateSparePartRoutePath = paths['/core/asset-spare-parts/parts/{sparePartId}']['put']['parameters']['path'];
export type TUpdateSparePartRouteBodyParams = paths['/core/asset-spare-parts/parts/{sparePartId}']['put']['requestBody']['content']['application/json'];
export type TUpdateSparePartRoutePayload = TApiPayload<TUpdateSparePartRoutePath, undefined, TUpdateSparePartRouteBodyParams>;
export type TUpdateSparePartRouteResponse = paths['/core/asset-spare-parts/parts/{sparePartId}']['put']['responses']['200']['content']['application/json'];
export const updateSparePartRouteQueryKey = (sparePartId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","parts"], sparePartId]);
export function updateSparePartRoute(payload: TUpdateSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateSparePartRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset-spare-parts/parts/{sparePartId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteSparePartRoutePath = paths['/core/asset-spare-parts/parts/{sparePartId}']['delete']['parameters']['path'];
export type TDeleteSparePartRoutePayload = TApiPayload<TDeleteSparePartRoutePath, undefined, undefined>;
export type TDeleteSparePartRouteResponse = paths['/core/asset-spare-parts/parts/{sparePartId}']['delete']['responses']['200']['content']['application/json'];
export const deleteSparePartRouteQueryKey = (sparePartId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","parts"], sparePartId]);
export function deleteSparePartRoute(payload: TDeleteSparePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteSparePartRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/asset-spare-parts/parts/{sparePartId}', payload.path), { signal: abortSignal });
}

export type TFindSparePartManufacturesRouteQuery = paths['/core/asset-spare-parts/manufacturers']['get']['parameters']['query'];
export type TFindSparePartManufacturesRoutePayload = TApiPayload<undefined, TFindSparePartManufacturesRouteQuery, undefined>;
export type TFindSparePartManufacturesRouteResponse = paths['/core/asset-spare-parts/manufacturers']['get']['responses']['200']['content']['application/json'];
export const findSparePartManufacturesRouteQueryKey = (query?: TFindSparePartManufacturesRouteQuery): QueryKey => ([...["core","asset-spare-parts","manufacturers"], ...(query ? [query] : [])]);
export function findSparePartManufacturesRoute(payload: TFindSparePartManufacturesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindSparePartManufacturesRouteResponse>> {
  return axiosClientV3.get('/core/asset-spare-parts/manufacturers', { signal: abortSignal, params: payload.query });
}

export type TCreateSparePartManufacturerRouteBodyParams = paths['/core/asset-spare-parts/manufacturers']['post']['requestBody']['content']['application/json'];
export type TCreateSparePartManufacturerRoutePayload = TApiPayload<undefined, undefined, TCreateSparePartManufacturerRouteBodyParams>;
export type TCreateSparePartManufacturerRouteResponse = paths['/core/asset-spare-parts/manufacturers']['post']['responses']['200']['content']['application/json'];
export const createSparePartManufacturerRouteQueryKey = (): QueryKey => ([...["core","asset-spare-parts","manufacturers"]]);
export function createSparePartManufacturerRoute(payload: TCreateSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateSparePartManufacturerRouteResponse>> {
  return axiosClientV3.post('/core/asset-spare-parts/manufacturers', payload.bodyParams, { signal: abortSignal });
}

export type TGetSparePartManufacturerRoutePath = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['get']['parameters']['path'];
export type TGetSparePartManufacturerRoutePayload = TApiPayload<TGetSparePartManufacturerRoutePath, undefined, undefined>;
export type TGetSparePartManufacturerRouteResponse = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['get']['responses']['200']['content']['application/json'];
export const getSparePartManufacturerRouteQueryKey = (manufacturerId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","manufacturers"], manufacturerId]);
export function getSparePartManufacturerRoute(payload: TGetSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetSparePartManufacturerRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/asset-spare-parts/manufacturers/{manufacturerId}', payload.path), { signal: abortSignal });
}

export type TUpdateSparePartManufacturerRoutePath = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['put']['parameters']['path'];
export type TUpdateSparePartManufacturerRouteBodyParams = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['put']['requestBody']['content']['application/json'];
export type TUpdateSparePartManufacturerRoutePayload = TApiPayload<TUpdateSparePartManufacturerRoutePath, undefined, TUpdateSparePartManufacturerRouteBodyParams>;
export type TUpdateSparePartManufacturerRouteResponse = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['put']['responses']['200']['content']['application/json'];
export const updateSparePartManufacturerRouteQueryKey = (manufacturerId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","manufacturers"], manufacturerId]);
export function updateSparePartManufacturerRoute(payload: TUpdateSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateSparePartManufacturerRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/asset-spare-parts/manufacturers/{manufacturerId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteSparePartManufacturerRoutePath = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['delete']['parameters']['path'];
export type TDeleteSparePartManufacturerRoutePayload = TApiPayload<TDeleteSparePartManufacturerRoutePath, undefined, undefined>;
export type TDeleteSparePartManufacturerRouteResponse = paths['/core/asset-spare-parts/manufacturers/{manufacturerId}']['delete']['responses']['200']['content']['application/json'];
export const deleteSparePartManufacturerRouteQueryKey = (manufacturerId: QueryKey[number]): QueryKey => ([...["core","asset-spare-parts","manufacturers"], manufacturerId]);
export function deleteSparePartManufacturerRoute(payload: TDeleteSparePartManufacturerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteSparePartManufacturerRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/asset-spare-parts/manufacturers/{manufacturerId}', payload.path), { signal: abortSignal });
}

export type TGetAssetTreeQuery = paths['/core/asset-tree']['get']['parameters']['query'];
export type TGetAssetTreePayload = TApiPayload<undefined, TGetAssetTreeQuery, undefined>;
export type TGetAssetTreeResponse = paths['/core/asset-tree']['get']['responses']['200']['content']['application/json'];
export const getAssetTreeQueryKey = (query?: TGetAssetTreeQuery): QueryKey => ([...["core","asset-tree"], ...(query ? [query] : [])]);
export function getAssetTree(payload: TGetAssetTreePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAssetTreeResponse>> {
  return axiosClientV3.get('/core/asset-tree', { signal: abortSignal, params: payload.query });
}

export type TUpdateAssetTreeRelativePositionsBodyParams = paths['/core/asset-tree']['put']['requestBody']['content']['application/json'];
export type TUpdateAssetTreeRelativePositionsPayload = TApiPayload<undefined, undefined, TUpdateAssetTreeRelativePositionsBodyParams>;
export type TUpdateAssetTreeRelativePositionsResponse = paths['/core/asset-tree']['put']['responses']['200']['content']['application/json'];
export const updateAssetTreeRelativePositionsQueryKey = (): QueryKey => ([...["core","asset-tree"]]);
export function updateAssetTreeRelativePositions(payload: TUpdateAssetTreeRelativePositionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateAssetTreeRelativePositionsResponse>> {
  return axiosClientV3.put('/core/asset-tree', payload.bodyParams, { signal: abortSignal });
}

export type TCreateTimeseriesNoteRouteBodyParams = paths['/core/data/timeseries/notes']['post']['requestBody']['content']['application/json'];
export type TCreateTimeseriesNoteRoutePayload = TApiPayload<undefined, undefined, TCreateTimeseriesNoteRouteBodyParams>;
export type TCreateTimeseriesNoteRouteResponse = paths['/core/data/timeseries/notes']['post']['responses']['200']['content']['application/json'];
export const createTimeseriesNoteRouteQueryKey = (): QueryKey => ([...["core","data","timeseries","notes"]]);
export function createTimeseriesNoteRoute(payload: TCreateTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateTimeseriesNoteRouteResponse>> {
  return axiosClientV3.post('/core/data/timeseries/notes', payload.bodyParams, { signal: abortSignal });
}

export type TFindTimeseriesNotesRouteQuery = paths['/core/data/timeseries/notes']['get']['parameters']['query'];
export type TFindTimeseriesNotesRoutePayload = TApiPayload<undefined, TFindTimeseriesNotesRouteQuery, undefined>;
export type TFindTimeseriesNotesRouteResponse = paths['/core/data/timeseries/notes']['get']['responses']['200']['content']['application/json'];
export const findTimeseriesNotesRouteQueryKey = (query?: TFindTimeseriesNotesRouteQuery): QueryKey => ([...["core","data","timeseries","notes"], ...(query ? [query] : [])]);
export function findTimeseriesNotesRoute(payload: TFindTimeseriesNotesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindTimeseriesNotesRouteResponse>> {
  return axiosClientV3.get('/core/data/timeseries/notes', { signal: abortSignal, params: payload.query });
}

export type TGetTimeseriesNoteRoutePath = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['get']['parameters']['path'];
export type TGetTimeseriesNoteRoutePayload = TApiPayload<TGetTimeseriesNoteRoutePath, undefined, undefined>;
export type TGetTimeseriesNoteRouteResponse = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['get']['responses']['200']['content']['application/json'];
export const getTimeseriesNoteRouteQueryKey = (timeseriesNoteId: QueryKey[number]): QueryKey => ([...["core","data","timeseries","notes"], timeseriesNoteId]);
export function getTimeseriesNoteRoute(payload: TGetTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetTimeseriesNoteRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/data/timeseries/notes/{timeseriesNoteId}', payload.path), { signal: abortSignal });
}

export type TUpdateTimeseriesNoteRoutePath = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['put']['parameters']['path'];
export type TUpdateTimeseriesNoteRouteBodyParams = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['put']['requestBody']['content']['application/json'];
export type TUpdateTimeseriesNoteRoutePayload = TApiPayload<TUpdateTimeseriesNoteRoutePath, undefined, TUpdateTimeseriesNoteRouteBodyParams>;
export type TUpdateTimeseriesNoteRouteResponse = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['put']['responses']['200']['content']['application/json'];
export const updateTimeseriesNoteRouteQueryKey = (timeseriesNoteId: QueryKey[number]): QueryKey => ([...["core","data","timeseries","notes"], timeseriesNoteId]);
export function updateTimeseriesNoteRoute(payload: TUpdateTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateTimeseriesNoteRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/data/timeseries/notes/{timeseriesNoteId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteTimeseriesNoteRoutePath = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['delete']['parameters']['path'];
export type TDeleteTimeseriesNoteRoutePayload = TApiPayload<TDeleteTimeseriesNoteRoutePath, undefined, undefined>;
export type TDeleteTimeseriesNoteRouteResponse = paths['/core/data/timeseries/notes/{timeseriesNoteId}']['delete']['responses']['200']['content']['application/json'];
export const deleteTimeseriesNoteRouteQueryKey = (timeseriesNoteId: QueryKey[number]): QueryKey => ([...["core","data","timeseries","notes"], timeseriesNoteId]);
export function deleteTimeseriesNoteRoute(payload: TDeleteTimeseriesNoteRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteTimeseriesNoteRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/data/timeseries/notes/{timeseriesNoteId}', payload.path), { signal: abortSignal });
}

export type TGetTimeseriesQuery = paths['/core/data/timeseries/{dataId}']['get']['parameters']['query'];
export type TGetTimeseriesPath = paths['/core/data/timeseries/{dataId}']['get']['parameters']['path'];
export type TGetTimeseriesPayload = TApiPayload<TGetTimeseriesPath, TGetTimeseriesQuery, undefined>;
export type TGetTimeseriesResponse = paths['/core/data/timeseries/{dataId}']['get']['responses']['200']['content']['application/json'];
export const getTimeseriesQueryKey = (dataId: QueryKey[number], query?: TGetTimeseriesQuery): QueryKey => ([...["core","data","timeseries"], dataId, ...(query ? [query] : [])]);
export function getTimeseries(payload: TGetTimeseriesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetTimeseriesResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/data/timeseries/{dataId}', payload.path), { signal: abortSignal, params: payload.query });
}

export type TGetJobValuesQuery = paths['/core/data/job-values/{dataId}']['get']['parameters']['query'];
export type TGetJobValuesPath = paths['/core/data/job-values/{dataId}']['get']['parameters']['path'];
export type TGetJobValuesPayload = TApiPayload<TGetJobValuesPath, TGetJobValuesQuery, undefined>;
export type TGetJobValuesResponse = paths['/core/data/job-values/{dataId}']['get']['responses']['200']['content']['application/json'];
export const getJobValuesQueryKey = (dataId: QueryKey[number], query?: TGetJobValuesQuery): QueryKey => ([...["core","data","job-values"], dataId, ...(query ? [query] : [])]);
export function getJobValues(payload: TGetJobValuesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobValuesResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/data/job-values/{dataId}', payload.path), { signal: abortSignal, params: payload.query });
}

export type TGetAlarmsQuery = paths['/core/data/alarms']['get']['parameters']['query'];
export type TGetAlarmsPayload = TApiPayload<undefined, TGetAlarmsQuery, undefined>;
export type TGetAlarmsResponse = paths['/core/data/alarms']['get']['responses']['200']['content']['application/json'];
export const getAlarmsQueryKey = (query?: TGetAlarmsQuery): QueryKey => ([...["core","data","alarms"], ...(query ? [query] : [])]);
export function getAlarms(payload: TGetAlarmsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAlarmsResponse>> {
  return axiosClientV3.get('/core/data/alarms', { signal: abortSignal, params: payload.query });
}

export type TFindDataSourcesRouteQuery = paths['/core/data-sources/data-sources']['get']['parameters']['query'];
export type TFindDataSourcesRoutePayload = TApiPayload<undefined, TFindDataSourcesRouteQuery, undefined>;
export type TFindDataSourcesRouteResponse = paths['/core/data-sources/data-sources']['get']['responses']['200']['content']['application/json'];
export const findDataSourcesRouteQueryKey = (query?: TFindDataSourcesRouteQuery): QueryKey => ([...["core","data-sources","data-sources"], ...(query ? [query] : [])]);
export function findDataSourcesRoute(payload: TFindDataSourcesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindDataSourcesRouteResponse>> {
  return axiosClientV3.get('/core/data-sources/data-sources', { signal: abortSignal, params: payload.query });
}

export type TCreateDataSourceRouteBodyParams = paths['/core/data-sources/data-sources']['post']['requestBody']['content']['application/json'];
export type TCreateDataSourceRoutePayload = TApiPayload<undefined, undefined, TCreateDataSourceRouteBodyParams>;
export type TCreateDataSourceRouteResponse = paths['/core/data-sources/data-sources']['post']['responses']['200']['content']['application/json'];
export const createDataSourceRouteQueryKey = (): QueryKey => ([...["core","data-sources","data-sources"]]);
export function createDataSourceRoute(payload: TCreateDataSourceRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateDataSourceRouteResponse>> {
  return axiosClientV3.post('/core/data-sources/data-sources', payload.bodyParams, { signal: abortSignal });
}

export type TGetDataSourceRoutePath = paths['/core/data-sources/data-sources/{dataSourceId}']['get']['parameters']['path'];
export type TGetDataSourceRoutePayload = TApiPayload<TGetDataSourceRoutePath, undefined, undefined>;
export type TGetDataSourceRouteResponse = paths['/core/data-sources/data-sources/{dataSourceId}']['get']['responses']['200']['content']['application/json'];
export const getDataSourceRouteQueryKey = (dataSourceId: QueryKey[number]): QueryKey => ([...["core","data-sources","data-sources"], dataSourceId]);
export function getDataSourceRoute(payload: TGetDataSourceRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDataSourceRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/data-sources/data-sources/{dataSourceId}', payload.path), { signal: abortSignal });
}

export type TUpdateDataSourceRoutePath = paths['/core/data-sources/data-sources/{dataSourceId}']['put']['parameters']['path'];
export type TUpdateDataSourceRouteBodyParams = paths['/core/data-sources/data-sources/{dataSourceId}']['put']['requestBody']['content']['application/json'];
export type TUpdateDataSourceRoutePayload = TApiPayload<TUpdateDataSourceRoutePath, undefined, TUpdateDataSourceRouteBodyParams>;
export type TUpdateDataSourceRouteResponse = paths['/core/data-sources/data-sources/{dataSourceId}']['put']['responses']['200']['content']['application/json'];
export const updateDataSourceRouteQueryKey = (dataSourceId: QueryKey[number]): QueryKey => ([...["core","data-sources","data-sources"], dataSourceId]);
export function updateDataSourceRoute(payload: TUpdateDataSourceRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateDataSourceRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/data-sources/data-sources/{dataSourceId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetDataSourceTreeRouteQuery = paths['/core/data-sources/tree']['get']['parameters']['query'];
export type TGetDataSourceTreeRoutePayload = TApiPayload<undefined, TGetDataSourceTreeRouteQuery, undefined>;
export type TGetDataSourceTreeRouteResponse = paths['/core/data-sources/tree']['get']['responses']['200']['content']['application/json'];
export const getDataSourceTreeRouteQueryKey = (query?: TGetDataSourceTreeRouteQuery): QueryKey => ([...["core","data-sources","tree"], ...(query ? [query] : [])]);
export function getDataSourceTreeRoute(payload: TGetDataSourceTreeRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDataSourceTreeRouteResponse>> {
  return axiosClientV3.get('/core/data-sources/tree', { signal: abortSignal, params: payload.query });
}

export type TFindMaterialRouteQuery = paths['/core/material/materials']['get']['parameters']['query'];
export type TFindMaterialRoutePayload = TApiPayload<undefined, TFindMaterialRouteQuery, undefined>;
export type TFindMaterialRouteResponse = paths['/core/material/materials']['get']['responses']['200']['content']['application/json'];
export const findMaterialRouteQueryKey = (query?: TFindMaterialRouteQuery): QueryKey => ([...["core","material","materials"], ...(query ? [query] : [])]);
export function findMaterialRoute(payload: TFindMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindMaterialRouteResponse>> {
  return axiosClientV3.get('/core/material/materials', { signal: abortSignal, params: payload.query });
}

export type TCreateMaterialRouteBodyParams = paths['/core/material/materials']['post']['requestBody']['content']['application/json'];
export type TCreateMaterialRoutePayload = TApiPayload<undefined, undefined, TCreateMaterialRouteBodyParams>;
export type TCreateMaterialRouteResponse = paths['/core/material/materials']['post']['responses']['200']['content']['application/json'];
export const createMaterialRouteQueryKey = (): QueryKey => ([...["core","material","materials"]]);
export function createMaterialRoute(payload: TCreateMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMaterialRouteResponse>> {
  return axiosClientV3.post('/core/material/materials', payload.bodyParams, { signal: abortSignal });
}

export type TDraftMaterialRouteBodyParams = paths['/core/material/materials/draft']['put']['requestBody']['content']['application/json'];
export type TDraftMaterialRoutePayload = TApiPayload<undefined, undefined, TDraftMaterialRouteBodyParams>;
export type TDraftMaterialRouteResponse = paths['/core/material/materials/draft']['put']['responses']['200']['content']['application/json'];
export const draftMaterialRouteQueryKey = (): QueryKey => ([...["core","material","materials","draft"]]);
export function draftMaterialRoute(payload: TDraftMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDraftMaterialRouteResponse>> {
  return axiosClientV3.put('/core/material/materials/draft', payload.bodyParams, { signal: abortSignal });
}

export type TGetMaterialRoutePath = paths['/core/material/materials/{materialId}']['get']['parameters']['path'];
export type TGetMaterialRoutePayload = TApiPayload<TGetMaterialRoutePath, undefined, undefined>;
export type TGetMaterialRouteResponse = paths['/core/material/materials/{materialId}']['get']['responses']['200']['content']['application/json'];
export const getMaterialRouteQueryKey = (materialId: QueryKey[number]): QueryKey => ([...["core","material","materials"], materialId]);
export function getMaterialRoute(payload: TGetMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMaterialRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/material/materials/{materialId}', payload.path), { signal: abortSignal });
}

export type TUpdateMaterialRoutePath = paths['/core/material/materials/{materialId}']['put']['parameters']['path'];
export type TUpdateMaterialRouteBodyParams = paths['/core/material/materials/{materialId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMaterialRoutePayload = TApiPayload<TUpdateMaterialRoutePath, undefined, TUpdateMaterialRouteBodyParams>;
export type TUpdateMaterialRouteResponse = paths['/core/material/materials/{materialId}']['put']['responses']['200']['content']['application/json'];
export const updateMaterialRouteQueryKey = (materialId: QueryKey[number]): QueryKey => ([...["core","material","materials"], materialId]);
export function updateMaterialRoute(payload: TUpdateMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMaterialRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/material/materials/{materialId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteMaterialRoutePath = paths['/core/material/materials/{materialId}']['delete']['parameters']['path'];
export type TDeleteMaterialRoutePayload = TApiPayload<TDeleteMaterialRoutePath, undefined, undefined>;
export type TDeleteMaterialRouteResponse = paths['/core/material/materials/{materialId}']['delete']['responses']['200']['content']['application/json'];
export const deleteMaterialRouteQueryKey = (materialId: QueryKey[number]): QueryKey => ([...["core","material","materials"], materialId]);
export function deleteMaterialRoute(payload: TDeleteMaterialRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteMaterialRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/material/materials/{materialId}', payload.path), { signal: abortSignal });
}

export type TFindMaterialGroupsRouteQuery = paths['/core/material/groups']['get']['parameters']['query'];
export type TFindMaterialGroupsRoutePayload = TApiPayload<undefined, TFindMaterialGroupsRouteQuery, undefined>;
export type TFindMaterialGroupsRouteResponse = paths['/core/material/groups']['get']['responses']['200']['content']['application/json'];
export const findMaterialGroupsRouteQueryKey = (query?: TFindMaterialGroupsRouteQuery): QueryKey => ([...["core","material","groups"], ...(query ? [query] : [])]);
export function findMaterialGroupsRoute(payload: TFindMaterialGroupsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindMaterialGroupsRouteResponse>> {
  return axiosClientV3.get('/core/material/groups', { signal: abortSignal, params: payload.query });
}

export type TCreateMaterialGroupRouteBodyParams = paths['/core/material/groups']['post']['requestBody']['content']['application/json'];
export type TCreateMaterialGroupRoutePayload = TApiPayload<undefined, undefined, TCreateMaterialGroupRouteBodyParams>;
export type TCreateMaterialGroupRouteResponse = paths['/core/material/groups']['post']['responses']['200']['content']['application/json'];
export const createMaterialGroupRouteQueryKey = (): QueryKey => ([...["core","material","groups"]]);
export function createMaterialGroupRoute(payload: TCreateMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMaterialGroupRouteResponse>> {
  return axiosClientV3.post('/core/material/groups', payload.bodyParams, { signal: abortSignal });
}

export type TGetMaterialGroupRoutePath = paths['/core/material/groups/{materialGroupId}']['get']['parameters']['path'];
export type TGetMaterialGroupRoutePayload = TApiPayload<TGetMaterialGroupRoutePath, undefined, undefined>;
export type TGetMaterialGroupRouteResponse = paths['/core/material/groups/{materialGroupId}']['get']['responses']['200']['content']['application/json'];
export const getMaterialGroupRouteQueryKey = (materialGroupId: QueryKey[number]): QueryKey => ([...["core","material","groups"], materialGroupId]);
export function getMaterialGroupRoute(payload: TGetMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMaterialGroupRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/material/groups/{materialGroupId}', payload.path), { signal: abortSignal });
}

export type TUpdateMaterialGroupRoutePath = paths['/core/material/groups/{materialGroupId}']['put']['parameters']['path'];
export type TUpdateMaterialGroupRouteBodyParams = paths['/core/material/groups/{materialGroupId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMaterialGroupRoutePayload = TApiPayload<TUpdateMaterialGroupRoutePath, undefined, TUpdateMaterialGroupRouteBodyParams>;
export type TUpdateMaterialGroupRouteResponse = paths['/core/material/groups/{materialGroupId}']['put']['responses']['200']['content']['application/json'];
export const updateMaterialGroupRouteQueryKey = (materialGroupId: QueryKey[number]): QueryKey => ([...["core","material","groups"], materialGroupId]);
export function updateMaterialGroupRoute(payload: TUpdateMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMaterialGroupRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/material/groups/{materialGroupId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteMaterialGroupRoutePath = paths['/core/material/groups/{materialGroupId}']['delete']['parameters']['path'];
export type TDeleteMaterialGroupRoutePayload = TApiPayload<TDeleteMaterialGroupRoutePath, undefined, undefined>;
export type TDeleteMaterialGroupRouteResponse = paths['/core/material/groups/{materialGroupId}']['delete']['responses']['200']['content']['application/json'];
export const deleteMaterialGroupRouteQueryKey = (materialGroupId: QueryKey[number]): QueryKey => ([...["core","material","groups"], materialGroupId]);
export function deleteMaterialGroupRoute(payload: TDeleteMaterialGroupRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteMaterialGroupRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/material/groups/{materialGroupId}', payload.path), { signal: abortSignal });
}

export type TGetMaterialSystemsRouteResponse = paths['/core/material/naming-systems']['get']['responses']['200']['content']['application/json'];
export const getMaterialSystemsRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems"]]);
export function getMaterialSystemsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMaterialSystemsRouteResponse>> {
  return axiosClientV3.get('/core/material/naming-systems', { signal: abortSignal });
}

export type TCreateMaterialSystemRouteBodyParams = paths['/core/material/naming-systems']['post']['requestBody']['content']['application/json'];
export type TCreateMaterialSystemRoutePayload = TApiPayload<undefined, undefined, TCreateMaterialSystemRouteBodyParams>;
export type TCreateMaterialSystemRouteResponse = paths['/core/material/naming-systems']['post']['responses']['200']['content']['application/json'];
export const createMaterialSystemRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems"]]);
export function createMaterialSystemRoute(payload: TCreateMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMaterialSystemRouteResponse>> {
  return axiosClientV3.post('/core/material/naming-systems', payload.bodyParams, { signal: abortSignal });
}

export type TGetDefaultMaterialSystemRouteResponse = paths['/core/material/naming-systems/default']['get']['responses']['200']['content']['application/json'];
export const getDefaultMaterialSystemRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems","default"]]);
export function getDefaultMaterialSystemRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetDefaultMaterialSystemRouteResponse>> {
  return axiosClientV3.get('/core/material/naming-systems/default', { signal: abortSignal });
}

export type TSetDefaultMaterialSystemRouteBodyParams = paths['/core/material/naming-systems/default']['put']['requestBody']['content']['application/json'];
export type TSetDefaultMaterialSystemRoutePayload = TApiPayload<undefined, undefined, TSetDefaultMaterialSystemRouteBodyParams>;
export type TSetDefaultMaterialSystemRouteResponse = paths['/core/material/naming-systems/default']['put']['responses']['200']['content']['application/json'];
export const setDefaultMaterialSystemRouteQueryKey = (): QueryKey => ([...["core","material","naming-systems","default"]]);
export function setDefaultMaterialSystemRoute(payload: TSetDefaultMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetDefaultMaterialSystemRouteResponse>> {
  return axiosClientV3.put('/core/material/naming-systems/default', payload.bodyParams, { signal: abortSignal });
}

export type TUpdateMaterialSystemRoutePath = paths['/core/material/naming-systems/{materialNamingSystemId}']['put']['parameters']['path'];
export type TUpdateMaterialSystemRouteBodyParams = paths['/core/material/naming-systems/{materialNamingSystemId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMaterialSystemRoutePayload = TApiPayload<TUpdateMaterialSystemRoutePath, undefined, TUpdateMaterialSystemRouteBodyParams>;
export type TUpdateMaterialSystemRouteResponse = paths['/core/material/naming-systems/{materialNamingSystemId}']['put']['responses']['200']['content']['application/json'];
export const updateMaterialSystemRouteQueryKey = (materialNamingSystemId: QueryKey[number]): QueryKey => ([...["core","material","naming-systems"], materialNamingSystemId]);
export function updateMaterialSystemRoute(payload: TUpdateMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMaterialSystemRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/material/naming-systems/{materialNamingSystemId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteMaterialSystemRoutePath = paths['/core/material/naming-systems/{materialNamingSystemId}']['delete']['parameters']['path'];
export type TDeleteMaterialSystemRoutePayload = TApiPayload<TDeleteMaterialSystemRoutePath, undefined, undefined>;
export type TDeleteMaterialSystemRouteResponse = paths['/core/material/naming-systems/{materialNamingSystemId}']['delete']['responses']['200']['content']['application/json'];
export const deleteMaterialSystemRouteQueryKey = (materialNamingSystemId: QueryKey[number]): QueryKey => ([...["core","material","naming-systems"], materialNamingSystemId]);
export function deleteMaterialSystemRoute(payload: TDeleteMaterialSystemRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteMaterialSystemRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/material/naming-systems/{materialNamingSystemId}', payload.path), { signal: abortSignal });
}

export type TFindCalendarEventsRoutesQuery = paths['/core/operation/calendar-events']['get']['parameters']['query'];
export type TFindCalendarEventsRoutesPayload = TApiPayload<undefined, TFindCalendarEventsRoutesQuery, undefined>;
export type TFindCalendarEventsRoutesResponse = paths['/core/operation/calendar-events']['get']['responses']['200']['content']['application/json'];
export const findCalendarEventsRoutesQueryKey = (query?: TFindCalendarEventsRoutesQuery): QueryKey => ([...["core","operation","calendar-events"], ...(query ? [query] : [])]);
export function findCalendarEventsRoutes(payload: TFindCalendarEventsRoutesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindCalendarEventsRoutesResponse>> {
  return axiosClientV3.get('/core/operation/calendar-events', { signal: abortSignal, params: payload.query });
}

export type TCreateCalendarEventRouteBodyParams = paths['/core/operation/calendar-events']['post']['requestBody']['content']['application/json'];
export type TCreateCalendarEventRoutePayload = TApiPayload<undefined, undefined, TCreateCalendarEventRouteBodyParams>;
export type TCreateCalendarEventRouteResponse = paths['/core/operation/calendar-events']['post']['responses']['200']['content']['application/json'];
export const createCalendarEventRouteQueryKey = (): QueryKey => ([...["core","operation","calendar-events"]]);
export function createCalendarEventRoute(payload: TCreateCalendarEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateCalendarEventRouteResponse>> {
  return axiosClientV3.post('/core/operation/calendar-events', payload.bodyParams, { signal: abortSignal });
}

export type TGetCalendarEventRoutePath = paths['/core/operation/calendar-events/{calendarEventId}']['get']['parameters']['path'];
export type TGetCalendarEventRoutePayload = TApiPayload<TGetCalendarEventRoutePath, undefined, undefined>;
export type TGetCalendarEventRouteResponse = paths['/core/operation/calendar-events/{calendarEventId}']['get']['responses']['200']['content']['application/json'];
export const getCalendarEventRouteQueryKey = (calendarEventId: QueryKey[number]): QueryKey => ([...["core","operation","calendar-events"], calendarEventId]);
export function getCalendarEventRoute(payload: TGetCalendarEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCalendarEventRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/operation/calendar-events/{calendarEventId}', payload.path), { signal: abortSignal });
}

export type TUpdateCalendarEventRoutePath = paths['/core/operation/calendar-events/{calendarEventId}']['put']['parameters']['path'];
export type TUpdateCalendarEventRouteBodyParams = paths['/core/operation/calendar-events/{calendarEventId}']['put']['requestBody']['content']['application/json'];
export type TUpdateCalendarEventRoutePayload = TApiPayload<TUpdateCalendarEventRoutePath, undefined, TUpdateCalendarEventRouteBodyParams>;
export type TUpdateCalendarEventRouteResponse = paths['/core/operation/calendar-events/{calendarEventId}']['put']['responses']['200']['content']['application/json'];
export const updateCalendarEventRouteQueryKey = (calendarEventId: QueryKey[number]): QueryKey => ([...["core","operation","calendar-events"], calendarEventId]);
export function updateCalendarEventRoute(payload: TUpdateCalendarEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCalendarEventRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/operation/calendar-events/{calendarEventId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteCalendarEventRoutePath = paths['/core/operation/calendar-events/{calendarEventId}']['delete']['parameters']['path'];
export type TDeleteCalendarEventRoutePayload = TApiPayload<TDeleteCalendarEventRoutePath, undefined, undefined>;
export type TDeleteCalendarEventRouteResponse = paths['/core/operation/calendar-events/{calendarEventId}']['delete']['responses']['200']['content']['application/json'];
export const deleteCalendarEventRouteQueryKey = (calendarEventId: QueryKey[number]): QueryKey => ([...["core","operation","calendar-events"], calendarEventId]);
export function deleteCalendarEventRoute(payload: TDeleteCalendarEventRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteCalendarEventRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/operation/calendar-events/{calendarEventId}', payload.path), { signal: abortSignal });
}

export type TFindCustomersRouteQuery = paths['/core/operation/customers']['get']['parameters']['query'];
export type TFindCustomersRoutePayload = TApiPayload<undefined, TFindCustomersRouteQuery, undefined>;
export type TFindCustomersRouteResponse = paths['/core/operation/customers']['get']['responses']['200']['content']['application/json'];
export const findCustomersRouteQueryKey = (query?: TFindCustomersRouteQuery): QueryKey => ([...["core","operation","customers"], ...(query ? [query] : [])]);
export function findCustomersRoute(payload: TFindCustomersRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindCustomersRouteResponse>> {
  return axiosClientV3.get('/core/operation/customers', { signal: abortSignal, params: payload.query });
}

export type TCreateCustomerRouteBodyParams = paths['/core/operation/customers']['post']['requestBody']['content']['application/json'];
export type TCreateCustomerRoutePayload = TApiPayload<undefined, undefined, TCreateCustomerRouteBodyParams>;
export type TCreateCustomerRouteResponse = paths['/core/operation/customers']['post']['responses']['200']['content']['application/json'];
export const createCustomerRouteQueryKey = (): QueryKey => ([...["core","operation","customers"]]);
export function createCustomerRoute(payload: TCreateCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateCustomerRouteResponse>> {
  return axiosClientV3.post('/core/operation/customers', payload.bodyParams, { signal: abortSignal });
}

export type TGetCustomerRoutePath = paths['/core/operation/customers/{customerId}']['get']['parameters']['path'];
export type TGetCustomerRoutePayload = TApiPayload<TGetCustomerRoutePath, undefined, undefined>;
export type TGetCustomerRouteResponse = paths['/core/operation/customers/{customerId}']['get']['responses']['200']['content']['application/json'];
export const getCustomerRouteQueryKey = (customerId: QueryKey[number]): QueryKey => ([...["core","operation","customers"], customerId]);
export function getCustomerRoute(payload: TGetCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCustomerRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/operation/customers/{customerId}', payload.path), { signal: abortSignal });
}

export type TUpdateCustomerRoutePath = paths['/core/operation/customers/{customerId}']['put']['parameters']['path'];
export type TUpdateCustomerRouteBodyParams = paths['/core/operation/customers/{customerId}']['put']['requestBody']['content']['application/json'];
export type TUpdateCustomerRoutePayload = TApiPayload<TUpdateCustomerRoutePath, undefined, TUpdateCustomerRouteBodyParams>;
export type TUpdateCustomerRouteResponse = paths['/core/operation/customers/{customerId}']['put']['responses']['200']['content']['application/json'];
export const updateCustomerRouteQueryKey = (customerId: QueryKey[number]): QueryKey => ([...["core","operation","customers"], customerId]);
export function updateCustomerRoute(payload: TUpdateCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCustomerRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/operation/customers/{customerId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteCustomerRoutePath = paths['/core/operation/customers/{customerId}']['delete']['parameters']['path'];
export type TDeleteCustomerRoutePayload = TApiPayload<TDeleteCustomerRoutePath, undefined, undefined>;
export type TDeleteCustomerRouteResponse = paths['/core/operation/customers/{customerId}']['delete']['responses']['200']['content']['application/json'];
export const deleteCustomerRouteQueryKey = (customerId: QueryKey[number]): QueryKey => ([...["core","operation","customers"], customerId]);
export function deleteCustomerRoute(payload: TDeleteCustomerRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteCustomerRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/operation/customers/{customerId}', payload.path), { signal: abortSignal });
}

export type TFindJobsQuery = paths['/core/operation/jobs']['get']['parameters']['query'];
export type TFindJobsPayload = TApiPayload<undefined, TFindJobsQuery, undefined>;
export type TFindJobsResponse = paths['/core/operation/jobs']['get']['responses']['200']['content']['application/json'];
export const findJobsQueryKey = (query?: TFindJobsQuery): QueryKey => ([...["core","operation","jobs"], ...(query ? [query] : [])]);
export function findJobs(payload: TFindJobsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindJobsResponse>> {
  return axiosClientV3.get('/core/operation/jobs', { signal: abortSignal, params: payload.query });
}

export type TGetJobPath = paths['/core/operation/jobs/{jobId}']['get']['parameters']['path'];
export type TGetJobPayload = TApiPayload<TGetJobPath, undefined, undefined>;
export type TGetJobResponse = paths['/core/operation/jobs/{jobId}']['get']['responses']['200']['content']['application/json'];
export const getJobQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs"], jobId]);
export function getJob(payload: TGetJobPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/operation/jobs/{jobId}', payload.path), { signal: abortSignal });
}

export type TGetJobImagesRoutePath = paths['/core/operation/jobs/{jobId}/images']['get']['parameters']['path'];
export type TGetJobImagesRoutePayload = TApiPayload<TGetJobImagesRoutePath, undefined, undefined>;
export type TGetJobImagesRouteResponse = paths['/core/operation/jobs/{jobId}/images']['get']['responses']['200']['content']['application/json'];
export const getJobImagesRouteQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId]);
export function getJobImagesRoute(payload: TGetJobImagesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobImagesRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/operation/jobs/{jobId}/images', payload.path), { signal: abortSignal });
}

export type TCreateJobImageRoutePath = paths['/core/operation/jobs/{jobId}/images']['post']['parameters']['path'];
export type TCreateJobImageRouteBodyParams = paths['/core/operation/jobs/{jobId}/images']['post']['requestBody']['content']['application/json'];
export type TCreateJobImageRoutePayload = TApiPayload<TCreateJobImageRoutePath, undefined, TCreateJobImageRouteBodyParams>;
export type TCreateJobImageRouteResponse = paths['/core/operation/jobs/{jobId}/images']['post']['responses']['200']['content']['application/json'];
export const createJobImageRouteQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId]);
export function createJobImageRoute(payload: TCreateJobImageRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateJobImageRouteResponse>> {
  return axiosClientV3.post(interpolateUrl('/core/operation/jobs/{jobId}/images', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TUpdateJobImageNameRoutePath = paths['/core/operation/jobs/{jobId}/images/{blobId}']['put']['parameters']['path'];
export type TUpdateJobImageNameRouteBodyParams = paths['/core/operation/jobs/{jobId}/images/{blobId}']['put']['requestBody']['content']['application/json'];
export type TUpdateJobImageNameRoutePayload = TApiPayload<TUpdateJobImageNameRoutePath, undefined, TUpdateJobImageNameRouteBodyParams>;
export type TUpdateJobImageNameRouteResponse = paths['/core/operation/jobs/{jobId}/images/{blobId}']['put']['responses']['200']['content']['application/json'];
export const updateJobImageNameRouteQueryKey = (jobId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId, blobId]);
export function updateJobImageNameRoute(payload: TUpdateJobImageNameRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateJobImageNameRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/operation/jobs/{jobId}/images/{blobId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteJobImageRoutePath = paths['/core/operation/jobs/{jobId}/images/{blobId}']['delete']['parameters']['path'];
export type TDeleteJobImageRoutePayload = TApiPayload<TDeleteJobImageRoutePath, undefined, undefined>;
export type TDeleteJobImageRouteResponse = paths['/core/operation/jobs/{jobId}/images/{blobId}']['delete']['responses']['200']['content']['application/json'];
export const deleteJobImageRouteQueryKey = (jobId: QueryKey[number], blobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","images"], jobId, blobId]);
export function deleteJobImageRoute(payload: TDeleteJobImageRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteJobImageRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/operation/jobs/{jobId}/images/{blobId}', payload.path), { signal: abortSignal });
}

export type TGetJobStagesPath = paths['/core/operation/jobs/{jobId}/stages']['get']['parameters']['path'];
export type TGetJobStagesPayload = TApiPayload<TGetJobStagesPath, undefined, undefined>;
export type TGetJobStagesResponse = paths['/core/operation/jobs/{jobId}/stages']['get']['responses']['200']['content']['application/json'];
export const getJobStagesQueryKey = (jobId: QueryKey[number]): QueryKey => ([...["core","operation","jobs","stages"], jobId]);
export function getJobStages(payload: TGetJobStagesPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetJobStagesResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/operation/jobs/{jobId}/stages', payload.path), { signal: abortSignal });
}

export type TFindPartAttributesRouteQuery = paths['/core/operation/parts/attributes']['get']['parameters']['query'];
export type TFindPartAttributesRoutePayload = TApiPayload<undefined, TFindPartAttributesRouteQuery, undefined>;
export type TFindPartAttributesRouteResponse = paths['/core/operation/parts/attributes']['get']['responses']['200']['content']['application/json'];
export const findPartAttributesRouteQueryKey = (query?: TFindPartAttributesRouteQuery): QueryKey => ([...["core","operation","parts","attributes"], ...(query ? [query] : [])]);
export function findPartAttributesRoute(payload: TFindPartAttributesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindPartAttributesRouteResponse>> {
  return axiosClientV3.get('/core/operation/parts/attributes', { signal: abortSignal, params: payload.query });
}

export type TUpdatePartAttributeRouteBodyParams = paths['/core/operation/parts/attributes']['post']['requestBody']['content']['application/json'];
export type TUpdatePartAttributeRoutePayload = TApiPayload<undefined, undefined, TUpdatePartAttributeRouteBodyParams>;
export type TUpdatePartAttributeRouteResponse = paths['/core/operation/parts/attributes']['post']['responses']['200']['content']['application/json'];
export const updatePartAttributeRouteQueryKey = (): QueryKey => ([...["core","operation","parts","attributes"]]);
export function updatePartAttributeRoute(payload: TUpdatePartAttributeRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdatePartAttributeRouteResponse>> {
  return axiosClientV3.post('/core/operation/parts/attributes', payload.bodyParams, { signal: abortSignal });
}

export type TDeletePartAttributesRoutePath = paths['/core/operation/parts/attributes/{partAttributeId}']['delete']['parameters']['path'];
export type TDeletePartAttributesRoutePayload = TApiPayload<TDeletePartAttributesRoutePath, undefined, undefined>;
export type TDeletePartAttributesRouteResponse = paths['/core/operation/parts/attributes/{partAttributeId}']['delete']['responses']['200']['content']['application/json'];
export const deletePartAttributesRouteQueryKey = (partAttributeId: QueryKey[number]): QueryKey => ([...["core","operation","parts","attributes"], partAttributeId]);
export function deletePartAttributesRoute(payload: TDeletePartAttributesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeletePartAttributesRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/operation/parts/attributes/{partAttributeId}', payload.path), { signal: abortSignal });
}

export type TFindPartInstancesRouteQuery = paths['/core/operation/parts/instances']['get']['parameters']['query'];
export type TFindPartInstancesRoutePayload = TApiPayload<undefined, TFindPartInstancesRouteQuery, undefined>;
export type TFindPartInstancesRouteResponse = paths['/core/operation/parts/instances']['get']['responses']['200']['content']['application/json'];
export const findPartInstancesRouteQueryKey = (query?: TFindPartInstancesRouteQuery): QueryKey => ([...["core","operation","parts","instances"], ...(query ? [query] : [])]);
export function findPartInstancesRoute(payload: TFindPartInstancesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindPartInstancesRouteResponse>> {
  return axiosClientV3.get('/core/operation/parts/instances', { signal: abortSignal, params: payload.query });
}

export type TFindPartsRouteQuery = paths['/core/operation/parts']['get']['parameters']['query'];
export type TFindPartsRoutePayload = TApiPayload<undefined, TFindPartsRouteQuery, undefined>;
export type TFindPartsRouteResponse = paths['/core/operation/parts']['get']['responses']['200']['content']['application/json'];
export const findPartsRouteQueryKey = (query?: TFindPartsRouteQuery): QueryKey => ([...["core","operation","parts"], ...(query ? [query] : [])]);
export function findPartsRoute(payload: TFindPartsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindPartsRouteResponse>> {
  return axiosClientV3.get('/core/operation/parts', { signal: abortSignal, params: payload.query });
}

export type TCreatePartRouteBodyParams = paths['/core/operation/parts']['post']['requestBody']['content']['application/json'];
export type TCreatePartRoutePayload = TApiPayload<undefined, undefined, TCreatePartRouteBodyParams>;
export type TCreatePartRouteResponse = paths['/core/operation/parts']['post']['responses']['200']['content']['application/json'];
export const createPartRouteQueryKey = (): QueryKey => ([...["core","operation","parts"]]);
export function createPartRoute(payload: TCreatePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreatePartRouteResponse>> {
  return axiosClientV3.post('/core/operation/parts', payload.bodyParams, { signal: abortSignal });
}

export type TCreateOnetimePartRouteBodyParams = paths['/core/operation/parts/onetime']['post']['requestBody']['content']['application/json'];
export type TCreateOnetimePartRoutePayload = TApiPayload<undefined, undefined, TCreateOnetimePartRouteBodyParams>;
export type TCreateOnetimePartRouteResponse = paths['/core/operation/parts/onetime']['post']['responses']['200']['content']['application/json'];
export const createOnetimePartRouteQueryKey = (): QueryKey => ([...["core","operation","parts","onetime"]]);
export function createOnetimePartRoute(payload: TCreateOnetimePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateOnetimePartRouteResponse>> {
  return axiosClientV3.post('/core/operation/parts/onetime', payload.bodyParams, { signal: abortSignal });
}

export type TGetPartRoutePath = paths['/core/operation/parts/{partId}']['get']['parameters']['path'];
export type TGetPartRoutePayload = TApiPayload<TGetPartRoutePath, undefined, undefined>;
export type TGetPartRouteResponse = paths['/core/operation/parts/{partId}']['get']['responses']['200']['content']['application/json'];
export const getPartRouteQueryKey = (partId: QueryKey[number]): QueryKey => ([...["core","operation","parts"], partId]);
export function getPartRoute(payload: TGetPartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetPartRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/operation/parts/{partId}', payload.path), { signal: abortSignal });
}

export type TUpdatePartRoutePath = paths['/core/operation/parts/{partId}']['put']['parameters']['path'];
export type TUpdatePartRouteBodyParams = paths['/core/operation/parts/{partId}']['put']['requestBody']['content']['application/json'];
export type TUpdatePartRoutePayload = TApiPayload<TUpdatePartRoutePath, undefined, TUpdatePartRouteBodyParams>;
export type TUpdatePartRouteResponse = paths['/core/operation/parts/{partId}']['put']['responses']['200']['content']['application/json'];
export const updatePartRouteQueryKey = (partId: QueryKey[number]): QueryKey => ([...["core","operation","parts"], partId]);
export function updatePartRoute(payload: TUpdatePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdatePartRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/operation/parts/{partId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeletePartRoutePath = paths['/core/operation/parts/{partId}']['delete']['parameters']['path'];
export type TDeletePartRoutePayload = TApiPayload<TDeletePartRoutePath, undefined, undefined>;
export type TDeletePartRouteResponse = paths['/core/operation/parts/{partId}']['delete']['responses']['200']['content']['application/json'];
export const deletePartRouteQueryKey = (partId: QueryKey[number]): QueryKey => ([...["core","operation","parts"], partId]);
export function deletePartRoute(payload: TDeletePartRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeletePartRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/operation/parts/{partId}', payload.path), { signal: abortSignal });
}

export type TGetPermissionsRouteResponse = paths['/core/permissions']['get']['responses']['200']['content']['application/json'];
export const getPermissionsRouteQueryKey = (): QueryKey => ([...["core","permissions"]]);
export function getPermissionsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetPermissionsRouteResponse>> {
  return axiosClientV3.get('/core/permissions', { signal: abortSignal });
}

export type TFindRecipesRouteQuery = paths['/core/recipe']['get']['parameters']['query'];
export type TFindRecipesRoutePayload = TApiPayload<undefined, TFindRecipesRouteQuery, undefined>;
export type TFindRecipesRouteResponse = paths['/core/recipe']['get']['responses']['200']['content']['application/json'];
export const findRecipesRouteQueryKey = (query?: TFindRecipesRouteQuery): QueryKey => ([...["core","recipe"], ...(query ? [query] : [])]);
export function findRecipesRoute(payload: TFindRecipesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindRecipesRouteResponse>> {
  return axiosClientV3.get('/core/recipe', { signal: abortSignal, params: payload.query });
}

export type TFindRolesRouteQuery = paths['/core/roles']['get']['parameters']['query'];
export type TFindRolesRoutePayload = TApiPayload<undefined, TFindRolesRouteQuery, undefined>;
export type TFindRolesRouteResponse = paths['/core/roles']['get']['responses']['200']['content']['application/json'];
export const findRolesRouteQueryKey = (query?: TFindRolesRouteQuery): QueryKey => ([...["core","roles"], ...(query ? [query] : [])]);
export function findRolesRoute(payload: TFindRolesRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindRolesRouteResponse>> {
  return axiosClientV3.get('/core/roles', { signal: abortSignal, params: payload.query });
}

export type TCreateRoleRouteBodyParams = paths['/core/roles']['post']['requestBody']['content']['application/json'];
export type TCreateRoleRoutePayload = TApiPayload<undefined, undefined, TCreateRoleRouteBodyParams>;
export type TCreateRoleRouteResponse = paths['/core/roles']['post']['responses']['200']['content']['application/json'];
export const createRoleRouteQueryKey = (): QueryKey => ([...["core","roles"]]);
export function createRoleRoute(payload: TCreateRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateRoleRouteResponse>> {
  return axiosClientV3.post('/core/roles', payload.bodyParams, { signal: abortSignal });
}

export type TGetRoleRoutePath = paths['/core/roles/{roleId}']['get']['parameters']['path'];
export type TGetRoleRoutePayload = TApiPayload<TGetRoleRoutePath, undefined, undefined>;
export type TGetRoleRouteResponse = paths['/core/roles/{roleId}']['get']['responses']['200']['content']['application/json'];
export const getRoleRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles"], roleId]);
export function getRoleRoute(payload: TGetRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetRoleRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/roles/{roleId}', payload.path), { signal: abortSignal });
}

export type TUpdateRoleRoutePath = paths['/core/roles/{roleId}']['put']['parameters']['path'];
export type TUpdateRoleRouteBodyParams = paths['/core/roles/{roleId}']['put']['requestBody']['content']['application/json'];
export type TUpdateRoleRoutePayload = TApiPayload<TUpdateRoleRoutePath, undefined, TUpdateRoleRouteBodyParams>;
export type TUpdateRoleRouteResponse = paths['/core/roles/{roleId}']['put']['responses']['200']['content']['application/json'];
export const updateRoleRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles"], roleId]);
export function updateRoleRoute(payload: TUpdateRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateRoleRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/roles/{roleId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteRoleRoutePath = paths['/core/roles/{roleId}']['delete']['parameters']['path'];
export type TDeleteRoleRoutePayload = TApiPayload<TDeleteRoleRoutePath, undefined, undefined>;
export type TDeleteRoleRouteResponse = paths['/core/roles/{roleId}']['delete']['responses']['200']['content']['application/json'];
export const deleteRoleRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles"], roleId]);
export function deleteRoleRoute(payload: TDeleteRoleRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteRoleRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/roles/{roleId}', payload.path), { signal: abortSignal });
}

export type TGetRolePermissionsRoutePath = paths['/core/roles/{roleId}/permissions']['get']['parameters']['path'];
export type TGetRolePermissionsRoutePayload = TApiPayload<TGetRolePermissionsRoutePath, undefined, undefined>;
export type TGetRolePermissionsRouteResponse = paths['/core/roles/{roleId}/permissions']['get']['responses']['200']['content']['application/json'];
export const getRolePermissionsRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles","permissions"], roleId]);
export function getRolePermissionsRoute(payload: TGetRolePermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetRolePermissionsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/roles/{roleId}/permissions', payload.path), { signal: abortSignal });
}

export type TUpdateRolePermissionsRoutePath = paths['/core/roles/{roleId}/permissions']['put']['parameters']['path'];
export type TUpdateRolePermissionsRouteBodyParams = paths['/core/roles/{roleId}/permissions']['put']['requestBody']['content']['application/json'];
export type TUpdateRolePermissionsRoutePayload = TApiPayload<TUpdateRolePermissionsRoutePath, undefined, TUpdateRolePermissionsRouteBodyParams>;
export type TUpdateRolePermissionsRouteResponse = paths['/core/roles/{roleId}/permissions']['put']['responses']['200']['content']['application/json'];
export const updateRolePermissionsRouteQueryKey = (roleId: QueryKey[number]): QueryKey => ([...["core","roles","permissions"], roleId]);
export function updateRolePermissionsRoute(payload: TUpdateRolePermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateRolePermissionsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/roles/{roleId}/permissions', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetMeaningsRouteQuery = paths['/core/semantics/meanings']['get']['parameters']['query'];
export type TGetMeaningsRoutePayload = TApiPayload<undefined, TGetMeaningsRouteQuery, undefined>;
export type TGetMeaningsRouteResponse = paths['/core/semantics/meanings']['get']['responses']['200']['content']['application/json'];
export const getMeaningsRouteQueryKey = (query?: TGetMeaningsRouteQuery): QueryKey => ([...["core","semantics","meanings"], ...(query ? [query] : [])]);
export function getMeaningsRoute(payload: TGetMeaningsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMeaningsRouteResponse>> {
  return axiosClientV3.get('/core/semantics/meanings', { signal: abortSignal, params: payload.query });
}

export type TGetMeaningRoutePath = paths['/core/semantics/meanings/{meaningId}']['get']['parameters']['path'];
export type TGetMeaningRoutePayload = TApiPayload<TGetMeaningRoutePath, undefined, undefined>;
export type TGetMeaningRouteResponse = paths['/core/semantics/meanings/{meaningId}']['get']['responses']['200']['content']['application/json'];
export const getMeaningRouteQueryKey = (meaningId: QueryKey[number]): QueryKey => ([...["core","semantics","meanings"], meaningId]);
export function getMeaningRoute(payload: TGetMeaningRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMeaningRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/semantics/meanings/{meaningId}', payload.path), { signal: abortSignal });
}

export type TUpdateMeaningRoutePath = paths['/core/semantics/meanings/{meaningId}']['put']['parameters']['path'];
export type TUpdateMeaningRouteBodyParams = paths['/core/semantics/meanings/{meaningId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMeaningRoutePayload = TApiPayload<TUpdateMeaningRoutePath, undefined, TUpdateMeaningRouteBodyParams>;
export type TUpdateMeaningRouteResponse = paths['/core/semantics/meanings/{meaningId}']['put']['responses']['200']['content']['application/json'];
export const updateMeaningRouteQueryKey = (meaningId: QueryKey[number]): QueryKey => ([...["core","semantics","meanings"], meaningId]);
export function updateMeaningRoute(payload: TUpdateMeaningRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMeaningRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/semantics/meanings/{meaningId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TFindMetadataQuery = paths['/core/semantics/metadata']['get']['parameters']['query'];
export type TFindMetadataPayload = TApiPayload<undefined, TFindMetadataQuery, undefined>;
export type TFindMetadataResponse = paths['/core/semantics/metadata']['get']['responses']['200']['content']['application/json'];
export const findMetadataQueryKey = (query?: TFindMetadataQuery): QueryKey => ([...["core","semantics","metadata"], ...(query ? [query] : [])]);
export function findMetadata(payload: TFindMetadataPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TFindMetadataResponse>> {
  return axiosClientV3.get('/core/semantics/metadata', { signal: abortSignal, params: payload.query });
}

export type TCreateMetadataRouteBodyParams = paths['/core/semantics/metadata']['post']['requestBody']['content']['application/json'];
export type TCreateMetadataRoutePayload = TApiPayload<undefined, undefined, TCreateMetadataRouteBodyParams>;
export type TCreateMetadataRouteResponse = paths['/core/semantics/metadata']['post']['responses']['200']['content']['application/json'];
export const createMetadataRouteQueryKey = (): QueryKey => ([...["core","semantics","metadata"]]);
export function createMetadataRoute(payload: TCreateMetadataRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateMetadataRouteResponse>> {
  return axiosClientV3.post('/core/semantics/metadata', payload.bodyParams, { signal: abortSignal });
}

export type TGetMetadataPath = paths['/core/semantics/metadata/{dataId}']['get']['parameters']['path'];
export type TGetMetadataPayload = TApiPayload<TGetMetadataPath, undefined, undefined>;
export type TGetMetadataResponse = paths['/core/semantics/metadata/{dataId}']['get']['responses']['200']['content']['application/json'];
export const getMetadataQueryKey = (dataId: QueryKey[number]): QueryKey => ([...["core","semantics","metadata"], dataId]);
export function getMetadata(payload: TGetMetadataPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetMetadataResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/semantics/metadata/{dataId}', payload.path), { signal: abortSignal });
}

export type TUpdateMetadataPath = paths['/core/semantics/metadata/{dataId}']['put']['parameters']['path'];
export type TUpdateMetadataBodyParams = paths['/core/semantics/metadata/{dataId}']['put']['requestBody']['content']['application/json'];
export type TUpdateMetadataPayload = TApiPayload<TUpdateMetadataPath, undefined, TUpdateMetadataBodyParams>;
export type TUpdateMetadataResponse = paths['/core/semantics/metadata/{dataId}']['put']['responses']['200']['content']['application/json'];
export const updateMetadataQueryKey = (dataId: QueryKey[number]): QueryKey => ([...["core","semantics","metadata"], dataId]);
export function updateMetadata(payload: TUpdateMetadataPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateMetadataResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/semantics/metadata/{dataId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TSetMetadataMeaningPath = paths['/core/semantics/metadata/{dataId}/meaning']['put']['parameters']['path'];
export type TSetMetadataMeaningBodyParams = paths['/core/semantics/metadata/{dataId}/meaning']['put']['requestBody']['content']['application/json'];
export type TSetMetadataMeaningPayload = TApiPayload<TSetMetadataMeaningPath, undefined, TSetMetadataMeaningBodyParams>;
export type TSetMetadataMeaningResponse = paths['/core/semantics/metadata/{dataId}/meaning']['put']['responses']['200']['content']['application/json'];
export const setMetadataMeaningQueryKey = (dataId: QueryKey[number]): QueryKey => ([...["core","semantics","metadata","meaning"], dataId]);
export function setMetadataMeaning(payload: TSetMetadataMeaningPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetMetadataMeaningResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/semantics/metadata/{dataId}/meaning', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetProfileMeaningsPath = paths['/core/semantics/profiles/{profileId}/meanings']['get']['parameters']['path'];
export type TGetProfileMeaningsPayload = TApiPayload<TGetProfileMeaningsPath, undefined, undefined>;
export type TGetProfileMeaningsResponse = paths['/core/semantics/profiles/{profileId}/meanings']['get']['responses']['200']['content']['application/json'];
export const getProfileMeaningsQueryKey = (profileId: QueryKey[number]): QueryKey => ([...["core","semantics","profiles","meanings"], profileId]);
export function getProfileMeanings(payload: TGetProfileMeaningsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetProfileMeaningsResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/semantics/profiles/{profileId}/meanings', payload.path), { signal: abortSignal });
}

export type TFindUnitOfMeasurementsRouteResponse = paths['/core/semantics/unit-of-measures']['get']['responses']['200']['content']['application/json'];
export const findUnitOfMeasurementsRouteQueryKey = (): QueryKey => ([...["core","semantics","unit-of-measures"]]);
export function findUnitOfMeasurementsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TFindUnitOfMeasurementsRouteResponse>> {
  return axiosClientV3.get('/core/semantics/unit-of-measures', { signal: abortSignal });
}

export type TGetUnitOfMeasurementRoutePath = paths['/core/semantics/unit-of-measures/{unitOfMeasurementId}']['get']['parameters']['path'];
export type TGetUnitOfMeasurementRoutePayload = TApiPayload<TGetUnitOfMeasurementRoutePath, undefined, undefined>;
export type TGetUnitOfMeasurementRouteResponse = paths['/core/semantics/unit-of-measures/{unitOfMeasurementId}']['get']['responses']['200']['content']['application/json'];
export const getUnitOfMeasurementRouteQueryKey = (unitOfMeasurementId: QueryKey[number]): QueryKey => ([...["core","semantics","unit-of-measures"], unitOfMeasurementId]);
export function getUnitOfMeasurementRoute(payload: TGetUnitOfMeasurementRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUnitOfMeasurementRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/semantics/unit-of-measures/{unitOfMeasurementId}', payload.path), { signal: abortSignal });
}

export type TGetAllSettingsRouteResponse = paths['/core/settings']['get']['responses']['200']['content']['application/json'];
export const getAllSettingsRouteQueryKey = (): QueryKey => ([...["core","settings"]]);
export function getAllSettingsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAllSettingsRouteResponse>> {
  return axiosClientV3.get('/core/settings', { signal: abortSignal });
}

export type TUpdateSettingsRouteBodyParams = paths['/core/settings']['put']['requestBody']['content']['application/json'];
export type TUpdateSettingsRoutePayload = TApiPayload<undefined, undefined, TUpdateSettingsRouteBodyParams>;
export type TUpdateSettingsRouteResponse = paths['/core/settings']['put']['responses']['200']['content']['application/json'];
export const updateSettingsRouteQueryKey = (): QueryKey => ([...["core","settings"]]);
export function updateSettingsRoute(payload: TUpdateSettingsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateSettingsRouteResponse>> {
  return axiosClientV3.put('/core/settings', payload.bodyParams, { signal: abortSignal });
}

export type TGetSettingsStructureRouteResponse = paths['/core/settings/structure']['get']['responses']['200']['content']['application/json'];
export const getSettingsStructureRouteQueryKey = (): QueryKey => ([...["core","settings","structure"]]);
export function getSettingsStructureRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetSettingsStructureRouteResponse>> {
  return axiosClientV3.get('/core/settings/structure', { signal: abortSignal });
}

export type TGetTenantLogoRouteResponse = paths['/core/settings/tenant-logo']['get']['responses']['200']['content']['application/json'];
export const getTenantLogoRouteQueryKey = (): QueryKey => ([...["core","settings","tenant-logo"]]);
export function getTenantLogoRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetTenantLogoRouteResponse>> {
  return axiosClientV3.get('/core/settings/tenant-logo', { signal: abortSignal });
}

export type TSetTenantLogoRouteBodyParams = paths['/core/settings/tenant-logo']['put']['requestBody']['content']['multipart/form-data'];
export type TSetTenantLogoRoutePayload = TApiPayload<undefined, undefined, TSetTenantLogoRouteBodyParams>;
export type TSetTenantLogoRouteResponse = paths['/core/settings/tenant-logo']['put']['responses']['200']['content']['application/json'];
export const setTenantLogoRouteQueryKey = (): QueryKey => ([...["core","settings","tenant-logo"]]);
export function setTenantLogoRoute(payload: TSetTenantLogoRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetTenantLogoRouteResponse>> {
  return axiosClientV3.put('/core/settings/tenant-logo', payload.bodyParams, { signal: abortSignal });
}

export type TGetCurrentUserRouteResponse = paths['/core/users/me']['get']['responses']['200']['content']['application/json'];
export const getCurrentUserRouteQueryKey = (): QueryKey => ([...["core","users","me"]]);
export function getCurrentUserRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserRouteResponse>> {
  return axiosClientV3.get('/core/users/me', { signal: abortSignal });
}

export type TGetCurrentUserPermissionsRouteResponse = paths['/core/users/me/permissions']['get']['responses']['200']['content']['application/json'];
export const getCurrentUserPermissionsRouteQueryKey = (): QueryKey => ([...["core","users","me","permissions"]]);
export function getCurrentUserPermissionsRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserPermissionsRouteResponse>> {
  return axiosClientV3.get('/core/users/me/permissions', { signal: abortSignal });
}

export type TFindApiKeysRouteResponse = paths['/core/users/me/api-keys']['get']['responses']['200']['content']['application/json'];
export const findApiKeysRouteQueryKey = (): QueryKey => ([...["core","users","me","api-keys"]]);
export function findApiKeysRoute(abortSignal?: AbortSignal): Promise<AxiosResponse<TFindApiKeysRouteResponse>> {
  return axiosClientV3.get('/core/users/me/api-keys', { signal: abortSignal });
}

export type TCreateApiKeyRouteBodyParams = paths['/core/users/me/api-keys']['post']['requestBody']['content']['application/json'];
export type TCreateApiKeyRoutePayload = TApiPayload<undefined, undefined, TCreateApiKeyRouteBodyParams>;
export type TCreateApiKeyRouteResponse = paths['/core/users/me/api-keys']['post']['responses']['200']['content']['application/json'];
export const createApiKeyRouteQueryKey = (): QueryKey => ([...["core","users","me","api-keys"]]);
export function createApiKeyRoute(payload: TCreateApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateApiKeyRouteResponse>> {
  return axiosClientV3.post('/core/users/me/api-keys', payload.bodyParams, { signal: abortSignal });
}

export type TGetApiKeyRoutePath = paths['/core/users/me/api-keys/{apiKeyId}']['get']['parameters']['path'];
export type TGetApiKeyRoutePayload = TApiPayload<TGetApiKeyRoutePath, undefined, undefined>;
export type TGetApiKeyRouteResponse = paths['/core/users/me/api-keys/{apiKeyId}']['get']['responses']['200']['content']['application/json'];
export const getApiKeyRouteQueryKey = (apiKeyId: QueryKey[number]): QueryKey => ([...["core","users","me","api-keys"], apiKeyId]);
export function getApiKeyRoute(payload: TGetApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetApiKeyRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/me/api-keys/{apiKeyId}', payload.path), { signal: abortSignal });
}

export type TUpdateApiKeyRoutePath = paths['/core/users/me/api-keys/{apiKeyId}']['put']['parameters']['path'];
export type TUpdateApiKeyRouteBodyParams = paths['/core/users/me/api-keys/{apiKeyId}']['put']['requestBody']['content']['application/json'];
export type TUpdateApiKeyRoutePayload = TApiPayload<TUpdateApiKeyRoutePath, undefined, TUpdateApiKeyRouteBodyParams>;
export type TUpdateApiKeyRouteResponse = paths['/core/users/me/api-keys/{apiKeyId}']['put']['responses']['200']['content']['application/json'];
export const updateApiKeyRouteQueryKey = (apiKeyId: QueryKey[number]): QueryKey => ([...["core","users","me","api-keys"], apiKeyId]);
export function updateApiKeyRoute(payload: TUpdateApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateApiKeyRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/me/api-keys/{apiKeyId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteApiKeyRoutePath = paths['/core/users/me/api-keys/{apiKeyId}']['delete']['parameters']['path'];
export type TDeleteApiKeyRoutePayload = TApiPayload<TDeleteApiKeyRoutePath, undefined, undefined>;
export type TDeleteApiKeyRouteResponse = paths['/core/users/me/api-keys/{apiKeyId}']['delete']['responses']['200']['content']['application/json'];
export const deleteApiKeyRouteQueryKey = (apiKeyId: QueryKey[number]): QueryKey => ([...["core","users","me","api-keys"], apiKeyId]);
export function deleteApiKeyRoute(payload: TDeleteApiKeyRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteApiKeyRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/users/me/api-keys/{apiKeyId}', payload.path), { signal: abortSignal });
}

export type TGetCurrentUserSecretRoutePath = paths['/core/users/me/secrets/{lookupKey}']['get']['parameters']['path'];
export type TGetCurrentUserSecretRoutePayload = TApiPayload<TGetCurrentUserSecretRoutePath, undefined, undefined>;
export type TGetCurrentUserSecretRouteResponse = paths['/core/users/me/secrets/{lookupKey}']['get']['responses']['200']['content']['application/json'];
export const getCurrentUserSecretRouteQueryKey = (lookupKey: QueryKey[number]): QueryKey => ([...["core","users","me","secrets"], lookupKey]);
export function getCurrentUserSecretRoute(payload: TGetCurrentUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetCurrentUserSecretRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/me/secrets/{lookupKey}', payload.path), { signal: abortSignal });
}

export type TUpdateCurrentUserSecretRoutePath = paths['/core/users/me/secrets/{lookupKey}']['put']['parameters']['path'];
export type TUpdateCurrentUserSecretRouteBodyParams = paths['/core/users/me/secrets/{lookupKey}']['put']['requestBody']['content']['application/json'];
export type TUpdateCurrentUserSecretRoutePayload = TApiPayload<TUpdateCurrentUserSecretRoutePath, undefined, TUpdateCurrentUserSecretRouteBodyParams>;
export type TUpdateCurrentUserSecretRouteResponse = paths['/core/users/me/secrets/{lookupKey}']['put']['responses']['200']['content']['application/json'];
export const updateCurrentUserSecretRouteQueryKey = (lookupKey: QueryKey[number]): QueryKey => ([...["core","users","me","secrets"], lookupKey]);
export function updateCurrentUserSecretRoute(payload: TUpdateCurrentUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateCurrentUserSecretRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/me/secrets/{lookupKey}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteCurrentUserSecretRoutePath = paths['/core/users/me/secrets/{lookupKey}']['delete']['parameters']['path'];
export type TDeleteCurrentUserSecretRoutePayload = TApiPayload<TDeleteCurrentUserSecretRoutePath, undefined, undefined>;
export type TDeleteCurrentUserSecretRouteResponse = paths['/core/users/me/secrets/{lookupKey}']['delete']['responses']['200']['content']['application/json'];
export const deleteCurrentUserSecretRouteQueryKey = (lookupKey: QueryKey[number]): QueryKey => ([...["core","users","me","secrets"], lookupKey]);
export function deleteCurrentUserSecretRoute(payload: TDeleteCurrentUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteCurrentUserSecretRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/users/me/secrets/{lookupKey}', payload.path), { signal: abortSignal });
}

export type TGetUserSettingsPath = paths['/core/users/me/settings/{storageKey}']['get']['parameters']['path'];
export type TGetUserSettingsPayload = TApiPayload<TGetUserSettingsPath, undefined, undefined>;
export type TGetUserSettingsResponse = paths['/core/users/me/settings/{storageKey}']['get']['responses']['200']['content']['application/json'];
export const getUserSettingsQueryKey = (storageKey: QueryKey[number]): QueryKey => ([...["core","users","me","settings"], storageKey]);
export function getUserSettings(payload: TGetUserSettingsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserSettingsResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/me/settings/{storageKey}', payload.path), { signal: abortSignal });
}

export type TSetUserSettingsPath = paths['/core/users/me/settings/{storageKey}']['put']['parameters']['path'];
export type TSetUserSettingsBodyParams = paths['/core/users/me/settings/{storageKey}']['put']['requestBody']['content']['application/json'];
export type TSetUserSettingsPayload = TApiPayload<TSetUserSettingsPath, undefined, TSetUserSettingsBodyParams>;
export type TSetUserSettingsResponse = paths['/core/users/me/settings/{storageKey}']['put']['responses']['200']['content']['application/json'];
export const setUserSettingsQueryKey = (storageKey: QueryKey[number]): QueryKey => ([...["core","users","me","settings"], storageKey]);
export function setUserSettings(payload: TSetUserSettingsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TSetUserSettingsResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/me/settings/{storageKey}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetUsersRouteQuery = paths['/core/users']['get']['parameters']['query'];
export type TGetUsersRoutePayload = TApiPayload<undefined, TGetUsersRouteQuery, undefined>;
export type TGetUsersRouteResponse = paths['/core/users']['get']['responses']['200']['content']['application/json'];
export const getUsersRouteQueryKey = (query?: TGetUsersRouteQuery): QueryKey => ([...["core","users"], ...(query ? [query] : [])]);
export function getUsersRoute(payload: TGetUsersRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUsersRouteResponse>> {
  return axiosClientV3.get('/core/users', { signal: abortSignal, params: payload.query });
}

export type TCreateUserRouteBodyParams = paths['/core/users']['post']['requestBody']['content']['application/json'];
export type TCreateUserRoutePayload = TApiPayload<undefined, undefined, TCreateUserRouteBodyParams>;
export type TCreateUserRouteResponse = paths['/core/users']['post']['responses']['200']['content']['application/json'];
export const createUserRouteQueryKey = (): QueryKey => ([...["core","users"]]);
export function createUserRoute(payload: TCreateUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateUserRouteResponse>> {
  return axiosClientV3.post('/core/users', payload.bodyParams, { signal: abortSignal });
}

export type TGetUserRoutePath = paths['/core/users/{userId}']['get']['parameters']['path'];
export type TGetUserRoutePayload = TApiPayload<TGetUserRoutePath, undefined, undefined>;
export type TGetUserRouteResponse = paths['/core/users/{userId}']['get']['responses']['200']['content']['application/json'];
export const getUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users"], userId]);
export function getUserRoute(payload: TGetUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/{userId}', payload.path), { signal: abortSignal });
}

export type TUpdateUserRoutePath = paths['/core/users/{userId}']['put']['parameters']['path'];
export type TUpdateUserRouteBodyParams = paths['/core/users/{userId}']['put']['requestBody']['content']['application/json'];
export type TUpdateUserRoutePayload = TApiPayload<TUpdateUserRoutePath, undefined, TUpdateUserRouteBodyParams>;
export type TUpdateUserRouteResponse = paths['/core/users/{userId}']['put']['responses']['200']['content']['application/json'];
export const updateUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users"], userId]);
export function updateUserRoute(payload: TUpdateUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/{userId}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteUserRoutePath = paths['/core/users/{userId}']['delete']['parameters']['path'];
export type TDeleteUserRoutePayload = TApiPayload<TDeleteUserRoutePath, undefined, undefined>;
export type TDeleteUserRouteResponse = paths['/core/users/{userId}']['delete']['responses']['200']['content']['application/json'];
export const deleteUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users"], userId]);
export function deleteUserRoute(payload: TDeleteUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteUserRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/users/{userId}', payload.path), { signal: abortSignal });
}

export type TRestoreUserRoutePath = paths['/core/users/{userId}/restored']['put']['parameters']['path'];
export type TRestoreUserRoutePayload = TApiPayload<TRestoreUserRoutePath, undefined, undefined>;
export type TRestoreUserRouteResponse = paths['/core/users/{userId}/restored']['put']['responses']['200']['content']['application/json'];
export const restoreUserRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","restored"], userId]);
export function restoreUserRoute(payload: TRestoreUserRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TRestoreUserRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/{userId}/restored', payload.path), { signal: abortSignal });
}

export type TGetUserPermissionsRoutePath = paths['/core/users/{userId}/permissions']['get']['parameters']['path'];
export type TGetUserPermissionsRoutePayload = TApiPayload<TGetUserPermissionsRoutePath, undefined, undefined>;
export type TGetUserPermissionsRouteResponse = paths['/core/users/{userId}/permissions']['get']['responses']['200']['content']['application/json'];
export const getUserPermissionsRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","permissions"], userId]);
export function getUserPermissionsRoute(payload: TGetUserPermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserPermissionsRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/{userId}/permissions', payload.path), { signal: abortSignal });
}

export type TUpdateUserPermissionsRoutePath = paths['/core/users/{userId}/permissions']['put']['parameters']['path'];
export type TUpdateUserPermissionsRouteBodyParams = paths['/core/users/{userId}/permissions']['put']['requestBody']['content']['application/json'];
export type TUpdateUserPermissionsRoutePayload = TApiPayload<TUpdateUserPermissionsRoutePath, undefined, TUpdateUserPermissionsRouteBodyParams>;
export type TUpdateUserPermissionsRouteResponse = paths['/core/users/{userId}/permissions']['put']['responses']['200']['content']['application/json'];
export const updateUserPermissionsRouteQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","permissions"], userId]);
export function updateUserPermissionsRoute(payload: TUpdateUserPermissionsRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserPermissionsRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/{userId}/permissions', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TGetUserSecretRoutePath = paths['/core/users/{userId}/secrets/{lookupKey}']['get']['parameters']['path'];
export type TGetUserSecretRoutePayload = TApiPayload<TGetUserSecretRoutePath, undefined, undefined>;
export type TGetUserSecretRouteResponse = paths['/core/users/{userId}/secrets/{lookupKey}']['get']['responses']['200']['content']['application/json'];
export const getUserSecretRouteQueryKey = (userId: QueryKey[number], lookupKey: QueryKey[number]): QueryKey => ([...["core","users","secrets"], userId, lookupKey]);
export function getUserSecretRoute(payload: TGetUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserSecretRouteResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/{userId}/secrets/{lookupKey}', payload.path), { signal: abortSignal });
}

export type TUpdateUserSecretRoutePath = paths['/core/users/{userId}/secrets/{lookupKey}']['put']['parameters']['path'];
export type TUpdateUserSecretRouteBodyParams = paths['/core/users/{userId}/secrets/{lookupKey}']['put']['requestBody']['content']['application/json'];
export type TUpdateUserSecretRoutePayload = TApiPayload<TUpdateUserSecretRoutePath, undefined, TUpdateUserSecretRouteBodyParams>;
export type TUpdateUserSecretRouteResponse = paths['/core/users/{userId}/secrets/{lookupKey}']['put']['responses']['200']['content']['application/json'];
export const updateUserSecretRouteQueryKey = (userId: QueryKey[number], lookupKey: QueryKey[number]): QueryKey => ([...["core","users","secrets"], userId, lookupKey]);
export function updateUserSecretRoute(payload: TUpdateUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TUpdateUserSecretRouteResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/{userId}/secrets/{lookupKey}', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteUserSecretRoutePath = paths['/core/users/{userId}/secrets/{lookupKey}']['delete']['parameters']['path'];
export type TDeleteUserSecretRoutePayload = TApiPayload<TDeleteUserSecretRoutePath, undefined, undefined>;
export type TDeleteUserSecretRouteResponse = paths['/core/users/{userId}/secrets/{lookupKey}']['delete']['responses']['200']['content']['application/json'];
export const deleteUserSecretRouteQueryKey = (userId: QueryKey[number], lookupKey: QueryKey[number]): QueryKey => ([...["core","users","secrets"], userId, lookupKey]);
export function deleteUserSecretRoute(payload: TDeleteUserSecretRoutePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteUserSecretRouteResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/users/{userId}/secrets/{lookupKey}', payload.path), { signal: abortSignal });
}

export type TGetUserSubscriptionsTreePath = paths['/core/users/{userId}/subscriptions']['get']['parameters']['path'];
export type TGetUserSubscriptionsTreePayload = TApiPayload<TGetUserSubscriptionsTreePath, undefined, undefined>;
export type TGetUserSubscriptionsTreeResponse = paths['/core/users/{userId}/subscriptions']['get']['responses']['200']['content']['application/json'];
export const getUserSubscriptionsTreeQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId]);
export function getUserSubscriptionsTree(payload: TGetUserSubscriptionsTreePayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUserSubscriptionsTreeResponse>> {
  return axiosClientV3.get(interpolateUrl('/core/users/{userId}/subscriptions', payload.path), { signal: abortSignal });
}

export type TCreateUserSubscriptionsPath = paths['/core/users/{userId}/subscriptions']['post']['parameters']['path'];
export type TCreateUserSubscriptionsBodyParams = paths['/core/users/{userId}/subscriptions']['post']['requestBody']['content']['application/json'];
export type TCreateUserSubscriptionsPayload = TApiPayload<TCreateUserSubscriptionsPath, undefined, TCreateUserSubscriptionsBodyParams>;
export type TCreateUserSubscriptionsResponse = paths['/core/users/{userId}/subscriptions']['post']['responses']['201']['content']['application/json'];
export const createUserSubscriptionsQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId]);
export function createUserSubscriptions(payload: TCreateUserSubscriptionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TCreateUserSubscriptionsResponse>> {
  return axiosClientV3.post(interpolateUrl('/core/users/{userId}/subscriptions', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TReplaceUserSubscriptionsPath = paths['/core/users/{userId}/subscriptions']['put']['parameters']['path'];
export type TReplaceUserSubscriptionsBodyParams = paths['/core/users/{userId}/subscriptions']['put']['requestBody']['content']['application/json'];
export type TReplaceUserSubscriptionsPayload = TApiPayload<TReplaceUserSubscriptionsPath, undefined, TReplaceUserSubscriptionsBodyParams>;
export type TReplaceUserSubscriptionsResponse = paths['/core/users/{userId}/subscriptions']['put']['responses']['200']['content']['application/json'];
export const replaceUserSubscriptionsQueryKey = (userId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId]);
export function replaceUserSubscriptions(payload: TReplaceUserSubscriptionsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TReplaceUserSubscriptionsResponse>> {
  return axiosClientV3.put(interpolateUrl('/core/users/{userId}/subscriptions', payload.path), payload.bodyParams, { signal: abortSignal });
}

export type TDeleteUserSubscriptionPath = paths['/core/users/{userId}/subscriptions/{subscriptionId}']['delete']['parameters']['path'];
export type TDeleteUserSubscriptionPayload = TApiPayload<TDeleteUserSubscriptionPath, undefined, undefined>;
export type TDeleteUserSubscriptionResponse = paths['/core/users/{userId}/subscriptions/{subscriptionId}']['delete']['responses']['204']['content'];
export const deleteUserSubscriptionQueryKey = (userId: QueryKey[number], subscriptionId: QueryKey[number]): QueryKey => ([...["core","users","subscriptions"], userId, subscriptionId]);
export function deleteUserSubscription(payload: TDeleteUserSubscriptionPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TDeleteUserSubscriptionResponse>> {
  return axiosClientV3.delete(interpolateUrl('/core/users/{userId}/subscriptions/{subscriptionId}', payload.path), { signal: abortSignal });
}

export type TGetInAppNotificationsQuery = paths['/core/users/me/notifications']['get']['parameters']['query'];
export type TGetInAppNotificationsPayload = TApiPayload<undefined, TGetInAppNotificationsQuery, undefined>;
export type TGetInAppNotificationsResponse = paths['/core/users/me/notifications']['get']['responses']['200']['content']['application/json'];
export const getInAppNotificationsQueryKey = (query?: TGetInAppNotificationsQuery): QueryKey => ([...["core","users","me","notifications"], ...(query ? [query] : [])]);
export function getInAppNotifications(payload: TGetInAppNotificationsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetInAppNotificationsResponse>> {
  return axiosClientV3.get('/core/users/me/notifications', { signal: abortSignal, params: payload.query });
}

export type TMarkReadBodyParams = paths['/core/users/me/notifications/mark-read']['put']['requestBody']['content']['application/json'];
export type TMarkReadPayload = TApiPayload<undefined, undefined, TMarkReadBodyParams>;
export type TMarkReadResponse = paths['/core/users/me/notifications/mark-read']['put']['responses']['204']['content'];
export const markReadQueryKey = (): QueryKey => ([...["core","users","me","notifications","mark-read"]]);
export function markRead(payload: TMarkReadPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TMarkReadResponse>> {
  return axiosClientV3.put('/core/users/me/notifications/mark-read', payload.bodyParams, { signal: abortSignal });
}

export type TMarkAllReadResponse = paths['/core/users/me/notifications/mark-read/all']['put']['responses']['204']['content'];
export const markAllReadQueryKey = (): QueryKey => ([...["core","users","me","notifications","mark-read","all"]]);
export function markAllRead(abortSignal?: AbortSignal): Promise<AxiosResponse<TMarkAllReadResponse>> {
  return axiosClientV3.put('/core/users/me/notifications/mark-read/all', { signal: abortSignal });
}

export type TGetUnseenCountResponse = paths['/core/users/me/notifications/unseen-count']['get']['responses']['200']['content']['application/json'];
export const getUnseenCountQueryKey = (): QueryKey => ([...["core","users","me","notifications","unseen-count"]]);
export function getUnseenCount(abortSignal?: AbortSignal): Promise<AxiosResponse<TGetUnseenCountResponse>> {
  return axiosClientV3.get('/core/users/me/notifications/unseen-count', { signal: abortSignal });
}

