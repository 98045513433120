export default {
  description: 'Erstellen und verwalten Sie Ihr Preset',
  presetLabel: 'Ausgewähltes Preset',
  nameLabel: 'Presetname',
  saveAsDefault: 'Preset als Standard speichern',
  publishGlobaly: 'Für alle Benutzer veröffentlichen',
  publishGlobalyTooltip: 'Um diese Einstellung zu ändern, kopieren Sie ein vorhandenes Preset oder erstellen Sie ein neues Preset',
  defaultName: 'Neues Preset',
  selectPreset: 'Ein Preset auswählen',
  reset: 'Zurücksetzen',
};
