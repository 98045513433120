export default {
  setpoint: 'Sollwert in hhh:mm:ss',
  stabilizationPeriodSeconds: 'Stabilizierungszeitraum in hhh:mm:ss',
  timerSensors: 'Timer-Sensoren',
  overruleResult: 'Ergebnis korrigieren',
  overruleReason: 'Grund für die Korrektur',
  overruleResultLabel: 'Korrigiertes Ergebnis',
  mainSensor: {
    placeholder: 'Hauptsignal auswählen',
  },
  durationSensor: {
    placeholder: 'Dauersignal auswählen',
  },
};
