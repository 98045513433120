import {
  OPERATION_DASHBOARDS_PATH,
  JOBS_LIST_PATH,
  LIVE_KPIS,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';
import {
  ResponseUtils,
} from '@/utils/ResponseUtils';

export const OperationDashboardService = {
  getFurnaces({
    customerId, locationId, groupId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${OPERATION_DASHBOARDS_PATH}`, {
          params: {
            customerId,
            locationId,
            groupId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFurnaceDetail(equipment_id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${OPERATION_DASHBOARDS_PATH}/details/${equipment_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getGanttData(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${OPERATION_DASHBOARDS_PATH}/${JOBS_LIST_PATH}/`, {
          params: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFurnaceKpis(equipment_id) {
    ResponseUtils.validateId(equipment_id, 'equipment_id');
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${OPERATION_DASHBOARDS_PATH}/${equipment_id}/${LIVE_KPIS}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
