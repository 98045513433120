/** composable for using @/components/date-range-picker/date-range-picker.vue along with saving result in URL */

import {
  computed, ComputedRef, shallowRef, watch,
} from 'vue';
import {
  useRoute,
} from 'vue-router';
import dayjs from 'dayjs';
import {
  CUSTOM_RANGE_ID, getDefaultRangeList, IRange,
} from '@/components/date-range-picker/date-range-picker';
import {
  useAppStore,
} from '@/store/pinia/app';

export const RANGE_QUERY_KEY = 'range';

export function useQueryDateRangePicker({
  defaultRangeList,
  defaultId,
}: {
  defaultRangeList?: ComputedRef<IRange[]> | IRange[],
  defaultId?: string | number,
} = {}) {
  const appStore = useAppStore();
  const route = useRoute();
  const rangeList = computed(() => (defaultRangeList as ComputedRef<IRange[]>)?.value
    || defaultRangeList
    || getDefaultRangeList(appStore.locale));
  const defaultItem = rangeList.value.find((_item) => _item.id === defaultId) || rangeList.value[0];
  const activeItemId = shallowRef<string | number>(defaultItem.id);
  const activeRange = shallowRef<IRange>(defaultItem);

  if (route.query[RANGE_QUERY_KEY]) {
    const queryRange = JSON.parse(route.query[RANGE_QUERY_KEY] as string) as IRange;
    const queryActiveItemId = queryRange.isCustom
      ? CUSTOM_RANGE_ID
      : queryRange.id;
    if (queryActiveItemId === CUSTOM_RANGE_ID) {
      activeItemId.value = queryActiveItemId;
      activeRange.value.from = dayjs(queryRange.from).toDate();
      activeRange.value.to = dayjs(queryRange.to).toDate();
    } else {
      const foundItem = rangeList.value.find((_range) => _range.id === queryActiveItemId);
      if (foundItem) {
        activeItemId.value = queryActiveItemId;
        activeRange.value = foundItem;
      }
    }
  }

  watch(() => activeRange.value, (newValue) => {
    const query = new URLSearchParams(route.fullPath.split('?')[1] || '');
    query.set(RANGE_QUERY_KEY, JSON.stringify(newValue));
    const newPath = `${route.fullPath.split('?')[0]}?${query.toString()}`;
    // we do this in order NOT to trigger router hooks
    window.history.replaceState(window.history.state, '', newPath);
    // we do it in order to update reactive object
    route.query.range = query.get(RANGE_QUERY_KEY);
  });

  return {
    rangeList,
    activeItemId,
    activeRange,
  };
}
