import {
  NPM_API_PATH,
  NPM_FURNACE_PATH,
} from '@/constants/apiPaths';
import {
  portalApiClient,
} from '@/services/AxiosService';
import {
  createOrUpdateRecipe,
  deleteRecipe,
  getRecipe,
  getRecipesList,
  getRecipeTemplate,
} from '@/api/recipe-npm';

export const RecipeBuilderService = {
  getRecipesList({
    customerCode, location, systemId,
  }) {
    return getRecipesList({
      path: {
        customer_code: customerCode,
        plant_name: location,
        system_id: systemId,
      },
    }).then((response) => response.data);
  },
  getRecipeDetails({
    customerCode, location, systemId, recipeCode,
  }) {
    return getRecipe({
      path: {
        customer_code: customerCode,
        plant_name: location,
        system_id: systemId,
        recipe_code: recipeCode,
      },
    })
      .then((response) => response.data);
  },
  getRecipeTemplate({
    customerCode, location, systemId,
  }) {
    return getRecipeTemplate({
      path: {
        customer_code: customerCode,
        plant_name: location,
        system_id: systemId,
      },
    })
      .then((response) => response.data);
  },
  getRecipeConfig({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(
          `${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/recipe-config`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteRecipe({
    customerCode, location, systemId, recipeCode,
  }) {
    return deleteRecipe({
      path: {
        customer_code: customerCode,
        plant_name: location,
        system_id: systemId,
        recipe_code: recipeCode,
      },
    }).then((response) => response.data);
  },
  createOrUpdateRecipe({
    customerCode, location, systemId, payload,
  }) {
    return createOrUpdateRecipe({
      path: {
        customer_code: customerCode,
        plant_name: location,
        system_id: systemId,
        recipe_code: payload.recipe_code,
      },
      bodyParams: payload.recipe,
    })
      .then((response) => response.data);
  },
  updateRecipeChanges({
    customerCode, location, systemId, payload,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .put(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/recipe`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getRecipesBuilderLocation(customerName) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_API_PATH}/plants/${customerName}`)
        .then((response) => {
          response.data.children = response.data.plant_names.map((item) => {
            return {
              id: item,
              name: item,
              objectType: 'FL',
            };
          });
          response.data.name = customerName;
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getRecipesBuilderEquipment(customerName, locationName) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_API_PATH}/system-identifiers/${customerName}/${locationName}`)
        .then((response) => {
          response.data.children = response.data.system_identifiers.map((item) => {
            return {
              id: item.id,
              name: item.name,
              objectType: 'EQU',
            };
          });
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
