<template>
  <v-autocomplete
    v-model="selectedItem"
    :items="sortData(items)"
    :item-title="itemToStringFnc"
    :item-value="itemValue"
    :disabled="disabled"
    :label="label"
    :clearable="true"
    :custom-filter="customFilter"
  >
    <template
      v-if="itemComponent"
      #item="{ props, item }"
    >
      <slot
        name="item"
        :props="props"
        :item="item"
      />
    </template>
    <template
      v-if="selection"
      #selection="{ item }"
    >
      <slot
        name="selection"
        :item="item"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import escapeRegExp from 'lodash/escapeRegExp';

export default {
  name: 'SearchableDropdown',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    itemComponent: {
      type: Boolean,
      default: false,
    },
    itemSortFnc: {
      type: Function,
      default: null,
    },
    itemValue: {
      type: [
        String,
        Function,
      ],
      default() {
        return 'id';
      },
    },
    itemToStringFnc: {
      type: Function,
      default: (item) => item,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    selection: {
      type: Boolean,
      default: false,
    },
    selectedItemId: {
      type: [
        String,
        Number,
        Array,
      ],
      default: null,
    },
    sortOrder: {
      type: String,
      validator(value) {
        return [
          'asc',
          'desc',
          '',
        ].includes(value);
      },
      default: '',
    },
  },
  emits: [
    'update:selectedItemId',
  ],
  computed: {
    selectedItem: {
      get() {
        return this.selectedItemId;
      },
      set(value) {
        this.$emit('update:selectedItemId', value);
      },
    },
  },
  methods: {
    customFilter(item, queryText) {
      const itemLowerCase = item.toLowerCase();
      const query = queryText.toLowerCase();
      if (new RegExp(`^.*${query.split('*').map((str) => escapeRegExp(str)).join('.*')}.*$`).test(itemLowerCase)) {
        return 1;
      } return -1;
    },
    sortData(items) {
      const itemsList = [
        ...items,
      ];
      if (typeof this.itemSortFnc === 'function') {
        return this.itemSortFnc(itemsList);
      } if (this.sortOrder === 'asc') {
        return itemsList.sort();
      }
      return itemsList.sort().reverse();
    },
  },
};
</script>
