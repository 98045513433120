import {
  MAINTENANCE_CALENDAR_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const MaintenanceCalendarService = {
  createTask({
    payload,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${MAINTENANCE_CALENDAR_PATH}/tasks`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  createSeries({
    displayName,
    description,
    maintenanceJobId,
    recurrence,
    priority,
    color,
    validFromUtc,
    endAfter,
    assetId,
    ownerId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${MAINTENANCE_CALENDAR_PATH}/series`, {
          displayName,
          description,
          maintenanceJobId,
          recurrence,
          priority,
          color,
          validFromUtc,
          endAfter,
          assetId,
          ownerId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getSeriesDetails(seriesId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_CALENDAR_PATH}/series/${seriesId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  updateSeries({
    seriesId, payload,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${MAINTENANCE_CALENDAR_PATH}/series/${seriesId}`, {
          payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  deleteSeries({
    seriesId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${MAINTENANCE_CALENDAR_PATH}/series/${seriesId}`, {
          params: {},
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  createJobs({
    payload,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${MAINTENANCE_CALENDAR_PATH}/jobs`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getMaintenanceJob(maintenanceJobId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_CALENDAR_PATH}/jobs/${maintenanceJobId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  updateMaintenanceJob({
    maintenanceJobId, payload,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${MAINTENANCE_CALENDAR_PATH}/jobs/${maintenanceJobId}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  deleteMaintenanceJob({
    maintenanceJobId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${MAINTENANCE_CALENDAR_PATH}/jobs/${maintenanceJobId}`, {
          params: {},
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getOccurrenceResults(occurrenceId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${MAINTENANCE_CALENDAR_PATH}/occurrences/${occurrenceId}/results`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  updateOccurrenceResults({
    occurrenceId, results,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(
          `${MAINTENANCE_CALENDAR_PATH}/occurrences/${occurrenceId}/results`,
          results.map(({
            taskId, result, reason,
          }) => ({
            taskId,
            result,
            reason,
          })),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
