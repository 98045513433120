import {
  ACTION_PATH,
  EQUIPMENTS_PATH,
  JOB_FEATURE_DESCRIPTIONS_PATH,
  OBSERVATION_TYPES_PATH,
  OBSERVATION_TYPES_ROOT_CAUSE_PATH,
  ROOT_CAUSE_PATH,
} from '@/constants/apiPaths';
import {
  portalApi,
} from '@/services/AxiosService';

export const AnomalyDetectionService = {
  getPossibleRootCauses() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${EQUIPMENTS_PATH}/root-causes`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getObservations({
    equipmentId, rootCauseId, from, to,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${EQUIPMENTS_PATH}/${equipmentId}/observations`, {
          params: {
            equipmentId,
            rootCauseId,
            from,
            to,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobFeatureDescriptions(equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `/${EQUIPMENTS_PATH}/${equipmentId}/${JOB_FEATURE_DESCRIPTIONS_PATH}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getObservationsTypes() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${OBSERVATION_TYPES_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getRootCauses({
    jobFeatureDescriptionId, observationType,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${EQUIPMENTS_PATH}/root-causes`, {
          params: {
            jobFeatureDescriptionId,
            observationType,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateRootCause({
    rootCauseId, name, description,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`/${ROOT_CAUSE_PATH}/${rootCauseId}`, {
          name,
          description,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addRootCause({
    name, description,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`/${ROOT_CAUSE_PATH}`, {
          name,
          description,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteRootCause(rootCauseId) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`/${ROOT_CAUSE_PATH}/${rootCauseId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteObservationTypeRootCause(observationTypeRootCauseId) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(
          `/${OBSERVATION_TYPES_ROOT_CAUSE_PATH}/${observationTypeRootCauseId}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getObservationTypeRootCause({
    equipmentId, rootCauseId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${OBSERVATION_TYPES_ROOT_CAUSE_PATH}`, {
          params: {
            equipmentId,
            rootCauseId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createObservationTypeRootCause({
    jobFeatureDescriptionId,
    observationType,
    rootCauseId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`/${OBSERVATION_TYPES_ROOT_CAUSE_PATH}`, {
          jobFeatureDescriptionId,
          observationType,
          rootCauseId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateObservationTypeRootCause(observationTypeRootCauseId, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(
          `/${OBSERVATION_TYPES_ROOT_CAUSE_PATH}/${observationTypeRootCauseId}`,
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getJobFeatureDescriptionId(jobFeatureId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/job-feature/${jobFeatureId}/job-feature-description`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getRootCauseConnections({
    rootCauseId, equipmentId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${ROOT_CAUSE_PATH}/${rootCauseId}/${OBSERVATION_TYPES_ROOT_CAUSE_PATH}`, {
          params: {
            equipmentId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getActionList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${ACTION_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getActionsDetails({
    actionId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`/${ACTION_PATH}/${actionId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateAction({
    actionId, rootCauseIds, name, description,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`/${ACTION_PATH}/${actionId}`, {
          rootCauseIds,
          name,
          description,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteAction(actionId) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`/${ACTION_PATH}/${actionId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createAction({
    rootCauseIds, name, description,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`/${ACTION_PATH}`, {
          rootCauseIds,
          name,
          description,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
