<template>
  <datepicker
    v-model="datepickerRange"
    :enable-time-picker="false"
    :month-change-on-scroll="false"
    :locale="locale"
    no-today
    auto-apply
    range
    multi-calendars
    multi-calendars-solo
    inline
    hide-offset-dates
  />
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';

export default {
  name: 'DateRangePickerAbsolute',
  components: {
    Datepicker,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      datepickerRange: [],
    };
  },
  watch: {
    datepickerRange(date) {
      this.$emit('update:modelValue', {
        from: date[0],
        to: date[1],
      });
    },
  },
  mounted() {
    this.datepickerRange = [
      this.modelValue.from,
      this.modelValue.to,
    ];
  },
};
</script>

<style>
.dp__input_wrap {
  box-sizing: inherit;
}
.dp__range_between {
  background: #fcede8 !important;
  border-radius: 0;
  border-top: 1px solid #fcede8 !important;
  border-bottom: 1px solid #fcede8 !important;
}
.dp__flex_display {
  justify-content: center;
  height: 376px;
}
.dp__menu.dp__relative.dp__theme_light {
  border: none;
}

.dp__menu .dp__relative {
  border: none !important;
}
.dp__active_date,
.dp__range_start,
.dp__range_end,
.dp__date_hover:hover,
.dp__date_hover_start:hover,
.dp__date_hover_end:hover,
.dp__month_year_select:hover {
  background: #fcede8 !important;
  color: #000 !important;
}
.dp__overlay_cell_active {
  color: #000;
  background: #fcede8;
}
</style>
