export default {
  name: 'Signalname',
  description: 'Beschreibung',
  color: 'Farbe',
  unit: 'Einheit',
  meaning: 'Bedeutung',
  meaningNumber: 'Instanznummer der Bedeutung',
  numberOfSignificantDigits: 'Nachkommastellen',
  hidden: 'versteckt',
  visible: 'sichtbar',
};
