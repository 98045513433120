export const create = (apiClient) => {
  const {
    fetchGraph, fetchGraphJSON,
  } = apiClient;

  const TYPE_OPTION_ITEM = `{
    id
    value
  }`;

  const TYPE_DIGITAL_OUTPUT = `{
    name
    value
  }`;

  const TYPE_ANALYSIS = `{
    c
    si
    mn
    cr
    ni
    v
    al
    cu
    p
    mo
    s
    b
    ti
  }`;

  const TYPE_MATERIAL = `{
    carbonContent,
    alloyFactor,
    legFactorN,
    carbideLimit,
    temperature,
    specialNitrides,
    analysis ${TYPE_ANALYSIS},
    co,
    h2,
    quenchingIntensity,
    requiredHardness,
    representativeDiameter,
    grainSize,
    endTemperature,
    targetSurfC,
    customerMaterialId,
  }`;

  const TYPE_SP_ENTRIES = `{
    furnaceSwitchEntry,
    recipeDescription2,
    recipeDescription3,
    recipeDescription4,
    gasEntryValues,
  }`;

  const TYPE_ACT_RECIPE_POSITION_HEADER = `{
    positionNo
    chamberOption ${TYPE_OPTION_ITEM}
    chamberOperationOption ${TYPE_OPTION_ITEM}
    description
    estimatedDurationInMin
    afterOption ${TYPE_OPTION_ITEM}
    afterTimeInMin
    actualDurationInMin
  }`;

  const TYPE_RECIPE_ITEM_POSITION_HEADER = `{
    positionNo
    chamberOption ${TYPE_OPTION_ITEM}
    chamberOperationOption ${TYPE_OPTION_ITEM}
    description
    estimatedDurationInMin
    afterOption ${TYPE_OPTION_ITEM}
    afterTimeInMin
  }`;

  const TYPE_SET_POINT_ITEM = `{
    userComponentId
    valueType
    meaning
    relevant
    name
    control ${TYPE_OPTION_ITEM}
    value
    unit
    toleranceMax
    toleranceMin
    alarmStartTimeInMin
    alarmToleranceTimeInMin
    endControl ${TYPE_OPTION_ITEM}
    endValue
  }`;

  const TYPE_ACT_SET_POINT = `{
    userComponentId
    valueType
    meaning
    name
    control ${TYPE_OPTION_ITEM}
    actualValue
    value
    unit
    toleranceMax
    toleranceMin
    alarmStartTimeInMin
    alarmToleranceTimeInMin
    endControl ${TYPE_OPTION_ITEM}
    endValue
  }`;

  const TYPE_RECIPE_ITEM_POSITION = `{
    header ${TYPE_RECIPE_ITEM_POSITION_HEADER}
    setpoints ${TYPE_SET_POINT_ITEM}
    digitalOutputList ${TYPE_DIGITAL_OUTPUT}
    digitalOutputs
    endAfter
  }`;

  const TYPE_ACT_RECIPE_POSITION = `{
    header ${TYPE_ACT_RECIPE_POSITION_HEADER}
    setpoints ${TYPE_ACT_SET_POINT}
    digitalOutputList ${TYPE_DIGITAL_OUTPUT}
    digitalOutputs
    endAfter
  }`;

  const TYPE_RECIPE_ITEM_HEADER = `{
    id
    name
    description
    comment
    version
    processOption ${TYPE_OPTION_ITEM}
    processType
    processDepth
    processDepthAt
    correction
    diff
  }`;

  const TYPE_ACT_RECIPE_HEADER = `{
    id
    name
    recipePrefix
    description
    comment
    version
    processOption ${TYPE_OPTION_ITEM}
    processType
    processDepth
    processDepthAt
    correction
    diff
    loadName
    loadIdentifier
    actualStep
    startingTime
    user ${TYPE_OPTION_ITEM}
  }`;

  const TYPE_ACT_RECIPE_VERSION = `{
    header ${TYPE_ACT_RECIPE_HEADER}
    positions ${TYPE_ACT_RECIPE_POSITION}
    spEntries ${TYPE_SP_ENTRIES}
    material ${TYPE_MATERIAL}
    correctionProfile
  }`;

  const TYPE_RECIPE_ITEM_VERSION = `{
    header ${TYPE_RECIPE_ITEM_HEADER}
    positions ${TYPE_RECIPE_ITEM_POSITION}
    spEntries ${TYPE_SP_ENTRIES}
    material ${TYPE_MATERIAL}
    correctionProfile
  }`;

  const TYPE_RECIPE_METADATA_OPERATION = `{
    id
    name
  }`;

  const TYPE_RECIPE_METADATA_CUSTOMER_MATERIAL = `{
    id
    name
    description
    elementValues ${TYPE_ANALYSIS}
    carbideLimit
  }`;

  const TYPE_METADATA_SET_POINT = `{
    name
    userComponentId
    meaning
    relevant
    valueType
    unit
  }`;

  const TYPE_CHAMBER_OPTION_ITEM = `{
    chamberId
    name
    operationOptions ${TYPE_OPTION_ITEM}
    setPoints ${TYPE_METADATA_SET_POINT}
  }`;

  const TYPE_RECIPE_METADATA = `{
    afterOptions ${TYPE_OPTION_ITEM}
    controlOptions ${TYPE_OPTION_ITEM}
    endControlOptions ${TYPE_OPTION_ITEM}
    chamberOptions ${TYPE_CHAMBER_OPTION_ITEM}
    operationProcessList ${TYPE_RECIPE_METADATA_OPERATION}
    materialList ${TYPE_RECIPE_METADATA_CUSTOMER_MATERIAL}
  }`;

  const TYPE_CLONE_RECIPE_AND_SAVE_AS_RESULT = `{
    recipeVersions ${TYPE_RECIPE_ITEM_VERSION}
    isSaved
    comment
  }`;

  const TYPE_SAVE_RECIPE_RESULT = `{
    recipeVersion ${TYPE_RECIPE_ITEM_VERSION}
    isSaved
    comment
  }`;
  const INPUT_ANALYSIS = (analysis) => `{
    c: ${analysis.c || null}
    si: ${analysis.si || null}
    mn: ${analysis.mn || null}
    cr: ${analysis.cr || null}
    ni: ${analysis.ni || null}
    v: ${analysis.v || null}
    al: ${analysis.al || null}
    cu: ${analysis.cu || null}
    p: ${analysis.p || null}
    mo: ${analysis.mo || null}
    s: ${analysis.s || null}
    b: ${analysis.b || null}
    ti: ${analysis.ti || null}
  }`;

  const INPUT_MATERIAL = (material) => `{
    carbonContent: ${material.carbonContent},
    alloyFactor: ${material.alloyFactor},
    legFactorN: ${material.legFactorN},
    carbideLimit: ${material.carbideLimit},
    temperature: ${material.temperature},
    specialNitrides: ${material.specialNitrides},
    analysis: ${INPUT_ANALYSIS(material.analysis)}
    co: ${material.co},
    h2: ${material.h2},
    quenchingIntensity: ${material.quenchingIntensity},
    representativeDiameter: ${material.representativeDiameter},
    requiredHardness: ${material.requiredHardness},
    grainSize: ${material.grainSize},
    endTemperature: ${material.endTemperature},
    targetSurfC: ${material.targetSurfC},
    customerMaterialId: ${material.customerMaterialId},
    partId: ${material?.partId || 0},
  }`;

  const INPUT_SP_ENTRIES = (spEntries) => `{
    furnaceSwitchEntry: "${spEntries.furnaceSwitchEntry}",
    recipeDescription2: "${spEntries.recipeDescription2}",
    recipeDescription3: "${spEntries.recipeDescription3}",
    recipeDescription4: "${spEntries.recipeDescription4}",
    gasEntryValues: "${spEntries.gasEntryValues}",
  }`;

  const INPUT_POSITION_HEADER = (header) => `{
    positionNo: ${header.positionNo}
    afterTimeOptionNo: ${header.afterOption?.id ?? header.afterOption}
    afterTimeInMin: ${header.afterTimeInMin}
    estimatedTimeInMin: ${header.estimatedDurationInMin}
    chamberId: ${header.chamberOption?.id ?? header.chamberOption}
    description: "${header.description}"
    operationId: ${
  header.chamberOperationOption?.id ?? header.chamberOperationOption
}
    endOr: ${header.endOr || null /* temporary */}
    valueDepth: ${header.valueDepth || null /* temporary */}
  }`;

  const INPUT_SET_POINT = (setPoint) => `{
    meaning: ${setPoint.meaning},
    userComponentId: ${
  setPoint.userComponentId?.userComponentId ?? setPoint.userComponentId
},
    relevant: ${setPoint.relevant},
    value: ${setPoint.value},
    valueType: ${setPoint.valueType},
    toleranceMax: ${setPoint.toleranceMax},
    toleranceMin: ${setPoint.toleranceMin},
    alarmStartTimeInMin: ${setPoint.alarmStartTimeInMin},
    alarmToleranceTimeInMin: ${setPoint.alarmToleranceTimeInMin},
    controlOptionNo: ${setPoint.control.id},
    endControlOptionNo: ${setPoint.endControl.id},
    endValue: ${setPoint.endValue},
  }`;

  const INPUT_RECIPE_POSITION = (position) => `{
    header: ${INPUT_POSITION_HEADER(position.header)}
    setPoints: [${position.setpoints.map((setPoint) => INPUT_SET_POINT(setPoint))}]
  }`;

  const INPUT_RECIPE_HEADER = (data) => `{
    recipeName: "${data.name}",
    description: "${data.description}",
    comment: "${data.comment}",
    processId: ${data.processOption?.id ?? data.processOption},
    processType: ${data.processType},
    processDepth: ${data.processDepth},
    processDepthAt: ${data.processDepthAt},
    diff: ${data.diff},
    correction: ${data.correction},
  }`;

  const INPUT_SAVE_NEW_RECIPE = (data) => `{
    recipePrefix: "${data.recipePrefix}",
    header: ${INPUT_RECIPE_HEADER(data.header)}
    positions: [${data.positions.map((position) => INPUT_RECIPE_POSITION(position))}]
    spEntries: ${INPUT_SP_ENTRIES(data.spEntries)}
    material: ${INPUT_MATERIAL(data.material)}
    correctionProfile: ${
  data.header.correction ? JSON.stringify(data.correctionProfile) : null
}
  }`;

  const INPUT_SAVE_EDITED_RECIPE_VERSION = (data) => `{
    recipePrefix: "${data.recipePrefix}"
    headId: ${data.header.id}
    editedVersionNo: ${data.header.version}
    editedRecipeName: "${data.editedRecipeName}"
    createNewVersion: ${data.createNewVersion}
    header: ${INPUT_RECIPE_HEADER(data.header)}
    positions: [${data.positions.map((position) => INPUT_RECIPE_POSITION(position))}]
    spEntries: ${INPUT_SP_ENTRIES(data.spEntries)}
    material: ${INPUT_MATERIAL(data.material)}
    correctionProfile: ${
  data.header.correction ? JSON.stringify(data.correctionProfile) : null
}
  }`;

  function getRecipeList(location, prefix) {
    const query = `
    query {
      recipeList(recipePrefix: "${prefix}") {
        id
        recipe
        description
      }
    }`;

    return fetchGraphJSON(query, {
      location,
    });
  }

  function getRecipeItem(location, {
    recipePrefix, recipe, workplaceId,
  }) {
    const query = `
      query {
        recipeItem(recipePrefix: "${recipePrefix}", recipe: "${recipe}", workplaceId: ${workplaceId}) ${TYPE_RECIPE_ITEM_VERSION}
      }`;

    return fetchGraphJSON(query, {
      location,
    });
  }

  async function getActualRecipeOfWorkplace(workplaceId) {
    const query = `
    query {
    actualRecipeOfWorkplace(workplaceId: "${workplaceId}") ${TYPE_ACT_RECIPE_VERSION}
  }`;
    try {
      let res = await fetchGraph(query);
      res = await res.json();
      res = res?.data?.actualRecipeOfWorkplace;
      return res;
    } catch (err) {
      return err;
    }
  }

  function saveRecipeAs(data, workplaceId, location) {
    const mutation = `
    mutation {
      cloneRecipeAndSaveAs(data: {oldRecipeName: "${data.oldRecipeName}", newRecipeName: "${data.newRecipeName}", description: "${data.description}", recipePrefix: "${data.recipePrefix}"}, workplaceId: ${workplaceId}) ${TYPE_CLONE_RECIPE_AND_SAVE_AS_RESULT}
    }`;

    return fetchGraphJSON(mutation, {
      location,
    });
  }

  function deleteRecipeEntry(location, {
    name, pgmPrefix, versionIds,
  }) {
    const mutation = `
    mutation {
      deleteRecipeVersions(data: {name: "${name}", pgmPrefix: "${pgmPrefix}", versionIds: [${versionIds}]})
    }`;

    return fetchGraphJSON(mutation, {
      location,
    });
  }

  function getRecipeMetadata(location, {
    recipePrefix, workplaceId,
  }) {
    const query = `
    query {
      getRecipeMetadata(recipePrefix: "${recipePrefix}", workplaceId: ${workplaceId}) ${TYPE_RECIPE_METADATA}
    }`;

    return fetchGraphJSON(query, {
      location,
    });
  }

  function saveRecipe(location, {
    data, workplaceId,
  }) {
    const mutation = `
    mutation {
      saveNewRecipe(data: ${INPUT_SAVE_NEW_RECIPE(
    data,
  )}, workplaceId: ${workplaceId}) ${TYPE_SAVE_RECIPE_RESULT}
    }`;

    return fetchGraphJSON(mutation, {
      location,
    });
  }

  function saveEditedRecipe(location, {
    data, workplaceId,
  }) {
    const mutation = `
    mutation {
      saveEditedRecipeVersion(data: ${INPUT_SAVE_EDITED_RECIPE_VERSION(
    data,
  )}, workplaceId: ${workplaceId}) ${TYPE_SAVE_RECIPE_RESULT}
    }`;
    return fetchGraphJSON(mutation, {
      location,
    });
  }

  function getMenuRecipes(location) {
    const query = `
    query {
      menuRecipes {
        id
        workplaceId
        name
        description
        recipePrefix
      }
    }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function listOfAvailableFurnaces(location) {
    const query = `
    query {
      listOfAvailableFurnaces
    }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  return {
    getMenuRecipes,
    saveEditedRecipe,
    saveRecipe,
    getRecipeMetadata,
    saveRecipeAs,
    getRecipeItem,
    getActualRecipeOfWorkplace,
    deleteRecipeEntry,
    getRecipeList,
    listOfAvailableFurnaces,
  };
};
