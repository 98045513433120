import {
  portalV2Api,
} from '@/services/AxiosService';
import {
  FURNACES_PATH,
} from '@/constants/apiPaths';

export const furnace = {
  get: async (params) => {
    const response = await portalV2Api.get(FURNACES_PATH, {
      params,
    });
    return response.data;
  },
  productivity: {
    get: async (equipmentId = null) => {
      const response = await portalV2Api.get(`${FURNACES_PATH}/${equipmentId}/productivity`);
      return response.data;
    },
  },
};
