import FormData from 'form-data';
import {
  portalV2Api,
} from '@/services/AxiosService';
import {
  BLOB_STORE_PATH,
} from '@/constants/apiPaths';

export const NAMESPACE_MAINTENANCE_CALENDAR = 'maintenance-calendar';

export const NAMESPACE_PRODUCTION_LOGS = 'production-logs';

export const BlobStoreService = {
  delete(namespace, key, file_name) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .delete(`${BLOB_STORE_PATH}/${namespace}/files/${key}/${file_name}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  add(namespace, file, key) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return new Promise((resolve, reject) => {
      portalV2Api
        .post(`${BLOB_STORE_PATH}/${namespace}/files/${key}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFile(namespace, key, filename) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${BLOB_STORE_PATH}/${namespace}/files/${key}/${filename}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFileList(namespace, key) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${BLOB_STORE_PATH}/${namespace}/content/${key}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
