import {
  portalApi,
} from '@/services/AxiosService';
import {
  RECIPE_PATH,
  RECIPES_PATH,
  RECIPE_MATERIALS_PATH,
} from '@/constants/apiPaths';

export const RecipeService = {
  getRecipeMaterials(filter = '') {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${RECIPE_PATH}/${RECIPE_MATERIALS_PATH}`, {
          params: {
            filter,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getRecipes(filter = '') {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${RECIPE_PATH}/${RECIPES_PATH}${filter}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getRecipeFromId(id = '') {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${RECIPE_PATH}/${RECIPES_PATH}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getProcess(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${RECIPE_PATH}/${RECIPES_PATH}/${id}/process`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getProfile(id) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${RECIPE_PATH}/${RECIPES_PATH}/${id}/profile`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
