import {
  components,
} from '@/types/openapi';

export enum EPhysicalDimension {
  IDENTITY = 0,
  ACCELERATION = 1,
  ADMITTANCE = 2,
  AMOUNT = 3,
  ANGLE = 4,
  ANGULAR_ACCELERATION = 5,
  ANGULAR_MOMENTUM = 6,
  ANGULAR_VELOCITY = 7,
  APPARENT_ELECTRICAL_POWER = 8,
  AREA = 9,
  CAPACITANCE = 10,
  COOLING_RATE = 11,
  CURRENCY = 12,
  DATE = 13,
  DATETIME = 14,
  DENSITY = 15,
  DURATION = 16,
  ELECTRICAL_REACTIVE_POWER = 17,
  ELECTRICAL_RESISTANCE = 18,
  ELECTRIC_CHARGE = 19,
  ELECTRIC_CURRENT = 20,
  ENERGY = 21,
  FLOW = 22,
  FORCE = 23,
  FREQUENCY = 24,
  HEAT_FLUX = 25,
  INDUCTIVITY = 26,
  INTERVAL = 27,
  LENGTH = 28,
  MAGNETIC_FLUX = 29,
  MASS = 30,
  MASS_FLOW_RATE = 31,
  MODULUS_OF_ELASTICITY = 32,
  MOMENTUM = 33,
  MOMENT_OF_INERTIA = 34,
  POWER = 35,
  POWER_DENSITY = 36,
  PRESSURE = 37,
  QUANTITY = 38,
  RATIO = 39,
  ROTATIONAL_VELOCITY = 40,
  SOUND_PRESSURE = 41,
  STRESS = 42,
  SURFACE_TENSION = 43,
  TEMPERATURE = 44,
  THERMAL_EXPANSION = 45,
  THERMAL_RESISTANCE = 46,
  TIME = 47,
  TORQUE = 48,
  VELOCITY = 49,
  VISCOSITY = 50,
  VOLTAGE = 51,
  VOLUME = 52,
  WORK = 53,
  PRESSURE_RATE = 54,
  MASS_FRACTION = 55,
  HARDNESS = 56,
  FLOW_PER_AREA = 57,
}

export const PhysicalDimensionMap: Record<components['schemas']['PhysicalDimensionType'], keyof typeof EPhysicalDimension> = {
  0: 'IDENTITY',
  1: 'ACCELERATION',
  2: 'ADMITTANCE',
  3: 'AMOUNT',
  4: 'ANGLE',
  5: 'ANGULAR_ACCELERATION',
  6: 'ANGULAR_MOMENTUM',
  7: 'ANGULAR_VELOCITY',
  8: 'APPARENT_ELECTRICAL_POWER',
  9: 'AREA',
  10: 'CAPACITANCE',
  11: 'COOLING_RATE',
  12: 'CURRENCY',
  13: 'DATE',
  14: 'DATETIME',
  15: 'DENSITY',
  16: 'DURATION',
  17: 'ELECTRICAL_REACTIVE_POWER',
  18: 'ELECTRICAL_RESISTANCE',
  19: 'ELECTRIC_CHARGE',
  20: 'ELECTRIC_CURRENT',
  21: 'ENERGY',
  22: 'FLOW',
  23: 'FORCE',
  24: 'FREQUENCY',
  25: 'HEAT_FLUX',
  26: 'INDUCTIVITY',
  27: 'INTERVAL',
  28: 'LENGTH',
  29: 'MAGNETIC_FLUX',
  30: 'MASS',
  31: 'MASS_FLOW_RATE',
  32: 'MODULUS_OF_ELASTICITY',
  33: 'MOMENTUM',
  34: 'MOMENT_OF_INERTIA',
  35: 'POWER',
  36: 'POWER_DENSITY',
  37: 'PRESSURE',
  38: 'QUANTITY',
  39: 'RATIO',
  40: 'ROTATIONAL_VELOCITY',
  41: 'SOUND_PRESSURE',
  42: 'STRESS',
  43: 'SURFACE_TENSION',
  44: 'TEMPERATURE',
  45: 'THERMAL_EXPANSION',
  46: 'THERMAL_RESISTANCE',
  47: 'TIME',
  48: 'TORQUE',
  49: 'VELOCITY',
  50: 'VISCOSITY',
  51: 'VOLTAGE',
  52: 'VOLUME',
  53: 'WORK',
  54: 'PRESSURE_RATE',
  55: 'MASS_FRACTION',
  56: 'HARDNESS',
  57: 'FLOW_PER_AREA',
};
