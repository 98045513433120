import {
  components,
} from './openapi';

export enum EChamberType {
  HOT = 'hot',
  ISO_THERMAL = 'iso_thermal',
  QUENCH = 'quench',
  VACUM = 'vacuum',
}

export const chamberTypeMap: Record<components['schemas']['ChamberType'], keyof typeof EChamberType> = {
  hot: 'HOT',
  iso_thermal: 'ISO_THERMAL',
  quench: 'QUENCH',
  vacuum: 'VACUM',
};

export enum EHeatTreatmentProcessType {
  ANNEALING = 1,
  QUENCHING = 32,
  NEUTRAL_HARDENING = 33,
  CRYOGENIC_TREATMENT = 65,
  TEMPERING = 75,
  AGING = 100,
  CARBURIZING = 128,
  CARBONITRIDING = 129,
  NITRIDING = 161,
  NITROCARBURIZING = 162,
  PRE_OXIDATION = 163,
  POST_OXIDATION = 164,
  ACTIVATION = 165,
}

export const heatTreatmentProcessTypeMap: Record<components['schemas']['HeatTreatmentProcess'], keyof typeof EHeatTreatmentProcessType> = {
  1: 'ANNEALING',
  32: 'QUENCHING',
  33: 'NEUTRAL_HARDENING',
  65: 'CRYOGENIC_TREATMENT',
  75: 'TEMPERING',
  100: 'AGING',
  128: 'CARBURIZING',
  129: 'CARBONITRIDING',
  161: 'NITRIDING',
  162: 'NITROCARBURIZING',
  163: 'PRE_OXIDATION',
  164: 'POST_OXIDATION',
  165: 'ACTIVATION',
};
