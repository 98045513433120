import {
  NPM_FURNACE_PATH,
} from '@/constants/apiPaths';
import {
  portalApiClient,
} from '@/services/AxiosService';

export const NpmControlService = {
  getRecipeList({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/recipe-list`, {
          withCredentials: true,
        })
        .then((response) => {
          resolve(response.data.recipe_list);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getLoadJobConfig({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/job/config`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getLoadJobInfo({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/job`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  saveLoadJobInfo({
    customerCode, location, systemId, payload,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .post(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/load-job`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e?.response?.data?.detail?.response?.content);
        });
    });
  },
  getSystemStatus({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/system-status`)
        .then((response) => {
          resolve(response.data.chambers);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  acknowledgeAlarm({
    customerCode, location, systemId, subSystemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .post(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/${subSystemId}/acknowledge-alarms`, null, {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  startJob({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .post(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/start-job`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getButtonInfo({
    customerCode, location, systemId,
  }) {
    return new Promise((resolve, reject) => {
      portalApiClient
        .get(`${NPM_FURNACE_PATH}/${customerCode}/${location}/${systemId}/button-info`)
        .then((response) => {
          resolve(response.data.button_info.permissions);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
