export default {
  generalInformation: {
    title: 'General information',
    company: 'Company',
    street: 'Street',
    postalCode: 'Postal code',
    city: 'City',
    state: 'State',
    country: 'Country',
    email: 'Email',
    phone: 'Phone',
    fax: 'Fax',
    cellPhone: 'Cell phone',
  },
  contact: {
    firstName: 'First name',
    lastName: 'Last name',
    jobTitle: 'Job title',
    phone: 'Phone 1',
    extensionPhone: 'Ext.',
    secondPhone: 'Phone 2',
    cellPhone: 'Cell phone',
    privateMail: 'Email',
    addContact: 'Add contact',
  },
};
