export default {
  errors: {
    downloadPdfFailed: 'Failed to download the Quality report for {report}. If that error continues to persist please get in contact with the Qmulus Support.',
    contentUrlNotSet: 'Could not determine the URL of the PDF for report {report}.',
  },
  longRunning: {
    summary: 'Generating report',
    detail: 'The generation of a report may take a while. Please keep the browser window open. This message will close once the request has completed.',
  },
};
