export default {
  simulation: {
    header: 'Simulation',
    secondsPerCalculationStepLabel: 'Sekunden pro Berechnungsschritt',
    secondsPerCalculationStepPlaceholder: 'Sekunden pro Berechnungsschritt eingeben',
    secondsPerCalculationStepDescription: 'Anzahl der Sekunden pro Simulationsschritt',
    factorOnCBetaLabel: 'Faktor auf C-Beta',
    factorOnCBetaDescription: 'Kohlenstoffübertragungskoeffizient anpassen',
    factorOnCDiffCoeffLabel: 'Faktor auf C-Diff-Coeff',
    factorOnCDiffCoeffDescription: 'Kohlenstoffdiffusionskoeffizient anpassen',
  },
  lowPressureCarburizingOnly: {
    header: 'Nur Niederdruckaufkohlung',
    maxCInCementiteLabel: 'Max. C in Zementit',
    maxCInCementiteDescription: 'Obere Grenze für die automatische Boost-Hysterese',
    cBelowCarbidelimitLabel: 'C unter Carbidelimit',
    cBelowCarbidelimitDescription: 'Untere Grenze für die automatische Boost-Hysterese',
  },
  showComponentSelectionWhenLoadingCheckbox: 'Komponentenauswahl beim Laden anzeigen',
};
