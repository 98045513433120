export default {
  resourceId: {
    1: 'Wert eintragen',
    2: 'OK',
    3: 'Abbrechen',
    4: 'Dateiname',
    5: 'Löschen',
    6: 'Schliessen',
    7: 'Rückstellung',
    8: 'Löschen',
    9: 'Datei kann nicht geöffnet werden',
    10: 'Eingabekasten',
    11: 'Neue',
    12: 'Geschichte',
    13: 'Öffnen',
    14: 'Speichern',
    15: 'Beenden',
    16: 'Änderungen speichern?',
    17: 'Ja',
    18: 'Nein',
    19: 'Datei',
    20: 'Alarm',
    21: 'Drucken',
    22: 'Verändern',
    23: 'Auswählen',
    24: 'Benutzer',
    25: 'Standardwert',
    26: 'Kopieren',
    27: 'Verschieben',
    28: 'Als Standardwert speichern?',
    29: 'Sind Sie sicher?',
    30: 'ist nicht verfügbar',
    31: 'Einfügen',
    32: 'Plan',
    33: 'Jetzt',
    34: 'Abschicken',
    35: 'Aktualisierung erfolgreich',
    36: 'Aktualisierung fiel aus',
    37: 'Kommentar:',
    38: 'ansehen',
    39: 'Logbuch',
    40: 'Konfigurieren',
    41: 'Ignorieren',
    42: 'Entfernen',
    43: 'Warnung',
    44: 'Bitte bestätigen',
    45: 'Unzoom',
    46: 'Beschreibung',
    47: 'Prozess',
    48: 'Hilfedatei offnen nicht moglich',
    49: 'Auswählen',
    50: 'Sortieren',
    51: 'Erweitern',
    52: 'Verbergen',
    53: 'Fehler Nummer',
    54: '<Mit Nitrex Kontakt aufnehmen>',
    55: 'Anwendung schliessen ?',
    56: 'Entweder fehlt die folgende Datei',
    57: 'Für diese Windows Version',
    58: 'Operation erfolgreich beendet.',
    59: 'Operation erfolglos beendet. Mehr Information finden Sie in Datei [system.err].',
    60: 'Ein unerwartete Fehler trat auf.',
    61: 'Einige Dateien fehlen.',
    62: 'Panel Zustand',
    63: 'Applikation beendet',
    64: 'Datei gespeichert',
    65: 'Datei nicht gespeichert',
    66: 'Drives',
    67: 'Selektion',
    68: 'Abbruch',
    69: 'Log Intervall',
    70: 'Sekunden',
    71: 'raus',
    72: 'Zu',
    73: 'Datei Typ',
    74: 'Schau in',
    75: 'Zuruck',
    76: 'Hauptstamm',
    77: 'Listenansicht',
    78: 'Detailansicht',
    79: 'Grosse',
    80: 'Typ',
    81: 'Modifizieren',
    82: 'MM-DD HH:MM:SS',
    83: 'Nicht als Fernbediener angemeldet',
    84: 'Systemzugang nicht moglich',
    85: 'Installiere Update',
    86: ':',
    87: 'Alles anzeigen',
    88: 'Sicherheitsschlussel nicht gultig',
    89: 'Sicherheitsschlussel nicht gefunden',
    90: 'Zugriffserlaubniss abgelaufen',
    91: 'Datum wurde zuruckgesetzt',
    92: 'Zuvor',
    93: 'Nachstes',
    94: 'Arbeitseinheiten',
    95: 'EU Group',
    96: 'Alle anzeigen',
    97: 'Alle zuruckziehen',
    98: 'Chart',
    99: '#',
    100: 'Benutzer Log On',
    101: 'Elapsed Time',
    102: 'Saved',
    103: 'None',
    104: 'Bottom',
    105: 'Right',
    106: 'License',
    107: 'License Has Been Set',
    108: 'License Code',
    109: 'Request Code',
    110: 'Invalid License',
    111: 'This License Has Already Been Set',
    112: 'Error restoring backup file ',
    113: 'Contact UPC',
    114: 'You are running a demo version of this software',
    115: 'Firmware Version',
    116: 'Serial Number',
    117: 'Please wait ...',
    118: 'Watchdog failed to restart controller',
    119: 'Invalid File',
    120: 'Missing File',
    121: 'Shop Order',
    122: 'Load Number',
    123: 'Part Number(s)',
    124: 'Save As',
    125: 'Delete ?',
    126: 'Configuration is saved. Please restart the application in order for changes to take effect.',
    127: 'Append',
    128: 'Save As',
    129: 'Insert',
    130: 'Recalculate',
    131: 'On',
    132: 'Off',
    133: 'View Details',
    134: 'Plant Alarm Status',
    135: 'System Alarm Status',
    136: 'System',
    137: 'Plant View',
    138: 'Attempting to establish connections.',
    139: 'Driver Error',
    140: 'True',
    141: 'False',
    142: 'Day',
    143: 'Month',
    144: 'Year',
    145: 'Hour',
    146: 'Minute',
    147: 'Second',
    148: 'Yes All',
    149: 'Chart Comment',
    150: 'Part Count',
    151: 'Error',
    152: 'Add',
    153: 'Profile/Die',
    154: 'Die Count',
    155: 'Profile',
    156: 'Customer Code',
    157: 'Invalid Format',
    158: 'Update in progress.',
    159: 'Please reboot the controller.',
    160: 'Reset Font',
    161: 'Set Language',
    162: 'Font Size',
    163: 'Large Font',
    164: 'Medium Font',
    165: 'Small Font',
    166: 'Help',
    167: 'Start',
    168: 'Stop',
    169: 'Template',
    170: 'Update',
    171: 'Tools',
    172: 'Calculate',
    173: 'Calculation',
    174: 'Lot number(s)',
    175: 'Please insert USB stick',
    176: 'Operator',
    177: 'Function not supported',
    178: 'Nothing to display',
    179: 'File too big',
    199: 'Reading',
    200: 'Do you want to proceed?',
    201: 'IIoT',
    202: 'Select Recipe',
    203: 'Select Log Path',
    204: 'Interactive Plot',
    205: 'Show Model',
    206: 'Time Series',
    207: 'Predictive/Actual Comparison',
    208: 'Model Results Evaluation',
    209: 'Correlation Plot',
    512: 'Benutzer kreieren ...',
    513: 'Speichern',
    514: 'RESERVIERT',
    515: 'Voll Name',
    516: 'Benutzer Name',
    518: 'Gruppe verändern...',
    519: 'Benutzer Manager',
    520: 'Benutzer verändern...',
    521: 'Ausgewahlte Benutzer kopieren',
    522: 'Benutzer klonieren...',
    523: 'Gruppe kreieren...',
    524: 'Gruppe löschen',
    525: 'Gruppe klonieren...',
    526: 'Gruppenname:',
    527: 'RESERVIERT',
    528: 'RESERVIERT',
    529: 'RESERVIERT',
    530: 'Zugang',
    531: 'Eingebaute Grupe',
    532: 'Unfähig',
    533: 'Voll Name :',
    534: 'Benutzer Name:',
    535: 'Passwort:',
    536: 'Beschreibung:',
    537: 'Nicht sichtbar',
    538: 'RESERVIERT',
    540: 'Benutzer Manager',
    541: 'RESERVIERT',
    542: 'RESERVIERT',
    543: 'RESERVIERT',
    544: 'RESERVIERT',
    546: 'Ungültige Benutzer Name',
    547: 'Ungültige Beschreibung',
    549: 'Ungültige Voll Name',
    550: 'Fehler beim Lesen oder Schreiben der Datei.',
    551: 'RESERVIERT',
    557: 'Sprache:',
    558: 'Bitte Sprache auswählen',
    560: 'Einloggen',
    561: 'Benutzer Name:',
    562: 'Passwort:',
    563: 'Ungültige Benutzer Name oder Passwort',
    565: 'Benutzer Name: ',
    575: 'WARNUNG:Ihre eingeloggte Sitzung läuft in weniger als 20 Sekunden ab.',
    576: 'Fortsetzen',
    577: 'Ein Benutzer mit diesem Namen ist schon vorhanden.',
    579: 'Ausloggen',
    580: 'Ordner für Wiederherstellung der Dateien',
    582: 'Datensicherung',
    583: 'Wiederherstellen',
    585: 'Nitrex Benutzer',
    588: 'Einloggung wird versucht. Bitte warten...',
    598: 'Der folgende gemeinsam genutzte Ordner für System',
    599: 'Benutzer Manager ist im Moment beschäftigt',
    600: 'Die Default-Gruppe wurde nicht gefunden. Eine neue Default Gruppe wird kreiert und er muss von einem berechtigtem Benutzer konfiguriert werden.',
    602: 'Default Benutzer unmöglich zu kreieren',
    604: 'Lesen/Schreiben Vorgang versagt. Änderungen nicht gespeichert.',
    606: 'Verzeichnis der Gruppen',
    607: 'Bitte warten bis die Verzeichnis bestückt wird ...',
    608: 'Die Systeme',
    614: 'Ungültige Gruppenname',
    615: 'Gruppe ist schon vorhanden',
    621: 'Benutzer Überprüfung',
    622: 'Bitte ein Ordner für Dateispeicherung auswählen',
    629: 'Möchten Sie die Änderungen speichern?',
    632: 'Invalid permission',
    633: 'Error loading user file.',
    634: 'User already logged in.',
    635: 'No system name was found for the system located at: ',
    636: 'File not found: ',
    637: 'Unable to delete the user that is currently logged on.',
    638: 'Unable to remove groups from the user that is currently logged on.',
    639: 'User Manager Stand Alone - Configurator',
    640: 'Select folder where <QUsers.Dat> file is located',
    641: '',
    642: 'Delay (sec)',
    643: 'You must select a row',
    901: 'Main Temperature Control',
    902: 'Heating Ramp Control',
    903: 'Temperature Control on Zone',
    904: 'Pressure Control',
    905: 'Kn Control - Flow',
    906: 'Kn Control - Atmosphere',
    907: 'Kc Control - Flow',
    908: 'Kc Control - Atmosphere',
    909: 'W% Control',
    910: 'Post-Oxidation Control',
    911: 'Cooling Ramp Control',
    912: 'Dissociation Control - Flow',
    913: 'Dissociation Control - Atmosphere',
    914: 'Controller with External Set Point',
    931: 'Overheat Protection',
    932: 'Overpressure Protection',
    933: 'Under Pressure Protection',
    934: 'Cooling Water Control',
    935: 'Pressure Control with ON/OFF Valve',
    936: 'Heat Trace Control',
    937: 'Backfill Control',
    1024: 'Prozess',
    1025: 'Existiert',
    1026: 'Ersetzen',
    1027: 'Ofen',
    1028: 'Voraussichtliche Zeit',
    1030: 'Prozess Kode',
    1031: 'Sollwerten',
    1032: 'Atmosphäre',
    1033: 'Ausgaben',
    1034: 'Stufe',
    1035: 'Endebedingung',
    1036: 'Zeit',
    1037: 'Stufenname',
    1039: 'Prozess Ersteller',
    1040: 'SET',
    1041: 'Min',
    1042: 'Max',
    1043: 'Fluss',
    1044: 'Nitrierschablonen',
    1045: 'Prozessverzeichnis',
    1046: 'Stufe löschen',
    1047: 'Stufe zufügen',
    1048: 'Stufe einsetzen',
    1049: 'Stufe bestätigen',
    1050: 'Gas',
    1051: 'Wert ausser Bereich',
    1052: 'Total Atmosphäre soll gleich',
    1053: 'Ungültige Wert für Nitrierkennzahl',
    1054: 'Ungültige Wert für Dissoziation',
    1055: 'Reserve',
    1066: 'Chamber',
    1067: 'Invalid Chamber. ',
    1068: 'Chambers are not in correct sequence.',
    1069: 'Missing setup information.  All chambers must be set.',
    1079: 'Konfiguration',
    1080: 'Name eingeben',
    1086: 'Stufe eingefügt!',
    1087: 'Stufe verändert!',
    1088: 'Stufe zugefügt',
    1089: 'Prozess Kode eingeben',
    1090: 'Prozess Beschreibung eingeben (30 Zeichen maximal)',
    1091: 'Stufe kann nicht ohne Prozess Kode gespeichert werden!',
    1092: 'Stufe kann nich ohne Prozess Beschreibung gespeichert werden!',
    1093: 'Keine Stufe! Benutzen Sie stattdessen',
    1094: 'Fehler in Konfigurationsdatei',
    1095: 'Prozess speichern',
    1096: 'Prozess schliessen',
    1101: 'Prozess wird nicht gespeichert',
    1102: 'Prozess gespeichert',
    1103: 'Voraussichtliche Prozesszeit',
    1104: 'Keine Änderung zum Speichern',
    1105: 'Fehler mit Vakuum Sollwert',
    1106: 'ATM berechnen',
    1107: 'Um den Name zu ändern',
    1108: 'Voraussichtliche Ofenatmosphäre',
    1109: 'Bericht erstatten',
    1110: 'Ofen',
    1111: 'Prozessende',
    1112: 'Prozess Kode kann nicht    /   \\\\  *   oder ?    beinhalten',
    1113: 'Prozessbauer',
    1114: 'Prozess öffnen',
    1115: 'Prozess Ersteller geöffnet zurzeit für ',
    1116: 'Nur eine Netzverbindung pro Ofen erlaubt',
    1117: 'Prozessdatei kann nicht geöffnet werden',
    1118: 'Prozess kann von einen anderen Benutzer geöffnet werden ',
    1119: 'Prozess speichern als:  (12 Zeichen maximal)',
    1120: 'Prozess gespeichert',
    1121: 'Öfen',
    1123: 'OFEN T',
    1124: 'Speziale Prozesse',
    1125: 'Schablonen',
    1126: 'Notfallprozessen',
    1129: 'Prozess beladen',
    1130: 'Prozess verändern',
    1131: 'Stufen die schon eingetreten sind',
    1132: 'Stufe ist seit der Beladung fortgeschritten. Prozess wiederbeladen.',
    1133: 'Kein Prozess läuft zurzeit.',
    1134: 'Um Prozess zu beladen',
    1135: 'Fehler beim Ablesen/Schreiben der Datei',
    1136: 'Fehler beim Beladen der Datei',
    1137: 'Fehler beim Verändern des Prozesses - Keine Änderung gespeichert',
    1138: 'Kein Prozess läuft zurzeit',
    1139: 'Prozess:',
    1140: 'Geplante Prozess Startzeit',
    1141: 'Nichts ausgewählt',
    1142: 'Sofort anfangen ',
    1143: 'Schablonen',
    1144: 'Kein Prozess beladen',
    1145: 'Von Datei beladen',
    1146: 'In Datei speichern',
    1147: 'Ungültige Temporärdatei',
    1148: 'Temporärdatei ersetzen?',
    1149: 'Werk abspeichern',
    1150: 'Temporärdateien löschen',
    1151: 'Dateiname eingeben (Keine Dateiendung)',
    1152: 'Temporär Dateiname',
    1153: 'Dateiname kann nicht beinhalten ',
    1154: 'Keine Stufe definiert - Zuerst Stufe zufügen oder speichern',
    1155: 'Sie müssen ein Dateiname eingeben - Werk nicht gespeichert',
    1156: 'Stufe bestätigt',
    1157: 'Werkzeugprogramme',
    1158: 'Gamma Epsilon Grafik',
    1159: 'Bericht erstatten',
    1160: 'Prozess mit vorgenommenen Anderungen up-datieren?',
    1161: 'Sie haben nicht die benötigten Rechte spezielle Rezepte zu ändern. Wollen Sie stattdessen das Job-Rezept ändern?',
    1168: 'Nicht genug Daten um Atmosphäre zu berechnen',
    1169: 'Nur im Wartungsmodus durchführbar!',
    1170: 'Prozess hat keine gültige Stufen! ',
    1171: 'Nur ein geöffnetes Prozess erlaubt',
    1172: 'Um den Prozess Kode zu ändern',
    1173: 'Ungültige Stufen gefunden und gelöscht. Teilprozess wird beladen.',
    1174: 'Cannot insert new stage before current stage.',
    1175: 'Cannot delete current stage.',
    1176: 'Cannot delete a block when it includes the current stage.',
    1182: 'Prozess beladen',
    1183: 'Nicht verbunden',
    1184: 'Fehler beim Senden der Datei',
    1185: 'Prozess anfangen',
    1186: 'Um Prozess anzufangen',
    1187: 'Kein Prozess läuft zurzeit',
    1188: 'Prozess abbrechen',
    1189: 'Prozess wiederherstellen',
    1190: 'Prozess verändert',
    1191: 'Atmosphärengrafik',
    1192: 'Prozessstufe',
    1193: 'Gaskonzentration',
    1194: 'Kein gültige Nummer',
    1195: 'Prozess nicht gefunden - Entfernung aus Index',
    1196: 'Datensicherung',
    1197: 'Kopieren beendet',
    1199: 'Kode',
    1200: 'Prozess wird von anderem Benutzer verändert',
    1202: 'Bestätigen',
    1203: 'Kopieren versagt',
    1204: 'Bibliothek aktualisieren',
    1205: 'ist kein gültige Zeit.',
    1206: 'ist keine gültige Nummer.',
    1207: 'ist kein gültige Atmosphärenwert.',
    1208: 'Prozess geschützt von Nitrex',
    1209: 'Als Teilprozess validieren',
    1210: 'Zeitverzug (Sek.)',
    1211: 'Digitale-Steuerung',
    1212: 'Äquivalenz',
    1213: 'Analog Eingänge',
    1214: 'Digital Eingänge',
    1215: 'Analog Outputs',
    1216: 'N2',
    1217: 'NH3',
    1218: 'DNH3',
    1219: 'LUFT',
    1220: 'CO2',
    1221: 'ENDO',
    1222: 'METHAN',
    1223: 'RESERVE',
    1224: 'Prozess existiert bereits- Ersetzen?',
    1225: 'Datei existiert bereits - Ersetzen?',
    1226: 'Notzustand',
    1227: 'Transfer',
    1228: 'Laufendes Rezept',
    1229: 'Analoge Steuerung',
    1230: 'Modified process sent to the control computer.',
    1231: 'Add Block',
    1232: 'Insert Block',
    1233: 'Remove Block',
    1234: 'Block',
    1235: 'New Block',
    1236: 'Open Block',
    1237: 'Delete Block',
    1238: 'Save Block',
    1239: 'Close Block',
    1240: 'New Process',
    1241: 'Open Process',
    1242: 'Delete Process',
    1243: 'Block Name',
    1244: 'Cannot save without block name',
    1245: 'Block Saved',
    1246: 'Delete Blocks',
    1247: 'Open Block',
    1248: 'Block has been added.',
    1249: 'Block has been inserted.',
    1250: 'Not an appropriate block.',
    1251: 'Error Modifying Block - No changes have been saved',
    1252: 'Blocks must have at least two stages.',
    1253: 'Block Version',
    1254: 'Error in Block Stage',
    1255: 'Use Furnace Volume For Flows',
    1256: 'Before Selected Modification',
    1257: 'After Selected Modification',
    1258: 'Set point',
    1259: 'Value',
    1260: 'Unit',
    1261: 'Enabled Controllers',
    1262: 'This process may not be modified remotely.',
    1263: 'Block has been deleted.',
    1264: 'Set Alarm Limits',
    1265: 'Always',
    1266: 'Error - High warning must be less than high alarm',
    1267: 'Error - Low warning must be less than low alarm',
    1268: 'Warning Offsets',
    1269: 'Alarm Offsets',
    1270: 'Alarm Delay (sec)',
    1271: 'In-charge value',
    1272: 'On-charge value',
    1273: 'Upper Limit Use %',
    1274: 'Lower Limit Use %',
    1275: 'High',
    1276: 'Low',
    1277: '% of Setpoint',
    1278: 'Default',
    1279: 'Reference Value',
    1290: 'Digital Outputs',
    1291: 'Status',
    1301: 'H2',
    1302: 'CH4',
    1303: 'C3H8',
    1304: 'N2O',
    1305: 'CH3OH',
    1306: 'C2H2',
    1307: 'C2H6',
    1308: 'Spare Gas1',
    1536: 'Log anschauen',
    1537: 'Alle Punkte löschen',
    1538: 'Alle Meldungen und Alarme auswählen',
    1539: 'Maximale Anzahl der Log Punkten ist ',
    1540: 'Ungültige Log Datei!',
    1541: 'Bank',
    1543: 'Alarmen bestätigt',
    1544: 'SYSTEM WIEDERANLAUFEN NACH ',
    1545: 'MINUTEN',
    1546: 'Teil',
    1547: 'Beladen in',
    1548: 'Ofen',
    1549: 'Entladen von',
    1550: 'Stufenänderung',
    1551: 'Total Verwendung',
    1553: 'SYSTEM ALARM',
    1554: 'Clear All Points',
    1555: ' "Bitte geben Sie Standard-Einstellungen',
    1567: 'Kein Anschauung ausgewählt. Datei öffnen oder Anschauen aktueller Datei auswählen',
    1568: 'Keine gültige Log Datei beladen zurzeit. Öffnen auswählen.',
    1569: 'Log Dateipflege',
    1570: 'Löschen von Dateien ...',
    1571: 'Kopieren von Dateien...',
    1572: 'Verschieben von Dateien...',
    1573: 'Kein gültiger Log Datei Name - Diese Datei wird nicht bearbeitet.',
    1574: 'Kein gültiger Logordner. Vorgang abgebrochen.',
    1575: 'Aktuelle Log Datei kann nicht verschoben oder gelöscht werden. Diese Datei wird nicht bearbeitet',
    1576: 'Die Punkte mit verschiedenen technischen Einheiten können nicht grafisch dargestellt werden - Andere Auswahl machen',
    1577: 'Grafik anschauen',
    1578: 'Auftrag anfangen',
    1579: 'Auftragsnummer',
    1580: 'Prozess Kode',
    1581: 'Interval (Minuten)',
    1583: 'System',
    1584: 'Historie einschliessen?',
    1585: 'Aktuelle',
    1586: 'Kumulativ',
    1587: 'Prozess wiederaufgenommen',
    1588: 'Prozess abgebrochen',
    1589: '(O für ganze Log eingeben)',
    1590: 'Keine Punkte wurden ausgewählt. Um die Punkte zu wählen',
    1591: '(Um die Punkte automatisch zu beladen',
    1592: 'Prozess geändert von ',
    1593: 'Log Dateien exportieren',
    1594: 'In Datei speichern',
    1595: 'Datei kreiert',
    1596: 'MS Excel',
    1597: 'Bevor Anschauung eine Datei',
    1598: 'Export',
    1599: 'Excel.exe kann nicht gefunden werden',
    1600: 'Falls Sie Excel haben',
    1601: 'Exportierte Datei muss eine Dateiendung haben',
    1602: 'Auftragsdauer',
    1603: 'Akkumulierte Auftragsdauer',
    1604: 'Kein Drucker instaliert. Default Drucker instalieren und wieder versuchen.',
    1605: 'Auftragskosten',
    1606: 'Überprüft von',
    1607: 'Process Stopped',
    1608: 'Use Elapsed Time',
    1609: 'Select Points',
    1610: 'Select Points and Axis',
    1611: 'Interval (Minutes)',
    1612: 'Point Description',
    1613: 'Toggle-View',
    1614: 'Toggle to select the view',
    1615: 'Printing-View',
    1616: 'Free-View',
    1617: 'Export to CSV',
    1618: 'Export to HTML',
    1619: 'Data corruption has been detected in this file.',
    1620: '*** CORRUPTION DETECTED ***',
    2048: ' IO Konfiguration fehlt.',
    2049: ' Systemkonfiguration fehlt.',
    2050: ' Fataler Fehler - es kann nicht beladen werden.',
    2051: ' Prozess Set-up Datei fehlt.',
    2052: ' Prozessverzeichnung fehlt.',
    2053: 'Fehler bei Ablesung der Aufstellung des Prozesses',
    2054: 'Prozess hat keine gültige Stuffen',
    2055: 'Ungültige Zeichen in Stufenargument',
    2056: 'Ungültige Stufen Sollwert',
    2057: 'Stufe hat keine gültige Argumentenanzahl',
    2058: 'Prozess Set-up hat keine gültige Stufenreienfolge',
    2059: 'DO Set-up beinhaltet ungültige Zeichen',
    2060: 'DO Set-up beinhaltet eine Verweisung zum nicht existierendem Gerät',
    2061: 'Fehler in DO Set-up - zu viele Argumenten',
    2062: 'Ofenvolumen fehlt',
    2063: 'Heizungsgradient fehlt',
    2064: 'Kühlungsgradient fehlt',
    2065: 'Vakuumsgradient fehlt',
    2066: 'Gas Verhältniss ist nicht zwischen 0 und 100',
    2067: 'Set-up der Stufe verwendet ein nicht existierendes Gas',
    2068: 'Summe allen Gasen ist nicht 100%',
    2069: 'MIN Atmosphäre ist zu hoch',
    2070: 'MAX Atmosphäre ist zu niedrig',
    2071: 'Endebedingung existiert nicht',
    2072: 'Endebedingung für diese Stufe nicht angemessen',
    2073: 'Sollwert ist ausser Bereich',
    2074: 'Wert für Stufenzeit fehlt',
    2075: 'Sollwert für Stufenzeit fehlt',
    2076: 'Ofentemperatur ist nicht konfiguriert',
    2077: 'Endebedingungswert für Temperatur fehlt',
    2078: 'Sollwert für Ofentemperatur fehlt',
    2079: 'Auf-Ladung Temperatur ist nicht konfiguriert',
    2080: 'Auf-Ladung Temperatursollwert fehlt',
    2081: 'In-Ladung Temperatur ist nicht konfiguriert',
    2082: 'In Ladung Temperatursollwert fehlt',
    2083: 'Vakuumablesung ist nicht konfiguriert',
    2084: 'Vakuum Sollwert fehlt',
    2085: 'Druckablesung ist nicht konfiguriert',
    2086: 'Drucksollwert fehlt',
    2087: 'MFC Ablesung ist nicht konfiguriert',
    2088: 'MFC Sollwert fehlt',
    2089: 'Dissoziation Sollwert fehlt',
    2090: 'Nitrierkennzahlsollwert falsch',
    2091: 'Dissoziation Sollwert falsch',
    2092: 'Unklare Daten ',
    2093: 'Solltemperatur zu niedrig',
    2094: 'Solltemperatur zu hoch',
    2095: 'Temperatur zu Endstufe zu hoch',
    2096: 'Temperatur zum Endstufe zu niedrig',
    2097: 'Vakuum Sollwert zu hoch',
    2098: 'Vakuum Sollwert zu niedrig',
    2099: 'Druck Sollwert zu hoch',
    2100: 'Druck Sollwert zu niedrig',
    2101: 'Druck ist zu niedrig für Beenden den Auftrag',
    2102: 'Druck ist zu hoch für Beenden den Auftrag',
    2103: 'Temperatur ist zu hoch für Beenden den Auftrag',
    2104: 'Ammoniakinhalt ist zu hoch für Beenden den Auftrag',
    2105: 'Wasserstoffinhalt ist zu hoch fuer Beenden den Zyklus',
    2106: 'Luftinhalt ist zu hoch für Einführung H2 MAX = ',
    2107: 'Anfangsdurchfluss der Stufe fehlt',
    2108: 'Minimale Durchfluss der Stufe fehlt',
    2109: 'Maximale Durchfluss der Stufe fehlt',
    2110: 'Stufendurchfluss zu hoch',
    2111: 'Stufendurchfluss zu niedrig',
    2112: 'Minimale Durchfluss zu hoch',
    2113: 'Minimale Durchfluss zu niedrig',
    2114: 'Maximale Durchfluss zu hoch',
    2115: 'Maximale Durchfluss zu niedrig',
    2116: 'Minimale Durchfluss ist nicht niedriger als maximale Durchfluss',
    2117: 'Durchfluss Sollwert ist für diese Stufe nicht erlaubt',
    2118: 'Heizung ist für diese Stufe nicht erlaubt',
    2119: 'Beide kann nicht existieren',
    2120: 'IO Punkt existiert nicht',
    2121: 'Unvollständige Setup (Einstellung) der kundenspezifische DE ',
    2122: 'Zeitverzug länger als Stufendauer ',
    2123: 'In-Ladung Temperatur für Beenden der Stufe zu hoch',
    2124: 'In-Ladung Temperatur für Beenden der Stufe zu niedrig',
    2125: 'Auf-Ladung Temperatur für Beenden der Stufe zu hoch',
    2126: 'Auf-Ladung Temperatur für Beenden der Stufe zu niedrig',
    2127: 'Air content too high to end stage, MAX=',
    2128: 'Stufenargument-',
    2129: 'Temperatur Sollwert',
    2130: 'Druck Sollwert',
    2131: 'Vakuum Sollwert',
    2132: 'Endebedingung',
    2133: 'Stufenzeit',
    2134: 'Stufendurchfluss',
    2135: 'Minimale Stufendurchfluss',
    2136: 'Maximale Stufendurchfluss',
    2137: 'Stufenatmosphäre',
    2138: 'Minimale Stufenatmosphäre',
    2139: 'Maximale Stufenatmosphäre',
    2140: 'Nitrierkennzahl Sollwert',
    2141: 'Dissoziation Sollwert',
    2142: 'Stufen Ausschaltentemperatur',
    2143: 'Heizungssteigung Sollwert ',
    2144: 'Reihenfolge',
    2145: 'Beschreibung der Stufe',
    2146: 'Set-up der Vorrichtungen',
    2147: 'Auf-Ladung Temperatur Sollwert',
    2148: 'In-Ladung Temperatur Sollwert',
    2149: 'Kundenspezifische DE Endebedingung',
    2150: 'Kundenspezifische AE Endebedingung',
    2151: 'Analog Output Setup',
    2152: 'Heating should be set on',
    2153: 'On-Off Controllers Setup',
    2154: 'Cooling Slope Set Value',
    2155: 'Differential Temperature Set Value',
    2160: 'Prozess Set-up Fehler!',
    2161: 'Stufenfolge - ',
    2162: 'Stufenargument - ',
    2163: 'Nicht erkennbar Stufenargument',
    2164: 'Unbekannte Fehler',
    2165: 'Analog Output from the setup does not exist',
    2166: 'Not valid output setup',
    2167: 'Missing analog point: Temperature Difference',
    2168: 'Missing value for temperature difference',
    2169: 'Please select this digital output in all stages: ',
    2170: 'Following digital outputs are mutually exclusive (on recipe basis): ',
    2171: 'Some gasses cannot be used concurrently in the stage ',
    2172: 'Custom DI',
    2173: 'Custom AI',
    2174: 'Min Flow',
    2175: 'Max Flow',
    2176: 'Bungs should be closed in heating',
    2177: 'Bungs should be open',
    2178: 'Only one speed can be selected for cooling blower',
    2179: 'Pressure too high for convection fan. MAX=',
    2180: 'Pressure too low for convection fan. MIN=',
    2181: 'Temperature too high for convection fan. MAX=',
    2182: 'Pressure too high for cooling blower. MAX=',
    2183: 'Pressure too low for cooling blower. MIN=',
    2184: 'Cooling water valve on heat exchanger has to be open',
    2185: 'Vent valve has to be closed',
    2186: 'Gas valve has to be closed',
    2187: 'Vacuum valve has to be closed',
    2188: 'Vacuum valve has to be open',
    2189: 'Cannot have both vacuum and gas valve open',
    2190: 'Improper use of heating/cooling ramp',
    2191: 'Cannot split the recipe ',
    2192: 'Set Values for controllers that share input have to be the same',
    2193: 'Cannot enable more than one controller with shared output',
    2194: "Stage setup contains controllers that don't exist",
    2195: 'Inlet gas mixture is not safe',
    2196: 'Not safe to introduce AIR/N2O',
    2200: 'Recipe does not match chamber index. ',
    2201: ' ',
    2202: ' ',
    2203: 'Set Flow is too high for some gas ratios. Will be lowered automatically, if needed.',
    2204: 'Set Flow value that covers the whole ratio range = ',
    2205: 'Set Flow is too low for some gas ratios. Will be increased automatically, if needed.',
    2206: 'Min Flow value that covers the whole ratio range = ',
    2207: 'Max Flow value that covers the whole ratio range = ',
    2208: 'Min Flow is too low for some gas ratios. Will be increased automatically, if needed.',
    2209: 'Max Flow is too high for some gas ratios. Will be lowered automatically, if needed.',
    2210: 'Min Flow is too high for some gas ratios. Will be lowered automatically, if needed.',
    2211: 'Max Flow is too low for some gas ratios. Will be increased automatically, if needed.',
    2561: 'Prozess öffnen',
    2562: 'Benutzer Manager',
    2563: 'Prozess speichern',
    2564: 'Schablone verändern',
    2565: 'Prozess einladen',
    2566: 'Stuffennummer in Prozess verändern',
    2567: 'Log Dateien anschauen',
    2568: 'Grafik anschauen',
    2569: 'Prozessbericht erstatten',
    2570: 'Zugriff auf temporäre Dateien',
    2571: 'Stuffenendebedingung ändern',
    2572: 'Stuffenzeit ändern',
    2573: 'Temperatursollwert ändern',
    2574: 'Drucksollwert ändern',
    2575: 'Vakuumsollwert ändern',
    2576: 'Heizungsneigungsollwert ändern',
    2577: 'Potenzialsollwert ändern',
    2578: 'Dissoziationssollwert ändern',
    2579: 'Stuffendurchfluss ändern',
    2580: 'Stuffenatmosphäre ändern',
    2581: 'Ausgabeeinstellung verändern',
    2582: 'Stuffe anfangen',
    2583: 'Prozesskodierung ändern',
    2584: 'Prozessbeschreibung ändern',
    2585: 'Neuen Prozess erstellen',
    2586: 'Math Model',
    2587: 'Werkzeuge vom Prozess-Ersteller',
    2588: 'Laufende Prozess verändern',
    2589: 'Prozess anlaufen',
    2590: 'Prozess abbrechen',
    2591: 'Process wiederaufnehmen',
    2592: 'Wartungsmodus',
    2593: 'Kalibrieren von Sensoren',
    2594: 'Versätze anwenden',
    2595: 'Anmerkung zufügen',
    2596: 'Prozess-Ersteller',
    2597: 'Log Dateien exportieren',
    2598: 'Log Dateienpflege',
    2599: 'Anwendung beenden',
    2600: 'Sicherungskopie von Config Datei',
    2601: 'Config Datei wiederherstellen',
    2602: 'System aktualisieren',
    2603: 'Ofenmodus',
    2604: 'Bestätigung von Alarmen',
    2605: 'Detailbildschirm anschauen',
    2606: 'Prozess Bibliotheksdateien Sicherungskopie',
    2607: 'Prozess Einstellungsdateien wiederherstellen',
    2608: 'Prozess Einstellungsdateien von Bibliothek löschen',
    2609: 'Verbrauch Monitor anschauen',
    2610: 'Verbrauchsdaten löschen',
    2611: 'Verbrauchpreise ändern',
    2612: 'Auftrag überprüfen',
    2613: 'Prozess Äquivalenzliste verändern',
    2614: 'Ofenbildschirm anschauen',
    2615: 'Restart Turbo Cooling',
    2616: 'Preheat Open Furnace',
    2617: 'Maintenance Scheduler: View',
    2618: 'Maintenance Scheduler: Configure',
    2619: 'Maintenance Scheduler: Do Maintenance',
    2620: 'Acknowledge Message Warning',
    2621: 'Access Die Manager',
    2622: 'Access Die Queries',
    2623: 'Load and Unload Dies',
    2624: 'Toggle Chamber Power',
    2625: 'Toggle Standby Types',
    2626: 'Process Builder:  Change Log Interval',
    2627: 'Process Builder:  View Protected Processes',
    2628: 'Erase Blackboard',
    2629: 'Process Builder:  Create Blocks',
    2630: 'Restart Application',
    2631: 'Process Builder:  Add/Remove Blocks in Process',
    3552: 'Nitrieren',
    3553: 'Nitro',
    3554: 'Vakuum',
    3555: 'Matrizen',
    3556: 'Unbekannt',
    3557: 'Echtzeitgrafik wird beladen',
    3584: 'List all dies.',
    3585: 'List a specific die.',
    3586: 'List all groups.',
    3587: 'List all dies processed.',
    3588: 'List all dies processed within a certain date range.',
    3589: 'List a specific die processed within a certain date range.',
    3616: 'Transaction',
    3617: 'Job Set Temp',
    3618: 'Date/Time',
    3632: 'Die Code',
    3633: 'Total Soak (min)',
    3634: 'Over Soak (min)',
    3635: 'Total Cycle',
    3636: 'Group',
    3637: 'Expired',
    3638: 'From Date',
    3639: 'To Date',
    3642: 'Soak Temp',
    3643: 'Min Soak (min)',
    3644: 'Max Soak (min)',
    3645: 'No records were found',
    3646: 'Search Parameters',
    3647: 'List of queries',
    3648: 'Systemanlauf',
    3649: 'Fehler in EA Konfiguration. stage.err Datei nachprüfen.',
    3650: 'Anlauf',
    3651: 'Unfähig zu öffnen',
    3652: 'Fehler#',
    3653: 'Aktuelle Stufe',
    3654: 'ist grösser als die totale Stufenanzahl',
    3655: 'Fehler beim Herstellen des Prozesses aus Konfig Datei',
    3656: 'Stufennummer',
    3657: 'Bereitschaftsfehler: Prozessmanager öffnungsunfähig',
    3658: 'Bereitschaftsfehler: Baustufe hat ein NULL Prozess zurückgegeben',
    3659: 'Mit Nitrex Kontakt aufnehmen',
    3660: 'Notfallprozess Anlauf.',
    3661: 'Sytem Anlauf versagt. Prozess kann nicht hergestellt werden.',
    3662: 'Von Nitrex geschützter Prozess. Sie haben keine Zugangberechtigung',
    3663: 'Von Nitrex geschützte Prozesse wurden ausgewählt. Sie wurden von Ihrer Auswahl weggenommen.',
    3664: 'Prozesszustand ist geändert. Veränderungen wurden abgebrochen .',
    3665: 'Anwendung hört jetzt auf',
    3666: 'Wiederaufnahme',
    3667: 'Automatisch',
    3668: 'Ofen nicht geschlossen',
    3669: 'Invalid Emergency Type',
    3670: 'Job Start Not Enabled',
    3776: 'Kalibrierung',
    3777: 'Momentane Anzahl',
    3778: 'Niveau',
    3779: 'Anzahl',
    3780: 'Kalibrierung',
    3781: 'Versatz',
    3782: 'Start',
    3783: 'Im Arbeit',
    3784: 'Erledigt',
    3785: 'Herunterladung der Kalibrierungsdatei erfolglos- Keine Kalibrierungsdaten',
    3786: 'Letzte Kalibrierung:',
    3787: '****',
    3788: 'Fehler in der Kalibrierungsdatei ',
    3789: 'Falsche Argumentenanzahl',
    3790: 'Neue Daten können nicht beladen werden. Versuch zu vorherigen Werten zurückzukehren.',
    3791: 'Wert ausser Bereich.',
    3792: 'Es muss dazwischen sein',
    3793: 'ist nicht eine gültige Nummer',
    3794: 'Die kalibrierte Anzahl ist nicht in Reihenfolge',
    3795: 'Kalibrierung nachprüfen',
    3796: 'Die abschliessende Anzahl ist nicht in Reihenfolge',
    3797: 'Den Versatz überprüfen',
    3799: 'Versuch zu den vorherigen Werten zurückzukehren erfolglos. Original Datei wiederherstellen: ',
    3800: 'Original Werte wieder beladen.',
    3801: 'Möchten Sie fortsetzen?',
    3802: 'Kalibrierungsdaten wurden aktualisiert.',
    3803: 'Bericht erstatten',
    3804: 'Kalibrierungsbericht',
    3805: 'Versatz überschreitet untere Grenze',
    3806: 'Versatz überschreitet obere Grenze',
    3807: 'Calibration in progress.  Cannot change mode.',
    3808: 'Correction Offset',
    3809: 'Modification Offset',
    3810: 'Sensor Error',
    3904: 'NORMAL',
    3905: 'WARNUNG',
    3906: 'NIEDRIGE WARNUNG',
    3907: 'HOCH WARNUNG',
    3908: 'ALARM',
    3909: 'NIEDRIGE ALARM',
    3910: 'HOCH ALARM',
    3911: 'NOTFALL',
    3912: 'NIEDRIGE NOTFALL',
    3913: 'HOCH NOTFALL',
    3914: 'KEIN BESCHEID',
    3915: 'NOTFALL (KEIN BESCHEID)',
    3916: 'NORMAL -  Warnung ist aufgehoben',
    3917: 'NORMAL -  Alarm ist aufgehoben',
    3918: 'NIEDRIG WARNUNG - Alarm ist aufgehoben',
    3919: 'HOCH WARNUNG -Alarm ist aufgehoben',
    3920: 'Alarm quittiert',
    3921: 'UNBEKANNTER ALARM?',
    3922: '-',
    3923: '-',
    3924: '-',
    3925: '-',
    3926: '-',
    3927: 'Wert: ',
    3928: 'AUTOMATISCH',
    3929: '?',
    3936: 'Alarm Geschichte',
    3938: 'Typ des Alarms',
    3939: 'Wert bei Auftreten',
    3940: 'Auftrittszeit',
    3941: 'Fehler!',
    3942: 'Log Datei kann nicht gelesen werden',
    3943: 'Log Datei ist leer',
    3944: 'Log Datei kann nicht gefunden werden',
    3945: 'Kein Alarm',
    3947: 'Aktuell',
    3948: 'Historie von: ',
    3949: 'Zuletzt aufgefrischt: ',
    3950: 'Einsatzbereit',
    3968: 'Prozess beladen',
    3971: 'Zeitplan löschen',
    3976: 'Ausgewählter Prozess:',
    3977: 'Auftragsstartzeit:',
    3978: 'Kunde:',
    3980: 'Beladen von:',
    3981: 'Kontrolleur:',
    3982: 'Teile:',
    3983: 'Prozessbibliotheke nicht verfügbar ',
    3985: 'Fehler beim Kopieren der Prozessdatei!',
    3986: 'Ladungsprozessinformationen können nicht gespeichert werden!',
    3987: 'Prozess kann nicht gefunden werden : ',
    3988: 'Sind Sie sicher dass Sie entladen möchten? ',
    3989: ' ?',
    3990: 'Geplante Prozess: ',
    3991: 'Beladene Prozess: ',
    3992: 'Zeitplan gelöscht.',
    3993: 'Zu viel Zeit abgelaufen!',
    3994: 'Sind Sie sicher dass Sie den Prozess anlaufen möchten?',
    3995: 'Kein Prozess beladen',
    3996: 'Geplante Zeitpunkt ist vergangen: ',
    3997: 'Ladungsprozessinformation kann nicht zugegriffen werden !',
    3998: 'Auftrag muss vor dem Lauf',
    4000: 'Auftrag Endprüfung',
    4001: 'Nicht unterschrieben vom Kontrolleur',
    4002: 'Neue Auftrag kann nicht angelaufen werden: Letze Auftrag wurde nicht geprüft!',
    4003: 'Fehler beim Schreiben in Datei ',
    4004: 'Datei nicht gefunden: ',
    4005: 'Zu viele Fehlern! Aufgegeben!',
    4006: 'Es wird versucht',
    4007: 'Sind Sie sicher dass Sie Zeitplan löschen möchten?',
    4008: 'Kein Zeitplan',
    4009: 'Kontrolleur wird nicht annerkant',
    4010: '-',
    4011: '-',
    4012: '-',
    4013: '-',
    4014: '-',
    4015: '-',
    4016: '-',
    4017: '-',
    4018: '-',
    4019: '-',
    4020: 'Log Datei kann nicht kopiert werden',
    4021: '-',
    4022: '-',
    4023: '-',
    4024: '-',
    4025: '-',
    4026: '-',
    4027: '-',
    4028: '-',
    4029: 'DNC Datei kann nicht beladen werden: ',
    4030: 'DNC Belader nicht konfiguriert!',
    4031: 'Pfad kann nicht gefunden werden : ',
    4033: '-',
    4034: '-',
    4035: 'Möchten Sie diese Kommentar speichern? ',
    4036: ' ?',
    4037: 'Fehler beim Schreiben der Datei ',
    4038: 'Kommentar löschen?',
    4039: 'The scheduled start has been cancelled.',
    4096: '               Strom',
    4097: '            Ammoniak',
    4098: 'Dissozierte Ammoniak',
    4099: '          Stickstoff',
    4100: '        Kohlendioxid',
    4101: '            Endo Gas',
    4102: '-',
    4103: '-',
    4104: '-',
    4105: '-',
    4106: '-',
    4107: '-',
    4108: '-',
    4109: '-',
    4110: '-',
    4111: '-',
    4112: 'Ethane C2H6',
    4113: 'Em N2',
    4114: 'N2O',
    4197: 'Cost',
    4198: 'Consumable',
    4582: '-',
    4583: '-',
    4584: '-',
    4585: '-',
    4586: '-',
    4587: '-',
    4588: '-',
    4589: '-',
    4590: 'Fehler beim Speichern der Datei!  Keine Änderung gemacht.',
    4591: 'Keine Änderungen. Nicht gespeichert.',
    4592: 'Fehler beim Beladen der Daten!',
    4593: 'Fehler beim Eingabe für',
    4594: 'Preiswechsler',
    4595: 'Nicht konfiguriert',
    4596: 'Nichts ausgewählt',
    4597: 'Preise ändern',
    4598: 'Auftragsdaten anzeigen',
    4599: 'Kumulierte Daten anzeigen',
    4600: 'Datenlöschen für',
    4601: 'Total Akkumulierte Kosten:',
    4602: 'Akkumulierte Kosten:',
    4603: 'Akkumulierte Verbrauch:',
    4604: 'Total Auftragskosten:',
    4605: 'Auftragskosten:',
    4606: 'Auftragsverbrauch:',
    4607: 'Verbrauchswächter',
    4608: 'Ausreichender Speicherplatz verfügbar.',
    4609: 'Speicherplatz knapp!',
    4610: 'Speicherplatz zu knapp!',
    4611: 'Program kann Prozess Log Datei zugreifen.',
    4612: 'Probleme mit Schreiben in Prozess Log Datei!',
    4613: 'Es konnte nicht in Prozess Log Datei geschrieben werden! Daten sind verloren!',
    4614: 'Program kann jetzt die Prozessänderungen speichern.',
    4615: '-',
    4616: '!Program KANN NICHT die Prozessänderungen speichern!',
    4617: '-',
    4618: '-',
    4619: '-',
    4620: '-',
    4621: '-',
    4622: '-',
    4623: 'Warnung!',
    4624: 'Speicherplatz',
    4625: '-',
    4626: 'Speicherplatz ein wenig!',
    4627: 'Speicherplatz ZU wenig!',
    4628: 'Prozess Log',
    4629: '-',
    4630: 'Speichern in Prozess Log Datei nicht möglich!',
    4631: 'DATEN SIND VON PROZESS LOG VERLOREN !',
    4632: 'Prozessspeicher',
    4633: '-',
    4634: '-',
    4635: 'Program kann nicht die Prozessänderungen speichern.',
    4636: 'Chart Recorder',
    4672: 'Produktionsaufgabe',
    4673: 'Teilenummer',
    4674: 'Menge',
    4675: '-',
    4676: '-',
    4677: '-',
    4678: '-',
    4679: '-',
    4680: 'Minimum:',
    4681: 'Aufgabesnummer eingeben:',
    4682: 'Aufgabe auswählen',
    4683: 'Neue Aufgabe',
    4684: 'Aufgabe entfernen',
    4685: 'Total Menge:',
    4686: 'Aufgabe schon beladen!',
    4687: 'Aufgabe nicht gefunden!',
    4688: '-',
    4689: '-',
    4690: 'Sind Sie sicher dass Sie die folgende Aufgaben entfernen möchten ?:',
    4691: 'Es gibt nichts zu entfernen.',
    4692: 'Doppelte Aufgabe.',
    4693: 'SAP Ordner ist nicht konfiguriert.',
    4694: 'Datei kann nicht beladen werden:',
    4695: '-',
    4696: 'Die folgende Felder sind nicht gültig:',
    4697: '-',
    4698: '-',
    4699: 'Sicherheitsübersteuerung',
    4700: 'Mengenbeschränkung Übersteuerung',
    4701: 'Sind Sie sicher dass Sie die Sicherheitsübersteuerungen löschen möchten?',
    4702: '-',
    4703: '-',
    4704: 'Aus SAP beladen',
    4705: 'Verfügbare Aufgaben',
    4706: 'Ausgewählte Aufgaben',
    4707: '-',
    4709: 'Die minimale Menge wurde nicht erreicht.',
    4710: 'Widerstreit der Prozessen.',
    4711: '-',
    4712: '-',
    4713: '-',
    4714: '-',
    4715: '-',
    4716: '-',
    4717: '-',
    4718: '-',
    4719: '-',
    4720: 'Auftragsanforderungen Übersteuerung',
    4721: 'Minimale Menge ansetzen',
    4722: 'Sind Sie sicher dass Sie die MINIMALE MENGE Anforderung',
    4723: 'Diese Änderung wird alle künftige Aufträge beeinflussen.',
    4724: 'DIE ÄNDERUNG KONNTE NICHT GESPEICHERT WERDEN!',
    4725: 'Minimale Menge geändert zu: ',
    4726: 'Minimale Menge muss ein nicht Null',
    4727: '-',
    4728: '-',
    4729: '-',
    4730: '-',
    4731: '-',
    4732: '-',
    4733: '-',
    4734: '-',
    4735: '-',
    4736: 'Wartungszeitplaner',
    4737: 'Element zufügen',
    4738: 'Element konfigurieren',
    4739: 'Wartungserinnerungsintervall (Tagen):',
    4740: 'Neue Log starten',
    4741: 'Sind Sie sicher dass Sie neue Wartungslog starten möchten?',
    4742: '-',
    4743: 'Log wählen',
    4744: '-',
    4745: 'Element wählen',
    4746: '-',
    4747: '-',
    4748: 'Aufgabe:',
    4749: 'Es kann nicht gespeichert werden',
    4750: 'Keine Änderungen zu speichern!',
    4751: '-',
    4752: 'Das Element nach Kalibrierung rückstellen',
    4753: 'Sind Sie sicher dass Sie Wartungszeitplan für diese Element ändern möchten?',
    4754: 'Sind Sie sicher dass Sie diese Element von Wartungszeitplan entfernen möchten ?',
    4755: '-',
    4756: '-',
    4757: '-',
    4758: '-',
    4759: '-',
    4760: 'Verbrauch',
    4761: 'Zeit',
    4762: 'Arbeiten',
    4763: 'Abgelaufen',
    4764: '-',
    4765: '-',
    4766: '-',
    4767: '-',
    4768: 'Wartungserinnerung',
    4769: 'Wartung ist für folgende Elemente(n) erforderlich:',
    4770: 'Wartung durchgeführt',
    4864: 'Wartungslog',
    4865: 'Max Arbeiten:',
    4866: 'Max Verbrauch:',
    4867: 'Max Zeit:',
    4868: 'Arbeiten',
    4869: 'Tagen',
    4870: '-',
    4871: '-',
    4872: 'ALLES',
    4873: '-',
    4874: '-',
    4875: 'ABGELAUFEN',
    4876: 'BEMERKUNG',
    4877: 'KONFIGURATION geändert.',
    4878: 'WARTUNG durchgeführt.',
    4879: 'ENTFERNT: Element wird von Zeitplan entfernt.',
    4880: 'Die Elemente',
    4881: 'Element wurde abgelaufen.',
    4882: 'Element wurde nicht abgelaufen.',
    4883: 'Arbeiten abgelaufen',
    4884: 'Verbrauch abgelaufen',
    4885: 'Zeit abgelaufen',
    4886: 'Arbeiten vor Ablauf:',
    4887: 'Arbeiten seit Ablauf:',
    4888: 'Verbrauch vor Ablauf:',
    4889: 'Verbrauch seit Ablauf:',
    4890: 'Zeit vor Ablauf:',
    4891: 'Zeit seit Ablauf :',
    4892: '-',
    4893: '*** BESCHÄDIGTE LINIE ***',
    4894: 'KALIBRIERUNG durchgeführt.',
    4895: 'Element wird  nach Kalibrierung rückgestellt',
    4896: '**************',
    4897: 'BESCHREIBUNG DER AUFGABE',
    4898: '-',
    4899: '-',
    4900: '-',
    4901: '-',
    4902: '-',
    4903: '-',
    4904: '-',
    4905: '-',
    4906: '-',
    4907: '-',
    4908: '-',
    4909: '-',
    4910: '-',
    4911: '-',
    4912: '-',
    4913: '-',
    4914: '-',
    4915: '-',
    4916: '-',
    4917: 'VERSATZ durchgeführt.',
    4918: 'Operation unvollendet!',
    4919: 'UNBERÜCKSICHTIGT: Benutzer hat der Wartungserinnerung nicht berücksichtigt.',
    4920: 'Benutzer hat eine neue Logdatei gestartet.',
    4921: '-',
    4922: '-',
    4923: '-',
    4924: '-',
    4925: '-',
    4926: '-',
    4927: '-',
    4992: 'Select Curve',
    4993: 'Pen Width',
    4994: 'Pen Style',
    4995: 'Symbol Style',
    4996: 'Line Color',
    4997: 'Symbol Color',
    4998: 'Canvas Color',
    4999: 'Pens',
    5000: 'Save PDF',
    5001: 'Save in Color',
    5002: 'Save With White Canvas',
    5003: 'Error!  Plot took too much time and was aborted.',
    5004: 'Error saving chart pen configuration!',
    32767: 'Computer Watchdog Enabled',
    32768: 'Hilfe',
    32769: 'Inhalt',
    32770: 'Über...',
    32771: 'Communication Timeout',
    32772: 'Cannot Load User Profiles.  Contact Nitrex.',
    32773: 'Computer Watchdog Has Failed.',
    32774: 'Configuration Error.  Application will not work properly.',
    32775: 'System Down',
    32776: 'Minutes',
    32777: 'Log Dateien exportieren',
    32778: 'Log Dateipflege',
    32781: 'Prozess Ersteller',
    32782: 'Dienstprogramm',
    32783: 'Datensicherung Konfig',
    32784: 'Alarme',
    32785: 'Detailmodus',
    32786: 'Prozess laden',
    32787: 'Ändern',
    32788: 'Prozess Starten',
    32789: 'Abbrechen',
    32790: 'Prozess Wiederaufnahme',
    32791: 'Ofenmodus',
    32792: 'RT Grafik',
    32793: 'Use Step Markers',
    32794: 'Konfig wiederherstellen',
    32795: 'System aktualisieren',
    32796: 'Kalibrierung',
    32797: 'Versätze',
    32798: 'Prozess verändert',
    32799: 'Prozessveränderung hat versagt',
    32800: 'Fehler in System Konfig Datei',
    32801: 'Fehler beim EA Objektenerzeugen',
    32802: 'Für Einzelheiten bitte Datei anschauen ',
    32803: 'Fehler beim EA Objekten Paketerzeugen ',
    32804: 'Kalibrierung nur in Wartungsmodus erlaubt',
    32805: 'Diese Vorgang kann zurzeit nicht durchgeführt werden',
    32806: 'Empfangen',
    32807: 'Komponente',
    32808: 'Prozess gestartet von',
    32809: 'Prozess abgebrochen von',
    32810: 'Um den Prozess beladen/zeitlich einplannen',
    32811: 'Kein Prozess im Gange',
    32812: 'Sie haben keine Genehmigung diese Prozess zu verändern',
    32813: 'Um den Prozess anzufangen',
    32814: 'Prozess ist schon zeitlich geplant',
    32815: 'Process Aborted Remotely By',
    32816: 'Versuch den Prozess abzubrechen ist misslungen!',
    32817: 'Fehler beim Prozessstart',
    32818: 'Sind Sie sicher dass Sie diesen Auftrag BEENDEN möchten?',
    32819: 'Es ist nicht sicher diesen Auftrag zu BEENDEN!',
    32820: 'Sind Sie sicher dass Sie Prozess ABBRECHEN möchten?',
    32821: 'Prozess nicht im Gange',
    32822: 'Sind Sie sicher dass Sie den Prozess WIEDERAUFNEHMEN möchten?',
    32823: 'Nicht in Notfallmodus',
    32824: 'Wartung kann aus diesem Modus nicht gestartet werden',
    32825: 'Wiederaufnehmen/Abbrechen nicht möglich',
    32826: 'Systemfehler',
    32827: 'Panelfehler',
    32828: 'Rückstellung',
    32829: 'Panel',
    32830: 'Fehler',
    32831: 'Fernveränderung des Prozesses von',
    32832: 'Der Fernversuch für Prozessveränderung hat versagt',
    32833: 'Prozess wurde fern wiederaufgenommen von',
    32834: 'Der Fernversuch für Prozesswiederaufnahme hat versagt',
    32835: 'Process STOPPED Remotely By',
    32836: 'Delay between two consecutive jobs not elapsed',
    32837: 'Specified job not in chamber.',
    32838: 'Process Stopped By',
    32839: 'Error with chart recorder!',
    32840: 'Missing information for batch!',
    32841: 'The configuration is missing the Model Number or the Serial Number of the furnace!',
    33581: 'There is already a comment saved at this time:',
    33582: 'Are you sure you want to replace it?',
    33583: "Can't create local copy.  Processing original file",
    33584: 'Log Dateipflege',
    33585: 'Fehler beim Kopieren',
    33586: 'Vorgang abgebrochen',
    33587: 'Das Ziellaufwerk überprüfen',
    33588: 'Fehler beim Dateilöschen',
    33589: 'Es kann schreibgeschützt oder unzugänglich sein',
    33590: ' Ist kein gültige Log Datei - Diese Datei wird nicht bearbeitet.',
    33591: ' Kein gültige Log Ordner - Vorgang abgebrochen',
    33592: 'Aktuelle Log datei kann nicht verschoben oder gelöscht werden - Diese Datei wird nicht bearbeitet.',
    33593: 'Dateilöschen...',
    33594: 'Dateikopieren...',
    33595: 'Dateiverschieben...',
    33596: 'Fehler beim Dateikopieren',
    33597: 'Quelldatei kann nicht geöffnet werden ',
    33598: 'Zieldatei kann nicht gemacht werden ',
    33599: 'Fehler beim Dateikopieren ',
    33600: 'Ofenmodus',
    33601: 'Wartungsmodus',
    33602: 'Modus',
    33603: 'Halt',
    33604: 'Restart Turbo Cool',
    33605: 'Restart Turbo?',
    33606: 'Preheat Open Furnace',
    33607: 'Cannot start - furnace should be open',
    33608: 'Nitplanner',
    33609: 'Acknowledge Message',
    33610: 'Configuration Error while creating Gasses-see Ctrl.Err file ',
    33611: 'Cannot restart Turbo Cool',
    33612: 'Turbo Cool suspended',
    33613: 'Turbo Cool disabled',
    33614: 'Error in process setup',
    33615: 'Fatal error in control.',
    33616: 'Fatalfehler',
    33617: 'Diese Auftrag kann nicht wiederaufgenommen werden',
    33618: 'Fehler beim Kontrolsetup - Anwendung wird jetzt abgebrochen - Bitte CTRL.ERR Datei anschauen',
    33619: 'Sicherungskopie der Konfigurationsdateien ',
    33620: 'Fehler in Steuerkreis',
    33621: 'Ofenthermoelement ersetzt mit Hilfsthermoel. TC1',
    33622: 'Ofenthermoelement ersetzt mit Hilfsthermoel. TC2',
    33623: 'Hauptthermoelement zurück ausgetauscht',
    33624: 'Diffusionspumpe kann nicht angelaufen werden',
    33625: 'Ofenlecktest bestanden - LECKGRAD =',
    33626: 'Ofenlecktest durchgefallen - LECKGRAD =',
    33627: 'Beheizung nicht erlaubt - Auftragsabbrechen angefordert',
    33628: 'Control Errors recovered - back to normal ',
    33629: 'Configuration Error - All gasses are disabled ',
    33630: 'Configuration Error - Some analyzers are disabled ',
    33631: 'Configuration Error - All analyzers are disabled ',
    33632: 'Configuration Error - Some Analog Controllers are disabled ',
    33633: 'Configuration Error - Some On/Off Controllers are disabled ',
    33634: 'Cold Standby',
    33635: 'Hot Standby',
    33636: 'Turbo cooling restarted by: ',
    33637: 'Turbo cooling restarted by remote user: ',
    33638: 'Job started by remote user:  ',
    33639: 'Job resumed by remote user: ',
    33640: 'Are you sure you want to preheat open furnace? ',
    33641: 'Preheat open furnace started by: ',
    33642: 'Preheat open furnace started by remote user: ',
    33643: 'Prozess beladen von: ',
    33644: 'Job loaded by remote user: ',
    33645: 'Wartungsmodus ist ein ungeschützter Modus. Möchten Sie wirklich fortfahren?',
    33646: 'Wartungsmodus gestartet von: ',
    33647: 'Maintenance mode started by remote user: ',
    33648: "Sorry, you don't have permission for this operation",
    33649: 'Calibration/Offset requested by: ',
    33650: 'Calibration/Offset requested by remote user:',
    33651: 'Chambers',
    33652: 'Previous chamber',
    33653: 'Next chamber',
    33654: 'Conditions are not met to end this job',
    33655: 'Conditions are not met to transfer the load',
    33656: 'Standby type switched to HOT STANDBY',
    33657: 'Remote User switched standby type to HOT STANDBY',
    33658: 'Standby type switched to COLD STANDBY',
    33659: 'Remote User switched standby type to COLD STANDBY',
    33660: 'Next job enabled in ',
    33661: 'Main GUI',
    33662: 'Do you want to start Purge?',
    33663: 'Job unloaded by: ',
    33664: 'Job unloaded by remote user: ',
    33665: 'Select Hot Standby Recipe',
    33666: 'Cannot get reading from: ',
    33667: 'Downloading file:',
    33668: 'File transfer error',
    33669: 'File transfer connection refused',
    33670: 'File transfer remote host closed',
    33671: 'File transfer host not found',
    33672: 'File transfer timeout',
    33673: 'File transfer operation cancelled',
    33674: 'File transfer content access denied',
    33675: 'File transfer content not found',
    33676: 'File transfer authentication required',
    33677: 'File transfer content conflict',
    33678: 'File transfer content gone',
    33679: 'File transfer internal server error',
    33680: 'File transfer unknown network error',
    33681: 'Restart Controller',
    33682: 'Are you sure you want to restart the controller now?',
    33683: 'Cannot find recipe for this part',
    33684: 'Job loaded by inspector',
    33685: 'This verification code is incorrect.',
    33686: 'Part number not found in file.',
    33687: 'Part has already been loaded.',
    33691: 'Recipients',
    33692: 'Recipient',
    33693: 'Company SMTP setup',
    33694: 'E-mail ',
    33695: 'Event',
    33696: 'Emergency',
    33697: 'Job Aborted',
    33698: 'Job Started',
    33699: 'Job Finished',
    33700: 'System Alarm',
    33701: 'I/O Alarm',
    33702: 'Cannot Write LOG file',
    33703: 'Cannot write process memory file',
    33704: 'Low Disk Space',
    33705: 'System Clock not Set',
    33706: 'Add Recipient',
    33707: 'Remove Recipient',
    33708: 'Clone Recipient',
    33709: 'Add Event',
    33710: 'Clear All Events',
    33711: 'Enable All Events',
    33712: 'Clear Event',
    33713: 'Mobile Connection',
    33714: 'Job Status Event',
    33715: 'All Events',
    33716: 'None',
    33717: 'I/O Type',
    33718: 'SMS Text',
    33719: 'Job Resumed',
    33720: 'ERROR',
    33721: 'Save Failed. At least one of e-mail addresses has to be entered',
    33722: 'Save Failed. Missing SMTP configuration.',
    33723: 'Save Failed. Missing notification events configuration.',
    33724: 'COMMON SMTP',
    33725: 'USER SMTP',
    33726: 'SMTP User Name',
    33727: 'SMTP User Password',
    33728: 'Host Name',
    33729: 'Port Number',
    33730: 'SSL Required',
    33731: 'Are you sure you want to discard the changes?',
    33732: 'Are you sure you want to clear all notifications, for all systems? Changes apply to selected user only',
    33733: 'Are you sure you want to enable all existing notifications for all systems? Changes apply to selected user only',
    33734: 'Recipient already exists. Please select anothe name',
    33735: 'Recipient name requires at least one character',
    33736: 'Recipient Name',
    33737: 'Select a chamber',
    33738: 'No I/O alarms available for the selected system',
    33739: 'Save Failed.',
    33740: 'Saved Successfully.',
    33741: 'Mobile Connection Setting',
    33742: 'Port Number for Mobile Connection (default 443)',
    33743: 'Max. Number of Mobile Connections',
    33744: 'Are you sure you want to delete the recipient?',
    33745: 'Delete all connections?',
    33746: 'Save changes?',
    33747: 'Mirroring',
    33748: 'Path',
    33749: 'Interval (hours)',
    33750: 'Mirroring Configuration',
    33751: 'Invalid interval value',
    33752: 'Configuration',
    33753: 'Mirror all',
    33754: 'There are no systems configured',
    33755: 'There are no systems available',
    33756: 'No systems have been selected',
    33757: 'No systems have mirroring enabled',
    33758: 'Select folder',
    33759: 'The path does not exist or is currently not accessible. Continue and save anyways?',
    33760: "Service enabled ('yes' or 'no')",
    33761: 'Protocol',
    33762: 'MQTT Connection',
    33763: 'SSL/TLS Version',
    33764: 'Private Key File',
    33765: 'Private Key Password',
    33766: 'Keystore File',
    33767: 'Enable Server Certificate Authentication',
    33768: 'Verify Certificates',
    33769: 'Any SSL/TLS',
    33770: 'Server Supports Retained Messages',
    33771: 'Max Payload Size (bytes)',
    33772: 'Max Readings Interval (seconds)',
    33773: 'Enable Auto Export',
    33774: 'Export All Logs',
    33775: 'You need to specify the path for exporting log files',
    33776: 'Export Log Files is disabled automatically when mirroring is disabled',
    33777: 'Log File Access',
    33778: 'Process Memory Access',
    33779: 'Low Disk Space',
    33780: 'System Clock Not Set',
    33781: 'Export Log Files is disabled',
    33782: 'Client ID',
    33783: 'Enable MQTT',
    33784: 'The following required fields are empty',
    33785: 'This Client ID may or may not be accepted by a server.',
    33786: "MQTT servers are required only to accept Client IDs of 1 to 23 characters, with each character one of '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.",
    33787: 'View MQTT status',
    33788: 'View Mobile status',
    33789: 'Database Configuration',
    33790: 'Logging Configuration',
    33800: 'Job Started',
    33801: 'Job Resumed',
    33802: 'Emergency Process Started',
    33803: 'Job Finished',
    33804: 'Job Aborted',
    33805: 'Notifications',
    33820: 'Connected to mobile back end server.',
    33821: 'Disconnected from mobile back end server.',
    33822: 'Connection error with mobile back end server:',
    33850: 'DASHBOARD',
    33851: 'PLANT CONNECTIONS',
    33852: 'ABOUT',
    33853: 'STATUS',
    33854: 'ALARMS',
    33855: 'System ID',
    33856: 'View Alarms',
    33857: 'View Status',
    33858: 'CHART',
    33859: 'RECIPE',
    33860: 'PLANT SELECT',
    33861: 'Refresh',
    33862: 'View Recipe',
    33871: 'Plant Connection Added',
    33872: 'Plant Connection Removed',
    33873: 'DEMO-MODE',
    33874: 'View Chart',
    33875: 'SELECT PLANT',
    33880: 'Trying to connect...',
    33881: 'Logon accepted',
    33882: 'Logon rejected',
    33883: 'Too many users',
    33884: 'Failed to connect',
    33920: 'Die Preheat Oven ',
    33922: 'Watchdog Status ',
    33925: 'Communication',
    33926: 'Dies',
    33927: 'Die Manager',
    33928: 'Queries',
    33930: 'Unable to run while Pack utility is loaded',
    33936: 'Bank',
    33937: 'Oven',
    33938: 'Manual',
    33939: 'Load',
    33940: 'Control',
    33941: 'Oven Temperature',
    33942: 'Low Flow',
    33943: 'High Flow',
    33944: 'Power',
    33945: 'Temperature Setpoint',
    33946: 'Oven Door Status',
    33947: 'Not a valid value!',
    33948: 'Value out of range! Must be between ',
    33949: 'Control Panel Status',
    33950: 'Turn On',
    33951: 'Turn Off',
    33952: 'Standby',
    33953: 'Power On',
    33954: 'Power Off',
    33955: 'N2 High Flow',
    33956: 'N2 Low Flow',
    33957: 'Cannot turn on power - job scheduled!',
    33958: 'Cannot turn off power - dies loaded!',
    33959: 'Cannot switch mode - dies loaded!',
    33960: 'Time elapsed:',
    33961: 'Time remaining:',
    34000: 'loaded',
    34001: 'heating',
    34002: 'soaking',
    34003: 'ready',
    34004: 'over-soaked',
    34005: 'error!',
    34006: 'Wrong configuration - No banks found',
    34007: 'spare',
    34008: 'spare',
    34009: 'spare',
    34016: 'Die',
    34017: 'Invalid data',
    34018: 'Invalid time',
    34019: 'Error reading die recovery file!',
    34048: 'Unload',
    34049: 'Job scheduled for ',
    34050: 'Add Die',
    34051: 'Oven: ',
    34052: 'Bank: ',
    34053: 'Door ENABLED, waiting for door to open...',
    34054: 'Door ENABLED, waiting for door to close...',
    34055: 'Min. Time:',
    34056: 'Max. Time:',
    34057: 'Set Temp.:',
    34058: 'Die Code:',
    34059: 'Delete Die',
    34060: 'Invalid data',
    34061: 'Unable to add cold dies with hot dies',
    34062: 'Unable to remove active die',
    34063: 'New die',
    34064: 'Die status: ',
    34065: 'You have exceeded the maximum allowed dies to be loaded',
    34066: 'List of dies',
    34067: 'Soak temp.',
    34068: 'Min.Soak Time (min)',
    34069: 'Max.Soak Time (min)',
    34070: 'Dies',
    34071: 'Group',
    34073: 'Die not found',
    34074: 'You are trying to add a die that is already being treated',
    34075: "Can't add dies with different temperatures.",
    34076: 'Temperature range:',
    34077: 'Oven set temperatures',
    34078: 'Dies with different set temperatures will be loaded, you must supply the same temperature for all dies.',
    34079: 'Invalid temperature.',
    34080: 'Unable to perform the requested operation while dies are loaded.',
    34081: 'Current Set Temp.',
    34082: 'Operation CANCELLED, waiting for door to close...',
    34083: 'Quick search:',
    34084: 'Die added',
    34085: 'Die already added',
    34086: 'Groups',
    34087: 'Purge',
    34088: 'From date (dd/mm/yyyy):',
    34089: 'To date (dd/mm/yyyy):',
    34090: 'Purging has completed successfully',
    34091: 'Invalid date',
    34092: 'Invalid date range',
    34093: 'Unable to load dies with different minimum time.',
    34094: 'You are currently editing or adding new data, data will not be saved. Continue?',
    34095: 'Some of the database files are missing or the data directory no longer exists, application will continue without database support.',
    34096: 'First record',
    34097: 'Prior record',
    34098: 'Next record',
    34099: 'Last record',
    34100: 'Insert record',
    34101: 'Delete record',
    34102: 'Edit record',
    34103: 'Save record',
    34104: 'Cancel record',
    34105: 'Refresh record',
    34112: 'Inactive',
    34113: 'An unexpected error has occurred, application will now terminate.',
    34114: 'Duplicate record',
    34115: 'Delete ?',
    34116: 'Unable to delete, this group is referenced by other dies',
    34117: 'There is an invalid entry in the field labeled:',
    34118: 'Unable to modify or delete a die that is loaded.',
    34119: 'Group name is required',
    34120: 'Description is required',
    34121: 'Die code is required',
    34122: 'Minimum soak time is out of range. The following range of values are permitted:',
    34123: 'Maximum soak time is out of range. The following range of values are permitted:',
    34124: 'Set temperature is out of range. The following range of values are permitted:',
    34125: 'NOT Ready',
    32126: 'Ready Die',
    32127: 'Oversoaked Die',
    32128: 'Scheduled',
    32129: 'Query Results',
    32130: 'Press ENTER when the die is selected',
    32131: 'Pause',
    32132: 'Reschedule Pause',
    32133: 'System paused until ',
    32134: 'Are you sure you want to pause the system ?',
    32135: 'Set resume time',
    32136: 'Set job start',
    32137: 'Pause the system',
    32138: 'System paused',
    35000: 'Set IP Address',
    35001: 'Timeout [msec]',
    35002: 'Polling Interval [msec]',
    35003: 'IP addresses have been reconfigured',
    35004: 'Are you sure you want to clear selected items?',
    35005: 'Add Slave',
    35006: 'Remove Slave',
    35007: 'Slave #',
    35008: 'Host IP Address',
    35009: 'Channel #',
    35010: 'It is limited to log data of ',
    40000: 'Zone 1 TE',
    40001: 'Zone Thermoelement 1',
    40002: 'Zone 2 TE',
    40003: 'Zone Thermoelement 2',
    40004: 'Zone 3 TE',
    40005: 'Zone Thermoelement 3',
    40006: 'Zone 4 TE',
    40007: 'Zone Thermoelement 4',
    40008: 'Zone 5 TE',
    40009: 'Zone Thermoelement 5',
    40010: 'Zone 6 TE',
    40011: 'Zone Thermoelement 6',
    40012: 'Zone 7 TE',
    40013: 'Zone Thermoelement 7',
    40014: 'Zone 8 TE',
    40015: 'Zone Thermoelement 8',
    40016: 'Ofen TE',
    40017: 'Inneres Hauptthermoelement',
    40018: 'Ofen2 TE',
    40019: 'Innere Thermoelement Zweite',
    40020: 'Ofen3 TE',
    40021: 'Innere Thermoelement Dritte',
    40022: 'Auf Ladung TE',
    40023: 'Auf Ladung Thermoelement 0',
    40024: 'Auf Ladung 1',
    40025: 'Auf Ladung Thermoelement 1',
    40026: 'Auf Ladung 2',
    40027: 'Auf Ladung Thermoelement 2',
    40028: 'In Ladung TE',
    40029: 'In Ladung Thermoelement 0',
    40030: 'In Ladung 1',
    40031: 'In Ladung Thermoelement 1',
    40032: 'In Ladung 2',
    40033: 'In Ladung Thermoelement 2',
    40034: 'Diff.Druck',
    40035: 'Differential Druck Haupt',
    40036: 'Diff.Druck2',
    40037: 'Differentail Druck 2.',
    40038: 'Abs.Druck',
    40039: 'Absolute Druckmessumformer Haupt',
    40040: 'Abs.Druck2',
    40041: 'Vakuum Druckmessumformer 2.',
    40042: 'N2 Fluss',
    40043: 'Stickstoff Massendurchflussmesser',
    40044: 'N2 Fluss 2',
    40045: 'MFC1B Stickstoff',
    40046: 'N2 Fluss 3',
    40047: 'MFC1C Stickstoff',
    40048: 'N2 Fluss 4',
    40049: 'MFC1D Stickstoff',
    40050: 'NH3 Fluss ',
    40051: 'Ammoniak Massendurchflussmesser',
    40052: 'NH3 Fluss 2',
    40053: 'MFC2B Ammoniak',
    40054: 'NH3 Fluss 3',
    40055: 'MFC2C Ammoniak',
    40056: 'NH3 Fluss 4',
    40057: 'MFC2D Ammoniak',
    40058: 'dNH3 Fluss ',
    40059: 'Dissozierte Ammoniak Massendurchflussmesser',
    40060: 'dNH3 Fluss 2',
    40061: 'MFC3B Diss.Ammoniak',
    40062: 'dNH3 Fluss 3',
    40063: 'MFC3C Diss.Ammoniak',
    40064: 'dNH3 Fluss 4',
    40065: 'MFC3D Diss.Ammoniak',
    40066: 'CO2 Fluss ',
    40067: 'MFC4A CO2 oder C tragende Gasen',
    40068: 'CO2 Fluss 2',
    40069: 'MFC4B CO2 oder C tragende Gasen',
    40070: 'CO2 Fluss 3',
    40071: 'MFC4C CO2 oder C tragende Gasen',
    40072: 'CO2 Fluss 4',
    40073: 'MFC4D CO2 oder C tragende Gasen',
    40074: 'Luftdurchfluss ',
    40075: 'MFC5A Luft',
    40076: 'Luftdurchfluss 2',
    40077: 'MFC5B Luft',
    40078: 'Luftdurchfluss 3',
    40079: 'MFC5C Luft',
    40080: 'Luftdurchfluss 4',
    40081: 'MFC5D Luft',
    40082: 'Endofluss',
    40083: 'MFC6A Endo',
    40084: 'Endofluss2',
    40085: 'MFC6B Endo',
    40086: 'Endofluss3',
    40087: 'MFC6C Endo',
    40088: 'Endofluss4',
    40089: 'MFC6D Endo',
    40090: 'H2O Fluss',
    40091: 'MFC7A Wasser',
    40092: 'H2O Fluss 2',
    40093: 'MFC7B Wasser',
    40094: 'H2O Fluss 3',
    40095: 'MFC7C Wasser',
    40096: 'H2O Durchfluss 4',
    40097: 'MFC7D Wasser',
    40098: 'MFC8A Reserve',
    40099: 'MFC8A Reserve',
    40100: 'MFC8B Reserve',
    40101: 'MFC8B Reserve',
    40102: 'MFC8C Reserve',
    40103: 'MFC8C Reserve',
    40104: 'MFC8D Reserve',
    40105: 'MFC8D Reserve',
    40106: 'H2 Analys.',
    40107: 'H2 Analysator Haupt',
    40108: 'H2 Anal.2',
    40109: 'H2 Analysator Zweite',
    40110: 'CO Analys.',
    40111: 'CO Analysator',
    40112: 'CO2 Analys',
    40113: 'CO2 Analysator',
    40114: 'O2 Analys.',
    40115: 'O2 Analysator',
    40116: 'Th. Waage',
    40117: 'Thermowaage Sensor',
    40118: 'BH 1',
    40119: 'Begleitheizung TC 1',
    40120: 'BH 2',
    40121: 'Begleitheizung  TC 2',
    40122: 'BH 3',
    40123: 'Begleitheizung  TC 3',
    40124: 'BH 4',
    40125: 'Begleitheizung  TC 4',
    40126: 'Probe. Fl.',
    40127: 'Durchfluss der Probennahme',
    40128: 'Tot. Fluss',
    40129: 'Gesamter Fluss',
    40130: 'Ofen N2',
    40131: 'Ofen Atmosphäre N2',
    40132: 'Ofen NH3',
    40133: 'Ofen Atmosphäre NH3',
    40134: 'Ofen dNH3',
    40135: 'Ofen Atmosphäre dissozierte NH3',
    40136: 'Ofen CO2',
    40137: 'Ofen Atmosphäre CO',
    40138: 'Ofen Luft',
    40139: 'Ofen Atmosphäre Luft',
    40140: 'Ofen Endo',
    40141: 'Ofen Atmosphäre ENDO',
    40142: 'Ofen H2O',
    40143: 'Ofen Atmosphäre Gas 7',
    40144: 'Ofen Gas 8',
    40145: 'Ofen Atmosphäre Gas 8',
    40146: 'Eintritt N2',
    40147: 'Eintritt Atmosphäre N2',
    40148: 'Eintritt NH3',
    40149: 'Eintritt Atmosphäre NH3',
    40150: 'Eintritt dNH3',
    40151: 'Eintritt Atmosphäre dissozierte NH3',
    40152: 'Eintritt CO2',
    40153: 'Eintritt Atmosphäre CO',
    40154: 'Eintritt Luft',
    40155: 'Eintritt Atmosphäre Luft',
    40156: 'Eintritt Endo',
    40157: 'Eintritt Atmosphäre ENDO',
    40158: 'Eintritt H2O',
    40159: 'Eintritt Atmosphäre Gas 7',
    40160: 'Eintritt Gas8',
    40161: 'Eintritt Atmosphäre Gas 8',
    40162: 'NH3 Sensor',
    40163: 'Ammoniak Sensor 1',
    40164: 'NH3 Sensor2',
    40165: 'Ammoniak Sensor 2',
    40166: 'Abkühlgefälle',
    40167: 'Gefälle bei der Abkühlung',
    40168: 'Heizsteigung',
    40169: 'Steigung beim Heizen',
    40170: 'Stufenanz.',
    40171: 'Anzahl der Stufen',
    40172: 'Kn',
    40173: 'Nitrierkennzahl',
    40174: 'Kn2',
    40175: 'Nitrierkennzahl 2',
    40176: 'Kc',
    40177: 'Kohlenstoffkennzahl',
    40178: 'Ko',
    40179: 'Sauerstoffkennzahl',
    40180: 'Stufenzeit',
    40181: 'Stufenzeit',
    40182: 'Gespültes Volumen',
    40183: 'IO Panel Zustand',
    40184: 'Neutralisator TC',
    40185: 'Neutralisator Thermoelement',
    40186: 'Comp Fehler',
    40187: 'Computer System Fehler',
    40188: 'Auftragszeit',
    40189: 'Voraussichtliche Auftragszeit',
    40190: 'Notfall',
    40191: 'Kode der Notfallstufe',
    40192: 'Diff Temp',
    40193: 'Unterschied zwischen in-Ladung/auf-Ladung Temperatur',
    40194: 'Ofen.Temp.',
    40195: 'Ofen Temperatur',
    40196: 'Ofen Druck',
    40197: 'Ofen Druck',
    40198: 'Max.S.Zeit',
    40199: 'Maximum Stufezeit',
    40200: 'Vak.in Pumpensys.',
    40201: 'Vakuum in Pumpensystem',
    40202: 'Schalle Temp',
    40203: 'Ofen Schallentemperatur',
    40204: 'Tür Temp',
    40205: 'Ofen Türtemperatur',
    40206: 'I1[A]',
    40207: 'Heizungsstrom 1',
    40208: 'I2[A]',
    40209: 'Heizungsstrom 2',
    40210: 'I3[A]',
    40211: 'Heizungsstrom 3',
    40212: 'N2 Dew',
    40213: 'Stickstoff Taupunkt',
    40214: 'Öl Temp.',
    40215: 'Diffusionspumpe Öl Temperatur',
    40216: 'Reserve',
    40217: 'Reserve',
    40218: 'H2 Test',
    40219: 'H2 Sensor 3',
    40220: 'Probe.Temp',
    40221: 'Probennahmedurchfluss Temperatur',
    40222: 'Notf.N2 Durchfluss',
    40223: 'Stickstoff Notfalldurchfluss',
    40224: 'Reserve',
    40225: 'Reserve',
    40226: 'Hoch Spülung',
    40227: 'Stickstoff hoch Spülungdurchfluss',
    40228: 'Niedrige Spülung',
    40229: 'Stickstoff niedrige Spülungdurchfluss',
    40230: 'Absch. Druck',
    40231: 'Druck in der Abschreckkammer',
    40232: 'Wasser Temp',
    40233: 'Abschreckwasser Temperatur',
    40234: 'Nf',
    40235: 'Nitriding Factor - Translate',
    40236: 'Reserve',
    40237: 'Reserve',
    40238: 'Reserve',
    40239: 'Reserve',
    40240: 'SCR1',
    40241: 'Strom SCR1 UNTEN',
    40242: 'SCR2',
    40243: 'Strom SCR2',
    40244: 'SCR3',
    40245: 'Strom SCR3',
    40246: 'SCR4',
    40247: 'Strom SCR4',
    40248: 'SCR5',
    40249: 'Strom SCR5',
    40250: 'SCR6',
    40251: 'Strom SCR6',
    40252: 'SCR7',
    40253: 'Strom SCR7',
    40254: 'SCR8',
    40255: 'Strom SCR8 OBEN',
    40256: 'N2 Sollwert',
    40257: 'Sollwert für Stickstoff Massendurchflussmesser',
    40258: 'N2 SW2',
    40259: 'MFC1B Stickstoff',
    40260: 'N2 SW3',
    40261: 'MFC1C Stickstoff',
    40262: 'N2 SW4',
    40263: 'MFC1D Stickstoff',
    40264: 'NH3 SW',
    40265: 'Sollwert für Ammoniak Massendurchflussmesser',
    40266: 'NH3 Sollwert',
    40267: 'MFC2B Ammoniak',
    40268: 'NH3 SW3',
    40269: 'MFC2C Ammoniak',
    40270: 'NH3 SW4',
    40271: 'MFC2D Ammoniak',
    40272: 'dNH3 SW',
    40273: 'Sollwert für dissozierte Ammoniak Massendurchflussmesser',
    40274: 'dNH3 SW2',
    40275: 'MFC3B Diss.Ammoniak',
    40276: 'dNH3 SW3',
    40277: 'MFC3C Diss.Ammoniak',
    40278: 'dNH3 SW4',
    40279: 'MFC3D Diss.Ammoniak',
    40280: 'CO2 SW',
    40281: 'MFC4A CO2 oder C tragende Gasen',
    40282: 'CO2 SW2',
    40283: 'MFC4B CO2 oder C tragende Gasen',
    40284: 'CO2 SW3',
    40285: 'MFC4C CO2 oder C tragende Gasen',
    40286: 'CO2 SW4',
    40287: 'MFC4D CO2 oder C tragende Gasen',
    40288: 'Luftdurchfluss',
    40289: 'MFC5A Luft',
    40290: 'Luft SW2',
    40291: 'MFC5B Luft',
    40292: 'Air SW3',
    40293: 'MFC5C Luft',
    40294: 'Air SW4',
    40295: 'MFC5D Luft',
    40296: 'Endo SW',
    40297: 'MFC6A Endo',
    40298: 'Endo SW2',
    40299: 'MFC6B Endo',
    40300: 'Endo SW3',
    40301: 'MFC6C Endo',
    40302: 'Endo SW4',
    40303: 'MFC6D Endo',
    40304: 'H2O SW',
    40305: 'MFC7A Wasser',
    40306: 'H2O SW2',
    40307: 'MFC7B Wasser',
    40308: 'H2O SW3',
    40309: 'MFC7C Wasser',
    40310: 'H2O SW4',
    40311: 'MFC7D Wasser',
    40312: 'CO SP',
    40313: 'MFC8A Reserve',
    40314: 'CO-B SP',
    40315: 'MFC8B Reserve',
    40316: 'CO-C SP',
    40317: 'MFC8C Reserve',
    40318: 'CO-D Set Point',
    40319: 'MFC8D Reserve',
    40320: 'Abgas',
    40321: 'Abgasventil',
    40322: 'Reserve',
    40323: 'Reserve',
    40324: 'Gebläse',
    40325: 'Gasgebläse Geschwindigkeit',
    40326: 'Ferne Sollwert',
    40327: 'Ferne Sollwert',
    40328: 'Reserve',
    40329: 'Reserve',
    40330: 'Reserve',
    40331: 'Reserve',
    40332: 'Reserve',
    40333: 'Reserve',
    40334: 'UH Temp',
    40335: 'Überhitzung Temperatur',
    40336: 'Total Durchfluss',
    40337: 'Total Durchflussausgabe (Potential ktrl)',
    40338: 'Atm. KnCtrl',
    40339: 'Atmosphäre stellen(Pot.ktrl)',
    40340: 'Atm.NH3',
    40341: 'Atmosphäre stellen NH3',
    40342: 'Atm. dNH3',
    40343: 'Atmosphäre stellen NH3DISS',
    40344: 'Atm. CO',
    40345: 'Atmosphäre stellen CO',
    40346: 'Atm. Air',
    40347: 'Atmosphäre stellen Luft',
    40348: 'Atm. ENDO',
    40349: 'Atmosphäre stellen ENDO',
    40350: 'Atm. Gas 7',
    40351: 'Atmosphäre stellen Gas 7',
    40352: 'Atm. CO',
    40353: 'Atmosphäre stellen Gas 8',
    40354: 'Reserve',
    40355: 'Reserve',
    40356: 'Temp. SP',
    40357: 'Temperatur SP zu Kontroller',
    40358: 'M2 Geschwindigkeit',
    40359: 'Innenturbine Geschwindigkeit',
    40360: 'Reserve',
    40361: 'Turbo Cool Valve',
    40362: 'Filt.Bypassed',
    40363: 'Filters Bypassed',
    40364: 'Filter Press.',
    40365: 'Differential Pressure on Filter',
    40366: 'Inj. Process.',
    40367: 'SS Injection Process Selected',
    40368: 'FLTR EXH.',
    40369: 'Filter Exhaust Enabled',
    40370: 'Reg. Process',
    40371: 'Regular Process Selected',
    40372: 'MAIN EXH.',
    40373: 'MAIN Exhaust Enabled',
    40440: 'Deckel verriegelt',
    40441: 'Ofendeckel Zustand',
    40442: 'Strom',
    40443: 'Stromausfall',
    40444: 'H2O',
    40445: 'Wasser Schalter',
    40446: 'Neutralisator',
    40447: 'Neutralisator Zustand',
    40448: 'Phase',
    40449: 'Phasenüberwachung',
    40450: 'Schalter 1',
    40451: 'Abgesperrte Schalter 1 Master',
    40452: 'Schalter 2',
    40453: 'Abgesperrte Schalter 2',
    40454: 'Kühlgebläse',
    40455: 'Abkühlungsmotor Untergeschwindigkeit',
    40456: 'Innenturbine',
    40457: 'Innenturbine Untergeschwindigkeit',
    40458: 'M3 entdecken.',
    40459: 'M3 Turbo Abkühl Mot. Untergeschwindigkeit',
    40460: 'Vakuumpumpe',
    40461: 'M4 Vakuumpumpe Untergeschwindigkeit',
    40462: 'Motoren',
    40463: 'Motor(en) Überlast Alarm',
    40464: 'Turbo Ventilen',
    40465: 'Turbo Ventilen Alarm',
    40466: 'SCR Überhitzung.',
    40467: 'SCR Überhitzung Zustand',
    40468: 'Turbo Bypass',
    40469: '2 Ofen: Zweite Ofen leer',
    40470: 'Press.SW ',
    40471: '2 Ofen: Druck Schalter',
    40472: 'Turbo Pressure',
    40473: '2 Ofen: Innere Tür Zustand',
    40474: 'NH3 Sensor',
    40475: 'Ammoniak Sensor 1',
    40476: 'NH3 Sensor2',
    40477: 'Ammoniak Sensor 2',
    40478: 'Turbo IN CLSD',
    40479: 'Reserve',
    40480: 'Reserve',
    40481: 'Reserve',
    40482: 'Reserve',
    40483: 'Reserve',
    40484: 'Luft HFVent. AUF',
    40485: 'Luft Hinterfüllungsventil geöffnet',
    40486: 'Luft HFVent.  ZU',
    40487: 'Luft Hinterfüllungsventil geschlossen',
    40488: 'Not-Aus',
    40489: 'Notfallstop',
    40490: 'UDSchlt',
    40491: 'Überdruckschalter',
    40492: 'Tot.Zeit',
    40493: 'Total Auftragszeit',
    40494: 'Ofen Überhitz.',
    40495: 'Ofen Überhitzung',
    40496: 'Kühler Druck',
    40497: 'Druck der Abkühlungsflüssigkeit',
    40498: 'WT OBEN',
    40499: 'Wärmetauscher Oben Zustand',
    40500: 'WT UNTEN',
    40501: 'Wärmetauscher UNTEN Zustand',
    40502: 'Q3 KW-Umleitung',
    40503: 'Abkühlungswasser Umleitung in NC Kammer',
    40504: 'Q1 KW-Umleitung',
    40505: 'Abkühlungswasser Umleitung in FC Kammer',
    40506: 'Reserve',
    40507: 'Reserve',
    40508: 'Ladung in Q1',
    40509: 'Ladung in Kühlkammer',
    40510: 'D1 AUF',
    40511: 'Kühlkammer AUF',
    40512: 'D1 ZU',
    40513: 'Kühlkammer ZU',
    40514: 'Kette in Q1',
    40515: 'Förderungkette in Q1',
    40516: 'Beladen Sperre',
    40517: 'Umkehrbare Beladen Sperre',
    40518: 'Entladen Sperre',
    40519: 'Umkehrbare Entladen Sperre',
    40520: 'Beladen in Q3',
    40521: 'Beladen in Q3',
    40522: 'D2/D3 AUF',
    40523: 'Tür D2/D3 AUF',
    40524: 'D2/D3 ZU',
    40525: 'Zustand Tür D2/D3 geschlossen',
    40526: 'Kette in Q3',
    40527: 'Kette in Q3',
    40528: 'Reserve',
    40529: 'Reserve',
    40530: 'Reserve',
    40531: 'Reserve',
    40532: 'Q1 Hochdruck',
    40533: 'Hochdruck Kühlkammer',
    40534: 'Tür öffnen',
    40535: 'Anforderung alle Türen zu öffnen',
    40536: 'Tür schliessen',
    40537: 'Anforderung alle Türen zu schliessen',
    40538: 'Reserve',
    40539: 'Reserve',
    40540: 'SCR1',
    40541: 'Strom SCR1 Bottom',
    40542: 'SCR2',
    40543: 'Strom SCR2',
    40544: 'SCR3',
    40545: 'Strom SCR3',
    40546: 'SCR4',
    40547: 'Strom SCR4',
    40548: 'SCR5',
    40549: 'Strom SCR5',
    40550: 'SCR6',
    40551: 'Strom SCR6',
    40552: 'SCR7',
    40553: 'Strom SCR7',
    40554: 'SCR8',
    40555: 'Strom SCR8',
    40556: 'Heizung',
    40557: 'Heizungselemente',
    40558: 'Not-N2',
    40559: 'Not-Stickstoff Solenoidventil',
    40560: 'N2',
    40561: 'Sticktoff Solenoidventil',
    40562: 'VS1B',
    40563: 'Ventil VS1B Stickstoff',
    40564: 'VS1C',
    40565: 'Ventil VS1C Stickstoff',
    40566: 'VS1D',
    40567: 'Ventil VS1D Stickstoff',
    40568: 'NH3',
    40569: 'Ammoniak Solenodiventil ',
    40570: 'VS2B',
    40571: 'Ventil VS2B Ammoniak',
    40572: 'VS2C',
    40573: 'Ventil VS2C Ammoniak',
    40574: 'VS2D',
    40575: 'Ventil VS2D Ammoniak',
    40576: 'dNH3',
    40577: 'Dissozierte Ammoniak Solenoidventil',
    40578: 'VS3B',
    40579: 'Ventil VS3B Diss.Ammoniak',
    40580: 'VS3C',
    40581: 'Ventil VS3C Diss.Ammoniak',
    40582: 'VS3D',
    40583: 'Ventil VS3D Diss. Ammoniak',
    40584: 'VS4A',
    40585: 'Ventil VS4A CO2 oder C tragend',
    40586: 'VS4B',
    40587: 'Ventil VS4B CO2 oder C tragend',
    40588: 'VS4C',
    40589: 'Ventil VS4C CO2 oder C tragend',
    40590: 'VS4D',
    40591: 'Ventil VS4D CO2 oder C tragend',
    40592: 'VS5A',
    40593: 'Ventil VS5A Luft',
    40594: 'VS5B',
    40595: 'Ventil VS5B Luft',
    40596: 'VS5C',
    40597: 'Ventil VS5C Luft',
    40598: 'VD5D',
    40599: 'Ventil VS5D Luft',
    40600: 'VS6A',
    40601: 'Ventil VS6A Endo',
    40602: 'VS6B',
    40603: 'Ventil VS6B Endo',
    40604: 'VS6C',
    40605: 'Ventil VS6C Endo',
    40606: 'VS6D',
    40607: 'Ventil VS6D Endo',
    40608: 'VS7A',
    40609: 'Ventil VS7A Wasser',
    40610: 'VS7B',
    40611: 'Ventil VS7B Wasser',
    40612: 'VS7C',
    40613: 'Ventil VS7C Wasser',
    40614: 'VS7D',
    40615: 'Ventil VS7D Wasser',
    40616: 'VS8A',
    40617: 'Ventil VS8A Reserve',
    40618: 'VS8B',
    40619: 'Ventil VS8B Reserve',
    40620: 'VS8C',
    40621: 'Ventil VS8C Reserve',
    40622: 'VS8D',
    40623: 'Ventil VS8D Reserve',
    40624: 'VS11',
    40625: 'Ventil VS11 Haupt Abgas',
    40626: 'Hilfsabgas',
    40627: 'Hilfsabgas Solenoidventil',
    40628: 'VS13',
    40629: 'Ventil VS13 Dritte Abgas - Freisetzung',
    40630: 'Ofen Vak-Ein',
    40631: 'Vakuum Solenoidventil: Gas Eintrittsleitung',
    40632: 'Ofen Vak-Aus',
    40633: 'Vakuum Solenoidventil: Abgasleitung',
    40634: 'Vakuumventil',
    40635: 'Vakuum Solenoidventil Eintritt',
    40636: 'Gas Ballast',
    40637: 'Vakuum Solenoidventil: Gas Ballast',
    40638: 'VS19',
    40639: 'Ventil VS19 Vakuum: hoch Notfall',
    40640: 'Probenahme',
    40641: 'Probenahme Solenoidventil',
    40642: 'VS22',
    40643: 'Ventil VS22 CO/CO2 Analysator',
    40644: 'VS23',
    40645: 'Ventil VS23 O2 Analysator',
    40646: 'VS9',
    40647: 'Ventil VS9  Spüllungdissoziator',
    40648: 'VS24',
    40649: 'Ventil VS24O Turbo Drossel-Ventil AUF',
    40650: 'VS24',
    40651: 'Ventil VS24C                       ZU',
    40652: 'Kühlgebläse',
    40653: 'Kühlgebläsemotor',
    40654: 'Innere Turbine',
    40655: 'Innere Turbine Hochgeschwindigkeitsmotor',
    40656: 'Innere Turbine',
    40657: 'Innere Turbine Niedergeschwindigkeitmotor',
    40658: 'M3',
    40659: 'M3 Turbo Kühlmotor',
    40660: 'Vakuumpumpe',
    40661: 'Vakuumpumpenmotor',
    40662: 'M5',
    40663: 'M5 Hydraulischepumpe',
    40664: 'M6',
    40665: 'M6 Reserve',
    40666: 'M7',
    40667: 'Ventil/Pumpe M7 Einspritz 1  - ONC',
    40668: 'Wirkstoff',
    40669: 'Wirkstoff Einspritzpumpe / Ventil',
    40670: 'M9',
    40671: 'Ventil/Pumpe M9 Einspritz 3',
    40672: 'Kühlwasser',
    40673: 'Kühlwasser Ventil/Pumpe',
    40674: 'Abg.Umleitung',
    40675: 'Abgas Umleitung',
    40676: 'Schn.Kühl',
    40677: 'Schell Kühlung',
    40678: 'Reserve',
    40679: 'Reserve',
    40680: 'Licht Alarm',
    40681: 'Licht Alarm',
    40682: 'Ton Alarm',
    40683: 'Ton Alarm',
    40684: 'Auftragsablauf',
    40685: 'Auftrag im Ablauf',
    40686: 'Einsatzbereit',
    40687: 'Ofen in Bereitschaftsmodus',
    40688: 'Abgas Diss.',
    40689: 'Abgas Dissoziator',
    40690: 'Neutralisator',
    40691: 'Neutralisator',
    40692: 'Wiederanlauf N.',
    40693: 'Wiederanlauf Neutralisator',
    40694: 'Dissoz.',
    40695: 'Prozess Dissoziator',
    40696: 'BH',
    40697: 'Begleitheizung',
    40698: 'BH1',
    40699: 'Begleitheizung Zone 1',
    40700: 'BH2',
    40701: 'Begleitheizung Zone 2',
    40702: 'BH3',
    40703: 'Begleitheizung Zone 3',
    40704: 'BH4',
    40705: 'Begleitheizung  Zone 4',
    40706: 'Deckel frei',
    40707: 'Deckelfreigabe ',
    40708: 'HeizProb',
    40709: 'Heizung Probennahmekasten',
    40710: 'VS16',
    40711: 'VS16 Spülung-Kammer von Nitrokarburieren',
    40712: 'Reserve',
    40713: 'Reserve',
    40714: 'WT OBEN',
    40715: 'Signal Wärmetauscher OBEN',
    40716: 'WT UNTEN',
    40717: 'Signal Wärmetauscher UNTEN',
    40718: 'VSW3',
    40719: 'VSW3 Külwasserventil in Q3',
    40720: 'Reserve',
    40721: 'Reserve',
    40722: 'VS10',
    40723: 'VS10-Kühlkammer vorspannen',
    40724: 'VS11-hoch',
    40725: 'VS11 Hoch Spülung alle Kammern',
    40726: 'VS15-niedrig',
    40727: 'VS15-Q2/Q3 Niederspülung',
    40728: 'VS12-Q1/Q2',
    40729: 'VS12 - Q1/Q2 Verbindung',
    40730: 'VS13-Q2/Q3',
    40731: 'VS13 Q2/Q3 Verbindung',
    40732: 'VS14 zu Atm.',
    40733: 'VS14 Abgas zu Atmosphäre',
    40734: 'N2 Hinterf.',
    40735: 'Stickstoff Hinterfüllungsventil',
    40736: 'Reserve',
    40737: 'Reserve',
    40738: 'Q1 Kühl EIN',
    40739: 'Schnellkühlung in Q1 EIN',
    40740: 'VSW1',
    40741: 'VSW1 Abschrecken Haupt Kühlwasserventil',
    40742: 'VSW2',
    40743: 'VSW2 Abschrecken Hilfskühlwasserventil',
    40744: 'Reserve',
    40745: 'Reserve',
    40746: 'Reserve',
    40747: 'Reserve',
    40748: 'D1 ÖFFNEN ',
    40749: 'Signal D1 Tür ÖFFNEN',
    40750: 'D1 SCHLIESSEN ',
    40751: 'Signal D1 Tür SCHLIESSEN',
    40752: 'D2/D3 ÖFFNEN',
    40753: 'Signal D2/D3 Tür ÖFFNEN ',
    40754: 'D2/D3 SCHLIESSEN',
    40755: 'Signal D2/D3 Tür SCHLIESSEN',
    40756: 'Kette forw.',
    40757: 'Förderkette forwärts',
    40758: 'Kette zurückziehen',
    40759: 'Förderkette zurückziehen',
    40760: 'Beladen Sperre',
    40761: 'Umkehrbar Beladen Sperre',
    40762: 'Entladen Sperre',
    40763: 'Umkehrbar Entladen Sperre',
    40764: 'Reserve',
    40765: 'Reserve',
    40766: 'ONC Abwasser',
    40767: 'ONC Abwasserventil',
    40768: 'Luft Hinterf',
    40769: 'Luft Hinterfüllung Ventil',
    40770: 'Kühltor',
    40771: 'Kühltor',
    40772: 'Reserve',
    40773: 'Reserve',
    40774: 'NH3 Leck',
    40775: 'Ammoniakleck Hilfsrelais 1',
    40776: 'CH4 Leck',
    40777: 'Methanleck Hilfsrelais 2',
    40778: 'Hilf 3',
    40779: 'Hilfrelais 3',
    40780: 'Auftragsende',
    40781: 'Auftrag kann beendet werden',
    40782: 'Reserve',
    40783: 'Reserve',
    40784: 'Reserve',
    40785: 'Reserve',
    40786: 'Reserve',
    40787: 'Reserve',
    40788: 'Reserve',
    40789: 'Reserve',
    40790: 'Reserve',
    40791: 'Reserve',
    40792: 'Reserve',
    40793: 'Reserve',
    40794: 'Reserve',
    40795: 'Reserve',
    40796: 'Reserve',
    40797: 'Reserve',
    40798: 'Reserve',
    40799: 'Reserve',
    40800: 'Nied Fluss 1',
    40801: 'Niedrige Durchfluss 1',
    40802: 'Ho Fluss 1',
    40803: 'Hoch Durchfluss 1',
    40804: 'Panel 1',
    40805: 'Panel Zustand 1',
    40806: 'Temp 1A',
    40807: 'Ofen 1A Temp',
    40808: 'Nied Fluss 1A',
    40809: 'Niedrige Durchfluss 1A',
    40810: 'Ho Fluss 1A',
    40811: 'Hoch Durchfluss 1A',
    40812: 'Temp 1B',
    40813: 'Ofen 1B Temp',
    40814: 'Nied Fluss 1B',
    40815: 'Niedrige Durchfluss 1B',
    40816: 'Ho Fluss 1B',
    40817: 'Hoch Durchfluss 1B',
    40818: 'Temp 1C',
    40819: 'Ofen 1C Temp',
    40820: 'Nied Fluss1C',
    40821: 'Niedrige Durchfluss 1C',
    40822: 'Ho Fluss1C',
    40823: 'Hoch Durchfluss 1C',
    40824: 'Nied Fluss 2',
    40825: 'Niedrige Durchfluss 2',
    40826: 'Ho Fluss 2',
    40827: 'Hoch Durchfluss 2',
    40828: 'Panel 2',
    40829: 'Panel Status 2',
    40830: 'Temp 2A',
    40831: 'Ofen 2A Temp',
    40832: 'Nied Fluss 2A',
    40833: 'Niedrige Durchfluss 2A',
    40834: 'Nied Fluss 2B',
    40835: 'Hoch Durchfluss 2A',
    40836: 'Temp 2B',
    40837: 'Ofen 2B Temp',
    40838: 'Nied Fluss 2B',
    40839: 'Niedrige Durchfluss 2B',
    40840: 'Ho Fluss2B',
    40841: 'Hoch Durchfluss 2B',
    40842: 'Temp 2C',
    40843: 'Ofen 2C Temp',
    40844: 'Beladen',
    40845: 'Ofen Beladen',
    40846: 'Ho Fluss2C',
    40847: 'Hoch Durchfluss 2C',
    40848: 'Nied Fluss 3',
    40849: 'Niedrige Durchfluss 3',
    40850: 'Ho Fluss 3',
    40851: 'Hoch Durchfluss 3',
    40852: 'Panel 3',
    40853: 'Panel Status 3',
    40854: 'Temp 3A',
    40855: 'Ofen 3A Temp',
    40856: 'Zurücksetzen',
    40857: 'Wirkstoffzahler zurücksetzen',
    40858: 'Ho Fluss 3A',
    40859: 'Hoch Durchfluss 3A',
    40860: 'Temp 3B',
    40861: 'Ofen 3B Temp',
    40862: 'Nied Fluss 3B',
    40900: 'H2 Flow',
    40901: 'MFC9A Hydrogen Flow',
    40902: 'H2-B Flow',
    40903: 'MFC9-B Hydrogen Flow',
    40904: 'H2-C Flow',
    40905: 'MFC9-C Hydrogen Flow',
    40906: 'H2-D Flow',
    40907: 'MFC9-D Hydrogen Flow',
    40908: 'CH4 Flow',
    40909: 'MFC10-A Methane Flow',
    40910: 'CH4-B Flow',
    40911: 'MFC10-B Methane Flow',
    40912: 'CH4-C Flow',
    40913: 'MFC10-C Methane Flow',
    40914: 'CH4-D Flow',
    40915: 'MFC10-D Methane Flow',
    40916: 'C3H8 Flow',
    40917: 'MFC11-A Propane Flow',
    40918: 'C3H8-B Flow',
    40919: 'MFC11-B Propane Flow',
    40920: 'C3H8-C Flow',
    40921: 'MFC11-C Propane Flow',
    40922: 'C3H8-D Flow',
    40923: 'MFC11-D Propane Flow',
    40924: 'N2O Flow',
    40925: 'MFC12-A Nitrous Flow',
    40926: 'N2O-B Flow',
    40927: 'MFC12-B Nitrous Flow',
    40928: 'N2O-C Flow',
    40929: 'MFC12-C Nitrous Flow',
    40930: 'N2O-D Flow',
    40931: 'MFC12-D Nitrous Flow',
    40932: 'CH3OH Flow',
    40933: 'MFC13-A Methanol Flow',
    40934: 'CH3OH-B Flow',
    40935: 'MFC13-B Methanol Flow',
    40936: 'CH3OH-C Flow',
    40937: 'MFC13-C Methanol Flow',
    40938: 'CH3OH-D Flow',
    40939: 'MFC13-D Methanol Flow',
    40940: 'C2H2 Flow',
    40941: 'MFC14-A Acetylene Flow',
    40942: 'C2H2-B Flow',
    40943: 'MFC14-B Acetylene Flow',
    40944: 'C2H2-C Flow',
    40945: 'MFC14-C Acetylene Flow',
    40946: 'C2H2-D Flow',
    40947: 'MFC14-D Acetylene Flow',
    40948: 'C2H6 Flow',
    40949: 'MFC15-A Ethane Flow',
    40950: 'C2H6-B Flow',
    40951: 'MFC15-B Ethane Flow',
    40952: 'C2H6-C Flow',
    40953: 'MFC15-C Ethane Flow',
    40954: 'C2H6-D Flow',
    40955: 'MFC15-D Ethane Flow',
    40956: 'Spare1 Flow',
    40957: 'MFC16-A Flow',
    40958: 'Spare1-B Flow',
    40959: 'MFC16-B Flow',
    40960: 'Spare1-C Flow',
    40961: 'MFC16-C Flow',
    40962: 'Spare1-D Flow',
    40963: 'MFC16-D Flow',
    41000: 'H2',
    41001: 'H2 Cut-off Valve',
    41002: 'H2-B',
    41003: 'H2-B Cut-off Valve',
    41004: 'H2-C',
    41005: 'H2-C Cut-off Valve',
    41006: 'H2-D',
    41007: 'H2-D Cut-off Valve',
    41008: 'CH4',
    41009: 'Methane Cut-off Valve',
    41010: 'CH4-B',
    41011: 'Methane-B Cut-off Valve',
    41012: 'CH4-C',
    41013: 'Methane-C Cut-off Valve',
    41014: 'CH4-D',
    41015: 'Methane-D Cut-off Valve',
    41016: 'C3H8',
    41017: 'Propane Cut-off Valve',
    41018: 'C3H8-B',
    41019: 'Propane-B Cut-off Valve',
    41020: 'C3H8-C',
    41021: 'Propane-C Cut-off Valve',
    41022: 'C3H8-D',
    41023: 'Propane-D Cut-off Valve',
    41024: 'N2O',
    41025: 'Nitrous Cut-off Valve',
    41026: 'N2O-B',
    41027: 'Nitrous-B Cut-off Valve',
    41028: 'N2O-C',
    41029: 'Nitrous-C Cut-off Valve',
    41030: 'N2O-D',
    41031: 'Nitrous-D Cut-off Valve',
    41032: 'CH3OH',
    41033: 'Methanol Cut-off Valve',
    41034: 'CH3OH-B',
    41035: 'Methanol-B Cut-off Valve',
    41036: 'CH3OH-C',
    41037: 'Methanol-C Cut-off Valve',
    41038: 'CH3OH-D',
    41039: 'Methanol-D Cut-off Valve',
    41040: 'Erhitzer 1A',
    41041: 'Heizung 1A',
    41042: 'Erhitzer 1B',
    41043: 'Heizung 1B',
    41044: 'Erhitzer 1C',
    41045: 'Heizung 1C',
    41046: 'Erhitzer 2A',
    41047: 'Heizung 1A',
    41048: 'Erhitzer 2B',
    41049: 'Heizung 1B',
    41050: 'Erhitzer 2C',
    41051: 'Heizung 1C',
    41052: 'Erhitzer 3A',
    41053: 'Heizung 3A',
    41054: 'Erhitzer 3B',
    41055: 'Heizung 3B',
    41056: 'Erhitzer 3C',
    41057: 'Heizung 3C',
    41058: 'Erhitzer 4A',
    41059: 'Heizung 4A',
    41060: 'Erhitzer 4B',
    41061: 'Heizung 4B',
    41062: 'Erhitzer 4C',
    41063: 'Heizung 4C',
    41100: 'TürC 1A',
    41101: 'Tür 1A geschlossen',
    41102: 'TürC 1B',
    41103: 'Tür 1B geschlossen',
    41104: 'TürC 1C',
    41105: 'Tür 1C geschlossen',
    41106: 'TürO 1A',
    41107: 'Tür 1A geöffnet',
    41108: 'TürO 1B',
    41109: 'Tür 1B geöffnet',
    41110: 'TürO 1C',
    41111: 'Tür 1C geöffnet',
    41112: 'Reserve 1',
    41113: 'Reserve',
    41114: 'Reserve 1',
    41115: 'Reserve',
    41120: 'Hyd Übrld 1',
    41121: 'Hydra. Motor Überladung 1',
    41122: 'Heiz Schlt 1',
    41123: 'Erhitzer Schalter Alarm 1',
    41124: 'Überhitzung 1',
    41125: 'Überhitzung 1',
    41126: 'Hyd Druck 1',
    41127: 'Niedrige Druck 1',
    41128: 'Reserve 1',
    41129: 'Reserve',
    41130: 'Reserve 1',
    41131: 'Reserve',
    41132: 'HoFluAl 1',
    41133: 'High Flow zu niedrig 1',
    41134: 'LoFloAl 1',
    41135: 'Niedrige Flow zu niedrig 1',
    41600: 'Reserve',
    41601: 'Reserve',
    41602: 'Reserve',
    41603: 'Reserve',
    41604: 'Reserve',
    41605: 'Reserve',
    41606: 'Reserve',
    41607: 'Reserve',
    41608: 'Reserve',
    41609: 'Reserve',
    41610: 'Reserve',
    41611: 'Reserve',
    41612: 'Reserve',
    41613: 'Reserve',
    41614: 'Reserve',
    41615: 'Reserve',
    41616: 'Reserve',
    41617: 'Reserve',
    41618: 'Reserve',
    41619: 'Reserve',
    41620: 'Reserve',
    41621: 'Reserve',
    41622: 'Reserve',
    41623: 'Reserve',
    41624: 'Reserve',
    41625: 'Reserve',
    41626: 'Klimaanlage',
    41627: 'Klimaanlage Status',
    41628: 'Reserve',
    41629: 'Reserve',
    41630: 'Reserve',
    41631: 'Reserve',
    41632: 'Reserve',
    41633: 'Reserve',
    41634: 'Reserve',
    41635: 'Reserve',
    41636: 'Reserve',
    41637: 'Reserve',
    41638: 'Reserve',
    41639: 'Reserve',
    41640: 'Reserve',
    41641: 'Reserve',
    41642: 'Reserve',
    41643: 'Reserve',
    41644: 'Reserve',
    41645: 'Reserve',
    41646: 'Reserve',
    41647: 'Reserve',
    41648: 'Haupt Vak',
    41649: 'Haupt Vakuum Ventil/Grob Vakuum Valve',
    41650: 'Vak Umleitung',
    41651: 'Umleitung Vakuum Ventil',
    41652: 'Hoch Vak',
    41653: 'Hoch Vakuum Ventil (mit Diff.Pumpe)',
    41654: 'Vornleitung',
    41655: 'Vornleitungventil (mit Diff.Pumpe)',
    41656: 'Gas Ventil',
    41657: 'Gas Ventil',
    41658: 'N2 TDV',
    41659: 'N2 Teildruckventil',
    41660: 'Argon TDV',
    41661: 'Argon Teildruckventil',
    41662: 'Entl Ventil',
    41663: 'Entlüft Ventil',
    41664: 'Halteventil',
    41665: 'Halteventil (mit Diffusionspumpe)',
    41666: 'Dkmp/Ausgl',
    41667: 'Dekomprimierung/Ausgleichventil (Tür Verriegelung)',
    41668: 'Reserve',
    41669: 'Reserve',
    41670: 'Reserve',
    41671: 'Reserve',
    41672: 'Grob 1',
    41673: 'Mechanische/Grobpumpe 1',
    41674: 'Grob 1',
    41675: 'Mechanische/Grobpumpe 1 Gas Ballast Ventil',
    41676: 'Grob 2',
    41677: 'Mechanische/Grobpumpe 2',
    41678: 'Grob 2',
    41679: 'Mechanische/Grobpumpe 2 Gas Ballast Ventil',
    41680: 'Root/Boost',
    41681: 'Roots / Boosterpumpe',
    41682: 'Diff Pumpe',
    41683: 'Diffusionspumpe Erhitzer',
    41684: 'Halt Pmp',
    41685: 'Haltepumpe',
    41686: 'Hyd Pmp',
    41687: 'Hydraulischepumpe',
    41688: 'Reserve',
    41689: 'Reserve',
    41690: 'Reserve',
    41691: 'Reserve',
    41692: 'Konv Vent',
    41693: 'Konvektionsventilator',
    41694: 'Gebläse Ho',
    41695: 'Gebläse - Hohe Geschwindigkeit',
    41696: 'Gebläse',
    41697: 'Gebläse - Niedrige Geschwindigkeit',
    41698: 'Gebläse',
    41699: 'Gebläse - Variable Geschwindigkeit',
    41700: 'Gebläse',
    41701: 'Gebläse',
    41702: 'Reserve',
    41703: 'Reserve',
    41704: 'Obere Stopfen',
    41705: 'Obere Stopfen ÖFFNEN/SCHLIESSEN',
    41706: 'Untere Stopfen',
    41707: 'Untere Stopfen ÖFFNEN/SCHLIESSEN',
    41708: 'Tür Stopfen',
    41709: 'Tür Stopfen ÖFFNEN/SCHLIESSEN',
    41710: 'Hintere Stopfen',
    41711: 'Hintere Stopfen ÖFFNEN/SCHLIESSEN',
    41712: 'Reserve',
    41713: 'Reserve',
    41714: 'Reserve',
    41715: 'Reserve',
    41716: 'Anfrd Abschrecken',
    41717: 'Anforderung für ABSCHRECKEN (zum Wassersystem)',
    41718: 'KWV WTA',
    41719: 'Kühlwasserventil - Wärmetauscher (Abschrecken)',
    41720: 'KWV Schall',
    41721: 'Kühlwasserventil - Ofenschale',
    41722: 'H2O Alm',
    41723: 'Signalwasseralarm (zum Wassersystem)',
    41724: 'H2O ANF',
    41725: 'Kühlwasseranforderung (zum Wassersystem)',
    41726: 'H20 Abschr Alm',
    41727: 'Signal Abschreckenwasseralarm (zum Wassersystem) ',
    41728: 'Tür OFN',
    41729: 'Tür Öffnen Befehl',
    41730: 'Tür SchLS',
    41731: 'Tür schliessen Befehl',
    41732: 'Ofn Tür Klm',
    41733: 'Signal zum Öffnen Türklammer',
    41734: 'SchLS Tür Klm',
    41735: 'Signal zum Schliessen Türklammer',
    41736: 'Ofn Klm Intrlk1',
    41737: 'Signal zum Öffnen Klammerringinterlock 1',
    41738: 'SchLS Klm Intrlk1',
    41739: 'Signal zum Schliessen Klammerringinterlock 1',
    41740: 'Ofn Klm Intrlk2',
    41741: 'Signal zum Öffnen Klammerringinterlock 2',
    41742: 'SchLS Klm Intrlk2',
    41743: 'Signal zum Schliessen Klammerringinterlock 2',
    41744: 'Tür Zyl V',
    41745: 'Tür - Zylinder V Ventil',
    41746: 'Tür Zyl H',
    41747: 'Tür - Zylinder H Ventil',
    41748: 'Tür Sp FrSetz',
    41749: 'Tür Sperre - Freisetz Ventil',
    41750: 'Tür Hyd Öffnung',
    41751: 'Tür Hydraulische Ventil - Öfnung',
    41752: 'Tür Hyd Schliss',
    41753: 'Tür Hydraulische Ventil - Schliessung',
    41754: 'Hyd Pmp',
    41755: 'Hydraulische Pumpe',
    41756: 'Tür Ofn Licht',
    41757: 'Signal zum Tür Öffnung Licht',
    41758: 'Tür Schliss Licht',
    41759: 'Signal zum Tür Schliessung Licht',
    41760: 'Tür Verr Licht',
    41761: 'Signal zum Türverriegelungslicht',
    41762: 'Reserve',
    41763: 'Reserve',
    41764: 'Reserve',
    41765: 'Reserve',
    41766: 'Reserve',
    41767: 'Reserve',
    41768: 'Reserve',
    41769: 'Reserve',
    41770: 'Probefluss EIN',
    41771: 'Probeflusspumpe ein',
    41772: 'Reserve',
    41773: 'Reserve',
    41774: 'Reserve',
    41775: 'Reserve',
    41776: 'Reserve',
    41777: 'Reserve',
    41778: 'Reserve',
    41779: 'Reserve',
    41780: 'Reserve',
    41781: 'Reserve',
    41782: 'Kühl H20 WT',
    41783: 'Kühlwasser durch Wärmetauscher',
    41784: 'Reserve',
    41785: 'Reserve',
    41786: 'Reserve',
    41787: 'Reserve',
    41788: 'Reserve',
    41789: 'Reserve',
    41790: 'Filter bereit',
    41791: 'Reserve',
    41792: 'Reserve',
    41793: 'Reserve',
    41794: 'Reserve',
    41795: 'Reserve',
    41796: 'Reserve',
    41797: 'Reserve',
    41798: 'Reserve',
    41799: 'Reserve',
    41800: 'Reserve',
    41801: 'Reserve',
    41802: 'Reserve',
    41803: 'Reserve',
    41804: 'Reserve',
    41805: 'Reserve',
    41806: 'Reserve',
    41807: 'Reserve',
    41808: 'Reserve',
    41809: 'Reserve',
    41810: 'Reserve',
    41811: 'Reserve',
    41812: 'Reserve',
    41813: 'Reserve',
    41814: 'Reserve',
    41815: 'Reserve',
    41816: 'Reserve',
    41817: 'Reserve',
    41818: 'Reserve',
    41819: 'Reserve',
    41820: 'Reserve',
    41821: 'Reserve',
    41822: 'Reserve',
    41823: 'Reserve',
    41824: 'Reserve',
    41825: 'Reserve',
    41826: 'Reserve',
    41827: 'Reserve',
    41828: 'Reserve',
    41829: 'Reserve',
    41830: 'Reserve',
    41831: 'Reserve',
    41832: 'Reserve',
    41833: 'Reserve',
    41834: 'Reserve',
    41835: 'Reserve',
    41836: 'Reserve',
    41837: 'Reserve',
    41838: 'Reserve',
    41839: 'Reserve',
    41840: 'Reserve',
    41841: 'Reserve',
    41842: 'Reserve',
    41843: 'Reserve',
    41844: 'Reserve',
    41845: 'Reserve',
    41846: 'Reserve',
    41847: 'Reserve',
    41848: 'Reserve',
    41849: 'Reserve',
    41850: 'Reserve',
    41851: 'Reserve',
    41852: 'Reserve',
    41853: 'Reserve',
    41854: 'Reserve',
    41855: 'Reserve',
    41856: 'Reserve',
    41857: 'Reserve',
    41858: 'Reserve',
    41859: 'Reserve',
    41860: 'Reserve',
    41861: 'Reserve',
    41862: 'Reserve',
    41863: 'Reserve',
    41864: 'Reserve',
    41865: 'Reserve',
    41866: 'Reserve',
    41867: 'Reserve',
    41868: 'Reserve',
    41869: 'Reserve',
    41870: 'Reserve',
    41871: 'Reserve',
    41872: 'Reserve',
    41873: 'Reserve',
    41874: 'Reserve',
    41875: 'Reserve',
    41876: 'Reserve',
    41877: 'Reserve',
    41878: 'Reserve',
    41879: 'Reserve',
    41880: 'Reserve',
    41881: 'Reserve',
    41882: 'Reserve',
    41883: 'Reserve',
    41884: 'Reserve',
    41885: 'Reserve',
    41886: 'Reserve',
    41887: 'Reserve',
    41888: 'Reserve',
    41889: 'Reserve',
    41890: 'Reserve',
    41891: 'Reserve',
    41892: 'Reserve',
    41893: 'Reserve',
    41894: 'Reserve',
    41895: 'Reserve',
    41896: 'Reserve',
    41897: 'Reserve',
    41898: 'Reserve',
    41899: 'Reserve',
    41900: 'Reserve',
    41901: 'Reserve',
    41902: 'Reserve',
    41903: 'Reserve',
    41904: 'Reserve',
    41905: 'Reserve',
    41906: 'Reserve',
    41907: 'Reserve',
    41908: 'Reserve',
    41909: 'Reserve',
    41910: 'Reserve',
    41911: 'Reserve',
    41912: 'Reserve',
    41913: 'Reserve',
    41914: 'Reserve',
    41915: 'Reserve',
    41916: 'Reserve',
    41917: 'Reserve',
    41918: 'Reserve',
    41919: 'Reserve',
    41920: 'Vakkumventil geöff',
    41921: 'Haupt Vakkumventil- geöffnet',
    41922: 'Vakuumventil geschl',
    41923: 'Haupt Vakkumventil- geschlossen  ',
    41924: 'Vak UM geöff',
    41925: 'Umleitung Vakuum Ventil - geöffnet ',
    41926: 'Vak UM geschl',
    41927: 'Umleitung Vakuum Ventil - geschlossen ',
    41928: 'HoVak geöff',
    41929: 'Ho Vakuum Ventil - geöffnet (Diff.Pumpe)  ',
    41930: 'HoVak geschl',
    41931: 'Hoch Vakuum Ventil - geschlossen (Diff.Pumpe)  ',
    41932: 'Vornleitung geöff',
    41933: 'Vornleitung Ventil - geöffnet (Diff.Pumpe)  ',
    41934: 'Vornleitung geschl',
    41935: 'Vornleitung Ventil - geschlossen (Diff.Pumpe)  ',
    41936: 'Gas geöff',
    41937: 'Gas Ventil - geöffnet',
    41938: 'Gas geschl',
    41939: 'Gas valve - geschlossen',
    41940: 'TD geöff',
    41941: 'Teildruckventil - geöffnet',
    41942: 'TD geschl',
    41943: 'Teildruckventil - geschlossen',
    41944: 'Entl geöff',
    41945: 'Entlüftungventil - geöffnet ',
    41946: 'Entl geschl',
    41947: 'Entlüftungventil - geschlossen',
    41948: 'Halt geöff',
    41949: 'Haltventil - geöffnet (Diff.Pumpe)',
    41950: 'Halt geschl',
    41951: 'Haltventil - geschlossen (Diff.Pumpe)',
    41952: 'Dekmp/Ausg geöff',
    41953: 'Dekomprimierung/Ausgleichventil geöffnet',
    41954: 'Dekmp/Ausg geschl',
    41955: 'Dekomprimierung/Ausgleichventil geschlossen',
    41956: 'Ventil1 geöff',
    41957: 'Reserveventil 1 geöffnet',
    41958: 'Ventil1 geschl',
    41959: 'Reserveventil 1 geschlossen',
    41960: 'Ventil2 geöff',
    41961: 'Reserveventil 2 geöffnet',
    41962: 'Ventil2 geschl',
    41963: 'Reserveventil 2 geschlossen',
    41964: 'Grob1 Zustand',
    41965: 'Mechanische/Grobpumpe 1 Zustand',
    41966: 'Grob1 Übrld',
    41967: 'Mechanische/Grobpumpe 1 Thermische Überladung Zustand',
    41968: 'Grob2 Zustand',
    41969: 'Mechanische/Grobpumpe 2 Zustand',
    41970: 'Grob2 Überld',
    41971: 'Mechanische/Grobpumpe 2 Thermische Überladung Zustand  ',
    41972: 'Root Ein',
    41973: 'Roots/Booster Pumpe EIN Zustand ',
    41974: 'Root Übrld',
    41975: 'Roots/Booster Pumpe Thermische Überladung Zustand',
    41976: 'Diff Erhitzer',
    41977: 'Diffusionspumpe Erhitzer',
    41978: 'Diff Übrld',
    41979: 'Diffusionspumpe Thermische Überladung Zustand',
    41980: 'Halt Zustand',
    41981: 'Haltpumpe Zustand',
    41982: 'Halt Übrld',
    41983: 'Haltpumpe Thermische Überladung Zustand',
    41984: 'HP Zustand',
    41985: 'Hydraulische Pumpe Zustand ',
    41986: 'HP Übrld',
    41987: 'Hydraulische Pumpe Thermische Überladung Zustand ',
    41988: 'Pumpe1 Zustand',
    41989: 'Reservepumpe 1 Zustand ',
    41990: 'Pump1 Übrld',
    41991: 'Reservepumpe 1 Überladung',
    41992: 'Pump2 Zustand',
    41993: 'Reservepumpe 2 Zustand',
    41994: 'Pump2 Übrld',
    41995: 'Reservepumpe 2 Überladung',
    41996: 'KonvVent Zustand',
    41997: 'Konvektion Ventilator Zustand',
    41998: 'KonvVent Übrld',
    41999: 'Konvektion Ventilator Thermische Überladung Zustand ',
    42000: 'Gebläse Ho',
    42001: 'Kühlung Gebläse Hoch Geschwindigkeit Zustand ',
    42002: 'Gebläse Ni',
    42003: 'Kühlungsgebläse Niedrige Geschwindigkeit Zustand ',
    42004: 'Gebläse Geschwindigkeit',
    42005: 'Kühlungsgebläse Variable Geschwindigkeit Zustand ',
    42006: 'Gebläse Übrld',
    42007: 'Kühlungsgebläse Überladungsschutz',
    42008: 'Gebläse Sicherung',
    42009: 'Kühlungsgebläse Sicherung Zustand ',
    42010: 'Gebläse Übrld',
    42011: 'Kühlungsgebläse Thermische Überladung @LS ',
    42012: 'Gebläse THRM',
    42013: 'Kühlungsgebläse Thermistorschutz @LS/HS',
    42014: 'Gebläse VSD',
    42015: 'Kühlungsgebläse VSD Zustand @ VS',
    42016: 'Gebläse Fehler',
    42017: 'Kühlungsgebläse Sanftanlaut Fehler HS ',
    42018: 'Ventilator1 Zustand',
    42019: 'Reserveventilator 1 Zustand ',
    42020: 'Ventilator1 Übrld',
    42021: 'Reserveventilator 1 Überladung ',
    42022: 'Ventilator2 Zustand',
    42023: 'Reserveventilator 2 Zustand',
    42024: 'Ventilator2 Übrld',
    42025: 'Reserveventilator 2 Überladung',
    42026: 'D < Max',
    42027: 'Ofen Überdruck',
    42028: 'D > 1 Bar',
    42029: 'Druck > 1 bar',
    42030: 'P > 900 mBar',
    42031: 'Druck > 900 mbar',
    42032: 'D > 800 mbar',
    42033: 'Druck > 800 mbar  ',
    42034: 'D > 8 mbar',
    42035: 'Druck > 8 mbar ',
    42036: 'Ofen DS2',
    42037: 'Ofen Druckschalter < 0.9 bar',
    42038: 'D < 1 bar',
    42039: 'Druck < 1 bar',
    42040: 'HD Zustand',
    42041: 'Hydraulischer Druck Zustand',
    42042: 'Luftdruck',
    42043: 'Luftdruck Zustand ',
    42044: 'Reserve',
    42045: 'Reserve',
    42046: 'Ofen UTS',
    42047: 'Ofen Gefäss-Übertemperaturschutz ',
    42048: 'Tür UTS',
    42049: 'Ofen Tür-Übertemperaturschutz',
    42050: 'VRT UTS',
    42051: 'VRT-Übertemperaturschutz',
    42052: 'Zone1 UTS',
    42053: 'Zone 1-Übertemperaturschutz ',
    42054: 'Zone2 UTS',
    42055: 'Zone 2-Übertemperaturschutz',
    42056: 'Zone3 UTS',
    42057: 'Zone 3-Übertemperaturschutz',
    42058: 'Temp TU',
    42059: 'Prozess Temperatur Überhitzung ',
    42060: 'Heiz Schlt',
    42061: 'Heizung Schalter Zustand  ',
    42062: 'Reserve',
    42063: 'Reserve',
    42064: 'Reserve',
    42065: 'Reserve',
    42066: 'Vorne Stopfen geöffn',
    42067: 'Vorne Stopfen ÖFFNEN Zustand ',
    42068: 'Vorne Stopfen geschl',
    42069: 'Vorne Stopfen geschlossen Zustand',
    42070: 'Seite Stopfen geoffn',
    42071: 'Seite Stopfen ÖFFNEN Zustand',
    42072: 'Seite Stopfen(O) geschl',
    42073: 'Seite Stopfen (obere) geschlossen Zustand',
    42074: 'Hintere Stopfen(O) Opnd',
    42075: 'Hintere Stopfen (obere) ÖFFNEN   Zustand',
    42076: 'Seite Stopfen(U) geschl',
    42077: 'Seite Stopfen (Untere) geschlossen Zustand',
    42078: 'Hintere Stopfen(U) geöff',
    42079: 'Hintere Stopfen (Untere) ÖFFNEN Zustand',
    42080: 'Hintere Stopfen geschl',
    42081: 'Hintere Stopfen geschlossen Zustand',
    42082: 'Haupt H2O',
    42083: 'Hauptwasser Druckschalter ',
    42084: 'Gebläse H2O',
    42085: 'Wasser Durchfluss durch Kühlungsgebläse',
    42086: 'KV H2O',
    42087: 'Wasser Durchfluss durch Konvektion Ventilator',
    42088: 'WT H2O',
    42089: 'Wasser Durchfluss durch Wärmetauscher ',
    42090: 'Strom1 H2O',
    42091: 'Wasser Durchfluss durch Einspeisung durch Strom 1',
    42092: 'Strom2 H2O',
    42093: 'Wasser Durchfluss durch Einspeisung durch Strom 2',
    42094: 'Strom3 H2O',
    42095: 'Wasser Durchfluss durch Einspeisung durch Strom 3',
    42096: 'VRT H2O',
    42097: 'Wasser Durchfluss durch VRT ',
    42098: 'Tür H2O',
    42099: 'Wasser Durchfluss durch Ofen Tür',
    42100: 'Schale H2O',
    42101: 'Water Durchfluss durch Ofen Schale',
    42102: 'WT H2O Vntl geöff',
    42103: 'Wärmetauscher Wasser Ventil geöffnet',
    42104: 'WT H2O Vntl geschl',
    42105: 'Wärmetauscher Wasser Ventil geschlossen',
    42106: 'Diff H2O',
    42107: 'Wasser Durchfluss durch Diffusionspumpe',
    42108: 'Reserve',
    42109: 'Reserve',
    42110: 'Tür geschl',
    42111: 'Ofen Tür geschlossen Zustand ',
    42112: 'Tür Klm geschl',
    42113: 'Ofen Türklammer geschlossen Zustand',
    42114: 'Tür Klm geöff',
    42115: 'Ofen Türklammer geöffnet Zustand ',
    42116: 'Sicher Spr1 geöff',
    42117: 'Sicherungssperre 1 - geöffnet ',
    42118: 'Sicher Lck1 geschl',
    42119: 'Sicherungssperre 1 - geschlossen',
    42120: 'Sicher Lck2 geöff',
    42121: 'Sicherungssperre 2 - geöffnet',
    42122: 'Sicher Lck2 geschl',
    42123: 'Sicherungssperre 2 - geschlossen',
    42124: 'Zyl V Tür geöff',
    42125: 'Zylinder V - Tür Öffnen Zustand',
    42126: 'Cyl V Tür geschl',
    42127: 'Cylinder V - Tür geschlossen Zustand',
    42128: 'Zyl H Tür geöff',
    42129: 'Zylinder H - Tür öffnen Zustand',
    42130: 'Zyl H Tür geschl',
    42131: 'Zylinder H - Tür geschlossen Zustand ',
    42132: 'Frstz Vnt Tür Spr',
    42133: 'Freisetzventil - Tür absperren Zustand ',
    42134: 'Frstz Vnt Tür Entspr',
    42135: 'Frstz Ventil - Tür entsperren Zustand',
    42136: 'Öffnen Tür Tst',
    42137: 'ÖFFNEN Tür Taste Zustand',
    42138: 'Tür Sperre Tst',
    42139: 'Tür ABSPERREN Taste Zustand ',
    42140: 'Schliessen Tür Tst',
    42141: 'SCHLIESSEN Tür Taste Zustand',
    42142: 'Frstz Drck Tst',
    42143: 'DRUCKFREISETZEN Taste Zustand',
    42144: 'Ofen Temp',
    42145: 'Ofen Temperature',
    42146: 'Ofen VAK',
    42147: 'Ofenvakuum',
    42148: 'Haupt Ktrl Rel',
    42149: 'Hauptkontrollrelais  ',
    42150: 'DC Spannung',
    42151: 'DC Spannung Zustand',
    42152: 'VAK 1',
    42153: 'Vakuum Umwandler 1',
    42154: 'VAK 2',
    42155: 'Vakuum Umwandler 2',
    42156: 'Reserviert für AI-123',
    42157: 'Reserviert für AI-123',
    42158: 'Reserviert für AI-124',
    42159: 'Reserviert für AI-124',
    42160: 'H2 Sensor',
    42161: 'Wasserstoff Sensor',
    42162: 'Panel Temp',
    42163: 'Temperature innerhalb Panel',
    42164: 'Heiz Status',
    42165: 'Heizung Fehler',
    42166: 'Kühltor ZU',
    42167: 'Kühltor geschlossen',
    42168: 'Wirkstoff niedrig',
    42169: 'Aktivierungswirkstoff niedrig',
    42170: 'Wirkstoff Leck',
    42171: 'Aktivierungswirkstoff Lecksensor',
    42172: 'Dissoz Status',
    42173: 'Dissoziator Heizungsalarm',
    42174: 'Totalisator',
    42175: 'Spülung vollendet',
    42176: 'CH4 Sensor',
    42177: 'Methansensor',
    42178: 'Reserve',
    42179: 'Reserve',
    42180: 'Reserve',
    42181: 'Reserve',
    42182: 'On Load 6',
    42183: 'Reserve',
    42184: 'Reserve',
    42185: 'Reserve',
    42186: 'Reserve',
    42187: 'Reserve',
    42188: 'Reserve',
    42189: 'Reserve',
    42190: 'Reserve',
    42191: 'Reserve',
    42192: 'Reserve',
    42193: 'Cover Seal 1 - P < 45 PSI',
    42194: 'CS2 P<45',
    42195: 'Cover Seal 2 - P < 45 PSI',
    42196: 'CS P<40',
    42197: 'Cover Seals  1&2 - P < 40 PSI',
    42198: 'PowS. overheat',
    42199: 'Power Supply Overheat ',
    42200: 'Payload Purge',
    42201: 'Payload N2 Purge',
    42202: 'Seal 1 isol.',
    42203: 'Seal 1 isolating valve',
    42204: 'Seal 1 defl.',
    42205: 'Seal 1 inflating/deflating valve',
    42206: 'Seal 2 isol.',
    42207: 'Seal 2 isolating valve',
    42208: 'Seal 2 defl.',
    42209: 'Seal 2 inflating/deflating valve',
    42210: 'Ext. Water',
    42211: 'External Water Flow',
    42212: 'Hi.Speed',
    42213: 'Interior turbine - High Speed Feedback',
    42214: 'Low Speed',
    42215: 'Interior Turbine - Low Speed Feedback',
    42216: 'Turbo Purged',
    42217: 'Turbo purge completed',
    42218: 'Free N2 IN',
    42219: 'Independent N2 reading',
    42220: 'Free NH3 IN',
    42221: 'Independent NH3 reading',
    42222: 'Free DISS IN',
    42223: 'Independent DISS NH3 reading',
    42224: 'Free CO2 IN',
    42225: 'Independent CO2 reading',
    42226: 'Free air IN',
    42227: 'Independent air reading',
    42228: 'Free ENDOIN',
    42229: 'Independent ENDO reading',
    42230: 'Free GAS7 IN',
    42231: 'Independent GAS7 NH3 reading',
    42232: 'Free CO IN',
    42233: 'Independent CO reading',
    42234: 'Free N2 OUT',
    42235: 'Independent N2 request',
    42236: 'Free NH3 OUT',
    42237: 'Independent NH3 request',
    42238: 'Free DIS OUT',
    42239: 'Independent DISS NH3 request',
    42240: 'Free CO2 OUT',
    42241: 'Independent CO2 request',
    42242: 'Free AIR OUT',
    42243: 'Independent AIR request',
    42244: 'Free ENDO OUT',
    42245: 'Independent ENDO request',
    42246: 'Free 7 OUT',
    42247: 'Independent GAS7 request',
    42248: 'Free CO OUT',
    42249: 'Independent CO request',
    42250: 'Free N2',
    42251: 'Independent N2 valve',
    42252: 'Free NH3',
    42253: 'Independent NH3 valve',
    42254: 'Free DIS',
    42255: 'Independent DISS NH3 valve',
    42256: 'Free CO2',
    42257: 'Independent CO2 valve',
    42258: 'Free AIR',
    42259: 'Independent AIR valve',
    42260: 'Free ENDO',
    42261: 'Independent ENDO valve',
    42262: 'Free 7',
    42263: 'Independent GAS7 valve',
    42264: 'Free CO',
    42265: 'Independent CO valve',
    42266: 'Furnace Removed',
    42267: 'Start Cooling - remove the furnace',
    42268: 'Furnace Unlocked ',
    42269: 'Unlock the furnace',
    42270: 'Sed. Level',
    42271: 'Sedimentation Tank Level Status',
    42272: 'ONC Waste',
    42273: 'ONC Waste Water Level Status',
    42274: 'Job Start',
    42275: 'Enable Job Start',
    42276: 'Kühltor Offen',
    42277: 'Kühltor Offen',
    42278: 'Bank 1 PS',
    42279: 'Ammonia Station 1',
    42280: 'Bank 2 PS',
    42281: 'Ammonia Station 2',
    42282: 'Water LOW',
    42283: 'Water level LOW ',
    42284: 'Water Heat ',
    42285: 'Water Heating Status ',
    42286: 'Circulating Pump',
    42287: 'Cooling Water Circulating Pump',
    42288: 'UP ',
    42289: 'Elevator UP ',
    42290: 'DOWN ',
    42291: 'Elevator Down ',
    42292: 'Filter Installed',
    42293: 'Install the filter',
    42296: 'Filter Bereit ',
    42297: 'Filter Bereit ',
    42298: 'Filt. verstopft ',
    42299: 'Filter verstopft ',
    42300: 'pp N2',
    42301: 'Partial Pressure N2',
    42302: 'pp NH3',
    42303: 'Partial Pressure NH3',
    42304: 'pp H2',
    42305: 'Partial Pressure H2',
    42306: 'pp O2',
    42307: 'Partial Pressure O2',
    42308: 'pp H2O',
    42309: 'Partial Pressure H2O',
    42310: 'pp CO',
    42311: 'Partial Pressure CO',
    42312: 'pp CO2',
    42313: 'Partial Pressure CO2',
    42314: 'pp CH4',
    42315: 'Partial Pressure CH4',
    42318: 'N2/W',
    42319: 'Nitrogen Weight',
    42320: 'Hot Standby is set in Chamber ',
    42321: 'Set Hot Standby in Chamber ',
    42322: 'P/O No',
    42323: 'Post-Oxidation qualifier',
    42324: '',
    42325: '',
    42326: 'READY TO MOVE the retort ',
    42327: 'Confirm: READY TO MOVE the retort',
    42328: 'Retort is READY FOR COOLING',
    42329: 'Confirm: Retort is READY FOR COOLING ',
    42330: 'Unload Enabled',
    42331: 'Transfer OUT is enabled',
    42332: 'Linear Function',
    42333: 'Timer Function',
    42334: 'Error in percent (instead of reading)',
    42335: 'Logical OR operation',
    42336: 'Logical AND operation',
    42337: 'Logical NOR operation',
    42338: 'Logical NAND operation',
    42340: 'H2S Ambient Temp.',
    42341: 'Analyzer Ambient Temperature',
    42342: 'H2S Block Temp.',
    42343: 'Analyzer Block Temperature',
    42344: 'Raw Flow',
    42345: 'Raw Flow',
    42346: 'Dissipation',
    42347: 'Dissipation',
    42348: 'CComp',
    42349: 'CComp',
    42350: 'Pulse Time',
    42351: 'Pulse Time',
    42352: 'Pump ',
    42353: 'Sampling Pump ',
    42358: 'Sampling',
    42359: 'Enable Sampling',
    42360: 'H2S Pump Control',
    42361: 'H2S Pump Control Signal',
    42362: 'Sampling status',
    42363: 'Sampling enabled status',
    42364: 'H2S Pump Alarm',
    42365: 'H2Smart Pump Alarm Status',
    42366: 'H2S Flow Alarm',
    42367: 'H2Smart Flow Alarm Status',
    42368: 'TCS Alarm',
    42369: 'TCS Thermocouple Alarm Status',
    42370: 'Ri Alarm',
    42371: 'Ri Test Alarm Status',
    42372: 'H2S Pump status',
    42373: 'H2S Pump enabled status',
    42374: 'H2S Block Control',
    42375: 'H2S Block Control',
    42400: 'Turbine',
    42401: 'Turbine',
    42402: 'N2O',
    42403: 'N2O Cut-Off Valve',
    42404: 'N2O-B',
    42405: 'N2O-B Cut-Off Valve',
    42406: 'Filter Blocked',
    42407: 'Filter station blocked',
    42408: 'Filter Bypass',
    42409: 'Filter Bypass Open',
    42410: 'Air Gate',
    42411: 'Air Gate Control (hot side)',
    42412: 'WCS',
    42413: 'Water Cooling Request',
    42414: 'Condens. Valve',
    42415: 'Condensate Valve Request',
    42416: 'HT Filter',
    42417: 'Heat Trace Filter SSR',
    42418: 'HT Enable',
    42419: 'Heat Trace Enable',
    42420: 'HT Sampling',
    42421: 'Heat Trace Sampling SSR ',
    42422: 'HT Exhaust',
    42423: 'Heat Trace Exhaust SSR',
    42424: 'HT AUX',
    42425: 'Heat Trace AUX SSR ',
    42426: 'Steam Gen.',
    42427: 'Steam Generator Enable ',
    42428: 'Steam Flow',
    42429: 'Steam Flow Request ',
    42430: 'Reset Injection',
    42431: 'Reset Injection Totalizer',
    42432: 'Injection',
    42433: 'Injection Enable',
    42434: 'Control Voltage ',
    42435: 'Control Voltage Failure',
    42436: 'Cover Closed',
    42437: 'Cover Closed and Locked',
    42438: 'Purge Complete',
    42439: 'Purge Complete',
    42440: 'ACC Status',
    42441: 'ACC Cooling Status',
    42442: 'ACC Alarm',
    42443: 'ACC Cooling Alarm',
    42444: 'Steam Ready',
    42445: 'Steam Ready',
    42446: 'ONC Water Level',
    42447: 'ONC Water Tank Level',
    42448: 'Exhaust Valve',
    42449: 'Exhaust Valve',
    42450: 'ONC Exhaust Open',
    42451: 'ONC Exhaust Open',
    42452: 'Probe Air Status',
    42453: 'Probe Ref Air Status',
    42454: 'HT Filter',
    42455: 'Heat Trace Filter Status',
    42456: 'HT Exhaust',
    42457: 'Heat Trace Exhaust Status',
    42458: 'HT Sampling',
    42459: 'Heat Trace Sampling Status',
    42460: 'HT AUX',
    42461: 'Heat Trace AUX Status',
    42462: 'N2O SP',
    42463: 'N2O SP',
    42464: 'N2O-B SP',
    42465: 'N2O-B SP',
    42466: 'H2 Sniffer',
    42467: 'H2 Sniffer',
    42468: 'CO Sniffer',
    42469: 'CO Sniffer',
    42470: 'Injection Pressure',
    42471: 'Injection Pressure',
    42472: 'Injection Volume',
    42473: 'Injection Volume',
    42474: 'CO Flow',
    42475: 'CO Gas Flow',
    42476: 'CO Flow-B',
    42477: 'CO Gas Flow-B',
    42478: 'NH3 Sniffer',
    42479: 'NH3 Sniffer',
    42480: 'Agent Pump',
    42481: 'Agent Pump Request',
    42482: 'dNH3 Flow',
    42483: 'Dissociated Ammonia',
    42484: 'dNH3 Flow-B',
    42485: 'Dissociated Ammonia-B',
    42486: 'H2 Flow',
    42487: 'H2 Gas Flow',
    42488: 'H2 Flow-B',
    42489: 'H2 Gas Flow-B',
    42490: 'HT Filter',
    42491: 'Heat Tracing Filter',
    42492: 'HT Sampling',
    42493: 'Heat Tracing Sampling Line',
    42494: 'HT Exhaust',
    42495: 'Heat Tracing Exhaust Line',
    42496: 'HT Aux',
    42497: 'Heat Tracing Auxilary',
    48992: 'Wasserrelais',
    48993: 'Warmezone wasserrelais',
    48994: 'DP Übertemp',
    48995: 'Differentialpumpe Übertemperature',
    48996: '-',
    48997: '-',
    48998: '-',
    48999: '-',
    49000: 'IO Panel',
    49001: 'IO Panel Status',
    49002: 'VLP Panel',
    49003: 'VLP Panel Status',
    49004: 'O2 Probe mV',
    49005: 'O2 Probe mV',
    49006: 'O2 Probe Temp',
    49007: 'O2 Probe Temp',
    49008: 'Blower Ramp',
    49009: 'Blower Control Enable',
    49010: 'Flap Contrl',
    49011: 'Blower Flap Control Enable',
    49012: 'Dryer Satus',
    49013: 'Ammonia Dryer Status',
    49014: 'WCS_ACC Status',
    49015: 'ACC Water System Status',
    49016: 'CPU Temp',
    49017: 'CPU Temperature',
    49018: 'Board Temp',
    49019: 'Controller Board Temperature Sensor',
    49020: 'Board Volts',
    49021: 'Controller Board Voltage Sensor',
    49022: 'Board 3.3V',
    49023: 'Controller Board 3.3V',
    49024: 'Board VBat',
    49025: 'Controller Board Battery Voltage',
    49026: 'Board VCC',
    49027: 'Controller Board VCC',
    50000: 'Probe T',
    50001: 'Probe Temperature',
    50002: 'Furn.T',
    50003: 'Furnace Temperature',
    50004: 'Quench T',
    50005: 'Quenching Temperature',
    50006: 'Zone T',
    50007: 'Zone Temperature',
    50008: 'O2 mV',
    50009: 'Oxygen Probe Signal',
    50010: ' ',
    50011: ' ',
    50012: ' ',
    50013: ' ',
    50014: 'C%',
    50015: 'Carbon',
    50016: 'C-ATM',
    50017: 'C-Atmospheric',
    50018: 'C-Steel',
    50019: 'C-Steel',
    50020: 'C-CH4',
    50021: 'C-CH4',
    50022: 'C-Saturated',
    50023: 'C-Saturated',
    50024: 'Redox',
    50025: 'Redox',
    50026: 'Carbide',
    50027: 'Carbide Limit',
    50028: 'Redox No',
    50029: 'Redox Number',
    50030: 'DPT',
    50031: 'Dew Point Temperature',
    50032: 'Alloy F',
    50033: 'Alloy Factor',
    50034: 'PF',
    50035: 'Process Factor',
    50036: 'CH4 F.',
    50037: 'CH4 Factor',
    50038: 'Factor',
    50039: 'Factor',
    50040: 'Spare',
    50041: 'Spare',
    50042: 'H2 sensor',
    50043: 'H2 sensor reading',
    50044: 'Kn',
    50045: 'Nitriding Potential',
    50046: 'NP',
    50047: 'Nitriding Potential',
    50048: 'KO ',
    50049: 'Oxygen Potential ',
    50050: 'log O2',
    50051: 'log O2',
    50052: ' ',
    50053: ' ',
    50054: ' ',
    50055: ' ',
    50056: ' ',
    50057: ' ',
    50058: ' ',
    50059: ' ',
    50060: ' ',
    50061: ' ',
    50062: ' ',
    50063: ' ',
    50064: 'CO',
    50065: 'CO',
    50066: 'CO2',
    50067: 'CO2',
    50068: 'CH4',
    50069: 'CH4',
    50070: 'H2',
    50071: 'H2',
    50072: 'O2',
    50073: 'O2',
    50074: 'N2',
    50075: 'N2',
    50076: 'NH3',
    50077: 'NH3',
    50078: 'AIR',
    50079: 'AIR',
    50080: 'ENDO',
    50081: 'ENDO',
    50082: '',
    50083: '',
    50084: '',
    50085: '',
    50086: 'Press.',
    50087: 'Pressure',
    50088: 'Flow',
    50089: 'Flow',
    50090: 'Gas',
    50091: 'Gas',
    50092: 'Inlet',
    50093: 'Inlet',
    50094: 'Furnace',
    50095: 'Furnace',
    50096: 'Zone',
    50097: 'Zone',
    50098: 'Ramp+',
    50099: 'Heat Ramp',
    50100: 'Ramp-',
    50101: 'Cooling Ramp',
    50102: 'Ref#',
    50103: 'Reference Number',
    50104: 'Remaining Time',
    50105: 'Remaining Step Time',
    50106: ' ',
    50107: ' ',
    50108: 'Input',
    50109: 'Input - Loop X',
    50110: 'Stage Time',
    50111: 'Stage Time',
    50112: 'Job Time',
    50113: 'Job Time',
    50114: 'Stage',
    50115: 'Stage Number',
    50116: 'Emergency',
    50117: 'Emergency',
    50118: 'IO Card',
    50119: 'I/O Card Status',
    50120: 'Slave',
    50121: 'Slave Status',
    50122: 'Panel',
    50123: 'Panel Status',
    50124: 'Rack',
    50125: 'Brain Board Status',
    50240: 'Out',
    50241: 'Output-Loop ',
    50242: 'Forward',
    50243: 'Forward Output',
    50244: 'Reverse',
    50245: 'Reverse Output',
    50246: 'Heat',
    50247: 'Heating Power',
    50248: 'Cool',
    50249: 'Cooling Signal',
    50250: 'C% out',
    50251: 'C%-direct output',
    50252: 'C-rev.out',
    50253: 'C-reversed out',
    50254: 'DP out',
    50255: 'Dew Point Control Output Signal',
    50256: 'DP out-',
    50257: 'Dew Point Control Output Signal (Reverse)',
    50258: 'O2 Out',
    50259: 'O2 Control Output Signal',
    50260: 'O2 Out-',
    50261: 'O2 Control Output Signal (Reverse)',
    50262: 'Redox Out',
    50263: 'Redox Control Output Signal',
    50264: 'Redox Out',
    50265: 'Redox Control Output Signal (Reverse)',
    50266: 'Quench Out',
    50267: 'Quench Out Control Output Signal',
    50268: 'Quench Out-',
    50269: 'Quench Out Control Output Signal (Reverse)',
    50270: 'H.Breaker',
    50271: 'Heating Breaker',
    50330: 'Remote SP',
    50331: 'Remote Set Point',
    50332: 'Local SP',
    50333: 'Local Set Point',
    50334: 'Act. Value',
    50335: 'Actual Value',
    50336: '% Output',
    50337: 'Percentage Output',
    50338: 'Auto/Man',
    50339: 'Auto/Manual',
    50340: 'Local/Remote',
    50341: 'Local/Remote',
    50342: 'Rem.TimeSP',
    50343: 'Remote Time Set Point',
    50344: 'Auto TimeSP',
    50345: 'Auto Time Set Point',
    50346: 'Time Left',
    50347: 'Remaining Time',
    50348: 'Timer Status',
    50349: 'Timer Status',
    50350: 'Ready',
    50351: 'System Ready',
    50360: '10Pro',
    50362: '10Pro/T',
    50440: ' ',
    50441: ' ',
    50442: 'Power',
    50443: 'Power Status',
    50444: 'Cooling',
    50445: 'Cooling Water',
    50446: 'Door',
    50447: 'Door Status',
    50448: 'Phase',
    50449: 'Phase Monitoring',
    50450: 'Open',
    50451: 'Door Open Status',
    50452: 'Closed',
    50453: 'Door Closed Status',
    50454: 'Open',
    50455: 'Cover Open Status',
    50456: 'Closed',
    50457: 'Cover Closed Status',
    50458: 'Motor',
    50459: 'Motor Underspeed',
    50460: 'Motor',
    50461: 'Motor Overload',
    50462: 'Agitator',
    50463: 'Agitator',
    50464: 'PS',
    50465: 'Pressure Switch',
    50466: 'E-STOP',
    50467: 'Emergency Stop',
    50468: 'Fan',
    50469: 'Circulating Fan',
    50470: 'Open',
    50471: 'Valve Open',
    50472: 'Closed',
    50473: 'Valve Closed',
    50474: 'Job Start',
    50475: 'Job Start Requested',
    50476: 'Atmosphere ON',
    50477: 'Atmosphere On Manual Safety Valve',
    50500: 'Agitator',
    50501: 'Quench Tank Agitator',
    50502: 'Gas',
    50503: 'Gas Valve',
    50680: 'Light',
    50681: 'Light Alarm',
    50682: 'Siren',
    50683: 'Sound Alarm',
    50684: 'Running',
    50685: 'Job Running',
    50686: 'Standby',
    50687: 'In Standby',
    50688: 'Shunt Res',
    50689: 'Shunt Resistor',
    50690: 'Burn Off',
    50691: 'Burn Off Relay',
    50692: 'Ref. Gas',
    50693: 'Reference Gas',
    50900: 'I/O Configuration',
    50901: 'Internal I/O port cannot be opened',
    50902: 'Internal I/O port opened for access',
    50903: 'Internal I/O Driver cannot be initialized',
    50904: 'Slot',
    50905: 'Slot Empty',
    50906: 'DIO_16',
    50907: 'DIO_8',
    50908: 'AI4_AO4',
    50909: 'Digital Board Configuration',
    50910: 'Unable to set I/O as requested',
    50911: 'Slot has been reconfigured',
    50912: 'Point #',
    50913: 'Input',
    50914: 'Output',
    50915: 'Slot set to empty',
    50916: 'Error setting board type',
    50917: 'Slot updated',
    50918: 'Digital Board Test',
    50919: 'Version #',
    50920: 'Revision #',
    50921: 'Serial #',
    50922: 'Unknown Board Type',
    50923: 'Factory Calibration',
    50924: 'Incompatible Firmware',
    50933: 'Analog Board Configuration',
    50934: 'None',
    50935: '0 - 20 mA',
    50936: '4 - 20 mA',
    50937: '0 - 2 VDC',
    50938: '0 - 5 VDC',
    50939: 'Slidewire',
    50940: 'Unknown',
    50941: 'RTD (100-398.48 Ω)',
    50942: 'TC Type J',
    50943: 'TC Type K',
    50944: 'TC Type N',
    50945: 'TC Type S',
    50946: 'TC Type R',
    50947: 'TC Type T',
    50948: 'TC Type E',
    50949: 'TC Type B',
    50950: 'TC Type C',
    50951: 'TC Type M',
    50952: '0 - 24 mA',
    50953: '0 - 10 VDC',
    50954: 'Test Analog Board',
    50955: 'Configure',
    50956: 'Save Version',
    50957: 'Test',
    50958: 'RTD',
    50959: 'TC',
    50960: 'AI8',
    50961: 'Shunt 10 kΩ',
    50975: 'Channel',
    50976: 'Analog Inputs',
    50977: 'Analog Outputs',
    50978: 'Calibrate 0 ',
    50979: 'Calibrate Gain',
    50980: 'Self 0 Calibration',
    50981: 'Set',
    50982: 'Start Logging',
    50983: 'Stop Logging',
    50984: 'Are you ready to perform Zero Calibration?',
    50985: 'Are you ready to perform Full-Scale Calibration?',
    50986: 'Are you sure you want to reset calibration data?',
    50987: 'Are you sure you want to reset ADC for this channel?',
    50988: 'ADC Reset was successful',
    50989: 'Are you sure you want to reset CJ measuring circuit?',
    50990: 'CJ measuring circuit was reset successfully"\\',
    50991: 'Are you sure you want to load default calibration data?',
    50992: 'Are you sure you want to load calibration data saved in EEPROM?',
    50993: 'Are you sure you want to save the data in EEPROM?',
    50994: 'Are you sure you want to revert changes?',
    50995: 'I2C Read/Write Failed',
    50996: 'Please wait.',
    50997: 'Controller is being initialized.',
    50998: '16 =DC DIO',
    50999: '8  ~AC DIO',
    51000: '4 In 4 Out AIO',
    51001: '8 In AIO',
    51002: 'Tap below to add comment',
    51003: 'OFFSET VALUES',
    51004: 'Default Value',
    51005: 'In EEPROM',
    51006: 'GAIN VALUES',
    51007: 'REGISTERS',
    51008: 'In OFC Registers',
    51009: 'In FSC Registers',
    51010: 'Must select input first in order to calibrate',
    51011: 'Please wait while I configure IO ...',
    51012: 'Save calibration data?',
    51013: 'Please wait while configuring the channel to its initial settings...',
    51014: 'Unable to save calibration data',
    51015: 'Saving, please wait ...',
    51016: 'An unexpected error occurred while saving data to EEPROM',
    51017: 'Calibration data saved',
    51018: 'Unable to restore calibration data',
    51019: 'Please connect the source of %1 to slot# %2 channel# %3',
    51020: 'Please select number of samples',
    51021: 'Number of Samples',
    51022: 'Please wait for calibration procedure to complete for slot# %1 channel# %2',
    51023: 'Sample',
    51024: 'Value(Hex)',
    51025: 'Failed to complete the procedure - too many trials for slot# %1 channel# %2',
    51026: 'Slot',
    51027: 'Channel',
    51028: 'Zero Calibration completed successfully for',
    51029: 'ZERO',
    51030: 'Hex',
    51031: 'Failed to set registers with the new Zero Calibration data',
    51032: 'Result',
    51033: 'Failed to set registers with the new Full Scale Calibration data',
    51034: 'Gain Calibration completed successfully for',
    51035: 'zero',
    51036: 'gain',
    51037: 'Failed to complete the procedure - Calibration data out of range for slot# %1 channel# %2',
    51038: 'Resetting calibration data...',
    51039: 'Unable to reset calibration data',
    51040: 'Calibration reset completed successfully',
    51041: 'Please wait while reconfiguring the channel ...',
    51042: 'Unable to configure channel',
    51043: 'Calibration',
    51044: 'Channel Reading',
    51045: 'TC Calibrator',
    51046: 'TC Type',
    51047: 'Signal',
    51048: 'Cold Junction',
    51049: 'Raw Reading',
    51050: 'Calibrated',
    51051: 'Calibration Corrections',
    51052: 'Under Test',
    51053: 'In EEPROM',
    51054: 'Please enter calibrator signal',
    51055: 'CJ Calibration',
    51056: 'Unable to get data from IO',
    51057: 'On board temperature offset set successfully',
    51058: 'Failed to set On Board Temperature offset',
    51059: 'On board temperature calibration failed, offset out of range.',
    51060: 'Invalid offset value',
    51061: 'On board temperature calibration failed, unable to write offset to EEPROM',
    51062: 'START CJ Calibration',
    51063: 'Calibrate Channel',
    51064: 'Please select the channel',
    51065: 'Adjustment of the on-board temperature circuit',
    51066: 'Save version information?',
    51067: 'Version information has been saved for slot',
    51068: 'Version information has not been saved for slot',
    51069: 'Error writing version information to EEPROM.',
    51080: 'Configure Lease Date',
    51081: 'Lease Date',
    51082: 'Saved Date',
    51083: 'Rollback Counter',
    51084: 'Serial Number',
    52324: '',
    52325: '',
    53000: 'English_French_German_Polish_Russian_Italian_Portuguese_Spanish_Chinese_Arabic_Serbian',
    60080: 'Bitte warten bis Datei Operation beendet wird...',
    60081: 'RESERVIERT',
    60082: 'RESERVIERT',
    60083: 'RESERVIERT',
    60084: 'RESERVIERT',
    60085: 'RESERVIERT',
    60086: 'Unerwartete Fehler trat auf',
    60087: 'RESERVIERT',
    60088: 'RESERVIERT',
    60089: 'Operation erfolgreich',
    60090: 'RESERVIERT',
    60091: 'Die folgende',
    60092: 'Einige gelieferte Dateien können nicht gefunden werden. Eine fileop.err Datei wird kreiert',
    60093: 'Operation abgebrochen',
    60144: 'Alarme',
    60146: 'Alarm',
    60147: 'Quittieren',
    60148: 'Auffrischen',
    60150: '%s ist zur Zeit offline',
    60175: 'Zustand der Verbindung  ',
    60192: 'Darstellen/Ausblenden Info',
    60200: 'Es muss in Wartugnsmodus sein',
    60201: 'Analogausgabewert eintragen',
    60204: 'Auftragsnummer',
    60209: 'Analogeingabe',
    60210: 'Sollwert',
    60211: 'Wert',
    60212: 'Analogausgabe',
    60213: 'Digitaleingabe',
    60214: 'Digitalausgabe',
    60215: 'Neue wert:',
    60216: 'Min: ',
    60217: 'Max: ',
    60219: 'Abbrechen',
    60220: 'Wert ausser Bereich.',
    60221: 'Analog',
    60222: 'Digital',
    60223: 'Zugang verweigert',
    60225: 'Nitrex Metal Inc.',
    60226: '3474 Poirier Boulevard',
    60227: 'Saint Laurent',
    60228: 'H4R 2J5  Canada',
    60229: 'Tel.:  514-335-7191',
    60230: 'Fax.: 514-335-4160',
    60231: 'Web:   www.nitrex.com',
    60232: 'Email:  nitrex@nitrex.com',
    60233: 'Unterstützung:  support@nitrex.com',
    60240: 'Meldungen',
    60241: 'Alle Meldungen löschen',
    60242: 'Alle Meldungen löschen?',
    60255: 'Company:',
    60256: 'Systemname:',
    60257: 'Status:',
    60258: 'Prozess:',
    60259: 'Auftragsnummer:',
    60260: 'Stufenname:',
    60261: 'Stufennummer:',
    60262: 'Stufenzeit:',
    60263: 'Prozess Zeit:',
    60264: '-',
    60265: 'Einsatzbereit...',
    60266: 'Prozess im Lauf...',
    60267: 'Wartungsmodus',
    60268: 'Kein Prozess im Lauf',
    60269: 'Fehler',
    60270: 'Unbekannte Status',
    60271: 'Notfallprozedur',
    60272: 'Prozess:',
    60273: 'Geplant für:',
    60274: 'Abbrechen...',
    60275: 'Preheat',
    60276: 'Waiting to receive load',
    60277: 'Waiting to transfer load',
    60278: 'Purge',
    60279: 'Chamber Finished',
    60368: 'erhitzen',
    60369: 'abkühlen',
    60370: 'Laufzeit',
    60371: 'Druck_plus',
    60372: 'Druck_minus',
    60373: 'Vakuum_plus',
    60374: 'Vakuum_minus',
    60375: 'ZeitAbkühlen',
    60376: 'inLadung_Erhitzen',
    60377: 'inLadung_Abkühlen',
    60378: 'aufLadung_Erhitzen',
    60379: 'aufLadung_Abkühlen',
    60380: 'Ofen_Erhitzen',
    60381: 'Ofen_Abkühlen',
    60382: 'N2_plus',
    60383: 'N2_minus',
    60384: 'NH3_plus',
    60385: 'NH3_minus',
    60386: 'dissNH3_plus',
    60387: 'dissNH3_minus',
    60388: 'CO2_plus',
    60389: 'CO2_minus',
    60390: 'Luft_plus',
    60391: 'Luft_minus',
    60392: 'Benutzer',
    60393: 'einsatzbereit',
    60394: 'anwspez_digein',
    60395: 'anwspez_anaein',
    60396: 'zeit_anaein',
    60397: 'sicher_zum_ende',
    60398: '1 Volumen',
    60399: '2 Volumes',
    60400: '5 Volumes',
    60401: 'in_on_diff',
    60500: 'End Condition Value',
    60880: 'Math Model',
    60881: 'Hier klicken zum einloggen',
    60882: 'Nitrex Math Model',
    60883: 'Modelart',
    60884: 'Stahlart',
    60885: 'Grafik berechnen',
    60886: 'Epsilon Schicht',
    60887: 'Gamma Prime Schicht',
    60888: 'Weisse Schicht',
    60889: 'Verbindungsschicht',
    60890: 'Leszek',
    60891: 'Verbindungsschicht auf eine getrennte Achse anzeigen.',
    60892: 'Um andere Schichten ausführilch anzuschauen',
    60893: 'Gewünschte Schicht(en) zur Anzeigen',
    60894: 'Zeit in Stunden',
    60895: 'Bearbeitungsinterface verbergen',
    60896: 'Bearbeitungsinterface darstellen',
    60897: 'Stufen in Prozess Ersteller aktuelisieren',
    60898: 'Warnung',
    60899: 'Der zurzeit beladene Prozess im Prozess Ersteller wird überschrieben !',
    60900: "WS   epsilon   gamma'  [µm]",
    60901: 'Stunden',
    60902: 'Minuten',
    60903: 'Temperatur C',
    60904: 'Temperatur F',
    60905: 'Stage Duration',
    60906: 'Temperature Setpoint',
    60907: 'Potential Setpoint',
    60908: 'Display DL (Vikkers)',
    60909: 'Depth [µm]',
    60910: 'Depth Thou',
    60911: 'Hv',
    60912: 'Eisen',
    60913: 'N135M',
    60914: 'H11',
    60915: '4140',
    60916: '1020',
    60917: 'NC11LV',
    60918: '18HGT',
    60919: '1050',
    60920: '5140',
    60921: '20H',
    60922: '4340',
    60923: 'Armco',
    60924: 'H13',
    60925: '32CDV13',
    60928: 'Display the depth at which specified hardness is achieved. Not all steels have an associated hardness coefficient. This option is not enabled for iron.  ',
    60944: 'Steel Properties',
    60945: 'Display Properties',
    60946: 'Nitplanner Menu',
    60947: 'Properties',
    60948: 'Nitplanner',
    61280: 'Epsilon',
    61281: "Gamma'",
    61282: 'WL',
    61283: 'DL',
    61284: 'Hardness',
    61285: "WL   epsilon   gamma'  [thou]",
    61286: '[thou]',
    61287: '[µm]',
    61288: 'Core hardness',
    61289: 'Metric',
    61290: 'Reference Hardness',
    61296: 'Diese Model behandelt nur NH3 und dissozierte NH3',
    61297: 'Es war ein unvorgesehener Fehler ',
    61298: 'Nitrierungstufen müssen länger dauern',
    61299: 'Sie müssen die Nitrierstufen vorlegen. Ihre Prozess hat keine Nitrierstufen.',
    61300: 'Temperatur ist ausser Bereich und es ist für diese Model ungültig.',
    61301: 'Stufennummer Fehler: ',
    61302: 'Coefficients have not been entered for all steels. Contact Nitrex.',
    61303: 'Potential of first stage must be between %s and %s for the material %s',
    61304: 'Process Setup',
    61305: 'Simulation of Completed Job',
    61306: 'Unknown Metal',
    61307: 'This stage cannot be modified',
    61308: 'Core hardness in HRC. A hardness of 0 will be disregarded and a default value will be used.',
    61309: 'Simulation of Completed Job (popup menu)',
    61310: 'Steel Coefficients could not be loaded. Contact Nitrex.',
    61311: "Click on the 'Calculate' button before using this function.",
    61312: 'Insert Before',
    61313: 'Insert After',
    61314: 'Delete Stage',
    61315: 'Load Log File',
    61316: 'Loading Log File: ',
    61317: 'Stage Name : ',
    61318: 'Log Filename : ',
    61319: 'This log is not compatible with Mathmodel.  ',
    61320: 'One or more stages in this log have been interruped and resumed. ',
    61321: 'Display stage controls',
    61322: 'Nitplanner Menu',
    61323: 'There is an error in the steel configuration file. Please contact Nitrex.',
    61324: 'Could not open file:',
    61325: 'You do not have permission to view this log.',
    61326: 'This log has no useable nitriding data.',
    61327: 'This log is not a valid log of a nitriding process',
    61328: 'Potential is wrong value :',
    61329: 'The duration of the stage cannot be 0. Nitriding stages cannot be less than 6 minutes. Delete the stage if necessary.',
    61330: 'This graph cannot be displayed for this material',
    61331: 'Potential Gradient',
    61332: 'Temperature Gradient',
    61333: 'Current Log Filename',
    61334: 'Current stage name',
    61335: 'This stage cannot be modified',
    61336: 'Cannot modify stages that have completed.  Going to first available stage',
    61337: 'There are no stages to commit.  Press the Add Stage button to create one.',
    61394: 'View Log',
    61409: 'Unknown protocol',
    61412: 'Socket error code',
    61417: 'Connected',
    61418: 'Disconnected',
    61419: 'Unable to access user file.  Using hard coded default user.',
    61420: 'Was able to access user file.',
    61429: 'Host',
    61435: 'The following error occurred while loading gui configuration file:',
    61436: 'is currently not connected',
    62000: 'Production',
    62001: 'Configuration',
    62002: 'Report',
    62003: 'Quote',
    62004: 'Evaluation',
    62005: 'Shop Order',
    62006: 'Shipping',
    62007: 'Invoicing',
    62008: 'Certification',
    62009: 'Customer',
    62010: 'Contact',
    62011: 'Operation Type',
    62012: 'Operation',
    62013: 'Process Flow',
    62014: 'Equipment',
    62015: 'Production Status',
    62016: 'Shop Today',
    62017: 'Company Setup',
    62018: 'Label',
    62019: 'Price List',
    62020: 'Unit',
    62021: 'Requirement',
    62022: 'Operator',
    62023: 'Material',
    62024: 'Currency',
    62025: 'Country',
    62026: 'Language',
    62027: 'Shipping Company',
    62028: 'Equipment Usage',
    62029: 'Shop Management Database System',
    62030: 'Customer Code',
    62031: 'Print Customer Info',
    62032: 'Address',
    62033: 'General Phone',
    62034: 'Select a Customer',
    62035: 'Family Name',
    62036: 'Toll Free Phone',
    62037: 'Middle Name',
    62038: 'General Information',
    62039: 'Contact Detail',
    62040: 'Name',
    62041: 'Representative',
    62042: 'Phone',
    62043: 'Business Type',
    62044: 'Website',
    62045: 'Distance',
    62046: 'Express Delivery Information',
    62047: 'Shipping Account',
    62048: 'Customer Discount',
    62049: 'Fax',
    62050: 'Terms of Payment',
    62051: 'Receiving',
    62052: 'Freight Information',
    62053: 'Business Address',
    62054: 'Additional Information',
    62055: 'Street Address',
    62056: 'Display all enabled customers',
    62057: 'E-Mail',
    62058: 'City',
    62059: 'Province/State',
    62060: 'Postal/Zip Code',
    62061: 'Identifier',
    62062: 'Position',
    62063: 'Department',
    62064: 'Send e-mail',
    62065: 'Access website',
    62066: 'Remark',
    62067: 'Ext.',
    62068: 'Shipping Address',
    62069: 'Same as Business Address',
    62070: 'Invoicing Address',
    62071: 'Mailing Address',
    62072: 'Company Info',
    62073: 'Quote Note',
    62075: 'Shop Order Note',
    62076: 'Certification Note',
    62077: 'Shipping Note',
    62078: 'Invoicing Note',
    62079: 'Part',
    62080: 'General E-mail',
    62081: 'General Fax',
    62082: 'Customer Code already exists.',
    62083: 'Given Name',
    62084: 'Title',
    62085: 'Print Quote',
    62086: 'Detail',
    62087: 'Customer Name',
    62088: 'Quote Code',
    62089: 'Created',
    62090: 'Validity',
    62091: 'Create',
    62092: 'Clone',
    62093: 'Mailing Name',
    62094: 'Quote Item',
    62095: 'Customer Part Number',
    62096: 'Contact already exists.  Try a different Identifier.',
    62097: 'Save Quote',
    62098: 'Finish',
    62099: 'Item',
    62100: 'Specification',
    62101: 'Price Item',
    62102: 'Add',
    62103: 'Up',
    62104: 'Down',
    62105: 'Add/Modify',
    62106: 'Clone to',
    62107: 'Value',
    62108: 'Item Code',
    62109: 'Materials',
    62110: 'Code',
    62111: 'Min. Price',
    62112: 'Save Price List',
    62113: 'Choose a Price Item',
    62115: 'Start Value',
    62116: 'End Value',
    62117: 'Price',
    62118: 'Days',
    62119: 'Months',
    62120: 'Discount',
    62121: 'Unable to modify',
    62122: 'Not all required fields are filled',
    62123: 'Unable to insert record',
    62124: 'Material already exists',
    62125: 'Unable to delete record',
    62126: 'Delete material?',
    62127: 'Operation Types',
    62128: 'Operation type already exists',
    62129: 'Delete operation type',
    62130: 'Equipment already exists',
    62131: 'Delete equipment?',
    62132: 'Please finish editing',
    62133: 'Assignments',
    62134: 'Selected Operation Types',
    62135: 'Available Operation Types',
    62136: 'Operation Group',
    62138: '%',
    62139: 'Pricing ',
    62140: 'Code',
    62141: 'Pricing Policy',
    62142: 'Spec.',
    62143: 'New Item',
    62144: 'Per-Lot Pricing',
    62145: 'Pricing Type',
    62146: 'Lot Price',
    62147: 'Double Click to add a customer',
    62148: 'Double Click to remove a customer',
    62149: 'Part Item: ',
    62150: 'This Quote is Applicable to the Following Part Numbers',
    62151: 'This Quote is Applicable to the Following Process',
    62152: 'We will Process Your Parts to the Following Specification',
    62153: 'Sent To',
    62154: 'Total weight should be greater or equal to weight per piece',
    62155: 'Total weight should be a multiple of the unit weight',
    62156: 'Please enter either unit weight or total weight ',
    62157: 'Please enter either unit weight or total piece count',
    62158: 'Please correct the value for the unit weight',
    62159: 'This quote has expired. Please enter a new Validity Date',
    62162: 'There is no item to be saved',
    62163: 'Quote Changed, are you sure to discard the changes?',
    62164: 'Quote Code is required',
    62165: 'A different Quote Code is required',
    62166: 'Are you sure to Cancel?',
    62167: 'Parameter is required',
    62168: 'Value1 is required',
    62169: 'Value2 is required',
    62170: 'Operator is required',
    62171: 'Eligible Customers',
    62172: 'Approved customers; at least one part is created for the listed customers.',
    62173: 'Pricing per part count is required',
    62174: 'Pricing per weight is required',
    62175: 'Pricing in different currency is required',
    62176: 'Please selct a price list',
    62177: 'Unit is required',
    62178: 'Please select what you want to export:',
    62179: 'All shown customers',
    62180: 'All contacts from the shown customers',
    62181: 'Total Price',
    62182: 'Disable Reason',
    62183: 'Address Type',
    62184: 'Load from Quote',
    62185: 'Qty',
    62187: 'Qty of Containers',
    62188: 'Container',
    62189: 'Gross Weight',
    62190: 'Net Weight',
    62191: 'Selected Part',
    62192: 'Related Quote',
    62193: 'Please Save the current editing part before adding new ones',
    62194: 'NOT USED',
    62195: 'NOT USED',
    62196: 'NOT USED',
    62197: 'Material Code is required',
    62198: 'Referenced',
    62199: 'Approval will make the Received Items available for Shop Orders.',
    62201: 'Inspection has not been completed for the following items:',
    62202: 'Certification Requested',
    62203: 'Print Shop Order',
    62204: 'List',
    62205: 'Shop Order Code',
    62206: 'to',
    62207: 'Priority',
    62208: 'Last Review Date',
    62209: 'Split',
    62210: 'Status',
    62211: 'Show available items',
    62212: 'Gross Weight must be greater than or equal to Net Weight',
    62213: 'Inspection Criteria',
    62214: 'Accept',
    62215: 'Reject',
    62216: 'Rejected Quantity is larger than Received Quantity',
    62217: 'Some Items with no Net Weight or Quantity have been Inspected.  Do you wish to erase the Inspection data?',
    62218: 'Receiving Packing Slip',
    62219: "The change won't be permanent until the Receiving record is saved.",
    62220: 'Remove ?tem',
    62221: 'Are you sure you want to remove this item?',
    62222: 'Select a Part',
    62223: 'Clear selected part',
    62224: 'Save Shop Order',
    62225: 'Shop Order code is required',
    62226: 'A different Shop Order Code is required',
    62227: 'The Shop Order has changed. Are you sure that you want to discard the changes?',
    62229: 'Database busy, please try again',
    62230: 'Show In-Box',
    62231: 'Step Requirement',
    62232: 'Certification Level',
    62233: 'Operation ID',
    62236: 'There are counting errors in the following Received Items:',
    62237: 'Fewer parts have been received than have been rejected or used in Shop Order.',
    62238: 'Number of Samples',
    62239: 'Received by',
    62240: 'This text will be replaced when a Part is selected.',
    62241: 'Cannot use this unit because the quantity is too large!',
    62242: 'Quantity used by Shop Order',
    62243: 'Receiving has been changed.  Are you sure you want to discard the changes?',
    62244: 'Selected parts will be cleared from Items if the Customer is changed.',
    62245: 'Please select a Purchase Order Number',
    62246: 'Could not find a Part for this Customer!',
    62247: 'Could not find a Purchase Order for this Customer!',
    62248: 'This Item cannot be deleted because it is used by Shop Order',
    62249: 'A Part must be selected for this Item before it can go into a Shop Order.',
    62250: 'Rejected Quantity',
    62251: 'You have removed the Shipping Company from the Customer.',
    62252: 'This will also remove the Shipping Account.',
    62253: 'The part you selected is already used in the following item:',
    62254: 'Approval cannot be done because there is an item with no selected part!',
    62255: 'Clone to which address type?',
    62256: 'Are you sure you want to delete this address?',
    62257: "The change won't be permanent until the Customer is saved.",
    62258: 'Are you sure you want to move this address?',
    62260: 'Send Newsletter',
    62261: 'Send Christmas Card',
    62262: 'Other',
    62270: 'Shop Order is using at least one item from this receiving record!',
    62271: 'Modification of quantities for affected items is restricted.',
    62272: 'Removing approval will make remaining quantities unavailable for shop orders.',
    62273: 'Customer Surcharge',
    62274: 'Enabled Customer Surcharge (%)',
    62275: 'Surcharge (%)',
    62276: 'Total Surcharge',
    62338: 'Complete Scheduling',
    62339: 'Qty Left',
    62340: 'Weight Left',
    62341: 'View Specification',
    62342: 'Scheduling No.',
    62343: 'Task Detail',
    62344: 'Availability',
    62345: 'Start Time',
    62346: 'End Time',
    62347: 'Treatement Code',
    62348: 'Job Code',
    62349: 'Operator',
    62350: 'Load No.',
    62351: 'View Step Requirement',
    62352: 'Weight Unit',
    62353: 'No. of Scheduling',
    62354: 'Treatment complete',
    62356: 'Schedule Load',
    62357: 'Save changes for the current Task?',
    62358: 'Save changes for the current Schedule?',
    62359: 'There are no more parts available for you to create a new Schedule',
    62360: 'Are you sure that you would like to delete this Schedule?',
    62361: 'Treatment',
    62363: 'Load No. is required',
    62364: 'Load No. is igored',
    62369: 'NOT USED',
    62370: 'NOT USED',
    62371: 'Scheduled',
    62372: 'In Process',
    62373: 'Certification Completed',
    62375: 'Invoiced/Closed',
    62376: 'Canceled',
    62377: 'Scheduling for this part will also be deleted. Proceed?',
    62378: 'Load Part',
    62379: 'Shop Part Number',
    62380: 'On',
    62381: 'Are you sure that you want to discard changes?',
    62382: 'Customer List',
    62383: 'Customer Detail',
    62384: 'This Customer already exists according to Name and business address',
    62385: 'Are you sure that you would like to delete the record?',
    62386: 'Record can not be locked, it is not selected',
    62387: 'Record can not be edited',
    62388: 'Contact List',
    62389: 'Alt. E-mail',
    62390: 'Alt. Phone',
    62391: 'The Customer has been changed. Are you sure you want to discard the changes?',
    62392: 'Selected Part/Process Flow',
    62393: 'Operation already exists',
    62394: 'Process flow already exists',
    62395: 'Quote changed, save changes?',
    62396: 'One or more selected parts already in the table are not inserted',
    62397: 'Assign Process Flow(s)',
    62398: 'The following Process Flows are referenced and cannot be removed:',
    62399: 'No additional parts available.',
    62400: 'Parts Total Cost',
    62401: 'Misc Total Cost',
    62402: 'Parts?',
    62403: 'Misc.',
    62404: 'The Contact has been changed.  Are you sure you want to discard the changes?',
    62405: 'No quotes available.',
    62406: 'Shop parts with same customer part number and customer code must be of the same material.',
    62407: 'Samples Received',
    62408: 'Modify Flow',
    62409: 'Add Part',
    62410: 'Remove Part',
    62411: 'Approving this shop order will require approval permission for further changes.',
    62412: 'Available Parts',
    62413: 'Process Flow Steps',
    62414: 'Assigned Process Flows',
    62415: 'For multiple selection: Press and hold down <Ctrl> key then click each item',
    62416: 'Quote - Part Selection',
    62417: 'Unable to load shop order.',
    62418: 'You cannot cancel a shop order with active batches.',
    62419: 'There are currently no batches for this shop order.',
    62420: 'Track by Weight',
    62421: 'Pleaes enter a weight or change tracking selection',
    62422: 'Please enter a quantity or change tracking selection',
    62423: 'Available Quantity',
    62424: 'Available Weight',
    62425: 'There are no alternate flows available for this part.',
    62426: 'Invalid quantity and weight',
    62427: 'Step Instruction',
    62428: 'Batch Details',
    62429: 'Modify Batches',
    62430: 'This will remove all batches for the selected shop order. Continue?',
    62431: 'Unable to remove. Process flow is used',
    62432: 'Unable to remove. Operation is used',
    62433: 'Unable to remove. Material is used',
    62434: 'Unable to remove. Parts may be used by invoicing, shipping or lab evaluation',
    62435: 'Unable to remove. This would violate quantities already claimed by the same part in other step(s).',
    62436: 'Unable to remove. Some parts are used in other following lot(s).',
    62437: 'Unable to remove. Lot is used by lab evaluation',
    62438: 'Unable to remove step. Some lots are used by lab evaluation',
    62439: 'Unable to remove. Operation type is used by operation',
    62440: 'Search in column: ',
    62441: 'Auto key is enabled',
    62442: 'Part Name',
    62443: 'The following field must contain a value',
    62444: 'Display all parts',
    62445: 'Display all operations',
    62446: 'Display all process flows',
    62447: 'Display all invoices',
    62448: 'Display all batches',
    62449: 'Unit Weight',
    62450: 'Create batch',
    62451: 'Delete batch',
    62452: 'Add part(s) to batch',
    62453: 'Delete selected part',
    62454: 'Unable to clear. Lot is used by lab evaluation',
    62455: 'Sorry, you do not have permission to save batches.',
    62456: 'Part',
    62457: 'from step',
    62458: 'is not found in the previous step',
    62459: 'Unable to remove end time. Some parts have already been invoiced or shipped.',
    62460: 'A part quantity for the batch is below the quantity already claimed by invoiing or shipping.',
    62461: 'The total quantity of treated parts is below the total quantity already claimed by invoicing or shipping.',
    62462: 'Quantity invoiced:',
    62463: 'Quantity shipped:',
    62464: 'Cust. Part',
    62465: 'Unable to remove. Part is used by lab evaluation.',
    62466: 'Unable to save. All parts have finished treatment and lab evaluation has already performed its final evaluation of these parts.',
    62467: 'Archive',
    62468: 'Are you sure you want to archive this part? Doing so will prevent you from using this part in any new future work.',
    62469: 'Sorry, You need permission to modify lab evaluation in order to do this.',
    62470: 'Sorry, You need permission to modify certification in order to do this.',
    62471: 'The percentages of all assigned categories should sum up to 100%.',
    62472: 'Repeated Category is assigned. Please remove it and save again.',
    62473: 'No Category is available. Please go to Company Info Panel for configuration.',
    62474: 'At least one Category has to be assigned to the Process Flow.',
    62475: 'Category',
    62476: 'Please check the Process Flow Category field. You have the same Category (case-insensitive) entered.',
    62477: 'Batch successfully removed.',
    62495: 'Multiple Selection',
    62496: 'Version Nr.',
    62497: 'Locked For Modifications',
    62498: 'Row :',
    62499: 'Column: ',
    62500: 'Transaction Failed',
    62501: 'Operation Description',
    62502: 'Price List Code',
    62503: 'Pricing Comment',
    62504: 'Pricing Unit',
    62505: 'Operation Price',
    62506: 'Invalid entry for Starting Value',
    62507: 'Invalid entry for Ending Value',
    62508: 'Invalid entry for Unit Price',
    62509: 'Cannot find pricing for requested operation; OPERATION CODE: ',
    62510: 'Please enter the code for Price List ',
    62511: 'Please enter the Operation Code ',
    62512: 'Please select the Currency ',
    62513: 'Please select the Pricing Unit ',
    62514: 'Unit for Pricing is not valid ',
    62515: 'Minimum Price is not set or not valid ',
    62516: 'Price List is not selected ',
    62517: 'Price List is missing Pricing Policy ',
    62518: 'Pricing Policy has gap or ambiguity ',
    62519: 'End Value has to be greater than the Starting Value ',
    62520: 'Cannot find Price List ',
    62521: 'Quantity or Weight has to be specified ',
    62522: 'Currencies do not match, conversion applied ',
    62523: 'Currencies do not match, conversion not supported ',
    62524: 'Pricing units do not match - conversion applied ',
    62525: 'Pricing units do not match - conversion not supported ',
    62526: 'Parameter already exists',
    62527: 'Shop Order part completion',
    62528: 'Currency is not enabled',
    62529: 'Please review and Delete or Cancel',
    62530: 'Custom',
    62531: 'Default Price',
    62532: 'Real Cost',
    62533: 'Old/Disabled Price',
    62534: 'Increase/Decrease Prices',
    62535: 'Change prices by [%] : ',
    62536: 'Pricing Method',
    62537: 'This Operation is ARCHIVED. Please remove it from this Process Flow.',
    62538: 'Please remove/replace all ARCHIVED Operations from this Process Flow.',
    62539: 'Brackets',
    62540: 'Steady Brackets',
    62541: 'Please modify the CODE of the flow, to reflect the new revision.',
    62542: 'Cannot perform this operation: the flow is either archived or modifiable.',
    62543: 'The following parts are updated with this new version of Process Flow: ',
    62544: 'This will create a new version of the Process Flow and update Parts (archived flow will be replaced with the newly created version). Would you like to proceed? ',
    62550: 'Units of Measure',
    62551: 'Groups of Measure',
    62552: 'Percentage Units',
    62553: 'Length Units',
    62554: 'Weight Units',
    62555: 'Volume Units',
    62556: 'Hardness Units',
    62557: 'Superficial Hardness Units',
    62558: '',
    62559: '',
    62560: 'Currencies',
    62561: 'Piece Count',
    62563: 'Temperature Units',
    62564: 'Pressure Units',
    62565: 'Time Units',
    62566: 'Temperature Ramp Units',
    62589: '°C/h',
    62590: 'deg C/hour',
    62591: '°C/min',
    62592: 'deg C/minute',
    62593: '°C/sec',
    62594: 'deg C/second',
    62595: '°F/h',
    62596: 'deg F/hour',
    62597: '°F/min',
    62598: 'deg F/minute',
    62599: '°F/sec',
    62600: 'deg F/second',
    62601: 'mi ',
    62602: 'mile ',
    62603: 'km ',
    62604: 'kilometre ',
    62605: 'm ',
    62606: 'metre ',
    62607: 'ft ',
    62608: 'foot ',
    62609: 'in ',
    62610: 'inch ',
    62611: 'thou ',
    62612: '0.001 in ',
    62613: 'µm ',
    62614: 'micrometre ',
    62615: 't ',
    62616: 'tonne ',
    62617: 'kg ',
    62618: 'kilogram ',
    62619: 'g ',
    62620: 'gram ',
    62621: 'lb ',
    62622: 'pound ',
    62623: 'kgf ',
    62624: 'kilogram force ',
    62625: 'lbf ',
    62626: 'pound force ',
    62627: 'm³ ',
    62628: 'cubic metre ',
    62629: 'barrel ',
    62630: 'barrel ',
    62631: 'gal ',
    62632: 'gallon ',
    62633: 'cu ft ',
    62634: 'cubic feet ',
    62635: 'l ',
    62636: 'litre ',
    62637: 'cu in ',
    62638: 'cubic inch ',
    62639: 'ml ',
    62640: 'mililitre ',
    62641: '% ',
    62642: 'percent ',
    62643: '‰ ',
    62644: 'permille ',
    62645: 'ppm ',
    62646: 'parts per million  ',
    62647: 'pc. ',
    62648: 'piece ',
    62649: 'dz. ',
    62650: 'dozen ',
    62651: 'x 100 ',
    62652: 'hundreds ',
    62653: 'x 1000 ',
    62654: 'thousands ',
    62655: '°C ',
    62656: 'Celsius ',
    62657: '°F ',
    62658: 'Fahrenheit ',
    62659: 'bar',
    62660: 'bar',
    62661: 'psi ',
    62662: 'pound-force per square inch ',
    62663: 'torr ',
    62664: 'mmHg',
    62665: 'mbar ',
    62666: 'mbar ',
    62667: 'mm ',
    62668: 'mm ',
    62669: ' ',
    62670: ' ',
    62671: 'x 1000000',
    62672: 'millions ',
    62673: ' ',
    62674: ' ',
    62675: ' ',
    62676: ' ',
    62677: ' ',
    62678: ' ',
    62679: ' ',
    62680: ' ',
    62681: 'year',
    62682: 'year',
    62683: 'month',
    62684: 'month',
    62685: 'h',
    62686: 'hour. ',
    62687: 'min. ',
    62688: 'minute ',
    62689: 'sec. ',
    62690: 'second',
    62691: ' ',
    62692: ' ',
    62693: ' ',
    62694: ' ',
    62695: ' ',
    62696: ' ',
    62697: ' ',
    62698: ' ',
    62699: ' ',
    62700: ' ',
    62701: 'HV',
    62702: 'Vickers hardness',
    62703: 'HV/0.1',
    62704: 'Vickers hardness HV/0.1 kgf',
    62705: 'HV/0.2',
    62706: 'Vickers hardness HV/0.2 kgf',
    62707: 'HV/0.3',
    62708: 'Vickers hardness HV/0.3 kgf',
    62709: 'HV/0.4',
    62710: 'Vickers hardness HV/0.4 kgf',
    62711: 'HV/0.5',
    62712: 'Vickers hardness HV/0.5 kgf',
    62713: 'HV/0.6',
    62714: 'Vickers hardness HV/0.6 kgf',
    62715: 'HV/0.7',
    62716: 'Vickers hardness HV/0.7  kgf',
    62717: 'HV/0.8',
    62718: 'Vickers hardness HV/0.8 kgf',
    62719: 'HV/0.9',
    62720: 'Vickers hardness HV/0.9 kgf',
    62721: 'HV/1',
    62722: 'Vickers hardness HV/1 kgf',
    62723: 'HV/10',
    62724: 'Vickers hardness HV/10 kgf',
    62725: 'HV/20',
    62726: 'Vickers hardness HV/20 kgf',
    62727: 'HV/30',
    62728: 'Vickers hardness HV/30 kgf',
    62729: 'HV/40',
    62730: 'Vickers hardness HV/40 kgf',
    62731: 'HV/50',
    62732: 'Vickers hardness HV/50 kgf',
    62733: 'HV/60',
    62734: 'Vickers hardness HV/60 kgf',
    62735: 'HV/70',
    62736: 'Vickers hardness HV/70 kgf',
    62737: 'HV/80',
    62738: 'Vickers hardness HV/80 kgf',
    62739: 'HV/90',
    62740: 'Vickers hardness HV/90 kgf',
    62741: 'HV/100',
    62742: 'Vickers hardness HV/100 kgf',
    62743: 'HR',
    62744: 'Rockwell hardness ',
    62745: 'HRA',
    62746: 'Rockwell hardness/Diamond cone/ 60 kgf',
    62747: 'HRB',
    62748: 'Rockwell hardness/ 1/16" Steel ball / 100 kgf',
    62749: 'HRC',
    62750: 'Rockwell hardness/Diamond cone/ 150 kgf',
    62751: 'HRD',
    62752: 'Rockwell hardness/Diamond cone/ 100 kgf',
    62753: 'HRE',
    62754: 'Rockwell hardness/ 1/8" Steel ball / 100 kgf',
    62755: 'HRF',
    62756: 'Rockwell hardness/ 1/16" Steel ball / 60 kgf',
    62757: 'HRG',
    62758: 'Rockwell hardness/ 1/16" Steel ball / 150 kgf',
    62759: 'HRH',
    62760: 'Rockwell hardness/ 1/8" Steel ball / 60 kgf',
    62761: 'HRK',
    62762: 'Rockwell hardness/ 1/8" Steel ball / 150 kgf',
    62763: 'HRL',
    62764: 'Rockwell hardness/ 1/4" Steel ball / 60 kgf',
    62765: 'HRM',
    62766: 'Rockwell hardness/ 1/4" Steel ball / 100 kgf',
    62767: 'HRP',
    62768: 'Rockwell hardness/ 1/4" Steel ball / 150 kgf',
    62769: 'HRR',
    62770: 'Rockwell hardness/ 1/2" Steel ball / 60 kgf',
    62771: 'HRS',
    62772: 'Rockwell hardness/ 1/2" Steel ball / 100 kgf',
    62773: 'HRV',
    62774: 'Rockwell hardness/ 1/2" Steel ball / 150 kgf',
    62775: 'HR 15 N',
    62776: 'Rockwell Superficial Hardness/Diamond cone/15 kgf',
    62777: 'HR 30 N',
    62778: 'Rockwell Superficial Hardness/Diamond cone/30 kgf',
    62779: 'HR 45 N',
    62780: 'Rockwell Superficial Hardness/Diamond cone/45 kgf',
    62781: 'HR 15 T',
    62782: 'Rockwell Superficial Hardness/1/16" steel ball/15 kgf',
    62783: 'HR 30 T',
    62784: 'Rockwell Superficial Hardness/1/16" steel ball/30 kgf',
    62785: 'HR 45 T',
    62786: 'Rockwell Superficial Hardness/1/16" steel ball/45 kgf',
    62787: 'HR 15 W',
    62788: 'Rockwell Superficial Hardness/1/8" steel ball/15 kgf',
    62789: 'HR 30 W',
    62790: 'Rockwell Superficial Hardness/1/8" steel ball/30 kgf',
    62791: 'HR 45 W',
    62792: 'Rockwell Superficial Hardness/1/8" steel ball/45 kgf',
    62793: 'HR 15 X',
    62794: 'Rockwell Superficial Hardness/1/4" steel ball/15 kgf',
    62795: 'HR 30 X',
    62796: 'Rockwell Superficial Hardness/1/4" steel ball/30 kgf',
    62797: 'HR 45 X',
    62798: 'Rockwell Superficial Hardness/1/4" steel ball/45 kgf',
    62799: 'HR 15 Y',
    62800: 'Rockwell Superficial Hardness/1/2" steel ball/15 kgf',
    62801: 'HR 30 Y',
    62802: 'Rockwell Superficial Hardness/1/2" steel ball/30 kgf',
    62803: 'HR 45 Y',
    62804: 'Rockwell Superficial Hardness/1/2" steel ball/45 kgf',
    62805: 'BHN',
    62806: 'Brinell hardness number',
    62807: 'HB 10/500/15',
    62808: 'Brinell Hardness 10 mm steel ball/500 kgf / 15 sec',
    62809: 'HB 10/1500/15',
    62810: 'Brinell Hardness 10 mm steel ball/1500 kgf / 15 sec',
    62811: 'HB 10/3000/15',
    62812: 'Brinell Hardness 10 mm steel ball/3000 kgf / 15 sec',
    62813: 'HB 10/500/10',
    62814: 'Brinell Hardness 10 mm steel ball/500 kgf / 10 sec',
    62815: 'HB 10/1500/10',
    62816: 'Brinell Hardness 10 mm steel ball/1500 kgf / 10 sec',
    62817: 'HB 10/3000/10',
    62818: 'Brinell Hardness 10 mm steel ball/3000 kgf / 10 sec',
    62819: 'HB 6/500/15',
    62820: 'Brinell Hardness 6 mm steel ball/500 kgf / 15 sec',
    62821: 'HB 6/1500/15" ',
    62822: 'Brinell Hardness 6 mm steel ball/1500 kgf / 15 sec',
    62823: 'HB 6/3000/15" ',
    62824: 'Brinell Hardness 6 mm steel ball/3000 kgf / 15 sec',
    62825: 'HB 6/500/10" ',
    62826: 'Brinell Hardness 6 mm steel ball/500 kgf / 10 sec',
    62827: 'HB 6/1500/10" ',
    62828: 'Brinell Hardness 6 mm steel ball/1500 kgf / 10 sec',
    62829: 'HB 6/3000/10" ',
    62830: 'Brinell Hardness 6 mm steel ball/3000 kgf / 10 sec',
    62831: 'HK',
    62832: 'Knoop hardness number ',
    62833: 'HK 200',
    62834: 'Knoop hardness number / 200gf / 10 sec ',
    62835: 'HK 500',
    62836: 'Knoop hardness number / 500gf / 10 sec ',
    62837: 'HK 50',
    62838: 'Knoop hardness number / 50gf / 10 sec ',
    62839: 'HK 100',
    62840: 'Knoop hardness number / 100gf / 10 sec ',
    62849: 'PSI',
    62850: 'Tensile strength 1 lb/square inch ',
    62851: 'KPSI',
    62852: 'Tensile strength 1000 lb/square inch ',
    62853: 'MPa',
    62854: 'Tensile strength  MPa',
    62855: 'N/mm2',
    62856: 'Tensile strength  N/mm2',
    62871: '$ ',
    62872: 'Dollar',
    62873: 'CAD ',
    62874: 'Canadian Dollar ',
    62875: 'USD',
    62876: 'United States Dollar ',
    62877: '€ ',
    62878: 'EUR ',
    62879: 'AUD ',
    62880: 'Australian Dollar ',
    62881: 'BYR',
    62882: 'Belarusian ruble',
    62883: 'INR ',
    62884: 'Indian rupee ',
    62885: 'BRL ',
    62886: 'Brazilian real ',
    62887: 'CLP ',
    62888: 'Chilean peso ',
    62889: 'CNY ',
    62890: 'Chinese yuan (renminbi) ',
    62891: 'PKR ',
    62892: 'Pakistani rupee ',
    62893: 'ILS ',
    62894: 'Israeli new sheqel ',
    62895: 'JPY ',
    62896: 'Japanese yen ',
    62897: 'PYG ',
    62898: 'Peruvian nuevo sol ',
    62899: 'z? ',
    62900: 'Polish z?oty ',
    62901: 'SAR ',
    62902: 'Saudi riyal ',
    62903: 'RUB ',
    62904: 'Russian ruble ',
    62905: 'Rp ',
    62906: 'Indonesian rupiah ',
    62907: 'IRR ',
    62908: 'Iranian rial ',
    62909: '? ',
    62910: 'South Korean won ',
    62911: '?.? ',
    62912: 'Kuwaiti dinar',
    62971: 'Fr ',
    62972: 'Franc ',
    62973: '$',
    62974: 'Peso ',
    62975: '?? ',
    62976: 'Bulgarian lev ',
    62977: ' ',
    62978: ' ',
    62979: ' ',
    62980: ' ',
    62981: 'Query',
    62982: 'Use',
    62983: 'Use Range',
    62984: 'From',
    62985: 'Boolean Value',
    62986: 'True',
    62987: 'False',
    62988: 'Invalid data',
    62989: 'Last Modified',
    62990: 'Sent',
    62991: 'Page: ',
    62992: 'of',
    62993: 'Approved',
    62994: 'and',
    62995: 'or',
    62996: 'Expiry',
    62997: 'The record is currently being modified by another user',
    62998: 'Equipment Not Required',
    62999: 'Equipment is disabled',
    63000: 'Not all mandatory fields for Shipping Company are filled',
    63001: 'Shipping Company Code is required',
    63002: 'Cannot insert the record. Shipping Company Code already exists',
    63003: 'Validity period is required',
    63004: 'Add',
    63005: 'Delete',
    63006: 'Refresh',
    63007: 'Move Up',
    63008: 'Move Down',
    63009: 'Previous Page',
    63010: 'Next Page',
    63011: 'Add',
    63012: 'Remove',
    63013: 'Clone',
    63014: 'Move Left',
    63015: 'Move Right',
    63016: 'Export',
    63018: 'Load',
    63019: 'Price',
    63848: 'Last Modified',
    63849: 'by',
    63850: 'Shop Pilot',
    63851: 'Sent on',
    63852: 'Confirming the Quote has been sent on this date will disallow deletion or any change on the Quote in the future. Are you sure?',
    63853: 'Created by',
    63854: 'Last Modified by',
    63855: 'Operations',
    63856: 'Flows',
    63857: 'Parts',
    63858: 'Part Number',
    63859: 'Material Description',
    63861: 'Material',
    63862: 'Description',
    63863: 'Approved on',
    63864: 'P/O No.',
    63865: 'Received on',
    63866: 'NOT USED',
    63867: 'Last Reviewed',
    63868: 'The shop part code entered for this new part has already been assigned and that part has been archived',
    63869: 'Unable to load data',
    63870: 'Priceschema can  not be locked',
    63871: 'Part Detail',
    63872: 'Select Material',
    63873: 'Record can not be locked for editing',
    63874: 'Part already selected',
    63875: 'Part with the same process flow already selected',
    63876: 'Requested Delivery',
    63877: 'Promise Date',
    63878: 'Part already exists',
    63879: 'Selected flows',
    63880: 'Available flows',
    63881: 'Primary',
    63882: 'Status',
    63883: 'Failed',
    63884: 'No Process Flow set to primary',
    63885: 'Please enter Shop Part Code',
    63886: 'Active',
    63887: 'Testing',
    63900: 'Invoices',
    63901: 'Invoice',
    63902: 'PO',
    63903: 'Date Invoiced',
    63904: 'Total invoiced',
    63905: 'Payments',
    63906: 'Created on',
    63907: 'Modified on',
    63908: 'By',
    63909: 'Total',
    63910: 'Invoices that have been sent can no longer be edited or deleted.',
    63911: 'Comment',
    63912: 'Invoicing Items',
    63913: 'Invoiceable Items',
    63914: 'Unit Price',
    63915: 'Invoice already exists',
    63916: 'Duplicate miscellaneous charges found',
    63917: 'Amount',
    63918: 'Date Received',
    63919: 'Payment ID',
    63920: 'Payment already exists',
    63921: 'Miscellaneous description missing',
    63922: 'Miscellaneous total missing',
    63923: 'Invoice code is required',
    63924: 'You have chosen to send invoice. Doing so will prevent any future changes. Proceed?',
    63925: 'Sorry, you do not have permission to save invoice',
    63926: 'Misc. Item',
    63927: 'Outstanding invoices',
    63928: 'Outstanding',
    63929: 'Invalid payment amount',
    63930: 'Create Supplemental Invoice',
    63931: 'must be present in the Format',
    63932: 'Modules that are/were using',
    63933: 'Modules that are not using',
    63934: 'Min Counter Digit',
    63935: 'Counter Reset Option',
    63936: 'Counter Reset to/Start from',
    63937: 'No Reset',
    63938: 'Reset by Year',
    63939: 'Reset by Month',
    63940: 'Reset by Day',
    63941: 'Auto Key Generation',
    63942: 'Module Name',
    63943: 'Prefix',
    63944: 'Suffix',
    63945: 'Format',
    63946: 'Separator',
    63947: 'Counter Type',
    63948: 'Counter',
    63949: 'The Auto Key feature has already been enabled by other user',
    63950: 'Invalid Format. Fix the gap please.',
    63951: 'Shipping Company Code',
    63952: 'Shipping Company Name',
    63953: 'Contact Person',
    63954: 'A new shipping company has been saved successfully',
    63955: 'The shipping company has been updated successfully',
    63958: 'Shipping Code',
    63959: 'Shipping/Pick-Up Date',
    63960: 'Tracking No.',
    63961: 'Shipping Cost Paid by',
    63962: 'Service Type',
    63963: 'Shipping Cost',
    63964: 'Volume',
    63965: 'Container ID',
    63966: 'Selected Parts for Shipping',
    63967: 'Save Shipping',
    63968: 'Shipping Code already exists',
    63969: 'Confirmed by',
    63970: "Customer's Shipping infomation",
    63971: 'Wt',
    63972: 'Receiver',
    63973: 'Shipping Items',
    63974: 'Item is required',
    63975: 'A new shipment has been saved successfully',
    63976: 'The shipment has been updated successfully',
    63977: 'Item already exists',
    63978: 'Quantity has to be greater than zero',
    63979: 'Total Gross Weight',
    63980: 'Total Net Weight',
    63981: 'Recv Slip No.',
    63982: 'Confirming the shipment/pick-up on this date will disallow deletion or any modification. Press save to proceed.',
    63983: 'Treated Part',
    63984: 'Untreated Part',
    63985: 'Other Item',
    63986: 'This shipment has already been confirmed. No deletion or further modification is allowed.',
    63987: 'Batch ID already exists.',
    63988: 'Quantity or weight must be greater than zero',
    63989: 'Batches',
    63990: 'The shipment has been deleted successfully',
    63991: 'Pick-Up',
    63992: 'All entered data will be lost by selecting another Customer. Select Yes to contine.',
    63993: 'All entered data will be lost by selecting YES to clear Customer',
    63994: 'Clear the Customer',
    63995: 'Select a Shipping Company',
    63996: 'Select a Shop Order',
    63997: 'Weight Unit is not selected',
    63998: 'Quantity Unit is not selected',
    63999: 'Available Shipping Addresses',
    64000: 'Select a Shipping Address',
    64001: 'Please select a CUSTOMER first',
    64002: 'No Shipping Address from CUSTOMER is available, Would you like to load Business Address?',
    64003: 'The current record no longer exists.',
    64004: 'PDF for Preview or Email',
    64005: 'Select a Report Template',
    64006: 'Please save your report and then close your default PDF reader program',
    64007: 'Shipping Company is already selected, Overwrite it with the default Shipping Company from Customer?',
    64008: 'No Shipping Address from CUSTOMER is available, the Primary Business Address is loaded',
    64009: 'Are you sure you want to discard the changes and exit the editing form?',
    64010: "Shipping Company Code (Use 'equal' and leave the box empty for Pick-Up)",
    64011: 'Are you sure you want to delete this item?',
    64012: 'An invalid number is entered',
    64013: 'By removing the Selected Parts, Gross Weight and Container Infomation will be lost. Continue?',
    64014: 'Gross Weight is less than Net Weight on the highlighted Part, ignore? ',
    64015: 'Cannot delete this item',
    64016: 'The database engine is not supported by the report generator',
    64017: 'Qty Damaged',
    64018: 'Parts Available From Production',
    64019: 'The sum of quantities to be shipped and not to be shipped (damaged) for this part must be less than or equal to ',
    64020: 'Would you like to have the total quantity and total weight re-calculated and updated? ',
    64021: "Total Gross Weight is less than Total Net Weight, you'd like to continue?",
    64022: 'Total QTY Shipped',
    64023: 'Shipping Inspection  (Use _ to separate in between criteria)',
    64024: 'Not all Inspection Criteria have been completed',
    64025: 'Receiving Inspection  (Use _ to separate in between criteria)',
    64026: 'List of Countries  (Use _ to separate)',
    64056: 'Contact Name 1',
    64057: 'Contact Phone No. 1',
    64058: 'Contact Phone Ext. 1',
    64059: 'Contact Fax No. 1',
    64060: 'Contact Email 1',
    64061: 'Contact Name 2',
    64062: 'Contact Phone No. 2',
    64063: 'Contact Phone Ext. 2',
    64064: 'Contact Fax No. 2',
    64065: 'Contact Email 2',
    64066: 'Contact Name 3',
    64067: 'Contact Phone No. 3',
    64068: 'Contact Phone Ext. 3',
    64069: 'Contact Fax No. 3',
    64070: 'Contact Email 3',
    64071: 'Auto detect bar code: User Name prefix',
    64072: 'Auto detect bar code: User Password prefix',
    64073: 'Auto detect bar code: Shop Order prefix',
    64074: 'Auto detect bar code: Step Number Order prefix',
    64075: 'Auto detect bar code: Equipment prefix',
    64076: 'Auto detect bar code: Scanner prefix (Ascii code)',
    64077: 'Auto detect bar code: Scanner suffix (Ascii code)',
    64078: 'Accounts receivable number for Peachtree',
    64079: 'Tax type number for Peachtree',
    64081: 'Name',
    64082: 'Quote Report: Comment 1',
    64083: 'Quote Report: Comment 2',
    64084: 'Quote Report: Purchase Order Address',
    64085: 'Quote Report: Receiving Address',
    64086: 'Certification Report: Comment 1',
    64087: 'EDITING MODE',
    64088: 'Changes have been done successfully. Would you like to exit the EDITING MODE?',
    64089: 'Shop Order Report: Comment 1',
    64090: 'Production Report: Comment 1',
    64091: 'Lab Evaluation Report: Comment 1',
    64092: 'Shipping Report: Comment 1',
    64093: 'Invoicing Report: Comment 1',
    64094: 'Allow multiple process flows in shop orders',
    64095: 'Auto populate lab evaluation items',
    64099: 'Unable to automatically generate a key',
    64100: 'Certification Code',
    64101: 'Issued Date',
    64102: 'Issued by',
    64103: 'Lot ID',
    64104: 'Sample ID',
    64105: 'Mount ID',
    64106: 'Do you want to remove this item from the Certificate?',
    64107: 'Do you want to add this item to the Certificate?',
    64108: 'The Certificate has changed.  Are you sure you want to discard the changes?',
    64109: 'There is no Lab Evaluation available for the Certificate.',
    64110: 'Please select a Lab Evaluation',
    64111: 'Please select one or more parts for this Certificate',
    64112: 'Create Certificate',
    64113: 'A Certificate cannot be issued without any test results.',
    64114: 'Issuing this Certificate will disallow any further changes or deletion.',
    64115: 'If you delete this record you cannot access it again.',
    64116: 'Are you sure you want to continue?',
    64122: 'Certification Code already exists',
    64123: 'Save changes before refreshing?',
    64124: 'Please save your changes',
    64133: 'Tax is used by tax policy',
    64134: 'Process Flows',
    64135: 'Unable to modify, operation type is currently assigned to an equip?ent',
    64136: 'Unable to remove operation type from equipment. Equipment currently used in schedualing',
    64137: 'Please save changes before printing',
    64138: 'An error has occured that is preventing you from continuing. The window must be closed. Please try again.',
    64139: 'Edit successfull',
    64140: 'Save changes before closing?',
    64141: 'Add/Modify/Delete Materials',
    64142: 'Add/Modify/Delete Process Flows',
    64143: 'Shop Amount',
    64144: 'By Percentage',
    64145: 'Invoiced Amount',
    64146: 'Total Invoiced',
    64147: 'Tax',
    64148: 'Tax Name',
    64149: 'Base amount',
    64150: 'Registration Name',
    64151: 'Registration No.',
    64152: 'Percent',
    64153: 'Tax percent must be grater the zero',
    64154: 'Tax name already exists',
    64155: 'Tax Policy',
    64156: 'Policy Name',
    64157: 'Tax Level',
    64158: 'Applied To Level',
    64159: 'Invalid policy name',
    64160: 'A manditory field has been found empty',
    64161: 'Tax policy already exists',
    64162: 'Sub total',
    64163: 'Unable to calculate price. Verify price schema.',
    64164: 'Price based on',
    64165: 'Job No.',
    64166: 'All Disabled',
    64167: 'Batch No.',
    64168: 'Shop Order Status',
    64169: 'At what time?',
    64170: 'Add lot',
    64171: 'Remove step',
    64172: 'Remove lot',
    64173: 'Insert step',
    64174: 'Production Steps',
    64175: 'Lot complete',
    64176: 'No more parts available',
    64177: 'All parts have been treated for this batch. Continuing will mark this batch not editable. Continue?',
    64178: 'Invalid quantity or weight for the following step:',
    64179: 'Lot must have at least one part.',
    64180: 'Lot',
    64181: 'Invalid entry found at',
    64182: 'Please enter start date.',
    64183: 'Invalid start date.',
    64184: 'Please enter an equipment.',
    64185: 'Please enter a treatment.',
    64186: 'Invalid part quantity or weight for the following step:',
    64187: 'Please enter an operator.',
    64188: 'step',
    64189: 'End time must be greater than the start time.',
    64190: 'Quantity or weight in the batch must be greater than zero.',
    64191: 'End time can not exceed the current date and time.',
    64192: 'Unable to edit. This batch is complete.',
    64193: 'Please enter a part.',
    64194: 'Unable to insert step after the last step if it contains lot with treated parts.',
    64195: 'Unable to remove step.',
    64196: 'Unable to remove lot.',
    64197: 'Unable to insert step before step',
    64198: 'It contains lots.',
    64199: 'Batch can only contain parts with same process flow.',
    64200: 'Unable to remove part from batch.',
    64201: 'Equipment is required but none appear to be available.',
    64202: 'Add Part',
    64203: 'Remove Part',
    64204: 'Part quantity and weight must be greater than zero.',
    64205: 'Unable to add part. Lot is finished.',
    64206: 'Unable to remove lot. Some parts have been treated.',
    64207: 'Tax level field is empty',
    64208: 'Tax name field is empty',
    64209: 'Applied to field is empty',
    64210: 'The following part exceeds its batch part max quantity or weight',
    64211: 'Step',
    64212: 'Part',
    64213: 'Missing start time',
    64214: 'Must clear end time first',
    64215: 'Setting the end date will prevent any future changes to this lot. Continue?',
    64216: 'No parts available',
    64217: 'Select batch start time.',
    64218: 'Clear batch start time.',
    64219: 'Select batch end time.',
    64220: 'Clear batch end time.',
    64221: 'Please select level first.',
    64222: 'Invalid tax level.',
    64223: 'Taxes in the same level must be unique.',
    64224: 'Must have at least one tax.',
    64225: 'Unable to remove. Tax policy is used.',
    64226: 'Must have at least a start time in order to approve the lot.',
    64227: 'Must be unapproved before changing start time.',
    64228: 'Please enter an operator for end time.',
    64229: 'Auto Complete',
    64230: 'Shop order is no longer approved, please refresh your data',
    64251: 'Requirement Parameter',
    64252: 'Test Type Available',
    64253: 'Unit Available',
    64254: 'Unit Group',
    64255: 'Requirement Set Code',
    64256: 'Requirement Set',
    64257: 'Parameter',
    64258: 'Test type',
    64259: 'Requirement Parameter is required',
    64260: 'At least one Requirement Parameter is required',
    64261: 'Requirement Set Code is required',
    64262: 'Warning - At least one Test Type and one Unit should be added for the selected Requirement Parameter before being able to be assigned in Requirement Set',
    64300: 'Picture',
    64301: 'Load picture from file',
    64302: 'Clear picture',
    64303: 'Unable to load picture',
    64304: 'Click picture to enlarge',
    64305: 'Nothing to paste',
    64306: 'No picture',
    64307: 'of',
    64308: 'Revision No.',
    64309: 'Unable to remove. Part is used.',
    64310: 'Load from requirement set templates',
    64311: 'Test type is required',
    64312: 'Load picture from clipboard',
    64313: 'Unable to understand picture file!',
    64314: 'This picture takes too much memory!',
    64315: 'Please reduce the dimensions or compress it, and try again.',
    64316: 'The maximum memory requirement for pictures, in bytes, is:',
    64317: 'The memory requirement for this picture, in bytes, is:',
    64318: 'I was able to reduce the memory requirement for your picture to: ',
    64325: 'Selected Units',
    64326: 'Available Units',
    64327: 'Group',
    64328: 'Process Flow Category',
    64329: 'Category Percentage',
    64330: 'Category Cost',
    64331: 'Warning, you are editing an invoice that is based on a process flow with no cost categories assigned to it.',
    64340: 'Sales',
    64341: 'Statistics Report: Sales By Customer',
    64342: 'Statistics Report: Sales By Category',
    64343: 'Statistics Report: Sales By Equipment',
    64400: 'Barcode',
    64401: 'Shop order code is empty',
    64402: 'Batch number is empty',
    64403: 'Branch number is empty',
    64404: 'Step number is empty',
    64405: 'Permission to edit batches denied',
    64406: 'Equipment is empty',
    64407: 'Multiple batch/lots are not supported by barcode reader.',
    64408: 'This step has not started yet',
    64409: 'Branch must contain parts',
    64410: 'There are no quantities',
    64411: 'Scan successful',
    64412: 'Unable to update data',
    64413: 'Step already started',
    64414: 'Step already finished',
    64415: 'Shop order needs to be approved',
    64416: 'Shop order does not exists',
    64417: 'Batch does not exist',
    64418: 'Equipment is not required for this step',
    64419: 'This step contains more parts then allowed. Please use the Production Status screen',
    64420: 'Branch does not exist',
    64421: 'This equipment is not assigned to this type of step.',
    64422: 'Branch No.',
    64423: 'Step No.',
    64424: 'Print user cards',
    64450: 'Please select shop order code',
    65000: 'Material: View',
    65001: 'Material: Modify',
    65002: 'Material: Delete',
    65003: 'Equipment: View',
    65004: 'Equipment: Modify',
    65005: 'Equipment: Delete',
    65006: 'Operation type: View',
    65007: 'Operation type: Update',
    65008: 'Operation type: Delete',
    65009: 'Operation type: Assign To Equipment',
    65010: 'Operation: View',
    65011: 'Operation: Modify',
    65012: 'Operation: Delete',
    65013: 'Part: View',
    65014: 'Part: Modify',
    65015: 'Part: Delete',
    65050: 'LabTest Types:  View',
    65051: 'LabTest Types:  Modify',
    65052: 'LabTest Types:  Delete',
    65060: 'Statistics Report: Sales By Customer',
    65061: 'Statistics Report: Sales By Category',
    65062: 'Statistics Report: Sales By Equipment',
    65290: 'Lab Evaluation:  View',
    65291: 'Lab Evaluation:  Modify',
    65292: 'Lab Evaluation:  Delete',
    65293: 'Lab Evaluation:  Approve',
    65296: 'Company Info:  View',
    65297: 'Company Info:  Modify',
    65301: 'Customer:  View',
    65302: 'Customer:  Modify',
    65303: 'Customer:  Delete',
    65304: 'Customer Contact: Modify',
    65305: 'Customer Contact: Delete',
    65306: 'Receiving: View',
    65307: 'Receiving: Modify',
    65308: 'Receiving: Delete',
    65309: 'Receiving: Inspect',
    65310: 'Shop Order:  View',
    65311: 'Shop Order:  Modify',
    65312: 'Shop Order:  Delete',
    65313: 'Shop Order:  Approve',
    65314: 'Process Flow: View',
    65315: 'Process Flow: Modify',
    65316: 'Process Flow: Delete',
    65321: 'Certification: View',
    65322: 'Certification: Modify',
    65323: 'Certification: Delete',
    65328: 'Requirement: View',
    65329: 'Requirement: Modify',
    65330: 'Requirement: Delete',
    65331: 'Auto Key Generation: View',
    65332: 'Auto Key Generation: Modify',
    65333: 'Auto Key Generation: Delete',
    65341: 'Shipping Company: View',
    65342: 'Shipping Company: Modify',
    65343: 'Shipping Company: Delete',
    65351: 'Tax: View',
    65352: 'Tax: Modify',
    65353: 'Tax: Delete',
    65361: 'Shipping: View',
    65362: 'Shipping: Modify',
    65363: 'Shipping: Delete ',
    65364: 'QUOTE: View',
    65365: 'NOT USED',
    65366: 'Shop Order Panel',
    65367: 'Shipping Panel',
    65368: 'Invoicing: View',
    65369: 'Ceritification Panel',
    65371: 'Operation Type & Equipment',
    65372: 'Operation & Process Flow',
    65374: 'Shop Today',
    65375: 'Company Info',
    65382: 'PL: Price List',
    65384: 'Shipping Company',
    65385: 'Equipment Usage',
    65386: 'NOT USED',
    65387: 'Shop Order Report',
    65388: 'Shipping Report',
    65389: 'Invoicing Report',
    65390: 'Part Report',
    65391: 'Equipment Report',
    65392: 'Process Flow Report',
    65393: 'Certification Report',
    65394: 'QUOTE: Create ',
    65395: 'QUOTE: Delete ',
    65396: 'QUOTE: Modify ',
    65397: 'QUOTE: Clone',
    65398: 'QUOTE: Add Part',
    65399: 'QUOTE: Remove Part',
    65400: 'Invoicing: Delete',
    65401: 'Invoicing: Modify',
    65402: 'Unit: View',
    65403: 'Unit: Modify',
    65411: 'Samples',
    65423: 'PL: Create Price List',
    65424: 'PL: Modify Price List',
    65425: 'PL: Delete Price List',
    65426: 'ID',
    65427: 'Treatment',
    65428: 'Approved',
    65429: 'Approval Date',
    65430: 'Approved by',
    65431: 'Tracking information currently unavailable',
    65432: 'Refreshing data will lose changes.  Are you sure?',
    65433: 'Production Status: Modify',
    65434: 'Production Status: Delete',
    65435: 'Production Status: View',
    65436: 'Cancel Shop Order',
    65437: 'Please save work before proceeding',
    65438: 'This customer has been disabled',
    65439: 'NOT USED',
    65440: 'NOT USED',
    65441: 'NOT USED',
    65442: 'You must enter quantites and/or weights for any selected parts.',
    65443: 'NOT USED',
    65444: 'NOT USED',
    65445: 'NOT USED',
    65446: "T?e currency for the selected part doesn't match the current selected currency.",
    65447: 'Would you like to load it and adjust pricing?',
    65448: 'Zoom',
    65449: 'Prices have been updated',
    65450: 'NOT USED',
    65451: 'NOT USED',
    65452: 'Double click to modify schema',
    65453: 'Double click to modify discount',
    65500: 'Lab Evaluation',
    65501: 'The Lab Evaluation has changed.  Are you sure you want to discard the changes?',
    65502: 'Evaluation Date',
    65503: 'Lab Report Code',
    65504: 'Lab Report already exists',
    65505: 'There is no Shop Order available for the Lab Evaluation.',
    65506: 'Please select the parts you wish to include in the Lab Evaluation.',
    65507: 'Include closed shop orders',
    65508: 'Select requirements to add to Lab Evaluation',
    65509: 'Final part requirement',
    65510: 'Requirements',
    65511: 'Do you want to delete this item from the Lab Evaluation?',
    65512: 'Do you want to add this item to the Lab Evaluation?',
    65513: 'Please select a batch for the Lab Evaluation.',
    65514: 'Instructions',
    65515: 'Results',
    65516: 'Initial Measured Value',
    65517: 'Result Value',
    65518: 'Production Step',
    65519: 'Images',
    65520: 'Lab Technician Name',
    65521: 'Move picture left',
    65522: 'Move picture right',
    65523: 'Are you sure you want to delete this picture?',
    65524: 'This picture is already loaded.',
    65525: 'Create Chart',
    65526: 'Etchant',
    65527: 'Are you sure you want to move this picture?',
    65528: 'Please select a unit',
    65529: 'Set Unit',
    65530: 'Convert',
    65531: 'Cannot convert value:',
    65532: 'Update Chart',
    65533: 'Remove Chart',
    65534: 'Set Titles',
    65535: 'Chart Title',
    65536: 'Left Axis Title',
    65537: 'Bottom Axis Title',
    65538: "The data plotted on the chart doesn't correspond to what is in the table.",
    65539: 'Confirming that the Lab Evaluation has been approved will disallow any further changes or deletion.',
    65540: '[meas.]',
    65541: 'A Lab Evaluation cannot be approved without any test results.',
    65542: 'The following lab tests have not been completed:',
    65543: 'Approval will remove this Lab Evaluation from the inbox without doing these tests.',
    65544: 'Sample Size',
    65545: 'Destructive #',
    65546: 'Accepted #',
    65547: 'Rejected #',
    65548: 'Magnification (X)',
    65549: 'Location',
    65550: 'Item Approved',
    65551: 'Item Approved By',
    65552: 'This lab test item has not been completed.',
    65553: 'This lab test item cannot be modified or deleted after it is approved.',
    65554: 'X-Axis',
    65555: 'Y-Axis',
    65556: 'Use Count',
    65557: 'Legend Position',
    65558: 'Start Date',
    65559: 'End Date',
    65560: 'Set date range',
    65800: 'LabTest Type Templates',
    65801: 'This Template Is In Use And Cannot Be Deleted',
    65802: 'LabTest Description',
    65803: 'Are you sure that you want to delete this template?',
    65804: 'Columns',
    65805: 'Rows',
    65806: 'Set All Units',
    65807: 'No Units',
    65808: 'LabTest Type Code',
    65809: 'Test Instructions',
    65810: 'Test Description',
    65812: 'Add Picture Button',
    65813: 'Add Chart Button',
    65814: 'Add Convert Units Button',
    65815: 'Use Row Header',
    65816: 'Use Column Header',
    65817: 'Test Title',
    65818: 'Modify Rows',
    65819: 'Modify Columns',
    65820: 'A Test Type Code is Required',
    65821: 'The Lab Test Type Has Been Saved',
    65822: 'This template has been changed, are you sure you want to discard the changes?',
    65823: 'Rev. Promise',
    65824: 'Due Date',
    65825: 'Modify Spec',
    65826: ' ',
    65827: 'Flow Spec',
    65828: 'Requirement Parameter',
    65829: 'Requirement Operator',
    65830: 'First Requirement Value',
    65831: 'Second Requirement Value',
    65832: 'Requirement Unit',
    65833: 'Removing approval',
    65834: 'Shop order cannot be modified once parts are in production',
    65835: 'Display Shop Order In Box',
    65836: 'Part is in production.  Remove from production to delete part.',
    65837: 'Minimum Quantity -',
    65838: 'Minimum Weight -',
    65839: 'Approval cannot be removed with parts in production',
    65840: 'Archived',
    65841: 'Show Available Test Types',
    65842: 'Show Archived Test Types',
    65843: 'This test type is not being used.  Delete it instead',
    65844: 'There are no available test types',
    65845: 'Select Replacement Test Type',
    66000: 'Open',
    66001: 'Inspected',
    66002: 'Approved',
    66003: 'Scheduled',
    66004: 'In Production',
    66005: 'Treated',
    66006: 'Certified',
    66007: 'Shipped',
    66008: 'Invoiced',
    66009: 'Cancelled',
    66010: 'Closed',
    66011: 'Maximum number of entries has been reached for the demo version',
    66012: 'There are no units selected.  Please update unit table.',
    66013: 'If used, gross weight must be at least equal to net weight.',
    66014: 'Quantity',
    66015: 'Packing Slip',
    66016: 'Enter quantity:',
    66017: 'Total',
    66018: 'No additional packing slips are available.',
    66019: 'Try adjusting existing part quantities.',
    66020: 'Save changes before proceeding.',
    66021: 'In-Box',
    66022: 'Unable to find requested item.',
    66023: 'Double click to mo?ify quantity.',
    66024: 'Check box selects item.',
    66150: 'equals',
    66151: 'does not equal',
    66152: 'is less than',
    66153: 'is greater than',
    66154: 'is less than or equal to',
    66155: 'is greater than or equal to',
    66156: 'is like',
    66157: 'is between',
    66170: 'Add empty item',
    66171: 'Add item(s) from operations',
    66172: 'Add item(s) from process flows',
    66173: 'Clone selected item',
    66174: 'Delete selected item(s)',
    66175: 'New item',
    66176: 'Missing description',
    66177: 'Missing unit',
    66178: 'Missing amount',
    66179: 'Missing unit price',
    66180: 'Missing start value',
    66181: 'Missing end value',
    66182: 'Missing unit price',
    66183: 'Customized',
    66184: 'Enable customizing current price schema',
    66185: 'Select exsisting price schema',
    66186: 'Add new scale',
    66187: 'Remove selected scale',
    66188: 'End value must be grater then start value',
    66189: 'Invalid schema code',
    66190: 'Invalid total',
    66191: 'Must have parts',
    66192: 'Amount must be grater then zero',
    66193: 'Part quantity must be grater then zero',
    66195: 'Shop Equipment Status:',
    66196: 'Load items(s) from quote',
    66197: 'Quote',
    66198: 'No available quotes with pricing items where found for this specific customer',
    66199: 'Can only move one unit at a time',
    66200: 'Sorry, you do not have permission to save units.',
    66201: 'A unit that has been selected by a user to be part of the system is no longer available and has been removed from your list',
    66202: 'Unable to create export file.',
    66203: 'Save as XML file...',
    66204: 'Unable to Save XML file.',
    66205: 'Nothing to export.',
    66206: 'Locked',
    66207: 'An invalid mandatory unit was found in the config file.',
    66208: 'Invoices without tax details.',
    66209: 'Invoice tax details only.',
    66210: 'Batches without production step details.',
    66211: 'Production step details only.',
    66212: 'Weight per count',
    66213: 'Invoice all parts equally',
    66214: 'Multiple process flows are not allowed in this shop order.',
    66215: 'Invoices with process flow categories.',
    66216: 'Part Total',
    66217: 'Peachtree',
    66218: 'Credit Memo',
    66219: 'Due Date',
    66220: 'Accounts Receivable Account',
    66221: 'Accounts Receivable Amount',
    66222: 'GL Account',
    66223: 'Tax Type',
    66224: 'Number of Distributions',
    70001: 'Shop Layout',
    70002: 'Quality Assurance',
    70003: 'Data',
    70004: 'Component Registration',
    70005: 'Plant Layout Configuration',
    70006: 'Menu & Toolbar Configuration',
    70007: 'Changes have been made',
    70008: 'Default user not found.',
    70009: 'No user logged on',
    70010: 'Add/Remove Workplaces',
    70501: 'Unable to load. Component version conflict.',
    70502: 'Resource id',
    70503: 'Add Component',
    70504: 'Remove Component',
    70505: 'Modify Component',
    70506: 'Component is no longer registered',
    70508: 'Version',
    70509: 'Available Components',
    70510: 'Components in Use',
    70511: 'Edit',
    70512: 'Icon',
    70516: 'Load image from file',
    70517: 'Use default image provided by component',
    70520: 'View Component Registration',
    70521: 'Modify Component Registration',
    70522: 'Delete Component Registration',
    73001: 'Terminal Manager Plugin',
    73002: 'Manage computer terminals',
    73201: 'UPC User',
    73202: 'User and Group Permission',
    73203: 'UPC User does not allow to have Group assigned to her/him. All Group will be removed. Are you sure?',
    73204: 'Group for the selected User already exists',
    73205: 'Cannot clone Default User',
    73206: 'UPC User cannot have Group assigned to him/her. All Group, if there is any, to be cloned will be ignored',
    73207: 'Please select Group to assign to User',
    73208: 'Please select User to be assigned to Group',
    73209: 'Default Group can only be assigned to Default User',
    73210: 'Cannot assign Group to UPC User',
    73211: 'Permission Level',
    73212: 'Create Default User',
    73213: 'Create Default Group',
    73214: 'Last name:',
    73215: 'First name:',
    73216: 'Update All Groups',
    73217: 'Cannot remove Default User',
    73218: 'Cannot delete Default Group',
    73219: 'Cannot remove Default Group from Default User',
    73501: 'Enter Name',
    73502: 'Modify Layout Name',
    73503: 'Rename',
    73504: 'No Work Places have been assigned to this layout.',
    73505: 'Select Layout',
    73506: 'Change Style',
    73507: 'Change Orientation',
    73508: 'Layout ID',
    73509: 'Layout Name',
    73510: 'Row Position',
    73511: 'Column Position',
    73512: 'Height (rows)',
    73513: 'Width (columns)',
    73514: 'Workplace',
    73515: 'Workplace Added',
    73516: 'There are no available workplaces.',
    73517: 'Workplace Removed',
    73518: 'Workplace Modified',
    73519: '',
    73520: 'Change Font Size',
    73521: 'There are multiple workplaces in the same position.',
    74001: 'View Menu & Toolbar Layout',
    74002: 'Modify Menu & Toolbar Layout',
    74003: 'Delete Menu & Toolbar Layout',
    74004: 'Menu',
    74005: 'Toolbar',
    74006: 'Components',
    74007: 'Create Top Menu Item',
    74008: 'Edit Top Menu Item',
    74009: 'Remove Top Menu Item',
    74010: 'Remove Component From Menu',
    74011: 'Remove Component From Toolbar',
    80000: 'User Manager',
    80001: 'Shut down application',
    80002: 'Acknowledge alarms',
    80003: 'Enter manual mode',
    80004: 'Calibration',
    80005: 'Offsets',
    80006: 'Update Firmware',
    80007: 'Modify Current Job',
    80008: 'Edit Recipe',
    80009: 'Configure Recipe Alarms',
    80010: 'Configure Recipe Flags',
    80011: 'Configure System',
    80012: 'Configure Control',
    80013: 'Configure Communication',
    80014: 'View Log Files',
    80015: 'Configure Cascade',
    80016: 'Configure Digital',
    80017: 'Probe Tests',
    80018: 'Configure Probe Tests',
    80019: 'Configure Recipe Events',
    80020: 'Configure External Controller',
    80021: 'Configure Proc Cal (FULL)',
    80022: 'Start/Stop recipe',
    80023: 'View Debug Screen',
    80024: 'Configure Proc Cal (PARTIAL)',
    80025: 'Internal I/O Configuration',
    80026: 'Log Maintenance',
    80027: 'Set Date/Time',
    80028: 'NOT USED',
    80029: 'Recipe Maintenance',
    80030: 'Touch Screen Calibration',
    80031: 'Restart Application',
    80032: 'Test Watchdog',
    80033: 'Maintenance mode is an unprotected mode. Are you sure you want to proceed? ',
    80034: 'You are currently editing or adding new data, data will not be saved. Continue?',
    80035: 'NOT USED',
    80036: 'Attempt to set system time returned error',
    80037: 'Attempt to set hardware time returned error',
    80038: 'System and hardware time have been set',
    80039: 'Memory Map',
    80040: 'Backup/Restore',
    80041: 'Halt/Resume',
    80042: 'Start/Stop standby logging',
    80043: 'Configure I/O points',
    80044: 'File/Folder',
    80100: 'Main Menu',
    80101: 'Save Successful',
    80102: 'Save Failed',
    80103: 'Delete Successful',
    80104: 'Delete Failed',
    80105: 'Ready',
    80106: 'Range',
    80107: 'Access denied',
    80108: 'It is strongly recommended not to perform this action while job is running. Are you sure you want to proceed?',
    80109: "Can't write log",
    80110: 'Can write log',
    80111: "Can't write process memory",
    80112: 'Are you sure you want to erase memory and restart the application?',
    80113: ' "Use Gas Input',
    80114: ' "Use DPT Input',
    80115: ' "Use mV Input',
    80121: 'HIGH',
    80122: 'LOW',
    80200: 'Log On',
    80201: 'Log Off',
    80202: 'Edit Users',
    80203: 'Shut Down',
    80204: 'Restart',
    80205: 'Log on, please',
    80206: 'Controller has to be in Local Mode',
    80207: 'Controller has to be in Manual Mode',
    80208: 'Controller has to be in Remote Mode',
    80209: 'Controller has to be in Auto Mode',
    80210: 'This will clear the Set Point, disable the control loop. Do you want to continue?',
    80221: 'Unable to determine what to update. The following file is missing',
    80222: 'Unable to load the following file',
    80223: 'The following file was not found in the software update content',
    80224: 'Invalid checksum value in software update content for the following file',
    80225: 'Invalid software update',
    80226: 'The following files checksum is invalid after copy',
    80227: 'Unable to obtain checksum value from the following file',
    80228: 'A checksum mismatch has been detected during copy of this file',
    80229: 'Software Updates will need to restart application or even the controller, do you want to proceed?',
    80230: 'Unable to perform software updates while job is running',
    80231: 'You will need to insert or connect an external media containing updates. The root of this media must contain the following folder with updates in it',
    80232: 'Nothing to restore',
    80233: 'After restore has completed the application will automatically restart, do you want to proceed?',
    80249: 'Do NOT power off the instrument till procedure is completed.',
    80250: 'Please insert or connect external media containing updates.',
    80251: 'The following folder containing software updates was not found',
    80252: 'Update folder was found but is empty',
    80253: 'Software updates complete. Controller will now restart.',
    80254: 'Unable to remove the following folder',
    80255: 'Unable to create the following folder',
    80256: 'Unable to copy the following file',
    80257: 'Unable to remove the following file',
    80258: 'Software update failed. Controller will now restart',
    80259: 'Please specify the external media',
    80260: 'Invalid external media',
    80261: 'Error accessing the following folder',
    80262: 'An error occurred while preparing to perform software updates.',
    80263: 'Software Updates failed',
    80264: 'Software updates failed. Application will now restart',
    80265: 'Software updates complete',
    80266: 'Copying update files to controller ...',
    80267: 'Preparing to update software ...',
    80268: 'Performing backup and updating software ...',
    80269: 'Software updates complete. Application will now restart',
    80270: 'Control',
    80271: 'Recipe',
    80272: 'Maintenance',
    80273: 'Configuration',
    80274: 'Users',
    80275: 'View Logs',
    80301: "Can't remove user logged on",
    80350: 'Group',
    80351: 'User already exists',
    80352: 'Edit User',
    80353: 'Please enter a user name',
    80354: 'Please enter a password',
    80355: 'Please select a group',
    80356: 'User',
    80357: 'User Name',
    80358: 'Password',
    80359: 'ERROR - Unable to load users data file',
    80360: 'User Menu',
    80361: 'Width',
    80362: 'Height',
    80500: 'Select Recipe',
    80501: 'Caps Lock',
    80502: 'OP Code',
    80503: 'Command',
    80504: 'Recipe Saved',
    80505: 'Error saving recipe',
    80506: 'Error opening recipe',
    80507: 'No instruction can be set for NOOP',
    80508: 'Please select a recipe',
    80509: 'Select a step to insert before or choose append',
    80510: 'Select a step to delete',
    80511: 'This recipe exists',
    80512: 'Are you sure that you want to replace it?',
    80513: 'The instruction is invalid or missing for this step.',
    80514: 'The instruction for this step has an invalid format for the OP Code.',
    80515: "The branch instruction refers to a step that doesn't exist.",
    80516: 'This recipe will be saved but will not run as it is.',
    80517: 'This step is set to NOP and cannot have an instruction.',
    80518: 'Time is lower than minimum allowed for this OP Code.',
    80519: 'Time is greater than maximum allowed for this OP Code.',
    80520: 'Flag or Event number (XXX) is out of range (XXX.Y).',
    80521: 'Value (Y) must be 0 or 1 (XXX.Y).',
    80522: 'Limit must follow test or wait OP Codes or it is ignored.',
    80523: 'Ramp step must be followed by H (set temperature).',
    80524: 'The value for this step is below the minimum range.',
    80525: 'The value for this step is above the maximum range.',
    80526: 'This OP Code is unknown.',
    80527: 'Z Opcode must follow the F (flag) OP Code.',
    80528: 'The maximum number of recipes has been exceeded.',
    80529: 'Are you sure that you want to delete this recipe?',
    80530: 'Recipe cannot be deleted.',
    80531: 'There are unsaved changes.',
    80532: 'Do you wish to save the changes to this recipe?',
    80533: 'Error in Step',
    80534: "This op code requires a point that doesn't exist in this configuration.",
    80535: 'New Recipe',
    80536: 'Limit after alarm is valid only for Deviation Alarms 81,83 and 85',
    80537: 'The Flag is referring to a controller that is not configured',
    80538: 'The Flag that Z is referring to must be set to ON (xx.1)',
    80539: 'The Flag that Z is referring to must refer to a controller or a Timer',
    80540: 'The Flag that Z is referring to must refer to a configured controller or Timer',
    80541: 'The running recipe cannot be saved with errors.',
    80542: "It's not valid to wait for this flag to be on.",
    80543: "It's not valid to wait for this flag.",
    80544: "It's not valid to set this flag off.",
    80545: "It's not valid to set this flag on without following with a set point (y or Z opcode).",
    80546: 'Jump to step one of this recipe',
    80547: 'Load Recipe Number From Ref Number',
    80548: 'There is no control set for C or c op codes.',
    80549: 'Are you sure that you want to stop this job?',
    80550: 'Event or associated point does not exist',
    80551: 'Are you sure that you want to put this job on HOLD?',
    80552: 'RECIPE EDITOR',
    80553: 'The flag is referring to a background sequence that is not configured',
    80554: 'The flag is referring to a background recipe file that does not exist',
    80555: 'Cannot open this subroutine',
    80556: 'This subroutine invokes another subroutine',
    80557: 'Bad Subroutine',
    80558: 'The flag is referring to a background recipe that is not valid',
    80559: 'Seconds',
    80560: 'Degrees',
    80561: 'Recipe library updated.',
    80562: 'Running recipe updated.',
    80563: 'The deviation set value for this step is below the minimum range.',
    80564: 'The deviation set value for this step is above the maximum range.',
    80565: 'There is no job currently running.',
    80566: 'The current job has been aborted.',
    80567: 'The requested recipe is not running.',
    80568: 'The recipe was modified by a remote user.',
    80569: 'The subroutine was modified by a remote user.',
    80570: 'System is not set in remote mode.',
    80571: 'Abort job failed',
    80572: 'Opcode y must follow F and Z',
    80701: 'Loop',
    80702: 'Details',
    80703: 'Reading',
    80704: 'Set Point',
    80705: 'Output',
    80706: 'Manual',
    80707: 'Auto',
    80708: 'Local',
    80709: 'Remote',
    80710: 'Tune',
    80711: 'Main',
    80721: 'Please enter the new Output signal.',
    80722: 'Range',
    80730: 'Are you sure you want to START standby logging?',
    80731: 'Are you sure you want to STOP standby logging?',
    80800: 'Background Recipe Builder',
    80801: 'Disable Alarms',
    80802: 'Disable Calculations',
    80803: 'Freeze Controllers',
    80804: 'Main Action',
    80805: 'Max Time (sec)',
    80806: 'Max Time Exceeded',
    80807: 'Set DO',
    80808: 'Set Controllers',
    80809: 'Set Boolean Flags',
    80810: 'Set AO',
    80811: 'Continue recipe after restart',
    80812: 'Error Loading',
    80813: 'All',
    80814: 'None',
    80815: 'Enable',
    80816: 'Select Recipe Number',
    80817: 'The recipe exists.  Do you want to replace it?',
    80818: 'On',
    80819: 'Off',
    80820: 'Set Alarm',
    80821: 'Prompt',
    80822: 'Go To',
    80823: 'Select Action',
    80824: 'Step Description',
    80825: 'Analog Outputs',
    80826: 'Set End Condition',
    80827: 'Boolean Flags',
    80828: 'Set Main Action',
    80829: 'Controllers',
    80830: 'Step #',
    80831: 'Set Max Time Exceeded Action',
    80832: 'Max Stage Time',
    80833: '(seconds)',
    80844: 'Wait for end condition',
    80845: 'Enabled',
    80846: 'Disabled',
    80847: 'Buffer',
    80848: 'Value',
    80849: 'Select',
    80850: 'Output Buffers to System',
    80851: 'Enter Constant Value',
    80852: 'Analog Input',
    80853: 'Step Time',
    80854: 'Value from system config',
    80855: 'Select Function',
    80856: 'Store Source Value in Target Buffer',
    80857: 'Add Source to Target and store result in Target',
    80858: 'Subtract Source from Target and store result in Target',
    80859: 'Multiply Source by Target and store result in Target',
    80860: 'Divide Target by Source and store result in Target',
    80861: 'Start averaging source AI, store result in Target',
    80862: 'Source',
    80863: 'Operator only works with analog inputs.',
    80864: 'Step Time',
    80865: 'Step Number',
    80866: 'Value from configuration',
    80867: 'Target',
    80868: 'Select Source',
    80869: 'Select Buffer',
    80870: 'Buffer Not Yet Assigned',
    80871: 'Value From Buffer',
    80872: 'Enter key manually',
    80873: 'Verification First Time Delay',
    80874: 'Verification Measurement Time',
    80875: 'Verification Final Time Delay',
    80876: 'Verification Gas',
    80877: 'Verification Tolerance',
    80878: 'Oxygen Probe Min Temp',
    80879: 'Burnoff Max Temp',
    80880: 'Burnoff Time',
    80881: 'Burnoff Max Recovery Time',
    80882: 'Max Impedance',
    80883: 'Impedance Recovery Time',
    80884: 'Impedance Shunt Value',
    80885: 'There are errors in this recipe.',
    80886: 'Recipe has been saved.',
    80887: 'Control',
    80888: 'Manual Mode',
    80889: 'Start Job',
    80890: 'Maintenance Menu',
    80891: 'Pen Style & Color',
    80892: 'Probe Test Menu',
    80893: 'Probe Test Params',
    80894: 'Probe Test Results',
    80895: 'Enable/Disable Shunt',
    80896: 'Select Analog Input for Shunt',
    80897: 'Start finding min value of source AI, store result in Target',
    80898: 'Start finding max value of source AI, store result in Target',
    80899: 'Analog Input SP',
    80900: 'Digital Input',
    80901: 'Digital Input SP',
    80902: 'Digital Output SP',
    80903: 'Analog Output SP',
    81045: 'No Operation',
    81065: 'Alarm',
    81066: 'Branch',
    81067: 'C Setpoint',
    81069: 'Event',
    81070: 'Flag',
    81071: 'Go Sub',
    81072: 'Set Temp',
    81074: 'Jump',
    81076: 'Limit',
    81080: 'Set Proc Fact',
    81083: 'Soak',
    81084: 'Set Timer',
    81085: 'Test Temp.Out',
    81087: 'Wait',
    81089: 'Test Input',
    81090: 'Setpoint',
    81099: 'Test Carbon',
    81100: 'Add Reference',
    81104: 'Test Temp',
    81105: 'Delay',
    81109: 'Test Probe T.',
    81110: 'Set Ref',
    81111: 'Test Probe mV',
    81113: 'Test Ref No',
    81114: 'Ramp',
    81116: 'Test Timer',
    81117: 'Test C out',
    81119: 'Wait < Setpoint',
    81121: 'Test Input <',
    81200: 'VALUE',
    81201: 'IO Port',
    81202: 'SCADA Port',
    81203: 'IP ADDRESS',
    81204: 'IP PORT',
    81205: 'COM PORT',
    81206: 'BAUD',
    81207: 'PARITY',
    81208: 'DATA SIZE',
    81209: 'STOP BIT',
    81210: 'SLAVE ID',
    81211: 'TIMEOUT',
    81212: 'Protocol change will reset the Parameter values to its default of the selected Protocol',
    81213: 'Proceed the change?',
    81214: 'The change will erase the setting of the Parameters',
    81216: 'CONFIGURATION MENU',
    81217: 'LANGUAGE',
    81218: 'FURNACE VOLUME (L.)',
    81219: 'SHOW CURSOR',
    81220: 'SYSTEM NAME',
    81221: 'COMPANY NAME',
    81222: 'EQUIPMENT NAME',
    81223: 'EQUIPMENT CODE',
    81224: 'AUTO LOG OFF (SEC.)',
    81225: 'LOG INTERVAL (SEC.)',
    81226: 'EXTERNAL DRIVE',
    81227: 'MAX GRAPH VALUE',
    81228: 'LOG ENCRYPTED',
    81229: 'WATCHDOG TYPE',
    81230: 'WATCHDOG ENABLED',
    81231: 'SYSTEM',
    81232: 'INTERNAL LOOPS',
    81233: 'COMMUNICATION',
    81234: 'FLAGS',
    81235: 'PROGRAM ALARMS',
    81236: 'SET CASCADE',
    81237: 'ON/OFF',
    81238: 'EXTERNAL',
    81239: 'EVENTS',
    81240: 'CONTROLLERS',
    81241: 'RECIPES',
    81242: 'CALCULATIONS',
    81243: 'PROBE TEST',
    81244: 'ON/OFF CONTROLLERS',
    81245: 'EXTERNAL CONTROLLERS',
    81246: 'PROCESS CALCULATIONS',
    81247: 'SLAVES',
    81248: 'Slave',
    81249: 'Slave number',
    81250: 'A slave with the same number already exists',
    81251: 'NONE',
    81252: 'ODD',
    81253: 'EVEN',
    81254: 'CPU TYPE',
    81255: 'Are you sure you want to delete the selected item?',
    81256: 'CONTROL LOOP',
    81257: 'PID',
    81258: 'MASTER LOOP',
    81259: 'SLAVE LOOP',
    81260: 'EXTERNAL CONTROLLER',
    81261: 'CONTROL ASSIGNMENT',
    81262: 'CONTROL MODE',
    81263: 'CONTROL ACTION',
    81264: 'CONTROL FLAG',
    81265: 'SET POINT SOURCE',
    81266: 'CYCLE TIME(SEC)',
    81267: 'INPUT TYPE',
    81268: 'INPUT COMP ID',
    81269: 'SHOW TITLEBAR',
    81270: 'FIRST OUTPUT ID',
    81271: 'SECOND OUTPUT ID',
    81272: 'UPDATE INTERVAL(SEC)',
    81273: 'DERIVATIVE APPROACH (%)',
    81274: 'CUT-BACK-LOW (%)',
    81275: 'CUT-BACK-HIGH (%)',
    81276: 'MIN OUTPUT (%)',
    81277: 'MAX OUTPUT (%)',
    81278: 'MIN OUTPUT DEVIATION',
    81279: 'MAX OUTPUT DEVIATION',
    81280: 'HIERARCHY',
    81281: 'PROPORTIONAL BAND (%)',
    81282: 'INTEGRAL TIME (SEC.)',
    81283: 'DERIVATIVE TIME (SEC.)',
    81284: 'IDENTIFIER',
    81285: 'NORMALIZATION VALUE',
    81286: 'IDENT.COEFFICIENT',
    81287: 'IDENT.HYSTERESIS',
    81288: 'IDENT.INTERVAL GAIN',
    81289: 'LOCATION',
    81290: 'INTERNAL',
    81291: 'EXTERNAL',
    81292: 'MODE',
    81293: 'LOW DEADBAND',
    81294: 'HIGH DEADBAND',
    81295: 'ON DELAY (sec.)',
    81296: 'OFF DELAY (sec.)',
    81297: 'OUTPUT COMP ID',
    81298: 'PUT ON VIEW',
    81299: 'TRIM',
    81300: 'SP OFFSET',
    81301: 'CARBON',
    81302: 'DEW POINT',
    81303: 'KN FLOW',
    81304: 'KN ATM',
    81305: 'DISS FLOW',
    81306: 'DISS ATM',
    81307: 'OXYGEN',
    81308: 'LOG O2',
    81309: 'LINEAR',
    81310: 'REDOX',
    81311: 'TEMPERATURE',
    81312: 'HEAT RAMP',
    81313: 'COOL RAMP',
    81314: 'TRACING',
    81315: 'QUENCHING',
    81319: 'ACCUBLENDOR',
    81320: 'TRUE-CARB',
    81321: 'TIME PROPORTIONAL SINGLE',
    81322: 'TIME PROPORTIONAL DUAL',
    81323: 'POSITION PROPORTIONING',
    81324: 'ON OFF SINGLE',
    81325: 'ON OFF DUAL',
    81326: 'DIRECT CURRENT SINGLE',
    81327: 'DIRECT CURRENT DUAL',
    81328: 'DUAL PULSED&CURRENT',
    81329: 'DUAL ONOFF&CURRENT',
    81330: 'DUAL ONOFF&TIME PROP.',
    81331: 'DIRECT',
    81332: 'REVERSED',
    81335: 'INDEPENDENT',
    81336: 'CASCADE',
    81337: 'FIXED',
    81338: 'RELATIVE',
    81339: 'LIMIT',
    81340: 'DEVIATION',
    81341: 'BAND',
    81351: 'ACTUAL PV ID',
    81352: 'OUTPUT ID',
    81353: 'LOCAL SP ID',
    81354: 'READ REMOTE SP ID',
    81355: 'SET REMOTE SP ID',
    81356: 'READ AUTO/MANUAL ID',
    81357: 'SET AUTO/MANUAL ID',
    81358: 'READ LOCAL/REMOTE ID',
    81359: 'SET LOCAL/REMOTE ID',
    81366: 'DO:OK TO SAMPLE',
    81367: 'DO: COMPENSATE',
    81368: 'RESISTANCE @800°C (Ω)',
    81369: 'AF AI',
    81370: 'AF USER DEFINED',
    81371: 'TEMPERATURE AI',
    81372: 'PROBE mV AI',
    81373: 'PF AI',
    81374: 'ID: ',
    81375: 'REDOX NO',
    81376: 'CO AI',
    81377: 'CO USER DEFINED',
    81378: 'H2 AI',
    81379: 'H2 USER DEFINED',
    81380: 'PF USER DEFINED',
    81381: 'Please select a CONTROL LOOP or PID to delete',
    81382: 'Each CONTROL LOOP requires at least one PID',
    81383: 'The number of CONTROL LOOPs exceeds the allowable number',
    81384: 'The number of PIDs for each CONTROL LOOP exceeds the allowable number',
    81385: 'Please select a EXTERNAL CONTROLLER to delete',
    81386: 'The number of EXTERNAL CONTROLLERs exceeds the allowable number',
    81387: 'Please select an item to delete',
    81388: 'The maximum allowable number has been reached. Please delete one before adding another.',
    81389: 'MMI',
    81390: 'DIN',
    81391: 'This will erase the setting of this item, would you like to continue?',
    81393: 'TRUSTED %C',
    81394: 'ADJUSTMENT TYPE',
    81395: 'NONE',
    81396: '%C COMPENSATION',
    81397: 'CO COMPENSATION',
    81398: 'ADJUST CO',
    81399: 'MONITOR',
    81401: 'Invalid Cascade Configuration found possibly due to the change of Control Configuration!',
    81402: 'Please select an entire Cascade Block or Slave Loop to delete',
    81403: 'Each Cascade Block requires at least one Slave',
    81404: 'Insufficient Control Loops remained for having another Slave Loop',
    81405: 'You have unsaved work! Would you like to save before exit?',
    81406: 'Some Master Loop has no ID assignment',
    81407: 'Some Master Loop has repeated ID assignment',
    81408: 'Some Slave Loop has no ID assignment',
    81409: 'Some Slave Loop has repeated ID assignment',
    81410: 'Insufficient Control Loops remained for having another Cascade Block',
    81411: 'The following Formula are not properly configured: ',
    81412: 'Flag value is out of range',
    81413: 'AI not configured, ID=',
    81414: 'Missing PAO to hold % value, ID=',
    81415: 'The Identification Value is outside the range of the AI point',
    81416: '1st Output not configured, ID=',
    81417: 'Missing PAO for the 1st Output, ID=',
    81418: '2nd Output not configured, ID=',
    81419: 'Missing PAO for the 2nd Output, ID=',
    81420: 'Identification Value is missing',
    81421: 'Invalid Limits Min/Max',
    81422: 'Invalid Proportional Band',
    81423: 'Invalid Normalization Value',
    81424: 'Invalid Sampling Interval',
    81425: 'Invalid Invalid Derivative Time',
    81426: 'Invalid Invalid Integral Time',
    81427: 'Invalid Invalid Trim Time',
    81428: 'Internal Loops Configuration Changed',
    81429: 'External Controllers Configuration Changed',
    81430: 'The AO Device Type has to be Pulsed, ID=',
    81431: 'WARNING: Oxygen Probe not detected - Op-code c/C cannot be used',
    81432: 'Missing Point for Temperature',
    81433: 'Missing Point for mV',
    81434: 'Invalid Configuration for Process Factor',
    81435: 'Invalid Configuration fo CO',
    81436: 'Invalid Configuration for H2',
    81437: 'Invalid Configuration for AF',
    81438: 'Point to hold Redox Number Value is not configured',
    81439: 'Point to hold Decarb@800 Value is not configured',
    81440: 'Process Factor shall be configured as Analog Input',
    81441: 'Reference for %C (AI) shall be configured',
    81442: 'CO shall be configured as Analog Input',
    81443: 'Analog Pont to hold calculated value is not configured',
    81444: 'Point to hold Calculated Value is reused',
    81450: ' 0 - 4/3Al+O2=2/3Al2O',
    81451: ' 1 - 2Ca+O2=2CaO',
    81452: ' 2 - 2Co+O2=2CoO',
    81453: ' 3 - 4/3Cr+O2=2/3CrO3',
    81454: ' 4 - 4Cu+O2=2Cu2O',
    81455: ' 5 - 2Fe+O2=FeO',
    81456: ' 6 - 4Fe3O4+O2=Fe2O3',
    81457: ' 7 - 6FeO+O2=2Fe3O4',
    81458: ' 8 - 2Mg+O2=2MgO',
    81459: ' 9 - 2Mn+O2=2MnO',
    81460: '10 - 2Ni+O2=2NiO',
    81461: '11 - 2Si+O2=2SiO',
    81462: '12 - 2Ti+O2=2TiO',
    81463: '13 - 2V+O2=2VO',
    81464: '14 - 2Zn+O2=2ZnO',
    81500: 'Manual Mode',
    81501: 'Software Updates',
    81502: 'Touch Screen Calibration',
    81503: 'Filename',
    81504: 'Size',
    81505: 'Modified',
    81506: 'You must reboot your controller for changes to take effect.',
    81507: 'Log Maintenance',
    81508: 'Recipe Maintenance',
    81509: 'Test Watchdog',
    81510: 'Internal IO Configuration',
    81551: 'Timers',
    81552: 'Timer',
    81553: 'Remaining',
    81554: 'Elapsed',
    81555: 'hh:mm',
    81556: 'mm:ss',
    81557: 'Please enter timer set value in seconds',
    81558: 'STANDBY',
    81559: 'RUNNING',
    81560: 'HOLD',
    81561: 'FINISHED',
    81562: 'Switch to manual mode first',
    81563: 'Caution: at least one timer is left in manual mode!',
    81564: 'Time Elapsed',
    81565: 'Timer on Hold',
    81566: 'TIMERS',
    81567: 'Start Rejected-Busy',
    81568: 'This will reset the timer to IDLE state. Do you want to continue?',
    81569: 'ENABLED',
    81570: '[seconds]',
    81571: '[minutes]',
    81572: 'Do you want to set time in MINUTES?',
    82000: 'Recipe Started',
    82001: 'Error Copying Recipe File',
    82002: 'Error Deleting Temporary Recipe File',
    82003: 'Error Loading Recipe File',
    82004: 'There are no valid steps to this recipe.',
    82005: 'Jump Failed',
    82006: 'Error Reading Reference Number.',
    82007: 'Recipe Error',
    82008: 'Event not configured',
    82009: 'Process Alarm',
    82011: 'Waiting for',
    82013: 'Error Loading Recipe for GoSub Command',
    82014: 'Process Alarm Tripped',
    82015: 'Go Sub Too Deep',
    82016: 'PAL ',
    82017: 'Background Alarm',
    82018: 'Abort or Continue?',
    82119: 'Modified Recipe Is No Longer Current',
    82120: 'Error Modifying Recipe',
    82121: 'Step cannot be deleted.  Set to NOP instead.',
    82122: 'Step references a controller that is not configured.  It will be ignored.',
    82123: 'Step references a timer that is not configured.  It will be ignored.',
    82500: 'Error in step',
    82501: 'Invalid storage request',
    82502: 'Invalid action',
    82503: 'Output request with no buffer locations',
    82504: 'Requests output that exceeds maximum size',
    82505: 'Cannot divide by 0',
    82506: 'Requests invalid number for alarm',
    82507: 'Requests invalid number for prompt',
    82508: 'Invalid value for GOTO',
    82509: 'Invalid range for GOTO',
    82510: 'Invalid argument for Time Exceeded Action',
    82511: 'Step Time Limit',
    82512: 'Invalid set value for analog output',
    82513: 'Setting all analog outputs to other than 0 (zero) or 1 (span) is not allowed',
    82514: 'Analog output does not exist',
    82515: 'Value is outside of analog output range',
    82516: 'Flag must be set to 0 or 1',
    82517: 'Flag is not configured',
    82518: 'Controller is invalid or controller is not configured',
    82519: 'Controller enable/disable must be 0/1',
    82520: 'Requesting to set controller to invalid value',
    82521: 'Digital output must be set to 0 or 1',
    82522: 'Digital output does not exist',
    82523: 'End condition',
    82524: 'Invalid end condition',
    82525: 'Invalid value',
    82526: "Component ID doesn't exist",
    82527: 'Buffer location exceeds max index',
    82528: "Request time for step that doesn't exist",
    82529: "Request to set flag that doesn't exist or isn't configured",
    82530: "Field in system configuration doesn't exist",
    82531: 'Requests value that cannot be decoded',
    82532: 'Step number is incorrect or out of sequence',
    82533: 'Resume tag must be set to 0 or 1',
    82534: 'Recipe number is not consistent with filename',
    82535: 'Background Recipe',
    82536: "Freeze calculation request refers to a point that doesn't exist",
    82537: "Freeze calculation request refers to a calculation that doesn't exist",
    82538: 'Request to freeze alarm is not valid',
    82539: 'Request to freeze controller is not valid',
    82540: 'Cannot disable controller and set setvalue',
    82541: 'Missing or invalid recipe flag',
    82542: 'Invalid value for analog input',
    82543: "Analog input doesn't exist",
    82544: 'Shunt must be set to ON or OFF',
    82545: 'Shunt must use analog input from internal IO',
    82601: 'INTERNAL/EXTERNAL CONTROLLERS',
    82602: 'Multiple use of Flag',
    82603: 'Cannot use again assignment TEMPERATURE, use different Flag# instead',
    82604: 'Cannot use again assignment Carbon/DewPoint/Oxygen/Redox, use different Flag# instead',
    82605: 'Master Controller used one more time',
    82606: 'Slave Controller used one more time',
    82607: 'Cascade Controller is not used in any cascade chain',
    82608: 'Flag value is out of range',
    82609: 'Analog Input not configured, ID=',
    82610: 'Digital Input not configured, ID=',
    82611: 'Digital Output not configured, ID=',
    82612: 'Missing/Invalid Process Value input',
    82613: 'Missing/Invalid Send Set Point Value output',
    82614: 'Missing Local SP input',
    82615: 'Missing Output Value reading',
    82616: 'Missing Remote SP input',
    82617: 'Missing Remote Status reading',
    82618: 'Missing Set-to-Remote output',
    82619: 'Missing Auto Status reading',
    82620: 'Missing Set-to-Auto output',
    82621: 'No slaves configured for the Master Controller',
    82622: 'Master controller is using a slave that does not exist',
    83001: 'Error Loading Recipe.  Load Aborted.',
    83002: 'Error Starting Job - Job Already Running',
    83003: 'Step',
    83004: 'Loading Subroutine',
    83005: 'Jump to new recipe',
    83006: 'Error resuming recipe.',
    83007: 'Failed to start the procedure',
    83008: 'Select Alarm',
    83009: 'Select Op Code',
    83010: 'Select Event',
    83011: 'Select Flag',
    83012: 'Resuming recipe at boot',
    83013: 'Resuming subroutine at boot',
    84100: 'No Error has occurred',
    84101: 'Invalid file descriptor (port was not opened correctly)',
    84102: 'Unable to allocate memory tables (POSIX)',
    84103: 'Caught a non-blocked signal (POSIX)',
    84104: 'Operation timed out (POSIX)',
    84105: 'The file opened by the port is not a character device (POSIX)',
    84106: 'The port detected a break condition',
    84107: 'The port detected a framing error(usually caused by incorrect baud rate settings)',
    84108: 'There was an I/O error while communicating with the port',
    84109: 'Character buffer overrun',
    84110: 'Receive buffer overflow',
    84111: 'The port detected a parity error in the received data',
    84112: 'Transmit buffer overflow',
    84113: 'General read operation failure',
    84114: 'General write operation failure',
    84121: 'The configured COM PORT cannot be opened',
    84131: 'Invalid MMI slave address',
    84132: 'Invalid 10Pro Command',
    84133: 'Unable to send message to 10Pro',
    84134: 'Error in the message received',
    84135: 'Value out of range',
    84136: '10Pro driver not initialized',
    84137: '10Pro not responding',
    84200: 'Error converting MMI data to recipe data',
    84201: 'Error converting recipe data to MMI data',
    84202: 'Unable read/write recipe file.',
    84203: 'Start job returned an error',
    84250: 'System',
    84251: 'User defined',
    84252: 'New item ...',
    84253: 'Description',
    84254: 'Readonly',
    84255: 'Sticky',
    84256: 'Halt',
    84257: 'Recipe Alarm',
    84258: 'ID',
    84259: 'An alarm with the same ID already exists',
    84300: 'Controller',
    84301: 'Sequence/Procedure',
    84302: 'Recipe Reads Flag ',
    84303: 'Recipe Sets Flag',
    84304: 'Type',
    84305: 'Recipe Flag',
    84306: 'A flag with the same ID already exists',
    84310: 'Tap below to select',
    84311: 'Disabled',
    84312: 'Enabled',
    84313: 'Turn OFF',
    84314: 'Turn ON',
    84315: 'List of Events',
    84316: 'List of Flags',
    84350: 'Shop Order No.',
    84351: 'Load No.',
    84352: 'Part No.',
    84400: 'View Log',
    84401: 'View Chart',
    84420: 'Vertical Markers',
    84421: 'Maximum Points',
    84440: 'Digital Controller Flag',
    84441: 'Invalid input comp id',
    84442: 'Invalid output comp id',
    84460: 'TEST INTERVAL (sec.)',
    84461: 'MIN. TEMPERATURE',
    84462: 'MAX. IMPEDANCE (KΩ)',
    84463: 'RECOVERY TIME (sec.)',
    84464: 'BURN OFF TIME (sec.)',
    84465: 'MAXIMUM RECOVERY TIME (sec.)',
    84466: 'MAXIMUM TEMPERATURE',
    84467: 'FIRST TIME DELAY (sec.)',
    84468: 'MEASUREMENT TIME (sec.)',
    84469: 'FINAL TIME DELAY (sec.)',
    84470: 'VERIFY GAS (%)',
    84471: 'VERIFY TOLERANCE (%)',
    84472: 'SHUNT RESISTOR (KΩ)',
    84473: 'AUTOMATIC TEST',
    84474: 'Impedance',
    84475: 'Verification',
    84476: 'Burn off',
    84477: 'Both Tests',
    84478: 'View Last Test Results',
    84479: 'General',
    84480: 'Test procedure started, navigate to Control Tab now',
    84481: 'Probe is not configured',
    84482: 'Error in test procedure',
    84483: 'Tolerance',
    84484: 'Probe',
    84485: 'View Probe Alarms',
    84486: 'Impedance Test',
    84487: 'Probe Burn Off',
    84488: 'Probe Verification',
    84489: 'Standby Logging',
    84490: 'Recipe was modified',
    84491: 'Recipe was not modified',
    84492: "Can't load recipe",
    84493: 'Error loading Standby',
    84494: 'Computer watchdog is disabled',
    84495: 'Computer watchdog is disabled in simulation mode',
    84496: 'IO Configuration errors detected!',
    84497: 'Please see file:',
    84498: 'Internal IO configuration mismatch!',
    84500: 'Event',
    84501: 'An event with the same ID already exists',
    84502: 'Point already assigned to an event',
    84503: 'Point no longer exists',
    84504: 'This will remove all current events and reassign all points. Continue?',
    84505: 'Auto Assignment',
    84506: 'I/O COMPONENT ID',
    84507: 'I/O COMPONENT TYPE',
    84508: 'EVENT#',
    84509: 'Point does not exist',
    84510: 'Point can only read',
    84540: 'Impedance',
    84541: 'Burnoff',
    84542: 'Verification',
    84543: 'Date',
    84544: 'Time',
    84545: 'Recovery Time (sec)',
    84546: 'Probe signal (mV)',
    84547: 'Temperature',
    84548: 'O2 reference (%)',
    84549: 'Impedance(KΩ)',
    84550: 'O2 measured (%)',
    85000: 'Successful Operation',
    85001: '<1> Illegal Argument Error',
    85002: '<2> Illegal State Error',
    85003: '<3> Evaluation Expired',
    85004: '<4> Missing Data Table Error',
    85005: '<5> Illegal Slave Address',
    85006: 'Modbus TCP Server Started',
    85007: 'Modbus RTU Server Started',
    85008: 'Modbus Slave Unexpectedly Terminated',
    85064: '<64> I/O Communication Error',
    85065: '<65> I/O System Error',
    85066: '<66> Port or Socket cannot be opened',
    85067: '<67> Serial Port is already opened',
    85068: '<68> Cannot establish TCP connection',
    85069: '<69> TCP Connection was closed',
    85070: '<70> Socket library error',
    85071: '<71> Socket already bound',
    85072: '<72> Listen failed',
    85073: '<73> Number of usable file descriptors has been exceeded',
    85074: '<74> You do not have permission to access this port',
    85075: '<75> TCP port is not available on this machine',
    85128: '<128> Bus protocol error',
    85129: '<129> Checksum error',
    85130: '<130> Invalid frame number error',
    85131: '<131> Invalid reply error',
    85132: '<132> Reply timeout error',
    85133: '<133> Send timeout error',
    85160: '<160> Modbus exception response',
    85161: '<161> Modbus illegal function response',
    85162: '<162> Modbus illegal address response',
    85163: '<163> Modbus illegal value response',
    85164: '<164> Modbus slave failure response',
    85201: 'Interface',
    85202: 'Use DHCP',
    85204: 'Network Mask',
    85205: 'Gateway',
    85206: 'Error running script ',
    85207: 'Error accessing file ',
    85208: 'Invalid IP Address',
    85209: 'Invalid Network Mask',
    85210: 'Invalid Gateway',
    85211: 'Please enter the interface name.',
    85212: 'Interface already exists.',
    85213: 'Please select the default interface.',
    85214: 'Default Interface',
    85215: 'Ethernet Interfaces',
    85216: 'Domain Name Servers',
    85217: 'Primary Address',
    85218: 'Secondary Address',
    85219: '',
    85220: '',
    85226: 'Export Log Files',
    85227: 'Delete Log Files',
    85228: 'Log Maintenance',
    85229: 'Select file(s)...',
    85246: 'Export Recipe Files',
    85247: 'Import Recipe Files',
    85248: 'Delete Recipe Files',
    86001: 'Autotune failed to start',
    86002: 'Autotune LOOP# ',
    86004: 'Please select a set to autotune',
    86005: 'Operation Rejected. Only one loop at the time can be autotuned',
    86006: 'Are you sure you want to stop autotuning procedure?',
    86007: 'Autotuning failed',
    86008: 'Set #',
    86009: 'Identification #',
    86010: 'Identification Value 2',
    86011: 'Identification Value 3',
    86012: 'New Set of PID Parameters',
    86013: 'Do you want to accept this set of PID parameters?',
    86014: 'Cannot perform this operation while job is running',
    86015: '#',
    86016: 'Description',
    86100: 'Standby',
    86101: 'Runtime',
    86102: 'Wait for digital output',
    86103: 'Wait for digital input',
    86104: 'Wait for flag',
    86105: 'Wait for analog input ',
    86106: 'Wait for analog output ',
    86494: 'Probe temperature too low',
    86495: 'OFF: DewPoint deviation band alarm',
    86496: 'DewPoint deviation band alarm',
    86497: 'OFF:O2 deviation band alarm',
    86498: 'O2 deviation band alarm',
    86499: 'OFF:Redox deviation band alarm',
    86500: 'Redox deviation band alarm',
    86501: 'OFF:Temperature deviation alarm',
    86502: 'Temperature deviation band alarm',
    86503: 'OFF:C% deviation band alarm',
    86504: 'C% deviation band alarm',
    86505: 'OFF:probe/controller temperature deviation',
    86506: 'Probe/Controller temperature deviation alarm',
    86507: 'Probe impedance too high',
    86508: 'Impedance recovery time exceeded',
    86509: 'Burn off recovery time exceeded',
    86510: 'Burn off temperature exceeded',
    86511: 'Verification exceeded tolerance',
    86514: 'LIMIT statement timeout',
    86516: 'Illegal Ramp',
    86517: 'Automatic Job Resume',
    86518: 'Subroutine does not exist',
    86519: 'Illegal jump requested',
    86531: 'Error in subroutine',
    86533: 'Gosub too deep',
    86539: 'Bad load returning from subroutine',
    86560: 'Furnace Volume in System is required if there is at least one Controller whose assignment type is Kn',
    86561: 'Touch Screen Calibration was not found.',
    86562: 'Internal IO Configuration was not found.',
    86563: 'The procedure will last for up to 2 minutes. Touch X signs that will show in each corner of the screen. After calibration is complete, system will be updated with new calibration values. Do you want to proceed?',
    86564: 'Failed to launch external application for Internal IO Configuration, this application will now restart',
    86565: 'Failed to launch external application for Touch Screen Calibration, this application will now restart',
    86566: 'Is there a terminal or other application running in the background? If you are not sure click YES - this will abort the procedure. It is very important to answer accurately to this question.',
    86590: 'Backplane PIC',
    86591: 'Backplane PIC Version',
    86592: 'Mainboard PIC',
    86593: 'Mainboard PIC Version',
    86600: 'Company Name',
    86601: 'System Name',
    86602: 'Equipment Name',
    86603: 'Equipment Code',
    86604: 'Automatic log-out delay',
    86605: 'Logging interval',
    86606: 'External(USB) drive letter',
    86607: 'Chart Recorder: max. value for y axis',
    86608: 'Write encrypted data to the log file',
    86609: 'Set controller watchdog type',
    86610: 'Enable/Disable controller watchdog',
    86611: 'Show Cursor',
    86612: 'Furnace Volume has to be greater than 0 if there is at least one controller whose assignment type is of Kn flow or Kn atm',
    86613: 'Language used for display',
    86614: 'CPU Type of the device',
    86615: 'Show titlebar',
    86616: 'Maximum number of TCP connections',
    86617: 'TCP port number',
    86618: 'Have an alarm with less than this number of MB free',
    86619: '',
    86620: 'IP Address',
    86621: 'IP Port',
    86622: 'Com Port',
    86623: 'Baud rate',
    86624: 'Parity',
    86625: 'Data Size',
    86626: 'Stop Bit',
    86627: 'Slave ID',
    86628: 'Timeout (ms)',
    86629: '',
    86630: '',
    86631: '',
    86632: '',
    86633: '',
    86634: '',
    86635: '',
    86636: '',
    86637: '',
    86638: '',
    86639: '',
    86640: 'Set Point value with assigned set of PID parameters',
    86641: 'Identifier 2',
    86642: 'Identifier 3',
    86643: 'Proportional Band in % of Normalization Value (Kp=100/pb)',
    86644: 'Integral Time in seconds (Ki=Kp/Ti)',
    86645: 'Derivative Time in seconds (Kd=Kp*Td)',
    86646: 'PID Sample Time: calculate new output interval(seconds)',
    86647: 'Normalization Value (divides AI reading)',
    86648: 'Error limit(%) below the SP to start integral accumulator',
    86649: 'Error limit(%) above the SP to stop integral accumulator',
    86650: 'Limit to start derivative control (percentage of Prop.Band)',
    86651: 'Min Output (%)',
    86652: 'Max Output (%)',
    86653: "Cascade parameter for slave's SP (%)",
    86654: 'Set point offset (%)',
    86655: '',
    86656: '',
    86657: '',
    86658: '',
    86659: '',
    86660: 'Control loop description',
    86661: 'Add controller to a separate tab',
    86662: 'Unique number assigned to a controller; 0 reserved for T & C%',
    86663: 'Control Assignment',
    86664: 'Control Mode',
    86665: 'REVERSED: When input<SP, going up, output has to go down',
    86666: 'Hierarchy: Independent or Cascade',
    86667: 'Input Component ID',
    86668: '',
    86669: 'Output Component ID',
    86670: 'Second Output Component ID (Dual Mode only)',
    86671: 'Cycle Time for pulse width modulation',
    86672: 'Set point source',
    86673: 'Autotune identification coefficient',
    86674: 'Autotune identification hysteresis',
    86675: 'Autotune identification interval gain',
    86676: '',
    86677: '',
    86678: '',
    86679: '',
    86680: 'Controller description',
    86681: 'Add controller to a separate tab',
    86682: 'Unique positive number assigned to a controller; 0 reserved for T & C%',
    86683: 'Controller assignment',
    86684: 'Controller Hierarchy',
    86685: 'Component ID for getting process value from the controller',
    86686: 'Component ID for getting output signal value from the controller',
    86687: 'Component ID for getting local set point from the controller',
    86688: 'Component ID for getting remote set point from the controller',
    86689: 'Component ID to send remote set point to controller',
    86690: 'Component ID to get Auto/Manual status from the controller',
    86691: 'Component ID to set Auto/Manual status',
    86692: 'Component ID to read Local/Remote status',
    86693: 'Component ID to set Local/Remote status',
    86694: "Cascade parameter for slave's SP (%)",
    86695: 'Setpoint offset (%)',
    86696: '',
    86697: '',
    86698: '',
    86699: '',
    86700: 'Calculation description',
    86701: 'Probe temperature component ID',
    86702: 'Probe mV component ID',
    86703: 'This number determines the constant coefficients for the chosen metal.',
    86704: 'Process Factor Component ID',
    86705: 'Process Factor set by user. This value will be used if the Component ID above is not configured.',
    86706: 'CO Component ID',
    86707: 'CO% set by user. This value will be used if the Component ID above is not configured.',
    86708: 'H2 Component ID',
    86709: 'H2% set by user.This value will be used if the Component ID above is not configured.',
    86710: 'Alloy Factor Component ID',
    86711: 'Alloy Factor set by user. This value will be used if the Component ID above is not configured.',
    86712: 'Resistance of decarburized wire @ 800 °C (Ω)',
    86713: 'Component ID to hold the result (calculated value)',
    86714: 'Process Factor Compensation Method',
    86715: 'Source of Trusted (Reference) %C value',
    86716: 'Safe Conditions for Sampling',
    86717: 'Command that enables/disables compensation calculation',
    86718: '',
    86719: '',
    86720: 'Controller Description',
    86721: 'Unique positive number assigned to a controller',
    86722: 'Control Action',
    86723: 'DO deadband values represent deviation from the SP, or absolute values.',
    86724: 'Digital Controller Low Deadband',
    86725: 'Digital Controller High Deadband',
    86726: 'Digital Controller On Delay',
    86727: 'Digital Controller Off Delay',
    86728: 'Digital Controller Input Type',
    86729: 'Digital Controller Input Component ID',
    86730: 'Digital Controller Output Component ID',
    86731: 'IN ALARM WHEN DO IS:',
    86732: 'DO status that triggers the Alarm',
    86733: '',
    86734: '',
    86735: '',
    86736: '',
    86737: '',
    86738: '',
    86739: '',
    86740: 'Recipe Alarm Description',
    86741: 'Unique Recipe Alarm ID (1-79)',
    86742: 'Sticky alarms do not go away after acknowledgement',
    86743: 'Halting alarms do not allow system to advance to the next step.',
    86744: '',
    86745: '',
    86746: '',
    86747: '',
    86748: '',
    86749: '',
    86750: '',
    86751: '',
    86752: '',
    86753: '',
    86754: '',
    86755: '',
    86756: '',
    86757: '',
    86758: '',
    86759: '',
    86760: 'Flag Description',
    86761: 'Unique number assigned to the flag. The allowable range is dependent on the Flag Type selected.',
    86762: 'Flag Type: input / output / special recipe',
    86763: '',
    86764: '',
    86765: '',
    86766: '',
    86767: '',
    86768: '',
    86769: '',
    86770: '',
    86771: '',
    86772: '',
    86773: '',
    86774: '',
    86775: '',
    86776: '',
    86777: '',
    86778: '',
    86779: '',
    86780: 'Unique number for event ID (0-79)',
    86781: 'Event type: input or output',
    86782: 'Real Component ID for the event',
    86783: '',
    86784: '',
    86785: '',
    86786: '',
    86787: '',
    86788: '',
    86789: '',
    86790: '',
    86791: '',
    86792: '',
    86793: '',
    86794: '',
    86795: '',
    86796: '',
    86797: '',
    86798: '',
    86799: '',
    86800: 'Scheduled probe test interval; Set to 0 to disable automatic probe tests',
    86801: 'Set which probe test(s) should run automatically',
    86802: 'Minimum probe temperature required to run a test',
    86803: '',
    86804: '',
    86805: '',
    86806: '',
    86807: '',
    86808: '',
    86809: '',
    86810: 'Maximum probe impedance that does not set IMPEDANCE FAULT ALARM',
    86811: 'Time that allows probe to reach 99% of initial mV reading or IMPEDANCE RECOVERY TIME EXCEEDED alarm is triggered',
    86812: 'Shunt Resistance. Default is 10 KΩ',
    86813: '',
    86814: '',
    86815: '',
    86816: '',
    86817: '',
    86818: '',
    86819: '',
    86820: 'Duration of Burn Off step.',
    86821: 'Time that allows probe to reach 99% of pre-burn off mV reading or RECOVERY TIME EXCEEDED alarm is triggered',
    86822: 'Limit for the probe temperature during burn off test; if exceeded, TEMPERATURE EXCEEDED alarm is triggered.',
    86823: '',
    86824: '',
    86825: '',
    86826: '',
    86827: '',
    86828: '',
    86829: '',
    86830: 'Time to purge probe with reference gas',
    86831: 'Time to sample & average temperature and mV',
    86832: 'Final time delay for probe to adapt to process atmosphere',
    86833: 'Content of O2 in reference gas',
    86834: 'Allowed error / deviation between the probe reading and reference gas value',
    86835: '',
    86836: '',
    86837: '',
    86838: '',
    86839: '',
    86840: 'MEMORY MAP',
    86841: 'I/O MAPPING',
    86842: 'CURRENT JOB MAPPING',
    86843: 'MISCELLANEOUS',
    86844: 'MMI MULTIPLIER',
    86845: 'PROBE TEST MAPPING',
    86846: 'CONTROLLER MAPPING',
    86847: 'I/O ALARMS MAPPING',
    86848: 'GENERAL',
    86849: 'NETWORK SETTINGS',
    86850: 'SET DATE/TIME',
    86851: 'I/Os',
    86852: 'Node Guarding',
    86853: 'Receive by RTR',
    86854: 'TCP CONNECTIONS',
    86855: 'TCP PORT NUMBER',
    86856: 'DISK SPACE ALARM (MB)',
    86857: 'Comp ID for OpCode Y',
    86858: 'Select Comp ID for input used in Y test',
    86859: 'The following communication ports are configured to use the same serial port:',
    86881: 'DISABLE ALL WDGS',
    86882: 'All backplane watchdogs will be disabled',
    86883: 'AI FILTERING',
    86884: 'Filter algorithm to be applied to internal AIs',
    86885: 'Moving Average',
    86886: 'Purified Average',
    86900: 'Point not found',
    86901: 'REGISTER',
    86902: 'POINT TYPE',
    86903: 'Analog Input',
    86904: 'Analog Output',
    86905: 'Digital Input',
    86906: 'Digital Output',
    86907: 'Actual Value',
    86908: 'Set Value',
    86909: 'VALUE TYPE',
    86910: 'ACCESS LEVEL',
    86911: 'Read',
    86912: 'Write',
    86913: 'Read/Write',
    86914: 'MULTIPLIER',
    86915: 'Unable save change to configuration file',
    86916: 'Must select a component',
    86917: 'This point can only be set to read',
    86918: 'Point no longer exists',
    86919: 'Wrong access level',
    86920: 'Point is already mapped',
    86921: 'NOT USED',
    86922: 'Invalid multiplier value',
    86923: 'Invalid register value',
    86924: 'Invalid access level',
    86925: 'Invalid value type',
    86926: 'Invalid point type',
    86927: 'Invalid component id',
    86928: 'IO Mapping Error(s)',
    86929: "Point can't have WRITE access",
    86930: 'Duplicate addressing',
    86931: 'Unable to read IO mapping data from configuration file',
    87000: 'NOT USED',
    87001: 'Date of last probe test',
    87002: 'Time of last probe test',
    87003: 'Probe maintenance interval',
    87004: 'Probe impedance max recovery time',
    87005: 'Probe burn off time',
    87006: 'Probe burn off max recovery time',
    87007: 'Probe impedance',
    87008: 'Probe impedance recovery time',
    87009: 'Probe burn off millivolts',
    87010: 'Probe burn off temperature',
    87011: 'Probe burn off recovery time',
    87012: 'Remaining time to next probe test',
    87013: 'Probe maintenance control',
    87014: 'Probe maintenance sequence indicator',
    87015: 'Start probe test only',
    87016: 'Start burn off only',
    87017: 'Start full maintenance',
    87018: 'Abort probe test',
    87021: 'REGISTER',
    87022: 'Address <%1> is already mapped in <%2>',
    87023: 'Invalid register value',
    87024: 'This address is already mapped to <%1>',
    87025: 'Address <%1> from <%2> is already mapped in <%3>',
    87026: 'Unable to read probe test mapping data from configuration file',
    87100: 'Invalid register value',
    87101: 'REGISTER',
    87200: 'Job is running',
    87201: 'Job is on hold',
    87202: 'Job number',
    87203: 'Recipe number',
    87204: 'Background recipe number',
    87205: 'Background recipe step number',
    87206: 'Recipe remaining soak time (sec.)',
    87207: 'Master timer',
    87208: 'Invalid multiplier value',
    87209: 'Invalid register value',
    87210: 'Invalid access level',
    87211: 'Current Program Alarm',
    87212: 'PARAMETER',
    87213: 'REGISTER',
    87214: 'MULTIPLIER',
    87215: 'Job parameter already used',
    87216: 'Register is reserved',
    87217: 'Foreground recipe step number',
    87218: 'Start Job',
    87219: 'Stop Job',
    87220: 'Hold Job',
    87221: 'Start/Stop Job',
    87222: 'Pause Job',
    87300: 'Invalid multiplier value',
    87301: 'Invalid register value',
    87302: 'Invalid access level',
    87303: 'PARAMETER',
    87304: 'REGISTER',
    87306: 'NOT USED',
    87307: 'Register is reserved',
    87308: 'MULTIPLIER',
    87400: 'Remote Mode Status',
    87401: 'Auto Mode Status',
    87402: 'Invalid control flag',
    87403: 'Invalid parameter',
    87404: 'Controller not found',
    87405: 'PARAMETER',
    87406: 'Controller parameter already mapped',
    87407: 'Unable to load controller mapping from configuration file.',
    87420: 'TRIM DEFAULT VAL.',
    87421: 'Value when reading is not available',
    87422: 'GET TRIM FROM',
    87423: 'Select point type (input/output) for trim',
    87424: 'SELECT TRIM CHANNEL',
    87425: 'Select channel that reads trim value',
    87426: 'TRIM AV/SP',
    87427: 'Value comes from Actual Value or the Set Point?',
    87428: 'MAX TRIM',
    87429: 'Set max offset for ratio (+/-)',
    87430: 'AIR DEFAULT FLOW',
    87431: 'Air flow when reading is not available',
    87432: 'SELECT AIR CHANNEL',
    87433: 'Select channel that reads air flow',
    87434: 'AIR AV/SP',
    87435: 'Value comes from Actual Value or the Set Point?',
    87436: 'BASE RATIO',
    87437: 'Base ratio constant AIR/GAS',
    87438: 'GAS CHANNEL',
    87439: 'Select channel to set GAS flow',
    87440: 'FINAL RATIO',
    87441: 'Compensated ratio AIR/GAS',
    87442: 'TEMP. USER DEFINED',
    87443: 'Value set by user when reading is not available',
    87444: 'REACTION COEFF.',
    87445: 'Chemical Reaction Coefficient',
    87446: 'TOTAL FLOW ID.',
    87447: 'Point that holds calculated total flow',
    87450: 'ACTUAL VALUE',
    87451: 'SET POINT',
    87500: 'Backup/Restore',
    87501: 'Folder not found',
    87502: 'Unable to create folder',
    87503: 'If a custom backup already exists on the target device, it will be overwritten. Continue?',
    87504: 'Unable to clear backup folder',
    87505: 'Backup',
    87506: 'No files were selected',
    87507: 'Unable to copy file',
    87508: 'Backup completed successfully',
    87509: 'Unable to calculate checksum value for the following file',
    87510: 'Unable to write',
    87511: 'No backup folder found',
    87512: 'Restore will overwrite any files that already exist and when finished will restart controller. Continue?',
    87513: 'Restore will overwrite any files that already exist and when finished will restart application. Continue?',
    87514: 'Restore will overwrite any files that already exist. Continue?',
    87515: 'File not found',
    87516: 'Invalid checksum value',
    87517: 'Unable to overwrite file during restore',
    87518: 'Unable to create folder',
    87519: "Checksum values don't match between the following two files",
    87520: 'Restore completed successfully',
    87521: 'Destination folder not found and was unable to create it.',
    87522: 'Unable to access external media.',
    87523: 'No external drive has been defined on this system. Please verify system configuration or environment variable',
    87524: 'Backup/restore has processed all files but the following error(s) have occurred',
    87525: 'Backup/restore stop processing files after to many errors. Below are some of the errors that have occurred',
    87526: 'Please wait while files are copied ...',
    87527: 'The following file has changed after it was copied',
    87528: 'No permission to read file',
    87529: 'No files to import',
    87530: 'The following folder containing files to import was not found',
    87531: 'The following folder containing files to export was not found',
    87532: 'No files to export',
    87533: 'The following folder containing files to delete was not found',
    87534: 'No files to delete',
    87535: 'File not found',
    87536: 'Unable to write the following file',
    87537: 'Verify if there is enough space.',
    87538: 'Backup failed',
    87539: 'Restore failed',
    87542: 'Application will now restart',
    87543: "The checksum value of the following file does not match it's checksum value when it was first backed up.",
    87544: 'Controller will now restart',
    87545: 'Unable to create temp backup folder prior to restore',
    87546: 'Unable to rename file',
    87547: 'Unable to rename folder',
    87548: 'Unable to remove folder',
    87549: 'Creating persistent backup (TC Backup)',
    87550: 'Unable to open file',
    87551: 'Unable to write to file',
    87552: 'Unable to sync file',
    87553: 'No write access permission for file',
    87554: 'Unable to read from file',
    87555: 'Device not found',
    87556: 'No read access permission for file',
    87557: 'Unable to read to file',
    87558: 'List of backup files is empty',
    87559: 'Invalid file format',
    87560: 'Many more files where not found. To many of them to display',
    87561: 'Failed to disable internal I/O watchdog. Operation aborted',
    87562: 'Environment variable not defined',
    87563: 'Full Backup',
    87564: 'Full Restore',
    87565: 'Custom Backup',
    87566: 'Custom Restore',
    87567: 'FULL BACKUP/RESTORE',
    87568: 'CUSTOM BACKUP/RESTORE',
    87569: 'Restore from - Select One',
    87570: 'Select files/folders to backup',
    87571: 'Select files/folders to restore',
    87572: 'Following message(s) are generated',
    87573: 'WARNING: Restore is partialy completed!',
    87574: 'Failed to make persistent backup!',
    87575: 'There are no files to restore',
    87576: 'No files where backed up',
    87577: 'A time out occurred while trying to copy file',
    87578: 'Bypass checksum validation',
    87600: 'Component <%1> is already mapped in <%2>',
    87601: 'This will remove all alarm mappings permanently. Continue?',
    87602: 'This will add all missing alarm mappings. Continue?',
    87603: 'Error trying to read data from configuration file for alarm mapped points.',
    87604: 'I/O Alarms Mapping Error',
    87700: 'TIMERS',
    87701: 'FLAG ID',
    87702: 'Range',
    87703: 'DESCRIPTION',
    87704: 'Description',
    87705: 'COUNT OPTION',
    87706: 'Count up or down',
    87707: 'Count Up',
    87708: 'Count Down',
    87709: 'Basic',
    87710: 'Guaranteed Soak',
    87711: 'Guaranteed Start',
    87712: 'Guatanteed Speed',
    87713: 'Unknown',
    87714: 'Type',
    87715: 'Select type of timer',
    87716: 'RESET DI',
    87717: 'Select DI to reset timer',
    87718: 'START DI',
    87719: 'Select DI to start/resume timer',
    87720: 'HOLD DI',
    87721: 'Select DI to hold timer',
    87722: 'SP AI',
    87723: 'Select AI to hold SP value',
    87724: 'PV AI',
    87725: 'Select AI to monitor for guaranteed timer functions',
    87726: 'TIME EXCEEDS DO',
    87727: 'DO to activate when time elapses',
    87728: 'HIGH LIMIT',
    87729: 'In units of process value AI',
    87730: 'LOW LIMIT',
    87731: 'Timer already exists',
    87732: 'Invalid flag number',
    87733: 'Invalid type',
    87734: 'Reset DI point does not exist',
    87735: 'Start DI point does not exist',
    87736: 'Hold DI point does not exist',
    87737: 'Invalid time SP AI',
    87738: 'Invalid process value AI',
    87739: 'Time exceeds DO point does not exist',
    87740: 'Invalid high limit',
    87741: 'Invalid low limit',
    87742: 'The following AI point does not exist',
    87743: 'Either high or low limit must have a value but not both',
    87744: 'Unable to load timer data from configuration file',
    87745: 'Unable to remove timer data from configuration file',
    87746: 'Unable to add timer data to configuration file',
    87747: 'Must select a process value',
    87748: 'High limit must be grater then low limit',
    87749: 'REFERENCE NUMBER',
    87750: 'Reference No.',
    88000: 'ANALOG INPUTS',
    88001: 'ANALOG OUTPUTS',
    88002: 'DIGITAL I/Os',
    88003: '',
    88004: 'EXTERNAL AIs',
    88005: 'EXTERNAL AOs',
    88006: 'EXT.DIGITAL I/Os',
    88007: 'ALARMS',
    88008: 'ADDRESS 1',
    88009: 'ADDRESS 2',
    88010: 'ADDRESS 3',
    88011: 'COLD JUNCTION',
    88012: 'Invalid description',
    88013: 'Invalid unit',
    88014: 'Invalid address 1',
    88015: 'Invalid address 2',
    88016: 'Invalid address 3',
    88017: 'Invalid driver',
    88018: 'Invalid device type',
    88019: 'Invalid min',
    88020: 'Invalid max',
    88021: 'Invalid number of decimals',
    88022: 'Delay to turn ON DO',
    88023: 'Retransmit point',
    88024: 'Retransmit Value Type',
    88025: 'Retransmit Time(sec)',
    88026: 'Retransmit Error',
    88027: 'Value',
    88028: 'Set Value',
    88029: 'CONFIGURE CJ COMPENSATION',
    88030: 'Default (on-board measurement)',
    88031: 'External - temperature measurement',
    88032: 'External - mV source',
    88033: 'Internal AI channel',
    88034: 'CJ compensation add. 1',
    88035: 'CJ compensation add. 2',
    88036: 'CJ compensation add. 3',
    88037: 'Invalid CJC add. 1',
    88038: 'Invalid CJC add. 2',
    88039: 'Invalid CJC add. 3',
    88040: 'Disabled',
    88041: 'Delay to turn off DO',
    88042: 'Invalid cycle time',
    88043: 'Cycle Time must be less than or equal to the the control update time value.',
    88044: 'Switches need to be repositioned. Continue?',
    88100: 'Time Zone',
    88101: 'Set Time Zone',
    88102: 'Time zone data folder not found',
    88103: 'Time zone data file not found',
    88104: 'Unable to set time zone',
    88105: 'Unable to save time zone',
    88106: 'Unable to load time zone data file',
    88107: 'Saving date/time configuration, please wait ...',
    88108: 'Must select a time zone',
    88109: 'Invalid year',
    88110: 'Invalid month',
    88111: 'Invalid day',
    88112: 'Invalid hour',
    88113: 'Invalid minute',
    88114: 'Get time from network',
    88115: 'Pools',
    88116: 'Servers',
    88117: 'SUCCESS',
    88118: 'UPDATING',
    88119: 'SOURCE OFFLINE',
    88120: 'ADDRESS UNKNOWN',
    88121: 'FAIL',
    90000: 'Equipment Type',
    90001: 'Describes basic role of the equipment',
    90002: 'Briefly, all types assume a furnace except the last type (OTHER). Equipment of type: NITRIDING, NITROCARBURIZING and NITRIDING WITH VACUUM,  require existence of inlet gasses, furnace atmosphere, H2 analyzer(s),   Kn – it has  predefined component IDs for standard  I/Os.  Equipment of type VACUUM does not require atmosphere, has predefined component IDs for standard   I/Os. Equipment of type “Other” can be completely customized by end user, it is a black-box with I/Os to be read/written and controllers to control something.',
    90003: 'Equipment Id',
    90004: 'Unique code for the equipment inside the plant',
    90005: 'The unique code is required so that equipment can be identified in a NPM or PT9800 network, or when viewing LOG files with a stand-alone utility. The code will appear on the user interface screen and in the log files/charts.',
    90006: 'Company Name',
    90007: 'Name of the company or department',
    90008: 'Company name will appear on the user interface screen and in the log files/charts.',
    90009: 'Equipment Name',
    90010: 'Unique name for the equipment',
    90011: 'Equipment name will appear on the user interface screen and in the log files/charts.',
    90012: 'Monetary Unit',
    90013: 'Monetary unit description',
    90014: 'Please select the unit from the drop-down list, or if not found,  type the symbols or name of the monetary unit.  This field is used when displaying the production cost.',
    90015: 'Furnace Volume (l)',
    90016: 'Applicable for atmospheric furnaces',
    90017: 'Furnace Volume is extremely important parameter for furnaces with atmosphere. If the entered value is smaller than the real volume, it will lead to the wrong calculation of atmosphere in the furnace and will  jeopardize the safety of the system. Also, incorrect value will cause errors in the calculation of Kn.',
    90018: 'Timeout for Logoff (min)',
    90019: 'Delay for automatic logoff (minutes)',
    90020: 'If there is no mouse/keyboard activity for predefined period, automatic log-off will happen and default user permission  will be loaded.',
    90021: 'Time to Resume Job after Power Fail (min)',
    90022: 'Maximum time in minutes to resume a job after a power failure.',
    90023: 'The longest delay acceptable for a job to be resumed after a power failure. If the system is off for longer than the predefined period, nitride software will automatically abort the job. Set to 0 to disable automatic abort.',
    90024: 'Configuration Path',
    90025: 'Folder where the configuration files are stored',
    90026: 'Folder where the configuration files are stored. The path is relative to the location where nitride configuration file SYSTEM.CFG file resides.',
    90027: 'Log Path',
    90028: 'Folder where LOG files are stored',
    90029: 'Enter either the full path, like C:\\nitrex\\MyFolder, or the relative path. For relative path, preceding $ character is required, like $Myfolder. Relative path is relative to the location where application executable file resides.',
    90030: 'Resource Path',
    90031: 'Folder where language resource files are stored',
    90032: 'Enter either the full path, like C:\\nitrex\\MyFolder, or the relative path. For relative path, preceding $ character is required, like $Myfolder. Relative path is relative to the location where application executable file resides.',
    90033: 'Watchdog Type',
    90034: 'Computer Watchdog Type ',
    90035: 'Please select the type from the drop-down menu.  Incorrect selection will jeopardize the proper functionality of the system and may damage the load in the furnace',
    90036: 'Validation of Analyzer Reading ',
    90037: 'Enables/Disables some validation of H2 analyzer readings',
    90038: 'If disabled, nitride software accepts as valid  H2 reading  when it is below the (calculated) content of  dissociated ammonia in the furnace.',
    90039: 'Blackboard Font',
    90040: 'Set font family and size for the blackboard',
    90041: 'To change the blackboard font, use this syntax:  FontFamilyName_size.  Example:  system_9    or   arial_12  or  courier_10',
    90042: 'Disk Space Alarm (MB)',
    90043: 'Upper limit  for free disk space alarm',
    90044: 'When free disk space is below this value, alarm on low disk space will be triggered. The Disk Space Alarm value should be lower than the Disk Space Warning value.',
    90045: 'Disk Space Warning (MB)',
    90046: 'Upper limit  for free disk space warning',
    90047: 'When free disk space is below this value, warning on low disk space  will be triggered. The Disk Space Warning value should be higher than the Disk Space Alarm value.',
    90048: 'Enable Computer Watchdog',
    90049: 'Enables/Disables computer watchdog.',
    90050: 'This attribute is reserved for developers’ use only. It should never be set to false.',
    90051: 'Enable Schedule Start',
    90052: 'Enables/Disables delayed job start.',
    90053: 'Uncheck the box for customers who didn’t purchase this option. ',
    90054: 'Start Neutralizer',
    90055: 'Turn ON Neutralizer in the first stage.',
    90056: 'If this box is checked, neutralizer starts when job starts, otherwise (unchecked box), neutralizer will start in the stage which asks for ammonia, or dissociated ammonia',
    90057: 'Erase Job Data at Start',
    90058: 'Keeps or Deletes data entered when job was loaded.',
    90059: 'Customers that always run the same recipe, for the same customer, can benefit if Job Load Data are saved and reloaded each time the new job starts. ',
    90060: 'Flow Step (l/min)',
    90061: 'Flow  ramping step in l/min ',
    90062: 'To prevent large oscillations in pressure when dramatically changing the flow, flow is increased/decreased in steps. If step is set to 0, there is no ramping in flow',
    90063: 'Force Process Code to Uppercase',
    90064: 'Converts Recipe Code to uppercase',
    90065: 'When starting job from SCADA systems that ignore case of the recipe code.',
    90066: 'Hot Standby Recipes',
    90067: 'List of all configured standby recipe files.',
    90068: 'This line contains file names of all configured hot-standby recipes. The list is delimited with | character. Example:   SB1.pro|SB2.pro|SB3.pro   ',
    90069: 'Resume Standby',
    90070: 'Continue or Restart Standby recipe',
    90071: 'When checked, standby recipe will continue after a power failure or when nitride application restarts . When unchecked, standby recipe starts from scratch each time nitride application restarts. Nitrex default is not to resume standby recipe.',
    90072: 'Encrypt Log Files',
    90073: 'Setting encryption of log files.',
    90074: 'Log files should always be encrypted.',
    90075: 'Max Down Time (days)',
    90076: 'Max delay to start scheduled job.',
    90077: 'This property defines whether a scheduled job should start once application is up and scheduled time has already elapsed. Setting the value to a positive number, allows nitride application to start the job when current time is less than scheduled time plus max. down time. Example:  Nitride application was down for more than 30 hours. Nitride application is back on November 10th 2010 @ 8:15 AM; Job was scheduled for November 9th 2010 @ 6:00 AM. If max. down time was set to 1 day, scheduled job will be cancelled.  However, if max. down time was set to 2 days, scheduled job will start on November 10th 2010 @ 8:15 AM. ',
    90078: 'Max Graph Value',
    90079: 'Max. Y value for the chart. ',
    90080: 'This parameter is used filter extremely high values for Kn when temperature and content of ammonia in the furnace are low.',
    90081: 'Hide Taskbar',
    90082: 'Hide Task Bar and disables Ctrl-Alt-Del',
    90083: 'Hide Taskbar should always be enabled. This property is linked with user permission. When hide taskbar is enabled, this will apply to users who have no access to exit application. The property also disables  Task Manger and Shut-Down when Ctrl-Alt-Del key combination is pressed. For Kontron computers, the argument must be set to 2.',
    90084: 'Serial Port Number',
    90085: 'Serial Port Number ',
    90086: 'The property is used for I/O drivers that communicate with I/Os through serial port (Opto).',
    90087: 'Baud Rate',
    90088: 'Communication Baud Rate',
    90089: 'The property is used for I/O drivers that communicate with I/Os through serial port (Opto). All devices on the serial network need to be set to the same baud rate.',
    90090: 'Turnaround Timeout (sec)',
    90091: 'Set Turnaround Delay (sec)',
    90092: 'The property is used for Opto  I/O driver. It represents max. delay for nitride application to wait for response from the slave. If there is no response within the specified time, the error /alarm will be generated. ',
    90093: 'Number of retries',
    90094: 'Set the max number of communication retries.',
    90095: 'Set the number of times the driver will attempt to send a command before it returns an error message.',
    90096: 'Watchdog Time',
    90097: 'Set Watchdog Delay on I/Os',
    90098: 'Watchdog timer resides in I/O brain boards. Each time I/O brain board receives request from the master (control application), watchdog timer on the brain board is reset. If there are no requests from the master (control application) for longer than the specified time, I/O brain board will set all output channels, both analog and digital, to zero signal.',
    90099: 'Backfill Gradient (torr/min)',
    90100: 'Pressure slope in backfill stage',
    90101: 'Average pressure slope in backfill stage. Always a positive number.',
    90102: 'Cool Gradient (°C/min)',
    90103: 'Cooling slope ',
    90104: 'Average temperature slope (always positive), measured by the main TC. Applies to stages that have end condition cool or furnace cool.',
    90105: 'Fill Gradient (torr/min)',
    90106: 'Pressure slope',
    90107: 'Average pressure slope in stages that pressurize the furnace (end condition is PRESSURE). Always  positive number.',
    90108: 'Heat Gradient (°C/min)',
    90109: 'Heating slope',
    90110: 'Average temperature slope, measured by the Main TC. Always positive number. Applies to stages with end condition HEAT or FURNACE HEAT.',
    90111: 'Potential Gradient /min',
    90112: 'Average decrease of Kn in the first heating stage.',
    90113: 'The parameter is used only in systems with incorporated Kn simulation model.',
    90114: 'Vacuum Gradient (torr/min)',
    90115: 'Vacuum speed in vacuum stages.',
    90116: 'Average vacuum slope during vacuum stages.',
    90117: 'Cool Limit (°C)',
    90118: 'Minimum end-stage temperature',
    90119: 'Minimum value for temperature end condition. Applies for stages with end conditions FURNACE COOL or TIME COOL. Typically it is a number between 80 and 100 (except for cryogenic furnaces). Values that are too low may lead to an endless cool stage, as standard hardware typically cannot cool below 60 °C. ',
    90120: 'Heat Limit (°C)',
    90121: 'Maximum end stage temperature.',
    90122: 'Maximum value for temperature when stage end condition is  FURNACE HEAT. This parameter is used to protect the hardware (furnace). Should be below the overheat protection setting. Typically it is set to 600 °C ',
    90123: 'Max. Temp for NH3 (°C)',
    90124: 'Temperature at which mixing NH3 with air is safe.',
    90125: 'Max. temperature at which NH3 gas can be introduced in the furnace with any content  of air.  For maximum security and for systems with dissociator on exhaust set it to 20 °C. Default value is 400 °C.  Management permission is required to overwrite the default value.',
    90126: 'Max. Air for NH3 (%)',
    90127: 'Max. allowed air content, safe to mix with NH3 at any temperature. ',
    90128: 'Maximum allowed content of air in the furnace that makes it safe to  introduce NH3 gas, at any temperature. Nitrex default is 14%.  Management permission is required to overwrite the default value.',
    90129: 'Max. Air for Diss. NH3 (%)',
    90130: 'Max. allowed air content, safe to mix with DNH3 at any temperature. ',
    90131: ' Maximum allowed content of air in the furnace that makes it safe to  introduce dNH3 gas, at any temperature. Nitrex default is 1%.   Management permission is required to overwrite the default value.',
    90132: 'Max. Last Stage Temp.(°C)',
    90133: 'Safe temperature for opening the furnace.',
    90134: 'Maximum allowed temperature below which is safe to open the furnace. Nitrex default is 100 °C.',
    90135: 'Max. Last Stage NH3 (%)',
    90136: 'Safe NH3 content for opening the furnace',
    90137: 'Maximum allowed content of NH3 in the furnace that makes it safe to open the furnace. Nitrex default is 1%.   Management permission is required to overwrite the default value.',
    90138: 'Max. Last Stage DNH3 (%)',
    90139: 'Safe DNH3 content for opening the furnace',
    90140: 'Maximum allowed content of diss. NH3 in the furnace that makes it safe to open the furnace. Nitrex default is 1%.   Management permission is required to overwrite the default value.',
    90141: 'Backfill Temp. Limit (°C)',
    90142: 'Max temperature in vacuum/backfill. ',
    90143: 'Temperature below which it is safe to do vacuum/backfill (hardware protection). Nitrex default is 350 °C.',
    90144: 'Default Vacuum Setpoint (torr)',
    90145: 'Default Vacuum Setpoint (torr)',
    90146: 'Default Vacuum Setpoint (torr)',
    90147: 'Min. Vacuum Level (torr)',
    90148: 'Limit for vacuum set point and  end condition.',
    90149: 'Limit to validate vacuum stages. Stages with vacuum end value below the limit will be pronounced faulty and the recipe will not be saved. The value depends on the vacuum pump capability. Default is 5 torr.  Putting a value too low may lead to an endless stage.',
    90150: 'Max. Pressure Setpoint (torr)',
    90151: 'Upper limit for pressure SP and PRESSURE PLUS end condition. ',
    90152: 'The value serves to validate recipes and to protect furnace against overpressurizing.',
    90153: 'Min. Pressure Setpoint (torr)',
    90154: 'Lower limit for pressure SP and PRESSURE MINUS end condition.',
    90155: 'The value serves to validate recipes and to prevent endless stages (stages that cannot meet end condition)',
    90156: 'Atmospheric pressure reading (torr)',
    90157: 'Atmospheric pressure read by the pressure transducer comp ID 18',
    90158: 'Typically main pressure transducer is a differential pressure transducer and atmospheric pressure is 0 torr. Sometimes absolute pressure transducer is used. In this case, atmospheric pressure depends on altitude, weather condition (humidity). As weather  conditions vary, average value is expected. Nitrex default is 760 torr . It is strongly recommended to adjust this value to the location where system is deployed.',
    90159: 'Atmospheric pressure read by the vacuum transducer (torr)',
    90160: 'Atmospheric pressure read by the vacuum transducer – comp ID 20',
    90161: 'Atmospheric pressure depends on altitude, weather condition (humidity). As weather conditions vary, average value is expected. This value is used to calculate the set point (and generate alarms/emergencies) on absolute pressure transducer in stages that do not control vacuum. For example if average atmospheric pressure is 740 torr and  if stage  has 3 torr set point on the main - differential pressure transducer, set point on the absolute pressure transducer will be calculated as 740 + 3 = 743 torr. Absolute pressure transducer is getting the set point of 743 torr and is  now capable to generate alarms and emergencies based on the point setting.',
    90162: 'Min. Last Stage Vacuum (torr)',
    90163: 'Limit for the vacuum level at the end of the recipe.',
    90164: 'Parameter used to validate a recipe. The last recipe stage should be near atmospheric pressure to allow easy opening the furnace. This value represents the limit for vacuum level. If recipe finishes with vacuum level  higher than the limit, recipe will not be valid.',
    90165: 'Enable Network Start',
    90166: 'Set access to start the job remotely.',
    90167: 'By default, remote start is disabled. In special cases, when Nitrex Control System is connected with  PT9800 Automat, remote start of job has to be enabled.',
    90168: 'Enable Remote Manual Mode',
    90169: 'Set access to manual mode from the  remote computer. ',
    90170: 'Nitrex policy is to disable manual operation of the furnace from the remote computer. Management permission is required to overwrite the default. ',
    90171: 'TCP Port Number',
    90172: 'Port Number dedicated for communication with NPM. ',
    90173: 'Port Number that Control Computer opens to receive requests from the remote computer (NPM). The port number needs to match  the one set  in the NPM configuration file.',
    90174: 'Number of Socket Connections',
    90175: 'Max. allowed socket connections.',
    90176: 'Set value to 0 if there is no NPM in the system. Set value to 10 if NPM is installed.',
    90177: 'On Time (sec)',
    90178: 'Pulse Width (sec) ',
    90179: 'Applies to Nitriding Systems with Turbo Cooling and butterfly valve controlled with two DOs. One DO is used for opening and one DO is used for closing the valve. Opening is done slowly with pulses to prevent pressure drop in the furnace. This time represents pulse width of the signal that opens the valve',
    90180: 'Off Time (sec)',
    90181: 'Delay between two pulses (sec)',
    90182: 'Applies to Nitriding Systems with Turbo Cooling and butterfly valve controlled with two DOs. One DO is used for opening and one DO is used for closing the valve. Opening is done slowly with pulses to prevent pressure drop in the furnace.  This time represents delay between the two pulses. ',
    90183: 'Turbo Cooling Volume (l)',
    90184: 'Turbo Cooling Heat Exchanger Volume (l)',
    90185: 'Turbo cooling volume is an important parameter. If the value entered is smaller than the real volume, it will lead to the wrong calculation of atmosphere in the furnace and will  jeopardize the safety of the system.',
    90186: 'Process File Path',
    90187: 'Folder where the recipe files are stored',
    90188: 'Enter either the full path, like C:\\nitrex\\MyFolder, or the relative path. For relative path, preceding $ character is requested, like $Myfolder. Relative path is relative to the location where application executable file resides.',
    90189: 'Emergency File Path',
    90190: 'Folder where the emergency recipe files are stored',
    90191: 'Enter either the full path, like C:\\nitrex\\MyFolder, or the relative path. For relative path, preceding $ character is requested, like $Myfolder. Relative path is relative to the location where application executable file resides.',
    90192: 'Enable logging during standby stage',
    90193: '',
    90194: '',
    90195: 'Enable toolbar icons',
    90196: '',
    90197: '',
    90600: 'General',
    90601: 'Safety',
    90602: 'Consumption',
    90603: 'Opto',
    90604: 'NPM',
    90605: 'Value',
    90606: 'Save As',
    90607: 'System Configuration',
    90608: 'Please select system configuration parameter(s) to add',
    90609: 'Untitled',
    90610: 'Settings',
    90611: 'Old Turbo',
    90612: 'New Turbo',
    90613: 'Turbo Style',
    90614: 'IO Communication Type',
    90615: 'Optomux',
    90616: 'DPN',
    90617: 'Hilscher',
    90618: 'KBus',
    90619: 'Simmux',
    90620: 'VLP',
    90621: 'MMI 10Pro',
    90622: 'Modbus TCP',
    90623: 'Must select an IO type',
    90624: 'Some fields in the checked rows have no values',
    90625: 'The Tag name has been used already.',
    90626: 'The Tag name has to be a letter from A to Z.',
    90627: 'Tag',
    90628: 'Description ID',
    90629: 'The form has been modified. Would you like to discard the change?',
    90630: 'System Specific',
    90631: 'Configuration Paths',
    90632: 'Configuration has been saved successfully. However, some parameters are improperly configured',
    90633: 'The following parameters are mandatory and must have a value:',
    90634: 'The following parameters are not configured properly:',
    90635: 'The following parameters were missing and have been added:',
    90636: 'The following parameters are not needed:',
    90637: 'Configure Consumption',
    90638: 'Configure Coefficient',
    90639: 'An invalid coefficient was found',
    90640: 'Help for Create/Modify Consumption :',
    90641: 'Help for Create/Modify Coefficient:',
    90642: 'Consumption section is not a mandatory section and does not have to exist. It is end user’s choice to track consumption of gasses, electricity or any measured variable in the system . Consumption section is grouped in segments. Segments that represent goods that user wants to track and  segments that represent coefficients to be used in order to convert real measured values to  final form or unit. To modify consumption parameters click on CONFIGURE CONSUMPTION or CONFIGURE COEFFICIENTS button',
    90643: 'Consumption section is not a mandatory section and does not have to exist. It is end user’s choice to track consumption of gasses, electricity or any measured variable in the system . Consumption section is grouped in segments. Segments that represent goods that user wants to track and  segments that represent coefficients to be used in order to convert real measured values to  final form or unit. To modify consumption parameters click on CONFIGURE CONSUMPTION or CONFIGURE COEFFICIENTS button',
    90644: 'Parameters under this group are not recognized by this version of the configuration tool. This means that these parameters are either obsolete or introduced after this tool has been released. After saving the configuration, these parameters will remain untouched.',
    90645: 'Multiplier: ',
    90646: 'Unknown',
    90647: 'Configure Modbus TCP',
    90648: 'Modbus TCP',
    90649: 'Modbus RTU',
    90650: 'Turbo',
    90651: 'Nitriding Furnace',
    90652: 'Nitro-Carburizing',
    90653: 'Vacuum Furnace',
    90654: 'Die Ovens',
    90655: 'Nitriding with Vacuum',
    90656: 'Other Equipment',
    90657: 'Configure Modbus RTU',
    90658: 'Host address for slave',
    90659: 'This is the address for this slave. You can edit more parameters by clicking on the "Configure Modbus TCP" button.',
    90660: 'Help for Create/Modify Modbus TCP:',
    90661: 'Slave',
    90662: 'Host',
    90663: 'Port',
    90664: 'Delay(ms)',
    90665: 'Int byte order',
    90666: 'Float byte order',
    90667: 'No empty fields allowed',
    90668: 'Duplicate slave not allowed',
    90669: 'Duplicate host not allowed',
    90670: 'Port Name',
    90671: 'This is the port name. You can edit more parameters by clicking on the "Configure Modbus RTU" button.',
    90672: 'Baud Rate',
    90673: 'Bits',
    90674: 'Parity',
    90675: 'Stop Bit',
    90676: 'Time Out(ms)',
    90677: 'Retries',
    90678: 'Ports',
    90679: 'Slaves',
    90680: 'Port with duplicate slave not allowed',
    90681: 'Port name empty.',
    90682: 'PT455',
    90683: 'Rts Delay(ms)',
    90684: 'Consume Echo',
    90685: 'No func. 16',
    90686: 'Unit ID',
    90687: 'CANopen',
    90688: 'Invalid slave address',
    90690: 'Bias',
    90691: 'Coefficient',
    90693: 'Signal Enabled',
    90694: 'Max has to greater than Min',
    90695: 'Calculation Type',
    90696: 'Calculation Parameter',
    90697: 'Destination',
    90698: 'Value',
    90699: 'Help',
    90700: 'Unable to Save File',
    90701: 'No Properly Configured Process Calculation',
    90702: 'PAI/AO Comp ID for the process calculation result is required but not configured',
    90703: 'The same Comp ID configured for the process calculation result should only appear once in the configuration',
    90704: 'The following field is required but not configured: ',
    90705: 'Process Control Calculation Configuration',
    90710: 'Key',
    90711: 'Control Action',
    90712: 'Set Point Type',
    90714: 'Low Deadband',
    90715: 'High Deadband',
    90716: 'Off Delay (sec)',
    90717: 'On Delay (sec)',
    90718: 'Input Type',
    90719: 'Input Comp ID',
    90720: 'Output Comp ID',
    90721: 'reversed',
    90722: 'direct',
    90723: 'relative',
    90724: 'absolute',
    90725: 'On-Off Controllers Configuration',
    90726: 'Some Key is empty',
    90727: 'The Key is has to be unique',
    90728: 'The values for some Low/High Deadband are missing',
    90729: 'Warning: Neither Description nor Resource ID exits in some On-Off controller configuration!',
    90730: 'Description Resource ID',
    90731: 'The values for some Off/On Delay are missing',
    90732: 'High Deadband has to be greater than Low Deadband',
    90733: 'The following Parameter is required: ',
    90749: 'Custom...',
    90750: 'Analog Controller',
    90751: 'Fuzzy File Name',
    90752: 'Control Interval (sec.)',
    90753: 'Max Output',
    90754: 'Max Deviation Output',
    90755: 'Min Output',
    90756: 'Min Deviation Output',
    90757: 'Unsupported Function 16',
    90758: 'Unit ID',
    90759: 'Timeout',
    90760: 'SP Offset',
    90791: 'The Fuzzy File Name must have .FUZ as the file extension',
    90792: 'Max Output has to be greater than Min Output',
    90793: 'Max Deviation Output has to be greater than Min Deviation Output',
    90794: 'The Min/Max Output should be bounded by the following range: ',
    90795: 'The Min/Max Deviation Output should be bounded by the following range: ',
    90796: 'Both Min/Max Deviation Output has to be greater than 0',
    90800: 'Analyzer Help: PAI for Calculation Result',
    90801: 'Analyzer Help: Kn - Nitriding Potential',
    90802: 'Analyzer Help: Kn - Dissociation in Furnace',
    90803: 'Analyzer Help: Kn - Sampling Line Solenoid Valve',
    90804: 'Analyzer Help: Kc - Carbon Potential',
    90805: 'Analyzer Help: Kc - Partial Pressure CO',
    90806: 'Analyzer Help: Kc - Partial Pressure CO2',
    90807: 'Analyzer Help: Kc - Sampling Line Solenoid Valve (optional)',
    90808: 'Analyzer Help: Ko - Oxygen Potential',
    90809: 'Analyzer Help: Ko - Oxygen Probe mV',
    90810: 'Analyzer Help: Ko - Oxygen Probe Temperature (optional)',
    90811: 'Analyzer Help: Ko - Sampling Line Solenoid Valve (optional)',
    90812: 'Analyzer Help: Nf - Nitriding Factor',
    90813: 'Analyzer Help: Nf - Dissociation in Furnace',
    90814: 'Analyzer Help: Nf - Nitriding Potential',
    90815: 'Analyzer Help: Nf - Sampling Line Solenoid Valve (optional)',
    90816: "Analyzer Help: Kn1 - PE's Nitriding Potential",
    90817: 'Analyzer Help: Kn1 - Dissociation in Furnace',
    90818: 'Analyzer Help: Kn1 - Partial Pressure NH3',
    90819: 'Analyzer Help: Kn1 - Sampling Line Solenoid Valve',
    90820: 'Analyzer Help: NW - Nitrogen Weight',
    90821: 'Analyzer Help: NW - Nitriding Potential',
    90822: 'Analyzer Help: NW - Furnace Temperature',
    90823: 'Analyzer Help: NW - Sampling Line Solenoid Valve (optional)',
    90824: 'Analyzer Help: LogO2 - Post-Oxidation Quantifier',
    90825: 'Analyzer Help: LogO2 - Oxygen Probe mV',
    90826: 'Analyzer Help: LogO2 - Oxygen Probe Temperature (optional)',
    90827: 'Analyzer Help: LogO2 - Sampling Line Solenoid Valve (optional)',
    90828: 'Analyzer Help: LinearFunc - Linear Function',
    90829: 'Analyzer Help: LinearFunc - Analog Input',
    90830: 'Analyzer Help: LinearFunc - Signal Enabled (optional)',
    90831: 'Analyzer Help: LinearFunc - Bias',
    90832: 'Analyzer Help: LinearFunc - Coefficient',
    90833: 'Analyzer Help: LinearFunc - Min (optional)',
    90834: 'Analyzer Help: LinearFunc - Max (optional)',
    90835: 'Analyzer Help: Timer Function',
    90836: 'Analyzer Help: Error in percent',
    90837: 'Analyzer Help: Point Type',
    90840: 'On-Off Controller Help: Key',
    90841: 'On-Off Controller Help: Description',
    90842: 'On-Off Controller Help: Control Action',
    90843: 'On-Off Controller Help: Set Point Type',
    90844: 'On-Off Controller Help: Low Deadband',
    90845: 'On-Off Controller Help: High Deadband',
    90846: 'On-Off Controller Help: Off Delay',
    90847: 'On-Off Controller Help: On Delay',
    90848: 'On-Off Controller Help: Input Type',
    90849: 'On-Off Controller Help: Input Comp ID',
    90850: 'On-Off Controller Help: Output Comp ID',
    90851: 'On-Off Controller Help: Description Resource ID',
    90870: 'Analog Controller Help: Key',
    90871: 'Analog Controller Help: Description',
    90872: 'Analog Controller Help: Fuzzy File Name',
    90873: 'Analog Controller Help: Control Interval',
    90874: 'Analog Controller Help: Max Output',
    90875: 'Analog Controller Help: Max Deviation Output',
    90876: 'Analog Controller Help: Min Output',
    90877: 'Analog Controller Help: Min Deviation Output',
    90878: 'Analog Controller Help: Input Comp ID',
    90879: 'Analog Controller Help: Output Comp ID',
    90880: 'Analog Controller Help: Description Resource ID',
    90881: 'Analyzer Help For Undefined Calculation Type: Column 1',
    90882: 'Analyzer Help For Undefined Calculation Type: Column 2',
    90883: 'Analyzer Help For Undefined Calculation Type: Column 3',
    90884: 'Analyzer Help For Undefined Calculation Type: Column 4',
    90885: 'Analyzer Help For Undefined Calculation Type: Column 5',
    90886: 'Analyzer Help For Undefined Calculation Type: Column 6',
    90887: 'Analyzer Help For Undefined Calculation Type: Column 7',
    90888: 'Analyzer Help For Undefined Calculation Type: Column 8',
    90889: 'Analyzer Help For Undefined Calculation Type: Column 9',
    90890: 'Analyzer Help For Undefined Calculation Type: Column 10',
    90891: 'Analyzer Help: Logical OR operation',
    90892: 'Analyzer Help: Logical AND operation',
    90893: 'Analyzer Help: Logical NOT OR operation',
    90894: 'Analyzer Help: Logical NOT AND operation',
    90900: 'Please select System Configuration file.',
    90901: 'IO Configuration file does not exist',
    90902: 'IO Configuration file found. Total number of points=',
    90903: 'Please load the SYSTEM.CFG first',
    90904: 'You are already connected to a server. Connect to an another?',
    90905: 'IO Errors detected!',
    90906: 'Missing Mandatory Points',
    90907: 'Gas Configuration errors detected!',
    90908: 'Set new folder name and location',
    90909: 'System loaded',
    91000: 'Zero',
    91001: 'Span',
    91002: 'Slave',
    91003: 'IO Point Configuration Utility',
    91004: 'Append',
    91005: 'Insert',
    91006: 'Move Up',
    91007: 'Move Down',
    91008: 'Pseudo',
    91009: 'Descriptor',
    91010: 'Driver',
    91011: 'Default Driver',
    91012: 'Would you also like to changes all points to use this newly selected driver.',
    91013: 'IO Config Point Edit',
    91014: 'Protected',
    91015: 'Display',
    91016: 'Totalizer',
    91017: 'Alarm',
    91018: 'Address',
    91019: 'Range',
    91020: 'Action',
    91021: 'Add.',
    91022: 'Cooling with nitrogen',
    91023: 'Wait',
    91024: 'Cool with NH3',
    91025: 'Power Failure',
    91026: 'Backfill',
    91027: 'Res ID',
    91028: 'Append String',
    91029: 'Sec.:',
    91030: 'Max. Counts:',
    91031: 'Min. Counts:',
    91032: 'Input:',
    91033: 'Always',
    91034: 'When ON requested',
    91035: 'When OFF requested',
    91036: 'Linked input',
    91037: 'Set to zero',
    91038: 'Set to min',
    91039: 'Set to max',
    91040: 'On',
    91041: 'Off',
    91042: 'IO Config Template file not found',
    91043: 'Full permission',
    91044: 'Nitrex user only',
    91045: 'No logging',
    91046: 'Retransmit',
    91047: 'Message point',
    91048: 'Unable to create software updates folder',
    91049: 'Unable to access media or folder. Try again?',
    91050: 'The current content of the software updates folder will be cleared. Continue?',
    91051: 'Unable to clear software updates folder',
    91052: 'Unable to copy file, operation aborted',
    91053: 'Unable to calculate checksum value. Operation aborted',
    91054: "Checksum values don't match. Operation aborted.",
    91055: 'Unable to write. Operation aborted',
    91056: 'Completed successfully',
    91057: 'Calculated Point',
    91058: 'Stage End Condition',
    91059: 'Alarm Levels',
    91060: 'End Condition & SP',
    91061: 'End Condition & Alarm',
    91062: 'End Condition & SP & Alarm',
    91063: '',
    91064: 'Misc',
    91200: 'Component ID',
    91201: 'A unique identifier per I/O type',
    91202: 'Within an I/O group (AI, AO, DI DO) component IDs have to be unique. Some component IDs are reserved for specific measurements/devices. Example:  AI 9 = Main TC measurement; AI 18 = Main Pressure Transducer; DI 1 = Furnace Cover; DI 2 = Power, etc… Using dedicated component ID for random measurements/devices may lead to malfunctioning of the system. As a rule of thumb, use comp IDs greater than 250 for devices that are not found in the template list. ',
    91203: 'Point type',
    91204: '',
    91205: '',
    91206: 'Key',
    91207: '',
    91208: '',
    91209: 'Sorting',
    91210: '',
    91211: '',
    91212: 'Decimal places',
    91213: 'Enter a value between 0 and 9',
    91214: 'The point will be logged and displayed with number of decimal places set in this field. ',
    91215: 'Available in Process Builder',
    91216: 'Adds the point to the Recipe Builder',
    91217: 'When selected, point can be used in Recipe Builder. If point is of type AI or DI, it can be used in the Recipe Builder to specify the end condition of the stage. If point is of type AO or DO, the requested output value can be set in the Recipe Builder.',
    91218: 'Point Permission for the Reading',
    91219: 'Please select from the list',
    91220: 'Defines the visibility of the point on all nitride screens as well as whether the point reading  should be logged.   By default point is visible but not logged. Other two possibilities enable logging but can make point visible only for Nitrex users. ',
    91221: 'Point Permission for the Set Point',
    91222: 'Please select from the list',
    91223: 'Defines the visibility of the set point on all nitride screens as well as whether the set point  should be logged.   By default set point is visible but not logged. Other two possibilities enable logging but can make point visible only for Nitrex users',
    91225: 'Tag for totalizer coefficient',
    91226: 'Please select from the list',
    91227: 'The letter tag is associated with a numeric coefficient defined in system configuration file. The coefficient multiplies the reading before adding it to an accumulator.',
    91228: 'Default Set Value',
    91229: 'Please select from the list',
    91230: 'This is an optional field. When enabled, point will always have a set point, and a possibility to have alarms/warnings. Note: For alarms/warning to happen, alarm delay and at least one of alarm/warning levels must also be enabled. ',
    91231: 'Slave',
    91232: 'Slave Address',
    91233: 'Different IO devices require different addressing methods. If value is negative this point will be ignored. Example: for K-bus & Optomux driver enter 1;  for Hilscher profibus driver enter value from 0 to 126; for Siemens profibus enter value from 3 to 126.',
    91234: 'Address 1',
    91235: 'Address 1',
    91236: 'Different IO devices require different addressing methods. If value is negative this point will be ignored. For Analog points, typically contains the address of the first byte (starting with 0) . For digital points contains bit position (starting with 1). For Optomux driver, represents rack number .',
    91237: 'Address 2',
    91238: 'Address 2',
    91239: 'Different IO devices require different addressing methods. If value is negative this point will be ignored. For Analog points, typically contains the address of the second byte (starting with 0) . For digital points contains byte address (starting with 0). For Optomux driver, represents module position within the rack, starting with 0 .',
    91240: 'Device Type',
    91241: 'Defines special behavior of the device',
    91242: 'The field is optional, leave it empty if no special features are required. Optomux driver requires in addition appropriate device types for thermocouples. Select PULSED type for analog outputs to emulate analog value with digital outputs using  PWM (pulse width modulation).  Other types are common for all drivers. For more details, please see the help file on the server \\\\United\\group\\NMI\\Software\\_INSTRUCTIONS_MANUALS \\IOPOINT_Description.doc .',
    91243: 'Descriptor ID ',
    91244: 'Descriptor Identifier',
    91245: 'Tag that points to a string in the resource file (English.cfg /French.cfg, etc…). Always an EVEN number. ',
    91246: 'Zero Counts',
    91247: 'Count value corresponding to Min',
    91248: 'Minimum count value in the range of the device/module.',
    91249: 'Span Counts',
    91250: 'Count value corresponding to Span/PERIOD for Pulsed AOs.',
    91251: 'Maximum count value. For Pulsed AO point - Period in number of 10 msec (Example 500 corresponds to 5 seconds; 4500 corresponds to 45 seconds).',
    91252: 'Min',
    91253: 'Minimum reading in applied measuring units ',
    91254: 'Minimum reading in applied measuring units ',
    91255: 'Max',
    91256: 'Maximum reading in applied measuring units ',
    91257: 'Maximum reading in applied measuring units',
    91258: 'Measuring unit',
    91259: 'Please select the unit of measure',
    91260: 'The units are predefined. Adding a new unit is not possible without changing the software.',
    91261: 'Calibration File',
    91262: 'Select calibration file ',
    91263: 'The file is used for calibration or linearization of the reading. The file is optional. If the field is left blank, no calibration/linearization file will be used. Calibration file overwrites the ranges specified in fields Min Counts/Max Counts and Min Units/Max Units.',
    91264: 'Resolution',
    91265: 'Alarm Offset when SP = 0',
    91266: 'Value is  expressed in the units of measure. If  alarm levels are set in percentage of the set point this value is mandatory and  has to be greater than zero. It represents the allowed offset (error)  when zero (0) set point is requested.  For all mfc-s do not set the value below 1% of the span. The totalizer will not accumulate value if the point reads below its resolution. ',
    91267: 'Low Warning ',
    91268: 'Offset for Low Limit',
    91269: 'If the field is empty or set to 0, low warning is disabled. Value can be expressed in the units of measure, or in the percentage. If specified in percentage, the limit  varies with the set point value.  The Low  Limit is calculated as:  Set Point Value - Low Warning Offset.  When reading is below the Low Limit, for delay longer than Alarm Delay, point will go to a Low Warning State. Each time point goes from warning state back to normal, alarm timer restarts from 0.  The value should be smaller than the Low Alarm value. Note:  if the point does not have a set point, all alarms & warnings are disabled. ',
    91270: 'High Warning ',
    91271: 'Offset for High Limit ',
    91272: 'If the field is empty or set to 0, high warning is disabled. Value can be expressed in the units of measure, or as a percentage. If specified as a percentage, the limit  varies with the set point value.  The High  Limit is calculated as:  Set Point Value + High Warning Offset.  When reading is above the High Limit, for delay longer than Alarm Delay, point will go to a High Warning State. Each time point goes from warning state back to normal, alarm timer restarts from 0.  The value should be smaller than the High Alarm value. Note:  if the point does not have a set point, all alarms & warnings are disabled. ',
    91273: 'Low Alarm ',
    91274: 'Offset for Low Limit ',
    91275: 'If the field is empty or set to 0, low alarm is disabled. Value can be expressed in the units of measure, or as a percentage. If specified as a percentage, the limit  varies with the set point value.  The Low  Limit is calculated as:  Set Point Value - Low Alarm Offset.  When reading is below the Low Limit, for delay longer than Alarm Delay, point will go to a Low Alarm State. Each time point goes from alarm state back to normal, alarm timer restarts from 0.  The value should be bigger than the Low Warning value. Note:  if the point does not have a set point, all alarms & warnings are disabled. ',
    91276: 'High Alarm ',
    91277: 'Offset for High Limit',
    91278: ' If the field is empty or set to 0, high alarm is disabled. Value can be expressed in the units of measure, or as a percentage. If specified as a percentage, the limit  varies with the set point value.  The High  Limit is calculated as:  Set Point Value + High Alarm Offset.  When reading is above the High Limit, for delay longer than Alarm Delay, point will go to a High Alarm State. Each time point goes from alarm state back to normal, alarm timer restarts from 0.  The value should be bigger than the High Warning value. Note:  if the point does not have a set point, all alarms & warnings are disabled.  ',
    91279: 'Alarm Delay (sec) ',
    91280: ' Delay that triggers an alarm or warning',
    91281: 'Delay that triggers all configured alarms and warnings. If the field is empty or set to 0, all alarms/warnings are disabled.  A positive value represents max time (in seconds) that point can be outside its limits, without triggering alarms/warnings. Each time point goes from alarm state back to normal, alarm timer restarts from 0.  ',
    91282: 'Emergency type',
    91283: 'Select appropriate special recipe',
    91284: 'Defines the procedure that will be invoked once point goes to Emergency state. Emergency types are predefined. To each type a special recipe is associated. Please review the recipe you associated with the point, to make sure that the right procedure will be invoked.',
    91285: 'Emergency Delay (sec) ',
    91286: 'Delay that triggers emergency procedure',
    91287: 'Emergency delay determines whether the emergency procedure is enabled or disabled. If the field is empty or set to 0, emergency procedure is disabled. A positive value represents max time (in seconds) that point can be in alarm without triggering emergency procedure. Each time point goes from alarm state back to normal, emergency timer restarts from 0.  ',
    91288: 'Mirror of',
    91289: 'Select a Comp. ID of the reference point',
    91290: 'When we want a point to follow - to have the same reading as another (Master) point, we specify the  Component ID of the Master Point in this field. The master point READING  becomes the SET POINT for our point. Tolerance between the two readings is defined  by Low/High Alarms/Warnings. Alarm is generated when the reading of the MIRROR point exceeds the tolerance. Alarm is generated on the MIRROR point, not on the master point.',
    91291: 'Description',
    91292: 'Description of the point in English language',
    91293: 'This text does not appear anywhere in the graphic user interface. It is used to describe the point when the file is edited manually.',
    91294: 'Action',
    91295: 'Please select from the list',
    91296: 'When interpreting point reading or setting a value to a point,  nitride software  uses two layers:  1) straight reading/setting the point and 2) software interpretation of the signal. When action is defined as DIRECT, minimum value from the layer 1 is represented by the minimum value in the layer 2; maximum value from the layer 1 is represented by the maximum value in the layer 2 . When action is defined as REVERSE, minimum value from the layer 1 is represented by the maximum value in the layer 2; maximum value from the layer 1 is represented by the minimum value in the layer 2 . By default, point action is DIRECT. ',
    91297: 'Affected DI points',
    91298: 'Please select from the list – Multiple selection is possible',
    91299: ' This field is optional. One Digital Output can have multiple Controlled-by digital inputs. All Controlled-by digital inputs should follow the output, i.e. should have the same reading as the output signal. Example: DO represents a signal Cooling Water ON; We can have several water switches that monitor water flow in different parts of our equipment; when water is commanded to be ON, all switches should see the flow of cooling water. There is a difference between the Feedback Point and Controlled-By Points. When one of Controlled-By point does not match the request, it does not mean that request is not executed properly.',
    91300: 'Enable Alarm on',
    91301: 'Setting Alarm Rules',
    91302: 'Used for Digital Inputs. When this field is set to ALWAYS (default) there are two cases when digital input can go into alarm: 1) when it is requested to be ON and its status is OFF and 2)  when it is requested to be OFF and its status is ON. If this field is set to When-ON-Requested, the point will go to alarm ONLY if it is requested to be ON and its state is not ON.  If this field is set to When-OFF-Requested, the point will go to alarm ONLY if it is requested to be OFF and its state  is not OFF.  ',
    91303: 'Tag for totalizer group',
    91304: 'Please select from the list',
    91305: 'The field is optional. Totalizer groups are defined through System Configuration. If you need to modify a group, create or delete one, please go to system configuration menu. Totalizer group is a tag for  container that holds accumulated consumption for all points that use the same tag.',
    91306: 'Append String',
    91307: 'Descriptor append string',
    91308: 'Optional field. Used to customize the description of the point. The entered value is appended to the description defined by the resource ID. ',
    91309: 'Driver Type',
    91310: 'Please select from the list',
    91311: 'Listed are drivers that are supported by NITRIDE software.',
    91312: 'Is Pseudo',
    91313: 'This is a Pseudo Point',
    91314: 'By default, points are REAL points. Pseudo points are not read using any I/O driver. Instead, they are containers, soft points that add flexibility to system functionality. Most pseudo points are reserved for nitride software to run properly. User can create custom pseudo points , typical example is for process calculation.',
    91315: 'Feedback Point',
    91316: 'Please select from the list',
    91317: ' This field is optional. Feedback point is a Digital Input that reads the status of the device, enabling system to check if command is executed properly. If the feedback point reading does not match  the request, an alarm can be generated on the reading (feedback point needs to have alarms enabled). ',
    91318: 'Min Count Alarm',
    91319: 'Min valid counts for an analog input ',
    91320: 'This field is optional, by default the value is not set. When specified, it represent the low limit for reading (in counts). Whenever the reading exceeds the limit, the point will go to a No-Response-Alarm. ',
    91321: 'Max Count Alarm',
    91322: 'Max valid counts for an analog input ',
    91323: 'This field is optional, by default the value is not set. When specified, it represent the high limit for reading (in counts). Whenever the reading exceeds the limit, the point will go to a No-Response-Alarm.',
    91324: 'Disable no response in standby',
    91325: 'Disable no response alarm in standby stage',
    91326: 'This is an optional field. By default, all alarms are enabled in standby stage. Sometimes, system is designed to disconnect some devices when job is finished. Use this check box for such case.  ',
    91327: 'On delay (sec)',
    91328: 'Delay to turn ON device',
    91329: 'This field is enabled when device is configured as On-Delay type and it is a mandatory field in this case. ',
    91330: 'Off delay (sec)',
    91331: 'Delay to turn OFF device',
    91332: 'This field is enabled when device is configured as Off-Delay type and it is a mandatory field in this case. ',
    91333: 'Feedback Point',
    91334: 'Please select from the list ',
    91335: 'This field is optional. Feedback point is an Analog Input that reads the status of the device, enabling system to check if command is executed properly. If the feedback point reading does not match  the request, an alarm can be generated on the reading (feedback point needs to have alarms enabled). ',
    91336: 'Reversed feedback',
    91337: 'Please select from the list',
    91338: 'This field is enabled when device is configured as Dual Input type and it is a mandatory field in this case. The point is linked with the digital output, and should always read opposite from the requested signal on the digital output. Example: DO is signal to Open the door. Reversed DI is signal : Door closed; so when we request 1 on the output Open the Door,  reversed feedback Door Closed Status should be false. If the reading of the reversed feedback point  matches  the request, an alarm can be generated on the reading (reversed feedback point needs to have alarms enabled).  ',
    91339: 'Controlled by DI',
    91340: 'Please select from the list',
    91341: ' This field is enabled when device is configured as On-When-On / On-When-Off / Off-When-On / Off-When-Off. The long description of these device types are:  Turn output On when input state is On / Turn output On when input state is Off / Turn output Off when input state is On / Turn output  Off when input state is Off . Select the digital input device that will  terminate the action of the digital output device. Example: we have a digital output of type Off-When-On. This output is controlled by the  digital input Door-Open. If we set this output to ON (open the door) the signal will automatically be set back to 0 once the Door-Open signal reaches 1 (door is open). ',
    91342: 'Visible',
    91343: 'Make the point visible in Detail Screen',
    91344: 'Checking this box makes the point visible in Detail Screen.',
    91345: 'Point',
    91346: '',
    91347: '',
    91348: 'Value/Set Value',
    91349: '',
    91350: '',
    91351: 'Retransmit Time(sec.)',
    91352: '',
    91353: '',
    91354: 'Error Value',
    91355: '',
    91356: '',
    91357: 'Message point',
    91358: 'Marks this point as a message point',
    91359: '',
    91360: 'Decision points',
    91361: 'Marks this point as a decision point',
    91362: 'Decision point is a pseudo point. It holds calculated value based on real readings of several ianalog input points. The first point (on the top) is a dominant point – takes precedence. ',
    91363: 'Decision type',
    91364: 'Select the decision algorithm',
    91365: 'Decision algorithms are:  Avoid Error,  Highest Value,  Lowest Value,  Mean Value and  Threshold Highest. All of these types except Threshold take only source points as arguments. Threshold point  also requires a  Threshold Value argument.  Avoid Error point gives the value of the higher priority (first-specified) point as long as the point is not in error state (no-response state)  ',
    91366: 'Threshold value',
    91367: 'Please set the value for the threshold',
    91368: 'Threshold Highest is the algorithm applied  for the probe on the PT455.  It requires exactly two source points (where the other types have N).  It gives the value of the higher priority (first-specified) point as long as the difference between the values of the source points is less than or equal to the Threshold Value (“TV” argument).  If the difference is greater than TV, then the higher of the two values is returned.',
    91369: 'Follow SP',
    91370: 'Follow set point',
    91371: 'The pseudo point will hold value equal to its set point.',
    91372: 'Disable no-response (real I/Os)',
    91373: 'Real Points - Disable no-response alarm',
    91374: 'Applicable to real points only. By default no-response alarms for real points are always enabled.This filed allows us to change the default and completely disable no-response alarms, for any real point. ',
    91375: 'No-Response Alarm Delay',
    91376: 'Alarm Delay for no-response alarms, applicable to pseudo inputs',
    91377: 'Applicable to pseudo points only. If NO-RESPONSE alarm for pseudo point is enabled, this field allows user to specify alarm delay. If NO-RESPONSE alarm for pseudo point is enabled and this field is left empty, default NO-RESPONSE alarm delay is 60 seconds.',
    91378: 'Enable no-response (PAIs/PDIs)',
    91379: 'Pseudo Inputs - Enable no-response alarm',
    91380: 'Applicable to pseudo inputs only. By default pseudo points have no NO-RESPONSE alarms. When the box is not checked, the PAI cannot have no-response alarm. When no-response alarm is enabled, default alarm delay is 60 seconds. User can overwrite the default alarm delay by specifying the desired alarm delay in seconds.',
    91381: 'Enable serial number',
    91382: 'Enable serial number',
    91383: 'Enable serial number',
    91384: 'Enforce defeat',
    91385: 'Enforce defeat',
    91386: 'Enforce defeat',
    91499: '',
    92000: 'Calibration Value',
    92001: 'Calibration Count',
    92002: 'Offset Value',
    92003: 'All editable field in the Calibration table cannot be empty.',
    92004: 'Calibration Configuration',
    92005: 'Calibration Description',
    92006: 'Enable Calibration',
    92007: 'Enable Offset',
    92008: 'Calibration Values in the table have to be in the increasing order.',
    92009: 'Calibration Counts in the table have to be either in the increasing or decreasing order.',
    92010: 'More than two rows are required for Calibration Values/Counts.',
    92011: 'Max Calibration value has to be greater than Min Calibration value',
    92012: 'Max Calibration Count has to be greater than Min Count value',
    92013: 'Evenly distribute points based on Min/Max',
    92014: 'Number of points between Min/Max',
    92015: 'Tick for decreasing order',
    92016: 'Some fields have missing data',
    92017: 'The 1st row is the Min value and the 2nd row is the Max Value. Max has to be greater than Min',
    92018: 'Volts',
    92019: 'All the data on the GUI will be erased. Are you sure you want to switch to a different Calibration Type? ',
    92101: 'Gui Configuration Utility',
    92102: 'Property',
    92103: 'Configuration has been saved successfully. However, some points are improperly configured',
    92104: 'Multi system not supported by this gui config util',
    92105: 'The following tag is not supported by this gui config util',
    92106: 'Hot spots are not supported by this gui config util',
    92107: '',
    92108: '',
    92109: '',
    92110: '',
    92111: '',
    92112: '',
    92113: '',
    92114: '',
    92115: '',
    92116: '',
    92117: '',
    92118: '',
    92119: '',
    92120: '',
    93000: 'User Manager Export Users Utility',
    93001: 'Invalid password, try again?',
    93002: 'Please enter your password',
    93003: 'Please select user data file <QUsers.dat>',
    93004: 'User Full Name',
    93005: 'User Name',
    93006: 'Is Nitrex User',
    93007: 'User Permissions',
    93008: 'Permission',
    93009: 'Access',
    93010: 'Users data file has been saved',
    93011: 'Unable to save',
    93030: 'Preferences',
    93031: 'Encrypt system configuration file',
    93032: 'Encrypt io configuration file',
    93033: 'Select Users',
    94001: '',
    94002: '',
    94003: '',
    94004: '',
    94005: '',
    94006: '',
    94007: 'Time Zone',
    94008: 'Set a different time zone for the PT9800',
    94009: 'If you want to keep the same time zone your computer is set to, leave this field empty. If client software needs to use different time zone than the computer it is installed in, select the time zone from the list. The selected time zone will be visible inside PT9800 application only.',
    94010: 'Partial Backup',
    94011: 'Backup records created after the specified date.',
    94012: 'This feature is used for the backups that last too long. If application that extracts database to a file (dbunload) takes too much time, we can do a selective backup; we can specify a date&time for the backup to filter data. Before setting this date, user shall rename the UNLOAD folder  (C:/users/dbtool/UNLOAD)  to "UNLOAD_BEFORE_DateTime" and this, user shall recreate a new UNLOAD folder (typically (C:/users/dbtool/UNLOAD). The renamed folder contains a full backup. After that, set the parameter. Once  application "dbunload" is invoked it will selectively extract data from the archive tables (a_value, a_msg, a_any ...) that are newer than the specified date/time. Use this feature with caution, you need to have full understanding of the procedure . Reset the time every 2 years.',
    94013: 'DB Version No.',
    94014: 'PT9800 data base version number',
    94015: 'PT9800 database tables evolve. Each new version  is marked with  a unique  version number. Different versions have different number of tables, different fields inside the table. Do not change this field unless you get instructions and approval from the supplier (software department).',
    94016: 'Customer No.',
    94017: 'Numeric code for customer',
    94018: 'Every Customer has a unique code (number). Once assigned, this number shall not be modified. For the new customers obtain the number from the people in charge, never invent or use a random number!',
    94019: 'Customer Name',
    94020: 'Customer name',
    94021: 'This field holds customer name.',
    94022: 'Debug Level',
    94023: 'Set the amount of a debug information to be written',
    94024: 'Debug Level of the Background Programs. To disable writing debug onfo, set this field to  0. Suggested value is 1 . When we need more debug info, set a higher number. Consult with supplier (software department first). Other options are: 6,48,64,8208,1040 ... These options are useful during the startups and whenever troubleshooting problems with drivers.',
    94025: 'Translation',
    94026: 'Enable or disable translation',
    94027: 'Enables or disables translation module. Should be enabled all the time. Option disabled is used only for German developers.',
    94028: 'ºC  or  ºF',
    94029: 'Select the unit for temperature',
    94030: 'Defines which unit of measure to use for the temperature.  Two options available ºC and ºF. ',
    94031: 'mm or thsd',
    94032: 'Select the unit for hardening layers',
    94033: 'Defines which unit of measure to use for hardening layers (Carburizing Depth, Hardness Depth, White Layer etc.). Two options are: mm or thou.',
    94034: 'mm or inch',
    94035: 'Set the unit for length&diameter',
    94036: 'Defines which unit of measure to use for the length/diameters.',
    94037: 'PE Logo',
    94038: 'Sets the logo on the toolbar',
    94039: 'Defines if PE Logo will be displayed on each toolbar, in the top right corner. ',
    94040: 'Chart Demo',
    94041: 'Chart demo mode',
    94042: 'Enables / Disables demo mode for historical charts. By default, demo mode is disabled. This feature is useful for shows, presentations…',
    94043: 'Continuous Charts',
    94044: 'Ignore the interrupt in data reception',
    94045: 'This field defines the behavior of the chart when data transfer is interrupted (the delay between the two adjacent points exceeds the maximum value). By default this delay is not ignored. When data transfer is interrupted the chart should display a break /gap. However, for controllers that do not resend data unless data has been changed this flag is very useful.  Set this flag enabled for older versions of PT500.',
    94046: 'Interrupt Delay',
    94047: 'Delay to declare data transfer interrupt',
    94048: 'This field defines when to declare that data transfer is interrupted. It represents the  maximum acceptable time (in minutes) between the two consecutive signals. If data is not received within this interval, the chart will show a gap. For the newer installations (2010 and later), maximum distance between the two points is defined in the Chart Manager. If older version of PT500 controller is used, please  enter 999. For newer installations suggested value is 7 (minutes).',
    94049: 'Confirm Deletion',
    94050: 'Confirm deletion in dictionary',
    94051: 'This field defines if the confirmation is required before deleting items from the dictionary. If large amount of text needs to be deleted from the dictionary choose to disable confirmation. Carefully use this option, if you do not know interface "by-heart" leave important text to help you save, close, navigate through the interface. By default, confirmation is requested before each deletion.',
    94052: 'Max Logs',
    94053: 'Max Entries in Production Log',
    94054: 'Sets the maximum  number of jobs to be displayed in Production Logs screen. By default it is 10,000.',
    94055: 'Production Logs Offset',
    94056: 'Interval to display recent logs',
    94057: 'Set the interval (in days) for Production Log module  to display recent logs. This interval sets the default start time for the production logs search. The search interval is set from the present time minus this value. Only jobs created within this interval will be displayed. Default value is 1 day.  Note, decimal values are accepted - it is possible to ask for a  0.5 (last 12 hours) or 0.25 (last 6 hours) etc...',
    94058: 'Default Language',
    94059: 'Set default language',
    94060: 'Sets the language to be used in the User Login Screen.  Should be set to the language of the country where the software is installed. Once user logs in, interface takes the language of the user.',
    94061: 'Style&Color',
    94062: 'Select the style and the background color',
    94063: 'Sets the style and the background color of the user interface - client application. ',
    94064: 'Highlight  Buttons',
    94065: 'Highlight buttons with shade of orange',
    94066: 'A button can be automatically highlighted when the user positions the cursor over it. This parameter enables or disables automatic change of a button highlight state. When using Clean Look style, disable this feature  (default for PT9800-installations after 2010)',
    94067: 'Alarm Delay',
    94068: 'Delay to send alarm notification',
    94069: 'Delay in seconds till alarm notification is sent through GSM modem, email…. Default value is 30 (sec). If alarm condition lasts shorter than the alarm delay specified by this parameter, alarm notification will not be sent.',
    94070: 'Database Alarms',
    94071: 'Turn OFF database alarms',
    94072: 'Defines if database errors should be displayed or not. By default, database faults are displayed. Disable database alarm notifications only in special cases,temporarily, till problems are solved.',
    94073: 'Splash Screens',
    94074: 'Start with splash screens',
    94075: 'Defines if the splash screens shall be used before opening the three essential interfaces. User should select the combination that suits the installation the best. Splash screens are not necessary for the high performance computers.',
    94076: 'Monitor Service',
    94077: 'Monitor PT9800 service',
    94078: 'Reserved for administrators only. Flag to enable/disable monitoring of the PT9800 (p9start) service. It should always be enabled. Client software monitors if the service is running. When it detects that the service is not running, it pops up a message to inform the user for how long the service is NOT running.',
    94079: 'Join a_ld_ext',
    94080: 'Extended load data / external data',
    94081: 'This is a protected parameter, for administrators only. Defines how to query production logs when external database exists.',
    94082: 'Pusher Screen',
    94083: 'Draw pusher screen in multiple steps',
    94084: 'Defines the way Pusher Screen is drawn. This parameter should be enabled for slow computers or slow database. When enabled, user interface is drown in steps, so end user sees activities on the screen. Typically this parameter should  not be enabled.',
    94085: 'Workplace Type',
    94086: 'Workplace: Pusher, Belt or None',
    94087: 'Defines the type of installation  in respect what types of workplaces are used. With pushers and belt furnaces or without. Select the option appropriate to your installation.',
    94088: 'Delete Loads',
    94089: 'Permission to delete production logs',
    94090: 'Enables/Disables deleting loads from the Production Logs. Note, user is able to delete loads from Production Logs if this parameter is set and  if she has permission to editing fields.',
    94091: 'Time Axis Units',
    94092: 'Time format on X axis',
    94093: 'Defines the format of time on the chart. Time can be expressed as elapsed time (in minutes) or can be in date time format. This parameter applies to Production Logs only (per-load histogram). Workplace histogram is always in Date/Time format.',
    94094: 'Standby Values',
    94095: 'Setting standby mode',
    94096: 'Use this field to enable or disable the "Standby Values" tab inside the workplace screen, or to select a special implementation of the standby mode.  When setting standby values is enabled, user can define set values for process variables that will be active when workplace is idle - not running a job. Two special implementations exist, one is applicable to customer 1016 only, the other one is used for an oil bath. When Oil bath option is selected, a new tab page is created in the workplace window, allowing user to edit additional set values.',
    94097: 'PT500 Buttons',
    94098: 'Enable PT500 buttons',
    94099: 'Applies for PT500 workplaces only. This field enables or disables PT500 buttons on the client GUI. It is not recommended to remotely command I/Os from the SCADA workplace GUI. This could be dangerous, one button click may jeopardize the safety of the workplace (like opening the furnace door, or turning some devices ON ...)',
    94100: 'Alignment for PT600',
    94101: 'Correction of button/label alignment',
    94102: 'This field applies for the PT600 workplaces only, it enables or disables the alignment of buttons and labels. For PT600 it may happen that the buttons and the labels are not of the same height. In this case alignment needs to be enabled.',
    94103: 'Tooling Assignment',
    94104: 'Module from which tooling is assigned',
    94105: 'By default tooling is assigned form the Part module. Typically for automats tooling is assigned per load. Other options are to assign tooling from the Job Start screen, or from the Car Command. The Car Command option cannot be used for any customer',
    94106: 'Delay for ux_dcr',
    94107: 'Delay to terminate ux_dcr',
    94108: 'Obsolete parameter. Not used as a configurable parameter, but used by the application.',
    94109: 'Part & Spec',
    94110: 'Assign  specification parameters to a part',
    94111: 'This parameter determines how specification parameters are attached to a part. There are two options.  Every Part can have the same collection of specification parameters, defined in Operations->PE-STANDARD, or part can inherit specification parameters from the Operation Type. End user needs to assign an Operation type to each part.  Example of Operation Types: Nitriding, Carburizing...',
    94112: 'Customer No./Drawing No.',
    94113: 'Attribute for the first field in the Part interface',
    94114: 'Part table & Part GUI have two fields that have different meanings based on the setting of the  two different parameters. There are 4 attributes linked with the part: Drawing No., Revision No., Customer No. and Order No. Only two fields exist on the GUI. This parameter setting defines which parameter will be used in the first field. Two options are available: Drawing No or Customer Number. When Drawing No. is selected as the first field, the second field always contains Revision No. of the drawing. When Customer No. is selected for the first field, then second field can take one of the two values: Order No. or Drawing No.  Which one will be used as a second field is determined with another configuration parameter  "Attribute for the second field in the Part interface',
    94115: 'Drawing No. /Order-No.',
    94116: 'Attribute for the second field in the Part interface',
    94117: 'Part table & Part GUI have two fields that have different meanings based on the setting of the  two parameters. There are 4 attributes linked with the part: Drawing No., Revision No., Customer No. and Order No. Only two fields exist on the GUI. This parameter setting defines the options for the second parameter when the first parameter is set to Customer No.  This field is active only when parameter  "Attribute for the first field in the Part interface" is set to Customer No. Select between  the Order No. and Drawing No. The final result will be that part screen will display either  Customer No. & Order No. or Customer No. &  Drawing No. By default  Customer No. &  Drawing No are displayed.',
    94118: 'Translate Alarms',
    94119: 'Obsolete Parameter',
    94120: 'Obsolete Parameter.Parameter not used since versions from 2010. Applies to non-externalized versions.  Defines  the range of alarms to be translated or not translated.',
    94121: 'Columns in Printout',
    94122: 'Style&number of parameters in the production log printout',
    94123: 'Defines the appearance of the production log  report and the number of recipe parameters to be printed. When old style printouts are selected, user have option to add (or not) the tolerance to the printout. For the new style printouts user can select between 2 and 20 parameters to be included in the printout.  Typically, for carburizing treatments, 2 parameters are enough - Temperature and Carbon Potential.',
    94124: 'Number of Copies',
    94125: 'Workplace Report: Number of Copies to be printed',
    94126: 'Applicable for automats only. Relates to printing workplace reports. Defines number of copies to be printed. Set it to 0 to disable printing.',
    94127: 'Keep in Memory or Release',
    94128: 'Keep  UIs in memory or release memory when UI is closed',
    94129: 'Three types of user interface windows can be set not to be closed when user requests to close them: Workplace GUI, Shop Layout and Production Logs. When close is requested, these windows stay loaded in memory but become invisible. By default all these windows are set to remain loaded in memory all the time. Other combinations are available too, we can, for example, select to keep only Shop Layout loaded but release resources for other windows when they are closed. To set this parameter properly we need to know hardware configuration (RAM) of the computers where client application is running. This is a trade off between the fast invoking of user interface screens and overall computer performance (we do not want system to run low in memory). When the computer has enough resources (RAM), keeping several windows in memory (number of loaded windows depends on  how many workplaces are configured)  will make these windows "loading" fast and this will not significantly deteriorate computer performance. For systems not rich in memory, better solution is to free the memory of the window that is closed.',
    94130: 'QM Create Date',
    94131: 'QM Page Create Date',
    94132: 'Creation Date  in QM Printouts',
    94133: 'QM Modify Date',
    94134: 'QM Page Modify Date',
    94135: 'Modify Date  in QM Printouts',
    94136: 'QM Author',
    94137: 'QM Page Author',
    94138: 'Author Name in QM Printouts',
    94139: 'Part in Production Logs',
    94140: 'Include or exclude Part from Production Logs search',
    94141: 'Defines if Part Order information will be inside search criterion in Production Logs. Normally part  is included in the search criterion. For slow server or slow database disable fetching of part-order information.',
    94142: 'Win Update Path',
    94143: 'Update path for clients running Windows OS',
    94144: 'Path where windows version of PT9800 updates reside (pe_9800.jar, Translation...)',
    94145: 'Linux Update Path',
    94146: 'Update path for clients running Linux OS',
    94147: 'Path where Linux version of PT9800 updates reside (pe_9800.jar, Translation...)',
    94148: 'Start Archiving',
    94149: 'Elapsed time (number of days)  to begin archiving',
    94150: 'The fastest growing tables, tables  that contain historical data: a_value and  a_any need to be archived. Old records are moved to designated tables. This field determines when we should start archiving.  The value represents number of days. 720 is the default value  (~ 2 years). Whenever the value is greater than 0, archiving is enabled and we have to activate the background process ux_jahr in order for archiving functions to work properly.',
    94151: 'Special Load',
    94152: 'Load Marker',
    94153: 'Designates if the load requires additional attention. Typically used for big loads which dimensions  exceed  a limit (too heavy, too long, to high ...), or for the loads that need to be verified.',
    94154: 'Print Load List',
    94155: 'Enables or disables printing of Load List',
    94156: 'This enables printing a load list. When enabled, an additional Print-Preview button appears  in Automat-Place-Information or in Production Logs (Part/Order)',
    94157: 'Printout Header',
    94158: 'Printout Header for the Load List',
    94159: 'Defines the header  for the Load List to be printed.',
    94160: 'Paper Format',
    94161: 'Set the paper format',
    94162: 'Applies when printing Load List or a Work Place information. If users always use the same format for printing, they should select the one they use. Otherwise, user should select a Print Dialog, and she will be asked to select a  format each time she prints.',
    94163: 'Toner Save Mode',
    94164: 'Enables or disables toner save mode',
    94165: 'Defines how to send information to a printer when Furnace Screen is printed. It is recommended to use toner save mode, when black background color is replaced with the light gray.',
    94166: 'SAP Path',
    94167: 'SAP Path for Pusher',
    94168: 'Applicable for pusher furnaces. Represents the path for reading SAP-Files (SAP files contain Part/Order-information for Pushers). Please put a (back) slash character at the end of the path.',
    94169: 'Reference to Part',
    94170: 'When starting a job, select a part',
    94171: 'Determines how to reference the part: as a free field where end user types part name/description, or by pointing to the record in the part table. By default no link with Part table is required, which is preferred option for most heat treaters. If user selects the option to use parts from the Part table, be aware that part has to exist in database prior to creating a load.',
    94172: 'Total Weight',
    94173: 'Display total weight in the Part form',
    94174: 'Obsolete Parameter.Determines if total weight will be displayed or not in the Part Edit form. By default, this value is displayed. Obsolete, today every part has total weight.',
    94175: 'Disable Read-Write Alarms',
    94176: 'Enable/Disable communication alarms with controllers',
    94177: 'Normally communication alarms should always be enabled. For customers who turn off their controllers very often, disabling the alarms should be considered.',
    94178: 'Varchar or BLOB',
    94179: 'Replace BLOB with Varchar',
    94180: 'For languages that cannot use ASCII characters (like Chinese), we need to replace BLOBs with varchars. By default application is set to keep BLOBs',
    94181: 'Time Format',
    94182: 'Specify time format',
    94183: 'Specifies the time format. If you want to keep the same time format your computer is set to, leave this field empty. If client software needs to use different time format than the computer it is installed in, select the time format from the list. The selected time format will be visible inside PT9800 application only.',
    94184: 'Date Format',
    94185: 'Specify date format',
    94186: 'Specifies the date format. If you want to keep the same date format your computer is set to, leave this field empty. If client software needs to use different date format than the computer it is installed in, select the date format from the list. The selected date format will be visible inside PT9800 application only.',
    94187: 'Production Timer',
    94188: 'Production minute counter',
    94189: 'Applicable to automats only. Typically production timer is disabled (non-automats). For automats, select the source for the time counter: external PLC or internal database (table ux-btrb).',
    94190: 'Max Loads',
    94191: 'Max number of loads in the cell',
    94192: 'Applicable to automats only. Number of loads should be limited; it shall always be smaller then the number of magazines (storage stations). For example, if we have 10 magazines and 6 furnaces and we want to limit the loads in the automat to  make sure we always have one magazine free, we should put 9 in this field. Without a limitation we can fill up the Automat and get production  stuck.',
    94193: 'PT9000 Load Certificate',
    94194: 'Enables/Disables a PT9000 style report',
    94195: 'When enabled a button appears in Production Logs to view and print the report. Report layout needs to be specified under "8001 - Definition of the Heat Treatment Certificate" .',
    94196: 'Picture Path',
    94197: 'Set initial path for pictures',
    94198: 'Applicable when  the option "Pictures" is enabled. This is an initial path for Open Picture Dialog.',
    94199: 'Number of seats',
    94200: 'Maximum allowed number of seats / client software',
    94201: ' If license protection is not installed, enter the encoded number, based on the following procedure. First multiply Customer Number with maximum allowed seats. After the each digit of the product, starting from left,  insert numbers 1,2,3 etc; meaning after the first digit from the left, insert 1, after the second digit from the left insert 2 etc… Example: Customer Number = 1016, number of seats = 3.  Product of these two numbers is 3048.  After inserting 1,2,3 & 4  after each digit from the left, encoded number to be entered is 31024384',
    94202: 'Pusher ID',
    94203: 'Pusher ID for simulation ',
    94204: '',
    94205: 'Version No.',
    94206: 'Navision Version Number',
    94207: 'Please enter the Navision software product number . If there is no connection with Navision system, type  0. Navision is an enterprise resource planning software from Microsoft.',
    94208: 'Charge Sequence Mode',
    94209: '',
    94210: '',
    94211: 'Chart Mode',
    94212: 'Use modern style',
    94213: '',
    94214: 'Planning chart for normal 9800',
    94215: 'Disable/enable planning chart for normal 9800',
    94216: '',
    94217: 'WBN Export Buttons',
    94218: '',
    94219: '',
    94220: 'Recipe runtime',
    94221: '',
    94222: '',
    94223: 'Server License',
    94224: 'Use server licensing',
    94225: '',
    94226: 'Look & Feel',
    94227: 'Use new look & feel',
    94228: 'Select the style of graphic user interfaces, based on your preferences.',
    94229: 'Bigger Icons',
    94230: 'Use higher resolution icons',
    94231: 'If enabled, icons are 24x24 pixels, else 16x16. Select the icon size, based on your preferences as well as monitor resolution.',
    94232: 'Energy management',
    94233: 'Disable/Enable energy management',
    94234: 'By default should be disabled. The feature, if enabled,  will work only for  one specific customer',
    94235: 'NADCAP compliant ',
    94236: 'Protect data against modifications.',
    94237: 'If data are protected – system complies to  NADCAP standard. When this box is checked, an error-detecting code (crc) is added to disclose changes to data. ',
    94238: 'Add Columns to a_cont_pos ',
    94239: 'Create/Use new fields in a_cont_pos table',
    94240: 'This feature applies to automats only. User has three options:  Not to create and use new fields;  To create new fields and To use already added fields. If user selects to create new fields, fields will be created and after refreshing the screen, drop down list  will be set to “Use added fields”. Once fields are created, please do not try to create them again.',
    94241: 'Load List',
    94242: 'Scheduled Load List',
    94243: 'When this feature is enabled a “View Load List” button will appear in the Furnace Recipe Tab. Load list can be obtained from  the PT9800 Load Sequencer or from the external application, like TTC’s ASM software',
    94244: 'Demo Date',
    94245: 'Demo Expiration Date ',
    94246: 'Date when demo version of PT9800 Client Software will stop running.',
    94247: 'Lock terminal',
    94248: 'Lock terminal until the following date',
    94249: 'Date until which terminal will be locked',
    94250: 'AMS Automat',
    94251: 'Enable connection to AMS',
    94252: 'AMS is a commercial heat-treating software by TTC.  When this feature is enabled, PT9800 can exchange data with TTC’s AMS software',
    94253: 'Client Auto Print',
    94254: 'Enable automatic printing for specific client',
    94255: '',
    94256: 'Load Auto Print',
    94257: 'Enable automatic printing for specific load',
    94258: '',
    94259: 'Auto print sleep time',
    94260: 'Wait time int seconds between two print request',
    94261: '',
    94262: 'Minimum workplace id for auto printing',
    94263: '',
    94264: '',
    94265: 'Maximum workplace id for auto printing',
    94266: '',
    94267: '',
    94268: 'Production log selection order',
    94269: 'Disable/Enable usage of a combo box to select header',
    94270: '',
    94271: 'Standby active if changed',
    94272: '',
    94273: '',
    94274: 'AMS IP Address',
    94275: ' AMS Server IP Address ',
    94276: ' Fill in  this field only if communication with AMS software is enabled.  Please enter the IP address of the AMS server. AMS is a commercial heat-treating software by TTC. PT9800 can exchange data with TTC’s AMS software. ',
    94277: 'AMS Port Number',
    94278: ' AMS Server Port Number ',
    94279: ' Fill in  this field only if communication with AMS software is enabled. Please enter the port number on AMS server,  in charge of communication with PT9800 server. AMS is a commercial heat-treating software by TTC. PT9800 can exchange data with TTC’s AMS software. ',
    94280: 'PE Port Number',
    94281: ' PE Port Number for AMS ',
    94282: 'Fill in  this field only if communication with AMS software is enabled. Please enter the port number on PT9800 server,  in charge of communication with AMS server. AMS is a commercial heat-treating software by TTC. PT9800 can exchange data with TTC’s AMS software. ',
    94283: 'AMS Time Out',
    94284: 'AMS Communication Timeout',
    94285: ' Fill in  this field only if communication with AMS software is enabled.  Please enter a time out delay for communication with AMS server. AMS is a commercial heat-treating software by TTC. PT9800 can exchange data with TTC’s AMS software. ',
    94286: 'Debug Information',
    94287: 'Disable/Enable debug Information',
    94288: 'When enabled, a debug tab will appear in the shop layout GUI, containing debug messages. ',
    94289: 'Image for process flow',
    94290: '',
    94291: '',
    94292: 'Picture path',
    94293: 'Process flow picture path',
    94294: '',
    94295: 'Hot standby in ect/hosts',
    94296: 'Disable/enable hot standby in etc/hosts',
    94297: 'If connected to a Siemens-PLC and Linux: in etc/hosts should be a alias for pe_server',
    94298: 'Cell with IVA',
    94299: '',
    94300: '',
    94301: 'Production log print button',
    94302: 'Hide/show production log print button',
    94303: 'Prints the search list',
    94304: 'Auto create PDF file.',
    94305: 'Client number for auto creation of PDF files',
    94306: '',
    94307: 'Auto create PDF file folder.',
    94308: 'Specifies the folder where automatically generates PDF files are stored',
    94309: '',
    94310: 'Auto create PDF options',
    94311: 'Options for the auto create PDF files',
    94312: 'Specifies Options for the automatically generates PDF-A files at the moment it can be set which pages shall be printed',
    94313: 'Scaling axis in chart',
    94314: 'Disable/enable scaling in chart',
    94315: '',
    94316: 'Auto log out',
    94317: 'Client will log out automatically after n minutes',
    94318: 'Specify the client number that will have auto log out enabled',
    94319: 'Navision manufacturing cell',
    94320: '',
    94321: '',
    94322: 'Navision company name',
    94323: '',
    94324: '',
    94325: 'Externalization',
    94326: 'Externalization of strings',
    94327: '',
    94328: 'Monday Hours',
    94329: 'Max. working hours on Mondays',
    94330: 'Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94331: 'Tuesday Hours',
    94332: 'Max. working hours on Tuesdays',
    94333: ' Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94334: 'Wednesday Hours',
    94335: 'Max. working hours on Wednesdays',
    94336: ' Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94337: 'Thursday Hours',
    94338: 'Max. working hours on Thursdays',
    94339: ' Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94340: 'Friday Hours',
    94341: 'Max. working hours on Fridays',
    94342: ' Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94343: 'Saturday Hours',
    94344: 'Max. working hours on Saturdays',
    94345: ' Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94346: 'Sunday Hours',
    94347: 'Max. working hours on Sundays',
    94348: ' Please enter the number of available production hours (0-24), for the specific day. This number is used to calculate the equipment utilization. For example, if plant operates 16 hours on specific day, and equipment was used for 10 hours that day, its utilization for that day is 10/16 = 62.5% ',
    94349: 'Availability(%)',
    94350: 'Availability time in percentage',
    94351: ' Represents the percentage of scheduled time that the equipment is available to operate. Availability (%) = 100 * Available Time / Scheduled Time',
    94352: 'Target time of part ??94352',
    94353: '',
    94354: '',
    94355: 'Bar in chart separated in operation',
    94356: '',
    94357: '',
    94358: 'Place holder for "0" value',
    94359: '',
    94360: '',
    94361: 'MDE production hours',
    94362: '',
    94363: '',
    94364: 'QM search mode',
    94365: 'Search lab evaluation by',
    94366: '',
    94367: 'Print identifier <piston>',
    94368: '',
    94369: '',
    94370: 'Print identifier <Mi-Za>',
    94371: '',
    94372: '',
    94373: 'Customer dept. name',
    94374: '',
    94375: '',
    94376: 'FTE/cost center',
    94377: '',
    94378: '',
    94379: 'FTE/cost center right',
    94380: '',
    94381: '',
    94382: 'Number of relations SpecSetMaterialPlan',
    94383: '',
    94384: '',
    94385: 'FTE122 (dept. 1)',
    94386: '',
    94387: '',
    94388: 'QME1 (dept. 2)',
    94389: '',
    94390: '',
    94391: 'QME2 (dept. 3)',
    94392: '',
    94393: '',
    94394: 'Print QM certificate with',
    94395: '',
    94396: '',
    94397: 'Company No.',
    94398: '',
    94399: '',
    94400: 'Small QM mode',
    94401: '',
    94402: '',
    94403: 'Automat type',
    94404: '',
    94405: '',
    94406: 'Load planning(cell)',
    94407: '',
    94408: '',
    94409: 'Number of places in rotary magazine',
    94410: '',
    94411: '',
    94412: 'Load color mode',
    94413: '',
    94414: '',
    94415: 'Process flow history',
    94416: 'Disable/Enable process flow history',
    94417: 'If enabled it is possible to view the versions in production log only for archived data atm. planed for base data',
    94418: 'Last empty cell rack number',
    94419: '',
    94420: '',
    94421: 'Last cell hold number',
    94422: '',
    94423: '',
    94424: 'Leading character for cell load number',
    94425: '',
    94426: '',
    94427: 'Last content number',
    94428: '',
    94429: '',
    94430: 'Leading character for content number',
    94431: '',
    94432: '',
    94433: 'Name of archive criteria 1',
    94434: '',
    94435: '',
    94436: 'Name of archive criteria 2',
    94437: '',
    94438: '',
    94439: 'Name of archive criteria 3',
    94440: '',
    94441: '',
    94442: 'Order number field name',
    94443: 'Name of field for order number in parts list',
    94444: '',
    94445: 'Serial number field name',
    94446: 'Name of field for serial number in parts list',
    94447: '',
    94448: 'Oil quench ID',
    94449: '',
    94450: '',
    94451: 'Oil quench place no.',
    94452: '',
    94453: '',
    94454: 'N2 quench ID',
    94455: '',
    94456: '',
    94457: 'N2 quench place no.',
    94458: '',
    94459: '',
    94460: 'Charge car ID',
    94461: '',
    94462: '',
    94463: 'Auto loading place ID',
    94464: '',
    94465: '',
    94466: 'Auto loading place no',
    94467: '',
    94468: '',
    94469: 'Cell base B_WP_ID',
    94470: '',
    94471: '',
    94472: 'Part selection',
    94473: 'Disable/Enable parts selection with matching process flows',
    94474: 'Part selection finds only parts with same process flow',
    94475: 'Name of box no.',
    94476: '',
    94477: '',
    94478: '',
    94479: '',
    94480: '',
    94481: 'Name of field 3 parts(CustomerOrderNo)',
    94482: '',
    94483: '',
    94484: 'Name of field 4 parts(Remark)',
    94485: '',
    94486: '',
    94487: 'Recipe history',
    94488: 'Disable/Enable Recipe history',
    94489: '',
    94490: 'Last load no.',
    94491: '',
    94492: '',
    94493: 'Load no. leading character',
    94494: '',
    94495: '',
    94496: 'Without PT500',
    94497: '',
    94498: '',
    94499: 'Option load sequence',
    94500: '',
    94501: '',
    94502: 'Production log load assignment',
    94503: 'Disable/Enable assign content of load to production logs',
    94504: '',
    94505: 'GetNewid via SQL Procedure/Function',
    94506: '',
    94507: '',
    94508: 'Recipe info print out',
    94509: 'Disable/Enable recipe info on printout',
    94510: '',
    94511: '',
    94512: '',
    94513: '',
    96999: '',
    97000: 'General',
    97001: 'Common Parameters',
    97002: 'Quality Control',
    97003: 'Utilization',
    97004: 'Recipe',
    97005: 'Automat',
    97006: 'Connections',
    97007: 'System',
    97008: 'Language',
    97009: 'Look&Feel',
    97010: 'Unit',
    97011: 'Time',
    97012: 'Performance',
    97013: 'Path',
    97014: 'Alarm',
    97015: 'Part',
    97016: 'Production Log',
    97017: 'Printout',
    97018: 'Chart',
    97019: 'Control',
    97020: 'Maintenance',
    97021: 'Obsolete',
    97022: 'AMS',
    97023: 'Navision',
    97024: 'SAP',
    97025: 'Cell 1',
    97026: 'Cell 2',
    97027: 'Automat',
    97028: 'Batch',
    97029: 'Light Blue',
    97030: 'Windows Style',
    97031: 'Clean Style',
    97032: 'No Pusher/Belt furnaces',
    97033: 'Reserved for 1016',
    97034: 'Pusher',
    97035: 'Belt',
    97036: 'Date & Time',
    97037: 'Elapsed Time',
    97038: 'No',
    97039: 'Reserved for 1016',
    97040: 'Yes',
    97041: 'PT500',
    97042: 'In Part',
    97043: 'Per Load',
    97044: 'In Recipe Start',
    97045: 'In Car Command',
    97046: 'Same for all parts',
    97047: 'Same for all parts',
    97048: 'Drawing No & Revision No',
    97049: 'Customer No & Order/Drawing No',
    97050: 'Drawing No. is displayed',
    97051: 'Order No. is displayed',
    97052: 'Alarms in the range 1 to 1024',
    97053: 'Alarms in the range 1 to 1024 and alarms greater 512',
    97054: 'None will be translated',
    97055: 'None',
    97056: 'Furnace only',
    97057: 'Shop Layout only',
    97058: 'Furnace and Shop Layout',
    97059: 'Production Logs Only',
    97060: 'Furnace & Production Logs',
    97061: 'Shop Layout &Production Logs',
    97062: 'Furnace & Shop Layout & Production Logs',
    97063: 'Current setting on the PC',
    97064: 'DIN A4',
    97065: 'Print Dialog',
    97066: 'Current setting on the PC',
    97067: '12 hours (AM, PM)',
    97068: '24 hours',
    97069: 'Current setting on the PC',
    97070: 'YY-MM-DD',
    97071: 'MM-DD-YY',
    97072: 'DD-MM-YY',
    97073: 'Use time (hours) received from the furnace PLC',
    97074: 'Used time (hours) from the table ux_btrb (pt9800)',
    97075: 'Default',
    97076: 'Earliest start time',
    97077: 'Production log',
    97078: 'Ascii',
    97079: 'XML',
    97080: 'All',
    97081: 'hh:mm',
    97082: 'min',
    97083: 'Off',
    97084: 'On',
    97085: 'On Read Only',
    97086: 'DR',
    97087: 'Bursa/Bosch',
    97088: 'Part',
    97089: 'Operation/Material',
    97090: 'Sample',
    97091: 'None',
    97092: 'Chart recorder',
    97093: 'Profile',
    97094: 'Info below',
    97095: 'Legend below',
    97096: 'Box No.',
    97097: 'Customer No.',
    97098: 'Old',
    97099: 'New',
    97100: 'Mini',
    97101: 'America',
    97102: 'Elgin',
    97103: 'Passau',
    97104: 'No',
    97105: 'Yes',
    97106: 'H&S',
    97107: 'No planning',
    97108: 'Only car command',
    97109: 'Sorting',
    97110: 'Heat',
    97111: 'Rest',
    97112: 'Hydro',
    97113: 'None',
    97114: 'MSSql',
    97115: 'MySql/PostGreSQL',
    97116: 'Oracle',
    97117: 'None',
    97118: 'Navision',
    97119: 'Pusher',
    97120: 'No',
    97121: 'Yes',
    97122: 'Load Sequencer',
    97123: 'TTC',
    97124: 'No',
    97125: 'Yes',
    97126: 'Take time from part',
    97127: 'Take time from how long load was in furnace',
    97128: 'No',
    97129: 'Yes',
    97130: 'Pusher',
    97131: 'Invalid host name',
    97132: 'Invalid database type',
    97133: 'Test connection',
    97134: 'Invalid client number',
    97135: 'Host name',
    97136: 'Database name',
    97137: 'Database type',
    97138: 'Port number',
    97139: 'User name',
    97140: 'Password',
    97141: 'Client number',
    97142: 'Client type',
    97143: 'Verbose',
    97144: 'Log to console',
    97145: 'Database',
    97146: 'Misc.',
    97147: 'Connection to database successful',
    97148: 'Select File to open',
    97149: 'Customer number not found',
    97150: 'Customer folder not found',
    97151: 'Set new folder name and location',
    97152: 'PT 9800 Configuration Utility',
    97153: 'Connect to server using client configuration file...',
    97154: 'Create new client configuration file...',
    97155: 'Configuration Parameters',
    97156: 'Clone System',
    97157: 'Settings',
    97158: 'Workplaces',
    97159: 'License',
    97160: 'Shop Layout',
    97161: 'Server Installation Wizard',
    97162: 'System',
    97163: 'Tools',
    97164: 'Manufacturer',
    97165: 'Developer',
    97166: 'Loaded',
    97167: 'Invalid value for the following parameter',
    97168: 'Edit data',
    97169: 'Edit',
    97170: 'Delete current shop layout',
    97171: 'Shop layout name already exists',
    97172: 'Workplaces',
    97173: 'Misc.',
    97174: 'Rect.',
    97175: 'Label',
    97176: 'Cart',
    97177: 'Back. Image',
    97178: 'Select layout ...',
    97179: 'Select workplace folder ...',
    97180: 'Shop Layout only supports 1 background image',
    97181: 'Unknown tag [ %1 ]. Unable to create component.',
    97182: 'Copy selected file to: %1 ?',
    97183: 'File already exists. Continue and overwrite?',
    97184: 'Failed to copy file',
    97185: 'Misc.',
    97186: 'User Defined',
    97187: "Shop layout name can't be empty",
    97188: 'Connect to server using database paramters...',
    97189: 'Database connection parameters',
    97190: 'Connected to database',
    97191: 'Select customer folder',
    97192: 'Customer folder is unknown. You will have to select the location of the customer folder',
    97193: 'PT9800 Configuration Utility',
    97194: 'Parameters saved successfully',
    97195: 'Settings',
    97196: 'Unable to save changes while there are other database connections.',
    97197: 'Please close all windows.',
    97198: 'Invalid database name',
    97199: 'Invalid port number',
    97200: 'Licensing: ',
    97201: 'Server Licensing: ',
    97202: 'Some DB records not found',
    97203: 'Invalid activation code',
    97204: 'Network card verification failure',
    97205: 'Demo version date expired!',
    97206: 'Access Violation!',
    97207: 'No license policy is set. All parameters are open to modification.',
    97251: 'Step 1 - Installation of Database Engine',
    97252: 'Step 2 - Verify Dependencies',
    97253: 'Step 3 - Copy 9800 DB-Tools to the Server',
    97254: 'Step 4 - Select the Method',
    97255: 'Step 5 - Create an Empty Database',
    97256: 'Step 6 - Create Database Tables',
    97257: 'Step 7 - Create Links between Tables',
    97258: 'Step 8 - Load Data',
    97259: '',
    97260: '',
    97261: '',
    97262: '',
    97263: '',
    97264: '',
    97265: '',
    97266: '',
    97267: '',
    97268: '',
    97269: '',
    97270: '',
    97271: '',
    97272: '',
    97273: '',
    97274: '',
    97275: '',
    97276: '',
    97277: '',
    97278: '',
    97279: '',
    97280: '',
    97281: 'Would you like to remove the database created in database?',
    97282: 'Database Delete Fails, Error:',
    97283: 'Please fill all the fields and try again!!',
    97284: 'Connection Test Passes",',
    97285: 'Connection Test does not pass',
    97286: 'Step 1 - Connection Test does not pass',
    97287: 'Step 1 - Success',
    97288: 'Required Dependencies Verification Succeeds',
    97289: 'Step 2 - Required Dependencies Verification is not done or Verification Fails',
    97290: 'Step 2 - Success',
    97351: 'Standard',
    97352: 'Logged Off',
    97353: 'Please close all windows before logging off',
    97354: 'Unable to log off',
    97355: 'Recent Files',
    97401: 'Server Licensing Manager',
    98000: 'The following configuration file was not found',
    98001: 'PT455 Configuration Tool',
    98002: 'Manufacturer',
    98003: 'Developer',
    98004: 'Loaded',
    98005: 'Recent Files',
    98006: 'Load System...',
    98007: 'Close System',
    98008: 'Exit',
    98009: 'Please close all windows before',
    98010: 'There is currently an open system. I will need to close it before loading another one, continue?',
    98011: 'You must close "IO Point Configuration Tool" in order to use "Background Recipe Builder',
    98012: 'You must close "Background Recipe Builder" in order to use "IO Point Configuration Tool',
    98013: 'Log In',
    98014: 'Show User Editor',
    98015: 'You must close "IO Point Configuration Tool" in order to use "Recipe Builder',
    98016: 'You must close "Recipe Builder" in order to use "IO Point Configuration Tool',
    98017: 'Unable to load the following external tool',
    98018: 'I/O Configuration',
    98019: 'PT455 Configuration',
    98020: 'Create update package',
    98021: 'Tools',
    98022: 'Regenerate "files.lst',
    98023: "'files.lst' not found",
    98024: 'Unable to calculate checksum value for the following file',
    98025: 'Unable to write the following file',
    98026: 'Completed successfully',
    98027: 'Not a valid backup',
    98500: 'Firmware Update',
    98501: 'The following firmware update folder was not found',
    98502: 'The following firmware update file was not found',
    98503: 'Firmware update as been copied. Unit needs to be restarted for effects to take place. Restart unit now?',
    98504: 'Please wait while copying firmware update ...',
    98505: 'The following checksum file for the firmware update was not found',
    98506: 'Unable to read the following checksum file',
    98507: 'A checksum file was found but does not appear to be for the firmware update file',
    98508: 'The checksum value obtained from the firmware update file does not match the value stored in the checksum file',
    98509: 'After copying firmware update file a checksum mismatch has been found',
    98510: 'You will need to insert or connect an external media containing the firmware update. The root of this media must contain the folder',
    98511: 'with the firmware update file',
    98512: 'After update has completed the unit will have to be restarted for changes to take affect. Do you want to proceed?',
    98513: 'The checksum command did not end normally and returned with an error',
    98514: 'Invalid checksum',
    98515: 'The checksum command returned with error',
    98550: 'Load Profibus Configuration',
    98551: 'You will need to insert or connect an external media containing the profibus configuration created with sycon.net. The root of this media must contain the folder',
    98552: 'with the configuration file',
    98553: 'After update has completed the unit will have to be restarted for changes to take affect. Do you want to proceed?',
    98554: 'Please wait while copying profibus configuration.',
    98555: 'The profibus configuration folder was not found',
    98556: 'The profibus configuration file was not found',
    100000: 'heat ramp',
    100001: 'cool ramp',
    100002: 'heat',
    100003: 'cool',
    100004: 'hold',
    100005: 'Min',
    100006: 'Max',
    100007: 'Search in column: ',
    100008: 'Created with',
    100009: 'version',
    100029: 'Invalid Value',
    100030: 'off',
    100031: 'on %C',
    100032: 'soot limit%',
    100033: 'Auto(100-x)%',
    100034: 'Surf.C%',
    100040: 'off',
    100041: 'on %N',
    100042: 'Nitride(100-x)%',
    100043: 'Surf. N%',
    100050: 'off',
    100051: 'on',
    100052: 'auto',
    100059: '°C/min ',
    100060: '°F/min ',
    100061: 'Minutes',
    100062: '%CD',
    100063: 'Stage Inserted',
    100064: 'Stage Added',
    100065: 'Please select a stage to be deleted.',
    100066: 'Stage Deleted',
    100067: 'Name',
    100068: 'Carrier Gas',
    100069: 'Fixed',
    100070: 'Stages',
    100071: 'Recipe',
    100072: 'Temperature',
    100073: 'Temp. Value',
    100074: 'Unit',
    100075: 'C Pot Type',
    100076: 'End',
    100077: 'Stage[min]',
    100078: 'Total[h:min]',
    100079: 'Entry out of range',
    100080: 'Setting entry to ',
    100081: 'Are you sure that you want to delete this recipe?',
    100082: 'Please load the recipe for deletion',
    100083: 'Recipe deleted',
    100084: 'Error deleting recipe',
    100085: 'Recipe saved',
    100086: 'Error saving recipe',
    100087: 'Error opening recipe database ',
    100088: 'C Pot Value [%]',
    100089: 'Gas Carburizing',
    100090: 'UPC HT-Tools',
    100091: 'Fixed',
    100092: 'Variable',
    100093: 'ß Control',
    100094: 'Ratio',
    100095: 'End Condition',
    100096: 'Carburizing Depth',
    100097: 'Time',
    100098: "Can't load template",
    100099: 'You may not delete or modify this recipe.',
    100100: 'Use "Save as" to make a copy.',
    100101: 'N Pot Type',
    100102: 'N Pot Value [%]',
    100103: 'Carbonitriding',
    100104: 'Low Pressure Carborizing',
    100105: 'Volumetric Flow',
    100106: 'Vol Flow [nl/m^2/h]',
    100107: 'Average',
    100108: 'Comp',
    100109: 'User',
    100110: 'Minimum',
    100111: 'Maximum',
    100112: 'Preprocessed',
    100118: 'Pre-processed Values',
    100119: 'Edit/Set',
    100120: 'Simulation Value',
    100121: 'Surface C',
    100122: 'Carbon Pot.',
    100123: 'Use',
    100124: 'Kn',
    100125: 'Kc Use',
    100126: 'Kc',
    100127: 'N2 Flow[%]',
    100128: 'Compound',
    100129: 'Diff./Prec.',
    100130: 'Layer',
    100131: "Iron AG'",
    100132: "Steel AG'",
    100133: "Iron G'E",
    100134: "Steel G'E",
    100135: 'CO+CO2[%]',
    100136: 'CO fl[%]',
    100137: 'CO2 fl[%]',
    100138: 'Phase',
    100139: 'NiCarm',
    100140: 'Diagram',
    100141: 'Temp. °F',
    100142: 'Temp. °C',
    100143: 'Nitralloy 135M',
    100144: 'Nitralloy EZ',
    100145: 'Nitralloy N',
    100146: '4140,4340',
    100147: 'DC,D6AC',
    100148: 'H11',
    100149: 'Stainless',
    100150: 'Carbon',
    100151: 'Group 1_HLSA Plain Carbon_5.1 to 21 [microns]',
    100152: 'Group 2_4140,4340_Nitralloy 135M_Low alloy 5.1 to 25_Tool Steels 2.5 to 15',
    100153: 'Group 3_Cast Iron 5.1 to 25 [microns]',
    100154: 'Class 0 No(Cl)',
    100155: 'Class 1 (0-13)',
    100156: 'Class 2 (13-25)',
    100157: 'Class 1_(Porosity <= 15%)',
    100158: 'Class 2_(10% - 40%)',
    100159: 'Kc Control',
    100160: 'Kn',
    100161: ' ',
    100162: 'Iron',
    100163: 'DC',
    100164: 'Nitralloy',
    100165: 'D6AC',
    100166: 'Carbon Steel',
    100167: 'Tool Steel',
    100168: 'H11 H13',
    100169: 'Low Alloy Steel',
    100170: '4140 4340',
    100171: 'High Alloy Steel',
    100172: 'Stainless Steel',
    100173: 'Steel Grade Estimation',
    100174: 'Use Recommended',
    100175: 'Steel',
    100176: 'Class',
    100200: 'Kn Eff',
    100201: 'Kc Eff',
    100202: 'AMS Helper',
    100203: 'Hide AMS',
    100204: 'E-Phase',
    100205: 'E-Phase-Steel',
    100206: 'Lehrer Diagram',
    100207: 'AMS Recommendation',
    100208: 'Diagrams',
    100209: 'Error in step ',
    100210: 'Not in epsilon.',
    100211: 'Stage time shorter than nucleation time.',
    100212: 'Kn too low for nucleation.',
    100213: "Kn too low.  Calculate with γ' steel.",
    100214: 'Temperature outside of specification.',
    100215: 'Surface hardness cannot be reached.',
    100216: 'Tempering temperature is too low, set to 500 deg C',
    100300: 'Process Values',
    100301: 'Profile Chart',
    100302: 'C/N2 Weight[%]',
    100303: 'Mass Flow',
    100304: 'C Potential',
    100305: 'N Potential',
    100306: 'Surface C',
    100307: 'Surface N',
    100308: 'Surface Carbide',
    100309: 'C/N Content [wt%]',
    100310: 'C/N Potential [wt%]',
    100311: 'C-Profile',
    100312: 'N-Profile',
    100313: 'Actual Nitride Limit',
    100314: 'Carb.Profile',
    100315: 'Carburizing Depth',
    100316: 'Actual Carbide Limit',
    100317: 'Retained Austenite',
    100318: 'Required Hardness at Case Depth',
    100319: 'Required Carbon at Carb. Depth',
    100320: 'Modify Selection',
    100321: 'Required Surface Hardness',
    100322: 'Nitride Profile',
    100323: 'Please select the items you want to display.',
    100324: 'No.',
    100325: 'Compound Layer',
    100326: 'Diffusion Layer',
    100327: 'Nitrided Case',
    100328: 'Distance to Surface',
    100329: 'Kn/Kc Potential',
    100330: 'Visual Case Depth',
    100331: 'Setpoint',
    100332: 'NHD',
    100333: 'C/C-Fe3C Content [wt%]',
    100400: 'Diffusion',
    100401: 'Part and Material',
    100402: 'Recipe',
    100403: 'Process Values Chart',
    100404: 'Profile Table',
    100405: 'Print Selection',
    100406: 'Analysis',
    100407: 'Page',
    100408: 'Carbon Profile Table',
    100409: 'Carbon/Nitrogen Profile Table',
    100410: '(continued)',
    100411: 'Simulation Errors',
    100415: 'Austenite',
    100416: 'Time [hh:mm:ss]',
    100417: 'Temp',
    100418: 'Atm.',
    100419: '%C',
    100420: '%N',
    100421: '%flow',
    100422: 'cd',
    100423: 'Ferrite',
    100424: 'Diff./Prec. Layer',
    100425: 'Carbide',
    100999: ' "Are you sure you want to delete this item?',
    101000: 'Part',
    101001: 'Quench Condition',
    101002: 'Quenching Media',
    101003: 'Quenching Intensity',
    101004: 'Temperature',
    101005: 'Material Data',
    101006: 'Grain Size',
    101007: 'Shape',
    101008: 'Diameter',
    101009: 'Carburizing Requirements',
    101010: 'Surface Hardness',
    101011: 'Case Depth [tsd]  NOT USED',
    101012: 'Reg. Hardness at cd [HV] NOT USED',
    101013: 'Content needed NOT USED',
    101014: 'Hardness[wt%]',
    101015: 'For Hardness at cd[wt%]',
    101016: 'Please enter the Part Name',
    101017: 'Please select the Quenching Media',
    101018: 'Please enter the Quenching Intensity',
    101019: 'Please enter the End Temperature',
    101020: 'Please enter Grain Size',
    101021: 'Please select the Shape',
    101022: 'Please enter Diameter',
    101023: 'Please enter Surface Hardness',
    101024: 'Please enter Case Depth',
    101025: 'Please enter Reg. Hardness',
    101026: 'Please select a treatment type',
    101027: 'Diameter',
    101028: 'Span betw. parallel sides',
    101029: 'Min. critical dimension',
    101030: 'Wall thickness',
    101031: 'Round Bar',
    101032: 'Hexagonal or Octagonal Bar',
    101033: 'Block/Plate/Sheet',
    101034: 'Disc inner diammeter < 200 mm',
    101035: 'Ring inner diammeter > 200 mm',
    101036: 'Tubing inner diameter < 80 mm',
    101037: 'Tubing inner diameter < 200 mm',
    101038: 'Tubing inner diameter > 200 mm',
    101039: 'Partially Closed Hollow Body',
    101044: 'Disc inner diammeter < 8 inch',
    101045: 'Ring inner diammeter > 8 inch',
    101046: 'Tubing inner diameter < 3 inch',
    101047: 'Tubing inner diameter < 8 inch',
    101048: 'Tubing inner diameter > 8 inch',
    101049: 'Rows',
    101050: 'Preprocessed Profile Data',
    101051: 'Sample',
    101052: 'Calculated Values (Carburizing)',
    101053: 'C-content @ core',
    101054: 'C-content @ CD',
    101055: 'Accept',
    101056: 'Select an equipment',
    101057: 'User Defined Values',
    101058: 'Profile',
    101059: 'Load From File',
    101060: 'Manual',
    101061: 'PartData',
    101062: 'Requirements',
    101063: 'Through Hardening',
    101064: 'Hardness @ CHD',
    101065: 'Equipment',
    101066: 'Total Case Depth',
    101067: 'Effective Case Depth',
    101068: 'Salt',
    101069: 'Oil',
    101070: 'Water',
    101071: 'Profile',
    101072: 'Properties',
    101073: 'End Temperature',
    101074: 'Heat Treatment Type',
    101075: 'C-content @ Surface',
    101076: 'Select a material',
    101077: 'End temperature is used for the calculation of the retained austenite',
    101078: 'Surface carbon[%]',
    101079: 'Reference carbon[%]',
    101100: 'Range',
    101101: 'Please select a material',
    101102: 'Select Units',
    101103: 'Change Units',
    101104: 'Depth',
    101105: 'C-Content',
    101106: 'Hardness',
    101107: 'CORE',
    101108: 'Conversion between Vickers and Rockwell failed...',
    101109: 'Please select a material',
    101110: 'Some fields are incomplete',
    101111: 'N2-Content',
    101112: 'C in Fe3c',
    101113: 'C-CORE',
    101114: 'N-CORE',
    101115: 'Initial Condition (Nitriding)',
    101116: 'Normalized',
    101117: 'As Quenched',
    101118: 'Tempered',
    101119: 'Estimated Tempering Conditions',
    101120: 'Temperature',
    101121: 'Time',
    101122: 'Carburizing Requirements',
    101130: 'PE C - Diff',
    101131: 'Newmann',
    101132: 'AWT',
    101133: 'Gunnarson',
    101134: 'Grabke',
    101135: 'User Defined',
    101136: 'PE C[+N] - Diff',
    101140: 'Chemical Composition',
    101141: 'Heat Treatment Properties',
    101142: 'General Properties',
    101143: 'Carburizing Properties',
    101144: 'Nitriding Properties',
    101145: 'Untreated Core Hardness',
    101146: 'Alloy Factor',
    101147: 'Carbide Limit',
    101148: 'Max. Carbon in Carbides',
    101149: 'Max. Nitrogen in Nitrides',
    101150: 'Calculation Method',
    101151: 'CH4 Factor',
    101152: '[min,max]',
    101153: 'wt %',
    101154: 'Carbon',
    101158: 'Please load the item for deletion',
    101159: 'No Change has been made',
    101160: 'Save Successful',
    101161: 'Save Failed',
    101162: 'Copy Successful',
    101163: 'Copy Failed',
    101164: 'Delete Successful',
    101165: 'Delete Failed',
    101166: 'Open Successful',
    101167: 'Open Failed',
    101168: 'Ready',
    101169: 'Please open one to copy',
    101170: 'Please verify Min/Max values.',
    101201: 'Gas carburizing',
    101202: 'Gas carbonitriding',
    101203: 'Low pressure (vacuum) carburizing',
    101204: 'Nitriding',
    101205: 'Nitrocarburizing',
    101206: 'Neutral hardening (vacuum)',
    101221: 'Distance from Surface',
    101222: 'Conversion Failed!',
    101223: 'Error',
    101224: 'Failed to get the list of all materials',
    101225: 'Alloy factor[Carbon]',
    101226: 'Carbide limit[%aC=1]',
    101227: 'Alloy factor[Nitrogen]',
    101228: 'Nitride limit[FexNy]',
    101229: 'Core Hardness untreated',
    101230: 'Undefined',
    101231: 'Core Hardness',
    101232: 'Quenched Hardness',
    101233: 'Hardness',
    101235: 'Tempering Curve',
    101236: 'Temperature',
    101237: 'Schaeffler Diagram',
    101238: 'NI-Equivalent [%]',
    101239: 'Cr-Equivalent [%]',
    101240: 'Jominy Curve',
    101241: 'Material',
    101242: 'Material Compare',
    101243: 'Tempering',
    101301: 'General',
    101302: 'Heating Capacity',
    101303: 'Cooling Capacity',
    101304: 'Carbon Potential - max. increase',
    101305: 'Carbon Potential - max. decrease',
    101306: 'Displaying result please wait ...',
    101310: 'Gas',
    101311: 'Salt&Oil',
    101312: 'Water',
    101313: 'Brine',
    101320: 'Depth unit',
    101321: 'Temp',
    101322: 'Time [hh:mm:ss]',
    101323: 'Atm. [%C]',
    101324: 'Atm. [%N]',
    101325: 'Atm. [%flow]',
    101326: 'Save As',
    101327: 'Simulation in progress, result will be displayed when simulation is finished...',
    101328: 'Save table to file',
    101329: 'Save profile result',
    101330: 'Configuration',
    101331: 'Configuration of carbon table',
    101332: 'Configuration of nitriding table',
    101333: 'Configuration of nitro carburizing table',
    101350: 'Options',
    101351: 'Set as Default',
    101352: 'Measuring Units',
    101353: 'Temperature',
    101354: 'Stage Duration',
    101355: 'Heat/Cool Ramp',
    101356: 'Part/Sample Dimension',
    101357: 'Simulation Speed',
    101358: 'Calculation Interval [ms]',
    101359: 'Corresponding Time [sec]',
    101360: 'Requirements',
    101361: 'Core Hardness (Untreated)',
    101362: 'Surface Hardness',
    101363: 'Effective Case Depth',
    101364: 'Required Hardness@Case Depth',
    101365: 'Total Case Depth',
    101366: 'White Layer',
    101367: 'User Interface',
    101368: 'Show Full Screen',
    101369: 'ShowLegend',
    101370: 'Select Title Color',
    101371: 'C-Beta',
    101372: 'C-Diff',
    101373: 'N-Beta',
    101374: 'N-Diff',
    101375: 'Use Part Shape in Simulation',
    101376: 'Low Pressure Carburizing Only',
    101377: 'Max.C in cementite [%C]',
    101378: 'C Below Carbide Limit[%C]',
    101400: 'Atmospheric carburizing is based on J. Wünning. Atmospheric Carbonitriding is based on K.-M. Winter. Low pressure carburizing is based on M. Steinbacher.',
    101401: ' "Hardness calculation is valid for direct hardening only and is  based on U. Wyss and K. H. Weissohn.',
    101402: ' "Atmospheric nitriding and nitrocarburizing is based on K.-M. Winter using: Hardenability (Just), Tempering (Grange et al.), Phase boundaries in Lehrer-Diagram (Maldzinski et al.), Iron-nitrogen-carbon ε-phase boundaries (Naumann and Langenscheidt), Iso-concentration lines for nitrogen and carbon in ε-phase (Kunze), Activity coefficients for nitrogen and carbon in ferrite (Fromm and Gebhardt), Nucleation (Sun and Bell), Compound layer growth (Hosseini et al.), Precipitation layer growth (Kunze), Softening (applying Hollomon-Jaffe equation on literature data). Simulator also provides recommendations for recipe design according to AMS 5759.',
    101403: ' "Please, select a valid recipe.',
    101404: " \"Fe αγ'",
    101405: " \"Fe γ'ξ",
    101406: " \"St αγ'",
    101407: " \"St γ'ξ",
    101408: ' "AMS',
    101409: 'Wrong recipe type from Simulation',
    101410: "Can't close form when simulation is running",
    101411: "Can't load recipe",
    101412: 'You do not have access to this type of recipe.',
    101413: 'Preprocessed values form is not open.',
    101414: 'Load the data from a file.',
    101415: 'Select file',
    101416: 'Result',
    101417: 'Update file was not found',
    101418: 'This feature is disabled',
    101419: 'Failed to set recipe parameters',
    101420: 'Some of recipe parameters are not valid',
    101421: 'Cross-section',
    101422: 'Plate from two sides',
    101423: 'Calculation from one side',
    101500: 'Yes',
    101501: 'No',
    101502: 'Ok',
    101503: 'Cancel',
    101504: 'Exit',
    101505: 'Close',
    101506: 'Save',
    101507: 'Save As',
    101508: 'Clear',
    101509: 'New',
    101510: 'New Recipe',
    101511: 'Delete',
    101512: 'Print',
    101513: 'Append',
    101514: 'Insert',
    101515: 'Open',
    101516: 'Duplicate',
    101517: 'Furnace',
    101518: 'Material',
    101519: 'Part',
    101520: 'Recipe Type',
    101521: 'Option',
    101522: 'Run Simulation',
    101523: 'Abort Simulation',
    101524: 'Pause',
    101525: 'Next Hour',
    101526: 'Next Stage',
    101527: 'Add Comment',
    101528: 'Process Calculator',
    101529: 'Export',
    101530: 'Go to End',
    101531: 'Switch Units',
    101532: 'Process Values',
    101533: 'Profile Table',
    101534: 'Profile Chart',
    101535: 'Configure',
    101536: 'Select Recipe',
    101537: 'Settings',
    101538: 'About',
    101539: 'Save to PDF',
    101540: 'Modify',
    101541: 'Nitriding',
    101542: 'Nitrocarburizing',
    101543: 'Surface Content',
    101544: 'Core Content',
    101545: 'Core Depth',
    101599: ' " ',
  },
};
