import moment from 'moment';

export const DATE_TIME_FORMAT_INTERNAL = 'YYYY-MM-DDTHH:mm';
export const DATE_TIME_FORMAT_VIEW = 'DD/MM/YYYY';

export const DateUtils = {
  formatDate(date: string | number): string {
    return moment(date).format(DATE_TIME_FORMAT_VIEW);
  },
  getStartOfDayDate(date: string | number): string {
    return moment(date).startOf('day').format(DATE_TIME_FORMAT_INTERNAL);
  },
  getEndOfDayDate(date: string | number): string {
    return moment(date).endOf('day').format(DATE_TIME_FORMAT_INTERNAL);
  },
};
