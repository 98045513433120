export const ERROR_NAME_USED = '23505';
export const ENTRY_MODIFIED = 'ENTRY_MODIFIED';
export const ENTRY_DOES_NOT_EXIST = 'ENTRY_DOES_NOT_EXIST';

export const create = (apiClient) => {
  const {
    fetchGraphJSON,
  } = apiClient;

  const TYPE_MATERIAL_GROUP = `{
  id,
  name,
  remark,
  user,
  lastModifiedTimeInSec,
}`;

  const TYPE_ERROR = `{
  code,
  message,
  detail,
}`;

  const TYPE_SAVE_MATERIAL_GROUP_RESULT = `{
  materialGroup ${TYPE_MATERIAL_GROUP}
  isSuccess,
  error ${TYPE_ERROR}
}`;

  const INPUT_SAVE_EDITED_MATERIAL_GROUP = (data) => `{
    id: ${data.id}
    name: '${data.name}'
    remark: '${data.remark}'
    lastModifiedTimeInSec: ${data.lastModifiedTimeInSec}
}`;

  const getMaterialGroupList = async (location) => {
    const query = `
  query{
    materialGroupListPortal ${TYPE_MATERIAL_GROUP}
  }`;

    try {
      let result = await fetchGraphJSON(query, {
        location,
      });
      result = result.data.materialGroupListPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  const saveNewMaterialGroup = async (location, {
    name, remark,
  }) => {
    const mutation = `
  mutation {
    saveNewMaterialGroupPortal(data: { name: '${name}', remark: '${remark}' }) ${TYPE_SAVE_MATERIAL_GROUP_RESULT}
  }`;

    try {
      let result = await fetchGraphJSON(mutation, {
        location,
      });
      result = result.data.saveNewMaterialGroupPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  const deleteMaterialGroup = async (location, data) => {
    const mutation = `
  mutation {
    deleteMaterialGroupPortal(data: { id: ${data.id}, lastModifiedTimeInSec: ${data.lastModifiedTimeInSec} }) {
      isSuccess
      error ${TYPE_ERROR}
    }
  }`;

    try {
      let result = await fetchGraphJSON(mutation, {
        location,
      });
      result = result.data.deleteMaterialGroupPortal;
      return result;
    } catch (err) {
      return err;
    }
  };

  const saveEditedMaterialGroup = async (location, data) => {
    const mutation = `
  mutation {
    saveEditedMaterialGroupPortal(data: ${INPUT_SAVE_EDITED_MATERIAL_GROUP(
    data,
  )}) ${TYPE_SAVE_MATERIAL_GROUP_RESULT}
  }`;

    try {
      let result = await fetchGraphJSON(mutation, {
        location,
      });
      result = result.data.saveEditedMaterialGroupPortal;
      return result;
    } catch (err) {
      return err;
    }
  };
  return {
    getMaterialGroupList,
    saveEditedMaterialGroup,
    deleteMaterialGroup,
    saveNewMaterialGroup,
  };
};
