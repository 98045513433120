import {
  SensorMappingService,
} from '@/services/SensorMappingService.js';
import {
  qmulusSdk,
} from '@/services/qmulusService';
import {
  TokenService,
} from '@/services/TokenService';

export default {
  state: {
    commonSensors: [],
    isLoadingCommonSensors: false,
    assetSensors: [],
    isLoadingAssetSensors: false,
    sensorGroups: [],
    isLoadingSensorsGroups: false,
    sensors: [],
    isLoadingSensors: false,
    isUpdatingGroups: false,
  },

  mutations: {
    setCommonSensors(state, list) {
      state.commonSensors = list.map((obj) => {
        return {
          ...obj,
          show: true,
        };
      });
    },
    updateAssetSensorMapping(state, {
      assetSensor, mapping,
    }) {
      const index = state.assetSensors.indexOf(assetSensor);
      if (index !== -1) {
        const updatedAssetSensor = state.assetSensors[index];

        updatedAssetSensor.mapped_generic_sensor = mapping;

        if (mapping.length === 0) {
          delete updatedAssetSensor.category;
          delete updatedAssetSensor.description;
          delete updatedAssetSensor.engineering_unit_metric;
          delete updatedAssetSensor.engineering_unit_imperial;
          delete updatedAssetSensor.engineering_unit_si;
        } else {
          const commonSensor = mapping[0];
          updatedAssetSensor.category = commonSensor.category;
          updatedAssetSensor.description = commonSensor.description;
          updatedAssetSensor.engineering_unit_metric = commonSensor.engineering_unit_metric;
          updatedAssetSensor.engineering_unit_imperial = commonSensor.engineering_unit_imperial;
          updatedAssetSensor.engineering_unit_si = commonSensor.engineering_unit_si;
        }
        state.assetSensors.splice(index, 1, updatedAssetSensor);
      }
    },
    updateCommonSensorDataMapping(state, commonSensor) {
      const index = state.commonSensors.findIndex(
        (s) => s.common_sensor_data_id === commonSensor.common_sensor_data_id,
      );
      if (index !== -1) {
        Object.assign(state.commonSensors[index], commonSensor);
      }
    },
    setIsLoadingCommonSensors(state, isLoading) {
      state.isLoadingCommonSensors = isLoading;
    },
    setAssetSensors(state, response) {
      for (const sensor of response) {
        const mappedGeneric = state.commonSensors.find(
          (common) => (common.furnace_sensor_id === sensor.log_identifier_id
              || common.furnace_sensor_id === sensor.set_log_identifier_id),
        );
        sensor.mapped_generic_sensor = [];
        if (mappedGeneric) {
          sensor.mapped_generic_sensor.push(mappedGeneric);
          // flattened data for dynamic table
          sensor.category = mappedGeneric.category;
          sensor.description = mappedGeneric.description;
          sensor.engineering_unit_metric = mappedGeneric.engineering_unit_metric;
          sensor.engineering_unit_imperial = mappedGeneric.engineering_unit_imperial;
          sensor.engineering_unit_si = mappedGeneric.engineering_unit_si;
        }
      }
      state.assetSensors = response;
    },
    setIsLoadingAssetSensors(state, isLoading) {
      state.isLoadingAssetSensors = isLoading;
    },
    setIsLoadingSensors(state, isLoading) {
      state.isLoadingSensors = isLoading;
    },
    setSensors(state, sensors) {
      state.sensors = sensors;
    },
    setIsLoadingSensorGroups(state, isLoading) {
      state.isLoadingSensorsGroups = isLoading;
    },
    setSensorGroups(state, sensorsGroups) {
      state.sensorGroups = sensorsGroups;
    },
  },

  actions: {
    async GET_SENSOR_GROUPS(context) {
      context.commit('setIsLoadingSensorGroups', true);
      context.commit('setSensorGroups', []);
      try {
        const {
          id: userId,
        } = await TokenService.decodedIdToken(); // TODO: Figure out a more efficient way of doing this
        const sensorGroups = await qmulusSdk.users.settings.sensorGroups.get(
          userId,
        );
        // TODO: QMU-1238
        //  We should opt to remove this secondary restructuring since the array and the dict outside out of the sensor groups array is redundant.
        const payload = [
          {
            groups: sensorGroups,
          },
        ];
        context.commit('setSensorGroups', payload);
      } finally {
        context.commit('setIsLoadingSensorGroups', false);
      }
    },
    async UPDATE_SENSOR_GROUPS(context, sensorGroups) {
      try {
        const {
          id: userId,
        } = await TokenService.decodedIdToken(); // TODO: Figure out a more efficient way of doing this
        await qmulusSdk.users.settings.sensorGroups.update(
          userId,
          sensorGroups,
        );
      } catch (e) {
        return e.response.status;
      }
    },
    async GET_ALL_COMMON_SENSORS(context) {
      context.commit('setIsLoadingSensors', true);
      context.commit('setSensors', []);
      try {
        context.commit(
          'setSensors',
          await SensorMappingService.getAllCommonSensors(),
        );
      } finally {
        context.commit('setIsLoadingSensors', false);
      }
    },
    async SET_COMMON_SENSORS(context, equipmentId) {
      context.commit('setIsLoadingCommonSensors', true);
      context.commit('setCommonSensors', []);
      try {
        context.commit(
          'setCommonSensors',
          await SensorMappingService.getCommonSensors(equipmentId),
        );
      } finally {
        context.commit('setIsLoadingCommonSensors', false);
      }
    },
    async SET_ASSET_SENSORS(context, equipmentId) {
      context.commit('setIsLoadingAssetSensors', true);
      context.commit('setAssetSensors', []);
      try {
        context.commit(
          'setAssetSensors',
          await SensorMappingService.getAssetSensors(equipmentId),
        );
      } finally {
        context.commit('setIsLoadingAssetSensors', false);
      }
    },
    async UPDATE_SENSOR_MAPPING(context, payload) {
      try {
        const response = await SensorMappingService.updateSensorDataMapping(
          payload,
        );

        const updateData = {
          ...payload,
        };
        if (response.sensor_data_mapping_id) {
          updateData.sensor_data_mapping_id = response.sensor_data_mapping_id;
        }
        context.commit('updateCommonSensorDataMapping', updateData);
      } catch (e) {
        return e.response.status || e.message;
      }
    },
    async DELETE_SENSOR_MAPPING(context, id) {
      try {
        await SensorMappingService.deleteSensorDataMapping(id);
      } catch (e) {
        return e.response.status;
      }
    },
  },
};
