import {
  ELocalisationStoreDateFormat,
} from '@/types/localization-store.ts';
import {
  TTimeFormatTranslation,
} from './select-time-format.ts';

const timeFormats: TTimeFormatTranslation = {
  [ELocalisationStoreDateFormat.HOURS_12]: '12 hours',
  [ELocalisationStoreDateFormat.HOURS_24]: '24 hours',
};

export default {
  placeholder: 'Select time format',
  timeFormats,
};
