import {
  TCsvQuoteCharacterTranslations,
} from './select-csv-quote-char.ts';

const translations: TCsvQuoteCharacterTranslations = {
  single_quote: 'Single quote',
  double_quote: 'Double Quote',
};

export default {
  placeholder: 'Please choose a quoting style',
  exampleText: 'my text',
  label: 'Quote character',
  options: translations,
};
