export default {
  unitLabel: {
    potential: 'C/N Potenziell',
    content: 'C/N Inhalt',
    depth: 'Tiefe',
    hardness: 'Härte',
    carbDepth: 'Aufkohlungstiefe',
  },
  profile: {
    carbonProfile: 'Kohlenstoffprofil',
    nitrogenProfile: 'Stickstoffprofil',
    carbideProfile: 'Hartmetallprofil',
  },
  legendLabel: 'Legend',
};
